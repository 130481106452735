export const getManyBrandQuery =`
query GetManyBrand{
    GetManyBrand{
      cid
      uuid
      brandName
      brandStartDate
      activity
      region
      country
      url
      iconUrl
      format
      dog
      catOnly
      healthy
      sickOnly
      countriesActive
      channels
      packaging
      special
      species
      health
      technology
      twitterHandle
      instaHandle
      facebookHandle
      tiktokHandle
      pinterestHandle
      youtubeHandle
      fuelUp
      mmni
      mmi
      cts
      mtt
      clh
      clmc
      companyInfo{
        funding
        revenue
        revenueDescription
        address
      }
      engagement{
        cumulative{
          followers
        }
        instagram{
          followers
          commentsPerPost
          favouritesPerPost
        }
        twitter{
          followers
          commentsPerPost
          favouritesPerPost
        }
        facebook{
          followers
          commentsPerPost
          favouritesPerPost
        }
        youtube{
          followers
          commentsPerPost
          favouritesPerPost
        }
      }
    }
  }`