import React from "react";
import axios from 'axios';

// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';

import {useDropzone} from 'react-dropzone';
import * as api from "../../API/IndividualVpAPI";

import CU from "../../../assets/img/icons/cloud-upload.svg";
//import PI from "../../../assets/img/generic/image-file-2.png";

export const Files = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
 
 
  React.useEffect(() => {
    if(acceptedFiles.length > 0) {
      if(acceptedFiles[0].path !== "false"){
        console.log(`file: ${acceptedFiles[0].path}`)
        api.uploadFile(vpHandler.get.uuid, acceptedFiles[0], vpHandler)
      }
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  // const uploadFile = (acceptedFiles) => {
  //   acceptedFiles.forEach()
  // }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const getDownloadLink = (fileId, fileName) => {
    console.log(`fileid: ${fileId}, fileName ${fileName}`)
    axios
      .post( process.env.REACT_APP_API, {
        query:`query GetDownload($fileId: String, $fileName: String){
          GetDownload(fileId:$fileId, fileName:$fileName)
        }`,
        variables: {
          fileId: fileId,
          fileName: fileName
        }
      })
    .then(res => {
      console.log(res.data.data.GetDownload)
      openInNewTab(res.data.data.GetDownload)
    })
    .catch(err => console.log(err))
  }

  // const getDownloadLink = (fileId, fileName) => { 
  //   //console.log(`fileID: ${fileId}, name: ${fileName}`)
  //   //let fileId = resource.uuid
  //   //fileName = `${fileName}.pdf`
  //   axios({
  //     url: process.env.REACT_APP_API,
  //     //url: "https://d2cx.dev/graphql",
  //     method: "post",
  //     data: {
  //       query: `query GetDownload($fileId: String, $fileName: String) {
  //         GetDownload(fileId: $fileId, fileName: $fileName)
  //       }`,
  //       variables: { fileId: fileId, fileName: fileName },
  //     },
  //   })
  //     .then((result) => {
  //       openInNewTab(result.data.data.GetDownload);
  //       //setLearnFiles( [...result.data.data.GetLearnFile] );
  //     })
  //     .catch((err) => console.log(err));
  // };
  
  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     api.uploadFile(vpHandler.get.uuid, file, vpHandler, setUploadBusy), 
  //   </li>
    
  // ));

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Files</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p>
              Upload any additional documents to showcase this value proposition and complement the information provided on this page.
            </p>
          </div>
        </div>
        {vpHandler.action !== "add" &&
        <div class={`${hide ? "collapse" : ""} card-body`}>
        
          <div class="table-responsive scrollbar mb-2">
            <table class="table table-sm fs--2 mb-0 overflow-hidden">
              <colgroup>
                <col style={{width:"75%"}}/>
                <col style={{width:"25%"}}/>
              </colgroup>
              <thead class="bg-200 text-900">
                <tr>
                  <th class="sort pl-1 align-middle white-space-nowrap" data-sort="name">Name</th>
                  <th class="sort pl-1 align-middle white-space-nowrap">Action</th>
                </tr>
              </thead>
              <tbody class="list fs--2">
                {vpHandler.get.files.map((file) => (
                  <tr class="btn-reveal-trigger">
                    <td class="align-top name hoverhand" onClick={e=>getDownloadLink(file.uuid, file.fileName)}>{file.fileName}</td>
                    <td class="">
                      <span class="bi-cloud-download mr-1 ml-2 "></span>
                      <span onClick={e=>api.removeFile(vpHandler.get.uuid, file.uuid, vpHandler)} class={`${!api.checkDisabled(vpHandler)?"":"d-none"} hoverhand fas far fa-trash-alt fs--2`} ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> 
          </div>
      {!api.checkDisabled(vpHandler) &&
        <div class={`dropzone dropzone-single p-0 hoverhand`} data-dropzone="data-dropzone" data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'>
          <div {...getRootProps({className: 'dropzone'})} class="dz-message rounded" style={{border: "2px dashed #d8e2ef"}} data-dz-message="data-dz-message">
            <div class="dz-message-text fs--2 row">
              <div class="ml-auto"><img class="mr-2" src={CU} width="25" alt="" /></div>
              <input {...getInputProps()} />
              <div class="mr-auto mt-1">Drop your file here</div>
            </div>
          </div>
        </div>
      }
      
        </div>
      }
      </div>
    </div>
          
  )}