import React from "react";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';
import { countryList } from "../../Lists/countryList";
import { InputFieldCell } from "../../Input/InputFieldCell";
import * as api from "./../../API/IndividualVpAPI";

export const TeamDetails = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [totalFte, setTotalFte] = React.useState(0);
  const [init, setInit] = React.useState(false);
  
  React.useEffect(() => {
    if(!init){
      setInit(true)
      getFte()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init])

  const getFte = () => {
    let fte = 0
    vpHandler.get.team.forEach((member) => fte = Number(fte) + Number(member.fte))
    fte = fte.toFixed(2)
    setTotalFte(fte)
  }

  const AddTeamMember = () => {
    let vpObject = vpHandler.get
    if(!!vpObject.team.find(item => item.name==="" || item.email==="" || item.fte==="") === false ){
      let newMember = {name: "", fte: "", email:""}
      vpObject.team.push({...newMember})
      //let _team = teamMembers
      //_team.push(member)
      //setTeamMembers([..._team])
      vpHandler.set({...vpObject})
      getFte()
    }
    else api.notify("Please fill out name, email and FTE before adding a new team member.", vpHandler.ref, "danger")
  }

  const AddSponsor = () => {
    let vpObject = vpHandler.get
    if(!!vpObject.sponsors.find(item => item.name==="" || item.email==="") === false ){
      let newMember = {name: "", email:""}
      vpObject.sponsors.push({...newMember})
      //let _team = teamMembers
      //_team.push(member)
      //setTeamMembers([..._team])
      vpHandler.set({...vpObject})
    }
    else api.notify("Please fill out name and email before adding a new sponsor.", vpHandler.ref, "danger")
  }

  const RemoveTeamMember = (e) => {
    let vpObject = vpHandler.get
    //let arr = []
    let index = vpObject.team.findIndex(obj => obj.name === e)
    if (index !== -1) {
      vpObject.team.splice(index, 1);
    }
    vpHandler.set({...vpObject})
    getFte()
  }

  const RemoveSponsor = (e) => {
    let vpObject = vpHandler.get
    //let arr = []
    let index = vpObject.sponsors.findIndex(obj => obj.name === e)
    if (index !== -1) {
      vpObject.sponsors.splice(index, 1);
    }
    vpHandler.set({...vpObject})
    getFte()
  }

  const getGatekeeper = () => { 
    return [{name: "Gatekeeper Name", email: "gate@keeper.com"}] 
  }  

  const checkIfAdd = () => {
    if(vpHandler.action === "add") return true
    else return false
  }
  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Team details</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2 pb-0">
            <ul>
              <li>Team member → List all core team members that are supporting the delivery of this value proposition. </li>
              <li>FTE → How much time does this person dedicate to this project? For example 0.2 FTE = 20% of their time committed</li>
              <li>Sponsor → List the approver for this VP, i.e. the person or people who decides to progress, pivot or perish at the end of the stage. Note that this is the person or people who will be sent an email notification to review when you click 'Submit for approval'</li>
            </ul>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
          <div class="row">
              <div class="col col-12 col-lg-6 mb-2">
                <div class="table-responsive scrollbar">
                  <table class="table table-sm fs--2 mb-0 overflow-hidden">
                    <colgroup>
                      <col style={{width:"35%"}}/>
                      <col style={{width:"25%"}}/>
                      <col style={{width:"15%"}}/>
                      <col style={{width:"25%"}}/>
                    </colgroup>
                    <thead class="bg-200 text-900">
                      <tr>
                        <th class="sort pl-1 align-middle white-space-nowrap" data-sort="name">                
                          {`Team Member${checkIfAdd() ? "*" : ""}`} 
                        </th>
                        <th class="sort pl-1 align-middle white-space-nowrap" data-sort="email">Email</th>
                        <th class="sort pl-1 align-middle white-space-nowrap text-end" data-sort="FTE">FTE</th>
                        <th class="sort pl-1 align-middle white-space-nowrap mr-2 ">Action<div onClick={e => AddTeamMember()}><span class={`mars-blue fas far ${api.checkDisabled(vpHandler) === false ? "fa-plus-square" : ""} fs--2 ml-2 hoverhand`}></span></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="list fs--2">
                    {vpHandler.get.team.map((item, itt) => (
                      <tr key={itt} class="btn-reveal-trigger">
                        <td class="align-top name">
                          <InputFieldCell title="Name" team={"team"} item={item} handle="name" vpHandler={vpHandler}/>
                        </td>
                        <td class="align-top email">
                          <InputFieldCell title="Email" team={"team"} item={item} handle="email" vpHandler={vpHandler}/>
                        </td>
                        <td class="align-top text-end FTE">
                          <InputFieldCell title="FTE" team={"team"} item={item} handle="fte" vpHandler={vpHandler}/>
                        </td>
                        <td class="align-top">
                          <div onClick={e => RemoveTeamMember(item.name)}><span class={`fas ${api.checkDisabled(vpHandler) === false ? "far fa-trash-alt" : ""} fs--2 mars-blue hoverhand`}></span></div>
                        </td>
                      </tr>
                    ))}
                    
                    </tbody>
                    <tfoot class="bg-100">
                      <tr class="sort pl-1 align-middle white-space-nowrap">
                        <th class="text-end ">Total:</th>
                        <td />
                        <td class="align-top text-end FTE">{totalFte}</td>
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div class="col col-12 col-lg-6 mb-3">
                <div class="table-responsive scrollbar">
                  <table class="table table-sm fs--2 mb-0 overflow-hidden">
                    <colgroup>
                      <col style={{width:"35%"}}/>
                      <col style={{width:"40%"}}/>
                      <col style={{width:"15%"}}/>
                    </colgroup>
                    <thead class="bg-200 text-900">
                      <tr>
                        <th class="sort pl-1 align-middle white-space-nowrap" data-sort="name">
                          {`Sponsor${checkIfAdd() ? "*" : ""}`} 
                        </th>
                        <th class="sort pl-1 align-middle white-space-nowrap" data-sort="email">Email</th>
                        <th class="sort pl-1 align-middle white-space-nowrap mr-2 ">Action<div onClick={e => AddSponsor()}><span class={`mars-blue fas far ${api.checkDisabled(vpHandler) === false ? "fa-plus-square" : ""} fs--2 ml-2 hoverhand`}></span></div></th>
                      </tr>
                    </thead>
                    <tbody class="list fs--2">
                      {vpHandler.get.sponsors.map((item) => (
                        <tr class="btn-reveal-trigger">
                          <td class="align-top name">
                            <InputFieldCell title="Name" team="sponsors" item={item} handle="name" vpHandler={vpHandler}/>
                          </td>
                          <td class="align-top email">
                            <InputFieldCell title="Email" team="sponsors" item={item} handle="email" vpHandler={vpHandler}/>
                          </td>
                          <td class="align-top">
                            <div onClick={e => RemoveSponsor(item.name)}><span class={`fas ${api.checkDisabled(vpHandler) === false ? "far fa-trash-alt" : ""} fs--2 mars-blue hoverhand`}></span></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class={`col col-12 col-lg-6 mb-2 ${vpHandler.get.currentStage === "scale" ? "d-none" : ""}`}>
                  <div class="table-responsive scrollbar">
                    <table class="table table-sm fs--2 mb-0 overflow-hidden">
                      <colgroup>
                        <col style={{width:"35%"}}/>
                        <col style={{width:"25%"}}/>
                      </colgroup>
                      <thead class="bg-200 text-900">
                        <tr>
                          <th class="sort pl-1 align-middle white-space-nowrap" data-sort="name">Gatekeeper</th>
                          <th class="sort pl-1 align-middle white-space-nowrap" data-sort="email">Email</th>
                        </tr>
                      </thead>
                      <tbody class="list fs--2">
                      {!!getGatekeeper() &&  
                       getGatekeeper().map((item, itt) => (
                        <tr key={itt} class="btn-reveal-trigger">
                          <td class="align-top name">
                            {item.name}
                          </td>
                          <td class="align-top email">
                            {item.email}
                          </td>
                        </tr>
                      )) }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
          {checkIfAdd() &&  
            <div className="ml-3 mb-2" style={{fontSize: '11px'}}>* required fields.</div>
          }
      </div>
    </div>
          
  )}