import React from 'react';
import PageTitle from "../../components/header/PageTitle";
import { GetValueProps } from '../../components/API/ControlTowerAPI';  
import { FilterDeck } from '../../components/Cards/FilterDeck';
// import ValuePropositionTable from '../dashboards/ValuePropositionTable';
import * as vpApi from "./../../components/API/IndividualVpAPI";
// import Select from 'react-select'
// import { lookUpList } from '../../components/Lists/lookUpList';
// import { lookUpKPI } from '../../components/Lists/lookUpKPI';
// import BenchmarkScatter from '../../components/ECharts/BenchmarkScatter';
// import { Input, Col, Card, CardHeader, CardBody, Row, Table, UncontrolledTooltip } from "reactstrap"
// import CsvDownload from 'react-json-to-csv'
import { SwimmingLaneObject } from '../../components/Cards/SwimmingLaneObject';

import { demoVPArray } from '../../components/Lists/demoVPArray';

const SwimmingLanes = () => {

  //const [yAxisName, setYAxisName] = React.useState("");
  const [init, setInit] = React.useState(false)
  const [init2, setInit2] = React.useState(false)
  const [valueProps, setValueProps] = React.useState([]);
  const [valuePropsBackup, setValuePropsBackup] = React.useState([]);
  const [string, setString] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [allVp, setAllVp] = React.useState(demoVPArray);
  const [allVpBackup, setAllVpBackup] = React.useState(demoVPArray);
  // const [selectedKPI, setSelectedKPI] = React.useState("");
  // const [selectedVP, setSelectedVP] = React.useState("");
  // const [selectedVPArr, setSelectedVPArr] = React.useState([]);
  // const [kpiData, setKpiData] = React.useState([]);
  // const [scatterData, setScatterData] = React.useState({ideation: [], customerVal: [], mvp: [], optimize: [], grow: [], scale: []});

  const [amount, setAmount] = React.useState({ideation: 0, customerVal: 0, mvp: 0, optimize: 0, grow: 0, scale: 0})

  React.useEffect(() => {
    calculateAmount()
    if (allVp.length > 0 && !init2) {
      calculateAmount()
      setInit2(!init2)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVp]); 

  const calculateAmount = () => {
    let obj = {ideation: 0, customerVal: 0, mvp: 0, optimize: 0, grow: 0, scale: 0}
    allVp.forEach(item => (
      obj[item.currentStage] = obj[item.currentStage] + 1
    ))
    setAmount({...obj})
  }

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      GetValueProps(setValueProps, setValuePropsBackup);
      vpApi.getManyVp(setAllVp, setAllVpBackup)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]); 

   

  return (
    <>
      <div class="px-3 pt-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
        <FilterDeck string={string} setString={setString} valueProps={allVp} setValueProps={setAllVp} valuePropsBackup={allVpBackup}/>
         
        <div class="card mb-3">
          <div class="card-header pb-1 bg-light">
            <div class="row flex-between-center">
              <div class="col-auto col-sm-6 col-lg-7">
                <h6 class="mb-0 text-nowrap py-2 py-xl-0">Stage Gating Overview</h6>
                  <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
                    <i class="fas fa-info-circle mr-1" />
                    Quick guide & legend
                  </div>
                </div> 
              </div>
            </div>
            <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
                <div class="p-card rounded fs--2">
                  Use the overview below to gauge the progress of each value proposition, seperated by their current stage.
                  <br/>
                  The number next to each stage indicates the amount of VP's currently in this stage.
                  <br/> 
                  The Red/Blue dot next to the name indicates the business type of the VP, red meaning a Core business type, blue indicating a Beyond the Core business type.
                  <br/>
                  The progress bar underneath each VP indicates how long this VP has resided in its current stage, displayed in weeks: 
                  <br/>
                    - a Green progess bar indicates the VP has spent less than 80% of the alotted time in this stage.
                  <br/>
                    - a Orange progress bar indicates the VP has spent between 80 and 100% of the alotted time in this stage.
                  <br/>
                    - a Red progress bar indicates the VP has spent more than the alotted time in this stage. 
                  <br/>
                  Click on the name of a VP to open the corresponding card to show more details.
                  <br/>
                  Click on the chevron next to KPI to display this VP's selected Highlight KPI's. To select a highlight KPI, navigate to the VP overview page and use the "Highlight" checkbox to select up to 4 KPI's.

                </div>
              </div>
              <div class="card-footer p-0"> 
                <div className=' ' style={{
                  display: "flex",
                  justifyContent: 'space-around',
                  minHeight: '500px'
                }}>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`Ideation (${amount.ideation})`}
                    </div>
                    {/* <div>
                      {dataObj.ideation.map(item => (
                        <SwimmingLaneObject input={item}/> 
                      ))}
                    </div> */}
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "ideation" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`Customer Validation (${amount.customerVal})`} 
                    </div>
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "customerVal" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`MVP (${ amount.mvp })`} 
                    </div>
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "mvp" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`Optimize (${amount.optimize })`} 
                    </div>
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "optimize" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`Grow (${amount.grow})`} 
                    </div>
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "grow" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                  <div className=''
                    style={{
                      width: "17.5%",
                      backgroundColor: '',
                      borderRight: '1px solid #e2e3e5'
                  }}>
                    <div className='text-center py-2 font-weight-bold fs--1' style={{
                      backgroundColor: '#f9fafd',
                    }}>
                      {`Scale (${amount.scale })`} 
                    </div>
                    <div>
                      {!!allVp && 
                        allVp.map(item => (
                          item.currentStage === "scale" &&
                            <SwimmingLaneObject input={item} api={vpApi}/> 
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div style={{height: '1000px'}}></div>

      </div>
    </>
  );
};

export default SwimmingLanes;
