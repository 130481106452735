export const valuePropositionList = [
    {
      label: 'Value Prop One',
      value: 'Value Prop One'
    },
    {
      label: 'Value Prop Two',
      value: 'Value Prop Two'
    },{
      label: 'Value Prop Three',
      value: 'Value Prop Three'
    },
]