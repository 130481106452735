import * as React from "react"

function VBToolkitLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2095.24 2949.37"
      {...props}
    >
      <defs>
        <style>{".cls-1{fill:#22adfe;fill-rule:evenodd}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M1472 2430.66a102.68 102.68 0 01-50.29-106.07 120.4 120.4 0 0024.29 57.24 121.64 121.64 0 0031 28.15c1.53 1 3.07 1.91 4.64 2.81s3 1.68 4.5 2.47a123.32 123.32 0 00106.94 3.42 104.58 104.58 0 01-121 12m-424.33 317.09c341.14-79.95 622.57-280.19 809.25-571.33a172.24 172.24 0 00-238.65 35.56 168.62 168.62 0 001.36 204.54 168.69 168.69 0 01-26.13-219.43 121.16 121.16 0 00-29.71-9.06 177.11 177.11 0 0011.7-63.46c0-95.34-77.35-178.86-180.41-178.86a181 181 0 00-81.24 19.11c-58.83 29.46-99.19 89.94-99.19 159.75a179.85 179.85 0 001.73 24.83 177.33 177.33 0 01-8.37-54c0-63.86 33.77-119.92 84.58-151.55-15.13-15.44-29.69-33.71-43.68-54.23C1146.37 1739 1075.1 1467 1047.62 1304.47zM846.25 1889.6c-32.23-87.23-116.71-149.48-215.86-149.48a230.06 230.06 0 00-136.5 44.55 154.23 154.23 0 00-103.42-39.54c-85.09 0-154.06 68.37-154.06 152.72 0 2.6.06 5.19.2 7.75a112.43 112.43 0 00-39.42-17.44l-.14 219.58q19.8 35.13 41.32 68.68A172.24 172.24 0 01476.89 2212a168.58 168.58 0 01-1.36 204.54 168.71 168.71 0 0026.14-219.43 120.9 120.9 0 0129.71-9.06 176.9 176.9 0 01-11.71-63.46c0-95.34 77.35-178.86 180.42-178.86a181 181 0 0181.23 19.11c58.83 29.47 99.19 89.94 99.19 159.75a178.31 178.31 0 01-1.73 24.83 177.33 177.33 0 008.38-54c0-63.86-33.78-119.92-84.58-151.55 15.12-15.44 29.69-33.71 43.67-54.23"
          />
          <path
            d="M1464.85 1740.12c-99.14 0-183.63 62.25-215.86 149.48 14 20.52 28.55 38.79 43.68 54.23-50.81 31.63-84.58 87.69-84.58 151.55a177.33 177.33 0 008.37 54 179.85 179.85 0 01-1.73-24.83c0-69.81 40.36-130.29 99.19-159.75a181 181 0 0181.24-19.11c103.06 0 180.41 83.52 180.41 178.86a177.11 177.11 0 01-11.7 63.46 121.16 121.16 0 0129.71 9.06 168.69 168.69 0 0026.13 219.43 168.62 168.62 0 01-1.36-204.54 172.24 172.24 0 01238.52-35.56q21.51-33.54 41.32-68.68l-.14-219.58a112.43 112.43 0 00-39.42 17.44c.14-2.56.21-5.15.21-7.75 0-84.35-69-152.72-154.06-152.72a154.25 154.25 0 00-103.43 39.54 230 230 0 00-136.5-44.55"
            fill="#018ede"
            fillRule="evenodd"
          />
          <path
            d="M1199 234.4c-38.53-99.09-90.59-180.07-151.38-234.4v234.4zm-151.42 959h129.92l-20.58-101.73h29.33q8.14-18.69 15.6-38.35l234.77 88.61c4.13 44.13 10.91 72.92 18.58 72.92 12.6 0 22.81-77.73 22.81-173.62S1467.8 867.6 1455.2 867.6c-6.39 0-12.16 20-16.3 52.21l-173.68-145.89c4-41 6.15-83.3 6.15-126.44 0-145.24-23.92-280.48-65.07-393.79h-158.68v129.88a123.45 123.45 0 110 246.9z"
            fill="#f60"
            fillRule="evenodd"
          />
          <path
            d="M162.45 626.55C344.85 555.78 566 511 797.4 492.2A1177.76 1177.76 0 01828.48 328C521.19 348.71 227 412.89 1.07 520.29L0 2157.51c201.39 386.06 557.54 691.63 1047.62 791.86 490.08-100.23 846.23-405.8 1047.62-791.86l-1.07-1637.22c-225.88-108-520.14-172-827.47-192.48A1175.69 1175.69 0 011297.81 492c231.43 18.55 452.63 63.29 635 134.37l1 1490.55c-190.86 343.71-502 579.62-886.15 667.32-384.12-87.7-695.29-323.61-886.15-667.32z"
            fill="#244065"
            fillRule="evenodd"
          />
          <path
            d="M623.3 2430.66a102.68 102.68 0 0050.29-106.07 120.4 120.4 0 01-24.29 57.24 121.81 121.81 0 01-31 28.15q-2.3 1.45-4.65 2.81t-4.5 2.47a123.3 123.3 0 01-106.93 3.42 104.58 104.58 0 00121 12m424.32 317.09c-341.13-79.95-622.57-280.19-809.24-571.33A172.23 172.23 0 01476.89 2212a168.58 168.58 0 01-1.36 204.54 168.68 168.68 0 0026.14-219.43 121.16 121.16 0 0129.71-9.06 177.11 177.11 0 01-11.7-63.46c0-95.34 77.34-178.86 180.41-178.86a181 181 0 0181.23 19.11c58.84 29.46 99.19 89.94 99.19 159.75a179.86 179.86 0 01-1.72 24.83 177.64 177.64 0 008.37-54c0-63.86-33.77-119.92-84.58-151.55 15.12-15.44 29.69-33.71 43.67-54.23C948.88 1739 1020.14 1467 1047.62 1304.47z"
            fill="#5fc1ff"
            fillRule="evenodd"
          />
          <path
            d="M896.2 234.4C934.77 135.31 986.83 54.33 1047.62 0v234.4zm151.42 959H917.74l20.57-101.73H909q-8.15-18.69-15.6-38.35l-234.77 88.61c-4.13 44.13-10.91 72.92-18.58 72.92-12.6 0-22.81-77.73-22.81-173.62S627.44 867.6 640 867.6c6.39 0 12.16 20 16.3 52.21L830 773.92c-4-41-6.15-83.3-6.15-126.44 0-145.24 23.91-280.48 65.07-393.79h158.68v129.88a123.45 123.45 0 100 246.9z"
            fill="#ff8d41"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}

export default VBToolkitLogo
