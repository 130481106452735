export const pageDataEmpty =
    {
      "name":"",
      "currentStage": "ideation",
      "archived": false,
      "geography":"",
      "channel":"",
      "businessType": "",
      "division":"",
      "painPoint":"",
      "description":"",
      "strategy":"",
      "funding": {
        ideation: 0,
        customerVal: 0,
        mvp: 0,
        optimize: 0,
        grow: 0,
        scale: 0
      },
      "eventHistory": [],
      "team":[{name: '', fte: '', email: ''}],
      "sponsors":[],
      "investment":"",
      "pluggedIntoBox": undefined,
      "valuePropositionName": "",
      "species": [],
      "sovDescription": {"intrinsicDescr":"","engagementDescr":"","dataDescr":"","routeDescr":""},
      "metrics":[{
          "sov": "Intrinsic",
          "kpi": "nsv",
          "unit": "-",
          "y1": "",
          "y2": "",
          "y3": ""
        },
        {
          "sov": "Route To Scale",
          "kpi": "tam",
          "unit": "-",
          "y1": "",
          "y2": "",
          "y3": ""
        },
        {
          "sov": "Data",
          "kpi": "ppp",
          "unit": "-",
          "y1": "",
          "y2": "",
          "y3": ""
        }
      ],
    "dictMetrics": [],  
    "valueModel": {
      intrinsic: "",
      engagement: "",
      data: "",
      routetoscale: "",
    },
    "keyLearnings":"",
    "nextSteps":"",
    "files":[],
    "nextStepsActionPlan": "",
    "nextStepsOutcomes": "",
    "nextStepsFundingAllocation": "",
    "stageGate": {
      "ideation": {
        "intrinsic": {
          "nsv": null,
          "gmac": null,
          "cac": null,
          "ltv": null,
          "ltvcac": null,
          "ctr": null,
          "session": null,
          "bcr": null,
          "aov": null
        },
        "engagement": {
          "rp": null,
          "tc": null,
          "rr": null,
          "nps": null
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      },
      "customerVal": {
        "intrinsic": {
          "nsv": null,
          "gmac": null,
          "cac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ltv": null,
          "ltvcac": null,
          "ctr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "session": null,
          "bcr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "aov": null
        },
        "engagement": {
          "rp": null,
          "tc": null,
          "rr": null,
          "nps": null
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      },
      "mvp": {
        "intrinsic": {
          "nsv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "gmac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "cac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ltv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ltvcac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ctr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "session": null,
          "bcr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "aov": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "engagement": {
          "rp": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "tc": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "rr": null,
          "nps": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      },
      "optimize": {
        "intrinsic": {
          "nsv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "gmac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "cac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ltv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ltvcac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ctr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "session": null,
          "bcr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "aov": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "engagement": {
          "rp": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "tc": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "rr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "nps": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      },
      "grow": {
        "intrinsic": {
          "nsv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "gmac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "cac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ltv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ltvcac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": true,
            "active": true,
            "trend": 2
          },
          "ctr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "session": null,
          "bcr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "aov": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "engagement": {
          "rp": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "tc": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "rr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "nps": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      },
      "scale": {
        "intrinsic": {
          "nsv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "gmac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "cac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ltv": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ltvcac": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "ctr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "session": null,
          "bcr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "aov": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "engagement": {
          "rp": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "tc": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "rr": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          },
          "nps": {
            "target": "",
            "benchmark": "",
            "actual": "",
            "comment": "",
            "modelled": false,
            "active": true,
            "trend": 2
          }
        },
        "data": {
          "dvam": null,
          "ppp": null,
          "cda": null
        }
      }
    }
    }

