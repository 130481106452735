import React, {  useState } from 'react';
import { Row, Col } from 'reactstrap';
import { updateBrand, updateVP } from '../../components/API/BrandsTableAPI';
import { GoogleMaps } from '../../components/Cards/GoogleMaps';
import WorldMap from '../../components/Cards/Worldmap';
import { InputFieldDiscover } from '../../components/Input/InputFieldDiscover';
import { InputFieldDiscoverProduct } from '../../components/Input/InputFieldDiscoverProduct';
import { InputFieldDiscoverSpecies } from '../../components/Input/InputFieldDiscoverSpecies';
//import { Link } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';


const CompanyProfile = ( { brand, setBrand, social, traffic, selectedBrand } ) => {
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const switchAction = () => {
    
    if(checked){
      if(brand.showDiscover) {
        console.log(`VP brand`)
        updateVP(brand);
      }
      else{
        updateBrand(brand)
      }
    }

    setChecked(!checked);
    // let action = vpHandler.action
    // // if(action === "add") {
    // //   if(vpHandler.get.name !== "") api.addVP(vpHandler)
    // //   else api.notify("Insert name before saving.", vpHandler.ref, "danger")}
    // if(action === "view") {
    //   setChecked(true)
    //   //vpHandler.history.push(`/monitor/individual/edit/${vpHandler.get.uuid}`)
    // }

    // if(action === "edit") {
    //     setChecked(false)
    //     vpHandler.history.push(`/monitor/individual/view/${vpHandler.get.uuid}`)
    // }
  }  
  const [hide, setHide] = useState(false);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openFacebook = (handle) => {
    let url = `http://facebook.com/${handle}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openTwitter = (handle) => {
    let url = `http://twitter.com/${handle}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openInstagram = (handle) => {
    let url = `http://instagram.com/${handle}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openYoutube = (handle) => {
    let url = `http://youtube.com/channel/${handle}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

const checkProduct = (brand) => {
  if(brand.mmi) return "Main Meal - Individualized"
  if(brand.mmni) return "Main Meal - Not Individualized"
  if(brand.cts) return "Care, Treats, Supplements"
  if(brand.mtt) return "Monthly Treats & Toys"
  return "N/A"
}

const getSpecies = (_brand) => {
  //console.log(_brand.species)
  if(_brand.species !== undefined){
    if(_brand.species.length > 1) return "Cat, Dog"
    else if(_brand.species.length == 1) {
      if(_brand.species.includes("dog")) return "Dog"
      if(_brand.species.includes("cat")) return "Cat"
    }
    // if(_brand.species.includes("dog") && !brand.species.includes("cat")) return "Dog"
    // if(!_brand.species.includes("dog") && brand.species.includes("cat")) return "Cat"
    // if(!_brand.species.includes("dog") && !brand.species.includes("cat")) return "Unknown"
    // if(_brand.species.includes("dog") && brand.species.includes("cat")) return "Cat, Dog"
  }
  else return "Unknown"
}

const checkBrandName = () => {
  return (brand.brandName==undefined && brand.name==undefined)
}

  return (
    <>
      {brand!=={}&&
      <div class="card mb-3">
        <div
          class="card-header pb-1 bg-light"
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <h6 className="card-title mb-0 pb-0 pt-1">{`Company profile: ${brand.brandName || brand.name} `}
            <div class="toggle-switch ml-2">
              <input 
                checked={checked}
                type="checkbox" 
                onChange={e=>switchAction()} 
                class="toggle-switch-checkbox" 
                name="toggleSwitch" 
                id="toggleSwitch" 
              />
              <label class="toggle-switch-label" for="toggleSwitch">
                <span class="toggle-switch-inner"></span>
                <span class="toggle-switch-switch"></span>
              </label>
            </div>
          </h6>

          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
        <div class={`${hide ? 'collapse' : ''} card-body`}>
          <div class="row">
            <div class="pt-2 col-md-4">
              <h6 class="fs--2">Website (click image to launch)</h6>
              {!checked?
                <div className="hover-primary hoverhand" onClick={e=>openInNewTab(brand.url)}>
                  <img
                    alt=""
                    //src={require(`../../assets/img/${company.websiteimg}`)}
                    width="100%"
                    src={`/screenshots/${encodeURIComponent(brand.cid)}.png`}
                    //to={`/insight/brandoverview/${encodeURIComponent(brand._id)}.png`}
                  />
              </div>:
                <InputFieldDiscover value={"url"} title="URL" brand={brand} setBrand={setBrand}/>
              }
            </div>
            <div class="pt-2 col-md-4">
              <h6 class="fs--2">Global presence</h6>
              {!checked ?
                <WorldMap followingCompanies={[brand]} style={{ marginTop: '-10px' }} /> :
                <InputFieldDiscover value={"country"} title="Presence" brand={brand} setBrand={setBrand}/>
              }

            </div>
            <div class="pt-2 col-md-4">
              <h6 class="fs--2">Office location</h6>
              {!checked?
                <>
                  <GoogleMaps address={brand.companyInfo? brand.companyInfo.address : brand.address} selectedBrand={selectedBrand}/>
                  <h6 className="mars-blue fs--2">{brand.companyInfo? brand.companyInfo.address : brand.address}</h6>
                </> :
                <InputFieldDiscover value={"address"} title="Address" brand={brand} setBrand={setBrand} companyInfo={true}/>}   
            </div> 
          </div>

          <div className="dropdown-divider mt-2" />

          <div class="row">
            <div class="col-md-4">
              <h6 class="fs--2 pb-3">Social media links</h6>
              <Row>
                {!checked?
                  <Col lg="6">
                    {brand.facebookHandle? (
                      <div class="table-cell mars-blue hoverhand" onClick={e => openFacebook(brand.facebookHandle)}>
                        <i class="fab fa-facebook mr-2" />
                        Facebook
                      </div>
                    ) : (
                      <div class="table-cell text-normal mt-2">
                        <i className="fab fa-facebook mr-2" />
                        Facebook
                      </div>
                )}
                  </Col> :
                  <Col lg="6">
                    <InputFieldDiscover value={"facebookHandle"} title="Facebook" brand={brand} setBrand={setBrand}/>
                  </Col>
                }
                {!checked?
                  <Col lg="6">
                    
                    {brand.instaHandle? (
                      <div class="table-cell mars-blue hoverhand" onClick={e => openInstagram(brand.instaHandle)}>
                        <i class="fab fa-instagram mr-2" />
                        Instagram
                      </div>
                    ) : (
                      <div class="table-cell  text-normal mt-2">
                        <i className="fab fa-instagram mr-2" />
                        Instagram
                      </div>
                    )}
                  </Col> :
                  <Col lg="6">
                    <InputFieldDiscover value={"instaHandle"} title="Instagram" brand={brand} setBrand={setBrand}/>
                  </Col>
                }
              </Row>
              <Row>
              {!checked?
                  <Col lg="6">
                    {brand.twitterHandle? (
                      <div class="table-cell mars-blue hoverhand" onClick={e => openTwitter(brand.twitterHandle)}>
                        <i class="fab fa-twitter mr-2" />
                        Twitter
                      </div>
                    ) : (
                      <div class="table-cell text-normal mt-2">
                        <i className="fab fa-twitter mr-2" />
                        Twitter
                      </div>
                    )}
                  </Col> :
                  <Col lg="6">
                    <InputFieldDiscover value={"twitterHandle"} title="Twitter" brand={brand} setBrand={setBrand}/>
                  </Col>
                }
                {!checked?
                  <Col lg="6">
                    {brand.youtubeHandle? (
                      <div class="table-cell mars-blue hoverhand" onClick={e => openYoutube(brand.youtubeHandle)}>
                        <i class="fab fa-youtube mr-2" />
                        YouTube
                      </div>
                    ) : (
                      <div class="table-cell text-normal mt-2">
                        <i className="fab fa-youtube mr-2" />
                        YouTube
                      </div>
                    )}
                  </Col> :
                  <Col lg="6">
                    <InputFieldDiscover value={"youtubeHandle"} title="YouTube" brand={brand} setBrand={setBrand}/>
                  </Col>
                }
              </Row>
            </div>
            <div class="pt-2 col-md-4">
              <h6 class="fs--2 ">Profile</h6>
              <p class="text-normal" style={{ marginTop: '', marginBottom: '8px' }}>
                {!checked?
                  <><i className="fas fa-rocket mr-2" />
                  <span>{`Company launch: ${brand.brandStartDate ? brand.brandStartDate : "Unknown"}`}</span></>:
                <InputFieldDiscover value={"brandStartDate"} title="Launch Date" brand={brand} setBrand={setBrand}/>
                }
              </p>
              <p class="text-normal" style={{ marginTop: '8px', marginBottom: '8px' }}>
                {!checked?
                  <><i className="fas fa-boxes mr-2" />
                  <span>{`Product: ${checkProduct(brand)}`}</span></>:
                  //<InputFieldDiscover value={checkProduct(brand)} title="Product"/>
                  <InputFieldDiscoverProduct brand={brand} setBrand={setBrand}/>
                }
              </p>
              <p class="text-normal" style={{ marginTop: '8px', marginBottom: '8px' }}>
                {!checked?
                  <><i className="fas fa-paw mr-2" />
                  <span>{`Species: ${getSpecies(brand)}`}</span></>:
                  //<InputFieldDiscover value={getSpecies(brand)} title="Species"/>
                  <InputFieldDiscoverSpecies brand={brand} setBrand={setBrand}/>
                }
              </p>
            </div>
            <div class="pt-2 col-md-4">
              <table class="table table-sm fs--2">
                <thead class="bg-200 text-900">
                  <tr>
                    <TableCell class=" ">
                      <div className="fs--2" style={{fontWeight: "600"}}>Description</div>
                    </TableCell>
                    <TableCell align={"right"}>
                      <div className="fs--2" style={{fontWeight: "600"}}>Value</div>
                    </TableCell>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                  <tr>
                  <TableCell align={"left"} style={{fontSize: '11px'}}>Turnover ($)</TableCell>
                    {!checked? 
                      <TableCell align={"right"}  style={{fontSize: '11px'}}>
                        {brand.companyInfo? brand.companyInfo.revenue : brand.revenue}M
                      </TableCell>:
                      <TableCell align={"right"} style={{fontSize: '11px'}}>
                        <InputFieldDiscover value={"revenue"} title="Revenue" brand={brand} setBrand={setBrand} companyInfo={true}/>
                      </TableCell>
                    }
                  </tr> 
                  <tr>
                    <TableCell align={"left"} style={{fontSize: '11px'}}>Turnover year</TableCell>
                    {!checked? 
                      <TableCell align={"right"}  style={{fontSize: '11px'}}>
                        {brand.companyInfo? brand.companyInfo.revenueDescription : brand.revenueDescription} 
                      </TableCell>:
                      <TableCell align={"right"} style={{fontSize: '11px'}}>
                        <InputFieldDiscover value={"revenueDescription"} title="Revenue Description" brand={brand} setBrand={setBrand} companyInfo={true}/>
                      </TableCell>
                    }
                  </tr> 
                  <tr>
                  <TableCell align={"left"} style={{fontSize: '11px'}}>Website visitors (#)</TableCell>
                    {!checked?
                      <TableCell align={"right"} style={{fontSize: '11px'}}>{traffic!==null&&traffic.visitsZero}M</TableCell>:
                      <td>
                        {//<InputFieldDiscover value={traffic!==null&&traffic.visitsZero} title="Traffic"/>
                        } 
                      </td>
                    }
                  </tr>
                  <tr>
                  <TableCell align={"left"} style={{fontSize: '11px'}}>Social media followers (#)</TableCell>
                    {!checked?
                      <TableCell align={"right"} style={{fontSize: '11px'}}>{social!==null&&social.totalFollowersZero}M</TableCell>:
                      <td>
                        {//<InputFieldDiscover value={social!==null&&social.totalFollowersZero} title="Traffic"/>
                        }
                      </td>
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    }</>
  );
};

export default CompanyProfile;
