import React from "react";

import corner1 from './../../../assets/img/icons/spot-illustrations/corner-1.png';
import corner2 from './../../../assets/img/icons/spot-illustrations/corner-2.png';
import corner3 from './../../../assets/img/icons/spot-illustrations/corner-3.png';
import { valuePropositionList } from "../../Lists/valuePropositionList";
import { GetBrands, GetVPs } from '../../API/BrandsTableAPI';



export const HomepageHeader = (props) => {

  const [vpAmount, setVpAmount] = React.useState(null);
  const [init, setInit] = React.useState(false);
  const [brandAmount, setBrandAmount] = React.useState(null);
  const [allVp, setAllVp] = React.useState([])

  React.useEffect(() => {
    if(!init){
      setInit(!init)
      GetBrands(setBrandAmount)
      GetVPs(setAllVp);
    }
  }, [init]);

  React.useEffect(() => {
    let arr = allVp.filter(item => item.geography !== "TestCountry")
    setVpAmount([...arr])
  }, [allVp]);
  
  
  React.useEffect(() => {
    console.log(`brandAmount: ${brandAmount !== null&& brandAmount.length}`)
  }, [brandAmount]);

  return(
    
    <div class="row g-3 mb-3">
      <div class="col-sm-6 col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div 
            class="bg-holder bg-card" 
            style={{backgroundImage: `url(${corner1})`}}
            >
          </div>

          <div class="card-body position-relative">
            <h6>Value Propositions</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" data-countup='{"endValue":3,"decimalPlaces":0,"suffix":""}'>
              {42}
            </div>
            <a class="mars-blue fw-semi-bold fs--1 text-nowrap" href="/monitor/starter">
              Manage
              <span class="fas fa-angle-right ms-1 ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div class="bg-holder bg-card" style={{backgroundImage: `url(${corner2})`}}>
          </div>

          <div class="card-body position-relative">
            <h6>Progress</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" data-countup='{"endValue":3,"decimalPlaces":0,"suffix":""}'>
              {"18 / 63"}
            </div>
            <a class="fw-semi-bold fs--1 text-nowrap mars-blue" href="/insight/dashboard">
              Discover
              <span class="fas fa-angle-right ms-1  ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div class="bg-holder bg-card" style={{backgroundImage: `url(${corner3})`}}>
          </div>

          <div class="card-body position-relative">
            <h6>Other Statistic</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif" data-countup='{"endValue":0,"prefix":""}'>
              39%
            </div>
            <a class="mars-blue fw-semi-bold fs--1 text-nowrap" href="/home/learn#playbooks">
              Use
              <span class="fas fa-angle-right ms-1 ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )}