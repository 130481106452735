import React from "react";
import Select from 'react-select'

export const DropdownFieldFilterDeck = ({title, label, list, setState, filterObject, setFilterObject, isMulti, width, minWidth}) => {

  const handleChange = (e) => {  
    //let arr = [] 
    if(!isMulti){
      let obj = filterObject
      obj[title] = e.value
      setFilterObject({...obj})
    }
    else {
      //console.log(`e: ${JSON.stringify(e)}`)
      let obj = filterObject
      let arr = []
      if(e!==null){  
        e.forEach(item => arr.push(item.value))
        obj[`${title}Arr`] = arr
      }
      else obj[`${title}Arr`] = []
      setFilterObject({...obj})
    }
  }

  const getList = () => {
    let array = [];
    let resetObj = {value: "", label: "All"}
    if(!isMulti) array.push(resetObj)

    list.forEach((item, i )=> {
      let object = {
        value: item,
        label: item
      }
      array.push(object);
    })
    
    return array;
  };

  const getValue = (e) => {
    if(filterObject !== null){
      if(isMulti){
        if(filterObject[`${title}Arr`] !== undefined){
          if(filterObject[`${title}Arr`].length === 1) {
            let arr = [
              {
                label: filterObject[`${title}Arr`][0],
                value : filterObject[`${title}Arr`][0]
              }
            ]
            return arr
          }      
          if(filterObject[`${title}Arr`].length > 1) {
            let arr = [
              {
                label: filterObject[`${title}Arr`][0],
                value : filterObject[`${title}Arr`][0]
              },
              {
                label: `+${filterObject[`${title}Arr`].length-1}`,
                value: filterObject[`${title}Arr`][1]
              }
            ]
            return arr
          }
          if(filterObject[`${title}Arr`].length === 0) return null
        }
        else return null 
      }
      else {
        //console.log(filterObject[title])
        if(!!filterObject[title]) return {label: filterObject[title], value: filterObject[title]}
        else return null
      }
    }
    else return null 
  }

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
      height: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return(  
      <div className="fs--2 ml-2 mt-1 z-20 relative" style={{maxWidth: `${width}%`, minWidth: `140px`, position: 'relative', marginTop: '', zIndex:'10' }} >
        <Select 
          isMulti={isMulti}
          isSearchable={false}
          isClearable={false}
          className="" 
          options={getList()}
          placeholder={`${label || title}`}
          autosize={true}
          onChange={(e) => handleChange(e)}
          value={getValue()}
          styles={styles}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
      </div>
  )
}