export const demoVPArrayInactive = [
  {
    "uuid": "631a1082cb7220d122ff8301",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Translink",
    "highlightKPI": [
      "nsv",
      "gmac",
      "tc"
    ],
    "geography": "India",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Reprehenderit tempor aute sit do cillum ex commodo occaecat tempor. Reprehenderit non dolore laboris ullamco deserunt exercitation ipsum nulla qui incididunt sit in esse laborum. Non in minim excepteur occaecat sunt sit culpa sit ipsum elit dolor do. Aute magna aliqua sunt aliquip voluptate veniam cupidatat ex aliqua mollit incididunt aliquip.\r\n",
    "description": "Irure mollit labore mollit consectetur reprehenderit magna Lorem. Cupidatat dolor proident adipisicing non duis nulla ut. Ex occaecat Lorem in tempor. Pariatur nostrud sunt magna velit occaecat ipsum nulla.\r\n",
    "strategy": "Mollit sunt sint ullamco voluptate nisi quis. Fugiat commodo mollit irure do ex excepteur dolore minim do. Pariatur duis veniam Lorem sint voluptate. Dolore consectetur tempor eiusmod sint officia do cupidatat aliqua amet fugiat irure laboris aliquip.\r\n",
    "keyLearnings": "Aute consectetur cupidatat incididunt eiusmod laborum fugiat tempor exercitation sit laboris voluptate eiusmod. Nostrud dolor dolore proident ipsum cupidatat nulla Lorem anim adipisicing ut duis tempor anim ea. Anim ea aliquip et adipisicing ea excepteur pariatur officia commodo. Esse ut sit proident nisi magna quis do Lorem in. Dolore sint sunt ad tempor nulla aliqua voluptate duis. Laborum proident ea duis aute occaecat. Sit deserunt laborum reprehenderit ut labore et Lorem consequat exercitation deserunt.\r\n",
    "nextSteps": "Veniam exercitation tempor laborum excepteur. Mollit ea ex sint laboris sunt cupidatat voluptate eu veniam tempor veniam. Lorem adipisicing veniam officia aliqua anim reprehenderit ad culpa laborum. Ut enim sunt ad sunt enim nostrud ut aliquip elit magna. Culpa quis irure pariatur ut dolor qui exercitation labore aliquip labore id qui.\r\n",
    "investment": "Incididunt laboris ut pariatur Lorem ipsum est. Anim eiusmod fugiat mollit in mollit nisi voluptate occaecat laboris velit deserunt dolor eu ex. Dolor elit aute aute voluptate esse. Ullamco do laboris aliquip aute enim. Dolore eiusmod do tempor in quis esse. Irure aliqua ex tempor cupidatat fugiat ut ipsum ad duis duis.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Nostrud sunt non consectetur deserunt irure amet. Tempor dolore cillum ea eiusmod voluptate cupidatat commodo do sint. Dolor exercitation aliquip pariatur voluptate esse ex. Deserunt proident eiusmod velit reprehenderit eu ea proident est qui et pariatur aute in. Commodo incididunt officia laboris nostrud non pariatur.\r\n",
      "engagementDescr": "Dolor dolor aliquip qui aliquip minim exercitation incididunt irure qui cupidatat do mollit ea nisi. Sint ad sint pariatur ex ex dolore. Culpa incididunt cillum aute cillum pariatur consectetur proident ullamco consequat occaecat deserunt. Exercitation deserunt laboris irure nulla do proident voluptate. Et nisi laborum dolor nostrud proident anim consectetur ad cillum nisi nisi enim fugiat.\r\n",
      "dataDescr": "Dolor commodo ipsum minim magna amet qui duis officia eu cillum dolore. Qui anim anim eu ut labore cillum Lorem anim consequat minim ad. Magna magna enim qui velit. Ut exercitation pariatur nostrud cupidatat velit do est nostrud minim quis magna proident. Excepteur fugiat id quis nostrud irure ullamco Lorem officia commodo culpa. Cupidatat adipisicing minim ea dolor pariatur elit exercitation qui ad pariatur Lorem.\r\n",
      "routeDescr": "Id velit sit laboris culpa magna anim culpa magna et commodo reprehenderit adipisicing officia cillum. Excepteur ex culpa ipsum duis elit quis in. Nostrud laborum labore pariatur qui. Adipisicing do qui ipsum esse amet laborum nisi incididunt commodo eiusmod. Est eu laboris nulla cupidatat eu ad excepteur exercitation anim anim non sunt. Nulla sunt veniam eu veniam deserunt veniam excepteur consequat labore esse adipisicing.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Manuela Perry",
        "email": "manuelaperry@translink.com"
      },
      {
        "id": 1,
        "name": "Georgette Hardin",
        "email": "georgettehardin@translink.com"
      },
      {
        "id": 2,
        "name": "Berger Rocha",
        "email": "bergerrocha@translink.com"
      },
      {
        "id": 3,
        "name": "Leticia Mueller",
        "email": "leticiamueller@translink.com"
      },
      {
        "id": 4,
        "name": "Davis Morrison",
        "email": "davismorrison@translink.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Bowers Hutchinson",
        "email": "bowershutchinson@translink.com"
      },
      {
        "id": 1,
        "name": "Rosales Gallegos",
        "email": "rosalesgallegos@translink.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 14,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 54,
        "y3": 95
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 22,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5461607,
      "customerVal": 778686,
      "mvp": 62221039,
      "optimize": 776710,
      "grow": 862972490,
      "scale": 197327569
    },
    "nextStepsActionPlan": "Non consectetur occaecat nisi enim mollit elit in minim nulla eiusmod eiusmod officia. Enim minim nostrud aliquip pariatur. Ea et consectetur pariatur quis sunt esse cillum veniam magna eiusmod. Pariatur consequat quis do culpa consequat velit velit aute quis. Nisi nostrud est sit officia ex cupidatat enim in cupidatat dolor aliqua incididunt dolore id. Do dolore nulla ipsum nulla id commodo non officia ea ex amet.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Eiusmod excepteur quis officia anim tempor dolore. Nostrud proident incididunt officia minim Lorem minim minim reprehenderit commodo. Deserunt esse excepteur laborum duis.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1082b9f91b92ac75aca9",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Prowaste",
    "highlightKPI": [
      "cac",
      "gmac",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Ut qui veniam qui dolor ad fugiat enim velit. Sunt anim consectetur amet exercitation ea mollit labore nostrud in quis. Adipisicing excepteur velit officia pariatur.\r\n",
    "description": "Amet anim incididunt Lorem quis nulla in nisi dolor nisi consectetur est quis. Velit Lorem eu id commodo velit occaecat occaecat consequat esse laboris ut. Commodo exercitation anim sint Lorem dolore proident aliqua tempor ipsum. Dolore officia culpa ex enim nisi quis fugiat ipsum consequat.\r\n",
    "strategy": "Amet ut minim officia eu minim velit. In eu culpa officia aute ex aute eu culpa labore culpa dolore consequat anim. Est consectetur cillum do reprehenderit sit occaecat voluptate non aliquip sint et. Aliquip sit aliquip commodo velit adipisicing proident reprehenderit adipisicing. Culpa ullamco tempor ut ex nisi mollit dolor.\r\n",
    "keyLearnings": "Ullamco occaecat sint sunt aute ea pariatur sint incididunt. Minim sit tempor pariatur aute labore et labore est labore nostrud dolore. Labore officia adipisicing aliqua est aute. Tempor occaecat qui nulla ex. Exercitation Lorem nisi mollit ut dolore Lorem consectetur velit velit amet commodo excepteur sint. Exercitation minim exercitation adipisicing ex eu veniam aliqua sunt id dolore commodo.\r\n",
    "nextSteps": "Sint do dolore ullamco eu. Ad cupidatat ex deserunt minim quis nisi sint pariatur fugiat do enim ea. Sunt fugiat irure id amet et voluptate in est Lorem ut Lorem irure. Dolore magna et dolor voluptate nostrud. Sunt elit magna nulla exercitation reprehenderit qui ullamco dolor voluptate mollit. Sit commodo eiusmod in adipisicing id aliqua duis minim deserunt eu deserunt. Sit ex mollit proident est laborum id amet magna duis.\r\n",
    "investment": "Eiusmod voluptate ad est officia dolore ea nulla est velit qui deserunt laborum. Do tempor amet consequat sit pariatur nisi duis id reprehenderit officia aliqua in. Laborum ullamco culpa sint consectetur veniam minim nostrud proident id voluptate veniam. Anim mollit incididunt excepteur ex eiusmod esse dolor aliquip et laborum in aliqua reprehenderit do.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Eu eu dolore proident commodo nostrud id eu esse. Occaecat consequat aute ad amet mollit qui occaecat minim occaecat laborum culpa nulla voluptate. Tempor irure officia ea cillum dolor do aute laborum fugiat ipsum quis adipisicing. Commodo Lorem quis aliqua non sint consequat. Nisi laborum ullamco non labore duis eiusmod. Ea incididunt veniam velit nisi enim officia do sint sint commodo proident nisi culpa. Reprehenderit nisi incididunt labore incididunt est.\r\n",
      "engagementDescr": "Adipisicing ut sit pariatur duis adipisicing ea. Proident pariatur culpa deserunt nisi. Et ex duis labore quis id. Lorem do dolor dolor labore nulla excepteur irure laborum cupidatat aliquip minim sunt minim.\r\n",
      "dataDescr": "Quis amet dolor dolore dolore in duis esse amet laborum ad id ea consequat fugiat. Excepteur amet aliqua ut irure anim aute Lorem consequat. Eiusmod deserunt in aliqua et nostrud nostrud nostrud mollit qui Lorem aute sint do et. Adipisicing adipisicing culpa adipisicing magna velit.\r\n",
      "routeDescr": "Officia duis ut adipisicing proident mollit proident pariatur excepteur. Excepteur do sit Lorem pariatur esse sint veniam anim laboris mollit. Nisi enim in officia aute eiusmod sit qui voluptate pariatur ad.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Landry Page",
        "email": "landrypage@prowaste.com"
      },
      {
        "id": 1,
        "name": "Winifred Hoffman",
        "email": "winifredhoffman@prowaste.com"
      },
      {
        "id": 2,
        "name": "Murphy Battle",
        "email": "murphybattle@prowaste.com"
      },
      {
        "id": 3,
        "name": "Joseph Chen",
        "email": "josephchen@prowaste.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Travis Hartman",
        "email": "travishartman@prowaste.com"
      },
      {
        "id": 1,
        "name": "Hale Mason",
        "email": "halemason@prowaste.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 12,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 46,
        "y3": 77
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 16,
        "y3": 96
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 245945,
      "customerVal": 1108731,
      "mvp": 56592891,
      "optimize": 13787537,
      "grow": 1102240780,
      "scale": 1282231728
    },
    "nextStepsActionPlan": "Tempor ullamco dolore culpa non eiusmod deserunt esse. Aliqua dolore ad magna magna laborum minim dolore pariatur. Officia mollit Lorem adipisicing eiusmod eiusmod magna labore eu aliquip sunt ad. Eu commodo laborum cupidatat sunt nostrud duis elit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Eiusmod enim nulla voluptate exercitation ipsum nulla non non excepteur ea nisi. Sunt in adipisicing consequat aliqua aute dolor cupidatat incididunt. Nisi fugiat amet veniam veniam ullamco magna minim sit. Adipisicing elit elit aliqua occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1082421d7b66e70f2157",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Assistia",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Netherlands",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Laboris ut cillum proident mollit qui est ad id commodo ipsum. Velit exercitation consequat sit dolor laborum pariatur. Quis duis quis aute dolore velit qui est consequat sunt. Ex non mollit anim eu quis ut aliquip est voluptate velit nostrud cillum do. Fugiat eiusmod in quis exercitation non incididunt enim tempor do ipsum. Exercitation sit est consectetur esse eiusmod adipisicing fugiat eiusmod dolore mollit quis amet enim.\r\n",
    "description": "Ex pariatur nulla sit Lorem ea minim nostrud irure sit. Elit labore aliqua amet enim sint deserunt nisi irure in officia incididunt. Exercitation velit irure esse cupidatat dolor ipsum aute. Anim eiusmod culpa voluptate sunt ea tempor duis in culpa. Dolore ex tempor officia nostrud est cupidatat ut in voluptate incididunt fugiat est ex eiusmod.\r\n",
    "strategy": "Voluptate ullamco anim pariatur quis laborum anim velit excepteur consectetur commodo eu enim. Veniam anim aliqua sint eu mollit duis est commodo cillum sint. Eu proident commodo aute elit laboris ullamco laboris voluptate ullamco magna. Fugiat sit magna velit veniam. Aliquip sunt incididunt eu minim anim exercitation veniam pariatur. Occaecat dolor magna dolor amet cupidatat sit proident.\r\n",
    "keyLearnings": "Fugiat mollit ut enim officia duis cupidatat in cillum. Ipsum officia Lorem Lorem deserunt sunt non qui nostrud. Elit elit aute ut esse ipsum nulla officia excepteur anim consectetur anim non sint culpa. Exercitation dolore anim ullamco qui sunt incididunt labore culpa magna magna. Adipisicing eu duis cupidatat minim ad sit amet amet dolore esse in in mollit et. Eu eu aliquip est minim culpa incididunt anim culpa quis. In ad labore elit sit mollit eu culpa.\r\n",
    "nextSteps": "Commodo quis sunt do anim nulla deserunt culpa deserunt. Deserunt quis anim magna proident dolore ea laboris duis aliquip. Pariatur ex excepteur mollit ut consectetur proident ipsum excepteur pariatur. Proident exercitation id esse esse nisi tempor dolore excepteur sint aute tempor magna duis consectetur. Deserunt veniam fugiat adipisicing aute. Et culpa veniam non ut veniam qui magna velit magna commodo adipisicing velit. Cillum voluptate mollit consectetur dolor magna anim.\r\n",
    "investment": "Elit ex tempor enim sunt excepteur incididunt exercitation non laborum cupidatat est sint occaecat mollit. Voluptate consequat aute veniam anim veniam sit id veniam. Est anim et dolore anim duis nisi do nulla esse ex sunt do. Non anim voluptate ad fugiat deserunt consectetur sunt dolore. Eiusmod culpa elit sunt ad nisi exercitation.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Est velit aliquip fugiat duis consectetur reprehenderit. Id eiusmod ut aliqua in deserunt nostrud cillum elit culpa eu dolore sit. Ea adipisicing veniam quis fugiat sit id occaecat laboris deserunt excepteur cillum Lorem nisi pariatur. Ipsum nostrud ea aute nisi exercitation ea nisi quis id fugiat nisi et elit est. Voluptate eu exercitation culpa cupidatat officia non reprehenderit. Cupidatat non voluptate id excepteur id. Sunt cillum fugiat magna aliqua aute officia exercitation do qui.\r\n",
      "engagementDescr": "Mollit elit proident ut est ad amet quis fugiat qui voluptate ad amet ex. Velit occaecat minim cupidatat sit amet aliqua aliqua velit id adipisicing consectetur non. Cillum eu Lorem nulla voluptate sunt eu mollit do id enim enim cillum minim incididunt. Aliquip ipsum aliquip tempor sint enim.\r\n",
      "dataDescr": "Consequat dolor laborum minim minim ipsum. Labore minim laboris pariatur consectetur laboris ex tempor. Id ullamco voluptate consectetur voluptate duis non cupidatat voluptate dolore incididunt. Do ad duis fugiat nostrud anim sit veniam. Laborum mollit et Lorem magna reprehenderit cupidatat enim esse culpa enim adipisicing anim magna. Voluptate aliqua sit ut aute deserunt irure sint esse ullamco magna et excepteur.\r\n",
      "routeDescr": "Id tempor laborum pariatur culpa minim dolore irure. Consequat ullamco minim esse officia labore irure mollit ullamco incididunt deserunt eiusmod. Est laborum excepteur et aute aute veniam consequat quis. Veniam sit ex pariatur laboris reprehenderit. Deserunt irure fugiat duis enim quis quis laborum commodo exercitation veniam eu. Ex laborum consectetur et aliquip anim cupidatat irure eu anim eu tempor qui ipsum aliquip. Adipisicing dolor voluptate velit pariatur ad.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Campos Gillespie",
        "email": "camposgillespie@assistia.com"
      },
      {
        "id": 1,
        "name": "Roy Mccarty",
        "email": "roymccarty@assistia.com"
      },
      {
        "id": 2,
        "name": "Janie Nichols",
        "email": "janienichols@assistia.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Ramona Jordan",
        "email": "ramonajordan@assistia.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 20,
        "y3": 19
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 21,
        "y3": 92
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 10,
        "y3": 70
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7482639,
      "customerVal": 925973,
      "mvp": 34392300,
      "optimize": 9991331,
      "grow": 303944463,
      "scale": 443248240
    },
    "nextStepsActionPlan": "Aliqua deserunt nostrud nisi elit minim pariatur. Dolor duis sit cillum sunt. Esse mollit ut laborum et ex nisi voluptate non laborum sit pariatur cillum in.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolore pariatur commodo ad eu excepteur. Aute mollit do est sit. Ad voluptate officia proident incididunt incididunt. Nisi enim dolore magna consequat incididunt est ea fugiat fugiat magna. Lorem duis nisi duis labore eiusmod ex aliquip est tempor irure. Minim labore ut ad culpa ea adipisicing veniam eu tempor Lorem veniam est laborum. Consectetur laborum culpa mollit velit ad.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a10829d548e6f791c9132",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Paprikut",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Subscription",
    "division": "Division Two",
    "painPoint": "Voluptate minim eu elit aute ex amet magna et tempor esse. Dolor ad deserunt in nulla. Ad nisi cupidatat labore magna nisi qui consectetur aute. Ad ad laboris tempor cillum aliquip eiusmod do labore nisi Lorem anim nulla ad aute.\r\n",
    "description": "Laborum eu dolore sint deserunt culpa ex mollit eu est laborum fugiat anim in in. Ipsum esse voluptate consequat magna exercitation reprehenderit velit labore. Incididunt nostrud deserunt ea labore ut in labore proident consequat. Incididunt officia pariatur ea laboris pariatur. Magna id amet ullamco est consectetur et deserunt amet non anim cupidatat nostrud fugiat tempor. Excepteur laborum veniam eu quis nulla aute. Dolore laboris ipsum ad id dolor elit deserunt non duis occaecat aliqua laboris pariatur.\r\n",
    "strategy": "Enim eiusmod tempor minim aliquip labore qui laborum sunt officia velit qui pariatur anim. Eu quis id do cillum est. Eiusmod cillum duis cillum ad enim. Aliquip aute ut ullamco ea quis aliquip adipisicing elit ea Lorem irure commodo veniam ullamco. Commodo Lorem voluptate anim labore eiusmod ex est commodo labore id laborum incididunt fugiat.\r\n",
    "keyLearnings": "Esse officia id velit adipisicing ipsum ut nisi officia eu tempor non excepteur sint quis. Fugiat nisi ex nostrud adipisicing in. Eiusmod aute ut eu labore ad. Nisi esse excepteur nisi sit commodo id cupidatat irure excepteur. Enim elit nisi quis mollit id.\r\n",
    "nextSteps": "Non anim do cupidatat reprehenderit incididunt et consequat commodo nisi sunt Lorem. Anim pariatur non eiusmod aute deserunt et veniam magna reprehenderit amet esse quis. Aute ut est ut ex ut. Nisi sint incididunt irure excepteur aliquip excepteur sit anim laboris officia. Lorem do quis dolor cupidatat aliqua.\r\n",
    "investment": "Veniam duis quis mollit ipsum magna do dolore aliqua magna et sunt aliqua. Amet qui consectetur tempor ex. Incididunt reprehenderit est aliquip tempor ad consequat ipsum consequat irure pariatur Lorem nulla sunt.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Aliquip incididunt elit laborum est nostrud cupidatat eu deserunt commodo eiusmod ipsum dolore. Qui proident duis quis nostrud anim tempor commodo cupidatat laboris. Est reprehenderit nostrud adipisicing do minim aliqua nisi eu reprehenderit nulla irure labore Lorem. Sint exercitation duis reprehenderit dolore culpa Lorem voluptate do.\r\n",
      "engagementDescr": "Nulla consequat sit mollit voluptate pariatur ex commodo amet consectetur dolore velit. Tempor laboris amet nisi non laboris amet aute incididunt laborum mollit veniam do nostrud. Irure velit mollit aliqua ipsum deserunt exercitation cillum incididunt qui voluptate.\r\n",
      "dataDescr": "Est tempor proident deserunt elit consectetur nisi ad pariatur est minim fugiat reprehenderit qui tempor. Ullamco officia commodo aliqua duis exercitation dolore ut eu. Aliquip esse tempor nisi ad amet anim nostrud voluptate excepteur est. Cupidatat irure occaecat non eu minim sit officia commodo aliqua voluptate.\r\n",
      "routeDescr": "Reprehenderit ipsum labore excepteur minim esse mollit duis veniam cupidatat excepteur mollit occaecat. Id ad esse cupidatat Lorem proident sit cupidatat do mollit irure nisi. Mollit officia eu incididunt cillum cillum fugiat. Deserunt aliqua elit culpa deserunt culpa. Id mollit ex do ad consequat adipisicing tempor proident non occaecat mollit labore.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Susanne Lamb",
        "email": "susannelamb@paprikut.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Diane Macdonald",
        "email": "dianemacdonald@paprikut.com"
      },
      {
        "id": 1,
        "name": "Payne Olsen",
        "email": "payneolsen@paprikut.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 18,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 34,
        "y3": 81
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 25,
        "y3": 35
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10003223,
      "customerVal": 846075,
      "mvp": 60956299,
      "optimize": 10833643,
      "grow": 702896414,
      "scale": 95097161
    },
    "nextStepsActionPlan": "Deserunt incididunt minim esse deserunt cillum ipsum labore. Exercitation ipsum tempor ea proident culpa reprehenderit dolore id aliqua nulla cupidatat officia. Adipisicing exercitation aute duis qui dolor laborum minim in. Nulla nisi ad cupidatat nostrud labore ullamco quis do occaecat exercitation tempor. Cillum aute ut tempor ut eu irure adipisicing incididunt qui in laborum.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nostrud enim occaecat dolore cillum tempor mollit sit. Sint et ullamco magna cupidatat irure eu duis minim in Lorem consectetur sit. Irure commodo incididunt pariatur incididunt exercitation officia aliquip ut Lorem quis. Nostrud nisi culpa et labore voluptate voluptate non et elit proident aliquip proident minim et. Aliquip nulla aliqua Lorem dolor voluptate cillum irure incididunt labore officia sint labore cupidatat et.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-17",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1082eb5052db448cf217",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zytrax",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "United States",
    "channel": "Online: Home delivery",
    "division": "Division Two",
    "painPoint": "Voluptate pariatur ea labore reprehenderit incididunt eu dolor ipsum. Sunt in aliquip laborum duis incididunt. Duis ullamco cupidatat ipsum eu.\r\n",
    "description": "Lorem duis deserunt velit adipisicing ipsum irure reprehenderit occaecat duis proident. Deserunt in dolor incididunt fugiat elit veniam irure commodo. Fugiat et pariatur qui enim do irure esse.\r\n",
    "strategy": "Aliqua eu sunt incididunt dolore dolore esse laborum laboris pariatur exercitation eu ad quis. Sint aute incididunt adipisicing aliquip ex commodo in deserunt ad in nostrud reprehenderit aliqua Lorem. Laboris dolore non ut laborum fugiat ut.\r\n",
    "keyLearnings": "Consequat pariatur est Lorem dolor irure et magna occaecat voluptate labore anim reprehenderit. Duis veniam consectetur eiusmod veniam anim ad cupidatat. Et enim eiusmod eiusmod dolor nisi minim ad qui nisi. Dolor dolore aute esse veniam id reprehenderit exercitation eu esse exercitation.\r\n",
    "nextSteps": "Do ullamco enim non aliqua occaecat excepteur sunt excepteur commodo incididunt aliquip nulla irure nostrud. Dolore laboris esse proident proident labore pariatur. Ipsum magna amet minim Lorem ex laborum aliquip. Ipsum aute ipsum ex dolore. Enim non enim anim velit. Deserunt ea et aliquip proident.\r\n",
    "investment": "Dolore non duis Lorem velit laboris esse cupidatat aliqua. Elit irure sit ad occaecat laborum laboris ullamco do magna. Ut quis irure laborum ut exercitation fugiat. In Lorem sint commodo ut cupidatat irure duis quis duis in elit ipsum culpa. Culpa mollit quis Lorem incididunt fugiat officia pariatur in et nisi laborum nostrud. Labore cupidatat cillum ullamco duis eu cillum.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Deserunt nisi reprehenderit eu velit exercitation magna in ut ipsum esse elit labore. Irure occaecat officia dolore amet proident deserunt aute tempor deserunt minim sint. Consequat nostrud fugiat proident culpa consequat officia non culpa minim velit aute ad. Ad cillum ea mollit consequat cillum incididunt amet ullamco eiusmod aliquip laborum cillum. Commodo velit consectetur elit sint do nisi ipsum pariatur quis veniam. Incididunt eu cupidatat aliqua fugiat et voluptate exercitation voluptate consectetur.\r\n",
      "engagementDescr": "Incididunt cupidatat labore culpa magna elit sint nulla. Ea est mollit deserunt sunt ea tempor amet tempor dolor in eiusmod consequat laboris minim. Dolor pariatur irure in duis ullamco cupidatat.\r\n",
      "dataDescr": "Ipsum dolore fugiat exercitation occaecat in dolore sit mollit culpa non voluptate. Adipisicing in duis anim officia cillum duis ex ea ut officia nostrud. Cupidatat commodo excepteur duis non non do proident nostrud excepteur nostrud exercitation consectetur.\r\n",
      "routeDescr": "Dolor excepteur magna sit laboris ad irure id deserunt non amet quis laborum magna. Elit minim aliqua ad adipisicing. Voluptate est consectetur cupidatat sint consectetur amet occaecat culpa nisi nisi aute esse cillum.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Hartman Farmer",
        "email": "hartmanfarmer@zytrax.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Malone Landry",
        "email": "malonelandry@zytrax.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 11,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 41,
        "y3": 74
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 13,
        "y3": 66
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12506248,
      "customerVal": 118560,
      "mvp": 3608765,
      "optimize": 7734651,
      "grow": 1142148481,
      "scale": 1316196022
    },
    "nextStepsActionPlan": "Proident nostrud laborum et occaecat ea in tempor est sit cillum. Est eu cupidatat cillum sint. Officia quis voluptate culpa tempor irure et nulla. Dolor nisi voluptate qui ullamco et do. Quis excepteur culpa laborum voluptate laborum. Sint dolore laborum incididunt sunt enim non consectetur minim incididunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Laboris est do velit laboris ea laboris cillum aliquip. Cillum deserunt tempor do veniam ut. Sunt do Lorem adipisicing ad excepteur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-31",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1082cba9ca209d044102",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Autograte",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Subscription",
    "division": "Division Two",
    "painPoint": "Sint consectetur amet duis adipisicing ipsum mollit culpa consequat. Dolore incididunt excepteur amet sunt cupidatat sit veniam laborum ipsum amet pariatur cupidatat aute commodo. Adipisicing incididunt consequat fugiat voluptate. Ad excepteur tempor incididunt consequat ea sunt qui non mollit et fugiat magna. Culpa occaecat labore laboris cupidatat ut nulla aliqua ea nostrud. Occaecat anim in dolor ullamco adipisicing adipisicing enim est et anim incididunt. Et irure reprehenderit sint enim.\r\n",
    "description": "Nisi cillum Lorem deserunt ullamco ullamco tempor eiusmod culpa irure nulla nisi officia quis dolor. Amet mollit minim eiusmod mollit ipsum deserunt aute irure Lorem eu occaecat dolor. Irure laboris cillum amet duis cillum ex exercitation ut amet ut. Tempor proident nulla eu velit. Tempor exercitation ex fugiat commodo aute excepteur minim ut ad consequat eu. Aliquip ut minim aute duis tempor qui et non.\r\n",
    "strategy": "Minim aliqua adipisicing amet enim non ea enim commodo duis. Ullamco laboris quis magna sit velit labore dolore culpa dolore id sunt consectetur sit. Consequat aliqua ut nostrud ipsum. Sunt irure consequat sint magna sit aute aute. Fugiat irure est nisi laborum in sint. Ex tempor consequat officia enim qui dolore quis.\r\n",
    "keyLearnings": "Exercitation laboris ipsum laborum voluptate nostrud do. Fugiat consectetur excepteur magna reprehenderit excepteur non ut exercitation aute Lorem adipisicing Lorem reprehenderit. Ea cillum ipsum ea exercitation ex.\r\n",
    "nextSteps": "Et nisi occaecat fugiat aliquip velit exercitation anim enim nisi ad aute aliquip reprehenderit mollit. Veniam incididunt in sint voluptate ipsum et. Minim cillum velit adipisicing enim nulla Lorem. Velit ex veniam labore excepteur occaecat sit anim ad qui non in consequat consectetur qui. Cupidatat esse sit ut adipisicing sit minim amet ipsum proident ex occaecat velit reprehenderit. Sint nulla eiusmod amet deserunt velit occaecat commodo et mollit. Do velit labore officia ad cupidatat do aute adipisicing excepteur labore commodo reprehenderit tempor.\r\n",
    "investment": "Cupidatat laborum sint consectetur eu deserunt. Nulla irure culpa dolore incididunt non sunt quis dolor do excepteur Lorem excepteur do cupidatat. Commodo proident esse commodo incididunt incididunt ea anim exercitation dolore eiusmod proident ad consequat. Esse deserunt eu duis incididunt aliquip. Ipsum labore ipsum dolore id deserunt elit eu sint reprehenderit labore irure commodo. In veniam magna magna mollit esse cillum nulla deserunt.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Consequat eu sit tempor nostrud commodo consequat culpa ex. Amet dolor pariatur deserunt sit irure sit minim. Veniam sint veniam elit non exercitation elit culpa exercitation anim officia.\r\n",
      "engagementDescr": "Do et dolor consequat ut esse reprehenderit laborum aliquip deserunt. Cupidatat sit velit tempor ut eiusmod sint. Aute est eiusmod est sint ea qui. Cupidatat enim culpa elit Lorem duis.\r\n",
      "dataDescr": "Anim veniam exercitation elit veniam nulla est duis elit dolor cillum Lorem nisi amet proident. Cupidatat dolor ullamco occaecat sit anim aute sit. Tempor ad adipisicing minim nostrud reprehenderit pariatur cillum aute irure consectetur voluptate. Qui in consectetur duis aute tempor id incididunt. Dolor exercitation incididunt consequat ad aliquip consectetur eiusmod. Laboris anim nisi non dolore dolore ullamco veniam mollit ut anim eu ea sunt.\r\n",
      "routeDescr": "Ex laborum occaecat pariatur consectetur voluptate proident veniam sunt sint labore id incididunt anim. Nostrud excepteur laborum irure mollit minim dolore sint ipsum minim. Nulla dolor do pariatur eu. Esse dolore officia sint aute reprehenderit fugiat occaecat. Ex eiusmod velit quis ea ipsum sunt duis nulla nulla pariatur cupidatat commodo nostrud. Eu ea nulla incididunt et sint consequat laboris officia qui proident. Deserunt excepteur incididunt cillum labore occaecat exercitation incididunt sint ullamco duis excepteur sit duis.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Durham Moreno",
        "email": "durhammoreno@autograte.com"
      },
      {
        "id": 1,
        "name": "Beasley Parrish",
        "email": "beasleyparrish@autograte.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Irma Summers",
        "email": "irmasummers@autograte.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 9,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 57,
        "y3": 56
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 25,
        "y3": 91
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10864620,
      "customerVal": 1019949,
      "mvp": 6713946,
      "optimize": 10062989,
      "grow": 476761748,
      "scale": 80417448
    },
    "nextStepsActionPlan": "Consectetur consequat laborum nostrud non irure est dolore nisi nostrud officia deserunt dolore cupidatat. Ipsum elit magna cillum velit ea pariatur cupidatat voluptate deserunt in quis laborum Lorem enim. Ad duis nisi laboris ipsum aliqua ea et dolore Lorem fugiat. Qui sint dolor est eu amet deserunt sunt occaecat officia amet eu. Veniam excepteur Lorem velit magna velit eu proident officia dolore duis incididunt deserunt sint commodo.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ullamco cupidatat magna elit tempor in consequat Lorem occaecat nisi consectetur labore. Nisi sint duis aliqua sint eiusmod officia excepteur exercitation consectetur exercitation. Et nostrud eu cillum ad magna ut mollit eu cillum ea fugiat sunt.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-06",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1082eeafcc55c43e3aed",
    "active": false,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Enthaze",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "India",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Aliqua officia ad aliquip officia cupidatat ea aute ullamco laboris ex sunt laboris exercitation. Ullamco eiusmod commodo ex nulla qui veniam laboris Lorem cillum. Elit ea ipsum elit dolore incididunt nisi est esse officia exercitation culpa aute.\r\n",
    "description": "Sint nostrud excepteur nisi velit pariatur id proident. Ad sint laboris dolor enim sit pariatur sint Lorem pariatur occaecat amet irure do. Nostrud ad non laboris voluptate nulla tempor velit velit adipisicing. Ullamco ipsum id deserunt adipisicing adipisicing adipisicing do officia eu commodo nostrud consectetur. Culpa occaecat id aliqua esse laborum quis mollit id. Fugiat ad fugiat officia culpa cupidatat sunt dolor. Aliquip ad ad ad amet aute in dolore ex eiusmod ipsum do consequat in.\r\n",
    "strategy": "Veniam aliquip enim adipisicing aliqua. Reprehenderit enim eu id laborum pariatur laborum et mollit consectetur velit proident consequat exercitation. Officia est eiusmod veniam aliqua anim aliquip cillum. Cillum laborum irure consectetur minim ex eiusmod. Exercitation tempor fugiat id ex aliqua dolor cupidatat voluptate qui deserunt cillum aute labore ea. Occaecat voluptate velit culpa fugiat et Lorem duis anim.\r\n",
    "keyLearnings": "Cillum esse id sit irure fugiat enim ea veniam labore tempor. Ut sint Lorem tempor quis aliquip minim fugiat. Lorem fugiat fugiat qui aute Lorem Lorem culpa qui reprehenderit sunt excepteur. Magna commodo reprehenderit elit laborum cillum tempor eiusmod.\r\n",
    "nextSteps": "Ex occaecat irure laborum in nisi consectetur proident in cupidatat est. Exercitation occaecat deserunt minim cupidatat. Reprehenderit ullamco Lorem officia incididunt anim. Officia aute dolore labore ipsum ad enim sint sunt dolore adipisicing ex Lorem mollit.\r\n",
    "investment": "Cupidatat esse nisi nostrud nisi. In labore est cupidatat do elit reprehenderit. Duis ullamco do minim quis do aliquip dolore do laboris ipsum exercitation.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod et eu elit qui quis ex esse labore ullamco consequat ea occaecat sit tempor. Nulla qui nulla aute fugiat deserunt. Aute consectetur proident quis sunt Lorem quis ad consectetur laborum minim amet culpa. Amet incididunt consectetur ad ut ut velit ut ipsum. Officia do elit proident mollit irure elit ipsum ex nulla duis sint do veniam Lorem. Aute sit laborum anim ad amet magna quis anim non adipisicing.\r\n",
      "engagementDescr": "Mollit do enim eiusmod officia occaecat. Tempor proident enim veniam labore. Dolore proident consectetur et ullamco duis voluptate laboris. Commodo quis deserunt excepteur ea reprehenderit sit ut ullamco dolor non adipisicing. Ullamco dolor nostrud minim in. Ad magna irure velit nostrud.\r\n",
      "dataDescr": "Nulla adipisicing exercitation mollit dolore est velit et nostrud ea aute aliqua ad aliquip nisi. Non deserunt exercitation consequat magna ut culpa reprehenderit dolore anim dolor in. Duis ut laboris incididunt aliqua. Et duis aliqua sit minim sit nulla laboris. Dolor quis nisi eiusmod Lorem cupidatat sit occaecat reprehenderit ullamco ex voluptate.\r\n",
      "routeDescr": "Velit nulla enim est do ullamco excepteur sunt amet voluptate veniam enim eu sint. Ullamco duis cupidatat do consequat laborum incididunt proident pariatur. Ipsum fugiat ut velit cupidatat laborum ad anim ipsum ullamco sint laborum. Fugiat dolor ipsum id ipsum elit. Laborum ad sunt enim ullamco ad ex labore tempor adipisicing ex velit ipsum sint consequat. Sint adipisicing mollit commodo ipsum dolore. Duis elit nulla culpa voluptate dolor tempor cillum adipisicing reprehenderit sit ex proident eiusmod.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Myrna Pacheco",
        "email": "myrnapacheco@enthaze.com"
      },
      {
        "id": 1,
        "name": "Jolene Soto",
        "email": "jolenesoto@enthaze.com"
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Lela Greer",
        "email": "lelagreer@enthaze.com"
      },
      {
        "id": 1,
        "name": "Clarice Stone",
        "email": "claricestone@enthaze.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 17,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 32,
        "y3": 65
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 25,
        "y3": 78
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1394537,
      "customerVal": 1480021,
      "mvp": 56045610,
      "optimize": 5072592,
      "grow": 934422538,
      "scale": 782236451
    },
    "nextStepsActionPlan": "Proident qui cupidatat do dolor officia mollit exercitation. Occaecat pariatur ipsum ipsum culpa ullamco consequat officia in exercitation. Eiusmod commodo sunt incididunt et officia. Qui aliqua irure ex consectetur consequat qui laborum proident nisi do veniam. Do dolore elit cillum eu ullamco excepteur Lorem magna culpa aute.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Pariatur sunt sit eu irure. Fugiat dolore cupidatat ullamco proident. Aute nostrud voluptate proident magna in magna enim ipsum non laboris culpa Lorem sint nulla. Et aliqua nulla anim culpa fugiat consectetur aute aliquip fugiat sint elit anim est. Sunt voluptate non nisi commodo qui reprehenderit. Laborum tempor deserunt fugiat sit. Lorem adipisicing deserunt veniam exercitation dolore qui cillum commodo esse laborum dolor voluptate officia reprehenderit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-07",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  }
]