export const demoVPArray = [
  {
    "uuid": "631a1e0389fbeb48ac261bb9",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Dyno",
    "highlightKPI": [
      "cac",
      "gmac",
      "rp"
    ],
    "geography": "United States",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Officia eiusmod esse ipsum pariatur id eu ut non voluptate et fugiat nulla. Cupidatat fugiat est non consequat est pariatur id non eu ipsum cupidatat. Quis non labore magna in ea fugiat aute nisi exercitation. Quis labore dolor reprehenderit fugiat voluptate in velit dolor. Veniam exercitation sunt proident quis Lorem cillum.\r\n",
    "description": "Lorem veniam nisi sunt aliqua esse ex deserunt amet quis esse ex. Laborum incididunt adipisicing cupidatat quis officia incididunt ea nisi consequat commodo aliquip est ad officia. Excepteur non duis sint incididunt est proident incididunt magna pariatur eiusmod. Est do nisi cupidatat commodo ea labore labore amet do.\r\n",
    "strategy": "Fugiat aliquip dolore cupidatat dolore do excepteur incididunt. Amet ad nisi id nisi excepteur consectetur. Sint exercitation adipisicing ipsum culpa quis velit occaecat anim nulla esse adipisicing aliquip aute id. Consequat et aliquip deserunt do est reprehenderit veniam ex do.\r\n",
    "keyLearnings": "Aute eiusmod minim consectetur eiusmod consectetur laborum et quis et et aute duis aliquip. Ea quis aute consectetur sint reprehenderit occaecat minim eu non cillum. Occaecat mollit mollit sint officia Lorem irure velit nulla ad anim consequat culpa. Occaecat id occaecat eiusmod aliquip occaecat ex in irure sit. Lorem exercitation laborum ut excepteur fugiat laboris aliquip dolor anim. Lorem nostrud aliqua consequat quis exercitation eu duis reprehenderit consectetur ex irure ut enim. Sint quis consequat aliqua eu mollit culpa mollit.\r\n",
    "nextSteps": "Cupidatat sint velit reprehenderit pariatur incididunt laboris proident deserunt dolore mollit. Irure ea reprehenderit aliqua veniam ipsum do mollit reprehenderit labore mollit culpa commodo commodo dolor. Minim sunt laboris do dolor non labore ipsum Lorem. Cupidatat eu ex deserunt proident quis laboris cillum. Non ut sunt nisi ea voluptate laborum eu sit duis Lorem id non.\r\n",
    "investment": "Minim ea incididunt non dolore nisi tempor sint consectetur reprehenderit velit qui eu non. Dolor nostrud incididunt cupidatat qui cillum nisi qui consectetur aute fugiat anim magna. Commodo duis id irure deserunt quis non commodo amet tempor sunt duis qui sunt. Quis ipsum irure esse et amet sint voluptate in ut fugiat. Esse veniam aliqua mollit deserunt minim aute do culpa amet et mollit adipisicing sint.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "In tempor ullamco Lorem id id cupidatat qui mollit nulla sit magna. Minim pariatur anim anim sunt. Enim elit laboris aliquip eu eu ipsum ad dolore.\r\n",
      "engagementDescr": "Deserunt officia ad voluptate elit adipisicing ullamco laboris consequat reprehenderit ad elit. Labore ipsum velit non quis. Et commodo ut mollit elit non esse nisi ipsum ad culpa qui sit dolor eiusmod.\r\n",
      "dataDescr": "Consectetur deserunt ex Lorem fugiat mollit magna excepteur ad deserunt sit. Aliqua ad sint consectetur enim consequat est quis incididunt est. In aute eiusmod occaecat ex ullamco culpa. Exercitation cupidatat pariatur pariatur irure tempor ut fugiat deserunt minim officia consequat irure anim.\r\n",
      "routeDescr": "Commodo excepteur est cupidatat et elit deserunt do cillum mollit tempor eiusmod excepteur do proident. Minim ex nulla incididunt nisi quis consequat commodo qui eiusmod dolore occaecat elit. Dolor enim nostrud et ullamco ad pariatur.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Beth Henry",
        "email": "bethhenry@dyno.com",
        "fte": 0.8615
      },
      {
        "id": 1,
        "name": "Berry Sargent",
        "email": "berrysargent@dyno.com",
        "fte": 0.414
      },
      {
        "id": 2,
        "name": "Zelma Page",
        "email": "zelmapage@dyno.com",
        "fte": 0.2522
      },
      {
        "id": 3,
        "name": "Gillespie Watson",
        "email": "gillespiewatson@dyno.com",
        "fte": 0.7168
      },
      {
        "id": 4,
        "name": "Mullins Kelly",
        "email": "mullinskelly@dyno.com",
        "fte": 0.9989
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Rich Guerrero",
        "email": "richguerrero@dyno.com"
      },
      {
        "id": 1,
        "name": "Ramona Stanley",
        "email": "ramonastanley@dyno.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 9,
        "y3": 21
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 39,
        "y3": 77
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 21,
        "y3": 69
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2272838,
      "customerVal": 716055,
      "mvp": 91650881,
      "optimize": 693778,
      "grow": 661991877,
      "scale": 1298932967
    },
    "nextStepsActionPlan": "Labore id aliquip dolor incididunt nostrud. Ea cillum do sunt mollit culpa. Aliquip aute velit esse sunt cillum id cupidatat ea cillum veniam aliqua deserunt. Ullamco nulla exercitation do in eiusmod. Nisi dolore nostrud amet non id. Id dolore labore consequat occaecat. Occaecat mollit cillum consequat consequat est fugiat aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt cupidatat Lorem nisi consequat occaecat qui adipisicing anim est est nulla et duis eiusmod. Occaecat amet sunt ut exercitation incididunt do aliquip magna id adipisicing quis labore. Ut nostrud laborum deserunt aute laborum labore excepteur. Consectetur Lorem ut nostrud do aliquip occaecat anim ullamco consequat exercitation. Sint exercitation labore non magna proident sunt sint consequat excepteur sit amet quis sunt. Aliquip pariatur nisi excepteur qui in consequat id est est. Nulla culpa nostrud in qui proident ullamco elit in eu nostrud excepteur Lorem id est.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e038c85083d8bcec917",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Gaptec",
    "highlightKPI": [
      "nsv",
      "gmac",
      "tc"
    ],
    "geography": "India",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Laborum officia consectetur dolor nisi ea aute anim veniam occaecat pariatur pariatur officia minim elit. In aliqua irure irure non sint qui nisi minim irure aute mollit nulla. Ipsum ullamco labore Lorem cillum pariatur amet in. Quis elit dolore commodo qui do ut ad est anim excepteur.\r\n",
    "description": "Dolor adipisicing adipisicing enim minim. Nisi incididunt laborum commodo mollit sunt et incididunt nulla fugiat ex proident in id fugiat. Proident anim magna duis cupidatat aute. Est minim sit nisi exercitation eiusmod ea fugiat aliquip ad duis sunt. Ea exercitation consectetur veniam est Lorem est in duis reprehenderit sit.\r\n",
    "strategy": "Nulla ad sint consectetur minim commodo. Ut incididunt aute sit Lorem tempor id ipsum anim. Qui nostrud pariatur reprehenderit pariatur laboris reprehenderit deserunt dolor magna officia non est ipsum dolor.\r\n",
    "keyLearnings": "Ullamco nisi ex minim dolor in adipisicing sit nostrud. Irure tempor nostrud duis duis sit amet. Laborum enim voluptate eu Lorem mollit cupidatat Lorem. Sit occaecat ea ad Lorem do consectetur proident ad consequat. Nulla esse tempor laboris ut sit quis. Dolor excepteur commodo aute labore quis sit qui duis qui proident.\r\n",
    "nextSteps": "Nisi nulla officia dolor ex non voluptate Lorem sit commodo et eu. Esse velit eu Lorem officia minim qui proident consectetur ea commodo. Dolore adipisicing excepteur nulla enim nisi deserunt. Voluptate non non laboris cillum in. Sint do nulla exercitation aliquip cillum elit cillum est non minim cillum Lorem occaecat.\r\n",
    "investment": "Est ea sunt esse cillum laboris commodo laboris incididunt sit nisi sit duis aliqua. Adipisicing veniam ipsum occaecat pariatur amet dolor nostrud in. Dolore excepteur enim enim Lorem in nisi anim eiusmod consequat aliqua irure dolore.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Consequat qui consequat incididunt mollit consectetur id laborum nisi culpa incididunt ullamco tempor. Laborum sit duis ea sit voluptate nisi tempor incididunt sunt. Sunt do do culpa dolore velit do occaecat aliquip. Commodo irure nisi irure eu veniam proident ullamco quis labore amet.\r\n",
      "engagementDescr": "Est reprehenderit ex sint amet irure Lorem voluptate laboris nostrud enim excepteur veniam anim reprehenderit. Do irure tempor anim laborum voluptate qui ipsum ea velit irure Lorem ut veniam. Deserunt eiusmod reprehenderit ad proident duis deserunt velit ex non voluptate mollit. Ut esse qui fugiat amet ea. Commodo deserunt tempor minim Lorem.\r\n",
      "dataDescr": "Ut sit pariatur tempor veniam tempor et. In reprehenderit esse elit incididunt et et reprehenderit sit laborum ut ullamco. Et reprehenderit commodo duis velit mollit. Reprehenderit ex officia officia Lorem proident reprehenderit fugiat. Labore nulla id ad ullamco nostrud in ea. Non anim cupidatat ad enim et id proident aliquip enim est dolore. Nisi officia eu non sit elit laboris fugiat eiusmod cupidatat commodo consequat.\r\n",
      "routeDescr": "Aute nisi commodo nisi ipsum exercitation exercitation enim velit sunt consequat id ex nisi. Esse duis nisi qui tempor ut occaecat veniam adipisicing eiusmod veniam Lorem culpa laborum. Minim Lorem nulla fugiat culpa culpa tempor quis dolor. Nulla esse nulla duis irure culpa qui ut elit sint. Ipsum excepteur Lorem mollit labore enim occaecat amet irure.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Whitehead Strickland",
        "email": "whiteheadstrickland@gaptec.com",
        "fte": 0.5468
      },
      {
        "id": 1,
        "name": "Matilda Rollins",
        "email": "matildarollins@gaptec.com",
        "fte": 0.7682
      },
      {
        "id": 2,
        "name": "Sheree Nelson",
        "email": "shereenelson@gaptec.com",
        "fte": 0.8365
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Stella Gentry",
        "email": "stellagentry@gaptec.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 15,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 10,
        "y2": 41,
        "y3": 67
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 23,
        "y3": 89
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 620200,
      "customerVal": 198044,
      "mvp": 51125487,
      "optimize": 5621129,
      "grow": 742831790,
      "scale": 932104601
    },
    "nextStepsActionPlan": "Irure excepteur nisi mollit exercitation nisi nulla tempor aute aliqua irure esse aliquip duis. Mollit nulla non amet eu non laboris nulla excepteur laboris est esse. Incididunt nulla ex mollit deserunt incididunt pariatur. Nisi incididunt culpa sint cupidatat veniam nulla ullamco esse pariatur proident duis enim duis. Nulla reprehenderit enim in culpa aute cupidatat nulla.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Labore exercitation quis et nisi non sit aliquip aute occaecat minim cupidatat pariatur veniam. Sint aute laborum esse ut magna dolore sint cupidatat officia amet aliquip magna laborum. Amet ex fugiat sunt commodo ut in excepteur qui cillum adipisicing.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-15",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03394df9f8dfaa2b56",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Protodyne",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "United States",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Eu do sit est ex. Ipsum ullamco culpa ipsum velit nulla esse dolor magna enim exercitation labore adipisicing esse et. Officia id tempor commodo magna exercitation. Officia labore ut consequat elit in eiusmod proident. Elit officia eu consequat enim ipsum ea consectetur est proident. Ipsum amet quis ut exercitation anim irure. Nostrud eiusmod sunt proident officia nulla duis.\r\n",
    "description": "Ipsum consequat sunt aute veniam. Tempor nulla esse ad tempor commodo nostrud est in voluptate consequat duis exercitation sunt. Aliqua et irure labore consequat amet esse labore. Ex excepteur ipsum deserunt nisi. Magna aliquip Lorem ut aliqua cupidatat ex.\r\n",
    "strategy": "Exercitation ea adipisicing dolor proident aliqua mollit dolor officia. Anim excepteur ea ipsum et veniam pariatur cupidatat elit ea. Do nostrud officia adipisicing enim enim voluptate qui exercitation duis est aliquip aliquip enim sint. Labore do minim dolore Lorem non mollit et culpa ut voluptate nulla dolore.\r\n",
    "keyLearnings": "Voluptate voluptate labore laborum sunt sunt officia. Consequat ea amet ut culpa ullamco ea tempor nisi pariatur aliquip aliqua. Laborum sit tempor dolore reprehenderit magna sunt quis ullamco. Consectetur ullamco esse nulla dolor esse excepteur laboris consequat laboris id. Cillum est adipisicing duis exercitation officia ad. Ea consectetur enim dolore aliqua ullamco laborum tempor ea aliqua irure ipsum.\r\n",
    "nextSteps": "Laboris culpa sint aliquip cillum ut Lorem occaecat minim magna sit. Enim deserunt aliqua est enim. Exercitation aliqua irure irure aliquip deserunt officia mollit magna incididunt sint aute veniam.\r\n",
    "investment": "Dolor culpa duis eu dolor id enim consectetur cupidatat Lorem sint eiusmod sunt ut. Lorem adipisicing elit occaecat id qui ut quis nostrud ea nostrud nulla occaecat. Labore esse ullamco eiusmod magna.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Proident nostrud cillum et sint culpa eiusmod irure irure irure voluptate nostrud. Adipisicing laboris cillum labore do id consectetur sint. Fugiat ad veniam nulla aute veniam occaecat enim. Amet velit mollit dolor sit nisi adipisicing enim proident proident anim. Et commodo aliqua nostrud elit qui adipisicing aliquip ullamco enim. Quis elit reprehenderit adipisicing cupidatat elit in. Velit sit ipsum eiusmod commodo deserunt.\r\n",
      "engagementDescr": "Aute laboris velit aute non consectetur reprehenderit qui ullamco est aute laborum occaecat nostrud ad. Tempor velit officia adipisicing fugiat ut proident. Mollit do exercitation anim cupidatat reprehenderit laborum deserunt cillum commodo. In amet non aliquip reprehenderit aute quis aute magna nostrud commodo. Elit non aliquip consequat reprehenderit aliqua dolore. In laborum minim minim exercitation occaecat exercitation irure in est excepteur et ipsum.\r\n",
      "dataDescr": "Ipsum est et labore fugiat aute magna aliqua velit. Labore ad minim nostrud nisi proident velit pariatur. Est pariatur et mollit sunt id aliqua non ut esse sit anim. Ipsum minim exercitation minim tempor sunt cillum tempor. Proident in cillum minim nisi incididunt adipisicing. Ullamco enim proident elit velit elit ea laboris minim. Dolore do do sint mollit quis amet cupidatat in in.\r\n",
      "routeDescr": "Id ea cupidatat ea anim cupidatat enim dolore sit amet id cupidatat deserunt mollit ullamco. Consectetur sit nisi cillum sint irure do aliquip eu minim nostrud minim veniam fugiat. Ea nisi dolor fugiat laborum duis duis eu consequat deserunt laborum do est fugiat non.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Ila Price",
        "email": "ilaprice@protodyne.com",
        "fte": 0.1559
      },
      {
        "id": 1,
        "name": "Jeannie Olsen",
        "email": "jeannieolsen@protodyne.com",
        "fte": 0.306
      },
      {
        "id": 2,
        "name": "Gilda Reynolds",
        "email": "gildareynolds@protodyne.com",
        "fte": 0.8246
      },
      {
        "id": 3,
        "name": "Mayo Morales",
        "email": "mayomorales@protodyne.com",
        "fte": 0.9507
      },
      {
        "id": 4,
        "name": "Daniel Holder",
        "email": "danielholder@protodyne.com",
        "fte": 0.5117
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Patricia Carson",
        "email": "patriciacarson@protodyne.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 11,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 21,
        "y3": 93
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 14,
        "y3": 44
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 372036,
      "customerVal": 96163,
      "mvp": 14190567,
      "optimize": 4391535,
      "grow": 1239263625,
      "scale": 441987065
    },
    "nextStepsActionPlan": "Nulla Lorem ex commodo occaecat consectetur. Ut esse non proident occaecat. Ipsum mollit dolor Lorem dolor mollit sunt. Ullamco anim culpa deserunt sit Lorem officia cupidatat. Cillum id nisi voluptate laborum culpa adipisicing incididunt ex proident. Consequat aute veniam occaecat deserunt cillum ut sit ipsum culpa occaecat laborum do aliqua mollit. Cillum excepteur duis ipsum sint eiusmod aliqua pariatur dolore nisi id ex.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cillum ullamco dolor pariatur culpa commodo. Ex reprehenderit id anim enim irure cillum dolore voluptate. Sint minim occaecat exercitation non esse sint incididunt laborum id culpa nisi minim veniam. Occaecat pariatur proident non qui Lorem magna irure do dolor exercitation reprehenderit magna. Occaecat aliquip et ut adipisicing non irure mollit incididunt enim magna sit. Aliqua ad eiusmod qui labore ut.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039ec3d28d202a8e17",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Bristo",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "Italy",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Fugiat elit excepteur est nisi commodo laboris reprehenderit mollit occaecat anim proident. Nisi aliquip cillum amet ea eu fugiat minim adipisicing magna sit laborum culpa nulla ut. Ut Lorem minim velit cupidatat ea deserunt. Enim velit eu pariatur incididunt nulla irure incididunt ullamco id quis. Id velit eu nisi non quis. Laborum reprehenderit excepteur consectetur laboris ea exercitation ipsum sit excepteur aliqua. Nostrud qui anim esse labore.\r\n",
    "description": "Incididunt duis enim veniam occaecat reprehenderit esse qui adipisicing laboris adipisicing magna ea irure consectetur. Deserunt dolore sint fugiat eiusmod anim fugiat dolor officia id laborum exercitation reprehenderit aute. In ad nostrud nostrud nulla ea. Reprehenderit esse consequat ipsum nostrud amet qui ex sint ut Lorem. Ut duis exercitation voluptate laborum dolore do. Enim laborum magna labore velit in sunt dolore nostrud veniam quis excepteur proident aliquip do. Velit et laboris quis ipsum enim tempor eu.\r\n",
    "strategy": "Labore nulla nisi ut esse sunt voluptate aliquip amet cupidatat aliqua voluptate. Do reprehenderit consequat aute id magna aliquip ad. Incididunt ipsum deserunt cupidatat duis. Excepteur do enim et qui nisi voluptate nisi ipsum ut ut irure Lorem. Ad quis do ad ea cillum Lorem culpa.\r\n",
    "keyLearnings": "Eu proident officia ex aute. Sint Lorem minim ipsum proident commodo exercitation officia. Esse fugiat do in aute ullamco consectetur laborum id deserunt et nisi deserunt Lorem. In sunt laboris consequat mollit eu cillum fugiat ea. Aliqua labore occaecat Lorem pariatur aute tempor culpa qui sunt reprehenderit mollit.\r\n",
    "nextSteps": "Qui irure culpa id et magna consequat reprehenderit aliqua consequat duis occaecat. Aliqua ad Lorem ipsum ad irure aliqua tempor cupidatat proident commodo laboris pariatur officia nisi. Laboris pariatur non est eiusmod. Minim incididunt do pariatur ullamco proident reprehenderit esse ut. Ipsum ea fugiat consequat sint exercitation.\r\n",
    "investment": "Nostrud ex fugiat eu incididunt esse sunt commodo anim. Cupidatat excepteur incididunt duis laborum tempor in nisi non. Lorem id labore adipisicing Lorem aute labore ut laborum sint aliquip voluptate proident. Mollit mollit proident cillum cillum consectetur commodo occaecat sunt ex non.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Et ea in incididunt labore sint elit anim ad exercitation eu. Enim exercitation ipsum cupidatat adipisicing pariatur sint et aute. Mollit velit velit consectetur nulla sunt incididunt magna adipisicing consectetur occaecat mollit consectetur mollit.\r\n",
      "engagementDescr": "Elit sunt et laborum irure anim ea aute veniam enim. Laborum amet minim irure eiusmod duis. Nostrud reprehenderit nisi ullamco culpa do do quis. Nostrud culpa ipsum consequat exercitation commodo proident excepteur cupidatat esse aliqua ullamco nostrud. Quis id nisi velit voluptate dolor tempor duis reprehenderit non. Fugiat veniam aliquip nisi ea ex.\r\n",
      "dataDescr": "Duis minim laborum quis sint fugiat quis. Aliqua nulla in in reprehenderit pariatur occaecat esse ad. Eu consequat ipsum voluptate reprehenderit pariatur dolore.\r\n",
      "routeDescr": "Excepteur amet veniam tempor sint eiusmod aliquip tempor adipisicing fugiat dolore commodo. Pariatur ipsum ad eiusmod id reprehenderit fugiat nulla sunt deserunt sunt aliqua aliqua velit. Nulla qui occaecat dolore quis tempor qui aliquip in et enim veniam adipisicing. Cillum est excepteur nostrud excepteur. Do qui cupidatat id quis veniam anim. Dolor laboris commodo deserunt veniam amet.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Kaufman Pearson",
        "email": "kaufmanpearson@bristo.com",
        "fte": 0.4083
      },
      {
        "id": 1,
        "name": "Holloway Trujillo",
        "email": "hollowaytrujillo@bristo.com",
        "fte": 0.343
      },
      {
        "id": 2,
        "name": "Rush Norris",
        "email": "rushnorris@bristo.com",
        "fte": 0.4484
      },
      {
        "id": 3,
        "name": "Miranda Herrera",
        "email": "mirandaherrera@bristo.com",
        "fte": 0.4519
      },
      {
        "id": 4,
        "name": "Hallie Norman",
        "email": "hallienorman@bristo.com",
        "fte": 0.7374
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "English Valenzuela",
        "email": "englishvalenzuela@bristo.com"
      },
      {
        "id": 1,
        "name": "Mcdonald Rogers",
        "email": "mcdonaldrogers@bristo.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 15,
        "y3": 23
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 30,
        "y3": 93
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 20,
        "y3": 100
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12151746,
      "customerVal": 1202860,
      "mvp": 32845173,
      "optimize": 4897148,
      "grow": 1155487719,
      "scale": 1484288768
    },
    "nextStepsActionPlan": "Esse anim velit sint officia consequat do sunt magna Lorem sint aliqua ex magna sint. Deserunt anim eiusmod laboris eiusmod laborum consectetur eiusmod incididunt eu mollit est. Cillum anim duis nostrud ad quis culpa id amet cupidatat cupidatat ad cillum consectetur minim. Officia do ut ullamco aliqua laborum est. Aliqua velit deserunt sit qui laborum tempor ut. Laborum est deserunt voluptate mollit proident sit dolor aliqua do culpa ullamco.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Esse proident occaecat deserunt ex id reprehenderit labore consequat est. Aliqua Lorem irure magna in ea incididunt anim do labore ullamco fugiat ipsum. Reprehenderit culpa officia sint enim elit in aute occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-09",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0379479ea6ddcb176f",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Zenthall",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Eu ex sint ullamco dolore duis ex culpa mollit. Fugiat nostrud occaecat minim ex. In sit excepteur sit culpa ipsum ullamco ipsum. Ut veniam excepteur id commodo. Quis consectetur eiusmod fugiat voluptate nostrud veniam proident dolor ipsum irure ea ex aliqua excepteur. Excepteur ea nisi quis pariatur culpa aliqua est adipisicing ad non do cillum officia nisi.\r\n",
    "description": "Do pariatur Lorem ullamco enim. Enim ullamco commodo officia fugiat mollit consectetur cupidatat commodo nostrud aliquip. Dolore incididunt aliquip sint dolor dolor ea sint. Dolor cillum officia incididunt cupidatat quis nostrud aliquip.\r\n",
    "strategy": "Elit excepteur anim sit commodo aliquip nisi exercitation laborum. Id occaecat voluptate sunt velit amet nisi dolore voluptate. Proident eu fugiat ullamco proident in minim veniam exercitation mollit dolor tempor incididunt. Enim sunt reprehenderit dolore veniam irure nisi aute eiusmod. Nisi pariatur sunt velit cillum ut consectetur culpa sit irure. Commodo exercitation laboris aute magna enim ipsum reprehenderit ullamco voluptate incididunt anim ex laboris. Veniam culpa esse veniam ipsum et ut irure nulla in enim.\r\n",
    "keyLearnings": "Labore et esse ea tempor amet anim dolore sit adipisicing. Ullamco amet aliquip pariatur magna proident ullamco ut fugiat aliqua Lorem. Est pariatur veniam reprehenderit cillum. Irure deserunt dolor et Lorem cupidatat non do sit eu.\r\n",
    "nextSteps": "Cupidatat mollit aliquip qui esse amet eiusmod commodo duis non cillum cillum quis adipisicing. Officia duis adipisicing proident eiusmod est duis ut cillum laborum enim non labore dolor. Mollit tempor voluptate aliquip enim duis occaecat nulla occaecat cillum laboris occaecat qui anim. Ipsum aute commodo cillum tempor nostrud nostrud deserunt ipsum ad non ullamco do adipisicing labore. Quis adipisicing occaecat culpa est minim Lorem ad consectetur anim exercitation non officia ut. Anim incididunt nostrud cillum irure labore. Do quis dolore laborum excepteur fugiat duis ullamco eu laborum id fugiat.\r\n",
    "investment": "Mollit esse veniam ullamco magna aliqua labore dolor adipisicing est enim culpa reprehenderit deserunt elit. In eu tempor aute Lorem tempor et excepteur ullamco. Aliquip pariatur aliquip ad anim et fugiat. Sint dolore eiusmod qui est sunt commodo nisi aute elit veniam. Lorem pariatur non labore fugiat officia occaecat ex. Exercitation ex Lorem magna fugiat officia velit excepteur cupidatat fugiat nisi culpa.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Deserunt id ea consequat nisi et veniam fugiat qui proident magna sit aliqua amet. Voluptate esse tempor ullamco quis. Dolor exercitation tempor dolor enim exercitation nulla minim in velit ad. Labore exercitation nisi do consectetur nisi dolor proident laborum Lorem eu adipisicing elit ipsum sit. Ea incididunt laboris adipisicing consequat consectetur aliqua quis ut anim sunt est et in mollit.\r\n",
      "engagementDescr": "Nulla irure voluptate laborum amet ad. Sunt ea ad sunt aliquip. Velit proident incididunt cillum laborum. Ex non exercitation do reprehenderit.\r\n",
      "dataDescr": "Enim consectetur incididunt ex laborum ad sunt nulla cupidatat. Ex labore nulla labore qui. Nostrud do esse eu in.\r\n",
      "routeDescr": "Non et quis aliqua dolor ad. Anim et quis irure veniam amet est dolore cillum minim laborum. Amet nulla proident officia enim consequat non velit laboris. Aute cillum et fugiat aute velit consectetur ullamco incididunt quis quis. Non qui ea culpa nulla cillum fugiat magna occaecat. Deserunt nulla aliquip culpa est. Mollit exercitation magna nisi anim duis sunt ea anim labore velit ullamco proident excepteur.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Susan Estes",
        "email": "susanestes@zenthall.com",
        "fte": 0.4987
      },
      {
        "id": 1,
        "name": "Leonor Dillard",
        "email": "leonordillard@zenthall.com",
        "fte": 0.5381
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Marquita Oneill",
        "email": "marquitaoneill@zenthall.com"
      },
      {
        "id": 1,
        "name": "Misty Mccray",
        "email": "mistymccray@zenthall.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 14,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 57,
        "y3": 77
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 30,
        "y3": 81
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14952614,
      "customerVal": 623987,
      "mvp": 127383423,
      "optimize": 8330654,
      "grow": 840290459,
      "scale": 1057388075
    },
    "nextStepsActionPlan": "Mollit qui nisi pariatur ipsum minim et cillum. Amet Lorem pariatur dolore incididunt non pariatur sint et dolore adipisicing fugiat. Sit labore laborum velit sit ipsum cillum adipisicing.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Lorem eu consequat fugiat ex ullamco pariatur aliquip consequat occaecat labore fugiat ex deserunt nisi. Consectetur et enim qui tempor Lorem aliqua nulla non magna cillum do. Id nulla sint qui ullamco eiusmod anim ipsum eu dolor. Deserunt quis do officia elit laborum consectetur anim duis fugiat sit amet.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e033c86e279a7787ca8",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Liquicom",
    "highlightKPI": [
      "ctr",
      "gmac",
      "tc"
    ],
    "geography": "Spain",
    "channel": "Online: Subscription",
    "division": "Division Three",
    "painPoint": "Occaecat ex labore non tempor. Et ullamco commodo sunt sit commodo nostrud velit ipsum in sint culpa. Adipisicing consectetur nostrud excepteur magna minim labore nisi esse incididunt. Consequat non cillum ex sint incididunt duis eiusmod eiusmod est laboris velit. Exercitation sit do enim nostrud pariatur labore eu do amet et culpa enim. Laboris mollit sint commodo aute deserunt elit. Amet culpa officia irure dolor ut amet ut esse nulla.\r\n",
    "description": "Aliqua sint eu Lorem nostrud laborum commodo commodo adipisicing eiusmod velit ipsum eiusmod eu. Excepteur duis in culpa nisi veniam veniam sint quis cillum aute aliquip quis esse. Sint irure aliqua irure mollit sit veniam consequat fugiat nisi excepteur elit velit irure occaecat. Irure laborum aliquip excepteur nisi esse ipsum nisi proident magna cupidatat dolore do id minim. Consequat incididunt pariatur tempor in ex sunt do cupidatat magna. Qui veniam aliqua laborum aute veniam cillum veniam. Lorem nostrud minim veniam deserunt magna exercitation enim ea cupidatat sit.\r\n",
    "strategy": "Excepteur in id ut amet nulla culpa ex aliqua cillum exercitation. Deserunt sit adipisicing veniam magna sit consectetur laborum esse minim duis reprehenderit. Deserunt dolore nisi aute velit Lorem aliqua. Tempor anim id sint veniam sint ea reprehenderit sunt do non excepteur dolor. Culpa magna cupidatat officia anim. Aliquip eiusmod Lorem aute culpa tempor ut eu esse labore aliquip laborum elit proident laborum. Ea ullamco ut id aliqua.\r\n",
    "keyLearnings": "Proident laboris mollit proident Lorem sit. Minim officia et ut qui ea velit elit incididunt. Esse adipisicing in in anim ex quis non reprehenderit duis commodo officia. Elit deserunt labore qui occaecat anim reprehenderit officia.\r\n",
    "nextSteps": "Id aute sit excepteur sit nulla ad reprehenderit esse enim laborum consectetur sunt ea laboris. Cupidatat est nostrud irure est eu Lorem ullamco cupidatat irure officia aliquip ea dolor. Dolor voluptate incididunt sint eiusmod deserunt magna nisi. Excepteur velit Lorem irure ad excepteur id aliquip sint labore cupidatat. Adipisicing duis in laboris sunt. Sit laborum nostrud cupidatat excepteur ullamco consectetur aliqua enim est et ut.\r\n",
    "investment": "Et exercitation veniam reprehenderit irure reprehenderit dolor et laborum ex officia. Sint cupidatat laboris aliquip culpa culpa labore qui sunt magna labore aliqua eiusmod exercitation. Aute ad mollit reprehenderit aliquip aliquip non.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Do et ullamco magna Lorem incididunt enim anim nulla ad reprehenderit deserunt non culpa amet. In nisi laboris id ea eiusmod. Nulla laboris mollit minim commodo elit sit quis voluptate amet commodo proident officia ullamco consequat.\r\n",
      "engagementDescr": "Quis minim cillum incididunt nisi. Consectetur velit amet sit fugiat occaecat elit. Nulla aute laborum voluptate sit est nostrud aliqua fugiat cillum ullamco. Velit nisi nostrud in nostrud sit sint est cupidatat. Sit occaecat reprehenderit elit dolor ea officia officia sunt.\r\n",
      "dataDescr": "Proident consectetur eiusmod sit cillum culpa commodo est exercitation fugiat culpa eu. Ullamco cupidatat veniam sit cupidatat Lorem et. Labore aliqua nulla aliqua aliquip reprehenderit dolor laborum commodo eiusmod. Dolor amet duis mollit ea dolore deserunt laborum esse sit Lorem exercitation.\r\n",
      "routeDescr": "Incididunt ad mollit reprehenderit tempor mollit velit. Occaecat esse anim quis ut. Commodo minim ea proident excepteur ullamco. Sunt velit labore mollit pariatur eiusmod do dolor enim ex aliquip.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Fox Macias",
        "email": "foxmacias@liquicom.com",
        "fte": 0.0724
      },
      {
        "id": 1,
        "name": "Hodges Farmer",
        "email": "hodgesfarmer@liquicom.com",
        "fte": 0.0926
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Adela Haley",
        "email": "adelahaley@liquicom.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 13,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 42,
        "y3": 97
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 26,
        "y3": 76
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8681347,
      "customerVal": 1394122,
      "mvp": 119005854,
      "optimize": 6511778,
      "grow": 549422260,
      "scale": 1192994173
    },
    "nextStepsActionPlan": "Tempor cupidatat sit magna irure nisi ea qui reprehenderit dolore fugiat. Irure veniam nisi eiusmod nulla ullamco eu minim aliquip officia dolor incididunt nisi voluptate deserunt. Enim cupidatat cupidatat occaecat qui.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Commodo dolore culpa commodo deserunt irure laborum in qui consectetur commodo. Veniam elit veniam minim ad enim. Occaecat ut eu quis et esse incididunt tempor Lorem duis duis qui in. Adipisicing commodo ad proident duis reprehenderit adipisicing dolore nisi dolor in magna sint. Minim voluptate dolor amet quis occaecat ut consectetur ut sint consectetur velit ipsum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0310c32325c3264256",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Coriander",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Offline: Pop up store",
    "division": "Division Two",
    "painPoint": "Proident Lorem nostrud deserunt magna quis incididunt. In ut laboris occaecat quis proident excepteur irure. Nostrud sunt do dolor non esse voluptate mollit. Enim id culpa sint qui velit irure velit. Commodo aliquip est deserunt ut ea aliquip irure laboris laborum Lorem sit excepteur.\r\n",
    "description": "Aliquip aliqua quis anim qui sit ut excepteur elit officia aliquip excepteur. Sint enim ipsum do est fugiat fugiat ad. Adipisicing do sit labore velit consectetur ex sunt do nisi elit. Proident ipsum reprehenderit minim Lorem anim. Aliqua magna mollit ipsum sint tempor laborum tempor aliquip duis nulla anim laboris duis irure. Laboris sunt aute consectetur non deserunt magna occaecat nostrud.\r\n",
    "strategy": "Est velit pariatur eu dolore minim. Commodo cupidatat ullamco nostrud nulla ad nulla est labore occaecat sunt ipsum. Dolore officia aliqua duis pariatur voluptate irure deserunt. Veniam reprehenderit ipsum commodo ipsum aute anim nulla culpa veniam sunt veniam est.\r\n",
    "keyLearnings": "Commodo eiusmod eiusmod culpa exercitation nostrud. Cillum velit nisi ea sit amet esse occaecat laboris dolor ipsum nostrud consequat incididunt. Ea velit ut eu velit quis velit commodo elit commodo veniam nostrud. Deserunt sunt Lorem tempor adipisicing et reprehenderit sit eu non aute laboris deserunt sunt voluptate. Sunt duis aliqua consectetur enim exercitation exercitation voluptate. Ea ut consequat nulla dolor mollit veniam aliquip dolor commodo.\r\n",
    "nextSteps": "Minim commodo adipisicing quis pariatur. Ea dolor eiusmod non do consequat sint occaecat non eiusmod anim proident tempor veniam. Aliquip amet Lorem officia in consequat voluptate. Nostrud esse eu qui tempor in magna nisi enim consequat do eiusmod quis esse irure. Proident qui aliqua quis fugiat qui sunt. Excepteur irure proident ea veniam. Esse anim reprehenderit laborum tempor laboris aliqua.\r\n",
    "investment": "Est consectetur dolor dolor exercitation. Aute eiusmod cillum magna incididunt nulla laborum et. Velit irure ullamco veniam non tempor aliquip.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Laborum ipsum velit cupidatat nulla sunt sunt. Irure id amet irure sunt enim exercitation duis esse veniam. Exercitation duis nostrud magna culpa fugiat commodo. Cupidatat consectetur cupidatat consectetur minim ad aliqua in eiusmod pariatur. Proident culpa anim laboris pariatur minim ipsum dolore adipisicing elit fugiat ex.\r\n",
      "engagementDescr": "Cupidatat enim laborum laboris pariatur consectetur commodo quis. Esse enim eiusmod anim non minim eiusmod sunt exercitation labore aliqua pariatur sint. Ut esse ut incididunt excepteur magna consectetur non sunt duis mollit culpa fugiat aute. Qui dolore duis est fugiat tempor sint ut aliquip nisi quis fugiat cillum aliqua irure. Anim occaecat labore esse ut consequat non voluptate cupidatat in consectetur do.\r\n",
      "dataDescr": "Occaecat culpa consequat commodo aliqua qui culpa. Irure ea exercitation labore eu qui duis. Nulla nulla non quis voluptate. Ipsum culpa eu cillum adipisicing laboris dolore ullamco anim aliquip mollit amet Lorem amet. Culpa consectetur nostrud sint dolor et do deserunt ullamco cupidatat mollit anim ut laborum anim.\r\n",
      "routeDescr": "Adipisicing commodo commodo eu aute elit ullamco veniam eu dolore non. Voluptate tempor culpa duis esse ut dolore ex eiusmod amet. Ipsum duis reprehenderit cillum minim qui. Sit ea aliquip fugiat elit magna ea in laborum elit eu veniam. Qui Lorem sint enim enim qui incididunt exercitation consectetur enim.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Bernice Hutchinson",
        "email": "bernicehutchinson@coriander.com",
        "fte": 0.4598
      },
      {
        "id": 1,
        "name": "Mckay Reilly",
        "email": "mckayreilly@coriander.com",
        "fte": 0.8861
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Tina Huber",
        "email": "tinahuber@coriander.com"
      },
      {
        "id": 1,
        "name": "Gale Sandoval",
        "email": "galesandoval@coriander.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 13,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 23,
        "y3": 86
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 29,
        "y3": 80
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1822161,
      "customerVal": 370320,
      "mvp": 72725100,
      "optimize": 445633,
      "grow": 90027890,
      "scale": 1170873250
    },
    "nextStepsActionPlan": "Fugiat magna et laboris exercitation sit commodo ipsum ad dolore Lorem duis. Pariatur amet in consectetur cillum amet labore ea sint esse velit non Lorem reprehenderit. Laboris veniam culpa excepteur incididunt. Quis consequat ut eiusmod qui tempor dolore esse. Et ex fugiat ut laboris excepteur ad ad est tempor Lorem deserunt. Nulla proident ut in adipisicing magna est. Cillum exercitation id cillum elit velit enim occaecat irure officia exercitation.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt qui id qui cupidatat enim cupidatat. Occaecat fugiat et excepteur ullamco enim elit occaecat Lorem consequat ut labore. Eu tempor quis esse qui exercitation quis quis excepteur cupidatat tempor proident. Velit minim qui aliqua deserunt eu reprehenderit consectetur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-07",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b7827cf6467e19a2",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Zanity",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "Sweden",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Id adipisicing id enim do sit tempor deserunt velit id incididunt mollit. Occaecat est esse ipsum pariatur nisi pariatur sint fugiat in occaecat nulla qui do. Eu cupidatat pariatur labore excepteur ad. Labore consectetur ea ea minim est proident qui enim nostrud officia qui officia cillum nostrud. Exercitation laborum nostrud exercitation eu anim do exercitation sit esse dolore anim nisi eiusmod dolore.\r\n",
    "description": "Commodo in veniam eiusmod laboris adipisicing nostrud Lorem do esse ipsum culpa amet elit. In eiusmod mollit nulla veniam sint qui ut irure non velit Lorem velit mollit. Consectetur laboris pariatur quis adipisicing laboris aute ullamco enim laborum officia. Eu do aute eu aliquip consectetur ad cillum nisi irure do.\r\n",
    "strategy": "Sit mollit enim exercitation enim aliqua incididunt aliquip esse irure. Non ea minim pariatur laborum culpa reprehenderit ea mollit minim amet dolor aute mollit laboris. Tempor veniam dolor in velit labore laboris exercitation ad cillum eu. Ex aliquip tempor id culpa nostrud proident reprehenderit non mollit. Pariatur nulla dolor Lorem do. Aute ea magna Lorem est laborum culpa anim pariatur dolor. Deserunt non veniam consequat amet anim exercitation magna excepteur magna dolor duis exercitation in officia.\r\n",
    "keyLearnings": "Ea veniam quis nostrud duis veniam anim ullamco consequat. Ullamco consectetur nulla velit deserunt. Consequat occaecat id nisi ipsum. Incididunt officia reprehenderit velit dolore laborum incididunt voluptate amet exercitation minim cupidatat tempor aliquip elit. Sunt ut incididunt in quis cupidatat laborum sint officia dolor id sunt Lorem. Ad culpa laboris exercitation non ullamco ea exercitation exercitation ut irure. Velit fugiat consectetur officia irure excepteur dolore sunt magna sint reprehenderit velit aute et dolore.\r\n",
    "nextSteps": "Eiusmod ea fugiat et est fugiat veniam pariatur. Duis ad veniam labore anim ut sunt id culpa pariatur aliquip pariatur. Tempor qui labore laboris pariatur amet culpa velit minim ipsum Lorem deserunt velit. Deserunt ex aute non laboris fugiat aute nulla cupidatat.\r\n",
    "investment": "Dolore incididunt laboris commodo tempor anim dolor ut laborum voluptate aliqua ullamco eu veniam velit. Veniam commodo ex aliqua proident adipisicing et dolor ullamco deserunt do. Quis pariatur reprehenderit nulla aliqua culpa exercitation adipisicing officia aliqua esse dolor adipisicing deserunt cupidatat.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Est ea consectetur dolor amet labore ipsum sit nostrud. Deserunt duis ullamco cillum aliqua commodo fugiat. Aliqua fugiat adipisicing reprehenderit ex pariatur dolore in irure est.\r\n",
      "engagementDescr": "Voluptate qui culpa dolor incididunt dolor. Aliquip ut enim velit nisi. Adipisicing quis nisi et culpa id sunt adipisicing proident eu.\r\n",
      "dataDescr": "Cillum cillum aliquip exercitation minim dolore quis esse duis adipisicing. Incididunt nulla mollit fugiat laboris. Eiusmod sint veniam enim nostrud minim. Aliquip fugiat id culpa enim.\r\n",
      "routeDescr": "Minim laboris magna commodo magna excepteur id in incididunt. Ex ipsum non officia ut id ad occaecat sunt. Magna consectetur enim duis enim anim cupidatat anim ad duis. Quis dolore nisi id deserunt ut est. Cillum deserunt reprehenderit eiusmod cillum ut.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Paul Bruce",
        "email": "paulbruce@zanity.com",
        "fte": 0.886
      },
      {
        "id": 1,
        "name": "Koch Montoya",
        "email": "kochmontoya@zanity.com",
        "fte": 0.2157
      },
      {
        "id": 2,
        "name": "Isabella Thompson",
        "email": "isabellathompson@zanity.com",
        "fte": 0.6008
      },
      {
        "id": 3,
        "name": "Rhea George",
        "email": "rheageorge@zanity.com",
        "fte": 0.7129
      },
      {
        "id": 4,
        "name": "Saundra Woodard",
        "email": "saundrawoodard@zanity.com",
        "fte": 0.0814
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Randi Whitfield",
        "email": "randiwhitfield@zanity.com"
      },
      {
        "id": 1,
        "name": "Richmond Randall",
        "email": "richmondrandall@zanity.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 18,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 41,
        "y3": 55
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 26,
        "y3": 99
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4202239,
      "customerVal": 1142881,
      "mvp": 50386366,
      "optimize": 6477547,
      "grow": 834527261,
      "scale": 1182703643
    },
    "nextStepsActionPlan": "Ea tempor aliquip esse nostrud ea esse labore. Ut id ut mollit cillum. Culpa est laboris nisi enim veniam dolore nulla id magna ex reprehenderit eu velit. Do eiusmod deserunt elit laborum culpa tempor adipisicing cillum laboris nulla tempor magna deserunt. Est qui exercitation adipisicing sint aliquip id irure minim aliqua exercitation tempor. Est qui cupidatat minim non aliqua consectetur velit est occaecat mollit. Velit officia qui nisi commodo.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Labore adipisicing aliquip ullamco velit nulla mollit Lorem adipisicing eiusmod Lorem laboris ex voluptate fugiat. Aliquip ipsum sit dolore exercitation labore et aliquip. Sit anim adipisicing est aliquip et id pariatur aliquip sint aliquip ut laboris.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e038badde102b15f682",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Enomen",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "India",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "In ad Lorem voluptate elit ut est qui laborum. Quis esse ullamco Lorem ea eu consectetur amet. Elit veniam sunt pariatur ex fugiat mollit. Amet labore occaecat fugiat consequat deserunt voluptate sit sit deserunt eu dolore exercitation duis amet. Occaecat officia amet esse aute nostrud voluptate aliqua et. Nulla aliqua voluptate id incididunt culpa.\r\n",
    "description": "Id et non aute laborum esse mollit minim ullamco voluptate eiusmod Lorem Lorem velit. Cupidatat elit deserunt sunt laborum quis sit aliquip est fugiat dolore qui. Labore irure proident sit mollit amet sunt cillum velit qui. Consectetur ipsum id labore aliquip ex nisi. Ut do pariatur ipsum ex elit pariatur elit ipsum do adipisicing nisi est. Anim magna ex velit consectetur dolor reprehenderit reprehenderit dolor fugiat enim. Dolor exercitation elit duis aliqua.\r\n",
    "strategy": "Veniam et proident laboris laboris consectetur anim sunt aute dolor. Excepteur est qui sit occaecat culpa consectetur commodo sunt in velit. Fugiat tempor exercitation laborum ullamco ullamco eiusmod esse. Ea deserunt dolor adipisicing laboris dolor laboris excepteur occaecat amet in.\r\n",
    "keyLearnings": "Pariatur proident est sint excepteur excepteur proident fugiat do ullamco. Et laboris et occaecat tempor sint Lorem in dolor veniam proident nisi. Enim dolor anim excepteur amet officia. Tempor magna in voluptate anim consectetur duis exercitation incididunt duis veniam deserunt. Eiusmod deserunt dolor dolor aute adipisicing occaecat voluptate tempor id aliqua officia in esse. Quis ea dolore aliqua aute nisi id incididunt pariatur Lorem. Cupidatat culpa enim non dolore quis incididunt cillum ea in irure proident magna ex id.\r\n",
    "nextSteps": "Quis excepteur incididunt ad consequat esse id in ipsum Lorem consequat in mollit. Sunt minim officia ullamco laborum excepteur minim officia. Labore veniam aliqua et magna aute labore amet aute non. Do deserunt ad culpa commodo nisi mollit magna laborum sit cupidatat laboris adipisicing.\r\n",
    "investment": "Sunt ipsum officia tempor consequat nisi sunt cupidatat. Ullamco excepteur veniam dolor pariatur aliqua ex dolore. Culpa nulla eu aute laborum occaecat velit. Adipisicing sunt aliqua officia deserunt. Sit ad culpa ex occaecat nostrud ea ut duis id cillum deserunt labore Lorem exercitation. Aute eu consectetur ut proident ullamco.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Occaecat aute occaecat non sit deserunt ut cillum aute cillum culpa. Et ullamco quis deserunt veniam exercitation quis anim velit amet dolor ut. Nisi exercitation ad aliqua amet. Non anim ad est sunt pariatur id duis amet labore. Sint fugiat mollit cupidatat est et magna elit qui ipsum pariatur qui veniam. Adipisicing non ex in elit consequat consequat est ut veniam ea minim amet et non.\r\n",
      "engagementDescr": "Quis voluptate irure consectetur cillum excepteur sunt et anim velit fugiat non ullamco in consequat. Do deserunt enim ad id sit culpa ad sunt occaecat quis ipsum. Do excepteur nulla officia aute non non culpa consectetur ea.\r\n",
      "dataDescr": "Deserunt nostrud quis ipsum sunt commodo. Qui exercitation cillum sit dolore nostrud esse incididunt exercitation ea aute. Excepteur magna ex qui ullamco. Laboris pariatur amet eiusmod do sit exercitation.\r\n",
      "routeDescr": "Quis proident sit exercitation irure et velit nulla magna irure aliquip. Irure sunt incididunt irure culpa fugiat sunt. Aute qui esse nostrud excepteur culpa incididunt. Dolore veniam sint fugiat id.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Ingrid Cash",
        "email": "ingridcash@enomen.com",
        "fte": 0.8925
      },
      {
        "id": 1,
        "name": "Leila Bird",
        "email": "leilabird@enomen.com",
        "fte": 0.8438
      },
      {
        "id": 2,
        "name": "Reyes Levy",
        "email": "reyeslevy@enomen.com",
        "fte": 0.2545
      },
      {
        "id": 3,
        "name": "Jimmie Woods",
        "email": "jimmiewoods@enomen.com",
        "fte": 0.6153
      },
      {
        "id": 4,
        "name": "Houston Harding",
        "email": "houstonharding@enomen.com",
        "fte": 0.1686
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Hudson Lawson",
        "email": "hudsonlawson@enomen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 10,
        "y3": 21
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 4,
        "y2": 36,
        "y3": 83
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 14,
        "y3": 58
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3190849,
      "customerVal": 513729,
      "mvp": 127109040,
      "optimize": 1357749,
      "grow": 17274463,
      "scale": 1264654534
    },
    "nextStepsActionPlan": "Est ullamco irure adipisicing et et. Do fugiat tempor elit culpa. Ea cillum aliquip ex ea anim ut ut pariatur. Amet esse occaecat qui adipisicing commodo. Eu non culpa velit voluptate occaecat culpa aute cupidatat excepteur exercitation consectetur amet aliquip laboris. Veniam velit anim mollit velit qui aliqua velit qui dolore irure officia exercitation irure. Eu nisi in proident et enim culpa.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Lorem culpa nulla est sit nulla aliquip enim minim do tempor. Nostrud labore eu ullamco adipisicing non aliqua ullamco occaecat veniam labore. Incididunt consequat consectetur excepteur in cupidatat ipsum aliqua pariatur. Ullamco amet esse deserunt veniam id et pariatur aliqua aliquip enim eu eu. Laboris quis ex ullamco nulla esse voluptate magna ullamco nisi. Adipisicing et et dolor aute sit ut. Mollit laborum voluptate nisi magna et excepteur deserunt id magna eu aliquip commodo in commodo.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-13",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03f403c038eefbac71",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Comcur",
    "highlightKPI": [
      "cac",
      "ltv",
      "rp"
    ],
    "geography": "China",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Commodo Lorem laboris amet excepteur. Voluptate ipsum voluptate consequat et tempor ipsum ex occaecat. Proident occaecat fugiat consequat enim Lorem labore mollit.\r\n",
    "description": "Est esse sint adipisicing cillum nulla. Exercitation consectetur Lorem voluptate enim duis. Deserunt dolor deserunt nisi ipsum magna non aliquip commodo et magna excepteur. Proident enim velit amet sint eu duis ut consectetur quis duis quis mollit. Cillum dolore amet adipisicing non proident. Deserunt ad culpa ea culpa enim nulla nisi.\r\n",
    "strategy": "Sunt est ullamco quis dolore aliqua incididunt sint irure sint. Incididunt elit minim laborum laboris id magna esse eu adipisicing. Culpa sit ad aliqua amet eiusmod velit eiusmod minim nisi.\r\n",
    "keyLearnings": "Deserunt cupidatat amet id aute mollit sunt commodo Lorem proident qui. Magna duis mollit tempor enim. Fugiat aliquip laboris consectetur duis adipisicing. Ea laborum voluptate adipisicing officia enim exercitation.\r\n",
    "nextSteps": "Consequat ad culpa enim aute nisi Lorem. Laboris nostrud excepteur cillum excepteur quis culpa. Voluptate eu laboris ut fugiat fugiat veniam ex id aliquip. Aute et exercitation qui ex occaecat laboris anim magna est eiusmod sit quis. Mollit exercitation ut dolore est. Ea magna incididunt deserunt labore dolore ad velit consequat. Proident eiusmod sit dolor ullamco est magna enim eu in nisi velit nisi.\r\n",
    "investment": "Nostrud irure incididunt qui anim excepteur. Nisi tempor sunt voluptate nisi do deserunt fugiat ut mollit Lorem nulla irure adipisicing ipsum. Adipisicing sint ullamco laboris pariatur est adipisicing quis dolor enim est velit culpa. Elit reprehenderit veniam laborum ad tempor veniam pariatur.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Id mollit reprehenderit cupidatat sit aliqua Lorem commodo ut ad labore. Exercitation sunt amet quis pariatur Lorem laboris excepteur deserunt laborum. Veniam Lorem amet aliqua occaecat amet. Ut elit commodo enim Lorem sit elit quis culpa fugiat ea elit non. Anim ullamco anim mollit esse occaecat aute duis.\r\n",
      "engagementDescr": "Fugiat aliquip laborum irure cupidatat anim aute anim ullamco fugiat et officia sint exercitation. Non dolore laborum incididunt aliquip ut. Minim ea proident non culpa cillum nisi. Elit elit dolor irure mollit nostrud aliqua aliqua nisi ullamco ipsum ad labore Lorem Lorem. Labore nulla Lorem aliqua esse officia exercitation Lorem. Nisi magna ex anim sint culpa irure ex. Nisi ad cupidatat esse incididunt aliquip enim dolor.\r\n",
      "dataDescr": "Sint in consectetur elit proident velit eu minim eiusmod. Quis eu reprehenderit occaecat enim culpa nisi deserunt ullamco. Veniam officia voluptate excepteur culpa sunt aliqua consequat magna irure duis aliquip esse. Excepteur quis ea dolor reprehenderit irure nostrud duis laboris ipsum aliquip Lorem magna. Consectetur officia ullamco eu do esse amet aliquip dolor est anim aute pariatur duis. Consectetur duis adipisicing amet excepteur aute dolore aliquip sit dolore esse.\r\n",
      "routeDescr": "Fugiat Lorem ullamco veniam occaecat sint. Sunt aliqua in ut velit adipisicing dolore elit ullamco ullamco et. Non officia nostrud amet tempor aute. Sint amet nulla sunt esse dolor ut magna non culpa. Cillum do anim eiusmod eiusmod eu consectetur fugiat non. Officia labore laborum ut anim ad sint qui duis dolor dolor commodo qui fugiat. Nostrud nisi ad reprehenderit velit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Sullivan Montgomery",
        "email": "sullivanmontgomery@comcur.com",
        "fte": 0.5449
      },
      {
        "id": 1,
        "name": "Mayra Pacheco",
        "email": "mayrapacheco@comcur.com",
        "fte": 0.0281
      },
      {
        "id": 2,
        "name": "Stacie Marshall",
        "email": "staciemarshall@comcur.com",
        "fte": 0.3892
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Melendez Boyd",
        "email": "melendezboyd@comcur.com"
      },
      {
        "id": 1,
        "name": "Herring Kim",
        "email": "herringkim@comcur.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 16,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 43,
        "y3": 79
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 20,
        "y3": 75
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10635516,
      "customerVal": 1330293,
      "mvp": 40077640,
      "optimize": 595383,
      "grow": 271890767,
      "scale": 816100124
    },
    "nextStepsActionPlan": "Irure elit eiusmod eiusmod dolor consectetur consectetur sit cillum nostrud reprehenderit. Tempor voluptate consequat eiusmod fugiat enim aliquip magna. Dolor nisi qui mollit reprehenderit elit sint. Culpa id dolor sint ut deserunt incididunt irure pariatur deserunt laborum do excepteur labore. Duis ex incididunt nisi tempor in velit aliquip. Nostrud ea et fugiat officia voluptate.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Incididunt aliquip nostrud aliqua eu dolore tempor magna ullamco non nostrud. Et veniam dolor id est ex nisi occaecat ea do cillum tempor ad. Aliqua veniam voluptate anim adipisicing incididunt aliqua proident ut exercitation veniam non ipsum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-09-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c6a1879a3d9ed60e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Tropolis",
    "highlightKPI": [
      "nsv",
      "gmac",
      "tc"
    ],
    "geography": "Italy",
    "channel": "Online: Social media",
    "division": "Division Three",
    "painPoint": "Labore occaecat amet ullamco culpa pariatur cillum occaecat. Commodo deserunt est minim mollit est ex. Dolor est incididunt magna in quis duis ea qui velit. Duis quis Lorem adipisicing ad pariatur deserunt reprehenderit adipisicing non sit. Ad ea anim ea ad nostrud elit nostrud.\r\n",
    "description": "Non magna sunt minim Lorem velit. Sunt irure ea ullamco duis dolore ea esse irure qui labore nisi minim eiusmod. Nisi dolore et reprehenderit est ipsum culpa officia nostrud. Ex incididunt dolore officia magna tempor ex in. Aute qui qui consequat cupidatat irure dolore velit quis id. Nulla adipisicing aute mollit minim aliquip nisi. Nulla exercitation eiusmod proident minim non et eiusmod aute anim ipsum mollit magna.\r\n",
    "strategy": "Laborum et Lorem consequat sunt sit eu. Irure commodo ipsum amet adipisicing do reprehenderit anim reprehenderit cillum aliquip aute minim culpa eu. Quis do irure qui aute cillum nisi. Fugiat irure cupidatat eiusmod tempor pariatur laboris cillum irure officia. Ad nulla qui incididunt ut et velit fugiat.\r\n",
    "keyLearnings": "Esse nostrud magna ipsum exercitation labore tempor ea et quis excepteur aliqua tempor quis qui. Nulla amet Lorem non aliquip tempor ea. Culpa magna eiusmod nulla ex anim irure incididunt. Velit sint quis Lorem proident exercitation pariatur eu quis.\r\n",
    "nextSteps": "Elit non consequat magna Lorem esse pariatur exercitation veniam. Non quis eiusmod labore quis ipsum nisi ea magna cillum. Consequat est incididunt quis ut est ullamco non culpa consequat velit amet labore. Culpa veniam pariatur sunt ad. Id incididunt adipisicing do laboris dolore ipsum mollit aute elit. Non commodo occaecat eu consequat veniam pariatur ad officia in.\r\n",
    "investment": "Sit sunt consectetur voluptate eu est nisi adipisicing esse proident est commodo quis. Minim consequat labore aliqua cupidatat tempor amet et est sit cillum adipisicing sit cillum duis. Ullamco laborum occaecat do proident. Fugiat voluptate in esse ut deserunt officia quis eiusmod aliquip aute. Occaecat commodo minim consequat reprehenderit sunt laboris occaecat anim in aute. Laboris qui elit duis mollit laborum minim.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Dolor amet deserunt deserunt Lorem exercitation incididunt exercitation. Quis sit pariatur voluptate veniam duis elit consectetur consectetur duis ad enim duis minim esse. Veniam adipisicing labore anim veniam veniam qui commodo officia ea tempor id enim.\r\n",
      "engagementDescr": "Consequat aliqua consequat aliquip esse esse consequat dolore ad et. Elit cupidatat ea consectetur veniam ex non dolore ad quis laboris voluptate adipisicing proident. Sunt ipsum id fugiat enim.\r\n",
      "dataDescr": "Est elit sint reprehenderit incididunt sit amet aliquip tempor dolore nulla occaecat sunt fugiat ut. Sit tempor aliquip nostrud sunt enim minim laboris nisi aute aute cillum minim id. Minim qui irure exercitation est. Commodo ut magna ut cillum. Duis labore reprehenderit deserunt eiusmod quis mollit nisi sunt sit laborum excepteur dolor in do.\r\n",
      "routeDescr": "Non nulla proident ex incididunt sint ipsum nostrud adipisicing minim dolor voluptate amet et. Nulla enim consequat sit ullamco pariatur pariatur labore. Qui do esse voluptate magna eiusmod sunt dolore. Dolor id commodo irure quis ipsum ad esse ex magna elit non proident. Pariatur pariatur deserunt culpa elit fugiat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Tamara Espinoza",
        "email": "tamaraespinoza@tropolis.com",
        "fte": 0.394
      },
      {
        "id": 1,
        "name": "Gena Miller",
        "email": "genamiller@tropolis.com",
        "fte": 0.0286
      },
      {
        "id": 2,
        "name": "Katy Lynn",
        "email": "katylynn@tropolis.com",
        "fte": 0.4066
      },
      {
        "id": 3,
        "name": "Ortiz Lowe",
        "email": "ortizlowe@tropolis.com",
        "fte": 0.2887
      },
      {
        "id": 4,
        "name": "Bianca Noel",
        "email": "biancanoel@tropolis.com",
        "fte": 0.6902
      },
      {
        "id": 5,
        "name": "Jessie Holcomb",
        "email": "jessieholcomb@tropolis.com",
        "fte": 0.7057
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Adkins Long",
        "email": "adkinslong@tropolis.com"
      },
      {
        "id": 1,
        "name": "Atkins Faulkner",
        "email": "atkinsfaulkner@tropolis.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 12,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 31,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 30,
        "y3": 70
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 553022,
      "customerVal": 1298696,
      "mvp": 36455367,
      "optimize": 6626783,
      "grow": 131747129,
      "scale": 1015853546
    },
    "nextStepsActionPlan": "Quis duis quis amet cupidatat nisi est labore occaecat aliquip. Laboris dolore enim adipisicing adipisicing consectetur proident dolor magna occaecat proident non. Fugiat duis voluptate laboris amet. Ea nulla laboris magna consequat consectetur ut dolor do ullamco ut laborum sit adipisicing.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ut duis occaecat ullamco ullamco tempor. Ut irure quis magna est duis eu aliqua et. Eiusmod duis mollit do esse sit non in qui culpa anim ipsum incididunt labore est. Nostrud sunt cupidatat officia ea adipisicing dolore labore amet nisi voluptate elit deserunt eu. Pariatur ipsum laborum magna quis sunt.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-23",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03312f129a728aed1a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Flexigen",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rp"
    ],
    "geography": "Spain",
    "channel": "Online: Social media",
    "division": "Division Three",
    "painPoint": "Excepteur dolore cillum elit excepteur aute aliqua dolor dolore est deserunt. Anim et voluptate aliquip fugiat proident in minim sunt eu. Duis fugiat ut ex sunt aute irure minim deserunt. Dolor ullamco ipsum nostrud nisi nisi cupidatat incididunt. Culpa minim non duis cupidatat esse quis eu duis duis.\r\n",
    "description": "Ad Lorem velit velit anim irure excepteur velit commodo. Quis enim laboris occaecat labore. Nisi magna enim Lorem consectetur qui quis laborum. Aliqua voluptate cupidatat amet exercitation cillum mollit nostrud minim cillum consequat culpa nulla commodo. Pariatur ea Lorem enim reprehenderit eiusmod quis et amet ut cillum esse do. Nostrud cillum ullamco ullamco cupidatat voluptate eu ad consequat elit velit sunt.\r\n",
    "strategy": "Esse nostrud nisi ad exercitation. Commodo elit excepteur elit excepteur dolor irure laborum irure occaecat mollit minim. Laborum eu anim ex voluptate eu esse amet. Voluptate dolor nulla do elit do duis ea nulla est Lorem cupidatat laborum consequat. Et non est ea voluptate consectetur incididunt excepteur enim amet do.\r\n",
    "keyLearnings": "Dolor nulla consectetur minim adipisicing pariatur sit mollit pariatur dolore. Duis cillum id aliquip incididunt qui dolor ex do aliquip nisi sunt enim reprehenderit. Est exercitation minim cupidatat aliquip ea magna quis magna ullamco non ut et.\r\n",
    "nextSteps": "Aute culpa aute eiusmod amet magna excepteur commodo laborum irure proident sint velit laborum velit. Ipsum commodo in dolor irure aute dolor proident fugiat ad exercitation. Do exercitation non veniam esse nulla pariatur ex ea amet. Cupidatat in consequat id cillum adipisicing incididunt. Aliquip ut magna amet sunt duis sunt adipisicing dolore dolore. Nisi sunt Lorem enim in sunt et eu eiusmod dolor. Velit veniam est et in eu aute est pariatur aliqua pariatur do adipisicing.\r\n",
    "investment": "Voluptate amet do incididunt excepteur culpa laboris. Cillum fugiat enim tempor tempor sit exercitation ea. Dolor velit esse reprehenderit eiusmod in cillum fugiat consequat eu est. Enim pariatur amet eiusmod qui irure.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Id esse anim nostrud do enim eu pariatur amet aliquip aliqua minim deserunt fugiat cillum. Quis nulla voluptate enim dolore. Qui aliqua magna proident irure ipsum duis sunt consectetur est occaecat id.\r\n",
      "engagementDescr": "Ut quis id commodo duis quis veniam dolore mollit. Nostrud anim enim deserunt dolor quis cillum amet est quis officia aliqua do consectetur quis. Pariatur ipsum velit ut amet adipisicing. Voluptate velit eu irure consectetur velit voluptate elit reprehenderit do Lorem laborum eu duis. Ut nostrud voluptate velit pariatur aute. Enim ea exercitation culpa Lorem irure voluptate aute id. Minim proident elit in ex laboris laboris pariatur.\r\n",
      "dataDescr": "Laborum quis sint Lorem cillum ex et eiusmod quis. Proident laboris sit commodo cupidatat. Irure ullamco eu enim duis ullamco excepteur ipsum proident in ad elit aliqua fugiat mollit. Fugiat labore irure consectetur ullamco non sunt.\r\n",
      "routeDescr": "Sit amet ea veniam do sit voluptate laboris adipisicing eiusmod eu deserunt. Exercitation aliqua irure ea sunt minim sunt excepteur consectetur reprehenderit. Cupidatat quis duis dolore irure eiusmod ullamco ullamco aliqua consequat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Casey Rose",
        "email": "caseyrose@flexigen.com",
        "fte": 0.8083
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Rodriguez Vincent",
        "email": "rodriguezvincent@flexigen.com"
      },
      {
        "id": 1,
        "name": "Mosley Small",
        "email": "mosleysmall@flexigen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 20,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 40,
        "y3": 70
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 29,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6273070,
      "customerVal": 769317,
      "mvp": 124449281,
      "optimize": 2008086,
      "grow": 775918623,
      "scale": 479381734
    },
    "nextStepsActionPlan": "Dolore et Lorem culpa voluptate. Ex mollit eu ipsum ut amet velit tempor adipisicing anim qui minim. Eiusmod dolore veniam adipisicing nostrud non elit nostrud deserunt velit irure. Pariatur ad fugiat commodo id aliquip deserunt ex esse officia ex cillum nisi. Do labore enim veniam veniam voluptate consectetur mollit adipisicing pariatur adipisicing excepteur voluptate. Id elit nulla exercitation reprehenderit voluptate id incididunt pariatur. Incididunt in aliqua deserunt do labore magna quis esse excepteur elit esse elit do culpa.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Consectetur sunt enim occaecat aliquip anim eu. Cillum sunt anim ut exercitation consequat. Est culpa consequat cillum nisi non proident laborum elit irure in commodo. Veniam Lorem elit ipsum deserunt reprehenderit. Consectetur dolor esse sint eu laborum est est quis sunt nostrud veniam nostrud. Voluptate in exercitation ex exercitation veniam deserunt sit cupidatat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03359faa164047422e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Moltonic",
    "highlightKPI": [
      "cac",
      "gmac",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Sit duis pariatur laborum id commodo laboris nulla labore qui. Laborum do commodo deserunt occaecat esse. Laboris anim ut esse nulla exercitation laborum id ad. Duis anim exercitation magna non aliquip proident eiusmod aliqua fugiat magna. Sint consectetur et cupidatat duis consectetur est. Lorem ipsum cupidatat deserunt ea ea dolore consequat ut velit anim pariatur anim enim. Incididunt deserunt id id magna et laborum voluptate sunt proident.\r\n",
    "description": "Non culpa sint mollit sit ad laboris est non cillum ullamco. Incididunt aliquip anim sit laboris in quis labore. Consectetur qui Lorem sint dolore sit reprehenderit mollit aliquip irure anim labore quis nostrud minim. Ex ea aute incididunt proident in consequat. Tempor consectetur aliqua labore nulla adipisicing ipsum exercitation quis tempor qui nisi.\r\n",
    "strategy": "Pariatur cillum reprehenderit ipsum in commodo duis id quis fugiat veniam. Magna Lorem dolore eu excepteur quis est aute est velit sint deserunt nostrud. Sunt magna deserunt ex pariatur pariatur esse esse occaecat ea amet fugiat est cupidatat. Ex veniam anim laborum anim esse ut sunt laboris.\r\n",
    "keyLearnings": "Anim laborum id mollit excepteur occaecat est nisi ex consequat labore qui enim deserunt. Non fugiat do voluptate labore aute ea aliqua. Eiusmod occaecat id ea ad duis consectetur sint do aute est dolor. Occaecat sit ut cupidatat quis fugiat enim Lorem. Exercitation sit nostrud elit magna. Magna cupidatat enim Lorem exercitation veniam eu excepteur exercitation aliquip veniam.\r\n",
    "nextSteps": "Aliqua nisi ullamco magna dolore nostrud do labore laborum. Dolore officia reprehenderit et ipsum veniam. Voluptate aliquip qui culpa reprehenderit fugiat excepteur.\r\n",
    "investment": "Sit elit pariatur proident non adipisicing tempor ullamco nulla non elit incididunt. Dolor adipisicing laborum tempor eu id. Est magna eu dolore labore excepteur dolor. Do do mollit id dolore reprehenderit culpa nulla do elit. Aliqua voluptate esse eiusmod incididunt sint ea laboris. Irure labore magna cupidatat consequat enim irure fugiat tempor sint excepteur velit ea.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Mollit aliquip consequat irure laborum proident pariatur. Esse ipsum irure esse id. Voluptate aliquip proident non est dolor. Consequat tempor anim dolore duis magna ea deserunt occaecat est. Excepteur est labore eiusmod pariatur consectetur.\r\n",
      "engagementDescr": "Tempor irure nisi cupidatat eu. Reprehenderit mollit consequat consequat laborum consequat ea. Qui labore id consequat exercitation non ad excepteur irure nostrud. Consequat qui est aliquip nulla duis tempor excepteur officia anim duis id consequat veniam. Qui minim aute eiusmod proident in dolore dolor sint tempor. Id ut officia do velit ut eiusmod sint laboris. Magna et officia cillum nisi consequat.\r\n",
      "dataDescr": "Incididunt sit dolore sunt ea enim nostrud ea incididunt et consectetur sint elit. Occaecat reprehenderit quis consequat occaecat eiusmod consectetur reprehenderit fugiat dolore proident laborum nulla id. Id do nisi voluptate voluptate consequat nostrud. Ullamco magna qui nulla aliquip magna. Dolor aute esse minim ullamco occaecat amet.\r\n",
      "routeDescr": "Magna laboris aute cupidatat sint in. Amet esse tempor incididunt esse ullamco dolore et quis labore aute ex officia amet. Amet magna est ea occaecat enim dolor.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Williamson Mcclure",
        "email": "williamsonmcclure@moltonic.com",
        "fte": 0.758
      },
      {
        "id": 1,
        "name": "Barron Shaffer",
        "email": "barronshaffer@moltonic.com",
        "fte": 0.2037
      },
      {
        "id": 2,
        "name": "Diane Franco",
        "email": "dianefranco@moltonic.com",
        "fte": 0.4226
      },
      {
        "id": 3,
        "name": "Juanita Bell",
        "email": "juanitabell@moltonic.com",
        "fte": 0.194
      },
      {
        "id": 4,
        "name": "Wheeler Young",
        "email": "wheeleryoung@moltonic.com",
        "fte": 0.6373
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Liza Harvey",
        "email": "lizaharvey@moltonic.com"
      },
      {
        "id": 1,
        "name": "Edith Morin",
        "email": "edithmorin@moltonic.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 8,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 48,
        "y3": 66
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 10,
        "y3": 43
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11331732,
      "customerVal": 1481969,
      "mvp": 27400858,
      "optimize": 4062789,
      "grow": 671903747,
      "scale": 275880950
    },
    "nextStepsActionPlan": "Pariatur tempor nulla eiusmod minim. Lorem in nulla officia nostrud incididunt in elit nisi. Nulla sunt adipisicing sint non sint minim ut qui in officia laboris esse cupidatat do.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Exercitation eiusmod et proident aute nulla eiusmod reprehenderit labore anim. Culpa velit magna excepteur occaecat culpa. Aliquip elit eu non nisi laboris ipsum in excepteur nostrud cupidatat eiusmod.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-26",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e031ddd80cc3d919758",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Nutralab",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "United States",
    "channel": "Online: Shop in shop",
    "division": "Division Two",
    "painPoint": "Aute commodo veniam velit commodo ullamco reprehenderit qui eu in ullamco minim. Velit culpa velit et culpa occaecat dolor elit cupidatat deserunt commodo ut adipisicing magna. Laboris in quis id commodo. Eu proident eu reprehenderit anim officia labore duis minim ipsum eu. Est nulla velit occaecat dolore.\r\n",
    "description": "Cillum dolore id ut enim dolore nisi enim in officia. Consectetur dolor magna ut proident velit laboris. Pariatur officia commodo sint non consequat aute ex non minim anim eu cupidatat. Aliquip anim esse sunt sit elit nostrud. Enim mollit est mollit consectetur commodo.\r\n",
    "strategy": "In excepteur proident nisi do veniam velit qui in. Duis anim anim occaecat nisi excepteur sint. Magna ea quis ad non laboris incididunt magna incididunt. Commodo nulla tempor cupidatat do qui amet amet laborum cupidatat id tempor. Quis esse eiusmod mollit ipsum sunt proident anim laboris dolor.\r\n",
    "keyLearnings": "Ad duis occaecat ad enim veniam reprehenderit occaecat quis pariatur reprehenderit laboris. Elit cillum deserunt est ipsum ullamco officia nulla id cupidatat laboris. Consectetur minim minim id ullamco consectetur consectetur excepteur Lorem. Mollit laboris dolor dolore nostrud consequat duis id tempor elit anim velit est aliquip.\r\n",
    "nextSteps": "Cillum qui officia fugiat sunt occaecat ea dolor et aute reprehenderit ut. Sunt non nostrud ex esse esse nisi minim. Ad minim voluptate qui in minim qui laborum officia qui exercitation eu proident. Minim qui minim aliqua reprehenderit ea. Anim dolore do nulla aliquip anim enim eu velit nisi. Cupidatat sit anim nostrud adipisicing veniam ullamco cillum adipisicing deserunt consequat fugiat ad. Culpa consectetur officia consectetur ad.\r\n",
    "investment": "Eu nulla ea dolore amet culpa exercitation veniam sit. Consequat dolore est nostrud veniam occaecat dolor commodo sit tempor adipisicing aliqua ex laboris. Eu tempor pariatur aliqua quis aliquip et Lorem laboris minim irure esse. Proident ea proident reprehenderit reprehenderit occaecat incididunt voluptate id deserunt. Tempor Lorem nulla sit consectetur veniam labore.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod esse commodo cillum qui aute do reprehenderit in dolore elit. Amet amet elit laborum incididunt duis pariatur sunt non. Do qui occaecat pariatur velit amet eiusmod ea eu. Quis laborum dolor deserunt sunt. Cupidatat excepteur nisi culpa quis Lorem anim occaecat aute est id sit officia non commodo. Consectetur labore enim laborum culpa sunt. Tempor nostrud do do Lorem ipsum anim aliqua aliqua esse.\r\n",
      "engagementDescr": "Mollit incididunt consequat proident officia amet dolore ex qui eu nulla ullamco. Est aute magna in cupidatat reprehenderit cillum qui aute. Ullamco voluptate consectetur ea qui irure Lorem sit velit ipsum esse. Ea ullamco nulla enim dolore nostrud sint deserunt eu in nulla. Anim occaecat aliquip ullamco nisi eu. Sint veniam id veniam officia officia cupidatat amet cillum minim occaecat. Eiusmod do pariatur eu ad ipsum ad ex quis ipsum est non deserunt.\r\n",
      "dataDescr": "Veniam occaecat amet enim deserunt nisi incididunt officia. Do ex Lorem anim ullamco cupidatat voluptate cillum in laborum velit incididunt veniam quis. Dolor sit excepteur id labore quis occaecat consectetur minim culpa in minim aute incididunt commodo. Labore minim nulla ut ex fugiat sint cillum sit irure velit labore. Non non occaecat consectetur est tempor. Qui irure cillum nulla ut aliqua deserunt.\r\n",
      "routeDescr": "Duis nostrud quis minim qui proident. Ipsum est do occaecat exercitation ad aliqua ut in. Dolor aliquip do ea do voluptate ullamco exercitation.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Jodi Clay",
        "email": "jodiclay@nutralab.com",
        "fte": 0.841
      },
      {
        "id": 1,
        "name": "Wade Zamora",
        "email": "wadezamora@nutralab.com",
        "fte": 0.9267
      },
      {
        "id": 2,
        "name": "Elma Mayo",
        "email": "elmamayo@nutralab.com",
        "fte": 0.3682
      },
      {
        "id": 3,
        "name": "Walsh Buchanan",
        "email": "walshbuchanan@nutralab.com",
        "fte": 0.6905
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Carly Russo",
        "email": "carlyrusso@nutralab.com"
      },
      {
        "id": 1,
        "name": "Sheena Albert",
        "email": "sheenaalbert@nutralab.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 10,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 57,
        "y3": 67
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 23,
        "y3": 47
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2766272,
      "customerVal": 1463341,
      "mvp": 100377497,
      "optimize": 12852260,
      "grow": 150171949,
      "scale": 355414330
    },
    "nextStepsActionPlan": "Veniam tempor ea ullamco ullamco mollit ullamco minim sint sit reprehenderit. Et minim fugiat nostrud dolor mollit. Laboris dolor irure officia ex elit sit labore culpa pariatur id quis do magna proident. Eu id ipsum labore nulla officia laboris ut officia minim dolore exercitation qui eiusmod. Incididunt occaecat amet anim consectetur ut magna sint irure laboris duis. Magna sit deserunt aliquip dolore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Anim amet exercitation commodo ad nulla nostrud sint dolor ipsum anim dolor laboris esse. Fugiat anim aliquip ut sint elit. Quis cupidatat sit irure magna aute quis commodo tempor aliquip. Cillum et officia ipsum consectetur proident deserunt esse fugiat laborum voluptate eu enim consectetur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-11",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03576fa27a91714f44",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Frolix",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Reprehenderit ut excepteur duis nostrud enim mollit aliquip incididunt eu fugiat sunt veniam enim aliquip. Fugiat aliquip laborum reprehenderit in do elit sint eu. Minim nulla dolore labore reprehenderit id ad non magna et sunt exercitation do. Amet anim elit fugiat minim. Labore fugiat adipisicing veniam nostrud deserunt. Elit velit magna laborum aliquip. Eiusmod adipisicing eu Lorem incididunt tempor.\r\n",
    "description": "Mollit irure qui enim laboris qui id pariatur dolor eu. Quis ut tempor nostrud eiusmod labore. Consectetur laboris magna commodo laborum ipsum dolor nulla Lorem commodo veniam consectetur magna deserunt.\r\n",
    "strategy": "Ipsum proident proident aliqua quis et reprehenderit commodo deserunt fugiat ea laborum velit pariatur. Reprehenderit ullamco exercitation Lorem irure tempor eiusmod adipisicing proident est nulla minim tempor. Minim nulla Lorem ipsum nulla eiusmod labore elit laborum do ut aliquip exercitation incididunt. Aliqua eu id veniam consequat consectetur quis dolore magna exercitation.\r\n",
    "keyLearnings": "Officia cupidatat tempor eiusmod labore incididunt aliqua. Lorem fugiat nostrud Lorem laborum commodo eu sint elit. Sunt veniam do id ipsum eiusmod.\r\n",
    "nextSteps": "Sit do irure dolor officia velit mollit sit duis sunt labore do sint. Duis ipsum qui cupidatat cupidatat velit esse aute do. Enim deserunt aliqua amet voluptate excepteur duis deserunt ea velit consectetur culpa nostrud incididunt. Excepteur eu ex nisi fugiat esse occaecat do est deserunt. Velit ea officia cillum aliquip incididunt ex sit quis tempor non.\r\n",
    "investment": "Laboris in dolor pariatur consectetur excepteur consectetur ex Lorem cupidatat culpa commodo incididunt. Consectetur Lorem culpa excepteur aliqua amet culpa officia laboris mollit esse enim ullamco culpa id. Minim aute cillum sit tempor. Velit duis sunt est tempor non cupidatat irure eiusmod id ex sunt labore nulla. Ullamco ipsum amet veniam velit sint id magna minim ullamco quis cupidatat deserunt. Ut aute Lorem commodo deserunt dolor enim culpa quis tempor ullamco sunt tempor. Nulla commodo nostrud laborum aliquip et dolor deserunt nostrud incididunt qui nulla reprehenderit.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Amet ipsum tempor in dolore amet culpa do aliqua nisi excepteur nulla. Commodo dolor sit aliquip aute laborum labore nostrud nisi eu Lorem proident consectetur et non. Amet enim eu dolore ut consequat exercitation nulla magna ea aliquip ad. In minim pariatur enim exercitation enim. Id anim cupidatat adipisicing labore cupidatat consequat. Ad quis do aliquip nulla. Incididunt excepteur commodo magna reprehenderit et occaecat dolore quis pariatur nulla eu quis.\r\n",
      "engagementDescr": "Duis adipisicing pariatur qui proident pariatur ipsum pariatur sunt deserunt amet eu in. Pariatur ad incididunt dolore nisi. Aliquip qui culpa veniam officia enim.\r\n",
      "dataDescr": "Ad do cupidatat consectetur irure mollit veniam culpa. Velit enim non enim mollit irure ullamco ex velit Lorem qui excepteur. Consequat esse ad esse labore dolor duis labore fugiat quis. Non aute consectetur proident aliqua consequat dolore dolore occaecat proident fugiat nostrud commodo in. Non reprehenderit aute tempor ipsum irure aute sunt eu duis elit quis laboris id esse. Proident qui do eu incididunt deserunt qui.\r\n",
      "routeDescr": "Adipisicing in qui sint dolore dolore aliquip exercitation in magna quis sunt. Ea proident anim nisi dolore tempor culpa eu. Ipsum consequat Lorem irure enim pariatur do eiusmod fugiat veniam dolore eiusmod nostrud et eu.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Sharron Oconnor",
        "email": "sharronoconnor@frolix.com",
        "fte": 0.2848
      },
      {
        "id": 1,
        "name": "Melisa Roach",
        "email": "melisaroach@frolix.com",
        "fte": 0.3145
      },
      {
        "id": 2,
        "name": "Gibbs Aguilar",
        "email": "gibbsaguilar@frolix.com",
        "fte": 0.0619
      },
      {
        "id": 3,
        "name": "Sherman Sampson",
        "email": "shermansampson@frolix.com",
        "fte": 0.1327
      },
      {
        "id": 4,
        "name": "Fitzpatrick Solis",
        "email": "fitzpatricksolis@frolix.com",
        "fte": 0.8702
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Monique Hodge",
        "email": "moniquehodge@frolix.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 12,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 10,
        "y2": 35,
        "y3": 52
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 12,
        "y3": 52
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4754804,
      "customerVal": 634868,
      "mvp": 141092452,
      "optimize": 9569684,
      "grow": 69720151,
      "scale": 136026039
    },
    "nextStepsActionPlan": "Laboris voluptate excepteur proident exercitation commodo cillum nulla do adipisicing esse dolor. Pariatur commodo adipisicing sunt ipsum duis qui cupidatat incididunt occaecat magna. Commodo magna do in consectetur reprehenderit pariatur tempor nulla.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Magna nisi aute adipisicing do laboris deserunt ex id do consequat aliquip ex sunt ipsum. Cillum reprehenderit qui aliquip adipisicing incididunt adipisicing incididunt exercitation eu aute anim cupidatat. Aliqua incididunt laboris proident id mollit aliquip labore laboris.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03ebe05eaf157ea715",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Satiance",
    "highlightKPI": [
      "cac",
      "gmac",
      "rp"
    ],
    "geography": "Italy",
    "channel": "Offline: Pop up store",
    "division": "Division One",
    "painPoint": "Voluptate culpa dolor officia minim. Elit voluptate ullamco proident enim. Ut adipisicing proident nulla exercitation pariatur irure id. Esse ut et minim pariatur eu consequat. Mollit labore et enim velit aliquip mollit irure ex ut enim mollit. Exercitation est officia adipisicing deserunt quis eu sint.\r\n",
    "description": "Et quis ullamco ipsum Lorem. Culpa ea consectetur commodo esse veniam qui labore est dolore aute minim. Duis occaecat veniam commodo ullamco anim laboris nostrud nisi nostrud enim aute aliquip quis.\r\n",
    "strategy": "In pariatur exercitation veniam est aliquip fugiat et ipsum et minim elit ex nostrud laborum. Consequat anim laborum enim nisi aliqua amet deserunt deserunt ullamco et amet velit irure Lorem. Magna veniam ipsum esse voluptate ullamco exercitation cupidatat eu nisi. Velit id pariatur amet laborum pariatur magna excepteur id excepteur. Exercitation pariatur labore in aliquip irure elit id dolor. Reprehenderit exercitation anim elit ex pariatur pariatur exercitation proident labore proident. Commodo eu laboris magna consequat aute incididunt magna anim.\r\n",
    "keyLearnings": "Incididunt duis ad sint occaecat. Exercitation do labore consectetur dolore culpa tempor occaecat officia proident esse officia. Ut eu aute qui minim.\r\n",
    "nextSteps": "Laborum irure irure tempor tempor excepteur quis ut culpa nostrud incididunt eu quis. Aute amet ad occaecat aute culpa nisi fugiat nulla. Lorem est eiusmod est elit dolore cillum mollit culpa officia. Eiusmod excepteur veniam eu cupidatat sint aute officia Lorem voluptate excepteur pariatur. Ut reprehenderit do voluptate qui mollit adipisicing ex.\r\n",
    "investment": "Elit ea consectetur et officia eu eiusmod in sint. Eiusmod nulla laborum esse sit sunt. Sint veniam sint voluptate elit nostrud incididunt magna ut laboris nisi. Amet eiusmod enim aliqua est laborum est laboris ea esse elit.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Occaecat cillum veniam aute et sit eiusmod veniam culpa cupidatat. Deserunt nulla irure nisi mollit veniam velit nostrud incididunt et. Eu velit mollit ad mollit excepteur exercitation eiusmod.\r\n",
      "engagementDescr": "Duis culpa id amet esse deserunt cillum consectetur enim excepteur deserunt excepteur magna ex. Magna officia cupidatat anim cillum exercitation duis. Officia laborum aliquip consequat sunt pariatur magna dolor. Dolore voluptate ea ad proident laborum Lorem non elit dolor occaecat commodo officia. Laborum amet nisi aliquip ex. Non ex commodo ea deserunt. Velit laborum voluptate nostrud labore reprehenderit irure Lorem dolore veniam esse proident pariatur dolore tempor.\r\n",
      "dataDescr": "Adipisicing adipisicing laboris laboris aliqua aliquip est est et. Quis ipsum mollit magna tempor incididunt excepteur velit excepteur eiusmod. Eiusmod ad exercitation duis excepteur commodo Lorem aliquip. Irure enim irure ullamco mollit qui adipisicing pariatur sunt est. Velit Lorem duis tempor esse officia ut. Enim cillum proident pariatur ullamco aliquip eu enim ad aliqua ex ut dolore ad anim. Magna ullamco culpa magna et excepteur tempor officia.\r\n",
      "routeDescr": "Et labore dolore eu veniam pariatur. Duis aliqua in id fugiat. Occaecat pariatur in incididunt velit anim aliquip culpa commodo amet irure consequat cupidatat deserunt. Ex officia quis aute nulla ad magna amet pariatur duis dolore anim dolor cupidatat. Quis ipsum aliqua excepteur culpa mollit sint.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Leticia Juarez",
        "email": "leticiajuarez@satiance.com",
        "fte": 0.8031
      },
      {
        "id": 1,
        "name": "Long Ramos",
        "email": "longramos@satiance.com",
        "fte": 0.4884
      },
      {
        "id": 2,
        "name": "Sharp Marks",
        "email": "sharpmarks@satiance.com",
        "fte": 0.498
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Callie Obrien",
        "email": "callieobrien@satiance.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 18,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 24,
        "y3": 53
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 24,
        "y3": 40
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6254950,
      "customerVal": 744595,
      "mvp": 131098756,
      "optimize": 14887037,
      "grow": 353624245,
      "scale": 832532876
    },
    "nextStepsActionPlan": "Ad duis nulla veniam id amet magna enim tempor elit nostrud consectetur. Excepteur amet ut proident elit ex duis. Commodo anim magna deserunt cillum qui ipsum irure magna ex sint ut eiusmod aliqua. Dolore cupidatat nisi id nostrud Lorem consequat eu.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolor consequat non sunt nostrud dolore duis labore. Laborum non ex sint enim irure ullamco amet in dolor ut consectetur. Anim cillum fugiat ex reprehenderit pariatur irure adipisicing excepteur nulla do duis aliquip do. Incididunt enim eiusmod reprehenderit cupidatat enim qui non fugiat excepteur ad labore. Excepteur aute nisi qui nulla tempor sint consequat cillum do fugiat voluptate. Reprehenderit voluptate nostrud cupidatat reprehenderit amet cupidatat adipisicing cillum qui.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0378412c9971af9ec0",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Earthmark",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "Brazil",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Velit officia eu exercitation ipsum id aliquip sit in occaecat exercitation dolor. Fugiat nostrud ea laboris fugiat esse minim. Deserunt excepteur velit culpa aliqua magna consequat quis incididunt velit pariatur qui labore ipsum reprehenderit. Culpa esse proident exercitation ex pariatur occaecat Lorem. Sint Lorem dolore pariatur et incididunt esse nisi. Dolor cillum labore sint exercitation aute laboris. Aute aute irure consectetur fugiat qui fugiat.\r\n",
    "description": "Aute nulla aliquip pariatur amet id. Magna magna sit dolor sunt eu aliquip cupidatat deserunt mollit dolore ipsum elit enim. Deserunt quis ad nisi elit est esse elit adipisicing qui esse. Sint aliquip esse ullamco aute nulla culpa cupidatat consectetur adipisicing ex cupidatat. Veniam elit nostrud laborum laboris do tempor labore eiusmod nisi commodo cupidatat culpa laborum mollit. Lorem sunt cillum nulla quis velit sunt. Sit aliqua culpa ex velit non ut sit excepteur.\r\n",
    "strategy": "Ut ullamco ut non ad ut. Sit id quis cillum nulla fugiat laboris adipisicing Lorem ex ut aute irure ullamco. Qui ut reprehenderit reprehenderit eu mollit elit est laboris. Nulla ipsum sit velit pariatur tempor aute aliqua incididunt voluptate eiusmod fugiat id. Lorem occaecat et est ullamco aute ex. Anim exercitation nisi incididunt ad enim. Eiusmod ullamco ex nostrud aute Lorem ea voluptate anim incididunt adipisicing occaecat id consequat proident.\r\n",
    "keyLearnings": "Culpa pariatur consectetur duis sunt quis. Pariatur adipisicing eiusmod sunt amet tempor commodo ea do aute Lorem reprehenderit nostrud exercitation aute. Elit voluptate dolor minim dolor voluptate nulla Lorem esse. Sit labore nostrud ea est id ut magna id ea Lorem eu.\r\n",
    "nextSteps": "Incididunt Lorem tempor fugiat nisi sit officia officia nulla irure non qui eu. Sunt culpa cillum sunt amet magna esse cupidatat nostrud anim et fugiat Lorem ipsum esse. Cillum laborum dolor eiusmod commodo deserunt aute et dolore elit ipsum do deserunt veniam commodo. Sunt irure consequat voluptate voluptate eiusmod pariatur minim. Excepteur minim duis consequat eu nostrud velit. Nostrud adipisicing officia nulla consequat eu ea quis incididunt labore in voluptate. Adipisicing qui ut pariatur magna excepteur laborum sit eiusmod mollit duis.\r\n",
    "investment": "Tempor laboris qui et exercitation ad non proident officia ex cupidatat cillum. Laboris reprehenderit dolor duis laboris tempor exercitation veniam minim. Deserunt ullamco qui deserunt eiusmod amet irure elit enim minim ad ipsum. Velit enim esse excepteur voluptate esse voluptate irure aliquip tempor.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Eu Lorem adipisicing officia voluptate do sunt. Magna tempor fugiat aliqua eiusmod mollit duis esse cillum eu fugiat amet irure. Ea sunt qui laborum dolore exercitation non. Elit irure incididunt ut incididunt velit quis. Veniam cillum tempor duis commodo cupidatat cillum adipisicing laborum non dolore ad in cupidatat nostrud. Ullamco cillum eu ea ea ullamco consectetur eu laboris labore eu.\r\n",
      "engagementDescr": "Cupidatat irure aliqua ea occaecat mollit adipisicing sunt culpa occaecat sunt adipisicing. Culpa aute quis excepteur incididunt. Esse sint cupidatat amet elit nostrud duis cupidatat magna aliqua pariatur. Lorem ex dolor labore enim ipsum cillum laboris incididunt et dolore officia veniam.\r\n",
      "dataDescr": "Dolor non elit adipisicing irure occaecat esse in ipsum ut. Ea nostrud pariatur exercitation amet ut dolore. Incididunt incididunt consequat commodo quis eu Lorem nulla magna nostrud labore ut laborum pariatur. Culpa culpa voluptate aute id officia dolore duis irure pariatur ea excepteur id. Esse fugiat quis eiusmod laboris dolor elit fugiat adipisicing nostrud magna aliqua sunt consectetur voluptate.\r\n",
      "routeDescr": "Ipsum velit deserunt magna sunt. Eu ad laborum ea tempor reprehenderit officia laboris. Occaecat deserunt officia ut elit eiusmod nostrud sunt ex proident voluptate nostrud irure enim occaecat. Ipsum et commodo officia est. Nisi veniam minim nulla dolor sint. Lorem consequat aute ipsum laborum exercitation labore do proident. Veniam adipisicing duis amet anim.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Carolyn Reyes",
        "email": "carolynreyes@earthmark.com",
        "fte": 0.1455
      },
      {
        "id": 1,
        "name": "Cecile Sweeney",
        "email": "cecilesweeney@earthmark.com",
        "fte": 0.4929
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Monroe Gould",
        "email": "monroegould@earthmark.com"
      },
      {
        "id": 1,
        "name": "Whitley Walker",
        "email": "whitleywalker@earthmark.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 20,
        "y3": 35
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 4,
        "y2": 32,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 19,
        "y3": 67
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3878055,
      "customerVal": 940770,
      "mvp": 82548388,
      "optimize": 6209110,
      "grow": 950756421,
      "scale": 953355505
    },
    "nextStepsActionPlan": "Proident tempor consequat anim ea consectetur adipisicing. Ea minim exercitation ad sit Lorem aliqua non nulla ipsum. Ullamco labore Lorem fugiat ipsum minim anim do consectetur ex culpa quis. Do ea ex commodo pariatur sunt irure enim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Labore enim amet voluptate nostrud nisi Lorem nulla ipsum proident. Duis mollit cillum exercitation cupidatat ut sit sunt veniam laboris minim do labore commodo cupidatat. Culpa pariatur minim excepteur non elit laboris sunt magna. Nisi amet minim elit est. Consectetur laboris nisi dolor esse amet dolor aute laborum sint excepteur elit nisi sunt. Cupidatat veniam quis ipsum consectetur occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-08",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0368db556bab859109",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Hotcakes",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rr"
    ],
    "geography": "Germany",
    "channel": "Online: Flagship store",
    "division": "Division One",
    "painPoint": "Culpa dolore pariatur ex ut tempor officia voluptate. Laboris velit Lorem sint ad sunt anim nulla pariatur aliquip occaecat id est duis ut. Labore incididunt do enim magna fugiat proident nulla officia cillum incididunt cillum. Nostrud irure tempor minim ullamco adipisicing cillum aliqua elit quis commodo sit magna dolor cupidatat.\r\n",
    "description": "Et exercitation qui dolor sit. Occaecat proident culpa nisi est aute elit do consequat dolore elit Lorem. Incididunt consectetur esse sint dolor ea velit eiusmod sint quis tempor mollit officia. Non laborum Lorem do cupidatat ea sint adipisicing anim est laboris ad laborum. Nostrud amet tempor velit velit nulla in consequat voluptate do incididunt velit non nisi.\r\n",
    "strategy": "Amet laboris mollit non qui dolor minim nostrud fugiat consequat officia ullamco labore consequat deserunt. Minim ut dolore dolore ullamco est et labore incididunt anim ut deserunt exercitation quis irure. Deserunt ex nostrud in adipisicing adipisicing Lorem duis reprehenderit laboris nisi. Aliqua dolore sunt sint exercitation est tempor officia consectetur ad dolor deserunt ea eu excepteur. Esse occaecat laborum eu sint sit ea velit ad mollit in consequat labore proident.\r\n",
    "keyLearnings": "Cillum enim adipisicing laboris qui quis commodo veniam elit eiusmod mollit Lorem minim. Ex irure ipsum consequat ea fugiat non magna nostrud sit quis tempor reprehenderit. Consectetur pariatur anim irure Lorem amet. Consectetur deserunt duis sit aliqua qui consequat Lorem quis.\r\n",
    "nextSteps": "Cupidatat consectetur sint reprehenderit ex minim eiusmod aute dolor magna veniam eiusmod dolore laboris nulla. Sint voluptate reprehenderit pariatur laborum aute elit do id consequat ex. Velit id qui id excepteur et tempor ipsum deserunt in aliquip elit.\r\n",
    "investment": "Commodo ex labore exercitation esse. Proident incididunt tempor laborum excepteur irure dolore aliquip minim fugiat proident nostrud enim consequat veniam. Commodo est quis fugiat sunt irure eiusmod incididunt reprehenderit amet consequat adipisicing consequat nulla. Cupidatat magna culpa Lorem aliquip sint incididunt reprehenderit do et dolor laborum quis elit. Laborum cillum ipsum magna proident nostrud enim velit laborum eu ea. Eiusmod enim deserunt reprehenderit ut sit. Laborum Lorem commodo consequat velit eu mollit ut ex velit pariatur consectetur aliquip.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Dolore voluptate fugiat aute velit excepteur sit sint deserunt veniam. Ullamco laboris reprehenderit proident et dolore qui excepteur aliqua in. Labore et enim nulla minim pariatur quis aute velit. Tempor labore deserunt duis officia pariatur adipisicing eiusmod cillum anim deserunt nulla elit cupidatat.\r\n",
      "engagementDescr": "Quis id cupidatat sint cillum adipisicing adipisicing duis non. Laborum Lorem enim officia cillum adipisicing dolore nisi et ex adipisicing. Ullamco cillum exercitation dolor velit duis amet. Anim labore do mollit aliqua. Dolor amet ullamco aliquip in sint veniam ea aliquip labore qui. Ea pariatur Lorem laborum esse aliquip et eiusmod eiusmod quis et officia incididunt culpa labore. Aute Lorem veniam do dolore in deserunt dolore ad amet id.\r\n",
      "dataDescr": "Officia laboris cupidatat aute sunt nostrud aliqua. Sunt nulla Lorem nostrud et quis cillum ullamco. Anim proident non pariatur nulla qui minim culpa mollit aliqua do do eu nisi anim. Consequat ullamco cillum sit eu nulla minim laboris incididunt. Et quis labore voluptate aute irure. Laborum exercitation in est voluptate excepteur consectetur labore ex aliquip. Commodo adipisicing tempor cillum eu aute dolor excepteur in anim enim consectetur quis duis est.\r\n",
      "routeDescr": "Nisi est sint culpa minim do culpa laboris. Eu nostrud dolor in Lorem excepteur culpa. Tempor dolor do magna magna. Ad ullamco consectetur et nulla cupidatat deserunt nostrud dolor esse magna veniam magna mollit. Consequat velit ea enim incididunt amet sint ea labore cupidatat. Ut adipisicing in fugiat veniam laborum in proident nostrud.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Ellis Fuller",
        "email": "ellisfuller@hotcakes.com",
        "fte": 0.7747
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Colette May",
        "email": "colettemay@hotcakes.com"
      },
      {
        "id": 1,
        "name": "Oliver Rosales",
        "email": "oliverrosales@hotcakes.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 14,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 56,
        "y3": 62
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 10,
        "y3": 56
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 9635730,
      "customerVal": 503395,
      "mvp": 146064074,
      "optimize": 1808176,
      "grow": 365954313,
      "scale": 1487982071
    },
    "nextStepsActionPlan": "Dolore consequat ipsum minim laborum sit. Id ex cillum cupidatat eiusmod laborum quis officia id. Ipsum aliqua ullamco fugiat reprehenderit Lorem occaecat eu id aute occaecat culpa ea voluptate. Elit elit irure proident mollit consequat laboris Lorem aliqua aliquip ipsum id.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliquip ea laboris proident aliquip elit voluptate labore duis. Ipsum reprehenderit elit incididunt eu irure laborum laboris mollit est sunt fugiat. Excepteur et mollit enim qui proident cupidatat fugiat sit ullamco magna. Eiusmod amet commodo consequat commodo amet proident. Do exercitation officia voluptate est minim ea pariatur mollit esse aute tempor. Excepteur sunt enim eu velit consequat excepteur ullamco aute laboris cillum cillum ipsum qui.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b6024c2fb6456cd6",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Zoinage",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Sweden",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Sit minim labore laborum anim exercitation commodo cillum laborum consectetur labore aliquip. Est proident proident commodo et cupidatat aliquip labore et culpa nulla. Labore cillum aute non mollit officia proident ut adipisicing nulla ex occaecat ea tempor ex. Voluptate amet proident officia sit veniam occaecat enim esse labore laborum proident amet. Consequat sint enim amet aliquip pariatur labore.\r\n",
    "description": "Reprehenderit ullamco veniam veniam non culpa et reprehenderit ipsum exercitation. Irure cupidatat ullamco nostrud excepteur. Veniam deserunt minim Lorem nostrud ad eu exercitation dolore commodo proident voluptate Lorem. Enim officia consequat incididunt magna ex laboris ullamco ex esse. Et officia velit enim qui cillum exercitation exercitation culpa id dolore cillum labore aute consequat. Nostrud duis culpa irure sint nostrud commodo enim minim ex.\r\n",
    "strategy": "Tempor aliqua pariatur ea fugiat aliqua dolor magna aute officia. Consequat veniam Lorem excepteur aliquip amet consectetur aliqua ut ullamco nulla. Dolor consequat aliqua veniam deserunt ullamco. Amet ullamco quis incididunt labore quis sint aute anim occaecat velit deserunt nulla tempor.\r\n",
    "keyLearnings": "Deserunt dolor qui occaecat dolor aute laborum. Nostrud quis excepteur sint esse sit nisi enim. Aliquip aliqua veniam laborum ullamco dolor dolor eiusmod aliquip duis aute cillum excepteur sunt eu. Fugiat quis non nostrud dolore dolore exercitation velit aliqua pariatur ut irure veniam amet irure. Excepteur duis nulla reprehenderit aliqua. Est cillum minim magna in veniam ad labore duis culpa culpa deserunt quis tempor. Velit nisi nisi cillum irure elit aliqua labore.\r\n",
    "nextSteps": "Elit est pariatur proident ex labore eu commodo nisi deserunt. Velit nulla est ut consequat occaecat minim incididunt nisi labore. Id voluptate aliquip aute sit reprehenderit incididunt in commodo veniam officia non anim.\r\n",
    "investment": "Id nisi tempor dolore excepteur non enim adipisicing ullamco sit id id ipsum. Non irure consequat excepteur esse dolor quis nulla nostrud sint ut proident occaecat consequat pariatur. In occaecat sint id ex. Tempor non excepteur sunt adipisicing voluptate nulla velit dolore dolor eiusmod dolor est.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Do cupidatat velit enim excepteur dolor voluptate ut voluptate amet dolor ad pariatur minim. Ad elit ea reprehenderit labore id cillum aliquip minim officia magna aliquip eiusmod sit velit. Incididunt enim irure consequat deserunt culpa ea nisi ea duis ullamco. Qui deserunt do nostrud eu.\r\n",
      "engagementDescr": "Voluptate aute ex adipisicing fugiat. Aliquip fugiat nulla excepteur irure quis mollit nostrud enim ut incididunt consequat sit fugiat consectetur. Aliqua aliquip labore eiusmod laboris. Quis Lorem ut exercitation aliqua sit exercitation pariatur.\r\n",
      "dataDescr": "Reprehenderit officia tempor culpa non labore reprehenderit est. Minim Lorem ut aliquip esse elit exercitation aliqua ea eu minim cillum enim. Est nostrud culpa pariatur nulla veniam mollit sint nostrud adipisicing ea velit Lorem ullamco. Tempor cillum aliquip veniam deserunt. Exercitation consectetur nisi id nostrud ullamco exercitation labore laborum sint nisi.\r\n",
      "routeDescr": "Labore enim in duis culpa magna et sint irure. Ad sint proident ea magna proident et reprehenderit sint minim aliqua veniam id velit. Non elit est qui sint mollit anim ullamco. Excepteur excepteur eu dolore excepteur eu amet deserunt ad fugiat minim. Officia non id commodo nisi magna qui sint. Ullamco quis adipisicing eiusmod minim reprehenderit. Occaecat adipisicing adipisicing mollit sunt aliqua ipsum et minim labore quis excepteur ullamco.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Carmela Sherman",
        "email": "carmelasherman@zoinage.com",
        "fte": 0.0991
      },
      {
        "id": 1,
        "name": "Felecia Conner",
        "email": "feleciaconner@zoinage.com",
        "fte": 0.4102
      },
      {
        "id": 2,
        "name": "Amalia Byrd",
        "email": "amaliabyrd@zoinage.com",
        "fte": 0.6053
      },
      {
        "id": 3,
        "name": "Cathy Hawkins",
        "email": "cathyhawkins@zoinage.com",
        "fte": 0.3706
      },
      {
        "id": 4,
        "name": "Dillon Woodward",
        "email": "dillonwoodward@zoinage.com",
        "fte": 0.0382
      },
      {
        "id": 5,
        "name": "Leann Knapp",
        "email": "leannknapp@zoinage.com",
        "fte": 0.8392
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Mendoza Pena",
        "email": "mendozapena@zoinage.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 17,
        "y3": 35
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 60,
        "y3": 68
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 19,
        "y3": 81
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10152496,
      "customerVal": 542580,
      "mvp": 16810225,
      "optimize": 2733695,
      "grow": 876340566,
      "scale": 1232649996
    },
    "nextStepsActionPlan": "Anim ex excepteur cupidatat minim officia incididunt est ex minim excepteur. Incididunt ea commodo fugiat minim Lorem excepteur voluptate officia sint cupidatat laborum sit nisi minim. Laboris laboris in aute nisi voluptate qui ullamco culpa eiusmod tempor proident est. Ex consectetur cupidatat ipsum culpa laboris.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Proident adipisicing esse quis sit minim enim pariatur do laboris eu velit dolore ad. Laboris consequat velit incididunt excepteur veniam minim cillum et officia mollit laborum eiusmod. Ea nulla cupidatat laborum laboris magna adipisicing nisi sunt elit deserunt nulla dolore. Deserunt enim aute dolore ex anim eu est nostrud officia enim amet irure proident. Nisi ad adipisicing est et esse quis ea dolore esse. Pariatur aute proident nisi amet voluptate in irure commodo dolore ut dolor ut magna labore.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-09-07",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03cbabf5b366a3b8dd",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Ohmnet",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Amet labore dolor proident minim dolor dolore ex tempor id amet. Nisi ullamco deserunt amet est enim et magna in. Ut excepteur mollit consequat ad. Incididunt ex minim enim velit ipsum laborum.\r\n",
    "description": "Aliquip id anim proident ut proident amet est nulla duis minim laboris pariatur ad pariatur. Nisi est cillum eu sint ut exercitation. Aliquip exercitation anim labore pariatur. Velit ullamco ea deserunt mollit incididunt. Ipsum consectetur dolore reprehenderit do irure eiusmod deserunt dolor anim pariatur exercitation nostrud occaecat. Id ipsum consequat do qui proident ut voluptate ad dolore excepteur mollit.\r\n",
    "strategy": "Pariatur aliqua consectetur veniam laboris ad excepteur laboris Lorem amet pariatur. Deserunt quis ex ad aliquip. Nulla qui sint labore nulla ullamco velit. Aliqua consequat ea enim elit magna nulla fugiat esse non. Ea mollit dolore velit Lorem nostrud nostrud dolore ea proident duis fugiat. Sunt deserunt non nostrud duis ex incididunt irure Lorem incididunt reprehenderit nisi.\r\n",
    "keyLearnings": "Lorem aliqua occaecat occaecat voluptate incididunt laboris magna irure elit qui sunt tempor cupidatat sunt. Nostrud dolor nisi do voluptate non ad occaecat dolor elit anim irure labore pariatur in. Duis aute minim excepteur laboris ad ad reprehenderit aute.\r\n",
    "nextSteps": "Eiusmod id culpa veniam dolor reprehenderit officia anim minim et sit. Excepteur irure fugiat quis eu laboris. Voluptate ullamco irure eiusmod incididunt magna exercitation incididunt ut proident mollit sint voluptate anim. Lorem est est exercitation veniam magna ex nisi officia adipisicing irure anim nulla ipsum id.\r\n",
    "investment": "Mollit dolor voluptate reprehenderit duis tempor est occaecat aliqua magna eiusmod. Cupidatat ipsum sint do eiusmod sunt. Cillum ipsum pariatur enim velit sunt voluptate aliqua Lorem. Reprehenderit ipsum enim quis anim culpa in duis nostrud aliqua cupidatat sint et labore dolor. Tempor magna et eiusmod eiusmod excepteur nulla non et ullamco amet occaecat. Sint cupidatat ullamco nulla aute.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Non Lorem incididunt cillum excepteur elit deserunt eiusmod pariatur officia nulla irure enim. Officia deserunt mollit consequat nostrud eiusmod aliquip sunt ad laboris fugiat. Mollit dolor ipsum ad consequat aliquip eiusmod sint labore sit elit veniam reprehenderit consequat aute. Magna et fugiat consectetur adipisicing nostrud commodo aute sunt pariatur laborum ad cillum. Consequat nostrud ea Lorem nulla duis ipsum non fugiat magna mollit occaecat. Commodo ex laboris voluptate anim in et.\r\n",
      "engagementDescr": "Fugiat Lorem laboris eiusmod officia consectetur est ad fugiat nulla elit ut reprehenderit dolore. Sit consectetur ad incididunt ex cillum minim pariatur consequat. Ex veniam laborum ipsum dolor amet non dolore Lorem enim cupidatat est. Nostrud pariatur tempor exercitation minim qui ad fugiat reprehenderit sit elit.\r\n",
      "dataDescr": "In amet aute velit fugiat anim excepteur exercitation magna aliquip ad. Incididunt esse tempor laboris ad ex aute sunt. Quis ea ex laboris cillum fugiat. Ipsum labore sit tempor cupidatat mollit ipsum nisi sit dolor commodo ut reprehenderit anim ut. Sit irure est aute aliquip fugiat. Qui anim do officia aute enim voluptate aliquip culpa esse reprehenderit.\r\n",
      "routeDescr": "Excepteur aliqua nulla adipisicing laborum commodo ea. Magna sit magna veniam in. Excepteur nisi in in fugiat aute nisi esse ea occaecat Lorem velit voluptate exercitation. Consectetur sit fugiat do officia culpa est sit reprehenderit aliqua. Ut cupidatat aliqua eu ut aliquip aute irure labore officia ipsum consequat non culpa.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Cindy Santos",
        "email": "cindysantos@ohmnet.com",
        "fte": 0.3573
      },
      {
        "id": 1,
        "name": "Nash Sykes",
        "email": "nashsykes@ohmnet.com",
        "fte": 0.3173
      },
      {
        "id": 2,
        "name": "Bettye Jacobs",
        "email": "bettyejacobs@ohmnet.com",
        "fte": 0.1618
      },
      {
        "id": 3,
        "name": "Stein Farley",
        "email": "steinfarley@ohmnet.com",
        "fte": 0.3297
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Sparks Wilson",
        "email": "sparkswilson@ohmnet.com"
      },
      {
        "id": 1,
        "name": "Chaney Hines",
        "email": "chaneyhines@ohmnet.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 16,
        "y3": 40
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 47,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 17,
        "y3": 50
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 888015,
      "customerVal": 866154,
      "mvp": 10930905,
      "optimize": 5079369,
      "grow": 863296659,
      "scale": 342921495
    },
    "nextStepsActionPlan": "Sint deserunt officia ad et. Sunt aliqua ad enim incididunt consequat consectetur in nisi. Commodo mollit nostrud cillum aute in. Magna pariatur id laborum aliquip magna Lorem.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Commodo fugiat dolor deserunt fugiat commodo qui. Commodo cupidatat ipsum exercitation nulla ex sint sunt cillum. Non duis velit dolore amet non. Enim consequat mollit consectetur aliquip labore velit cillum incididunt tempor aute ea nulla eiusmod nulla. Esse minim enim aliquip duis do reprehenderit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03bdff9467f2815f02",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Digigen",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Spain",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Dolor ea Lorem et cillum ipsum dolor fugiat dolor sunt duis. Nisi esse ex aliquip adipisicing nulla dolore cupidatat elit magna enim incididunt nulla tempor. Nulla veniam est qui exercitation ipsum sint velit voluptate irure elit cupidatat commodo.\r\n",
    "description": "Cillum do sunt exercitation occaecat aute commodo dolor consectetur eu nostrud. Consectetur magna cupidatat quis et mollit ut ipsum elit laborum. Incididunt est laboris ad ad. Nostrud excepteur nulla elit eiusmod pariatur laboris aliqua. Aliquip laboris sint officia incididunt deserunt proident eu laborum anim culpa aliqua tempor id et. Elit nisi culpa consequat irure officia veniam veniam. Lorem exercitation amet nostrud dolore minim ex ut in.\r\n",
    "strategy": "Aute in magna commodo occaecat do. Et sunt elit qui nisi nisi id aliquip nisi nulla. Commodo magna anim ullamco commodo cupidatat non fugiat esse aute. Magna proident incididunt duis amet exercitation nulla ex voluptate minim. Do ad commodo aute qui aliquip culpa nisi consectetur laboris sit. Velit pariatur aliquip amet ex nostrud. Deserunt cupidatat quis ex commodo esse irure minim quis occaecat non pariatur eu.\r\n",
    "keyLearnings": "Voluptate Lorem sunt exercitation id non reprehenderit voluptate consequat ad cillum ipsum. Velit consequat in velit esse ut dolor adipisicing pariatur labore sunt dolor. Ex magna amet pariatur eu. Mollit in qui adipisicing exercitation laborum culpa sint id reprehenderit reprehenderit aliqua do mollit consequat. Ut esse tempor culpa labore nisi magna ut ea ipsum. Anim cillum ad qui fugiat laboris et in consequat ea cupidatat cillum.\r\n",
    "nextSteps": "Dolore eu duis veniam laborum sunt est elit eu ex incididunt. Excepteur velit id eiusmod voluptate. Anim magna est enim cupidatat ut est labore eu nulla exercitation et laboris in. Aliquip eiusmod laborum qui proident labore est occaecat nostrud Lorem nisi cupidatat consequat duis. Non reprehenderit nisi dolor duis aliqua.\r\n",
    "investment": "Consectetur veniam id fugiat amet ea tempor aliquip tempor non. Amet anim nisi deserunt mollit eiusmod cupidatat qui consectetur sint. Labore mollit irure labore esse cupidatat ipsum ipsum deserunt ut elit laborum proident aliqua.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Ullamco consectetur laboris nostrud voluptate cupidatat ea velit incididunt laboris ea ea id minim. Magna cillum aute anim ad eiusmod deserunt. Incididunt ea elit mollit excepteur laborum dolor laboris amet consequat nostrud id.\r\n",
      "engagementDescr": "Commodo proident pariatur ad aliqua cupidatat sit aute quis. Nostrud anim ipsum enim occaecat sint. Nulla est laboris sunt velit. Officia et nostrud pariatur esse veniam mollit.\r\n",
      "dataDescr": "Aliqua ad amet sint aliquip elit. Et mollit magna reprehenderit id mollit sint excepteur. Est consectetur adipisicing et fugiat voluptate. Quis non cillum velit mollit minim est adipisicing. Est sint nulla deserunt pariatur consequat dolore incididunt ex proident reprehenderit. Consectetur incididunt cupidatat qui irure incididunt velit eiusmod aliqua nulla pariatur ipsum adipisicing.\r\n",
      "routeDescr": "Tempor ex commodo ex eiusmod exercitation elit anim exercitation adipisicing esse proident cupidatat. Tempor minim esse in proident enim dolor. Enim nulla reprehenderit incididunt laborum laboris exercitation non sit incididunt fugiat.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Lee Mercado",
        "email": "leemercado@digigen.com",
        "fte": 0.7314
      },
      {
        "id": 1,
        "name": "Bird Dunlap",
        "email": "birddunlap@digigen.com",
        "fte": 0.4417
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Clemons Fisher",
        "email": "clemonsfisher@digigen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 19,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 40,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 14,
        "y3": 99
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 9555227,
      "customerVal": 351852,
      "mvp": 45367756,
      "optimize": 10890142,
      "grow": 1403450508,
      "scale": 574673153
    },
    "nextStepsActionPlan": "Eiusmod ea amet ut proident est et aliqua esse anim eu occaecat esse. Reprehenderit laborum dolore qui reprehenderit elit reprehenderit adipisicing. Quis magna laborum Lorem culpa esse consequat pariatur aliquip. Nostrud elit sunt est reprehenderit mollit magna magna fugiat est commodo exercitation sint velit. Incididunt culpa excepteur in mollit cupidatat ipsum ut excepteur cupidatat. Deserunt mollit commodo anim do tempor aliqua excepteur Lorem mollit minim do esse labore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliquip cillum consequat amet do nulla culpa. Commodo qui occaecat eiusmod incididunt eu fugiat laborum minim culpa labore fugiat. Dolor laboris laboris dolore deserunt commodo enim adipisicing ad ut anim anim. Cupidatat consequat eiusmod non nisi non. Deserunt voluptate in in laboris qui ipsum et deserunt.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03148ff14a46f4db7a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Deepends",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "Germany",
    "channel": "Online: Digital distributor",
    "division": "Division One",
    "painPoint": "Duis sunt ea excepteur ut eu veniam pariatur sunt duis ex do veniam et commodo. Esse aliquip deserunt cupidatat duis do sint dolor aute commodo do cillum. Dolor laborum in officia magna eiusmod ex nostrud sint ea cillum voluptate sunt laborum. Cillum occaecat sunt excepteur aliquip laborum ut. Dolore commodo mollit eiusmod fugiat qui exercitation. Minim minim ut ipsum deserunt proident qui. Qui nostrud sunt qui anim reprehenderit quis reprehenderit.\r\n",
    "description": "Ex dolore esse deserunt amet velit cillum incididunt incididunt fugiat laboris fugiat ipsum reprehenderit reprehenderit. Qui nulla nulla proident id esse tempor ad sunt commodo. Quis cillum minim elit in eiusmod eiusmod officia irure in labore sunt duis reprehenderit. Exercitation nisi sunt et fugiat consequat mollit fugiat Lorem. Magna elit aliqua officia elit aliqua exercitation elit eu duis nostrud consectetur duis. Dolor Lorem ea commodo minim reprehenderit aute enim ullamco eu do laboris pariatur. Culpa nulla veniam magna ea velit in culpa ea aute deserunt et quis veniam.\r\n",
    "strategy": "Ipsum ut sit in ea velit veniam ex culpa reprehenderit aliqua. Ea aute consectetur in ea cillum adipisicing sint adipisicing dolore nostrud veniam. Duis mollit fugiat commodo amet nulla anim sit ea duis Lorem. Sunt adipisicing occaecat consequat laborum exercitation est nisi enim. Laboris pariatur non magna aute ad officia. Incididunt exercitation adipisicing Lorem in labore pariatur nulla aliqua in cillum ipsum laborum voluptate.\r\n",
    "keyLearnings": "In consectetur labore irure aliqua exercitation reprehenderit est amet aute. Non sit sint sint dolor voluptate ex aliquip minim eiusmod tempor et est. Elit nostrud culpa deserunt ipsum reprehenderit dolor veniam minim ullamco pariatur Lorem. Laboris ut ut non ea exercitation anim nostrud ea cupidatat. Do minim ad culpa culpa labore id velit nostrud incididunt consequat mollit eu qui consectetur. Eu enim aute sunt exercitation. Incididunt duis voluptate tempor labore Lorem pariatur do incididunt deserunt consequat velit.\r\n",
    "nextSteps": "Incididunt proident ullamco anim nulla reprehenderit dolore nulla tempor fugiat cupidatat aute amet deserunt incididunt. Irure dolore non ipsum cillum sint elit laborum laboris voluptate pariatur dolore laborum. Quis ea est minim et elit est. Quis labore consequat et incididunt.\r\n",
    "investment": "Elit in tempor commodo esse sit nostrud anim occaecat adipisicing eu. Ad aute tempor ad ullamco ad anim minim dolore ad consequat nulla labore proident veniam. Enim aute Lorem sint commodo. Do ut ea eu elit exercitation dolor sit veniam nulla ea.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Deserunt qui excepteur irure consectetur fugiat qui ullamco minim mollit magna. Est esse incididunt culpa velit in est aliqua commodo pariatur do ex minim in consectetur. Dolore fugiat sint culpa reprehenderit ex do aliqua. Dolore fugiat non qui mollit laboris quis nostrud nisi.\r\n",
      "engagementDescr": "Incididunt officia nulla esse voluptate amet minim pariatur fugiat. Exercitation amet consequat fugiat consequat Lorem ex dolore in. Do incididunt est anim aliqua sunt voluptate elit est laborum aliqua in dolor fugiat. Irure et elit eiusmod culpa ad nostrud non Lorem velit. Labore consectetur exercitation deserunt dolore consequat quis et adipisicing laboris voluptate.\r\n",
      "dataDescr": "Cupidatat eiusmod est velit anim dolore id culpa in irure Lorem dolore adipisicing fugiat sunt. Occaecat reprehenderit consequat consectetur esse adipisicing pariatur cupidatat cillum nostrud minim eiusmod labore sunt quis. Laborum in quis nulla adipisicing aliqua in ullamco amet ad qui eu. Labore labore minim eu sunt laboris exercitation duis irure dolor commodo est. Adipisicing qui excepteur dolor nulla dolore.\r\n",
      "routeDescr": "Ut pariatur ut do nulla velit nostrud excepteur. Aliqua et occaecat labore non. Dolore sit nisi duis in aliquip cupidatat consectetur proident anim ipsum aliqua. Exercitation sunt est nostrud non ad. Proident veniam consequat qui eiusmod mollit sint est occaecat enim consequat. Voluptate magna voluptate proident consectetur velit cupidatat anim.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Crane Charles",
        "email": "cranecharles@deepends.com",
        "fte": 0.9186
      },
      {
        "id": 1,
        "name": "Maddox Oliver",
        "email": "maddoxoliver@deepends.com",
        "fte": 0.7859
      },
      {
        "id": 2,
        "name": "Daisy Bowers",
        "email": "daisybowers@deepends.com",
        "fte": 0.6531
      },
      {
        "id": 3,
        "name": "Jensen Grant",
        "email": "jensengrant@deepends.com",
        "fte": 0.8851
      },
      {
        "id": 4,
        "name": "Lilia Knight",
        "email": "liliaknight@deepends.com",
        "fte": 0.6013
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Jessica Henson",
        "email": "jessicahenson@deepends.com"
      },
      {
        "id": 1,
        "name": "Amelia Stevenson",
        "email": "ameliastevenson@deepends.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 17,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 26,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 22,
        "y3": 44
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1537057,
      "customerVal": 1478593,
      "mvp": 58472112,
      "optimize": 14312610,
      "grow": 866542678,
      "scale": 518956478
    },
    "nextStepsActionPlan": "Minim eu fugiat laborum sunt laboris fugiat laborum aliqua consequat commodo irure. Quis elit aute sint in sunt exercitation commodo elit occaecat. Ad et deserunt est cillum in consequat. Fugiat sint cillum laboris dolore. Proident ipsum enim eu sint culpa pariatur occaecat duis exercitation eiusmod anim voluptate consectetur. Mollit labore laborum consectetur eiusmod magna excepteur in.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Adipisicing enim consectetur fugiat eiusmod adipisicing adipisicing eu. Mollit quis esse nisi dolore id. Anim reprehenderit incididunt qui aliquip ullamco do ipsum sunt. Deserunt ex cupidatat quis magna mollit exercitation.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-23",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b587040f416a173e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Quilk",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "India",
    "channel": "Offline: Shop in shop",
    "division": "Division Two",
    "painPoint": "Dolore cupidatat aliquip laboris incididunt deserunt nulla in. Esse ut pariatur dolor do culpa veniam in quis minim duis. Ipsum aute laboris dolor aliquip in pariatur. Reprehenderit fugiat tempor nostrud consequat enim officia excepteur. Magna consequat voluptate irure Lorem exercitation reprehenderit fugiat non. Est eu veniam ullamco adipisicing id proident eu enim.\r\n",
    "description": "Voluptate sint culpa dolore tempor esse et cupidatat qui ad tempor. In in ullamco ipsum excepteur sunt ex excepteur. Do aliquip mollit non eiusmod ad consequat.\r\n",
    "strategy": "Cupidatat ullamco dolore laborum ipsum ullamco est mollit sit. Ad ad proident commodo esse ex magna. Officia pariatur do dolore occaecat ex. Elit nulla proident cupidatat fugiat sit. Enim amet adipisicing amet ut adipisicing sit ut qui.\r\n",
    "keyLearnings": "Fugiat reprehenderit do id consectetur ex in id non dolore occaecat ut. Mollit fugiat ea elit amet enim proident velit exercitation magna. Ad voluptate laboris reprehenderit consequat minim in.\r\n",
    "nextSteps": "Cillum pariatur et consequat commodo esse consequat Lorem laboris culpa ex magna. Magna tempor ex occaecat occaecat id et ullamco consequat. Velit est cillum amet ut anim irure cillum minim excepteur. Ipsum officia sit in anim excepteur enim ullamco dolor labore. Consectetur laborum fugiat enim cupidatat minim mollit laborum. Nulla eiusmod minim in nostrud duis consequat nisi mollit in eu. Ex exercitation culpa nostrud anim dolor labore ullamco eiusmod consectetur.\r\n",
    "investment": "Veniam veniam dolore veniam exercitation nostrud voluptate anim proident anim quis. Adipisicing cillum deserunt sit excepteur irure fugiat in fugiat laborum sunt eiusmod quis exercitation esse. Nostrud nulla elit deserunt irure tempor commodo. Deserunt ullamco ullamco cillum cillum nulla aute magna culpa consequat in aliquip officia. Irure aliquip deserunt proident elit.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Ea occaecat velit irure elit enim ut culpa in aliquip veniam. Excepteur veniam consectetur fugiat commodo sunt occaecat non proident dolor velit. Mollit laborum ea sunt sint qui non Lorem quis voluptate nisi cupidatat excepteur.\r\n",
      "engagementDescr": "Cillum labore laboris irure quis nostrud qui dolore non enim. In sunt fugiat cupidatat qui excepteur in est id ullamco quis tempor ea. Eiusmod voluptate enim cupidatat veniam aliquip enim nulla in Lorem dolor sint duis et. Qui ex Lorem incididunt qui. Sit est consequat cillum elit ex do eu occaecat voluptate occaecat aliquip eiusmod aliquip. Aute id Lorem duis esse excepteur non sunt est id.\r\n",
      "dataDescr": "Nisi mollit et ea excepteur ea sint Lorem quis aliqua. Ex minim tempor eiusmod ipsum laborum mollit enim nulla laborum. In dolore quis ad dolor voluptate cupidatat id in aute sunt. Consectetur deserunt reprehenderit labore laboris ex in veniam exercitation. Excepteur reprehenderit incididunt nulla nulla cupidatat cupidatat laborum amet labore dolore cillum. Eu sunt labore dolore laboris proident Lorem incididunt voluptate mollit.\r\n",
      "routeDescr": "Elit fugiat esse ipsum sunt commodo nostrud pariatur ad mollit tempor deserunt laborum occaecat nostrud. Est dolor consectetur reprehenderit aute est tempor ut cillum sit voluptate sit Lorem aliqua. Lorem commodo et mollit sint ea quis et labore pariatur sit duis officia elit. Dolor cupidatat irure ullamco minim esse exercitation. Cupidatat dolor ullamco laborum amet aliquip pariatur. Aliquip occaecat exercitation est minim magna sint veniam quis.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Violet Johnson",
        "email": "violetjohnson@quilk.com",
        "fte": 0.6595
      },
      {
        "id": 1,
        "name": "Jordan Barr",
        "email": "jordanbarr@quilk.com",
        "fte": 0.6689
      },
      {
        "id": 2,
        "name": "Ebony Anderson",
        "email": "ebonyanderson@quilk.com",
        "fte": 0.478
      },
      {
        "id": 3,
        "name": "Carlson Douglas",
        "email": "carlsondouglas@quilk.com",
        "fte": 0.4583
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Vinson Weaver",
        "email": "vinsonweaver@quilk.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 14,
        "y3": 32
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 36,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 30,
        "y3": 41
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13314143,
      "customerVal": 880318,
      "mvp": 36990904,
      "optimize": 11746773,
      "grow": 1295124650,
      "scale": 1400150614
    },
    "nextStepsActionPlan": "Dolore sit sint minim aliqua dolore voluptate proident reprehenderit id nisi ipsum. Sunt nisi aliqua enim nulla proident excepteur laboris. Officia deserunt labore cillum qui veniam non. Aliqua anim quis fugiat minim mollit. Ut id tempor cupidatat veniam adipisicing qui minim enim tempor est cupidatat enim. Proident non id officia sint fugiat elit aliqua labore occaecat consectetur elit incididunt mollit sit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Amet ullamco excepteur pariatur aute eu dolor amet ullamco. Laboris enim laborum elit qui proident. Velit et voluptate officia incididunt commodo ullamco.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-14",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c18f0437795f361b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Enaut",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rp"
    ],
    "geography": "India",
    "channel": "Online: Shop in shop",
    "division": "Division Two",
    "painPoint": "Mollit aliquip elit eu qui ut in dolore adipisicing cupidatat. In sunt ad amet reprehenderit nisi do duis do excepteur irure. Ex officia culpa sint anim nulla fugiat non veniam reprehenderit. Eiusmod culpa in nostrud eiusmod ad ad qui amet tempor consectetur deserunt proident aliqua. Anim est irure deserunt ea et qui. Ullamco aliqua non et magna adipisicing eiusmod. Consectetur ullamco eu id proident nostrud velit ea exercitation sint eu.\r\n",
    "description": "Voluptate eu fugiat consequat dolor in quis non dolor elit in. Et elit exercitation occaecat elit commodo ea nisi. Do dolore id enim qui fugiat amet. In deserunt qui velit dolor. Est et ea reprehenderit mollit est id. Qui magna duis duis ut sit id tempor.\r\n",
    "strategy": "Do ea pariatur aliqua labore sint qui tempor sunt. Id ad velit voluptate sunt adipisicing id ullamco. Cupidatat aliqua sint culpa laborum consequat ullamco esse. Ipsum commodo veniam aliqua duis proident nisi ut. Mollit aliquip Lorem nostrud ea excepteur occaecat dolore enim fugiat nisi enim. Magna adipisicing culpa in quis eu. Fugiat ullamco nostrud voluptate consequat commodo.\r\n",
    "keyLearnings": "Aliqua aliqua et aute ipsum cupidatat cupidatat laborum esse occaecat veniam. Ut eiusmod Lorem in consequat adipisicing ad adipisicing cupidatat cupidatat velit est eu. Fugiat nulla laboris magna ut nisi consectetur.\r\n",
    "nextSteps": "Est sunt elit aute esse officia voluptate. Quis ipsum qui adipisicing ea ipsum nulla veniam reprehenderit Lorem. Officia mollit consectetur tempor veniam laborum velit deserunt quis elit ea. Enim nostrud excepteur anim ullamco sit commodo consequat pariatur commodo voluptate ex. Aliqua laborum ipsum minim nisi incididunt do incididunt. Voluptate deserunt laborum aliquip reprehenderit enim laborum aute tempor ullamco amet aliqua eiusmod qui. Commodo amet pariatur nulla minim tempor.\r\n",
    "investment": "Pariatur reprehenderit excepteur ullamco voluptate velit nulla in laboris aute ex mollit. Labore adipisicing quis in velit quis enim non cillum aute proident dolor commodo. Nisi Lorem aliquip officia tempor aliqua commodo anim reprehenderit nostrud. Ad esse laborum et sunt duis proident consectetur anim consequat ad pariatur reprehenderit.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Commodo mollit consectetur sit exercitation nisi qui sunt. Sunt aute ea proident amet consequat do consectetur mollit Lorem sit nisi duis mollit. Id aliquip nulla enim irure ex nostrud enim labore sint cupidatat proident consequat. Nulla nostrud minim enim voluptate esse irure quis pariatur reprehenderit officia enim ea proident pariatur. Irure reprehenderit nisi ipsum non esse laborum sunt commodo proident incididunt. Nulla irure mollit esse minim. Ut esse eu velit nulla nulla excepteur aliqua non officia ullamco.\r\n",
      "engagementDescr": "Cupidatat et in magna consectetur pariatur quis tempor ea Lorem ipsum incididunt. Nulla eiusmod duis pariatur magna pariatur. Esse occaecat deserunt ullamco sunt ad minim tempor tempor.\r\n",
      "dataDescr": "Voluptate dolor consectetur sit commodo eu laboris deserunt veniam. Excepteur nisi Lorem aliquip exercitation non dolore magna culpa. Nostrud exercitation adipisicing ut proident minim dolore commodo ea nisi ipsum eiusmod. Laborum excepteur tempor minim qui aute id mollit ex ipsum eu eu. Mollit laborum deserunt veniam consectetur quis pariatur laboris voluptate commodo incididunt velit. Do irure ullamco ad quis excepteur culpa in Lorem laborum.\r\n",
      "routeDescr": "Sit incididunt consequat voluptate ullamco consequat ullamco consequat proident consectetur. Labore ullamco irure anim enim aliqua aliqua commodo reprehenderit deserunt adipisicing. Aliquip qui dolore aliquip anim. Dolor veniam reprehenderit qui sit qui laborum. Eu do ad proident exercitation aliqua sunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Morgan Nguyen",
        "email": "morgannguyen@enaut.com",
        "fte": 0.4764
      },
      {
        "id": 1,
        "name": "Glenna Petty",
        "email": "glennapetty@enaut.com",
        "fte": 0.0581
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Anita Aguirre",
        "email": "anitaaguirre@enaut.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 11,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 10,
        "y2": 41,
        "y3": 81
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 28,
        "y3": 69
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10261241,
      "customerVal": 964518,
      "mvp": 40936451,
      "optimize": 7405097,
      "grow": 406184523,
      "scale": 1089800747
    },
    "nextStepsActionPlan": "Sint deserunt deserunt non adipisicing mollit commodo aliqua nulla laborum sit cillum labore magna aliquip. Deserunt duis eiusmod esse voluptate fugiat exercitation in duis velit cillum culpa ipsum. Anim ex voluptate ex anim ut fugiat ex irure dolore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Occaecat ea nisi adipisicing qui irure. Culpa Lorem et sunt reprehenderit veniam elit ullamco proident. Consectetur do id sint enim excepteur cillum adipisicing sunt tempor pariatur. Sunt commodo ex velit exercitation proident et. Veniam ut sint id eiusmod cillum elit voluptate ullamco aute. Ex magna fugiat sit velit eu ex.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e038c1503a9567f6fd6",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Terragen",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rp"
    ],
    "geography": "Italy",
    "channel": "Online: Flagship store",
    "division": "Division One",
    "painPoint": "Lorem exercitation laborum ut fugiat sunt culpa nisi officia. Cillum ipsum cupidatat nostrud labore aliqua proident magna deserunt laboris. Voluptate est do veniam dolor velit. Nisi minim incididunt id cupidatat dolor voluptate elit nostrud velit ipsum ad incididunt nisi voluptate.\r\n",
    "description": "Do tempor proident aliquip tempor reprehenderit officia eu cillum officia veniam laborum quis proident. Mollit anim ipsum id aliquip irure sit consectetur. Duis in ipsum laboris excepteur laboris officia ipsum ipsum nisi esse. Mollit consequat sit elit dolor. Ullamco nulla mollit irure cupidatat pariatur esse sint ut cillum. Esse sint adipisicing pariatur elit. Minim incididunt nulla tempor dolor in ex qui mollit cillum excepteur excepteur.\r\n",
    "strategy": "Excepteur nostrud in labore veniam ea deserunt esse id minim consequat minim irure adipisicing. Sit commodo tempor non labore. Exercitation dolor aute aute aliquip do nulla pariatur aliquip ut ullamco commodo laborum pariatur. Incididunt qui sint aute amet cillum in et fugiat veniam veniam voluptate nulla eiusmod eu. Ipsum do duis nostrud consequat do nostrud aliqua. Ea commodo magna esse amet mollit deserunt duis magna elit consectetur duis.\r\n",
    "keyLearnings": "Qui nisi non do eiusmod non aliquip cillum. Ad culpa fugiat aute minim enim. Aute deserunt proident ea nostrud cupidatat nisi exercitation aliqua amet. Exercitation aliquip cupidatat eu occaecat id voluptate amet cillum culpa nulla. Incididunt ullamco amet est labore in ullamco do laboris. Nostrud anim qui voluptate commodo aliqua quis nisi. Laboris magna nostrud esse labore tempor veniam deserunt nulla eu ea dolore irure deserunt.\r\n",
    "nextSteps": "Sint consequat elit do velit magna commodo. Pariatur aliqua anim dolore voluptate elit esse aliqua sit Lorem. Laboris mollit deserunt duis sint enim cupidatat ea. Sit et officia et laborum exercitation voluptate cillum sint. Mollit excepteur consequat quis commodo. Exercitation fugiat qui sint fugiat Lorem. Eiusmod sunt officia occaecat sit elit qui exercitation enim velit.\r\n",
    "investment": "Eiusmod est do consectetur incididunt. Quis laboris nisi quis qui cupidatat aliqua amet velit anim. Aliqua dolore velit laboris enim sit culpa labore esse. Eiusmod occaecat quis sint fugiat eiusmod in enim Lorem. Ut ad aliqua consequat mollit adipisicing labore nisi voluptate consequat nostrud.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Officia consequat quis occaecat consequat quis laboris fugiat. Sunt cupidatat fugiat laborum quis amet cupidatat minim voluptate. Commodo elit eiusmod occaecat magna velit ut reprehenderit ipsum amet fugiat do nostrud enim est. Aute do sunt excepteur qui Lorem et veniam. Voluptate duis deserunt voluptate dolore laboris eu ipsum incididunt qui quis proident aliquip qui labore. Sit enim culpa enim id ullamco tempor excepteur nisi elit minim esse. Est adipisicing tempor sunt Lorem excepteur consectetur.\r\n",
      "engagementDescr": "Do qui laborum dolor amet magna ipsum ullamco excepteur elit duis non consequat. Adipisicing consequat magna dolor reprehenderit esse ipsum eiusmod ex id aliqua fugiat sit proident. Quis veniam consectetur qui consequat fugiat minim eiusmod dolor. Cillum duis enim reprehenderit eu duis esse aliquip pariatur. Elit exercitation labore mollit et.\r\n",
      "dataDescr": "Voluptate eu et nisi pariatur esse consequat amet dolore proident laborum dolor amet fugiat cillum. Voluptate laborum velit eiusmod non magna ad aute deserunt occaecat nostrud ipsum veniam pariatur officia. Duis labore consectetur proident Lorem commodo aliquip esse dolore commodo occaecat consequat non dolor laboris. Culpa et ipsum proident in magna voluptate ipsum est aute mollit in. Ipsum ipsum sit tempor dolor cillum et fugiat. Fugiat aute sint consectetur adipisicing elit incididunt cillum consectetur aliqua velit cupidatat. Fugiat dolore eu nulla proident irure eu id nisi laboris.\r\n",
      "routeDescr": "Proident velit in qui ut aliquip aute non. Aliqua veniam anim irure id elit cillum do do ullamco proident officia voluptate aliquip. Amet consequat aute nisi fugiat. Aute do ea voluptate consectetur dolore ea magna eu culpa. Cillum ipsum pariatur sit ea ex enim ad reprehenderit ea adipisicing aute. Adipisicing mollit nostrud excepteur reprehenderit do. Excepteur minim ullamco aute labore nostrud eu sint cupidatat quis qui quis fugiat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Bryant Hale",
        "email": "bryanthale@terragen.com",
        "fte": 0.9842
      },
      {
        "id": 1,
        "name": "Chrystal Baldwin",
        "email": "chrystalbaldwin@terragen.com",
        "fte": 0.6663
      },
      {
        "id": 2,
        "name": "Franklin Leach",
        "email": "franklinleach@terragen.com",
        "fte": 0.4272
      },
      {
        "id": 3,
        "name": "Quinn Robertson",
        "email": "quinnrobertson@terragen.com",
        "fte": 0.5588
      },
      {
        "id": 4,
        "name": "Banks Hudson",
        "email": "bankshudson@terragen.com",
        "fte": 0.4077
      },
      {
        "id": 5,
        "name": "Travis Payne",
        "email": "travispayne@terragen.com",
        "fte": 0.1432
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Blevins Sullivan",
        "email": "blevinssullivan@terragen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 9,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 31,
        "y3": 87
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 26,
        "y3": 49
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1233448,
      "customerVal": 62846,
      "mvp": 40322870,
      "optimize": 14837204,
      "grow": 989758384,
      "scale": 770462007
    },
    "nextStepsActionPlan": "Anim sit eu occaecat sit enim culpa in esse aute officia dolore quis proident. Id duis veniam voluptate officia sit ex anim sunt exercitation eiusmod nisi. Sunt sit consequat et eu consectetur esse do mollit. Cillum ad quis esse ullamco excepteur consequat. Velit officia magna est culpa amet culpa laboris consectetur. Non laborum irure quis qui exercitation dolore anim minim nostrud. Labore aliquip consequat ad magna voluptate veniam veniam duis sint mollit qui aute.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Anim ad ad non magna aliqua minim id deserunt tempor velit. Dolore occaecat elit exercitation anim veniam quis officia Lorem laboris minim. Dolor occaecat anim consectetur proident voluptate laborum. Ullamco ipsum aute duis in do quis nostrud voluptate dolore in eu. Ullamco dolor aliqua consectetur qui quis anim. Non qui nostrud nostrud sint duis in. Sit non velit consequat quis excepteur cillum Lorem consectetur irure Lorem cillum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-18",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03e104d4c6a66dc4df",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Turnling",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Germany",
    "channel": "Online: Shop in shop",
    "division": "Division One",
    "painPoint": "Occaecat ullamco proident voluptate ex tempor in proident ut. Exercitation exercitation excepteur aliqua sint ullamco est nisi ut non excepteur ea ut irure. Velit magna veniam pariatur enim non dolor duis aliqua magna anim ullamco. Magna id sit in culpa qui velit Lorem adipisicing eiusmod do tempor sint amet nulla. Veniam tempor sint est sunt reprehenderit irure ex excepteur enim. Laborum reprehenderit ipsum nulla commodo minim fugiat.\r\n",
    "description": "Do eu et enim et velit nulla nisi labore. Incididunt sit culpa incididunt est deserunt incididunt. Laborum voluptate reprehenderit minim reprehenderit nostrud ea esse aute excepteur in voluptate. Pariatur laborum voluptate duis ullamco nostrud est ea et consectetur. Ex excepteur minim reprehenderit qui laborum voluptate cillum. Voluptate esse consectetur laboris culpa aliquip commodo laborum esse exercitation esse.\r\n",
    "strategy": "Velit eiusmod ex amet duis aliquip est ipsum amet Lorem elit eiusmod laboris consequat. Reprehenderit laborum ad consectetur deserunt officia ut proident nostrud est pariatur nulla in cupidatat. Duis proident velit incididunt nisi aliquip magna fugiat aliquip exercitation ex. Elit qui aliquip elit officia cupidatat incididunt amet amet reprehenderit incididunt.\r\n",
    "keyLearnings": "Ullamco proident sint cillum anim ea ut labore tempor culpa dolor excepteur. Fugiat sit veniam laboris labore aute velit veniam proident cupidatat proident ullamco dolore. Proident Lorem commodo nulla amet enim occaecat est duis pariatur ex magna nostrud non. Sunt adipisicing incididunt excepteur ullamco quis mollit nisi. Ea qui laboris eu fugiat cillum cillum quis dolore magna. Voluptate voluptate est incididunt culpa aliqua consequat.\r\n",
    "nextSteps": "Irure ea mollit quis fugiat minim id veniam reprehenderit id enim ex velit anim. Esse nulla veniam sit sint adipisicing sunt officia enim duis. Amet sint eu consectetur in nulla officia eiusmod voluptate aliqua reprehenderit culpa. Consequat pariatur ex ex mollit magna labore mollit non consequat laborum ut occaecat. Dolore nostrud ad enim ipsum aliquip amet magna.\r\n",
    "investment": "Sint sit laboris magna quis deserunt excepteur. Commodo dolore id excepteur aliquip laboris qui do culpa consectetur ea culpa. Pariatur nisi consequat non dolore adipisicing reprehenderit eu consectetur deserunt laboris aute do officia nisi.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Lorem ullamco eiusmod amet sunt enim fugiat dolore cupidatat aute voluptate. Sit cillum cillum nostrud sint non ad magna. Fugiat Lorem occaecat consequat voluptate dolor amet fugiat.\r\n",
      "engagementDescr": "Laboris excepteur quis aute nostrud veniam consectetur ea mollit consequat consectetur incididunt ea qui. Sunt est ex et excepteur velit proident incididunt adipisicing sint aliqua. Ullamco sint mollit esse pariatur mollit. Est ut voluptate est velit aliquip esse elit irure nulla quis ipsum commodo est laboris.\r\n",
      "dataDescr": "Cillum fugiat velit nostrud incididunt. Elit elit ipsum elit do ex ut adipisicing quis enim elit ea. Proident nostrud esse fugiat occaecat cupidatat velit sint ut aliqua est. Consequat magna proident do et voluptate deserunt eiusmod non ipsum. Consectetur laboris irure esse dolor ex pariatur cillum proident eiusmod cupidatat. Eu aute labore amet voluptate labore amet sint.\r\n",
      "routeDescr": "Reprehenderit do excepteur id elit aliquip consectetur commodo. Pariatur ut elit non culpa enim ad aute. Adipisicing fugiat elit nostrud et cupidatat ipsum est eu aliquip sunt. Pariatur aliqua officia do voluptate tempor tempor ullamco consequat in ex nostrud. Id irure excepteur laborum laborum nulla officia. Aliqua ad laboris est dolor ad Lorem ullamco ipsum.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Lena Carpenter",
        "email": "lenacarpenter@turnling.com",
        "fte": 0.2331
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Yvonne Chandler",
        "email": "yvonnechandler@turnling.com"
      },
      {
        "id": 1,
        "name": "Battle Ray",
        "email": "battleray@turnling.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 18,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 24,
        "y3": 57
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 10,
        "y3": 36
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8749944,
      "customerVal": 901015,
      "mvp": 45936535,
      "optimize": 13355052,
      "grow": 286324134,
      "scale": 682912169
    },
    "nextStepsActionPlan": "Anim labore aliquip labore officia velit consequat laborum nulla. Do exercitation velit duis aute cupidatat id sit nulla sit Lorem quis dolor. Officia quis in dolore Lorem magna officia labore occaecat ut nulla enim Lorem.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Do magna occaecat pariatur dolor minim fugiat reprehenderit excepteur culpa. Nisi anim est adipisicing minim laborum dolor duis sit aliqua minim do et et. Irure ex dolore occaecat duis sint consequat laboris cupidatat ea dolor. Consectetur cupidatat quis cillum minim eu aliqua ea aliqua excepteur exercitation labore non mollit nostrud. Sunt voluptate aliquip laborum mollit eiusmod nisi culpa eiusmod est. Sint pariatur adipisicing nostrud excepteur cupidatat id Lorem.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-29",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e030c87c56869bbea90",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Rameon",
    "highlightKPI": [
      "cac",
      "bcr",
      "tc"
    ],
    "geography": "Italy",
    "channel": "Online: Social media",
    "division": "Division One",
    "painPoint": "Enim ex consectetur aliqua aliqua ut minim cillum voluptate adipisicing est voluptate sint anim. Exercitation fugiat anim nulla exercitation nisi irure irure ut et reprehenderit ad. Pariatur nisi laboris nostrud non. Ullamco pariatur occaecat ex sint. Dolore irure pariatur eiusmod velit dolore magna minim consectetur fugiat nisi nisi.\r\n",
    "description": "Ut cupidatat nisi ipsum ad. Reprehenderit magna laborum elit do eu. Ex qui cupidatat proident tempor et dolor Lorem dolore mollit id tempor consequat velit.\r\n",
    "strategy": "Elit labore non deserunt sint nisi anim veniam ex incididunt sunt cillum enim magna. Proident aliqua ipsum proident excepteur. Do est magna pariatur ullamco magna cupidatat est mollit voluptate. Et veniam sint labore labore eiusmod et adipisicing. Fugiat ea deserunt eu Lorem laboris esse pariatur sint cillum elit laboris enim aliqua sunt. Do qui excepteur labore dolore eu voluptate ut amet cillum mollit reprehenderit elit culpa. Et nisi esse dolore elit.\r\n",
    "keyLearnings": "Ea velit commodo aute nostrud officia velit est ea elit reprehenderit commodo. Consequat elit sint dolore fugiat commodo sit. Proident occaecat deserunt ipsum aliquip ipsum est cillum deserunt veniam adipisicing enim irure Lorem. Ad dolor elit adipisicing ex elit irure proident proident est ipsum aliqua non. Sunt amet est est qui esse exercitation tempor consequat irure cupidatat reprehenderit velit. Mollit sit aliqua sunt et qui irure enim est cupidatat duis excepteur dolor commodo.\r\n",
    "nextSteps": "Anim deserunt culpa nostrud sit sunt fugiat dolor minim consectetur duis non in ullamco esse. Ea veniam excepteur ipsum cupidatat qui eu qui reprehenderit. Aliqua amet dolor sint nostrud eiusmod. Sint labore dolor culpa ut. Ex dolore voluptate Lorem nulla non exercitation voluptate duis esse fugiat fugiat. Mollit fugiat eu ad nulla exercitation anim fugiat magna qui consequat.\r\n",
    "investment": "Magna amet minim enim do sunt exercitation commodo adipisicing velit id ullamco. Officia ipsum ex non esse. Officia culpa est sint culpa veniam cupidatat minim tempor id. Duis exercitation dolor ut excepteur aliquip elit. Cupidatat esse ex velit aliquip veniam velit dolore sint duis consectetur elit nisi sint tempor.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Amet sit magna adipisicing nostrud est adipisicing laborum. Labore duis consequat tempor esse ipsum voluptate anim in nostrud sit adipisicing proident. Sit aliquip aliqua magna dolore irure adipisicing reprehenderit ipsum sint. Proident ex mollit velit eu consectetur ex id amet est nisi laboris dolore irure. Ut consequat duis ex sint ut id aliquip dolore eiusmod aliquip ad dolor.\r\n",
      "engagementDescr": "Reprehenderit nisi aliqua voluptate veniam ea exercitation qui cillum dolore est laboris eu voluptate dolore. Proident anim deserunt quis eu minim in ullamco sunt est cupidatat. Irure laborum veniam nulla nisi excepteur ea veniam laborum anim et. Proident excepteur elit enim ex magna eiusmod qui eiusmod enim irure.\r\n",
      "dataDescr": "Exercitation ullamco consectetur ad et. Cupidatat consectetur non tempor anim commodo deserunt enim sunt nulla id. Nisi ipsum non tempor irure excepteur dolor. Aute nulla Lorem enim velit laborum non.\r\n",
      "routeDescr": "Commodo aliqua cupidatat labore nisi tempor ipsum amet eu dolore sit. Irure pariatur consectetur reprehenderit laboris ipsum quis duis amet ad. Fugiat est deserunt nulla est id reprehenderit anim. Cupidatat enim laboris adipisicing do occaecat cupidatat occaecat minim. Magna aliquip occaecat sit id pariatur ad veniam sint deserunt cupidatat ullamco aute.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Barton Keller",
        "email": "bartonkeller@rameon.com",
        "fte": 0.3818
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Edwards Ellison",
        "email": "edwardsellison@rameon.com"
      },
      {
        "id": 1,
        "name": "Talley Figueroa",
        "email": "talleyfigueroa@rameon.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 9,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 60,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 26,
        "y3": 95
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11176398,
      "customerVal": 390380,
      "mvp": 67718134,
      "optimize": 2081882,
      "grow": 199637969,
      "scale": 432040444
    },
    "nextStepsActionPlan": "Magna cillum dolor ad officia fugiat veniam mollit non irure deserunt proident. Duis esse proident irure voluptate ad velit proident dolore Lorem. Et fugiat velit cillum nisi adipisicing anim ut laborum nostrud id labore nulla.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ad reprehenderit pariatur aliquip ipsum proident sit. Elit incididunt occaecat magna cupidatat ea ea. Velit aute cupidatat et sunt culpa officia.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e032af4ed346a918772",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Exposa",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Offline: Shop in shop",
    "division": "Division One",
    "painPoint": "Voluptate commodo velit pariatur eu nisi et nisi culpa laborum. Esse ut in consequat aliquip qui culpa ex laboris tempor. Quis non tempor est eiusmod consectetur sint adipisicing cupidatat ad minim. Nulla laborum id irure duis et labore eiusmod aliqua amet pariatur. Magna eu tempor non consectetur cillum pariatur enim irure tempor ipsum irure voluptate. Commodo pariatur aute proident commodo. Aliquip reprehenderit proident do magna enim aliquip id commodo elit nostrud fugiat dolore.\r\n",
    "description": "Mollit ad dolor elit consectetur aliquip. Magna voluptate reprehenderit in amet ad. Qui amet incididunt consectetur eiusmod amet ex proident consectetur excepteur. Esse minim sit do magna aliquip tempor eu tempor cillum. Nostrud aliquip duis commodo fugiat voluptate dolore. Nostrud duis exercitation qui quis sunt. Excepteur elit velit non ea sit in labore dolor minim enim consequat quis ad.\r\n",
    "strategy": "Elit sint mollit tempor consectetur ullamco nulla dolor sunt officia. Ullamco pariatur amet incididunt cillum culpa. Cupidatat cillum est veniam reprehenderit sit consequat in ad deserunt elit deserunt. Do et incididunt aute est aliqua officia tempor enim deserunt Lorem eu. Ex cupidatat culpa duis in proident nulla consequat esse qui pariatur.\r\n",
    "keyLearnings": "Voluptate quis reprehenderit aute reprehenderit commodo Lorem incididunt id Lorem aliqua magna eu. Mollit laborum occaecat est quis nulla. In eu reprehenderit elit aliqua eu commodo duis labore officia. Ex minim officia voluptate qui aute proident magna pariatur.\r\n",
    "nextSteps": "Dolor reprehenderit ut ullamco esse amet amet elit dolor deserunt do. Amet veniam ad in laboris nisi labore non aute in. Culpa irure est irure dolore sunt cillum velit aute elit ullamco exercitation. Elit cupidatat pariatur consectetur ipsum enim voluptate ut laboris sunt. Et qui ad nostrud voluptate eu voluptate. Occaecat ipsum incididunt duis pariatur ex aute qui enim. Occaecat labore ea eiusmod ea culpa cupidatat commodo id sunt minim laboris do.\r\n",
    "investment": "Culpa consequat dolore esse in aliquip aliqua consequat mollit sunt labore non irure in. Labore proident do quis ea eu quis mollit incididunt ipsum Lorem. Id eu nulla fugiat qui. Mollit id officia ullamco minim dolor duis.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Nulla dolor proident consectetur cupidatat Lorem exercitation commodo magna tempor quis velit occaecat quis mollit. Aute duis dolor sunt labore voluptate. Ullamco do ad et in nostrud nulla laboris amet. Nulla eu nisi proident id anim eiusmod aute sint non velit quis laborum nulla.\r\n",
      "engagementDescr": "Magna aute sunt in nostrud cillum aute. Exercitation non nostrud duis irure aute amet proident officia labore ea. Non exercitation proident eu est id ea. Minim est consectetur aliqua aute ullamco velit ex proident ut fugiat elit ipsum ut. Laboris deserunt anim exercitation nostrud. Elit pariatur elit commodo reprehenderit voluptate enim aute laborum. Aliqua do pariatur ullamco dolore nisi deserunt deserunt quis laboris.\r\n",
      "dataDescr": "Tempor sunt ea eiusmod elit id velit deserunt qui cupidatat deserunt minim fugiat. Do laboris non qui sit irure veniam ut tempor ad eiusmod. Cillum esse tempor sunt ea voluptate ut. Ut enim fugiat cillum quis nulla irure anim dolore velit labore enim nostrud. Aliqua est dolore reprehenderit ex voluptate. Et dolor duis do nostrud adipisicing consectetur mollit laborum. Sint minim fugiat ullamco ipsum sunt occaecat fugiat minim in ad.\r\n",
      "routeDescr": "Exercitation exercitation enim ullamco nostrud. Deserunt occaecat ut magna aliquip exercitation esse consequat veniam sit aute fugiat eu dolore. Culpa tempor tempor ipsum cupidatat minim elit et. Ut aliquip velit sint velit ut eiusmod ut deserunt. Nisi aute nulla consectetur excepteur eiusmod ullamco deserunt dolore veniam laborum excepteur ut.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Teri Solomon",
        "email": "terisolomon@exposa.com",
        "fte": 0.8716
      },
      {
        "id": 1,
        "name": "Gardner Jackson",
        "email": "gardnerjackson@exposa.com",
        "fte": 0.0735
      },
      {
        "id": 2,
        "name": "Sellers Nieves",
        "email": "sellersnieves@exposa.com",
        "fte": 0.6215
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Lela Chen",
        "email": "lelachen@exposa.com"
      },
      {
        "id": 1,
        "name": "Harris Tyson",
        "email": "harristyson@exposa.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 20,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 23,
        "y3": 79
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 11,
        "y3": 94
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12706313,
      "customerVal": 722484,
      "mvp": 107216336,
      "optimize": 955817,
      "grow": 1181096691,
      "scale": 441913349
    },
    "nextStepsActionPlan": "Nostrud dolore occaecat officia officia reprehenderit voluptate sunt exercitation incididunt dolor commodo velit laboris aliquip. Minim occaecat enim duis minim duis esse deserunt do in dolor officia cupidatat. Non mollit veniam cupidatat excepteur non duis ea ea cupidatat. Et nisi qui culpa magna quis tempor sit esse nisi dolor consectetur excepteur irure officia. Aute eiusmod enim deserunt nostrud magna laboris amet consectetur minim. Cupidatat sit magna nulla nisi aute ipsum. Sint voluptate in dolore voluptate nulla nulla nostrud aliquip officia.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis ea irure irure in incididunt tempor quis duis laboris. Sunt tempor dolor pariatur eu reprehenderit eiusmod voluptate magna id dolore magna excepteur nisi adipisicing. Ullamco consectetur in enim amet ex.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-13",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03fe12faf9d4e8dc67",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Cyclonica",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Laborum duis non quis Lorem veniam ad cupidatat laboris elit consectetur. Minim mollit eiusmod magna do eiusmod do. Magna quis irure aute ullamco ex sunt occaecat. Pariatur et tempor exercitation consequat ad Lorem sit mollit Lorem ea non.\r\n",
    "description": "Esse consequat laborum sit ullamco ea dolore commodo. Ex aliqua dolor sunt consectetur laborum incididunt in ipsum eu aute fugiat voluptate sunt et. Aute minim amet id tempor laboris ut. Do enim Lorem labore ex labore aliqua eiusmod proident sint aliqua proident. Ut voluptate culpa consequat adipisicing.\r\n",
    "strategy": "Ut aute eu tempor cupidatat eu nulla deserunt minim aliquip ad. Et dolore voluptate labore velit commodo. Est cillum consequat aute duis nulla sint veniam.\r\n",
    "keyLearnings": "Irure nisi ex esse amet incididunt minim. Lorem laborum consectetur do in occaecat in irure elit laboris irure exercitation. Pariatur occaecat nostrud excepteur qui irure duis mollit occaecat anim aute quis. Voluptate occaecat laboris velit ad occaecat mollit. Ipsum anim in adipisicing nisi consectetur deserunt laboris sint.\r\n",
    "nextSteps": "Est dolore esse aliquip laboris eiusmod deserunt. Pariatur nisi laboris culpa anim magna magna tempor deserunt nostrud duis. Cupidatat excepteur ullamco tempor commodo nisi nisi do culpa ipsum ullamco reprehenderit occaecat ex fugiat. Esse tempor non ex cupidatat ipsum. Mollit cillum laborum voluptate magna.\r\n",
    "investment": "Dolore anim incididunt anim cupidatat proident Lorem minim elit anim aute. Nulla elit incididunt magna pariatur reprehenderit enim id minim aute aliquip labore. Ex incididunt amet cillum adipisicing elit cupidatat do aliqua quis commodo commodo commodo. Consequat id ex officia dolor aliquip cillum enim do quis reprehenderit. Do exercitation ut eu aliqua nisi pariatur veniam do ut sint ullamco et laborum do. Esse qui mollit duis aute velit ipsum culpa sint pariatur ut in mollit. Ipsum magna quis incididunt sit.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Ad anim est in minim cillum in proident exercitation laboris tempor. Do cillum nulla cillum officia anim dolor veniam. Commodo id nostrud labore consequat ad officia laboris ipsum reprehenderit. In enim aute duis deserunt officia non nisi sunt laboris. Deserunt consequat aute dolor deserunt. Eu veniam consequat consectetur officia aliquip laboris anim elit Lorem. Amet sunt aliqua cillum deserunt id et.\r\n",
      "engagementDescr": "Nisi ipsum nisi aliqua mollit id et esse magna nulla reprehenderit aliqua dolore. Proident consequat ea nostrud in magna ullamco aliquip proident id fugiat non. Laborum esse quis irure eiusmod id officia elit reprehenderit ut commodo.\r\n",
      "dataDescr": "Eu anim magna do in deserunt laboris sit consectetur sint veniam adipisicing nisi sit. Magna laborum voluptate velit veniam quis ut laborum aliqua occaecat enim excepteur eiusmod laboris. Nisi amet amet labore eiusmod nostrud sit aute ullamco nisi. Incididunt labore reprehenderit deserunt eu deserunt exercitation reprehenderit. Velit esse officia fugiat anim. Sunt veniam laborum consectetur ex ut aliquip. Exercitation quis culpa commodo veniam consectetur esse ut voluptate do anim commodo cupidatat et.\r\n",
      "routeDescr": "Est do voluptate id aute cillum aliquip quis pariatur culpa proident. Dolor aliqua eiusmod sint non qui nisi elit excepteur nulla exercitation veniam ut culpa nisi. Cillum dolore magna do excepteur aliquip sit excepteur magna enim id mollit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Socorro Marquez",
        "email": "socorromarquez@cyclonica.com",
        "fte": 0.9068
      },
      {
        "id": 1,
        "name": "Amanda Pittman",
        "email": "amandapittman@cyclonica.com",
        "fte": 0.2175
      },
      {
        "id": 2,
        "name": "Beryl Mcmahon",
        "email": "berylmcmahon@cyclonica.com",
        "fte": 0.02
      },
      {
        "id": 3,
        "name": "Aurora Harper",
        "email": "auroraharper@cyclonica.com",
        "fte": 0.686
      },
      {
        "id": 4,
        "name": "Georgina Foster",
        "email": "georginafoster@cyclonica.com",
        "fte": 0.8724
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Allison Hooper",
        "email": "allisonhooper@cyclonica.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 15,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 27,
        "y3": 95
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 12,
        "y3": 60
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6260803,
      "customerVal": 622854,
      "mvp": 78553,
      "optimize": 3730460,
      "grow": 641307829,
      "scale": 335099176
    },
    "nextStepsActionPlan": "Anim et anim laboris labore. Do anim velit culpa nostrud nisi occaecat occaecat. Ipsum consequat voluptate non eu sunt. Magna esse duis occaecat ipsum nostrud ex id eiusmod do est ipsum ipsum. Elit exercitation tempor occaecat magna excepteur labore incididunt id tempor laborum irure officia. Labore aliquip ad laborum Lorem labore ut voluptate fugiat excepteur proident tempor.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Mollit dolor consectetur ullamco aliqua incididunt tempor excepteur veniam dolore elit labore magna officia. Anim laborum minim consectetur proident occaecat irure enim aliquip consequat amet. Minim ipsum veniam esse sunt qui ad. Labore proident irure consequat qui culpa magna mollit nostrud sit pariatur sit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c9cb7b8112083dd8",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Enersol",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Offline: Pop up store",
    "division": "Division Two",
    "painPoint": "Proident veniam consequat excepteur sint. Occaecat Lorem tempor ipsum et eu officia proident. Ea sint sunt consequat consequat dolor excepteur laboris nisi eu cillum voluptate exercitation laboris est. Veniam cillum magna incididunt cupidatat culpa ex tempor enim id Lorem veniam enim est. Quis adipisicing et proident fugiat exercitation. Reprehenderit eu enim incididunt consequat est qui sit est veniam exercitation non quis aliqua.\r\n",
    "description": "Amet tempor cillum esse sint eiusmod proident minim. Ea aliquip quis veniam aliqua cupidatat est. Consequat ipsum sit consequat cillum ullamco ullamco tempor. Consectetur dolore commodo anim dolore elit velit duis. Elit nostrud duis sunt proident aute tempor et incididunt in veniam.\r\n",
    "strategy": "Ea eu id in cillum Lorem eu. Reprehenderit non dolore voluptate do eiusmod cupidatat. Enim consectetur enim dolore in non consequat Lorem cillum. Consequat id velit magna sint elit et nostrud enim do.\r\n",
    "keyLearnings": "Lorem minim tempor proident laborum nisi excepteur. Laboris laboris consequat eu aliquip incididunt dolor voluptate enim amet. Fugiat minim proident quis officia consequat. Sint adipisicing cillum nisi aliquip proident non in id elit ex. Cillum irure tempor labore ut dolore.\r\n",
    "nextSteps": "Duis nostrud velit ex ipsum. Culpa qui mollit dolor nisi adipisicing et anim. Esse aute veniam esse ullamco proident mollit enim ullamco ut. Non anim dolore non aute fugiat deserunt est irure occaecat aliquip laborum est reprehenderit sint. Amet magna sint proident pariatur et tempor elit officia officia sit. Irure ex aliqua amet voluptate laboris nisi duis adipisicing officia culpa magna. Sint non exercitation est commodo et elit et excepteur ipsum labore mollit exercitation.\r\n",
    "investment": "Minim mollit nulla ad ex quis id enim in aliquip pariatur do consectetur elit labore. Minim cupidatat quis sunt cupidatat laborum irure et cupidatat elit reprehenderit labore ut velit qui. Laboris sunt id ex nisi commodo excepteur exercitation adipisicing magna sint minim exercitation proident amet. Esse elit ad dolore dolor ad esse.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod pariatur veniam anim commodo ex occaecat duis Lorem enim. Anim occaecat dolor eiusmod sint in aute dolor quis sit. Esse ullamco adipisicing proident officia officia id consequat nulla. Commodo dolore tempor laboris ipsum cupidatat dolor adipisicing. Ullamco deserunt in laborum ad id nisi ad eu exercitation nisi est ad.\r\n",
      "engagementDescr": "Enim eu aliquip labore exercitation. Eu aute ullamco non ullamco nisi elit esse adipisicing. Excepteur reprehenderit ut minim incididunt adipisicing ex eiusmod dolor adipisicing occaecat anim dolor commodo. Sint tempor nisi proident nulla. Aliquip amet esse commodo deserunt aliquip labore non ad sit velit. Officia eiusmod do esse laborum dolore velit quis aliquip. Ullamco sint enim ut proident tempor adipisicing velit pariatur.\r\n",
      "dataDescr": "Deserunt aute cillum veniam consectetur ex incididunt nisi pariatur ex quis minim. Tempor in enim reprehenderit duis ea nisi ea excepteur officia. Eiusmod Lorem elit non elit do laboris sit ullamco ea commodo. Reprehenderit exercitation aliquip est ipsum dolore nisi ipsum sunt non velit exercitation ut.\r\n",
      "routeDescr": "Tempor dolore qui sit dolor quis ut non quis ut aliqua ullamco velit. Ex id aliqua exercitation laboris eiusmod. Non nostrud cupidatat proident est do labore officia adipisicing pariatur commodo excepteur aliquip sit ea. Sit excepteur id aute nisi sint anim magna anim ipsum magna duis. Deserunt cillum cupidatat magna irure mollit ex exercitation ex id.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Angelica Leblanc",
        "email": "angelicaleblanc@enersol.com",
        "fte": 0.5678
      },
      {
        "id": 1,
        "name": "Iris Levine",
        "email": "irislevine@enersol.com",
        "fte": 0.0244
      },
      {
        "id": 2,
        "name": "Duncan Dean",
        "email": "duncandean@enersol.com",
        "fte": 0.3859
      },
      {
        "id": 3,
        "name": "Liz Gates",
        "email": "lizgates@enersol.com",
        "fte": 0.1334
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Yolanda Strong",
        "email": "yolandastrong@enersol.com"
      },
      {
        "id": 1,
        "name": "Mann Davidson",
        "email": "manndavidson@enersol.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 11,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 7,
        "y2": 29,
        "y3": 52
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 21,
        "y3": 42
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11136661,
      "customerVal": 890854,
      "mvp": 60614978,
      "optimize": 14170182,
      "grow": 798364363,
      "scale": 630938185
    },
    "nextStepsActionPlan": "Commodo ullamco ex adipisicing consectetur minim. Officia elit duis sit velit aute sit commodo in Lorem dolor. Anim ex dolor aliqua elit adipisicing minim ea. Nulla ipsum esse pariatur amet sit minim. In pariatur amet labore velit laborum eiusmod eu. Nisi irure ut non non anim. Velit quis velit aliqua do quis irure do veniam laborum minim reprehenderit incididunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Adipisicing elit laboris aliquip dolore esse cupidatat occaecat duis pariatur sunt aute et. Sit occaecat nisi ea sit ea deserunt tempor duis enim nostrud Lorem. Do quis reprehenderit magna elit dolor incididunt consequat ea occaecat tempor. Pariatur magna cupidatat dolor adipisicing laborum ea reprehenderit magna enim in. Quis cupidatat laboris mollit ullamco nulla commodo nostrud reprehenderit. Id pariatur Lorem nostrud amet mollit consectetur nisi sint et do et est in consequat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-21",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03fa0f052789959f67",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Oulu",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Subscription",
    "division": "Division Three",
    "painPoint": "Velit pariatur officia officia incididunt aliqua voluptate et dolore duis laboris anim officia officia dolor. Reprehenderit proident tempor sit nisi consectetur eu tempor nostrud cillum. Id aliquip cillum excepteur et Lorem. Incididunt ea dolor cupidatat aliquip cillum exercitation eu qui pariatur aliqua nulla sit dolor. Ex qui aute voluptate nisi occaecat reprehenderit elit. Sint reprehenderit occaecat exercitation deserunt esse velit. Cillum cupidatat mollit ea incididunt ex reprehenderit.\r\n",
    "description": "Eiusmod qui velit duis dolor sit est dolor. Officia ipsum fugiat consequat excepteur commodo reprehenderit ea anim. Irure elit esse aute irure ullamco labore et magna do non. Lorem laborum nulla id ad aute.\r\n",
    "strategy": "Ut consequat eiusmod ad aliquip non commodo dolor incididunt reprehenderit. Labore sint irure nulla fugiat labore ex. Est labore id reprehenderit duis Lorem aliquip commodo Lorem. Excepteur esse reprehenderit pariatur nostrud incididunt sit ea voluptate consequat velit elit. Amet sint cillum occaecat veniam. Non amet in officia ipsum magna nulla voluptate ea esse. Do anim sit consequat deserunt et ex ex quis anim sunt.\r\n",
    "keyLearnings": "Enim Lorem amet reprehenderit elit commodo reprehenderit do Lorem sint. Laboris magna aliquip proident voluptate veniam et enim id culpa aliquip nisi est. Consectetur est reprehenderit eu occaecat ea laboris adipisicing minim aliquip. Id labore labore Lorem eiusmod aliqua occaecat non sunt dolore labore ea dolor veniam. Lorem Lorem commodo elit deserunt occaecat cillum commodo aliquip sit sit.\r\n",
    "nextSteps": "Magna mollit cupidatat aliqua excepteur amet ad ipsum cupidatat aliqua labore aute qui voluptate voluptate. Cupidatat nulla enim magna dolor quis nostrud nisi. Cupidatat incididunt enim irure anim nulla quis ad cillum pariatur aliqua magna commodo sunt consectetur. Consequat pariatur quis reprehenderit sunt dolor ex eu sit labore nostrud ea amet qui.\r\n",
    "investment": "Tempor proident exercitation reprehenderit qui elit in enim laborum exercitation elit esse. Fugiat eu veniam Lorem et incididunt sit anim veniam eu. Excepteur consequat labore ullamco est laborum sunt deserunt elit.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Do enim velit ex ullamco aliquip consequat id aute cupidatat officia aliqua sit amet in. Ea id elit dolor Lorem ipsum incididunt esse minim et veniam nisi. Fugiat aliquip cillum pariatur nostrud veniam velit aute. Minim et nisi eiusmod non labore duis qui officia commodo. Ut ea sunt commodo nostrud commodo voluptate. Aliqua laboris duis adipisicing exercitation esse veniam.\r\n",
      "engagementDescr": "Ad reprehenderit fugiat aliquip pariatur ut deserunt consequat ex. Adipisicing tempor aliquip ad aute magna duis magna voluptate id do amet. Culpa do deserunt tempor eu occaecat. Enim eiusmod proident minim occaecat do enim excepteur cillum elit ad quis labore pariatur veniam.\r\n",
      "dataDescr": "Amet fugiat quis non duis nulla tempor labore nisi esse minim. Pariatur elit tempor quis non minim deserunt. Dolor amet esse minim elit ut non esse laborum excepteur. Pariatur occaecat consectetur veniam quis exercitation nostrud magna cillum est. Adipisicing minim in enim consequat culpa adipisicing. In veniam eu proident adipisicing nulla dolor. Culpa dolor nostrud elit enim.\r\n",
      "routeDescr": "Sint nostrud pariatur excepteur do ex commodo culpa veniam officia pariatur. Nisi ut labore Lorem tempor nisi consequat. Est velit nisi aute qui ex ullamco cupidatat cupidatat incididunt cupidatat Lorem ex cillum.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Margie Langley",
        "email": "margielangley@oulu.com",
        "fte": 0.4005
      },
      {
        "id": 1,
        "name": "Angelique Bright",
        "email": "angeliquebright@oulu.com",
        "fte": 0.1147
      },
      {
        "id": 2,
        "name": "Freeman Rivas",
        "email": "freemanrivas@oulu.com",
        "fte": 0.9374
      },
      {
        "id": 3,
        "name": "Daugherty Witt",
        "email": "daughertywitt@oulu.com",
        "fte": 0.6806
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Fuller Mcpherson",
        "email": "fullermcpherson@oulu.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 11,
        "y3": 37
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 60,
        "y3": 94
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 30,
        "y3": 81
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5954282,
      "customerVal": 1043840,
      "mvp": 97161617,
      "optimize": 695609,
      "grow": 1307098647,
      "scale": 1193056606
    },
    "nextStepsActionPlan": "Nisi voluptate et magna velit magna mollit Lorem. Duis et cillum quis minim eu quis fugiat in. Do culpa laboris velit elit. Est et laboris tempor id excepteur labore dolor nostrud commodo proident ullamco. Excepteur est nisi et fugiat. Excepteur aliquip ut dolore laboris ipsum. Officia excepteur veniam laborum proident id cupidatat magna.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolore ad elit Lorem amet proident exercitation id Lorem. Eu aliqua mollit laborum eiusmod non aliqua aliquip in et. Ad cillum ex laboris id in qui labore ipsum anim ut ipsum. Magna dolore in et laborum occaecat ullamco ex. Ipsum id deserunt minim aliqua ullamco cupidatat enim pariatur dolore proident aute eiusmod ut sit. Dolor elit ipsum enim ad pariatur exercitation.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0307f5fa3e8721f961",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Gallaxia",
    "highlightKPI": [
      "cac",
      "bcr",
      "tc"
    ],
    "geography": "United States",
    "channel": "Online: Marketpace",
    "division": "Division One",
    "painPoint": "Eu ex cillum officia culpa anim quis veniam anim minim anim nulla ipsum. Esse reprehenderit sint eiusmod laboris elit irure officia laborum pariatur sit dolore anim proident. Est veniam dolore consectetur nostrud occaecat ut tempor officia elit exercitation. Magna proident aliqua exercitation nulla incididunt veniam nulla exercitation.\r\n",
    "description": "Lorem ea laborum ipsum fugiat laborum laboris ex laboris dolore dolore sint laboris aliqua labore. In cupidatat sunt esse dolor Lorem exercitation voluptate deserunt eu id pariatur proident veniam magna. Sint tempor dolore quis amet incididunt ad deserunt laborum ullamco.\r\n",
    "strategy": "Dolore velit reprehenderit mollit cillum sunt dolor nostrud mollit pariatur. Commodo pariatur non occaecat sunt fugiat pariatur magna fugiat. Duis sunt ipsum incididunt excepteur cillum veniam deserunt cillum. Pariatur eiusmod aliqua adipisicing dolor aliqua excepteur culpa eiusmod cupidatat officia ipsum proident. Occaecat dolore irure aliqua quis ut consequat magna excepteur labore id adipisicing laboris adipisicing anim. Dolor exercitation officia fugiat fugiat Lorem ut enim eu.\r\n",
    "keyLearnings": "Occaecat consequat labore et nisi do ea aliquip nostrud cillum Lorem exercitation reprehenderit. Amet consectetur fugiat veniam irure aliquip qui enim. Ad magna commodo officia nisi anim cillum duis ex elit eiusmod.\r\n",
    "nextSteps": "Esse fugiat ullamco est exercitation Lorem. Mollit adipisicing aliqua laboris amet irure ad laborum cupidatat do. Ad et ad ex veniam minim elit.\r\n",
    "investment": "Sint laboris proident ullamco sunt qui amet commodo do deserunt duis labore commodo exercitation. Qui cillum ullamco eu commodo consectetur excepteur labore ipsum duis nostrud tempor incididunt magna tempor. Eiusmod amet aliquip proident irure voluptate excepteur ipsum sit esse in non.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Ullamco exercitation duis fugiat est elit. Sunt do voluptate officia eu minim id fugiat velit dolore. Voluptate ullamco veniam exercitation ullamco commodo ad cillum aliquip cillum.\r\n",
      "engagementDescr": "Velit eiusmod culpa voluptate amet exercitation magna. Id excepteur dolore cupidatat qui ad amet do. Veniam do ad minim laboris laboris veniam velit incididunt eiusmod cupidatat consectetur. Sunt velit ipsum excepteur ad sit labore est nulla dolor sit sint anim. Irure Lorem do nisi qui in esse velit nisi.\r\n",
      "dataDescr": "Duis do est in exercitation aute exercitation. Labore elit laboris laborum proident dolor cillum consectetur et eu aliquip cillum esse minim velit. Incididunt nisi adipisicing deserunt aute consequat incididunt minim occaecat quis deserunt.\r\n",
      "routeDescr": "Quis dolore velit aliqua anim mollit in irure. Consequat veniam eu deserunt ut et non proident elit labore in nisi. Elit pariatur occaecat et dolor.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Clayton Kent",
        "email": "claytonkent@gallaxia.com",
        "fte": 0.9773
      },
      {
        "id": 1,
        "name": "Kathrine Velazquez",
        "email": "kathrinevelazquez@gallaxia.com",
        "fte": 0.3297
      },
      {
        "id": 2,
        "name": "Fannie Mercer",
        "email": "fanniemercer@gallaxia.com",
        "fte": 0.4081
      },
      {
        "id": 3,
        "name": "Patrice Vazquez",
        "email": "patricevazquez@gallaxia.com",
        "fte": 0.0879
      },
      {
        "id": 4,
        "name": "Sandy Mccarty",
        "email": "sandymccarty@gallaxia.com",
        "fte": 0.3265
      },
      {
        "id": 5,
        "name": "Vasquez Justice",
        "email": "vasquezjustice@gallaxia.com",
        "fte": 0.5985
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Georgette Vaughan",
        "email": "georgettevaughan@gallaxia.com"
      },
      {
        "id": 1,
        "name": "Gracie Blackburn",
        "email": "gracieblackburn@gallaxia.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 14,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 18,
        "y2": 33,
        "y3": 63
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 15,
        "y3": 73
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2692532,
      "customerVal": 1186442,
      "mvp": 6209098,
      "optimize": 1532161,
      "grow": 606405699,
      "scale": 209932453
    },
    "nextStepsActionPlan": "Non pariatur sit dolor dolore commodo exercitation. Laboris aliquip incididunt aliqua enim est laborum in non occaecat velit consequat qui. Dolore officia commodo nulla do cillum incididunt ad nisi sunt deserunt consequat voluptate adipisicing ex.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Est mollit elit et in velit. Minim officia ipsum do ea cillum do incididunt enim. Ipsum duis enim culpa excepteur velit occaecat amet duis veniam. Id duis amet eiusmod non dolore nulla duis. Mollit elit culpa reprehenderit sint.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-29",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c8b4587ea2044549",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Lingoage",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Online: Home delivery",
    "division": "Division One",
    "painPoint": "Esse incididunt elit deserunt proident sint dolor ipsum minim dolor eiusmod quis. Sit elit velit proident consectetur excepteur proident commodo fugiat anim ea ut. Mollit ad amet commodo consectetur id nostrud aliqua proident occaecat consectetur mollit consequat. Commodo ullamco deserunt duis consequat quis et culpa labore.\r\n",
    "description": "Eiusmod ad consequat fugiat excepteur. Non tempor occaecat culpa dolore nisi sit occaecat sit. Labore veniam quis laboris exercitation deserunt. Pariatur qui ullamco officia adipisicing elit occaecat veniam. Adipisicing dolore ut ad in aute.\r\n",
    "strategy": "Velit laboris tempor exercitation minim aliqua in sit nisi sint. Ullamco eu elit reprehenderit reprehenderit duis occaecat. Nulla consequat id pariatur reprehenderit fugiat culpa sit do do ullamco pariatur. Voluptate amet aliqua irure et dolore amet irure occaecat officia pariatur excepteur. Sint nisi nostrud pariatur est officia sunt officia. Sit quis nulla nostrud anim elit enim velit ex sit aute excepteur.\r\n",
    "keyLearnings": "Anim ex qui ut culpa sint aliquip cillum. In aute eiusmod ea occaecat eu proident magna ullamco enim amet cillum Lorem. Anim aliquip minim ea cupidatat incididunt cillum laboris aute non Lorem. Tempor ullamco quis fugiat aliqua laborum tempor id qui qui sint officia labore commodo.\r\n",
    "nextSteps": "Fugiat Lorem est ut eu. Cupidatat consequat dolore reprehenderit mollit exercitation pariatur voluptate nulla consequat. Anim nisi nulla nisi voluptate officia nulla exercitation ex mollit sint enim exercitation nisi. Ullamco non anim aliquip culpa ullamco consequat minim velit laborum elit labore et voluptate.\r\n",
    "investment": "Reprehenderit dolore ex occaecat amet. Voluptate enim dolor irure fugiat magna Lorem consectetur. Lorem ipsum sit ad ad sit non cupidatat. Laborum commodo in dolor nulla nulla proident aute deserunt consequat esse ea consequat cupidatat. Non ut voluptate duis anim. Consequat incididunt proident laboris aliquip nulla non est dolore quis consectetur nisi mollit tempor consectetur.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Enim quis Lorem ad cupidatat ut. Esse enim ipsum nulla velit cupidatat. Laborum laborum dolore veniam dolor veniam in elit reprehenderit et. Laborum do veniam id exercitation sit laborum cillum proident adipisicing. Do cillum id consectetur magna duis eiusmod officia proident ullamco ad sunt laboris esse. Proident fugiat officia reprehenderit officia ea. Ullamco irure sunt fugiat nulla esse exercitation Lorem.\r\n",
      "engagementDescr": "Eu qui adipisicing culpa pariatur et nostrud labore. Incididunt quis reprehenderit non ut esse duis. Eiusmod cupidatat eu excepteur incididunt elit in ex aliquip.\r\n",
      "dataDescr": "Minim nostrud voluptate elit veniam id culpa ea pariatur tempor sint cupidatat pariatur. Eiusmod veniam ex eu duis. Eiusmod quis incididunt proident sunt anim quis reprehenderit magna sit pariatur.\r\n",
      "routeDescr": "Ut dolore cillum mollit cillum amet laboris eiusmod deserunt ea Lorem sunt aliquip. Id nulla officia aute ad non minim sint culpa tempor ipsum. Sit sunt ea mollit et. Culpa reprehenderit nisi ipsum ea aute id incididunt. Et officia laboris sit ut culpa sunt voluptate ex minim reprehenderit in reprehenderit excepteur enim. Non officia amet fugiat Lorem pariatur consectetur pariatur ut tempor nostrud.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Keisha Brewer",
        "email": "keishabrewer@lingoage.com",
        "fte": 0.8777
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Serrano Campos",
        "email": "serranocampos@lingoage.com"
      },
      {
        "id": 1,
        "name": "Monica Alexander",
        "email": "monicaalexander@lingoage.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 8,
        "y3": 23
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 5,
        "y2": 43,
        "y3": 56
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 15,
        "y3": 93
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5804627,
      "customerVal": 155000,
      "mvp": 102696631,
      "optimize": 5451105,
      "grow": 76917349,
      "scale": 1147630080
    },
    "nextStepsActionPlan": "Est sit pariatur laboris quis cupidatat laborum velit. Qui nisi minim occaecat aliqua veniam aliqua labore consequat. Incididunt dolor anim consequat anim. Ipsum proident deserunt reprehenderit anim eiusmod exercitation tempor non. In veniam adipisicing ea quis cupidatat velit ex consectetur deserunt mollit laborum ea. Culpa tempor laboris do sint pariatur reprehenderit minim veniam ut incididunt aute qui.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis magna qui ex duis. Veniam non fugiat exercitation dolore nisi mollit incididunt do qui officia exercitation. Commodo mollit sit cillum ullamco minim dolore culpa incididunt do sunt. Sunt eiusmod esse nostrud fugiat sunt Lorem duis velit commodo duis tempor amet sunt irure.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c12c38e940265edb",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Imkan",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Offline: Pop up store",
    "division": "Division Three",
    "painPoint": "Do amet consectetur exercitation dolore id irure culpa laborum veniam id aute culpa irure voluptate. Occaecat exercitation deserunt tempor mollit. Laboris anim minim mollit ullamco dolore labore ullamco consectetur. Pariatur sint sit ea adipisicing. Officia incididunt sunt amet exercitation sint dolore nostrud quis commodo magna Lorem. Commodo veniam irure labore eu ipsum exercitation veniam minim tempor aliquip ullamco non ipsum nostrud.\r\n",
    "description": "Deserunt exercitation irure est laborum ex reprehenderit elit magna duis. Et duis duis enim adipisicing dolor. Do officia ut amet officia cillum cupidatat sunt do deserunt eiusmod ad ad fugiat quis. Et quis amet ipsum do incididunt sunt nulla non est reprehenderit incididunt anim ullamco.\r\n",
    "strategy": "Pariatur quis est pariatur laborum non et esse dolore sint pariatur anim laboris labore ipsum. Sunt reprehenderit laborum nisi ad. Duis eu officia ea nulla et non reprehenderit veniam qui ut ad anim. Laborum sunt cillum occaecat incididunt elit amet consectetur esse.\r\n",
    "keyLearnings": "Sint reprehenderit voluptate et enim. Dolore nostrud exercitation qui labore esse excepteur id nulla quis. Qui sint mollit dolor aliqua anim occaecat commodo aliquip dolor commodo sint. Duis ipsum mollit et culpa ex elit non eiusmod fugiat ex occaecat cupidatat ipsum. Elit non in pariatur labore commodo veniam culpa cupidatat aliqua est proident magna laborum labore.\r\n",
    "nextSteps": "Anim dolor ea sit quis incididunt. Consequat ut occaecat aute pariatur laboris nulla eu sit tempor sunt. Proident ut veniam Lorem sit exercitation minim sunt reprehenderit. Non laborum ad sint laborum voluptate excepteur elit. Ut aliquip consequat occaecat ut eiusmod in. Deserunt elit sunt ipsum amet sit excepteur pariatur laboris officia veniam.\r\n",
    "investment": "Incididunt excepteur ea sunt enim est fugiat consequat magna ea sit sint. Non eiusmod aute in voluptate ad amet consectetur ut duis. Sunt dolor ut proident dolore occaecat consectetur incididunt est est ut cillum nisi dolor fugiat. Dolor nulla proident nulla quis in aliquip reprehenderit. Adipisicing aute incididunt amet anim. Voluptate officia dolor non minim dolore qui aute dolore.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Mollit qui nulla id laborum irure nulla dolor Lorem ipsum esse. Nostrud eu officia laboris duis sint nulla veniam adipisicing consequat. Deserunt est duis adipisicing irure nostrud dolore fugiat laboris do sint eu est ut. Laborum eiusmod tempor ea nulla esse. Consectetur incididunt et Lorem do.\r\n",
      "engagementDescr": "Excepteur Lorem sit cupidatat culpa aliquip ullamco fugiat ipsum irure ut adipisicing fugiat. Labore nostrud irure enim est irure officia ea veniam. Nulla ipsum occaecat ut ad ad. Eiusmod sunt officia excepteur nulla in. Nisi nostrud nulla enim culpa sunt officia velit adipisicing non aute ut quis. Anim elit occaecat laborum ex nulla id occaecat adipisicing minim anim amet esse do.\r\n",
      "dataDescr": "Laborum commodo nulla id quis. Deserunt velit laboris esse laboris sit. In cupidatat excepteur elit minim aliqua et duis amet aute. Sit occaecat aute id voluptate dolor aliqua irure.\r\n",
      "routeDescr": "Velit amet aute irure laboris adipisicing sunt commodo minim pariatur cillum mollit consequat. Sit magna sunt est nulla in. Do est aliquip elit sint. Ex exercitation minim veniam non.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Fletcher Bryant",
        "email": "fletcherbryant@imkan.com",
        "fte": 0.1656
      },
      {
        "id": 1,
        "name": "Frankie Cunningham",
        "email": "frankiecunningham@imkan.com",
        "fte": 0.1896
      },
      {
        "id": 2,
        "name": "Pate Potts",
        "email": "patepotts@imkan.com",
        "fte": 0.3603
      },
      {
        "id": 3,
        "name": "Winnie Hanson",
        "email": "winniehanson@imkan.com",
        "fte": 0.9317
      },
      {
        "id": 4,
        "name": "Susanna Murphy",
        "email": "susannamurphy@imkan.com",
        "fte": 0.6207
      },
      {
        "id": 5,
        "name": "Rogers Nicholson",
        "email": "rogersnicholson@imkan.com",
        "fte": 0.5782
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Haynes Snyder",
        "email": "haynessnyder@imkan.com"
      },
      {
        "id": 1,
        "name": "Patsy Martin",
        "email": "patsymartin@imkan.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 12,
        "y3": 22
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 5,
        "y2": 53,
        "y3": 82
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 25,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6681743,
      "customerVal": 567385,
      "mvp": 18960002,
      "optimize": 493244,
      "grow": 1006172868,
      "scale": 535235519
    },
    "nextStepsActionPlan": "Lorem ipsum enim ex mollit voluptate culpa officia voluptate laborum velit et cillum. Nostrud reprehenderit eiusmod exercitation officia tempor. Labore cillum irure eu sit ad. Qui mollit aute eiusmod sunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Amet dolor esse aliquip sunt deserunt fugiat anim minim ea. Cillum id nisi esse voluptate reprehenderit cillum tempor nostrud officia. Velit deserunt veniam excepteur Lorem elit minim ipsum cillum consectetur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-15",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0391f222ad13c23c53",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Avit",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rp"
    ],
    "geography": "Spain",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Anim voluptate excepteur cupidatat ut amet nulla cupidatat eu aute sint elit irure aute. Qui aliquip tempor ut officia sit culpa est. Nisi sint exercitation enim qui aute et fugiat id non reprehenderit.\r\n",
    "description": "Eu id anim proident sunt officia fugiat esse. Non aliqua tempor dolor aute enim. Ex aute eiusmod aliquip id sit laboris. Consectetur mollit ex ut consequat eiusmod ipsum nisi ex qui officia. Aliquip non consectetur commodo non excepteur. Commodo sit dolor commodo cupidatat nulla tempor veniam. Veniam labore ut nostrud consectetur cupidatat consectetur.\r\n",
    "strategy": "Tempor enim reprehenderit nostrud aliqua velit fugiat amet id voluptate. Cillum quis eiusmod reprehenderit fugiat aute. Cillum elit duis fugiat do. Eiusmod excepteur nostrud irure labore proident consectetur.\r\n",
    "keyLearnings": "Sunt pariatur excepteur duis qui ut sint Lorem est tempor ipsum do excepteur adipisicing pariatur. Enim consequat culpa et excepteur. Aliquip quis pariatur mollit anim nisi laboris mollit aute est ea do. In dolor et reprehenderit et ad irure Lorem enim cillum. Excepteur nisi occaecat dolore esse pariatur in irure ad ad ullamco dolore aliqua veniam esse. Sunt incididunt dolor exercitation nostrud deserunt. Nostrud cupidatat aute ut cillum culpa anim tempor incididunt consequat ad ea.\r\n",
    "nextSteps": "Est sit reprehenderit elit ullamco dolore sint velit qui esse qui elit. Labore fugiat velit aliqua mollit cillum. Aliqua consequat magna magna dolor dolore proident sunt minim pariatur exercitation sit. Velit consectetur qui mollit adipisicing.\r\n",
    "investment": "Nisi deserunt deserunt occaecat sit sunt eu minim do nisi non cillum velit excepteur. Eiusmod eu adipisicing enim dolore voluptate excepteur nostrud incididunt ipsum sit voluptate cupidatat consequat. Nisi dolore eiusmod ut ut esse incididunt laborum quis duis ut id quis voluptate aute. Irure et Lorem sint ut occaecat. Nostrud aliqua culpa anim nisi ut proident culpa culpa. Laborum consequat ea labore magna. Consectetur esse excepteur nulla in sit occaecat Lorem Lorem ullamco velit nostrud veniam veniam.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Cillum fugiat officia excepteur nulla proident exercitation eu aliqua aliqua. Consectetur Lorem excepteur veniam ea exercitation deserunt voluptate mollit eu sit aliqua. Exercitation ex sit dolor quis laboris reprehenderit aute dolor ad. Sint dolore nostrud dolore sit ullamco ullamco velit in ipsum exercitation aliqua tempor.\r\n",
      "engagementDescr": "In esse aute fugiat aute excepteur ullamco nisi amet deserunt. In pariatur nulla sunt irure culpa enim esse proident id ex cillum. Consequat irure dolore sint aute anim est. Exercitation dolor mollit duis sunt irure elit. Pariatur mollit aute anim amet pariatur. Pariatur fugiat ipsum labore aliqua. Irure commodo nulla magna eu fugiat fugiat in deserunt non excepteur veniam.\r\n",
      "dataDescr": "Lorem eu qui aute aliquip qui cupidatat id laboris aliquip qui eiusmod commodo. Duis labore aliqua aute officia. Ipsum elit qui aute amet excepteur eu Lorem elit aliqua cupidatat. Enim eiusmod pariatur anim culpa cillum dolore sit ea ullamco ad tempor ullamco culpa. Aliqua nostrud ipsum ullamco veniam quis magna duis commodo in in ullamco cupidatat fugiat amet.\r\n",
      "routeDescr": "Dolore sint labore eiusmod mollit aliqua dolor voluptate. Laborum anim consectetur nulla adipisicing. Commodo non consequat eu consequat. Id do commodo est non cupidatat mollit eiusmod mollit eiusmod.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Vance Wolfe",
        "email": "vancewolfe@avit.com",
        "fte": 0.1309
      },
      {
        "id": 1,
        "name": "Francine Benton",
        "email": "francinebenton@avit.com",
        "fte": 0.0198
      },
      {
        "id": 2,
        "name": "Hebert Valdez",
        "email": "hebertvaldez@avit.com",
        "fte": 0.7004
      },
      {
        "id": 3,
        "name": "Warner Carey",
        "email": "warnercarey@avit.com",
        "fte": 0.0063
      },
      {
        "id": 4,
        "name": "Medina Cooke",
        "email": "medinacooke@avit.com",
        "fte": 0.743
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Deidre Anthony",
        "email": "deidreanthony@avit.com"
      },
      {
        "id": 1,
        "name": "Rodriquez Golden",
        "email": "rodriquezgolden@avit.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 17,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 3,
        "y2": 42,
        "y3": 70
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 10,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8031123,
      "customerVal": 521170,
      "mvp": 143700299,
      "optimize": 1773338,
      "grow": 755991772,
      "scale": 1012230850
    },
    "nextStepsActionPlan": "Et exercitation fugiat officia officia nisi mollit ullamco veniam. Nostrud adipisicing do voluptate anim. Enim esse ad magna cupidatat nisi ex id quis ad proident incididunt consequat ut aliquip. Exercitation nisi sit Lorem adipisicing voluptate veniam elit pariatur et enim nostrud eu proident. Pariatur Lorem esse dolor aliqua ipsum nulla. Ea amet qui Lorem esse laborum in culpa pariatur laboris cupidatat consequat enim laborum. Consectetur aute veniam ad excepteur occaecat eiusmod cupidatat dolore id.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Mollit et velit laborum sunt. Id amet cupidatat nulla amet cillum in elit exercitation ad sint culpa. Mollit consectetur reprehenderit cillum sunt minim dolor cupidatat aliquip incididunt. Magna ut velit voluptate in cupidatat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-30",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03981b4db2bd0cd5ac",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Pheast",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Marketpace",
    "division": "Division One",
    "painPoint": "Est tempor anim sint sunt. Sunt in tempor do commodo tempor. Nulla qui sunt consectetur aute enim eu culpa id aliqua enim. Laborum ex consequat excepteur est voluptate nostrud officia labore.\r\n",
    "description": "Aliqua nisi dolore sunt excepteur cupidatat incididunt enim ipsum aute excepteur. Nisi in anim quis magna culpa culpa velit. Enim sunt non elit velit non amet pariatur id dolore nulla ut ut velit. Non commodo proident id eiusmod occaecat non quis esse officia cupidatat exercitation non consectetur.\r\n",
    "strategy": "Ipsum dolore ipsum duis tempor eu do. Eiusmod eu quis nisi consectetur proident proident aliquip. Nisi minim ad magna officia esse quis velit.\r\n",
    "keyLearnings": "Duis minim consectetur Lorem consequat incididunt labore voluptate. Non Lorem esse tempor dolor proident duis aliqua aliquip ut. Adipisicing cillum id esse elit officia deserunt laboris commodo dolor non reprehenderit. Quis mollit nostrud occaecat occaecat veniam Lorem eiusmod amet est cupidatat aliqua qui pariatur. Qui officia dolor id officia adipisicing qui esse exercitation. Id velit consequat culpa nisi nisi labore cupidatat incididunt minim minim duis irure veniam aute. Deserunt dolor exercitation eu sint fugiat ullamco Lorem voluptate.\r\n",
    "nextSteps": "Culpa dolor elit consequat occaecat. Reprehenderit id aute magna minim veniam sit officia voluptate. Officia consequat sit ea sint pariatur.\r\n",
    "investment": "Laborum incididunt dolore in cupidatat laborum eu nulla elit pariatur ea adipisicing et laborum. Cillum veniam deserunt mollit ullamco dolor esse eiusmod et laborum labore esse ea do. Consectetur sunt reprehenderit velit quis aute excepteur magna irure reprehenderit nostrud Lorem qui ullamco. Dolore ea qui aliqua ipsum fugiat irure ullamco duis officia do mollit non Lorem nulla. Esse culpa dolor eu sit minim. Enim aliquip aliquip nisi minim quis magna deserunt aliquip et elit adipisicing Lorem esse occaecat. Officia sint enim anim ut commodo dolor fugiat elit cillum culpa Lorem dolor Lorem consectetur.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Exercitation minim pariatur aute ex consectetur dolor est officia nostrud tempor. Officia eiusmod eiusmod duis reprehenderit excepteur elit dolor. Magna consectetur ipsum culpa tempor.\r\n",
      "engagementDescr": "Voluptate velit excepteur in ad dolore consectetur elit mollit veniam veniam eu minim veniam et. Voluptate in do amet velit minim nulla velit aute aliqua occaecat minim et minim id. Irure elit amet anim commodo minim culpa ut commodo nulla exercitation id tempor voluptate. Aliquip ut officia incididunt ea ea do culpa irure magna. Veniam qui fugiat exercitation laboris consequat esse veniam pariatur. Velit dolor consectetur ad anim eiusmod laborum reprehenderit cillum enim laborum aliqua incididunt mollit.\r\n",
      "dataDescr": "Reprehenderit consequat proident do esse. Mollit anim minim sit dolore dolor dolor exercitation magna est occaecat nulla consectetur excepteur adipisicing. Lorem do adipisicing fugiat deserunt ullamco. Consectetur ut quis laboris et velit officia officia elit proident aliquip irure culpa.\r\n",
      "routeDescr": "Commodo enim consectetur labore in officia nisi. Dolore proident culpa aute est qui nisi consequat amet esse Lorem adipisicing ut excepteur. Officia anim ea aliqua voluptate et eu. Sit culpa ullamco aute velit eiusmod incididunt enim eu exercitation commodo minim. Ipsum culpa elit reprehenderit commodo non fugiat ullamco consectetur.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Luella Carroll",
        "email": "luellacarroll@pheast.com",
        "fte": 0.5692
      },
      {
        "id": 1,
        "name": "Caitlin Terrell",
        "email": "caitlinterrell@pheast.com",
        "fte": 0.9732
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Maynard Hunter",
        "email": "maynardhunter@pheast.com"
      },
      {
        "id": 1,
        "name": "Tisha Snider",
        "email": "tishasnider@pheast.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 8,
        "y3": 19
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 45,
        "y3": 73
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 19,
        "y3": 31
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13199063,
      "customerVal": 132214,
      "mvp": 115246890,
      "optimize": 1650824,
      "grow": 1051545099,
      "scale": 1387847769
    },
    "nextStepsActionPlan": "Nulla ullamco pariatur incididunt id non dolore elit dolor minim id ea. Exercitation magna nostrud tempor dolore officia. Dolore exercitation ad consequat pariatur quis veniam incididunt esse fugiat excepteur Lorem tempor dolor culpa. Nisi ad adipisicing cupidatat reprehenderit cupidatat anim ipsum proident.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Id tempor tempor minim voluptate commodo sunt deserunt nulla non ea Lorem ad minim. In mollit occaecat eu pariatur. Reprehenderit ex ut cupidatat sit irure anim labore ipsum cillum et sunt ad labore voluptate. Duis officia exercitation consectetur magna incididunt laboris id culpa proident occaecat nulla aute deserunt est. Aliqua proident occaecat occaecat do irure Lorem. Officia anim deserunt aute id Lorem mollit consectetur. Voluptate velit dolore dolor occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-09-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03963c3e2b9b28f486",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Cytrex",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Consequat nulla minim ullamco labore cupidatat consequat excepteur est commodo exercitation. Labore sunt irure adipisicing enim ex tempor aute enim laboris non esse reprehenderit minim. Aliquip pariatur ipsum tempor commodo nulla amet quis aliquip ad in. Dolore culpa anim ut ad Lorem est.\r\n",
    "description": "Reprehenderit sunt Lorem dolor eiusmod sunt id sunt nulla do. Qui qui tempor do dolor ullamco elit adipisicing laboris ut consequat consequat ea nulla. Reprehenderit nisi qui fugiat aute laborum aute nostrud.\r\n",
    "strategy": "Occaecat occaecat labore dolor cillum enim Lorem. Qui ea commodo proident nostrud nisi duis occaecat eu. Amet incididunt sunt adipisicing culpa ullamco est laborum. Elit eiusmod do et dolor voluptate commodo minim.\r\n",
    "keyLearnings": "Laboris minim cupidatat veniam reprehenderit commodo ut tempor quis aliquip labore proident. Sit qui officia nostrud ad cillum ex commodo deserunt id laborum officia deserunt. Consequat sint ullamco reprehenderit laborum sunt ipsum labore irure proident excepteur. Ex cillum do ex aute nisi voluptate adipisicing in labore proident. Quis voluptate sit deserunt voluptate dolore. Laboris veniam ullamco anim aliqua cillum duis culpa ex anim.\r\n",
    "nextSteps": "Consequat nisi ad est exercitation elit elit duis ad minim. Duis culpa ea culpa qui et Lorem non amet velit adipisicing. Mollit anim duis exercitation sit proident. Cillum nostrud nulla sunt non ut ut consectetur irure reprehenderit ex do ea. Elit qui sit excepteur culpa laboris commodo. Excepteur mollit occaecat duis aute culpa. Voluptate eu ad labore deserunt laboris minim id nostrud.\r\n",
    "investment": "Dolore irure amet id deserunt qui ea voluptate deserunt ea mollit irure laborum voluptate nisi. Lorem Lorem est velit dolor quis do nulla. Elit amet pariatur reprehenderit in minim dolor aute adipisicing velit occaecat duis cillum elit aliquip. Velit Lorem est exercitation tempor. Do irure ex ipsum dolore ipsum eiusmod nisi nostrud irure nostrud pariatur amet tempor ipsum.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Ex commodo nostrud ex ex pariatur dolor dolore aliquip do nulla cillum sunt. Irure veniam do quis qui laborum commodo. Aute aute consequat fugiat sit dolore eiusmod fugiat eiusmod laborum. Dolore dolore in duis aliqua proident aute sit in sit consectetur aliqua.\r\n",
      "engagementDescr": "Laborum qui officia aute esse sit consequat fugiat do velit commodo. Culpa cupidatat sit cillum magna consectetur laboris ut et est mollit pariatur adipisicing culpa. Elit non est fugiat eu reprehenderit labore. Nulla ex non exercitation eu id nostrud ipsum laboris laboris.\r\n",
      "dataDescr": "Tempor laboris ut dolore ex aute cupidatat do aute et qui eu anim. Nisi tempor ullamco aliquip ad amet ut ea sint sit cillum enim minim voluptate. Dolor officia magna pariatur culpa minim fugiat sunt mollit veniam sit. Aliquip dolor consequat mollit eu cupidatat ea deserunt. Labore anim Lorem mollit tempor occaecat sit dolor laboris. Reprehenderit et sunt consequat est esse id duis consequat enim culpa do velit elit.\r\n",
      "routeDescr": "Non ut quis enim deserunt. Pariatur sint nulla et dolore fugiat reprehenderit cillum. Reprehenderit eu laborum tempor duis anim ex veniam. Velit eiusmod magna elit quis. Laborum sit minim voluptate velit ex voluptate consequat ipsum reprehenderit veniam quis Lorem aute deserunt.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Grimes Mitchell",
        "email": "grimesmitchell@cytrex.com",
        "fte": 0.9452
      },
      {
        "id": 1,
        "name": "Snider Gutierrez",
        "email": "snidergutierrez@cytrex.com",
        "fte": 0.8069
      },
      {
        "id": 2,
        "name": "Rita Hamilton",
        "email": "ritahamilton@cytrex.com",
        "fte": 0.9578
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Lynnette Howell",
        "email": "lynnettehowell@cytrex.com"
      },
      {
        "id": 1,
        "name": "Deloris Barnett",
        "email": "delorisbarnett@cytrex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 20,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 57,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 22,
        "y3": 59
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4408870,
      "customerVal": 140871,
      "mvp": 84716709,
      "optimize": 8327887,
      "grow": 1304857091,
      "scale": 816388403
    },
    "nextStepsActionPlan": "Irure qui laboris et officia in culpa fugiat. Laboris sit culpa tempor anim. Culpa amet duis duis esse nostrud. Dolore irure sunt exercitation irure deserunt. Est reprehenderit irure laborum nisi. Ipsum minim incididunt enim voluptate.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis reprehenderit laboris dolor nostrud exercitation sint. Reprehenderit labore duis dolor mollit. Est incididunt qui dolore consequat sit. Non commodo adipisicing ex ipsum do. Adipisicing id ad ea labore eu. Laborum consequat cillum ea sint commodo eiusmod quis sit eiusmod exercitation magna.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-22",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03cc7aa426fd94b83b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Comtrak",
    "highlightKPI": [
      "ctr",
      "ltv",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Dolore voluptate aute dolor sunt voluptate aliqua sit amet in ex officia magna. Duis commodo elit consectetur do eu eiusmod sit anim aute commodo officia do. Amet occaecat cupidatat dolore sunt consectetur in. Sint reprehenderit culpa nulla labore esse. Sunt esse consectetur cillum occaecat id Lorem consectetur cupidatat. Eiusmod fugiat Lorem sint excepteur nisi ipsum Lorem ut. Est magna sit ullamco aliqua non laboris est elit aliquip dolor aliqua nulla ad.\r\n",
    "description": "Ea eu amet ex occaecat adipisicing exercitation. Consequat tempor eiusmod id exercitation minim. Laborum dolore commodo veniam ipsum occaecat cupidatat proident proident eiusmod. Amet labore exercitation laborum laboris. Magna mollit sint officia ad. Irure nisi ad officia nulla ipsum mollit. Aute ipsum laboris aliquip eu quis eu.\r\n",
    "strategy": "Esse occaecat sunt amet nisi tempor Lorem. Laborum est adipisicing excepteur nisi ex ullamco aliquip elit officia aliqua minim enim ipsum voluptate. In nulla cupidatat veniam officia sit. Amet excepteur sit laborum ipsum ullamco et ea ex.\r\n",
    "keyLearnings": "Pariatur irure officia voluptate laboris consectetur et culpa amet magna est nulla. Esse id nulla non incididunt ut dolor consectetur. Veniam aute sit anim proident eu enim reprehenderit ex exercitation dolor. Ex ut cillum eu irure eu laboris aliqua qui deserunt ut quis aliqua.\r\n",
    "nextSteps": "Excepteur minim reprehenderit laborum Lorem exercitation ut sit velit culpa occaecat labore laboris aliqua dolor. Sit laboris aliquip incididunt id dolor irure Lorem. Fugiat proident nostrud non in veniam. Occaecat mollit aliquip eiusmod amet elit nostrud culpa ut ea. Reprehenderit exercitation eiusmod culpa ad mollit aute id dolore tempor laborum adipisicing qui. In ut nulla ea ipsum. Labore nostrud velit nostrud culpa.\r\n",
    "investment": "Nostrud nostrud commodo irure pariatur nulla velit dolor velit amet occaecat proident duis. Tempor fugiat excepteur amet proident do ex dolor eu magna deserunt elit occaecat. Do veniam qui veniam reprehenderit aliqua nulla do cupidatat enim reprehenderit sit dolor veniam aute. Laboris officia anim occaecat elit incididunt proident velit quis cillum nisi ut.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Ullamco consequat est laboris consectetur in aliquip quis sint consectetur. Id sint sunt duis irure voluptate consectetur. Do exercitation cupidatat do irure velit enim eu tempor ut consectetur occaecat ut ipsum adipisicing. Et culpa incididunt laboris ea aute mollit veniam incididunt sint sint pariatur. Quis consequat cillum consectetur excepteur eiusmod officia aliquip fugiat mollit elit.\r\n",
      "engagementDescr": "Commodo incididunt amet dolore enim. Ullamco reprehenderit officia ea dolore aliquip irure sit. Non id culpa eu dolor et dolore dolore ut sunt cupidatat fugiat aliqua. Exercitation ea sit ullamco excepteur eu ullamco esse nisi culpa commodo qui id enim.\r\n",
      "dataDescr": "Et nostrud eiusmod labore velit pariatur velit aute labore cupidatat aute consectetur velit aliquip ipsum. Nisi occaecat quis mollit do incididunt aliqua Lorem adipisicing sunt amet. Mollit laboris ullamco laboris aliquip tempor et elit adipisicing aliqua nostrud proident labore aliqua. Ad ad magna tempor enim nostrud sunt.\r\n",
      "routeDescr": "Ad consectetur elit excepteur sit ad esse tempor nisi. Pariatur ipsum sit sint id. Amet commodo non non aliqua magna adipisicing exercitation et elit laborum ut sint dolor. Excepteur occaecat esse ipsum consectetur voluptate dolore magna aliquip aliquip culpa sunt amet non.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Pena Slater",
        "email": "penaslater@comtrak.com",
        "fte": 0.1448
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Wilkinson Walsh",
        "email": "wilkinsonwalsh@comtrak.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 20,
        "y3": 19
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 44,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 21,
        "y3": 38
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3532229,
      "customerVal": 1419322,
      "mvp": 124078133,
      "optimize": 6984044,
      "grow": 760070290,
      "scale": 1094860607
    },
    "nextStepsActionPlan": "Laboris laborum Lorem irure adipisicing officia. Officia culpa proident culpa qui id irure. Velit tempor eiusmod anim ullamco consequat sit anim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cupidatat fugiat officia velit aliqua consectetur amet anim pariatur. Commodo consequat dolore occaecat dolore enim proident tempor qui eiusmod dolor fugiat nostrud duis. Sint aute laborum fugiat fugiat exercitation tempor ipsum consequat in id.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-22",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03261bd59eacec6680",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Gogol",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rp"
    ],
    "geography": "India",
    "channel": "Offline: Pop up store",
    "division": "Division Three",
    "painPoint": "In ex sint Lorem anim nostrud consectetur qui laboris eiusmod aliqua et labore. Sit irure tempor consequat exercitation duis fugiat. Duis fugiat sunt Lorem sint enim. Ex aute id deserunt officia labore consequat Lorem ut. Voluptate qui duis sint quis eiusmod.\r\n",
    "description": "Amet Lorem occaecat laboris laboris esse consequat sint sint. Sint culpa officia sit amet. Ad in ea commodo amet aute dolore velit. Nostrud incididunt occaecat proident sint deserunt elit aliqua ex.\r\n",
    "strategy": "Nisi laboris occaecat aliquip ex id quis cupidatat deserunt. Est et Lorem non aute consequat enim. Pariatur occaecat dolor sit tempor est adipisicing Lorem ad fugiat anim sit ullamco. Aute veniam laborum qui anim id laboris occaecat deserunt irure tempor. Mollit esse do non exercitation occaecat.\r\n",
    "keyLearnings": "Mollit consequat fugiat laboris magna consectetur magna irure ullamco. Magna quis in esse excepteur eu irure eiusmod qui aliqua quis non. Culpa ea reprehenderit do proident incididunt voluptate eiusmod. Laborum anim pariatur magna minim velit laborum voluptate et labore mollit aute cupidatat proident veniam. Quis anim enim deserunt dolore exercitation dolore cupidatat. Amet elit irure duis consequat tempor sit aliquip culpa culpa culpa et.\r\n",
    "nextSteps": "Sint tempor magna cupidatat eiusmod sunt minim culpa adipisicing sunt nulla magna. Non sint dolor enim ex tempor voluptate eu tempor incididunt et. Non mollit reprehenderit esse consequat proident. Officia adipisicing fugiat do minim elit. Aute excepteur consequat ut nulla minim magna eu fugiat ut tempor do reprehenderit cupidatat sunt. Minim enim qui laborum nisi aute ipsum minim minim labore laborum reprehenderit. Ea incididunt irure officia eu consectetur amet sint minim dolore pariatur officia nisi sit.\r\n",
    "investment": "Sit sint elit enim aute veniam deserunt commodo nostrud do ex. Sit minim eiusmod ea quis tempor eiusmod dolor minim proident. Sit veniam ex laboris minim eu proident consequat irure id fugiat. Sit culpa ex veniam fugiat excepteur. Do dolor nulla est cupidatat exercitation voluptate eiusmod.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Excepteur adipisicing qui excepteur proident qui sit nisi consequat dolor nulla amet aliqua. Tempor laborum cillum nostrud officia ullamco fugiat. Non sunt occaecat esse non ea fugiat. Nisi irure velit quis cillum aliqua do incididunt. Et nisi quis ad qui aliqua non ad laboris. Incididunt qui aute deserunt adipisicing. Ex reprehenderit enim dolor et.\r\n",
      "engagementDescr": "Commodo ullamco aliquip sit exercitation. Incididunt laborum sit magna cupidatat voluptate ad cillum culpa labore enim elit. Cupidatat anim fugiat sint magna ipsum ea deserunt elit.\r\n",
      "dataDescr": "Ipsum dolor culpa officia qui elit velit. Nostrud exercitation reprehenderit est velit consequat aliquip ad qui et Lorem aliqua sit. Fugiat ut anim magna reprehenderit sint duis duis est nulla do duis ad dolor.\r\n",
      "routeDescr": "Exercitation nulla mollit sunt amet in tempor dolore in nisi. Cillum mollit aliqua in velit amet ipsum laborum. Aute magna occaecat id labore aute do sit dolor veniam id et reprehenderit. Ad cillum qui commodo occaecat sint nulla.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Marisa Steele",
        "email": "marisasteele@gogol.com",
        "fte": 0.4599
      },
      {
        "id": 1,
        "name": "Lina Benjamin",
        "email": "linabenjamin@gogol.com",
        "fte": 0.3548
      },
      {
        "id": 2,
        "name": "Powell Pennington",
        "email": "powellpennington@gogol.com",
        "fte": 0.2783
      },
      {
        "id": 3,
        "name": "Larsen Wilkins",
        "email": "larsenwilkins@gogol.com",
        "fte": 0.643
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Amparo Kirby",
        "email": "amparokirby@gogol.com"
      },
      {
        "id": 1,
        "name": "Ivy Nolan",
        "email": "ivynolan@gogol.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 8,
        "y3": 28
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 52,
        "y3": 63
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 23,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14536380,
      "customerVal": 504210,
      "mvp": 14713377,
      "optimize": 2751741,
      "grow": 719698245,
      "scale": 311182269
    },
    "nextStepsActionPlan": "Proident proident eu excepteur aute id sunt culpa pariatur aliqua dolor cillum. Lorem do do sint eiusmod non quis enim sit reprehenderit ex dolor. Veniam dolore dolore nostrud cupidatat labore laboris dolor ipsum dolore duis nulla laboris culpa. Lorem aliquip ut nulla consectetur tempor nostrud occaecat officia laborum non. Ipsum aute veniam dolore Lorem magna laborum nisi fugiat ad qui reprehenderit labore. Ullamco sit excepteur enim qui cillum excepteur dolor minim tempor officia est ea sint ex.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Non eiusmod magna in anim tempor reprehenderit amet ad elit ea do nisi. Sint laboris eu adipisicing mollit aute dolor officia tempor proident nulla et. Deserunt nisi labore adipisicing veniam amet labore cillum veniam nulla dolor. Aliqua sint veniam adipisicing consequat irure non aliqua. Irure quis veniam aliqua magna culpa amet ipsum ad voluptate ex proident occaecat laboris.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-04",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b86b234637c54efe",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Intergeek",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "United States",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Ullamco eu ipsum labore aliquip ad elit commodo eu labore enim aliqua. Consequat amet labore eiusmod magna aliquip aliqua anim aliqua proident. Cupidatat incididunt magna est ut do incididunt do nisi fugiat ea commodo aute.\r\n",
    "description": "Qui fugiat nostrud tempor est. Ad reprehenderit proident ex sit ea dolore eu enim adipisicing exercitation ipsum ad. Nostrud do esse quis nisi Lorem labore minim esse laborum Lorem elit reprehenderit est.\r\n",
    "strategy": "Esse eiusmod irure quis eu tempor exercitation. Ex qui voluptate quis amet enim eiusmod ullamco. Quis exercitation voluptate laboris aute est ipsum reprehenderit veniam veniam aliquip aliquip dolor consectetur. Commodo esse anim quis sit sit voluptate amet Lorem do dolor consectetur sunt veniam.\r\n",
    "keyLearnings": "Irure incididunt laborum nulla labore culpa aute ea quis officia proident laboris duis eu. Non anim quis sit incididunt velit irure consequat et. Anim aliquip id do commodo duis magna dolor est ullamco. Ullamco dolor ullamco aliqua quis minim ex et cupidatat irure ea. Veniam nulla do aliqua irure nulla ullamco et cillum consectetur mollit enim cupidatat.\r\n",
    "nextSteps": "Incididunt ex veniam ut nisi laboris est anim duis enim consectetur et. Ea adipisicing minim irure eu minim nisi. Adipisicing ut et aliquip culpa aute occaecat. Sint officia tempor ex sint ut et elit Lorem minim magna. Aute anim ea in qui. Consectetur mollit laborum ea Lorem duis. Officia ad irure duis ipsum proident elit pariatur.\r\n",
    "investment": "Dolore ad consequat ullamco fugiat eu eu id laboris adipisicing. Exercitation ex fugiat Lorem ea. Tempor sint consequat Lorem velit dolor nostrud incididunt mollit. Est quis excepteur ad sit.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Amet minim non sunt sunt do sit sint Lorem ut. Qui est velit nulla tempor cupidatat velit dolore id aute incididunt laborum consectetur labore. Laboris ea veniam sit ea Lorem aliquip est id tempor. Consequat id ea aliquip do sint laborum duis excepteur. Aliqua ullamco enim reprehenderit aliqua.\r\n",
      "engagementDescr": "Id ea laborum ullamco est. Cillum exercitation commodo commodo consequat velit. Irure laboris velit proident culpa non exercitation non. Id consequat eu sit excepteur est nisi veniam ipsum eiusmod aliquip duis est. Elit duis voluptate commodo tempor pariatur nisi consequat fugiat excepteur et reprehenderit pariatur irure.\r\n",
      "dataDescr": "Qui tempor velit consequat qui. Tempor aliquip do laborum Lorem eiusmod aliqua nulla ea. Commodo cupidatat commodo amet amet incididunt eiusmod do ex.\r\n",
      "routeDescr": "Cupidatat cupidatat anim eiusmod ullamco ullamco nostrud Lorem id culpa nisi mollit. Nulla do ea consectetur amet eiusmod. Anim incididunt culpa cillum consequat voluptate dolor tempor et sunt excepteur. Adipisicing dolor amet laborum ullamco veniam mollit. Et nisi adipisicing dolore amet nulla eu esse ad sunt eu occaecat incididunt adipisicing. Ipsum aute esse non elit sunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Marcia Morrison",
        "email": "marciamorrison@intergeek.com",
        "fte": 0.9598
      },
      {
        "id": 1,
        "name": "Lott Middleton",
        "email": "lottmiddleton@intergeek.com",
        "fte": 0.1741
      },
      {
        "id": 2,
        "name": "Laverne Davenport",
        "email": "lavernedavenport@intergeek.com",
        "fte": 0.2899
      },
      {
        "id": 3,
        "name": "Blanchard Fischer",
        "email": "blanchardfischer@intergeek.com",
        "fte": 0.2319
      },
      {
        "id": 4,
        "name": "Joyner Bowen",
        "email": "joynerbowen@intergeek.com",
        "fte": 0.7182
      },
      {
        "id": 5,
        "name": "Reynolds Johns",
        "email": "reynoldsjohns@intergeek.com",
        "fte": 0.3305
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Dickson Powell",
        "email": "dicksonpowell@intergeek.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 18,
        "y3": 22
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 51,
        "y3": 58
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 28,
        "y3": 30
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10534649,
      "customerVal": 751486,
      "mvp": 10817279,
      "optimize": 131600,
      "grow": 706395759,
      "scale": 728893702
    },
    "nextStepsActionPlan": "In fugiat eiusmod magna et irure quis qui cillum aute. Magna commodo do eiusmod pariatur veniam occaecat laborum voluptate laboris enim veniam dolore quis eiusmod. Laboris elit eu duis occaecat do officia dolor quis esse ad nostrud. Aliquip velit quis et velit veniam fugiat mollit labore aute irure. Consequat voluptate pariatur sunt cupidatat officia enim nostrud officia et. Deserunt amet ea eu dolore sint exercitation dolore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis nisi ullamco ex sit proident Lorem reprehenderit ea cillum in ipsum consequat. Dolore eiusmod occaecat irure minim sunt cillum ullamco ea ipsum. Nulla sint in ex elit adipisicing est consectetur excepteur incididunt. Nostrud do proident proident veniam occaecat Lorem ut in sit deserunt adipisicing. Enim consequat mollit laboris minim est fugiat sunt officia velit veniam in Lorem aliqua nisi. Magna minim nulla velit est qui ut. Cupidatat anim nisi adipisicing nisi deserunt nulla ut dolore elit ex dolore fugiat eiusmod duis.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03491e9af7358e3c9d",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Scenty",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "Brazil",
    "channel": "Online: Home delivery",
    "division": "Division Two",
    "painPoint": "Culpa exercitation adipisicing exercitation nostrud ex ut sit eiusmod nisi esse officia. Quis enim do ex excepteur ea aliquip consectetur ex aute pariatur sint ad adipisicing mollit. Ad enim reprehenderit sint ex voluptate officia tempor et in dolor. Deserunt adipisicing aliqua reprehenderit aliquip aliquip eu tempor ullamco pariatur. Esse est non exercitation aliqua eiusmod ut cillum duis aliqua. Qui sit in ea nulla fugiat in ea elit tempor consequat laborum mollit. Veniam ullamco est in proident Lorem irure.\r\n",
    "description": "Eiusmod est non amet aliqua proident. Dolor duis enim eiusmod labore in voluptate adipisicing reprehenderit officia laboris officia. Sint ullamco cillum reprehenderit ullamco commodo dolor anim culpa. Ut aliqua in mollit tempor ea velit magna proident do consectetur. Non ex sunt magna ullamco sit. Ullamco reprehenderit voluptate exercitation aliqua ea ipsum dolore aute pariatur ipsum occaecat.\r\n",
    "strategy": "Aliqua aute irure nisi laboris cillum velit culpa aliqua in magna non. Labore velit irure ullamco irure exercitation. Sint quis qui nisi occaecat tempor. Anim anim sit dolore dolor consequat non proident ex exercitation nostrud ad aute elit. Reprehenderit in laboris consequat duis mollit aliqua adipisicing irure labore in occaecat. Esse eiusmod excepteur proident irure ex esse proident id duis non duis sit. Elit sunt minim nisi nulla duis eiusmod.\r\n",
    "keyLearnings": "Aliquip dolore in excepteur elit id irure velit laboris ex duis sunt excepteur. Do amet officia consectetur exercitation laboris ex nulla quis nulla. Esse deserunt laboris et sit ex labore commodo. Esse id incididunt in laborum exercitation duis id eu eiusmod do ea dolore minim. Lorem ullamco in eiusmod ea incididunt. Velit aute cupidatat labore qui eu culpa do eiusmod culpa exercitation. Tempor anim irure id pariatur ut cupidatat minim consequat consectetur dolor labore enim.\r\n",
    "nextSteps": "Cillum aliqua velit nulla eiusmod in. Id culpa et non culpa ex qui est irure quis pariatur proident proident. Enim pariatur deserunt adipisicing elit. Cupidatat nostrud cupidatat anim commodo quis et labore nisi irure nisi.\r\n",
    "investment": "Mollit veniam Lorem eiusmod nisi id nisi qui nisi laboris nostrud. Dolore esse fugiat cillum esse Lorem velit cillum Lorem non irure elit ipsum ullamco aliqua. Aliqua sit elit eu consectetur mollit exercitation voluptate dolor sit ullamco consectetur voluptate aliquip quis.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Consectetur velit esse qui eu ea eiusmod aliqua tempor ea. Id labore tempor ea excepteur occaecat aliquip mollit eu sint irure. Fugiat id ut dolor elit sit enim mollit est exercitation aliqua sint aute et elit. Mollit laborum voluptate proident ea quis do ex.\r\n",
      "engagementDescr": "Do officia amet ad ex tempor culpa dolor minim ut. Eiusmod quis eu irure sint cupidatat elit est ad. Excepteur sit laborum aliqua ut irure elit irure minim Lorem aliqua.\r\n",
      "dataDescr": "Deserunt dolor ullamco adipisicing voluptate do in nulla officia ea aliqua fugiat adipisicing laborum. Do eu veniam consequat proident sint. Pariatur aute magna amet non fugiat velit. Adipisicing consectetur ad ullamco laborum duis ad eu excepteur consequat labore excepteur. Ex magna amet anim cupidatat anim. Cupidatat duis reprehenderit sint et aliqua fugiat sit tempor. In mollit ea labore sint ipsum deserunt enim nulla sunt aliqua laboris incididunt.\r\n",
      "routeDescr": "Ad quis aliqua in proident officia aliqua laborum magna. Lorem ut reprehenderit ipsum aliqua. Aliqua esse cillum id ex ut cillum exercitation sit ipsum id.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Eva Atkinson",
        "email": "evaatkinson@scenty.com",
        "fte": 0.0254
      },
      {
        "id": 1,
        "name": "Hewitt Jones",
        "email": "hewittjones@scenty.com",
        "fte": 0.0374
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Cherry Gomez",
        "email": "cherrygomez@scenty.com"
      },
      {
        "id": 1,
        "name": "Benjamin Todd",
        "email": "benjamintodd@scenty.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 8,
        "y3": 34
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 20,
        "y3": 96
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 22,
        "y3": 93
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2835825,
      "customerVal": 142210,
      "mvp": 117951552,
      "optimize": 14288604,
      "grow": 997052115,
      "scale": 220438150
    },
    "nextStepsActionPlan": "Nostrud sunt nostrud ex irure aute duis sunt qui nisi irure ipsum deserunt cillum. Deserunt occaecat dolor sit in exercitation voluptate consectetur aliqua pariatur qui adipisicing officia ex elit. Eu id non non irure non in dolore ea occaecat nostrud. Non labore laboris voluptate duis id. Reprehenderit Lorem eu dolore eu anim Lorem anim ipsum et. Eiusmod eu velit incididunt laboris. Elit Lorem enim ad reprehenderit anim pariatur magna deserunt voluptate fugiat enim aliquip.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aute irure dolor quis aliquip Lorem labore irure id minim ea minim. Dolor eiusmod esse est est veniam commodo cillum. Deserunt ut dolor nostrud nulla amet ex anim ut sit nostrud dolore do eiusmod non.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0313eb0668fc6096ea",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Centree",
    "highlightKPI": [
      "cac",
      "gmac",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Shop in shop",
    "division": "Division Two",
    "painPoint": "Duis nostrud fugiat id minim quis nisi magna ullamco aliqua et officia nisi deserunt voluptate. Elit cillum quis sint deserunt aliquip laborum enim consequat tempor ullamco incididunt. Ut enim amet consectetur duis sunt laborum ex magna sunt cupidatat consequat. Excepteur ut sunt id sit aute eiusmod amet ut ad qui laboris commodo deserunt labore. Culpa in duis ad tempor nostrud nostrud irure Lorem veniam non id incididunt.\r\n",
    "description": "Velit consequat incididunt qui tempor eiusmod. Qui amet ipsum est qui aliquip occaecat quis pariatur ullamco. Qui cillum culpa do eu dolor do excepteur esse. Consequat labore Lorem magna aliquip ex non sunt sunt est reprehenderit duis. Consectetur laborum aliqua mollit laborum cillum ea ea nisi exercitation quis.\r\n",
    "strategy": "Officia deserunt minim est culpa. Laborum exercitation aliqua aliquip cupidatat laborum dolore incididunt minim. Non labore id commodo adipisicing aliqua ex.\r\n",
    "keyLearnings": "Quis dolore cillum non exercitation. In exercitation ex adipisicing officia sit. Sit eu deserunt sunt qui sint aute minim amet veniam ea aliquip qui. Elit labore voluptate qui magna eiusmod nisi elit ad duis in cupidatat. Esse officia culpa ea occaecat magna et ullamco eu Lorem non quis in proident eiusmod.\r\n",
    "nextSteps": "Qui aliqua ullamco sunt sint ea voluptate sint eiusmod aliquip nostrud aute labore commodo aliqua. Pariatur quis fugiat Lorem velit ex deserunt consectetur eiusmod esse consectetur. Incididunt voluptate pariatur do commodo irure adipisicing tempor fugiat in mollit. Laboris laborum qui esse ea reprehenderit consequat exercitation quis pariatur. Elit sit adipisicing elit excepteur duis aute.\r\n",
    "investment": "Anim commodo aute dolor pariatur. Ipsum enim nulla exercitation non. Mollit nulla ea eu et pariatur consectetur tempor adipisicing. Enim aliqua aute magna sint duis sit duis commodo minim excepteur. Mollit mollit id mollit est commodo velit adipisicing excepteur esse dolore. Nisi in dolor non pariatur mollit veniam pariatur fugiat voluptate pariatur cillum dolor culpa.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Cupidatat officia in ex enim ut pariatur officia non non magna. Aliqua consequat magna laboris irure aliqua ea. Exercitation excepteur Lorem sint et do culpa esse occaecat esse culpa. Sunt mollit exercitation qui quis anim anim commodo cillum Lorem occaecat cupidatat. Do laborum Lorem amet nulla cupidatat. Proident amet pariatur aliqua reprehenderit nisi duis consequat nisi quis ipsum.\r\n",
      "engagementDescr": "Et ut voluptate enim minim sit velit incididunt occaecat culpa ipsum dolore ullamco dolor velit. Nisi reprehenderit elit Lorem non proident minim reprehenderit nostrud ex nostrud esse cupidatat laboris aute. Lorem do consectetur reprehenderit ea exercitation. Reprehenderit incididunt quis do eu tempor enim quis fugiat enim eiusmod cupidatat. Laboris tempor irure anim commodo Lorem culpa minim ex sint esse officia. Sint Lorem veniam nostrud adipisicing aliquip veniam. Consectetur duis in aliquip ad fugiat aliqua ad enim deserunt ad.\r\n",
      "dataDescr": "Consequat labore minim nulla do dolore qui esse ad dolor. Lorem fugiat culpa consequat enim est nulla. Sint aute id elit occaecat. Magna reprehenderit consectetur esse esse occaecat ipsum minim ex ut. Officia consequat id elit sit ea incididunt.\r\n",
      "routeDescr": "Anim eu proident ea ipsum nostrud esse est voluptate ad velit dolore dolore in. Non irure aute tempor culpa. Sint tempor amet fugiat aliquip ipsum quis ad eu dolore incididunt minim non adipisicing. Id laboris officia irure sunt mollit magna quis aliquip ullamco non fugiat.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Ollie Browning",
        "email": "olliebrowning@centree.com",
        "fte": 0.7148
      },
      {
        "id": 1,
        "name": "Hill Delgado",
        "email": "hilldelgado@centree.com",
        "fte": 0.5447
      },
      {
        "id": 2,
        "name": "Kelly Roberson",
        "email": "kellyroberson@centree.com",
        "fte": 0.9533
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Frazier Ochoa",
        "email": "frazierochoa@centree.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 14,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 18,
        "y2": 39,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 19,
        "y3": 67
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 19274,
      "customerVal": 420564,
      "mvp": 1653050,
      "optimize": 5604656,
      "grow": 1108755805,
      "scale": 574989885
    },
    "nextStepsActionPlan": "Excepteur Lorem eiusmod aute dolore dolore culpa. Quis est magna tempor anim do quis enim proident mollit. Cupidatat ad non qui velit do ut non est. Sit quis dolor quis ea tempor. Eiusmod adipisicing laboris id esse. Qui laborum aliqua magna magna do exercitation anim reprehenderit commodo adipisicing. Eiusmod consectetur laborum et aliquip anim consectetur nulla nulla aliquip nostrud anim esse.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Irure enim officia enim laboris adipisicing ad sunt do fugiat velit excepteur aliqua fugiat ipsum. Ea magna nostrud occaecat incididunt consectetur. Irure quis cillum mollit non velit velit minim. Minim et excepteur voluptate laborum velit do ex irure eiusmod tempor in consequat fugiat excepteur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d57ce7e405078969",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Recritube",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Norway",
    "channel": "Online: Digital distributor",
    "division": "Division One",
    "painPoint": "Enim reprehenderit nostrud tempor nostrud qui incididunt incididunt quis. Nisi tempor eiusmod et ad aliqua commodo est adipisicing dolor. Voluptate esse excepteur sunt commodo quis non veniam fugiat labore aute. Est ea consectetur consectetur deserunt adipisicing deserunt fugiat sit in magna. Aliqua occaecat culpa veniam dolore officia ad aliqua esse sint nulla ut aute sit duis.\r\n",
    "description": "Laborum magna cupidatat amet dolore labore culpa fugiat mollit dolore elit. Lorem ullamco eu minim mollit aute excepteur laborum. Ad aliquip in ullamco nulla minim. Consectetur id nisi laborum culpa velit labore pariatur. Ut nisi nulla aute minim excepteur voluptate anim labore minim labore magna ex. Ut labore sit ea deserunt esse reprehenderit enim Lorem nostrud tempor minim.\r\n",
    "strategy": "Proident nostrud do excepteur cillum elit ex ad sit pariatur nulla duis ad sit. Consequat ex labore Lorem labore eiusmod excepteur sint dolor culpa anim dolor ut anim ipsum. Deserunt sint fugiat officia nulla quis non irure eiusmod cupidatat nostrud. Anim pariatur dolor laborum quis excepteur do esse. Fugiat eiusmod reprehenderit ut esse enim aute ex laboris velit laboris sit. Consequat elit enim non amet nostrud do.\r\n",
    "keyLearnings": "Nulla officia ea consectetur nisi in cupidatat dolore quis minim amet ad. Incididunt minim duis velit in magna ea duis. Lorem do eiusmod voluptate laborum deserunt cillum in culpa aliquip non exercitation.\r\n",
    "nextSteps": "Excepteur id sit ad elit in dolore proident labore dolore fugiat esse elit. Id sint officia cillum mollit excepteur. Labore laborum sint ut aliquip excepteur laborum amet labore culpa aute velit qui cillum.\r\n",
    "investment": "Adipisicing deserunt nisi esse sit cillum non reprehenderit deserunt tempor et consequat labore irure. Proident officia aute mollit proident deserunt ipsum deserunt incididunt minim. Irure laborum mollit est elit aute ullamco deserunt laborum minim. Eiusmod cillum dolore velit do officia magna eu. Cupidatat duis deserunt exercitation cillum cupidatat sunt anim fugiat sint. Pariatur eiusmod labore voluptate ut fugiat esse tempor ex do eiusmod enim reprehenderit aliquip. Ut commodo minim sint occaecat anim mollit deserunt non irure.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Veniam Lorem cupidatat exercitation sint nisi dolore sunt. Nisi duis ipsum do sunt dolore commodo aliquip enim sint consectetur enim. Dolore deserunt do quis est fugiat consequat excepteur dolore ea.\r\n",
      "engagementDescr": "Eiusmod amet quis sint officia. Amet adipisicing tempor reprehenderit ut in et eiusmod esse do do duis sint et. Aliquip consequat enim nisi ad et Lorem dolore duis ullamco ea duis non eu. Cillum in magna dolore ipsum nisi consectetur in labore dolore incididunt exercitation esse. Occaecat minim eu elit do magna non et elit culpa non incididunt.\r\n",
      "dataDescr": "Elit Lorem sint occaecat do qui aute esse ut et ut nulla occaecat sint ad. Fugiat aliqua veniam esse cillum est. Ad commodo labore ipsum ipsum. Laboris elit excepteur et ut occaecat nisi commodo. Sint reprehenderit voluptate enim enim est anim esse ea. Minim cillum nostrud tempor sit do nostrud anim in tempor irure reprehenderit consectetur sint.\r\n",
      "routeDescr": "Enim magna nisi duis exercitation et. Minim velit officia ipsum laborum. Sit tempor ullamco adipisicing irure consectetur nostrud incididunt nisi voluptate cupidatat magna. Ex exercitation anim Lorem veniam tempor proident anim velit cillum magna reprehenderit amet cillum.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Mack Berry",
        "email": "mackberry@recritube.com",
        "fte": 0.6043
      },
      {
        "id": 1,
        "name": "Ratliff Roberts",
        "email": "ratliffroberts@recritube.com",
        "fte": 0.3798
      },
      {
        "id": 2,
        "name": "Clarice Cross",
        "email": "claricecross@recritube.com",
        "fte": 0.8551
      },
      {
        "id": 3,
        "name": "Torres Swanson",
        "email": "torresswanson@recritube.com",
        "fte": 0.5891
      },
      {
        "id": 4,
        "name": "Simon Norton",
        "email": "simonnorton@recritube.com",
        "fte": 0.8893
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Julia Manning",
        "email": "juliamanning@recritube.com"
      },
      {
        "id": 1,
        "name": "Jody York",
        "email": "jodyyork@recritube.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 8,
        "y3": 35
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 4,
        "y2": 33,
        "y3": 65
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 17,
        "y3": 79
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11837936,
      "customerVal": 974362,
      "mvp": 13091602,
      "optimize": 4972395,
      "grow": 305791043,
      "scale": 504441121
    },
    "nextStepsActionPlan": "Et in nisi tempor sunt magna id veniam sunt et culpa pariatur quis. Ut nulla irure fugiat nulla ullamco et aliqua tempor ad cupidatat. Officia irure excepteur laboris occaecat amet enim voluptate aute amet ut aliqua cillum. Esse amet dolor consectetur qui elit ex exercitation eu dolore aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt quis veniam voluptate irure reprehenderit exercitation. Voluptate aute laboris commodo quis consequat pariatur adipisicing Lorem. Nisi ad esse ex deserunt ullamco dolor nisi nostrud laboris dolore nostrud voluptate. Do voluptate enim Lorem amet. Eu sint sunt adipisicing minim dolor ex ullamco consequat commodo non ad. Duis ipsum dolore enim veniam veniam ea ea ad veniam proident tempor cillum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-13",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03e007aa0f66d7dc74",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Geostele",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "China",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Ipsum exercitation deserunt reprehenderit voluptate reprehenderit. Ea in aute Lorem consequat ut adipisicing incididunt sit dolor. Officia aliqua velit reprehenderit eu velit nulla nostrud amet in exercitation Lorem do. Magna nulla et mollit ullamco nostrud ut commodo quis occaecat consequat proident. Dolore elit ex proident consequat officia veniam ut. Esse nisi amet velit enim sunt qui ipsum occaecat.\r\n",
    "description": "Deserunt duis cillum cillum enim eiusmod qui tempor ipsum ex veniam officia sunt laborum. Consectetur officia aute velit do eu cupidatat magna. Ullamco excepteur laborum velit quis consectetur velit. Irure laborum reprehenderit nisi veniam cillum ex amet est. Adipisicing ullamco consequat elit pariatur cillum irure veniam. Cillum veniam exercitation in nostrud ut consectetur consectetur commodo. Ipsum ullamco do ad non officia laboris ipsum laborum consequat ullamco id sunt deserunt cillum.\r\n",
    "strategy": "Laboris occaecat veniam incididunt sint mollit laborum incididunt id ea ad dolor. Occaecat dolor pariatur enim dolor nisi laborum incididunt qui aute amet ipsum voluptate ut do. Est nostrud quis adipisicing labore incididunt est Lorem eiusmod incididunt proident minim eiusmod duis. Aute ex duis minim culpa. Elit laboris voluptate do eu in dolore eiusmod cupidatat. Et id Lorem excepteur elit ullamco nostrud dolore eu sit sit laboris amet.\r\n",
    "keyLearnings": "Excepteur do officia irure non ad cupidatat ad irure irure nisi consectetur do. Reprehenderit Lorem reprehenderit laboris eiusmod commodo in id enim esse laborum magna magna aute nulla. Officia elit qui eiusmod incididunt irure consectetur ut aliqua cillum incididunt qui.\r\n",
    "nextSteps": "Ex sint nostrud aute exercitation quis do mollit anim quis nostrud. Fugiat sint pariatur eiusmod consectetur occaecat ipsum. Non aliquip ad ut duis amet Lorem. Fugiat ullamco eu officia velit quis dolore officia adipisicing eiusmod ullamco.\r\n",
    "investment": "Laborum esse officia id in ullamco reprehenderit voluptate fugiat mollit elit minim reprehenderit. Non Lorem incididunt aliqua deserunt aute elit dolore nostrud. Eiusmod pariatur voluptate pariatur officia pariatur. Magna sunt cillum elit non dolor cupidatat proident aliquip aliqua laborum do.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Ut consequat ad qui laborum duis ea anim veniam consectetur irure sunt cillum ut ut. Amet cupidatat aute labore occaecat amet est aute Lorem labore Lorem. Nostrud et tempor culpa tempor excepteur qui consequat.\r\n",
      "engagementDescr": "Labore ad officia aliquip reprehenderit ad non incididunt non tempor officia velit eiusmod. Anim velit consequat nulla voluptate Lorem velit cillum laboris ipsum et cupidatat Lorem ut aliquip. Ipsum exercitation exercitation deserunt reprehenderit amet in fugiat do aute fugiat. Dolore sunt elit excepteur excepteur non aliqua minim officia elit. Cillum pariatur proident laboris culpa. Pariatur tempor consequat dolor ad eiusmod exercitation consectetur et ipsum laborum Lorem ea irure.\r\n",
      "dataDescr": "Labore tempor labore esse consequat id ut in nisi ut eu nostrud occaecat fugiat. Non sit voluptate laboris ut ad occaecat ut esse in consequat tempor occaecat mollit. Mollit aliquip proident eiusmod culpa.\r\n",
      "routeDescr": "Nulla sit enim elit deserunt dolor magna consectetur laborum pariatur ex consequat eiusmod cillum. Nostrud pariatur nostrud ullamco anim minim tempor deserunt magna esse cupidatat qui duis. Amet enim aliquip eiusmod anim cupidatat exercitation commodo nostrud veniam aute occaecat mollit reprehenderit aliqua. Ea labore cillum sunt ullamco incididunt eu commodo sunt irure deserunt. Laborum non dolor est do sint anim reprehenderit aliqua dolor velit aute. Sunt enim dolore voluptate cillum.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Cobb Duran",
        "email": "cobbduran@geostele.com",
        "fte": 0.163
      },
      {
        "id": 1,
        "name": "White Herring",
        "email": "whiteherring@geostele.com",
        "fte": 0.6788
      },
      {
        "id": 2,
        "name": "Logan Lopez",
        "email": "loganlopez@geostele.com",
        "fte": 0.5646
      },
      {
        "id": 3,
        "name": "Nola Stephens",
        "email": "nolastephens@geostele.com",
        "fte": 0.03
      },
      {
        "id": 4,
        "name": "Thompson Sanford",
        "email": "thompsonsanford@geostele.com",
        "fte": 0.4485
      },
      {
        "id": 5,
        "name": "Walter Hart",
        "email": "walterhart@geostele.com",
        "fte": 0.9361
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Cook Santiago",
        "email": "cooksantiago@geostele.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 14,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 50,
        "y3": 70
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 12,
        "y3": 69
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12786760,
      "customerVal": 844982,
      "mvp": 14310824,
      "optimize": 4861124,
      "grow": 428994479,
      "scale": 1054753069
    },
    "nextStepsActionPlan": "Excepteur laboris ad cillum esse consequat tempor ipsum sint. Anim nulla irure velit quis labore magna reprehenderit ex laborum eu aute cupidatat fugiat enim. Lorem occaecat fugiat reprehenderit ullamco cillum. Elit eu voluptate duis anim dolore labore. Lorem quis veniam ad ut.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Excepteur laborum voluptate quis ipsum pariatur dolore ipsum occaecat duis. Consectetur dolore enim incididunt nostrud nisi enim non aliquip nostrud ipsum est laboris aliquip est. Dolore dolor Lorem esse anim irure.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-27",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d5a3dc9b7e2a4555",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zerbina",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "United States",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Reprehenderit sunt in aute laborum occaecat aute anim ipsum quis. Est enim ad et duis commodo est do. Amet irure dolore nostrud officia laborum. Sint minim ipsum deserunt veniam enim incididunt excepteur commodo anim.\r\n",
    "description": "Deserunt culpa ad deserunt ex fugiat id ea aliqua mollit officia excepteur cupidatat fugiat. Ex eu aliqua esse cupidatat id tempor nostrud commodo occaecat sunt do officia nulla. Voluptate sint proident anim laboris voluptate sit cillum. Esse sunt reprehenderit nisi incididunt enim aliquip in duis mollit non veniam. Enim pariatur nulla minim mollit qui commodo nostrud amet. Ipsum dolore exercitation qui irure ex nulla adipisicing.\r\n",
    "strategy": "Aliqua ex ad ad incididunt et veniam dolor pariatur commodo. In aliquip Lorem occaecat cillum ut ex laborum do labore nostrud. Non est ea anim mollit qui sunt consequat. Ullamco ut quis non laboris sit ullamco aliqua do mollit id proident fugiat ipsum. Duis adipisicing duis Lorem velit deserunt.\r\n",
    "keyLearnings": "Reprehenderit esse et cupidatat ea anim magna aliqua. Laborum quis occaecat eiusmod dolore qui. Proident sit commodo mollit irure magna incididunt. Sit officia veniam ea minim in elit. Do esse veniam proident eiusmod aute sunt officia consequat et proident aute. Aute sit ex esse consequat incididunt aliquip ut. Sint cillum reprehenderit enim sint ad qui quis excepteur qui commodo culpa cupidatat aute.\r\n",
    "nextSteps": "Enim veniam laboris non ea cupidatat est et fugiat ex nisi. Non fugiat adipisicing quis pariatur. Duis occaecat aute voluptate fugiat id fugiat labore nostrud aliquip dolor non reprehenderit aliquip. Sunt tempor labore ex est magna. Esse fugiat ea minim fugiat. Culpa labore nisi Lorem Lorem quis adipisicing amet quis aliqua sunt adipisicing laboris. Duis culpa occaecat occaecat consequat dolore aliquip id ut consectetur adipisicing aute et est.\r\n",
    "investment": "Elit nisi proident duis cupidatat. Ad id eiusmod nisi id laboris in cupidatat sunt reprehenderit non incididunt voluptate commodo. Ut culpa ipsum cupidatat amet.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Labore ullamco sunt aute sint culpa consequat fugiat consequat ad labore laboris magna. Do culpa dolor velit pariatur ad culpa ex reprehenderit cillum est dolor velit anim. Nisi pariatur ea laboris mollit cupidatat quis enim nostrud aliquip consectetur labore proident. Ullamco enim sit aute excepteur veniam voluptate elit officia esse. Ad et Lorem enim officia deserunt adipisicing excepteur elit aliquip dolore.\r\n",
      "engagementDescr": "Ullamco eu ullamco sit aliqua nulla excepteur ullamco. Amet ut proident esse reprehenderit laborum voluptate magna cillum. Veniam veniam exercitation pariatur eu ullamco in cupidatat et est duis ex cupidatat. In tempor pariatur mollit ipsum proident non exercitation ex deserunt ad magna. Consectetur velit dolore pariatur do ipsum. Excepteur culpa consectetur ex quis fugiat. Quis quis duis incididunt ex in eu pariatur.\r\n",
      "dataDescr": "Aute aute ipsum tempor ea dolore ex incididunt ea ex. Fugiat do pariatur irure adipisicing laborum amet reprehenderit amet laboris Lorem. Id quis Lorem non occaecat proident magna laboris aute proident esse adipisicing velit. Cillum mollit dolore dolor reprehenderit sit laboris tempor aliquip commodo nisi. Aliquip elit consequat officia incididunt do cupidatat laborum ut magna esse fugiat. Quis et enim ea occaecat sunt non aute magna aute ad esse ad occaecat in.\r\n",
      "routeDescr": "Irure id non ad cillum exercitation officia dolor labore. Laborum tempor cillum nisi reprehenderit ex ut fugiat. Esse labore eu eu eu irure aliquip eu commodo voluptate aliquip ea elit consequat. Ullamco aute sit culpa ea amet deserunt duis duis magna. Nisi laborum commodo ea incididunt enim velit irure. Occaecat irure consectetur ullamco excepteur id. Id aliqua dolor mollit proident dolore veniam reprehenderit laborum nostrud labore esse magna qui eu.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Hernandez Martinez",
        "email": "hernandezmartinez@zerbina.com",
        "fte": 0.6403
      },
      {
        "id": 1,
        "name": "Salas Bullock",
        "email": "salasbullock@zerbina.com",
        "fte": 0.2214
      },
      {
        "id": 2,
        "name": "Johnnie Day",
        "email": "johnnieday@zerbina.com",
        "fte": 0.1308
      },
      {
        "id": 3,
        "name": "Fitzgerald Moss",
        "email": "fitzgeraldmoss@zerbina.com",
        "fte": 0.6559
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Noel Hancock",
        "email": "noelhancock@zerbina.com"
      },
      {
        "id": 1,
        "name": "Jana Haynes",
        "email": "janahaynes@zerbina.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 17,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 49,
        "y3": 51
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 26,
        "y3": 94
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5098383,
      "customerVal": 676961,
      "mvp": 102483604,
      "optimize": 9632951,
      "grow": 1299375387,
      "scale": 520181023
    },
    "nextStepsActionPlan": "Nulla fugiat culpa tempor proident occaecat cillum laboris proident consectetur sit incididunt. Aute pariatur elit nostrud labore non deserunt laboris laboris adipisicing do incididunt. Quis enim officia pariatur et reprehenderit anim non laborum veniam est eiusmod velit. Cillum id aliquip deserunt nisi occaecat commodo nisi et. Minim deserunt aliqua laboris mollit aute eu voluptate laboris Lorem incididunt. Est occaecat sunt consequat commodo fugiat dolor.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Anim deserunt ad consequat ullamco quis proident. Eu cupidatat in commodo ad sint aute. Mollit in dolore occaecat irure amet nulla. Dolore ut fugiat minim et esse reprehenderit in mollit veniam enim aute. Laborum ad anim elit dolor anim officia duis aliqua minim. Commodo nisi officia ipsum sit est adipisicing commodo ad ex cillum ex ea.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0309d4da24cc4b332a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Zilodyne",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Online: Shop in shop",
    "division": "Division One",
    "painPoint": "Nostrud laboris irure elit sint. Irure aute ut exercitation id consequat ipsum reprehenderit ipsum Lorem anim. Deserunt excepteur elit eu esse est est ad laboris reprehenderit nulla nostrud minim ipsum. Quis non exercitation in sunt excepteur ad eiusmod esse amet aliqua labore mollit sit excepteur. Minim non cupidatat dolor incididunt velit irure non anim amet culpa do et ex officia. Excepteur enim Lorem non quis voluptate ea id consequat dolore.\r\n",
    "description": "Laborum deserunt elit aute do. Incididunt ea anim amet officia excepteur magna proident adipisicing. Consectetur dolore eiusmod pariatur proident tempor. Aute ipsum non incididunt excepteur dolore nulla velit. Eu ex Lorem laborum ut et est quis ad fugiat ad veniam.\r\n",
    "strategy": "Enim consectetur ullamco eu incididunt dolor quis incididunt in nisi consectetur consequat et do. Aliquip eu labore sint deserunt exercitation cupidatat quis. Magna qui esse adipisicing consectetur commodo in. Proident fugiat pariatur officia quis ipsum ipsum. Officia enim esse excepteur reprehenderit deserunt mollit voluptate quis cillum voluptate non tempor velit.\r\n",
    "keyLearnings": "Ut consectetur laborum irure ut laborum officia esse sunt consectetur commodo Lorem fugiat duis. Do dolore fugiat cillum ad Lorem enim magna nulla commodo laboris consectetur ex. Commodo quis occaecat proident irure labore tempor laboris fugiat ullamco non officia et cupidatat labore.\r\n",
    "nextSteps": "Dolor ex sint irure incididunt. Excepteur ex voluptate eu ad nostrud ad ipsum exercitation adipisicing fugiat reprehenderit elit. Do culpa enim fugiat dolor ut dolore do reprehenderit exercitation amet sint enim ullamco esse. Quis exercitation proident adipisicing esse id. Officia Lorem veniam adipisicing Lorem consequat.\r\n",
    "investment": "Qui sit non mollit aute veniam consectetur nisi duis est. Id ipsum aute sit ut amet sint occaecat Lorem ex deserunt commodo elit eu. Ipsum id cillum Lorem sunt duis cupidatat ut nulla. Ad incididunt incididunt sit duis ipsum. Nisi occaecat consectetur culpa nostrud pariatur irure. Minim elit sunt exercitation officia minim laboris ea in veniam non do eiusmod.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Anim incididunt esse eiusmod sint. Laborum mollit sit amet mollit. Amet culpa eiusmod tempor commodo anim exercitation commodo. Minim enim fugiat aute laborum cupidatat amet do deserunt cupidatat amet voluptate aliqua sint mollit. Proident quis nisi culpa aliquip ullamco officia ipsum officia aute nostrud sunt aliquip magna. Nisi amet deserunt cupidatat elit exercitation pariatur et labore dolor.\r\n",
      "engagementDescr": "Pariatur magna non enim proident ad eu ea eiusmod eu minim. Laboris excepteur enim minim fugiat nulla est laborum ex nulla mollit magna. Magna tempor non deserunt veniam laboris. Exercitation quis in irure consectetur mollit minim ex ad enim magna.\r\n",
      "dataDescr": "Sint nulla elit voluptate et quis commodo exercitation proident proident. Occaecat sunt minim amet enim fugiat commodo irure in eiusmod aute. Do consequat minim cillum deserunt amet deserunt incididunt consequat pariatur. Anim officia ex excepteur exercitation duis esse dolor minim ut sit nisi irure nisi. Pariatur ullamco consequat dolore Lorem tempor officia. Anim nostrud nostrud ex ullamco non commodo aute ullamco. Nulla culpa proident reprehenderit ea quis eu nostrud ullamco sint duis aliquip.\r\n",
      "routeDescr": "Id laborum anim ex culpa. Ipsum proident ullamco deserunt et. Duis do duis ipsum nostrud et aute est.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Delia Maxwell",
        "email": "deliamaxwell@zilodyne.com",
        "fte": 0.8631
      },
      {
        "id": 1,
        "name": "Chang Banks",
        "email": "changbanks@zilodyne.com",
        "fte": 0.787
      },
      {
        "id": 2,
        "name": "Verna Mcdowell",
        "email": "vernamcdowell@zilodyne.com",
        "fte": 0.3123
      },
      {
        "id": 3,
        "name": "Tanner Cantu",
        "email": "tannercantu@zilodyne.com",
        "fte": 0.0416
      },
      {
        "id": 4,
        "name": "Elliott Love",
        "email": "elliottlove@zilodyne.com",
        "fte": 0.6778
      },
      {
        "id": 5,
        "name": "Tabitha Casey",
        "email": "tabithacasey@zilodyne.com",
        "fte": 0.3314
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Charlotte Dunn",
        "email": "charlottedunn@zilodyne.com"
      },
      {
        "id": 1,
        "name": "Tanisha Graham",
        "email": "tanishagraham@zilodyne.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 13,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 22,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 18,
        "y3": 79
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7796174,
      "customerVal": 357940,
      "mvp": 118065914,
      "optimize": 11743028,
      "grow": 99892985,
      "scale": 414444498
    },
    "nextStepsActionPlan": "Laboris officia enim in commodo eu. Incididunt non aliquip deserunt cupidatat mollit veniam fugiat esse tempor reprehenderit deserunt magna incididunt. Irure sint adipisicing quis cupidatat. Elit esse veniam aliquip ut proident ad aliqua Lorem ea culpa sit sit eiusmod. Amet velit quis magna ut sit consectetur veniam quis. Ullamco consequat dolor aliqua Lorem et minim sint dolore id. Consectetur et non ipsum nostrud fugiat minim culpa eiusmod nulla.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolore consectetur exercitation voluptate ex elit id. Cillum dolor tempor cupidatat nulla magna ex eu sint dolore sint. Eiusmod velit aliqua culpa do ipsum laboris ipsum do. Qui culpa amet elit ut irure velit. Ad cupidatat eu incididunt culpa est.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03253bd8c5b03e3215",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zilidium",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Online: Social media",
    "division": "Division One",
    "painPoint": "Aliquip non aliqua eiusmod elit sint in et reprehenderit. Exercitation duis fugiat veniam fugiat sunt incididunt ad ut elit ex. Officia occaecat officia nisi aliquip deserunt dolore proident in exercitation nostrud labore est. Dolor labore ea laborum in cupidatat laborum reprehenderit incididunt aute velit eu non. Non labore do ea dolore fugiat do fugiat fugiat proident nisi excepteur Lorem.\r\n",
    "description": "Ad dolore sit voluptate ex excepteur voluptate sint ullamco occaecat. Est esse officia laboris aliqua ullamco cillum nulla do officia sunt velit ad. Voluptate dolor nostrud ex exercitation cupidatat anim. Excepteur nulla non esse sint esse est.\r\n",
    "strategy": "Deserunt Lorem cillum eiusmod nisi excepteur Lorem eiusmod sit. Enim Lorem ut do officia minim est deserunt mollit laborum duis irure duis. Magna dolore nulla minim amet proident esse qui veniam quis eiusmod adipisicing esse non. Ullamco tempor dolor ullamco occaecat duis labore deserunt aliquip labore mollit deserunt. Exercitation labore esse nulla amet.\r\n",
    "keyLearnings": "Sint eu eu sunt sint in consectetur eu. Sit nostrud nulla cupidatat ea exercitation velit sint velit laboris. Proident velit ullamco velit id incididunt esse sit veniam.\r\n",
    "nextSteps": "Esse est nostrud tempor dolore reprehenderit sit sunt nostrud et ea eu aliqua. Amet esse minim reprehenderit nostrud. Anim culpa qui aute veniam labore do et cupidatat. Irure enim dolor dolor aliquip Lorem adipisicing Lorem ipsum. Laboris nisi nulla proident consectetur anim officia cillum proident laborum exercitation fugiat consectetur dolor aliquip. Deserunt ipsum consequat reprehenderit ipsum mollit esse elit qui et anim ipsum.\r\n",
    "investment": "Aute occaecat in nostrud deserunt fugiat deserunt proident ex velit anim consequat voluptate. Quis occaecat deserunt proident eu adipisicing velit elit cupidatat qui. Ut ullamco minim eu culpa sint nulla culpa do. Esse quis ipsum labore ea excepteur esse anim ea deserunt cupidatat sunt veniam mollit. Exercitation in nostrud veniam officia ut aliquip consequat culpa commodo magna mollit.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Occaecat dolor consectetur aliquip ad esse labore dolore. Consectetur Lorem ad commodo aliquip proident Lorem culpa. Cupidatat excepteur eiusmod dolor sunt do velit enim nisi consequat culpa consectetur officia elit. Veniam esse eiusmod officia eiusmod ea laborum aute exercitation culpa ad ipsum minim quis sit. Veniam eiusmod deserunt ex irure minim sit ipsum magna commodo nulla non qui. Ipsum deserunt laborum labore ipsum eiusmod adipisicing esse ipsum minim id culpa quis ullamco sunt. Minim occaecat proident ut cupidatat est ea sunt nulla dolor minim.\r\n",
      "engagementDescr": "Consectetur Lorem id velit incididunt id aliquip enim. Est eu fugiat consequat duis deserunt incididunt qui est tempor incididunt. Minim velit exercitation consequat aute duis voluptate.\r\n",
      "dataDescr": "Lorem do do excepteur ullamco culpa aliqua elit do. Labore in irure fugiat velit fugiat ad ut amet culpa esse nisi cillum veniam. Elit sunt pariatur sit dolore tempor occaecat nisi non ea dolor adipisicing proident do. Incididunt fugiat proident cillum quis enim quis quis proident est. Ullamco non adipisicing tempor sint laborum. Voluptate velit sit commodo do. Officia deserunt eu tempor eiusmod.\r\n",
      "routeDescr": "Adipisicing aliqua officia mollit ea laborum culpa cupidatat consectetur eu eiusmod excepteur aute velit. Est sit eiusmod elit nulla excepteur deserunt elit excepteur eiusmod aliquip adipisicing consectetur. Pariatur commodo consequat tempor nisi tempor pariatur eu labore dolor et tempor velit elit deserunt. Nisi consequat eu eiusmod nulla pariatur Lorem deserunt proident fugiat. Culpa magna irure et duis ea. Occaecat nulla nulla do ad esse sint incididunt officia cillum ipsum exercitation dolore eiusmod magna.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Coleen Terry",
        "email": "coleenterry@zilidium.com",
        "fte": 0.9128
      },
      {
        "id": 1,
        "name": "Eula Santana",
        "email": "eulasantana@zilidium.com",
        "fte": 0.2965
      },
      {
        "id": 2,
        "name": "Clay Powers",
        "email": "claypowers@zilidium.com",
        "fte": 0.4461
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Davidson Bender",
        "email": "davidsonbender@zilidium.com"
      },
      {
        "id": 1,
        "name": "Selena Dickerson",
        "email": "selenadickerson@zilidium.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 19,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 5,
        "y2": 60,
        "y3": 62
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 27,
        "y3": 50
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2424235,
      "customerVal": 27843,
      "mvp": 36232192,
      "optimize": 8969408,
      "grow": 878508245,
      "scale": 668912082
    },
    "nextStepsActionPlan": "Est ex nostrud adipisicing eu exercitation laboris laborum nulla. Nisi elit minim nostrud incididunt dolor proident magna dolor. Pariatur magna laborum commodo non aute dolor id aliquip sint exercitation. Duis laborum ipsum velit cillum est fugiat commodo ea incididunt officia voluptate aute nostrud.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nulla consequat amet ullamco irure reprehenderit ipsum duis eu exercitation. Occaecat anim consequat et Lorem culpa et aliqua minim. Minim nisi tempor irure aute exercitation esse. Deserunt aliqua qui dolor id esse reprehenderit nulla deserunt veniam. Anim sit sit nulla duis mollit. Lorem voluptate enim dolor ipsum ad duis. Laborum dolor id nostrud excepteur irure.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-30",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d7b070f033ed1c99",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Dymi",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "China",
    "channel": "Online: Home delivery",
    "division": "Division Two",
    "painPoint": "In velit laboris quis consequat sit. In nisi eu et anim proident Lorem eiusmod. Proident aute excepteur veniam labore do do.\r\n",
    "description": "Ad et commodo esse dolore sint Lorem pariatur pariatur dolor ad consequat ut. Minim sunt sit adipisicing elit elit laborum exercitation id tempor consectetur aute dolore esse cupidatat. Officia proident eiusmod veniam ipsum tempor pariatur ullamco veniam amet. Do consectetur sit deserunt mollit. Exercitation culpa adipisicing consectetur officia irure occaecat officia ea Lorem sit nulla.\r\n",
    "strategy": "Ea sint eiusmod officia nostrud id ad consequat commodo ea aliquip in duis nisi est. Sunt labore labore ex cillum aliqua proident tempor ex aliquip voluptate enim id aliquip irure. Commodo reprehenderit mollit adipisicing velit pariatur ut dolor eu officia tempor adipisicing sunt sit non. Eu duis nulla dolore elit veniam elit esse Lorem amet ipsum mollit incididunt nostrud. Est Lorem esse ex anim ex cupidatat consectetur pariatur officia excepteur laborum est magna ut.\r\n",
    "keyLearnings": "Eiusmod aute labore et eu aliquip commodo eu incididunt sunt quis mollit aute. Dolore elit labore adipisicing irure esse dolor irure anim sit ex minim ea. Reprehenderit tempor do dolore aliquip aliquip nisi. Aute Lorem enim veniam anim. Dolore fugiat laboris nisi laboris dolor dolor fugiat sint et reprehenderit. Deserunt in tempor commodo laborum nostrud consectetur.\r\n",
    "nextSteps": "Fugiat officia culpa ea id duis sint nisi adipisicing minim ullamco commodo occaecat. Quis eiusmod occaecat minim dolor consequat qui do esse fugiat eiusmod reprehenderit. Id cupidatat sunt consequat excepteur commodo quis ullamco consequat ut. Irure deserunt dolore in commodo culpa nisi sint sunt cillum commodo enim est occaecat.\r\n",
    "investment": "Et consectetur officia in ex ullamco. Eu voluptate incididunt enim in elit cupidatat elit nostrud aliquip nulla et. Cillum nostrud eu proident excepteur proident.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Aute excepteur veniam voluptate et veniam voluptate aliqua aute. Amet culpa velit culpa laboris officia ex irure veniam incididunt esse quis ut tempor enim. Voluptate aliquip cillum nulla enim nisi. Incididunt pariatur ullamco culpa pariatur consequat minim occaecat consequat. Irure irure voluptate incididunt eiusmod eiusmod quis eiusmod cillum labore exercitation et consequat consectetur. Irure tempor elit anim ut.\r\n",
      "engagementDescr": "Nostrud ex nostrud duis veniam id ut minim. Aliquip pariatur eu minim sunt laborum proident nisi id est sunt. Est cillum consequat mollit officia sint ut sunt. Nulla consequat ex esse enim. Sit sint do et tempor qui incididunt cillum ipsum.\r\n",
      "dataDescr": "Eiusmod cillum amet est dolore reprehenderit anim. Consectetur laborum non Lorem tempor enim velit eiusmod do. Sint consequat anim pariatur nostrud ex aliqua aute deserunt.\r\n",
      "routeDescr": "Eiusmod Lorem Lorem esse nulla deserunt ut fugiat sit elit ullamco consequat. Ullamco dolor enim do magna ex culpa amet labore sint fugiat voluptate ea. Cupidatat mollit ex do nulla veniam nostrud duis ut est irure. Irure commodo tempor consectetur anim do. Nostrud nulla excepteur amet labore deserunt dolor officia labore. Ipsum qui consequat sunt sunt duis in aliquip irure laboris proident velit. Est consequat commodo ad ullamco laborum.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Valerie Wallace",
        "email": "valeriewallace@dymi.com",
        "fte": 0.6723
      },
      {
        "id": 1,
        "name": "Farmer Acosta",
        "email": "farmeracosta@dymi.com",
        "fte": 0.2303
      },
      {
        "id": 2,
        "name": "Baird Dorsey",
        "email": "bairddorsey@dymi.com",
        "fte": 0.1923
      },
      {
        "id": 3,
        "name": "Elise Decker",
        "email": "elisedecker@dymi.com",
        "fte": 0.3233
      },
      {
        "id": 4,
        "name": "Bertha Lloyd",
        "email": "berthalloyd@dymi.com",
        "fte": 0.6945
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Yates Mckinney",
        "email": "yatesmckinney@dymi.com"
      },
      {
        "id": 1,
        "name": "Irwin Britt",
        "email": "irwinbritt@dymi.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 16,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 43,
        "y3": 87
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 22,
        "y3": 62
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11065358,
      "customerVal": 573780,
      "mvp": 139919512,
      "optimize": 14419185,
      "grow": 178192024,
      "scale": 733733937
    },
    "nextStepsActionPlan": "Excepteur eiusmod Lorem qui dolor in ad sunt. Ea sunt voluptate commodo ipsum. Commodo nisi sit cupidatat quis nisi occaecat sit dolor adipisicing aliquip cupidatat ex mollit ullamco. Id adipisicing exercitation voluptate magna. Est excepteur irure pariatur esse do pariatur officia occaecat pariatur id et ea eu. Dolor sunt officia minim Lorem aute aliquip velit minim qui cillum et duis incididunt occaecat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sit magna officia deserunt incididunt proident sit labore minim do incididunt magna reprehenderit veniam elit. Qui deserunt tempor id amet exercitation commodo do eiusmod. Aliquip dolore culpa incididunt veniam officia tempor eu fugiat ea in labore et sint est. Culpa non aliquip laborum eu irure aute culpa adipisicing fugiat esse mollit. Magna irure reprehenderit aliqua do anim do qui ullamco quis anim exercitation ex mollit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-31",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e033a635878e4ac2eab",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Vertide",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "United States",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Sunt quis et cupidatat qui veniam esse aliquip eu cupidatat esse nisi consectetur. Ad labore incididunt commodo excepteur ea mollit elit fugiat cupidatat sunt. Non culpa deserunt qui aliqua adipisicing sint voluptate aliquip irure excepteur excepteur ipsum. Laboris cillum consectetur dolor deserunt duis anim nisi ullamco quis magna consectetur qui amet ipsum. Ea reprehenderit ea do proident non velit ex aliquip voluptate pariatur irure sint enim.\r\n",
    "description": "Sunt non anim et non culpa labore ad qui adipisicing veniam deserunt culpa. Incididunt velit nulla non nostrud. Sit id ut sit quis aliquip. Aliqua nisi occaecat enim reprehenderit elit. Anim nisi do deserunt quis sunt duis ea reprehenderit do magna anim adipisicing officia in. Enim laboris aliqua sunt in consectetur proident tempor pariatur cupidatat reprehenderit consequat elit. Irure id reprehenderit laborum sunt minim aliquip ad duis id consequat irure sint.\r\n",
    "strategy": "Quis cillum in incididunt eu Lorem exercitation. Irure elit do sint irure. Nisi labore in est eiusmod. Deserunt officia excepteur do ut ad deserunt.\r\n",
    "keyLearnings": "Esse cillum irure cillum magna incididunt culpa. Commodo in tempor pariatur nostrud aliqua excepteur consequat Lorem dolor ullamco irure minim eu tempor. Ut cillum veniam duis sit voluptate Lorem id consequat.\r\n",
    "nextSteps": "Ea cupidatat culpa officia anim sint cillum magna incididunt excepteur ad exercitation. Laboris eiusmod dolore occaecat cillum non ex ad excepteur nisi. Sunt magna deserunt proident adipisicing labore eu id fugiat fugiat. Magna tempor minim mollit magna sint consectetur veniam ipsum anim. Sint esse est labore esse irure.\r\n",
    "investment": "Esse adipisicing eiusmod eu proident nostrud velit ea dolore. Consequat dolor esse et non non labore. Non ullamco eu duis elit consequat Lorem sint deserunt do nostrud aliquip nostrud sint pariatur. Nisi esse proident sunt non sint ad anim aute minim est.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Nisi officia laborum occaecat consequat sint magna cillum et sunt quis eu. Laborum consequat aute ullamco consectetur. Dolor voluptate Lorem eu consequat velit sit velit ipsum dolore sit incididunt. Adipisicing dolore nulla sunt reprehenderit. Id deserunt duis sit ullamco irure anim adipisicing aliquip sint qui do aute fugiat anim. Laborum anim amet commodo dolor et. Ea nostrud amet ea elit adipisicing ex labore est non eiusmod est consectetur.\r\n",
      "engagementDescr": "Ut amet fugiat magna exercitation do nulla aliqua mollit velit voluptate fugiat Lorem incididunt. Id irure qui adipisicing exercitation sunt amet labore do qui sint. Deserunt duis excepteur laborum consectetur id cillum sit do ex dolore commodo elit.\r\n",
      "dataDescr": "Fugiat reprehenderit dolor dolor consequat esse cupidatat consectetur ea id mollit tempor. Aliquip ea magna sunt proident proident sint. Veniam anim ut est quis nisi ad magna cillum sunt sit. Et consequat ipsum ad pariatur et veniam enim nulla magna consequat.\r\n",
      "routeDescr": "Duis aliquip duis do deserunt ea adipisicing eiusmod ullamco exercitation reprehenderit tempor pariatur. Anim aliqua aliqua officia dolore tempor in labore. Consequat proident amet voluptate incididunt aliquip laborum ut irure reprehenderit amet culpa laborum reprehenderit excepteur. Consectetur eu eu cillum in nulla aliqua nulla. Amet laboris reprehenderit deserunt exercitation proident nulla dolore ipsum.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Madge Webb",
        "email": "madgewebb@vertide.com",
        "fte": 0.4346
      },
      {
        "id": 1,
        "name": "Cathryn Giles",
        "email": "cathryngiles@vertide.com",
        "fte": 0.8036
      },
      {
        "id": 2,
        "name": "Hays Mason",
        "email": "haysmason@vertide.com",
        "fte": 0.1907
      },
      {
        "id": 3,
        "name": "Bethany Briggs",
        "email": "bethanybriggs@vertide.com",
        "fte": 0.3542
      },
      {
        "id": 4,
        "name": "Marshall Reed",
        "email": "marshallreed@vertide.com",
        "fte": 0.3204
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Mcintosh Hoover",
        "email": "mcintoshhoover@vertide.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 19,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 22,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 20,
        "y3": 36
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10306680,
      "customerVal": 1376019,
      "mvp": 39074672,
      "optimize": 6873341,
      "grow": 1480833256,
      "scale": 1206810641
    },
    "nextStepsActionPlan": "Voluptate nulla ut quis magna officia pariatur duis. Ex mollit duis incididunt irure reprehenderit mollit enim aliquip id id deserunt Lorem ex. Nulla incididunt velit culpa occaecat. Ea aute laborum eiusmod nulla amet proident excepteur ex laboris pariatur incididunt officia ex dolor. Deserunt cupidatat laborum voluptate ullamco enim nisi sunt aliqua magna proident esse eu. Voluptate culpa nostrud proident sint esse esse duis officia minim excepteur.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Adipisicing officia pariatur laboris mollit. Incididunt esse voluptate occaecat eiusmod fugiat sit sit sit duis id consectetur aliqua exercitation culpa. Deserunt mollit mollit proident consectetur nostrud officia dolore officia ipsum Lorem ea. Et duis exercitation voluptate non irure ad Lorem ea eiusmod voluptate amet pariatur. Id eiusmod aute quis id occaecat. Deserunt sit cupidatat quis magna. Sunt est occaecat et nisi voluptate eu cillum commodo incididunt minim consequat officia.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-11",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c2cb8d1271e1075b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Zenolux",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "Norway",
    "channel": "Online: Flagship store",
    "division": "Division Two",
    "painPoint": "Occaecat ut adipisicing sunt nulla sunt. In ipsum dolore nisi officia nisi commodo irure mollit sunt cupidatat aliqua. Laborum tempor non excepteur aliquip duis. Labore mollit duis reprehenderit qui irure sunt amet esse sint exercitation mollit nulla ipsum ad.\r\n",
    "description": "Consectetur esse minim quis nulla pariatur amet nostrud exercitation ea anim eiusmod. Nisi dolore non veniam dolore irure ipsum quis id aliqua dolore aute proident anim non. Voluptate ea sint deserunt amet laborum amet. Fugiat eiusmod incididunt aliqua dolor eu aliqua quis Lorem adipisicing excepteur nisi enim labore velit.\r\n",
    "strategy": "Consequat culpa eu officia nulla in enim aliquip pariatur dolore. In laborum quis nulla mollit consequat consectetur. Amet do labore aliquip veniam ullamco laboris eu sit magna. Consequat officia Lorem ut mollit labore tempor labore excepteur veniam. Ullamco occaecat consequat quis ut non proident in laboris ut cillum incididunt id ipsum esse.\r\n",
    "keyLearnings": "Enim enim culpa consectetur cillum. Et eu irure proident aliquip ex amet. Excepteur aute voluptate esse ex reprehenderit sint esse. Duis aliqua mollit minim laborum incididunt mollit et. Sit eiusmod labore irure fugiat aliquip ipsum reprehenderit culpa culpa ullamco elit.\r\n",
    "nextSteps": "Pariatur laboris aliquip officia reprehenderit tempor reprehenderit reprehenderit ea. Ex dolor excepteur deserunt officia culpa culpa. Sint culpa nisi occaecat id sit elit sunt tempor officia sint. Commodo duis laborum nulla officia eiusmod nulla. Est anim do sunt adipisicing cupidatat magna laboris ea ea qui sint.\r\n",
    "investment": "Anim enim dolor dolore pariatur reprehenderit laboris Lorem aute fugiat proident veniam. Adipisicing duis in velit anim dolor dolore irure laborum cillum officia incididunt mollit culpa dolore. Non sit magna duis sit adipisicing minim minim id ut.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Ad sit occaecat sint excepteur. Enim amet Lorem reprehenderit qui est. Sint magna magna nisi eiusmod reprehenderit nulla eu labore dolore anim proident velit dolore nulla. Consectetur ad consequat enim consequat minim consequat excepteur ea anim consequat. Eiusmod pariatur deserunt veniam aliqua enim Lorem tempor cupidatat ullamco est ullamco aute eiusmod et. Mollit ullamco esse esse eu. Sint nulla ea veniam ullamco non.\r\n",
      "engagementDescr": "Aliqua nostrud dolore irure labore deserunt non consequat occaecat aliqua quis culpa laboris sit. Ullamco in ad elit mollit et aute deserunt dolore adipisicing. Sint mollit occaecat occaecat magna adipisicing in est non dolor Lorem tempor. Consectetur quis incididunt pariatur culpa culpa est.\r\n",
      "dataDescr": "Sit et culpa occaecat anim consequat do occaecat reprehenderit in laborum ea. Incididunt minim occaecat irure mollit. Ad laborum eiusmod dolore aliquip mollit labore ullamco fugiat duis laboris culpa incididunt commodo. Voluptate dolor eiusmod qui esse voluptate fugiat mollit in adipisicing nisi nulla. Non esse est id est tempor in. Lorem veniam esse proident sunt enim ea ex anim id occaecat irure enim duis eu. Cupidatat veniam aute voluptate pariatur cupidatat.\r\n",
      "routeDescr": "Commodo minim voluptate esse amet exercitation aute culpa cupidatat cupidatat veniam laborum officia cupidatat officia. Commodo cillum tempor aliqua anim amet laboris quis. Duis duis commodo nostrud id labore tempor dolore id eiusmod elit ex sint commodo pariatur.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Lucia Gilliam",
        "email": "luciagilliam@zenolux.com",
        "fte": 0.7008
      },
      {
        "id": 1,
        "name": "Hazel Stanton",
        "email": "hazelstanton@zenolux.com",
        "fte": 0.2193
      },
      {
        "id": 2,
        "name": "Tabatha Chaney",
        "email": "tabathachaney@zenolux.com",
        "fte": 0.4218
      },
      {
        "id": 3,
        "name": "Kathy Medina",
        "email": "kathymedina@zenolux.com",
        "fte": 0.7193
      },
      {
        "id": 4,
        "name": "Hodge Mckee",
        "email": "hodgemckee@zenolux.com",
        "fte": 0.4121
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Susana Noble",
        "email": "susananoble@zenolux.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 8,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 34,
        "y3": 83
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 23,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4809385,
      "customerVal": 1434060,
      "mvp": 134697913,
      "optimize": 1554043,
      "grow": 1098025429,
      "scale": 641164148
    },
    "nextStepsActionPlan": "Quis irure aliqua eiusmod voluptate occaecat do ea deserunt anim veniam commodo elit et. Deserunt officia ut id et excepteur irure. Cillum do sit ut deserunt eu proident excepteur eiusmod consequat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Consequat eu ex Lorem nulla veniam aliquip est veniam aliqua veniam. Velit nostrud sint laboris deserunt consequat qui exercitation est et est do quis. Sint mollit est irure excepteur laborum eu nisi non eu adipisicing mollit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-06",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e034cb856f5ad8cf411",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Exerta",
    "highlightKPI": [
      "cac",
      "ltv",
      "rr"
    ],
    "geography": "Spain",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Deserunt magna ipsum officia culpa id ullamco. Nulla sint dolor sit dolor laborum eiusmod duis tempor nostrud Lorem pariatur sunt irure. Officia sint cillum aliquip eiusmod aliquip duis nisi proident labore ex sunt est esse. Cillum sit sint mollit laborum minim minim minim enim esse. Cupidatat consequat tempor anim tempor laboris magna fugiat occaecat Lorem culpa consequat commodo. Minim quis cillum ullamco ipsum ut duis eu est fugiat eu. Laborum ad officia duis laboris aute sunt.\r\n",
    "description": "Laboris velit nostrud amet qui ex ex Lorem. Mollit aliqua aliquip sit mollit nisi non ad velit et adipisicing. Minim nulla duis eiusmod culpa quis sunt minim dolor ad sit ullamco nisi. Amet officia aliqua id incididunt est consectetur nisi eiusmod est. Labore minim magna laboris ea nulla esse dolore culpa id aute cillum officia.\r\n",
    "strategy": "Sunt labore cupidatat culpa occaecat. Cupidatat non Lorem aliqua enim ullamco. Ad non commodo eu ea nostrud nisi deserunt esse dolor magna consequat et proident.\r\n",
    "keyLearnings": "Occaecat dolor nostrud occaecat sunt occaecat dolore adipisicing nulla proident sunt in. Labore amet reprehenderit irure proident do veniam sit sunt ut cupidatat dolore eu consequat aliquip. Quis nisi laboris magna aliqua quis sit quis non in. Pariatur sint aute occaecat aliquip adipisicing adipisicing. Ad amet sunt anim consequat incididunt pariatur excepteur officia tempor aute. Ad culpa est non aliquip mollit enim et Lorem enim aliquip aute veniam ipsum et.\r\n",
    "nextSteps": "Sunt do tempor commodo elit irure exercitation anim veniam exercitation. Magna fugiat laboris eu ex id. Cillum reprehenderit ea amet cupidatat occaecat aute esse mollit nulla ea nulla officia. Nostrud ut ullamco laboris sit excepteur. Est eiusmod ut minim amet dolor commodo amet reprehenderit ad irure do commodo velit. Laborum sunt pariatur ex velit nostrud nisi irure ut anim id tempor ad.\r\n",
    "investment": "Dolore mollit dolor ea mollit proident. Dolor dolore nulla minim do veniam officia irure labore Lorem nisi ex veniam id. Culpa duis minim in consectetur eiusmod cillum reprehenderit reprehenderit duis officia exercitation veniam sunt. Aliquip dolor qui laborum Lorem excepteur in eu. Tempor officia Lorem sunt commodo proident incididunt eu deserunt amet non sit.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Cillum dolor aliquip eu labore proident commodo aliquip aliquip mollit nulla eiusmod. Consectetur excepteur laborum magna culpa labore excepteur. Incididunt amet aliqua pariatur duis dolore. Labore sunt magna irure ullamco incididunt aute. Mollit occaecat ex ullamco anim excepteur ullamco elit. Ea anim enim non veniam. Ipsum magna ad adipisicing est reprehenderit ipsum fugiat nulla ut ad magna.\r\n",
      "engagementDescr": "Consequat reprehenderit consequat ea minim labore. Excepteur enim adipisicing id nisi nulla officia ad aute esse labore incididunt ad. Sunt aute nulla culpa qui nulla ad ex non.\r\n",
      "dataDescr": "Magna excepteur proident ipsum amet ullamco aliqua ex occaecat irure mollit officia commodo nostrud. Id officia ipsum cillum esse duis Lorem. Mollit reprehenderit excepteur amet anim nostrud officia nulla magna ipsum ipsum. Et occaecat eiusmod eu sunt magna dolore laboris officia esse excepteur excepteur. Dolor eiusmod esse nulla qui esse Lorem et. Cupidatat anim cillum aliquip culpa fugiat irure veniam. Ut anim officia ea id commodo tempor anim aute cillum officia.\r\n",
      "routeDescr": "Cupidatat nisi consequat proident eiusmod irure ea ad ex id do officia ullamco. Consectetur ipsum deserunt id id sunt labore nulla ad tempor anim reprehenderit. Ad laborum ex pariatur laboris est pariatur laborum non nostrud anim enim duis nisi est.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Soto Collins",
        "email": "sotocollins@exerta.com",
        "fte": 0.6489
      },
      {
        "id": 1,
        "name": "Karina Griffin",
        "email": "karinagriffin@exerta.com",
        "fte": 0.5887
      },
      {
        "id": 2,
        "name": "Delores Pickett",
        "email": "delorespickett@exerta.com",
        "fte": 0.1313
      },
      {
        "id": 3,
        "name": "James Morris",
        "email": "jamesmorris@exerta.com",
        "fte": 0.7429
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Hooper Adams",
        "email": "hooperadams@exerta.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 16,
        "y3": 28
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 39,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 17,
        "y3": 78
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10168557,
      "customerVal": 328043,
      "mvp": 42749813,
      "optimize": 3905295,
      "grow": 465350259,
      "scale": 1197060363
    },
    "nextStepsActionPlan": "Laborum reprehenderit elit ullamco nisi cupidatat culpa proident esse nulla eu incididunt pariatur non. Duis Lorem adipisicing incididunt cupidatat laborum exercitation adipisicing est velit adipisicing qui laboris eiusmod ut. Quis ex incididunt proident ex dolor ea qui aliquip. Consectetur culpa et consequat adipisicing incididunt consectetur reprehenderit adipisicing est. Sunt nisi nostrud consequat mollit dolor fugiat. Do anim nulla tempor anim ea magna adipisicing eu nostrud nulla.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Eiusmod Lorem ea Lorem dolor sit occaecat nulla tempor nostrud velit et duis esse. Ad sint in irure laborum minim id. Duis non commodo exercitation cupidatat elit tempor id duis Lorem consequat excepteur veniam labore et. Esse sunt commodo est ut ullamco non consequat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-22",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0377507d1504f44ef2",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Shepard",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rr"
    ],
    "geography": "India",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Ut elit elit cillum dolor exercitation ullamco Lorem aute consequat laboris. Est ex ullamco tempor pariatur ut est consequat consectetur ex sit non cillum. Do eiusmod aute id elit elit qui veniam aliqua. Pariatur excepteur pariatur velit duis est exercitation excepteur do ullamco laboris deserunt. Consectetur deserunt sunt est non labore et adipisicing.\r\n",
    "description": "Aliqua irure eu eu enim ipsum cupidatat nisi reprehenderit. Ipsum enim non deserunt irure deserunt dolore veniam tempor ullamco laborum in. Eiusmod nostrud do aliquip non incididunt ex. Labore officia Lorem proident dolor culpa ipsum aliquip. Laborum cillum cillum nulla ut ullamco.\r\n",
    "strategy": "Mollit reprehenderit enim dolor mollit. Ex exercitation eiusmod excepteur consectetur. Nisi in et adipisicing reprehenderit irure aliqua esse voluptate ea non magna ullamco. Enim dolor labore enim ut officia sint deserunt tempor officia qui eiusmod laborum labore. Mollit anim ex officia sint anim dolor est pariatur laboris irure mollit consequat. Dolor non tempor aliquip esse id in dolore velit duis nostrud.\r\n",
    "keyLearnings": "Consectetur qui eiusmod eiusmod nisi et ea incididunt nisi occaecat aliquip elit nulla. Pariatur eu in minim qui duis esse mollit mollit voluptate cillum ut. Nostrud occaecat mollit eiusmod minim ex.\r\n",
    "nextSteps": "Aliquip elit officia mollit aliquip est mollit excepteur cillum. Proident voluptate esse fugiat ad qui. Laboris id irure velit magna nostrud nulla anim eiusmod incididunt. Occaecat dolore occaecat eiusmod enim amet laboris reprehenderit. Deserunt sint ullamco est aliquip occaecat deserunt consectetur elit incididunt irure voluptate adipisicing cupidatat enim.\r\n",
    "investment": "Anim anim id laboris minim est voluptate qui. Ullamco fugiat nisi do dolor mollit voluptate. Duis deserunt id reprehenderit ea irure. Nulla quis enim sit id amet qui officia eu nisi excepteur sint quis. Sunt do elit magna aliquip Lorem sint. Sint exercitation sit et eu ipsum eu proident eu amet excepteur veniam est eiusmod exercitation. Irure magna consectetur culpa non voluptate aliqua exercitation nostrud est veniam consectetur esse consequat eiusmod.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Duis duis mollit tempor exercitation aute aliqua elit tempor ea veniam irure. Veniam in laboris nulla velit laboris laboris reprehenderit labore est do ea ullamco cillum. Dolor nostrud cillum eiusmod esse duis ad exercitation deserunt labore adipisicing non. Est et ipsum aute do amet reprehenderit. Consectetur aliquip ex duis laboris labore exercitation id ut anim. Id esse do dolore commodo reprehenderit dolore minim aliqua occaecat fugiat.\r\n",
      "engagementDescr": "Aliqua reprehenderit nisi tempor elit proident dolor aliquip cupidatat. Reprehenderit adipisicing consectetur deserunt nulla laboris in occaecat velit mollit eiusmod. Id eiusmod consectetur est esse ea nisi eu ex velit id.\r\n",
      "dataDescr": "Ut adipisicing proident elit ipsum aute ex quis cupidatat magna proident reprehenderit. Culpa sint in pariatur nostrud irure exercitation velit. Magna occaecat est do culpa est do quis ex dolor excepteur enim fugiat excepteur qui. Consectetur consectetur nisi incididunt sunt ad sit mollit. Veniam amet dolor quis ea. Cupidatat adipisicing proident voluptate minim consectetur adipisicing veniam aliqua veniam nisi exercitation tempor officia ad.\r\n",
      "routeDescr": "Elit exercitation sit tempor sint duis ipsum reprehenderit et aliqua et velit. Dolor esse dolore adipisicing adipisicing dolore culpa magna in cillum. Ex minim Lorem sint consectetur elit culpa reprehenderit pariatur elit consectetur do exercitation eu. Ea Lorem ex dolor exercitation id est dolore sit nisi voluptate.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Jacquelyn Lowery",
        "email": "jacquelynlowery@shepard.com",
        "fte": 0.7377
      },
      {
        "id": 1,
        "name": "Leslie Simmons",
        "email": "lesliesimmons@shepard.com",
        "fte": 0.6949
      },
      {
        "id": 2,
        "name": "Christa Bonner",
        "email": "christabonner@shepard.com",
        "fte": 0.0838
      },
      {
        "id": 3,
        "name": "Wright Pruitt",
        "email": "wrightpruitt@shepard.com",
        "fte": 0.297
      },
      {
        "id": 4,
        "name": "Hale Kane",
        "email": "halekane@shepard.com",
        "fte": 0.4577
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Maryann Stewart",
        "email": "maryannstewart@shepard.com"
      },
      {
        "id": 1,
        "name": "Dorthy Wooten",
        "email": "dorthywooten@shepard.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 18,
        "y3": 34
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 8,
        "y2": 29,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 14,
        "y3": 67
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7757707,
      "customerVal": 613495,
      "mvp": 115967420,
      "optimize": 3263434,
      "grow": 513503310,
      "scale": 1456295657
    },
    "nextStepsActionPlan": "Velit elit amet culpa consectetur officia do in est consectetur. Aute et anim aute et sit irure excepteur nostrud. Sint voluptate dolore velit deserunt exercitation excepteur adipisicing.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Pariatur elit quis exercitation qui id ex esse. Excepteur proident sunt pariatur elit sint id sint cillum adipisicing. Est excepteur voluptate aute sint do excepteur mollit excepteur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03fe96e8f38c6330a5",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zialactic",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "United States",
    "channel": "Online: Digital distributor",
    "division": "Division One",
    "painPoint": "Et ea occaecat quis minim adipisicing consectetur eiusmod amet elit. Quis eiusmod occaecat nostrud irure voluptate ad aute duis. Tempor quis qui pariatur proident. Sint incididunt id ea eiusmod non nostrud duis incididunt. Cillum ut veniam exercitation nisi tempor est veniam eiusmod exercitation sint nulla anim elit.\r\n",
    "description": "Laborum pariatur aliqua elit consequat minim ut. Laborum esse adipisicing aute proident proident. Ut cillum id commodo magna et minim quis cupidatat cupidatat. Duis magna deserunt culpa tempor amet duis dolore cillum minim cillum eu officia in pariatur. Quis ullamco tempor proident exercitation non cillum. Exercitation aliquip ut proident ipsum. Ullamco non non excepteur sint labore aliqua sit fugiat.\r\n",
    "strategy": "Et Lorem do sit nostrud consectetur enim fugiat. Culpa magna adipisicing sint in fugiat proident sunt. Laboris adipisicing excepteur nulla commodo. Elit commodo ipsum laboris cillum ullamco ea reprehenderit nostrud. Cillum proident sunt sint sit in veniam minim ea laboris ea id adipisicing. Culpa deserunt mollit laboris qui adipisicing dolore nisi dolor qui dolor. Non sit ea ut ad tempor eu cupidatat duis pariatur commodo aute voluptate commodo.\r\n",
    "keyLearnings": "Ullamco eiusmod laboris laboris consequat irure quis sunt voluptate elit voluptate cillum nostrud anim. Et amet Lorem nostrud consectetur do eiusmod veniam ex officia mollit aliquip. Aliqua aliquip sit quis eiusmod veniam aliquip culpa.\r\n",
    "nextSteps": "Eiusmod aliquip proident velit exercitation ut mollit aliquip culpa anim culpa ullamco eu commodo. Lorem aliquip cillum cupidatat minim non nulla esse sit nostrud deserunt laborum in. Lorem mollit duis incididunt ipsum laborum enim deserunt. Cupidatat ipsum fugiat officia labore amet ad. Aliqua veniam duis elit ullamco ex. Cillum do qui elit fugiat dolor id mollit ad ad elit fugiat nisi. Anim voluptate et reprehenderit enim.\r\n",
    "investment": "Ut reprehenderit cupidatat elit sunt consectetur reprehenderit pariatur anim. Amet fugiat dolor consectetur ut consectetur. Deserunt exercitation officia est consectetur qui. Minim do ad cillum fugiat. Do aute sint sunt magna sit cillum amet aliqua.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Fugiat consectetur aute laborum esse in quis. Esse ut enim adipisicing amet. Excepteur culpa occaecat ipsum sit. Aute cillum nulla id non minim culpa. Aliquip aliquip esse ipsum officia velit.\r\n",
      "engagementDescr": "In enim ipsum fugiat occaecat amet nisi in proident cillum amet cupidatat dolore irure consectetur. Est ipsum ullamco est eiusmod commodo est ea laboris duis reprehenderit non velit ad et. Reprehenderit incididunt in tempor aute deserunt ea. Laboris officia pariatur adipisicing esse labore consectetur. Proident Lorem excepteur cillum aliqua non proident nisi consectetur. Officia excepteur amet laboris dolor aliquip voluptate incididunt enim.\r\n",
      "dataDescr": "Culpa deserunt duis commodo deserunt. Aliquip elit culpa irure fugiat sint qui culpa excepteur. Excepteur labore duis duis ipsum veniam consequat anim nisi. Eu mollit ad laboris exercitation nulla ad in adipisicing exercitation ad. Culpa eu ipsum aliqua culpa ipsum deserunt adipisicing minim ipsum aliqua aliqua. Cupidatat aliquip enim minim adipisicing.\r\n",
      "routeDescr": "Elit consequat exercitation veniam voluptate aliqua cupidatat aliqua incididunt nostrud tempor aliquip pariatur mollit dolor. Laboris sit consectetur cillum non velit veniam ex laborum nulla laboris esse ut. Est eiusmod aliquip est cillum anim Lorem dolore ex ut irure incididunt. Ea incididunt nostrud irure do ea voluptate in incididunt velit. Sunt ullamco aliqua velit voluptate magna aliqua dolore sint laborum sint pariatur ex.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Camacho Schneider",
        "email": "camachoschneider@zialactic.com",
        "fte": 0.678
      },
      {
        "id": 1,
        "name": "Bass Graves",
        "email": "bassgraves@zialactic.com",
        "fte": 0.0518
      },
      {
        "id": 2,
        "name": "Dominique Pollard",
        "email": "dominiquepollard@zialactic.com",
        "fte": 0.6425
      },
      {
        "id": 3,
        "name": "Gregory Walton",
        "email": "gregorywalton@zialactic.com",
        "fte": 0.3021
      },
      {
        "id": 4,
        "name": "Esperanza Hogan",
        "email": "esperanzahogan@zialactic.com",
        "fte": 0.7856
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Camille Randolph",
        "email": "camillerandolph@zialactic.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 20,
        "y3": 37
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 23,
        "y3": 95
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 20,
        "y3": 89
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6887153,
      "customerVal": 6334,
      "mvp": 92707674,
      "optimize": 6766861,
      "grow": 366943686,
      "scale": 146761417
    },
    "nextStepsActionPlan": "Eiusmod labore ad amet exercitation deserunt est ex nulla Lorem nisi enim labore veniam. Do consequat voluptate voluptate eu ad tempor. Ex sunt exercitation anim sint dolor ullamco aute. Anim cillum labore velit cupidatat cupidatat aute aliqua dolore nulla excepteur. Id eu fugiat commodo veniam anim cupidatat non ex sit esse aute exercitation aliqua pariatur. Do nostrud voluptate ipsum elit proident culpa sunt pariatur laboris et do fugiat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt proident ut laborum dolor voluptate magna esse. Eiusmod quis laboris do officia esse. Aute excepteur aliqua exercitation fugiat non dolor labore eu dolore ad cillum elit. Irure elit proident irure non aliqua nostrud irure laboris reprehenderit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-27",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e032ab60fd3c28d67f3",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Geekola",
    "highlightKPI": [
      "cac",
      "gmac",
      "tc"
    ],
    "geography": "China",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Non ex adipisicing excepteur aliquip veniam cillum reprehenderit. Est officia esse mollit proident excepteur voluptate reprehenderit. Magna excepteur adipisicing ipsum non do adipisicing incididunt cupidatat. Sint aliquip sint pariatur consectetur amet. Ea laboris exercitation et cupidatat Lorem.\r\n",
    "description": "Veniam proident ex in sit Lorem labore laboris veniam ut anim occaecat. Ad pariatur sit id cupidatat exercitation labore et veniam elit commodo amet enim dolore adipisicing. Laboris sunt non incididunt magna.\r\n",
    "strategy": "Exercitation do laboris ad commodo cillum voluptate aliqua proident dolore irure nisi in ex qui. Excepteur ex eu magna mollit exercitation aliqua proident. Adipisicing ea labore incididunt ipsum. Sit dolore adipisicing ea occaecat.\r\n",
    "keyLearnings": "Ad voluptate nostrud deserunt magna aliquip labore. Quis eu incididunt cupidatat ad aliquip qui nulla nisi irure duis sunt sint. Ut aute cillum tempor nisi nisi.\r\n",
    "nextSteps": "Duis id ipsum consequat ipsum ex laborum incididunt voluptate officia ut aliqua veniam commodo mollit. Ex reprehenderit consectetur labore id amet enim ea aute laboris. Commodo fugiat nulla sint tempor elit dolore. Fugiat sit amet culpa sunt.\r\n",
    "investment": "Tempor pariatur veniam officia anim ex laboris irure anim esse laborum do. Sint amet aute et quis magna anim. Qui proident excepteur tempor pariatur cillum excepteur officia enim in tempor. Officia eiusmod enim et cillum veniam ullamco nostrud nisi adipisicing excepteur.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Excepteur culpa ipsum aliqua laboris ut esse cillum proident elit. Id exercitation id fugiat excepteur occaecat labore consequat ut id ipsum. Amet adipisicing aute labore sint pariatur adipisicing adipisicing in laborum culpa dolor adipisicing.\r\n",
      "engagementDescr": "Aliquip sit tempor velit id fugiat et id incididunt laborum dolor sint irure. Culpa irure voluptate elit eiusmod do do ut dolore. Dolor non aliquip laboris eiusmod minim consectetur cupidatat et anim. Cupidatat enim adipisicing culpa ea sit deserunt anim incididunt aute velit culpa.\r\n",
      "dataDescr": "Cillum id quis ea commodo laborum commodo elit veniam Lorem culpa in. Occaecat ex adipisicing tempor veniam mollit do. Veniam aute laboris consectetur laborum qui deserunt aliqua anim cupidatat exercitation. Dolor exercitation non minim magna nulla excepteur ex dolor velit fugiat. Cupidatat eu magna eiusmod nostrud laborum ea aliquip. Est cillum id adipisicing ipsum tempor cillum enim dolore et commodo sint ex duis excepteur.\r\n",
      "routeDescr": "Ipsum eu culpa cillum duis incididunt est ut fugiat nulla voluptate laborum deserunt et et. Esse eu cillum officia commodo officia do culpa. Excepteur duis anim cupidatat nisi aliquip anim elit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Stanley Vance",
        "email": "stanleyvance@geekola.com",
        "fte": 0.246
      },
      {
        "id": 1,
        "name": "Tricia Nielsen",
        "email": "tricianielsen@geekola.com",
        "fte": 0.0168
      },
      {
        "id": 2,
        "name": "Faye Melton",
        "email": "fayemelton@geekola.com",
        "fte": 0.9203
      },
      {
        "id": 3,
        "name": "Glass Tillman",
        "email": "glasstillman@geekola.com",
        "fte": 0.5154
      },
      {
        "id": 4,
        "name": "Pollard Molina",
        "email": "pollardmolina@geekola.com",
        "fte": 0.2316
      },
      {
        "id": 5,
        "name": "Claire Odom",
        "email": "claireodom@geekola.com",
        "fte": 0.0535
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Foreman Boyer",
        "email": "foremanboyer@geekola.com"
      },
      {
        "id": 1,
        "name": "Holland Warner",
        "email": "hollandwarner@geekola.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 12,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 47,
        "y3": 96
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 17,
        "y3": 56
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5451133,
      "customerVal": 1367448,
      "mvp": 81873523,
      "optimize": 11263142,
      "grow": 882834053,
      "scale": 140806372
    },
    "nextStepsActionPlan": "Culpa quis dolor culpa aute exercitation cupidatat aliquip veniam aliqua incididunt et culpa irure. Mollit quis est sit consectetur minim cupidatat dolor sunt. Sint eiusmod do magna commodo ullamco esse dolor consequat commodo deserunt elit. Eu ipsum enim labore ipsum nisi pariatur mollit sint velit magna consequat sunt. Duis reprehenderit velit adipisicing aliquip non voluptate. Tempor elit exercitation adipisicing aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Amet anim nisi magna velit elit deserunt qui dolore eu. Laboris magna enim cillum minim veniam anim veniam elit. Eu irure consectetur occaecat voluptate consectetur aliqua qui esse. Quis nisi anim Lorem elit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-25",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d8dd08c824fe9fa9",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Softmicro",
    "highlightKPI": [
      "cac",
      "bcr",
      "tc"
    ],
    "geography": "Italy",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Mollit deserunt nisi adipisicing commodo qui cupidatat do voluptate officia nisi labore. Ipsum culpa Lorem pariatur aliquip velit ad dolore tempor pariatur ad aliqua laboris nulla. Laborum consectetur velit Lorem velit consectetur occaecat aute aliquip sit laboris. Laboris esse et duis dolor officia ut enim nostrud veniam id.\r\n",
    "description": "Labore sunt reprehenderit occaecat incididunt dolore minim veniam eiusmod est aliqua aliqua. Laboris labore sint fugiat sint est est cillum dolore nisi aliqua deserunt quis fugiat adipisicing. Est officia sunt anim id magna cupidatat. Nulla cupidatat nostrud excepteur laborum culpa do adipisicing ad exercitation deserunt excepteur. Cillum culpa ea minim ad adipisicing ex officia Lorem enim amet do et. Ut enim officia ipsum nulla. Duis labore non tempor labore culpa ex nulla ut.\r\n",
    "strategy": "Voluptate laboris minim ad pariatur proident quis velit aliqua non mollit fugiat velit exercitation anim. Aliqua ea et sit cillum cillum aliquip sunt incididunt exercitation anim. Ullamco Lorem officia nulla exercitation quis Lorem in eu et.\r\n",
    "keyLearnings": "Eu pariatur irure incididunt do ad id. Id do aliquip esse non Lorem consectetur velit. Laboris ex consectetur cillum culpa sint velit voluptate mollit proident minim cupidatat Lorem pariatur excepteur. Laboris velit cillum velit ut commodo sint eiusmod sunt. Ex aute magna quis et amet consectetur et commodo laboris culpa.\r\n",
    "nextSteps": "Proident elit labore proident commodo non. Culpa dolor commodo consequat fugiat nisi id non dolore. Et consectetur deserunt reprehenderit laborum eiusmod magna duis minim ut ex elit ullamco. Laborum magna quis mollit dolore irure deserunt dolore proident.\r\n",
    "investment": "Dolor dolore laboris aliquip quis cillum commodo laborum dolor sint incididunt laborum duis proident irure. Et reprehenderit ut tempor fugiat nostrud. In ex eu aute nostrud occaecat. Laboris qui est ea nulla. Dolore excepteur mollit deserunt consectetur ullamco. Ad aute sunt minim non non qui adipisicing enim sit in ullamco irure id. Reprehenderit do magna aute esse irure cupidatat culpa in tempor.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Nulla cillum irure duis incididunt excepteur veniam qui. Laborum laborum duis voluptate pariatur minim et ullamco occaecat irure occaecat. Ea id voluptate est proident. Ea exercitation voluptate pariatur veniam velit id enim occaecat aliqua mollit. Mollit occaecat nulla qui eu enim nisi cillum aliqua magna reprehenderit id labore fugiat.\r\n",
      "engagementDescr": "Dolore est ea et consectetur deserunt culpa eiusmod sunt ut laborum irure. Do veniam in aute consequat. Lorem aute cupidatat esse consectetur veniam magna excepteur in id. Esse culpa aute enim qui est exercitation in. Irure eiusmod ex culpa velit tempor aliqua esse amet aute voluptate.\r\n",
      "dataDescr": "Ex incididunt ullamco mollit enim duis commodo nostrud eu fugiat enim amet enim. Voluptate ad amet ea exercitation tempor fugiat duis voluptate qui pariatur. Elit irure fugiat Lorem et esse. Cillum amet eu consequat dolor ad anim eiusmod consequat ullamco. Ullamco duis veniam ea cupidatat nulla tempor consequat excepteur laboris aute duis eu.\r\n",
      "routeDescr": "Mollit ut id Lorem anim irure dolor aute enim ullamco eiusmod. Enim non proident cillum ex minim esse do. Eiusmod laborum sit pariatur ea nulla magna est fugiat consequat proident in.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Cochran Rodgers",
        "email": "cochranrodgers@softmicro.com",
        "fte": 0.193
      },
      {
        "id": 1,
        "name": "Helga Holman",
        "email": "helgaholman@softmicro.com",
        "fte": 0.0756
      },
      {
        "id": 2,
        "name": "Dawn Henderson",
        "email": "dawnhenderson@softmicro.com",
        "fte": 0.3147
      },
      {
        "id": 3,
        "name": "Cox Mcgowan",
        "email": "coxmcgowan@softmicro.com",
        "fte": 0.8501
      },
      {
        "id": 4,
        "name": "Darcy Chapman",
        "email": "darcychapman@softmicro.com",
        "fte": 0.6838
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Marla Robles",
        "email": "marlarobles@softmicro.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 14,
        "y3": 39
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 22,
        "y3": 81
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 27,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6584715,
      "customerVal": 633971,
      "mvp": 43533403,
      "optimize": 2121918,
      "grow": 1481124683,
      "scale": 94770631
    },
    "nextStepsActionPlan": "Duis dolore veniam in magna et sunt tempor esse nisi. Magna ipsum ea Lorem est dolor. Exercitation pariatur irure sunt cillum voluptate. Cillum aliqua enim eiusmod aliquip reprehenderit. Consequat culpa duis sit ea elit nulla magna sint.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Lorem deserunt culpa pariatur esse ullamco magna sint dolor eiusmod nulla voluptate ipsum cillum consequat. Laborum ex eu sint pariatur nostrud nulla aute aute consequat aute id deserunt est excepteur. Duis nostrud sit enim velit dolore fugiat elit sint excepteur cillum cillum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03e9a07b79134748cd",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Telequiet",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rp"
    ],
    "geography": "India",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Exercitation excepteur tempor enim pariatur in nisi. Do reprehenderit voluptate ipsum quis voluptate veniam minim sit. Consectetur dolore enim reprehenderit do amet velit eiusmod ex fugiat consectetur ex nisi do mollit. Adipisicing duis consequat cillum do esse consequat elit reprehenderit id. Sit non ad exercitation occaecat esse. Culpa consectetur nisi eiusmod magna irure.\r\n",
    "description": "Laborum duis ut incididunt do do excepteur dolor enim ullamco ad proident. Lorem tempor qui ipsum consectetur consequat veniam deserunt est ut incididunt occaecat duis veniam. Exercitation mollit irure cillum ea duis occaecat consectetur. Reprehenderit fugiat laborum esse anim esse laboris mollit laboris id esse quis. Consequat ea mollit deserunt eu eiusmod do velit deserunt. Lorem in laboris fugiat et commodo nulla. Veniam minim ullamco ipsum Lorem dolor anim dolore.\r\n",
    "strategy": "Aute proident deserunt id veniam Lorem anim duis ipsum nulla. Aute anim ea voluptate cillum. Est cupidatat anim consectetur velit non nisi labore adipisicing proident occaecat exercitation. Labore consectetur sint do Lorem commodo ut do do. Magna consectetur reprehenderit id laborum enim laboris labore velit laboris fugiat Lorem ad. Est anim anim nostrud sint tempor duis dolore. Do aliquip esse cupidatat proident culpa ipsum ullamco culpa dolore sit et sit.\r\n",
    "keyLearnings": "Irure ullamco laboris minim tempor incididunt cillum labore ad. Laboris nisi ad elit consectetur excepteur qui minim eiusmod excepteur eu. Commodo ad eiusmod tempor occaecat magna fugiat non duis sint quis. Do excepteur tempor proident ex nostrud officia in aliquip nostrud ad proident ea proident.\r\n",
    "nextSteps": "Pariatur laborum aliquip nostrud duis adipisicing reprehenderit officia elit. Nisi laboris aliqua enim tempor. Eiusmod qui magna dolore commodo adipisicing consectetur deserunt tempor nulla mollit commodo tempor consequat.\r\n",
    "investment": "Ad qui minim mollit ipsum voluptate laboris sit laborum qui exercitation culpa reprehenderit voluptate. Pariatur amet excepteur consectetur pariatur velit. Tempor fugiat proident deserunt commodo cupidatat in enim minim commodo aute deserunt fugiat sit sunt. Non incididunt ea cupidatat magna velit nisi exercitation sit nostrud do.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Minim reprehenderit qui occaecat exercitation aliqua deserunt irure nostrud aliquip sit pariatur duis excepteur. Aute culpa quis eiusmod veniam adipisicing nisi aute esse. Officia cillum ex excepteur deserunt qui.\r\n",
      "engagementDescr": "Qui tempor ullamco laborum eiusmod reprehenderit. Quis ut excepteur exercitation incididunt non ut officia consequat excepteur consequat ullamco. Aute duis ipsum deserunt amet aute est. Mollit non laborum nisi excepteur sint quis Lorem. Elit do ut amet excepteur proident exercitation velit est cillum nisi ipsum eu. Lorem mollit in veniam proident consectetur sunt ipsum duis laborum laborum quis nostrud.\r\n",
      "dataDescr": "Aliquip voluptate ad sit do excepteur culpa dolor aliqua laborum enim. Est cupidatat consectetur enim non laborum sit fugiat dolor. Ad ullamco adipisicing aliqua laboris ullamco occaecat sit velit. Irure exercitation veniam ullamco nulla est commodo nulla cillum aliqua laborum irure non aliqua. Non deserunt laboris laboris incididunt voluptate duis irure ex.\r\n",
      "routeDescr": "Pariatur velit ea mollit aute quis minim tempor ipsum elit. Deserunt sint velit ut esse aliqua. Aute laboris duis minim ullamco tempor sunt eiusmod excepteur occaecat adipisicing.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Cooper Wilder",
        "email": "cooperwilder@telequiet.com",
        "fte": 0.6012
      },
      {
        "id": 1,
        "name": "Maxine Dyer",
        "email": "maxinedyer@telequiet.com",
        "fte": 0.2055
      },
      {
        "id": 2,
        "name": "Marilyn Alford",
        "email": "marilynalford@telequiet.com",
        "fte": 0.8123
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Sophie Beach",
        "email": "sophiebeach@telequiet.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 16,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 3,
        "y2": 57,
        "y3": 95
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 17,
        "y3": 61
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7275416,
      "customerVal": 546935,
      "mvp": 143476382,
      "optimize": 5872265,
      "grow": 728207575,
      "scale": 571250523
    },
    "nextStepsActionPlan": "Quis aliquip dolor nulla reprehenderit occaecat veniam adipisicing commodo nisi incididunt exercitation voluptate labore anim. Cupidatat est sit commodo officia ea aliquip minim in aliqua ad enim officia. Sunt nulla dolor eiusmod consequat cupidatat aute pariatur id. Nulla Lorem enim cillum ipsum ex proident aliqua proident. Ullamco nostrud sunt ipsum amet voluptate qui est ut nostrud occaecat esse esse occaecat occaecat. Sunt culpa non ut reprehenderit culpa aliquip Lorem deserunt Lorem do fugiat laboris.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Fugiat irure minim consequat nisi culpa ea. Ex officia irure tempor quis voluptate duis dolor ex nostrud fugiat officia. Eu enim cupidatat tempor enim duis Lorem do anim excepteur pariatur officia qui sint. Do pariatur mollit ullamco cillum pariatur do et. Ullamco occaecat sunt ad veniam ipsum tempor ut ea enim in. Cillum do nulla sunt adipisicing laboris labore.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-14",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03f6dc27570d115834",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Kyagoro",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Magna ipsum ullamco est culpa irure velit ad aliquip excepteur reprehenderit nulla esse do ullamco. Culpa ex deserunt adipisicing in amet nulla sint occaecat. Ut veniam minim sunt ea do occaecat amet amet aute ex commodo.\r\n",
    "description": "Commodo proident fugiat adipisicing elit irure deserunt est adipisicing laboris aliquip. Officia nulla anim id enim pariatur ad nulla velit exercitation minim pariatur enim sint reprehenderit. Dolore deserunt dolor ullamco aute exercitation sunt duis culpa consequat commodo.\r\n",
    "strategy": "Ad qui deserunt dolore esse in culpa sit in voluptate mollit amet exercitation. Eiusmod culpa minim tempor non magna aliqua irure sint. Occaecat exercitation eu cupidatat est ipsum ullamco quis reprehenderit esse id quis sunt.\r\n",
    "keyLearnings": "Dolor velit duis voluptate aliquip excepteur duis est culpa aute. Esse culpa eiusmod ad nulla. Amet occaecat duis mollit mollit consectetur irure velit tempor ex nisi eu duis officia. Non excepteur elit exercitation elit. Commodo minim aliquip et consequat consectetur magna non cupidatat minim culpa occaecat Lorem.\r\n",
    "nextSteps": "Dolor qui eu qui aliqua. Irure ullamco sit enim et id consectetur sunt eiusmod adipisicing irure ut non consectetur. Eiusmod reprehenderit sit excepteur dolor cupidatat anim ipsum eiusmod. Ipsum adipisicing laborum esse nisi ad Lorem aute fugiat. Culpa est enim dolor non ea fugiat.\r\n",
    "investment": "Exercitation aliquip nulla aliquip in laboris eu ex cillum consectetur aliquip commodo ex dolore. Exercitation ipsum nisi veniam ea nostrud nostrud. Consequat dolore velit enim ea tempor nostrud officia culpa ullamco. Esse aliquip aute anim ex sunt elit ex exercitation. Eiusmod quis velit sint incididunt pariatur enim magna ut proident laborum. Nostrud veniam elit eu irure reprehenderit ipsum occaecat quis.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Laborum irure cillum consectetur aliqua ipsum. Eiusmod exercitation veniam in cillum ullamco quis id officia ut deserunt sunt et aute eu. Ut duis qui excepteur officia exercitation labore commodo reprehenderit consequat nulla. Consectetur commodo eiusmod labore aliqua aliquip irure elit anim occaecat.\r\n",
      "engagementDescr": "In id voluptate ullamco pariatur et fugiat ex commodo culpa consequat nulla sit mollit. Veniam occaecat et labore fugiat laborum. Magna reprehenderit pariatur labore excepteur mollit adipisicing sint eiusmod nostrud pariatur. Adipisicing aliqua nisi qui sit adipisicing velit reprehenderit minim ullamco.\r\n",
      "dataDescr": "Nostrud laboris fugiat cillum qui. Magna laboris anim qui exercitation labore excepteur minim labore qui commodo enim non. Minim deserunt ad fugiat cupidatat nostrud sunt exercitation incididunt labore. Consequat nisi nisi id deserunt ut commodo est deserunt irure deserunt aliquip. Nostrud nostrud nisi dolor est eu exercitation aute anim ex quis elit ipsum ex non. Laboris nostrud deserunt do occaecat laboris ipsum id laboris occaecat sunt reprehenderit. Ad sit enim commodo aliquip anim eiusmod sint quis ipsum.\r\n",
      "routeDescr": "Ea quis labore sint cupidatat in. Non consequat mollit nulla commodo consequat ullamco qui laborum aliqua velit. Dolor laboris cupidatat occaecat anim ad labore excepteur est esse cillum est tempor. Laborum elit veniam veniam ut voluptate consequat.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Twila Cervantes",
        "email": "twilacervantes@kyagoro.com",
        "fte": 0.3619
      },
      {
        "id": 1,
        "name": "Kirk Mathews",
        "email": "kirkmathews@kyagoro.com",
        "fte": 0.8243
      },
      {
        "id": 2,
        "name": "William Winters",
        "email": "williamwinters@kyagoro.com",
        "fte": 0.7293
      },
      {
        "id": 3,
        "name": "Conway Mcfarland",
        "email": "conwaymcfarland@kyagoro.com",
        "fte": 0.5696
      },
      {
        "id": 4,
        "name": "Mendez Glenn",
        "email": "mendezglenn@kyagoro.com",
        "fte": 0.8788
      },
      {
        "id": 5,
        "name": "Lucile Lancaster",
        "email": "lucilelancaster@kyagoro.com",
        "fte": 0.2197
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kristina Mckay",
        "email": "kristinamckay@kyagoro.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 14,
        "y3": 39
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 8,
        "y2": 29,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 11,
        "y3": 78
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8066726,
      "customerVal": 1070949,
      "mvp": 4673306,
      "optimize": 4791505,
      "grow": 447562892,
      "scale": 635104974
    },
    "nextStepsActionPlan": "Sint labore mollit qui nulla nostrud nisi esse id amet esse ut id reprehenderit. Dolor aute fugiat nostrud aute ullamco dolor nostrud. Amet eu et in laborum fugiat reprehenderit officia quis dolore cillum Lorem. Exercitation eu Lorem id deserunt officia commodo aute anim officia velit adipisicing cillum. Exercitation anim laborum esse quis do tempor tempor.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Culpa anim exercitation aute laboris id magna in qui fugiat voluptate quis. Magna quis esse amet aliqua. Et laborum aute dolore consectetur reprehenderit esse.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-30",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e034d0902221defcf88",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Geekfarm",
    "highlightKPI": [
      "cac",
      "gmac",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Laborum consequat et occaecat ullamco nulla laboris reprehenderit mollit enim proident deserunt eiusmod laborum ex. Eiusmod et et incididunt cillum sit sit tempor et sit labore mollit ex. Officia do ullamco elit deserunt irure laborum ipsum consectetur minim aute. Esse id anim aliquip est laboris in occaecat ad consequat nostrud culpa. Culpa veniam duis ad duis qui commodo veniam. Proident laboris in enim aliqua anim consectetur aliquip laborum ut elit. Sint excepteur magna minim ut et consequat consectetur.\r\n",
    "description": "Quis ipsum dolore labore aute Lorem nostrud est cillum cillum non. Elit est deserunt mollit anim in minim fugiat qui Lorem. Fugiat consectetur labore laborum incididunt sit irure minim deserunt est officia aute sunt exercitation cupidatat. Veniam deserunt esse enim laborum commodo cillum officia laboris adipisicing Lorem sit.\r\n",
    "strategy": "Ut duis ea eiusmod sit fugiat nisi aliqua nostrud anim eu eiusmod excepteur commodo. Ipsum nulla tempor adipisicing magna proident culpa ut ad id non adipisicing. Sit ad veniam labore quis labore labore. Commodo ea excepteur cillum sint magna officia in labore esse consectetur nulla aute dolore elit. Exercitation deserunt dolor ad qui ut ad pariatur exercitation voluptate ut.\r\n",
    "keyLearnings": "Dolore fugiat id aliqua nisi eu culpa incididunt consequat Lorem exercitation mollit id. Est nostrud dolore aliqua officia ipsum labore dolor eiusmod labore mollit adipisicing irure enim. Dolor nostrud duis proident ut esse irure.\r\n",
    "nextSteps": "Minim fugiat nostrud nulla nulla do ipsum qui ea cupidatat irure. Velit cupidatat excepteur cillum cupidatat adipisicing. Aute est velit nostrud eu ex nostrud pariatur aute deserunt eiusmod ex aliqua velit. Ut cupidatat veniam qui et cupidatat deserunt aute exercitation laboris tempor ut aliquip. Cupidatat deserunt aliqua nulla eiusmod cupidatat non id laboris adipisicing dolore eiusmod labore commodo ad. Occaecat fugiat cupidatat amet aliqua Lorem consectetur laboris sunt ipsum sit.\r\n",
    "investment": "Enim commodo laboris exercitation fugiat cupidatat ex fugiat ea non commodo tempor pariatur. Excepteur id labore sit magna proident officia ea quis. Minim magna ea proident est nostrud id cupidatat Lorem qui aliqua. Ad qui commodo minim incididunt dolore ad ea esse.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Commodo nisi nostrud ullamco Lorem sunt minim dolor ad ex duis culpa. Magna consequat ullamco cillum id quis deserunt magna occaecat eiusmod ea elit elit duis. Do reprehenderit voluptate id nulla cillum tempor cillum est exercitation in elit tempor. Duis irure aliquip adipisicing exercitation.\r\n",
      "engagementDescr": "Qui pariatur nulla ea mollit ipsum deserunt aute nisi sit. Non sunt incididunt ullamco amet irure voluptate sit. Commodo Lorem qui ut dolore culpa cupidatat do dolore incididunt cillum pariatur excepteur sint. Ex ullamco cillum et est in veniam sint. Ex eu ea in id voluptate veniam in id. Duis est est fugiat consequat exercitation Lorem proident.\r\n",
      "dataDescr": "Ad adipisicing et aliquip occaecat. Eu quis do voluptate anim aute laborum nulla laboris minim id duis nisi laboris quis. Ad minim exercitation ad elit pariatur minim aliquip. Adipisicing culpa quis in exercitation enim pariatur adipisicing qui id eiusmod. Esse et et do voluptate adipisicing sint non duis consequat cillum sunt nostrud veniam in. Enim ut ut velit labore nostrud eiusmod reprehenderit consectetur fugiat Lorem officia. Nostrud enim irure minim sit laboris laboris quis.\r\n",
      "routeDescr": "Velit Lorem enim duis nulla mollit excepteur culpa commodo. Ut qui adipisicing nisi fugiat. Ad et consequat sint do deserunt cupidatat. In nulla cupidatat Lorem dolore nisi id aliqua. Veniam proident velit incididunt proident dolore.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Nora Rivera",
        "email": "norarivera@geekfarm.com",
        "fte": 0.9147
      },
      {
        "id": 1,
        "name": "Marylou Serrano",
        "email": "marylouserrano@geekfarm.com",
        "fte": 0.6722
      },
      {
        "id": 2,
        "name": "Antonia Welch",
        "email": "antoniawelch@geekfarm.com",
        "fte": 0.56
      },
      {
        "id": 3,
        "name": "Jacklyn Gregory",
        "email": "jacklyngregory@geekfarm.com",
        "fte": 0.6099
      },
      {
        "id": 4,
        "name": "Cleo Pope",
        "email": "cleopope@geekfarm.com",
        "fte": 0.6699
      },
      {
        "id": 5,
        "name": "Adele Le",
        "email": "adelele@geekfarm.com",
        "fte": 0.0536
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Nolan Cardenas",
        "email": "nolancardenas@geekfarm.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 12,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 28,
        "y3": 83
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 28,
        "y3": 97
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12110435,
      "customerVal": 551320,
      "mvp": 2020500,
      "optimize": 14095509,
      "grow": 664821179,
      "scale": 25246475
    },
    "nextStepsActionPlan": "Ullamco ipsum incididunt minim minim tempor. Velit consequat et sint esse commodo aliquip excepteur cupidatat cupidatat cillum do. Quis do duis incididunt quis mollit ad nulla do. Consequat exercitation cupidatat fugiat est reprehenderit sunt aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nostrud aute ad ut dolor pariatur officia officia anim anim proident eiusmod. Commodo laboris aliquip sunt esse. Quis nulla do est fugiat sunt consectetur in aute voluptate esse. Duis magna exercitation voluptate deserunt qui deserunt pariatur cillum sunt irure consectetur. Consectetur consequat eiusmod officia id laborum Lorem. Nulla consectetur quis dolor quis.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-09",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039956f194ed2447be",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Zomboid",
    "highlightKPI": [
      "nsv",
      "gmac",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Sunt anim anim ad velit reprehenderit elit veniam. Sunt do magna nisi sint pariatur magna. Mollit ipsum officia aliquip qui laboris eu dolor incididunt in aliquip. Consectetur pariatur veniam ad officia reprehenderit aute irure non laboris do irure laboris mollit. Minim duis exercitation adipisicing proident magna qui nisi labore officia cupidatat duis veniam laborum. Fugiat eu occaecat commodo culpa commodo fugiat eiusmod enim Lorem.\r\n",
    "description": "Do amet dolor fugiat adipisicing. Nostrud proident esse occaecat enim labore anim ad tempor veniam deserunt. Occaecat adipisicing sint duis cupidatat irure aliqua. Exercitation pariatur officia mollit officia fugiat Lorem ut officia deserunt.\r\n",
    "strategy": "Mollit reprehenderit consectetur aute sint exercitation do est nisi. Dolor non do anim aute in irure ut consequat dolore ea ipsum anim Lorem pariatur. Eu velit sunt ipsum adipisicing aute consectetur excepteur eu incididunt deserunt non qui dolor dolore.\r\n",
    "keyLearnings": "Amet exercitation veniam dolor reprehenderit consectetur incididunt quis sint adipisicing eiusmod. Nulla aliquip aliqua consectetur dolore consequat tempor elit ullamco proident consectetur. Elit sit occaecat laborum ea eu. Ea ex ullamco non occaecat aute duis quis eu nisi sint nostrud incididunt. Aliquip quis sunt quis eiusmod sit velit. Officia ea magna voluptate quis. Laboris nisi in proident veniam ex quis nulla non amet ipsum nulla pariatur Lorem.\r\n",
    "nextSteps": "Proident reprehenderit proident qui occaecat anim nulla incididunt. In commodo voluptate magna officia excepteur labore consequat in sit sunt aute. Est aliqua commodo dolor qui. In proident ipsum ipsum anim excepteur esse minim laborum cupidatat laborum eiusmod sint laborum.\r\n",
    "investment": "Voluptate consectetur consequat nostrud occaecat. Nostrud ipsum aute reprehenderit velit in eu ea ea voluptate. Labore officia dolor laborum aliquip ullamco ipsum deserunt enim nulla incididunt qui duis est. Exercitation qui ullamco sit elit eiusmod Lorem do laboris est. Ipsum ad adipisicing minim nulla proident ea adipisicing do aliquip magna cillum minim. Id minim id ea magna. Officia do dolor occaecat mollit occaecat esse.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Incididunt enim sunt dolor laborum adipisicing incididunt id voluptate magna non velit. Anim magna aute irure elit enim culpa officia. Pariatur nisi cupidatat nulla enim incididunt enim sint elit consequat culpa irure ad. Exercitation labore est cupidatat labore ad dolor nulla enim mollit. Irure irure non do commodo. Non laboris eu nisi incididunt commodo veniam duis nulla. Culpa deserunt minim irure eiusmod reprehenderit elit et et sit ullamco ex nostrud occaecat.\r\n",
      "engagementDescr": "Qui non proident deserunt do voluptate ut veniam qui. Qui laborum qui magna voluptate exercitation ut esse et ullamco dolore tempor nostrud non voluptate. Do veniam aliquip nostrud ea veniam ullamco fugiat. Dolore consequat est magna excepteur duis ad anim eu.\r\n",
      "dataDescr": "Labore ex do amet Lorem aute velit elit officia. Do tempor sit Lorem pariatur dolor ea quis est incididunt sint irure aliquip voluptate. Labore et veniam reprehenderit officia id.\r\n",
      "routeDescr": "Qui veniam duis velit nulla commodo labore cillum elit ipsum magna laborum. Veniam nisi cupidatat Lorem laboris. Labore sint consectetur proident sint aliquip in nulla officia laborum officia. Voluptate qui reprehenderit proident cupidatat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Jayne Horne",
        "email": "jaynehorne@zomboid.com",
        "fte": 0.1266
      },
      {
        "id": 1,
        "name": "Mamie Rowland",
        "email": "mamierowland@zomboid.com",
        "fte": 0.3274
      },
      {
        "id": 2,
        "name": "Hester Mckenzie",
        "email": "hestermckenzie@zomboid.com",
        "fte": 0.6679
      },
      {
        "id": 3,
        "name": "Jenna Dodson",
        "email": "jennadodson@zomboid.com",
        "fte": 0.627
      },
      {
        "id": 4,
        "name": "Schmidt Little",
        "email": "schmidtlittle@zomboid.com",
        "fte": 0.3211
      },
      {
        "id": 5,
        "name": "Dorothy Huff",
        "email": "dorothyhuff@zomboid.com",
        "fte": 0.3643
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Rivers Osborn",
        "email": "riversosborn@zomboid.com"
      },
      {
        "id": 1,
        "name": "Earnestine Wong",
        "email": "earnestinewong@zomboid.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 13,
        "y3": 37
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 54,
        "y3": 56
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 28,
        "y3": 93
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1185645,
      "customerVal": 356075,
      "mvp": 117721754,
      "optimize": 12441398,
      "grow": 957437351,
      "scale": 638813335
    },
    "nextStepsActionPlan": "Dolore ut nisi mollit consequat ex aute amet laborum eu enim sint. Sit esse aliquip pariatur commodo do irure excepteur irure officia voluptate. Occaecat et anim aliqua incididunt dolore et duis reprehenderit cillum deserunt sit qui culpa. Lorem tempor aliqua dolore qui incididunt nisi. Nulla officia veniam esse anim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Minim labore adipisicing Lorem ullamco. Velit cupidatat duis qui aute non officia. Cupidatat consectetur nulla aute adipisicing velit nostrud Lorem et. Nostrud minim voluptate dolor sit laboris minim enim occaecat esse ipsum proident. Cupidatat aliqua cillum id commodo qui do est do et et labore occaecat enim amet. Ad veniam qui non in eiusmod cillum voluptate mollit pariatur laborum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-22",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03617d0f24a86c69e5",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zentia",
    "highlightKPI": [
      "cac",
      "ltv",
      "rp"
    ],
    "geography": "Norway",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Do labore duis laborum pariatur. In sit commodo velit do labore laborum tempor voluptate et irure. Esse pariatur ea excepteur cillum eu id enim incididunt laborum ipsum et dolor. Nisi laborum nisi fugiat amet ut veniam aliquip qui.\r\n",
    "description": "Laborum in culpa sunt esse dolor eiusmod. Quis consectetur ipsum ut id. Pariatur sunt sint nostrud enim excepteur. Minim pariatur voluptate do non occaecat occaecat non labore duis irure deserunt consectetur ea. Eu commodo mollit irure non proident consequat incididunt.\r\n",
    "strategy": "Aute anim cupidatat cillum enim ex veniam non nostrud officia. Velit pariatur labore deserunt duis. Duis exercitation aliqua fugiat non consequat esse tempor nulla. Fugiat nostrud ut reprehenderit consectetur cillum consequat reprehenderit. Lorem aliquip anim culpa sit anim. Dolor pariatur aliquip anim sunt eiusmod enim adipisicing consequat id.\r\n",
    "keyLearnings": "Cupidatat proident cupidatat dolor dolore exercitation fugiat labore aliqua sit. Ipsum reprehenderit nisi consequat ad ut cupidatat laborum amet proident laboris voluptate do ex. Eu dolore proident ad dolor mollit quis ea quis dolore sint velit tempor id. Qui Lorem fugiat aliqua reprehenderit ipsum ullamco sit. Fugiat reprehenderit nulla consequat officia eiusmod cupidatat amet velit nostrud sit esse deserunt.\r\n",
    "nextSteps": "Nulla id ex adipisicing aliqua enim et eu. Sit fugiat veniam proident mollit consectetur amet exercitation sit et. Dolore ullamco aliquip commodo cillum commodo duis Lorem. Dolor incididunt consectetur non reprehenderit sit. Consectetur sunt et nisi labore laboris laborum aliquip qui nostrud deserunt aute qui do.\r\n",
    "investment": "Incididunt ad sit tempor nostrud nostrud quis voluptate proident velit. Mollit velit veniam adipisicing et voluptate nulla consequat ad magna officia nulla. Occaecat adipisicing cupidatat cillum est ad et aliquip id exercitation cillum ad dolore. Fugiat eiusmod adipisicing cupidatat elit reprehenderit mollit ipsum qui cupidatat cupidatat eu est. Qui magna ex exercitation enim do.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Nulla ullamco veniam excepteur irure consequat laborum ea tempor laborum officia. Cillum consectetur occaecat sit quis exercitation. Elit duis nulla culpa amet aliqua commodo sunt qui Lorem velit magna in non. Adipisicing pariatur id consectetur voluptate culpa proident commodo dolor pariatur reprehenderit ipsum.\r\n",
      "engagementDescr": "Occaecat ad aliqua Lorem sint sunt consectetur excepteur aute laborum laboris esse anim. Culpa duis amet laborum amet qui. Esse cupidatat exercitation exercitation culpa eu est. Nulla elit ea consectetur ullamco anim elit laborum. Est ex occaecat aliqua mollit incididunt laboris aliquip occaecat officia mollit. Consequat pariatur dolor est aliqua eu amet nostrud.\r\n",
      "dataDescr": "Adipisicing amet sint pariatur incididunt aliquip laboris ex. Consectetur duis tempor eu aliquip deserunt anim aute velit et veniam enim aute laboris. Velit cillum aliquip ea do quis esse. Ullamco qui minim qui nostrud culpa ullamco reprehenderit culpa aliqua consequat proident eu ex fugiat.\r\n",
      "routeDescr": "Sint laboris eu nisi elit ex. Ut laboris enim commodo aliqua eu laboris qui exercitation dolor cupidatat deserunt deserunt proident. Quis exercitation mollit tempor eiusmod ex.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Sharlene Torres",
        "email": "sharlenetorres@zentia.com",
        "fte": 0.7522
      },
      {
        "id": 1,
        "name": "Nannie Barlow",
        "email": "nanniebarlow@zentia.com",
        "fte": 0.4551
      },
      {
        "id": 2,
        "name": "Jeanine Crosby",
        "email": "jeaninecrosby@zentia.com",
        "fte": 0.8923
      },
      {
        "id": 3,
        "name": "Ernestine Burke",
        "email": "ernestineburke@zentia.com",
        "fte": 0.8466
      },
      {
        "id": 4,
        "name": "Merrill Bond",
        "email": "merrillbond@zentia.com",
        "fte": 0.3359
      },
      {
        "id": 5,
        "name": "Megan Rush",
        "email": "meganrush@zentia.com",
        "fte": 0.1598
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Goodman England",
        "email": "goodmanengland@zentia.com"
      },
      {
        "id": 1,
        "name": "Mccormick Boyle",
        "email": "mccormickboyle@zentia.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 20,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 21,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 12,
        "y3": 37
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14618214,
      "customerVal": 1235875,
      "mvp": 45128352,
      "optimize": 11033791,
      "grow": 1371977289,
      "scale": 250058857
    },
    "nextStepsActionPlan": "Sit cillum laborum et culpa aliqua aute cupidatat cillum voluptate laboris sit. Nulla ut sit laboris cillum qui et eiusmod irure laborum. Nisi esse nulla non elit consectetur pariatur amet deserunt in officia magna. Velit ad exercitation aute mollit duis. Fugiat exercitation exercitation dolore nostrud dolore nisi occaecat tempor irure ex.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Exercitation laborum ut elit culpa nulla eiusmod dolore. Ipsum mollit cillum ex elit amet occaecat anim eiusmod. Deserunt tempor excepteur dolor nostrud laborum nostrud. Id magna aliqua non aliquip labore culpa laborum reprehenderit. Ad ipsum excepteur cupidatat cillum exercitation ea ea amet sint laborum commodo sunt. Pariatur incididunt do laborum dolore labore consequat adipisicing aliqua et. Esse incididunt qui fugiat exercitation labore veniam eiusmod aute magna eiusmod eu ex sit ullamco.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e032f97e968eefc93dc",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Dentrex",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Subscription",
    "division": "Division Three",
    "painPoint": "Reprehenderit voluptate velit consectetur anim qui. Esse dolore irure id eiusmod voluptate quis pariatur ea minim cillum et aute aliquip. Labore occaecat duis nostrud Lorem irure labore sint ad. Officia pariatur aliquip ea qui velit ex amet minim fugiat excepteur ipsum in elit incididunt. Voluptate laboris et veniam eiusmod ullamco. Irure nisi adipisicing ut dolore voluptate adipisicing nulla magna non minim ullamco. Excepteur Lorem reprehenderit veniam laboris velit minim anim.\r\n",
    "description": "Ullamco mollit proident adipisicing mollit esse pariatur in consequat veniam laborum Lorem. Cillum excepteur elit eu ullamco commodo quis consequat ex. Exercitation aliqua aliqua consequat excepteur deserunt qui est incididunt pariatur qui dolore eu.\r\n",
    "strategy": "Ad elit et est est duis voluptate. Officia ut eiusmod amet Lorem quis. Aliqua qui aliqua elit exercitation. Cillum deserunt pariatur deserunt nulla consequat ex aliquip excepteur.\r\n",
    "keyLearnings": "Cupidatat officia adipisicing aute enim proident. Irure pariatur dolor ullamco eu qui quis ad ut aliqua deserunt. Nisi laboris irure ipsum sit fugiat non tempor.\r\n",
    "nextSteps": "Veniam tempor amet esse ut dolor aute culpa ullamco veniam aliqua. Quis fugiat esse laborum ex cupidatat commodo incididunt elit sit commodo. Quis ipsum duis ipsum incididunt labore officia ullamco nulla consectetur.\r\n",
    "investment": "Consectetur veniam fugiat ut eu esse eu. Sit consequat amet consequat sunt anim sit ad mollit quis. Pariatur laboris enim esse elit excepteur cupidatat nulla. Amet culpa veniam labore cupidatat fugiat adipisicing laborum ut consequat qui. Ullamco eiusmod excepteur excepteur excepteur elit esse adipisicing cillum aliqua aliquip officia enim. Ut consectetur qui Lorem cupidatat anim qui culpa anim occaecat aliquip.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Sint deserunt pariatur veniam amet sunt officia cillum nulla laboris. Officia fugiat fugiat proident Lorem deserunt ex do excepteur ea velit amet. Eu enim occaecat officia aute fugiat ad exercitation mollit enim. Cillum fugiat irure consequat nisi occaecat dolore.\r\n",
      "engagementDescr": "Lorem fugiat ex non veniam velit quis consectetur excepteur. Ex voluptate adipisicing do culpa. Veniam voluptate elit occaecat exercitation exercitation fugiat. Deserunt reprehenderit veniam aliquip sit aliquip elit adipisicing esse. Ut voluptate excepteur reprehenderit sit officia ex nulla ea dolore consequat.\r\n",
      "dataDescr": "In cupidatat est labore laborum occaecat adipisicing eiusmod reprehenderit pariatur in laboris. Aute ex velit aliquip voluptate minim reprehenderit aute est consectetur velit culpa sit sint id. Nulla non qui velit commodo voluptate culpa magna nostrud veniam nisi reprehenderit sunt culpa esse. Ad laboris occaecat aliqua proident deserunt tempor voluptate.\r\n",
      "routeDescr": "Qui culpa tempor et ad adipisicing pariatur dolore et voluptate proident ea. Amet do aliquip voluptate sunt consequat minim nulla amet et cillum. Commodo in sunt irure officia elit exercitation id proident deserunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Claudia Conway",
        "email": "claudiaconway@dentrex.com",
        "fte": 0.0972
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Beach Villarreal",
        "email": "beachvillarreal@dentrex.com"
      },
      {
        "id": 1,
        "name": "Dotson Chavez",
        "email": "dotsonchavez@dentrex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 13,
        "y3": 39
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 29,
        "y3": 78
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 20,
        "y3": 86
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12205690,
      "customerVal": 93236,
      "mvp": 2785346,
      "optimize": 7554448,
      "grow": 1190103169,
      "scale": 1416591119
    },
    "nextStepsActionPlan": "Occaecat do veniam id ex esse cupidatat minim consectetur nisi dolor veniam ex culpa culpa. Ipsum ex aute consequat minim eu. Aliquip commodo voluptate pariatur exercitation officia quis ad culpa duis dolore laboris occaecat ex non. Labore deserunt et cupidatat adipisicing ex.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Laborum dolor elit magna esse veniam laborum do quis ad. Duis ex sit nostrud aliquip nostrud incididunt veniam nisi reprehenderit consequat. Est aliquip adipisicing eiusmod dolore ex. Fugiat commodo commodo adipisicing eu minim sint minim ullamco exercitation. Ea culpa qui eiusmod commodo ea minim mollit officia nisi labore laborum fugiat. Aute Lorem excepteur veniam adipisicing laboris nisi nostrud sint sint ea deserunt exercitation.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03625b093edee0ccd4",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Synkgen",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rr"
    ],
    "geography": "Germany",
    "channel": "Offline: Pop up store",
    "division": "Division One",
    "painPoint": "Sint consequat elit ullamco sunt veniam. Consectetur aliqua do in sit duis eu. Quis id et nulla ullamco occaecat fugiat ad. Aliqua et aliqua incididunt do elit magna anim reprehenderit aliqua elit incididunt irure laborum sunt.\r\n",
    "description": "Adipisicing laborum incididunt exercitation deserunt excepteur officia do quis irure dolor id. Dolore nisi aute pariatur ipsum est. Commodo ipsum enim dolor eu in laboris laborum. Aliquip proident velit magna dolore ipsum magna. Reprehenderit nostrud laborum et do veniam adipisicing pariatur consectetur. Est voluptate commodo do Lorem adipisicing laboris deserunt commodo dolore eu.\r\n",
    "strategy": "Adipisicing commodo consequat sunt amet velit nulla ut sint commodo exercitation esse laboris nisi labore. Commodo qui in ad deserunt excepteur enim cillum ex laborum. Reprehenderit nisi incididunt culpa amet ullamco velit minim amet sint.\r\n",
    "keyLearnings": "Nulla et culpa eu laborum culpa id ullamco veniam nisi incididunt commodo tempor ullamco do. Sit ad reprehenderit duis ipsum elit anim irure ad ex fugiat. Eiusmod eiusmod exercitation eu esse laboris exercitation in aliquip sunt quis sint. Deserunt incididunt consectetur velit ullamco quis consequat eiusmod enim exercitation proident et dolore. Cillum voluptate irure velit irure quis deserunt do nulla aliquip eiusmod.\r\n",
    "nextSteps": "Ad velit et do tempor fugiat veniam sunt incididunt ea ullamco. Aliqua veniam dolor ea laborum deserunt anim. Ipsum consectetur duis esse laboris. Sit sit et eu mollit aliqua ipsum mollit aliqua reprehenderit pariatur id laborum. Sint ea veniam esse labore aute reprehenderit aute et ipsum voluptate velit occaecat. Excepteur irure pariatur eu sit Lorem pariatur id.\r\n",
    "investment": "Elit duis ipsum veniam ea ullamco magna veniam pariatur voluptate ad voluptate. Lorem voluptate irure voluptate amet labore excepteur ea velit pariatur enim officia quis labore. Veniam sit nostrud culpa eu mollit in cillum cupidatat mollit pariatur excepteur.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod labore nostrud velit minim excepteur qui dolor Lorem dolore. Qui voluptate aute consequat occaecat elit sint. Culpa magna mollit sit laborum et deserunt est sunt nostrud minim quis sint ea aliquip.\r\n",
      "engagementDescr": "Irure sint culpa occaecat exercitation anim mollit. Et anim ut sit in. Labore minim sunt dolor incididunt voluptate id duis consectetur enim incididunt. Occaecat fugiat quis consectetur proident aute velit qui proident id aliquip duis deserunt. Officia culpa consectetur deserunt excepteur culpa enim incididunt veniam.\r\n",
      "dataDescr": "Minim eiusmod amet exercitation tempor exercitation pariatur labore in. Cillum pariatur anim reprehenderit exercitation eiusmod incididunt do incididunt commodo laboris et tempor cupidatat. Cillum occaecat labore duis mollit magna culpa aliqua pariatur cupidatat.\r\n",
      "routeDescr": "Pariatur incididunt esse velit tempor cillum excepteur sunt sit qui adipisicing excepteur dolor. Dolor incididunt esse tempor aliqua. Minim voluptate nisi qui anim sit officia nulla ad anim esse dolore duis mollit esse. Amet sunt id irure qui amet nisi. Cillum ut ad in consectetur pariatur eu laboris proident cillum cupidatat do incididunt. Sunt sit excepteur proident qui commodo culpa exercitation fugiat excepteur. Eiusmod pariatur cillum sit minim reprehenderit laborum ipsum do in.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Stout Combs",
        "email": "stoutcombs@synkgen.com",
        "fte": 0.5876
      },
      {
        "id": 1,
        "name": "Velasquez Morse",
        "email": "velasquezmorse@synkgen.com",
        "fte": 0.9854
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Ward Trevino",
        "email": "wardtrevino@synkgen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 10,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 25,
        "y3": 97
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 10,
        "y3": 97
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8539426,
      "customerVal": 595240,
      "mvp": 50084256,
      "optimize": 1671460,
      "grow": 219660656,
      "scale": 562656167
    },
    "nextStepsActionPlan": "Aliquip excepteur minim quis eiusmod ea deserunt fugiat labore duis amet do laboris. Cupidatat magna reprehenderit ullamco qui veniam eu aute occaecat irure laborum veniam cupidatat mollit. Sunt enim adipisicing eiusmod elit laborum ad officia pariatur.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ex laborum mollit adipisicing esse. Ullamco officia ut ex officia in exercitation magna ad laboris laborum. Irure Lorem in do do deserunt consectetur magna veniam irure voluptate elit fugiat. Enim laborum do excepteur reprehenderit. Culpa ipsum sint Lorem elit laborum exercitation laborum esse irure in incididunt eu commodo.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-06",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e033d74effe99bd88bb",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Eschoir",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rr"
    ],
    "geography": "United States",
    "channel": "Offline: Pop up store",
    "division": "Division Three",
    "painPoint": "Veniam ipsum sit occaecat ea. Fugiat eu officia sint nisi veniam. Lorem non nisi nostrud labore aute culpa ut amet nisi. Laborum do cupidatat elit exercitation exercitation irure do. Lorem sint non occaecat proident proident dolor cillum. Nulla qui cillum enim nisi veniam dolore.\r\n",
    "description": "Magna qui occaecat aliqua aute exercitation ea Lorem consectetur mollit labore laborum ex. Amet commodo proident consectetur cupidatat. Anim sint ullamco cupidatat ullamco do proident tempor ullamco. Proident incididunt cupidatat anim irure pariatur eiusmod.\r\n",
    "strategy": "Enim voluptate laborum culpa qui culpa eu voluptate qui proident Lorem veniam voluptate sunt consectetur. Quis sunt dolor deserunt dolore eu exercitation. Elit et deserunt pariatur laboris nisi id commodo fugiat anim non. Consequat elit eu occaecat ullamco. Anim ea occaecat tempor eiusmod incididunt ullamco veniam qui aliqua fugiat culpa eiusmod culpa.\r\n",
    "keyLearnings": "Nisi magna pariatur non eu elit qui incididunt aute. Ut in consequat Lorem nulla nulla proident pariatur laboris esse. Proident id ut ut minim. Cillum eiusmod enim sit eiusmod reprehenderit do. Nisi duis voluptate aute in occaecat fugiat laboris sint laborum dolore dolore est ea. Dolor dolore esse ullamco ad amet eu esse reprehenderit occaecat.\r\n",
    "nextSteps": "Consequat esse sunt id cillum esse deserunt velit nisi ad qui officia sunt ullamco. Tempor sit ea labore commodo excepteur esse eiusmod aliquip ex magna ullamco qui veniam. Non voluptate ex ipsum do velit laboris ex qui minim exercitation amet commodo. Consequat dolore esse dolore fugiat pariatur ipsum. Cillum reprehenderit duis adipisicing cillum sunt tempor ex do consequat do minim occaecat et exercitation. Laborum ea proident id culpa qui anim do labore ad officia. Officia ut incididunt sit eu cillum irure sint ex quis aliqua ut dolore.\r\n",
    "investment": "Quis deserunt adipisicing cupidatat qui cillum dolore eu. Deserunt excepteur pariatur nulla ullamco ut labore sint commodo elit laboris excepteur incididunt eu non. Tempor ullamco qui duis qui ut ipsum. Nulla deserunt laboris ullamco pariatur pariatur aliquip do aliqua sit voluptate.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Excepteur aute fugiat nostrud consequat aliqua consequat. Esse esse velit voluptate minim cillum magna eu mollit ex nostrud sunt do amet. Reprehenderit amet ut non sint et voluptate aliqua proident ea.\r\n",
      "engagementDescr": "Tempor nulla tempor commodo Lorem ipsum pariatur. Ex id consequat nisi ullamco. Fugiat anim voluptate nulla sit ullamco ad anim. Sit tempor cupidatat est consectetur ex et cupidatat laboris. Proident fugiat fugiat et adipisicing excepteur.\r\n",
      "dataDescr": "Elit velit commodo reprehenderit tempor duis et cillum sint eiusmod enim amet aliqua. Enim excepteur aute tempor laboris ut do do quis commodo id nisi minim veniam. Aliquip non qui exercitation fugiat magna occaecat id quis ut irure incididunt. Consectetur amet anim labore velit excepteur quis laboris sint aliquip incididunt deserunt reprehenderit officia id. Veniam tempor tempor ad dolore adipisicing duis laboris laborum. Cillum amet amet adipisicing incididunt adipisicing in minim laboris.\r\n",
      "routeDescr": "Ad aliquip sit est officia esse. Voluptate aute nisi nulla non qui mollit ullamco consectetur deserunt deserunt ex pariatur. Cupidatat occaecat elit elit est Lorem in. Elit cillum nisi velit ullamco cillum officia officia ex reprehenderit dolor duis tempor. Laborum aliquip irure proident aliquip irure laboris deserunt mollit aliquip sit eu anim sunt. Minim et et mollit reprehenderit ipsum consequat eiusmod culpa. Ex ullamco eu cupidatat sit exercitation mollit in elit do ex excepteur dolore ullamco.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Farrell Valencia",
        "email": "farrellvalencia@eschoir.com",
        "fte": 0.2284
      },
      {
        "id": 1,
        "name": "Rose Kirk",
        "email": "rosekirk@eschoir.com",
        "fte": 0.0609
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Reba Duffy",
        "email": "rebaduffy@eschoir.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 13,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 42,
        "y3": 63
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 10,
        "y3": 58
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10564420,
      "customerVal": 1433639,
      "mvp": 109333616,
      "optimize": 3247827,
      "grow": 1197096394,
      "scale": 1193975527
    },
    "nextStepsActionPlan": "Amet amet irure dolore veniam minim consectetur officia velit enim. Enim in cillum sit non ipsum culpa officia velit ipsum aliqua est ut. Fugiat nostrud proident nulla Lorem tempor anim in sint occaecat. Lorem quis tempor dolore dolor do ut proident occaecat labore. Veniam non tempor laboris laborum ipsum aliquip do ipsum exercitation esse in incididunt. Et ad nulla commodo in exercitation dolore enim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Non ullamco Lorem nostrud aliquip exercitation eu dolor dolor. Quis commodo in proident proident mollit ea et culpa adipisicing ex irure. Id nulla laborum consequat aute esse est sit elit in. Elit aute ad culpa pariatur aliqua sunt. Ea laboris officia occaecat tempor pariatur incididunt. Eiusmod ea pariatur anim ad incididunt quis. Culpa irure cupidatat non eiusmod adipisicing.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0384b1c127c439533e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Jumpstack",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Online: Marketpace",
    "division": "Division One",
    "painPoint": "Ullamco tempor minim occaecat eiusmod. Excepteur qui culpa mollit sit aute cupidatat sit magna commodo nostrud non. Eiusmod ut et incididunt veniam ex. Commodo nostrud quis dolore ex velit occaecat exercitation nulla enim nulla amet. In ullamco exercitation aliqua eiusmod cillum commodo. Ad eu dolore eiusmod pariatur amet dolore dolore esse reprehenderit. Laboris ut sint ut incididunt occaecat ad anim sint cillum dolore eu consequat.\r\n",
    "description": "Sit commodo dolore incididunt tempor nostrud et exercitation ea. Et incididunt cupidatat eu Lorem minim dolor commodo dolor magna nulla duis deserunt. Et ullamco non amet ad veniam quis. Consequat commodo sit eu labore non officia reprehenderit tempor consectetur. Id pariatur ad in et proident velit tempor. Voluptate cupidatat commodo aliquip occaecat sit ipsum culpa. Esse fugiat commodo laborum amet enim id amet cupidatat.\r\n",
    "strategy": "Velit nostrud dolore magna ea laboris deserunt duis nulla dolor esse. Quis laboris do pariatur esse tempor sunt sit voluptate fugiat esse proident proident. Fugiat nostrud ea excepteur adipisicing fugiat. Officia reprehenderit amet qui eu cillum pariatur nostrud qui adipisicing irure. Sit ullamco in labore irure laboris ut consectetur incididunt reprehenderit sint. Magna voluptate nulla sunt minim adipisicing proident sint. Labore in enim mollit non proident exercitation eiusmod.\r\n",
    "keyLearnings": "Cillum minim veniam velit sunt dolore aute amet pariatur culpa. Enim nulla non duis eiusmod officia dolore adipisicing culpa. Ea enim veniam ut sit velit anim magna quis eiusmod enim. Adipisicing cillum mollit cupidatat nulla occaecat cupidatat ullamco fugiat eu do cillum ipsum. Veniam dolor irure cillum non veniam quis anim qui eiusmod ipsum officia. Proident fugiat ad non proident qui esse aliqua ea. Velit eu nisi ipsum elit commodo sint fugiat mollit non magna minim aute ullamco.\r\n",
    "nextSteps": "Eiusmod ex ex sint magna ullamco eiusmod reprehenderit reprehenderit deserunt ea et et. Enim est officia reprehenderit nostrud ex velit ad fugiat quis. Irure proident cillum dolore tempor ad exercitation irure et magna. Veniam irure voluptate eu tempor ut sint culpa cupidatat aliquip sit voluptate. Enim deserunt sunt in nulla quis ullamco eiusmod cupidatat sunt culpa commodo occaecat. Aute velit ullamco fugiat anim voluptate veniam incididunt eu enim. Sunt nulla voluptate esse cillum voluptate cillum proident proident.\r\n",
    "investment": "Labore cupidatat consectetur sint ullamco magna adipisicing officia aute non aliquip labore adipisicing deserunt. Ullamco aliqua laboris labore aute deserunt enim ad ex nulla duis nostrud. Anim duis duis ad ad non eiusmod et Lorem aliqua qui occaecat. Nostrud reprehenderit nulla non dolor culpa ea adipisicing ut duis aute culpa aute minim proident.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Elit adipisicing sint ex est. Minim enim ex minim nulla ullamco sint. Aliquip irure laboris nostrud nulla eiusmod incididunt laboris incididunt pariatur ut cillum officia cillum elit. Dolor dolor consequat do cillum do enim deserunt velit quis voluptate duis. Non sit sint anim consectetur voluptate ullamco ad occaecat consequat ut amet. Commodo eu eiusmod anim minim Lorem.\r\n",
      "engagementDescr": "Anim officia reprehenderit ad ullamco non irure tempor. Incididunt laboris nisi exercitation excepteur do eiusmod occaecat in ad. In esse sit proident dolor excepteur pariatur laborum. Qui nulla culpa minim est magna laborum pariatur ea. Ea consectetur dolore est ut quis id laboris pariatur ut.\r\n",
      "dataDescr": "In Lorem esse anim irure id nisi culpa commodo. Excepteur aliqua exercitation veniam consequat et elit laborum est et ex consequat cillum Lorem. Incididunt officia nostrud dolor id aute exercitation ea. Proident sunt tempor culpa nostrud consequat esse dolor amet Lorem.\r\n",
      "routeDescr": "Amet enim culpa proident officia ea velit ipsum. Esse pariatur eu aute dolore non proident ullamco duis. Aliquip deserunt ad quis anim. Qui in culpa Lorem Lorem ipsum ea proident aute. Non anim sunt nisi ex labore sunt excepteur quis mollit do. Commodo est ad pariatur anim sit excepteur ea pariatur fugiat.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Peggy Pace",
        "email": "peggypace@jumpstack.com",
        "fte": 0.3312
      },
      {
        "id": 1,
        "name": "Julie Holt",
        "email": "julieholt@jumpstack.com",
        "fte": 0.9243
      },
      {
        "id": 2,
        "name": "Hampton Kemp",
        "email": "hamptonkemp@jumpstack.com",
        "fte": 0.5851
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Alston Brady",
        "email": "alstonbrady@jumpstack.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 19,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 7,
        "y2": 47,
        "y3": 90
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 30,
        "y3": 83
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4775189,
      "customerVal": 1026669,
      "mvp": 145056779,
      "optimize": 14802418,
      "grow": 607118265,
      "scale": 789296886
    },
    "nextStepsActionPlan": "Qui ipsum et consectetur sint ullamco in dolore elit sint. Amet dolor eiusmod officia ipsum. Pariatur tempor aute excepteur id ea dolor excepteur commodo cupidatat cillum. Incididunt laboris enim consequat ut. Lorem laborum reprehenderit labore nostrud eu non ipsum exercitation officia veniam dolor ipsum eu dolor. Qui dolore ullamco enim sit quis dolore ullamco.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliquip consequat proident sit aliquip nisi exercitation eu irure ullamco enim. Non nisi et commodo veniam id exercitation est eiusmod consectetur voluptate ipsum nulla enim. Enim anim anim commodo eu. Eu aliquip exercitation ipsum enim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e031e2fa510c94544e7",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Bedder",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "China",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Minim est id aute est qui ullamco esse est enim dolore. Eu duis ad reprehenderit aliqua mollit elit do eiusmod commodo. Nisi duis eu ut veniam esse.\r\n",
    "description": "Ea pariatur pariatur voluptate excepteur cupidatat et eu. Ea consequat id mollit excepteur exercitation voluptate aute. Incididunt ullamco officia nulla ad magna pariatur incididunt pariatur deserunt ullamco fugiat voluptate. Pariatur ullamco laborum duis laborum occaecat nostrud anim nisi laborum exercitation aliqua commodo.\r\n",
    "strategy": "Dolore incididunt do reprehenderit magna duis amet Lorem mollit dolore nisi reprehenderit dolor est do. Excepteur irure voluptate quis eiusmod aliquip ullamco magna. Anim enim do in velit cupidatat magna sunt eu quis est. Labore adipisicing quis cupidatat labore elit cupidatat. Excepteur cupidatat ad nisi do.\r\n",
    "keyLearnings": "Elit Lorem fugiat esse enim velit incididunt eiusmod excepteur. Labore labore proident tempor magna cillum aute ipsum in minim magna labore exercitation est. Anim occaecat ad elit fugiat pariatur labore pariatur mollit aliquip.\r\n",
    "nextSteps": "Eiusmod culpa labore officia et adipisicing occaecat aliqua cillum enim. Velit eu qui irure exercitation velit velit amet. Eiusmod nisi culpa Lorem do sunt quis cillum minim veniam. Commodo duis in sit proident excepteur sint id dolor sint. Aliqua consequat sit anim velit occaecat quis laborum dolor. Adipisicing laboris aliqua non nulla tempor laboris officia velit eiusmod veniam tempor eiusmod laborum deserunt. Incididunt labore nulla non proident amet nulla nisi elit pariatur dolore ullamco laborum velit.\r\n",
    "investment": "Commodo elit qui sit proident elit ut nulla aliqua non anim est culpa. Ut qui aliqua do culpa reprehenderit nulla. Est nulla officia fugiat do veniam veniam consectetur proident est. Dolor anim eiusmod tempor elit sint cupidatat. Ad reprehenderit tempor reprehenderit irure ea commodo dolor cupidatat aute nisi exercitation labore. Lorem qui duis exercitation ad reprehenderit minim commodo consectetur eu et incididunt dolor velit.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Tempor magna laboris exercitation ut enim. Aute incididunt dolore sit ad do pariatur eu id. Ipsum voluptate commodo Lorem magna.\r\n",
      "engagementDescr": "Aliqua reprehenderit dolor elit fugiat dolore ipsum sint sit voluptate. Ex esse veniam est labore nulla ipsum exercitation nostrud sunt sunt est occaecat elit laborum. Commodo consectetur commodo exercitation deserunt sint et Lorem reprehenderit et quis. Nulla cillum occaecat et incididunt ea.\r\n",
      "dataDescr": "In ex labore mollit eu. Officia minim incididunt aliquip amet sint ex deserunt eu ad qui dolore fugiat quis nisi. Eu cupidatat sit nisi aliqua veniam minim deserunt tempor exercitation minim laborum esse tempor ad. Non do adipisicing consequat exercitation proident sit culpa eiusmod ipsum laborum magna in duis. Labore nulla incididunt sunt voluptate deserunt ullamco.\r\n",
      "routeDescr": "Adipisicing dolore est laboris exercitation commodo consectetur esse aliqua dolor amet ex ex non laborum. Laboris ad ea duis tempor adipisicing duis consectetur quis id laborum ad aute. Duis quis anim laborum minim duis laboris nulla velit non nulla est eiusmod fugiat laborum. Laborum nulla exercitation irure consequat ex sunt laboris adipisicing do. Mollit irure laboris aliqua pariatur aliqua incididunt. Officia ullamco eu quis et amet occaecat.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Morgan Roth",
        "email": "morganroth@bedder.com",
        "fte": 0.5687
      },
      {
        "id": 1,
        "name": "Adeline Lott",
        "email": "adelinelott@bedder.com",
        "fte": 0.8223
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Puckett Macdonald",
        "email": "puckettmacdonald@bedder.com"
      },
      {
        "id": 1,
        "name": "Lesa Mcbride",
        "email": "lesamcbride@bedder.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 15,
        "y3": 20
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 55,
        "y3": 100
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 13,
        "y3": 98
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1455636,
      "customerVal": 1289194,
      "mvp": 116391474,
      "optimize": 21257,
      "grow": 790430591,
      "scale": 258451397
    },
    "nextStepsActionPlan": "Nisi ullamco id cillum aliquip eiusmod Lorem ullamco commodo cupidatat ipsum tempor sit quis. Lorem est aute laborum ea. Labore exercitation id reprehenderit dolore aliquip. Adipisicing qui enim id ipsum labore ullamco magna mollit voluptate adipisicing. Eiusmod deserunt consequat consectetur cillum anim exercitation deserunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ullamco esse ut esse ut commodo esse eu eu. Id laboris sunt quis in esse fugiat esse. Excepteur adipisicing cillum amet sit in veniam ea ullamco. Tempor voluptate aliquip labore incididunt et. Proident voluptate officia proident do.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-11",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c18debe1f822a0d0",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Bleeko",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Online: Home delivery",
    "division": "Division One",
    "painPoint": "Et dolor dolor tempor commodo eiusmod fugiat nisi consectetur deserunt laborum. Ipsum aliquip ipsum nisi aliquip irure cillum quis et adipisicing. Consectetur fugiat id consequat ad commodo elit mollit amet nulla ea. Nostrud ut proident deserunt adipisicing duis aliqua cillum aliquip duis nulla. Pariatur proident nostrud excepteur veniam elit exercitation ut. Lorem qui ex consectetur deserunt. Duis laborum occaecat aliquip aute pariatur.\r\n",
    "description": "Nostrud sint anim laborum nulla proident incididunt fugiat et aliqua dolore id do nulla. Ipsum nulla duis excepteur esse ut labore. Tempor anim laboris sunt reprehenderit Lorem occaecat cillum duis quis mollit. Qui ut proident labore nulla est eiusmod officia cillum commodo. Nulla proident esse laborum incididunt nulla id proident ullamco excepteur aliquip reprehenderit.\r\n",
    "strategy": "Nulla dolor pariatur tempor laborum qui magna pariatur labore qui adipisicing ullamco do. Excepteur consequat velit Lorem officia sint veniam fugiat cillum ipsum magna cillum sit amet. Velit reprehenderit duis amet occaecat laborum dolore nostrud Lorem deserunt ipsum occaecat. Eu non mollit cillum cillum cillum officia.\r\n",
    "keyLearnings": "Id occaecat qui magna nulla adipisicing id aliqua aute dolor occaecat labore. Laboris adipisicing fugiat officia mollit eu nisi sit quis laborum velit. Fugiat irure minim veniam fugiat in excepteur excepteur mollit.\r\n",
    "nextSteps": "Sit excepteur laboris aliquip non exercitation cillum sit consequat tempor anim ut ad. Adipisicing elit magna sint ullamco ipsum ea deserunt ex velit. Qui minim nostrud ullamco cupidatat ex nulla aliquip adipisicing anim amet. Consequat labore incididunt nisi irure cupidatat ullamco labore irure ullamco. Commodo fugiat dolor nostrud labore pariatur reprehenderit esse anim ex non consequat magna.\r\n",
    "investment": "Laboris laboris deserunt commodo cupidatat eu nulla ullamco magna sint. Id excepteur quis eu do. Sit exercitation fugiat eiusmod laborum voluptate consectetur. Anim reprehenderit est aliquip ea do deserunt.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Tempor irure commodo labore laborum nulla eu ut cillum laboris occaecat eu labore. Est pariatur commodo deserunt officia dolor Lorem cupidatat. Id non ea eu enim. Occaecat voluptate aliqua Lorem sunt commodo et do tempor esse ut ad. Mollit proident elit voluptate laborum proident aliquip exercitation velit proident. Labore eiusmod consectetur anim in eiusmod aliqua sunt nulla.\r\n",
      "engagementDescr": "Qui adipisicing id enim esse. Eu dolor ullamco labore deserunt dolore tempor occaecat. Elit consequat et aliqua duis. Id occaecat consectetur ipsum sit esse anim.\r\n",
      "dataDescr": "Magna sunt exercitation ex consectetur eu mollit nisi qui elit. Eiusmod mollit enim veniam cupidatat esse excepteur cupidatat cupidatat aute ullamco. Lorem labore cupidatat commodo anim exercitation Lorem irure laboris reprehenderit est. Cupidatat sunt nostrud excepteur ex incididunt ex velit ex cupidatat sit exercitation enim nisi.\r\n",
      "routeDescr": "Magna officia reprehenderit duis minim dolore est amet duis in ut Lorem dolor. Ipsum sunt ex mollit aliqua sit occaecat ipsum excepteur eiusmod non id aute aute ullamco. Enim ex ipsum do sit quis mollit aliqua. Et dolor aute irure anim id. Quis consectetur voluptate aute proident est nisi fugiat laboris mollit occaecat. Aliquip adipisicing proident velit Lorem incididunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Nieves Hughes",
        "email": "nieveshughes@bleeko.com",
        "fte": 0.8813
      },
      {
        "id": 1,
        "name": "Rollins Larson",
        "email": "rollinslarson@bleeko.com",
        "fte": 0.32
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Marissa Evans",
        "email": "marissaevans@bleeko.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 16,
        "y3": 20
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 7,
        "y2": 22,
        "y3": 60
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 12,
        "y3": 46
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14932959,
      "customerVal": 153223,
      "mvp": 135086507,
      "optimize": 5766255,
      "grow": 709805129,
      "scale": 911155791
    },
    "nextStepsActionPlan": "Ex do cillum sunt eiusmod culpa ea reprehenderit laborum proident eu nulla cupidatat consectetur mollit. Est anim amet in nostrud consequat commodo minim ipsum nisi pariatur consequat magna. Nostrud excepteur elit veniam nisi anim elit esse. Proident culpa ea exercitation dolore sunt. Ad aliquip voluptate consequat commodo ex et exercitation nulla esse proident adipisicing labore sunt. Est irure pariatur occaecat incididunt eiusmod ad nulla commodo commodo non aliqua excepteur. Mollit cupidatat amet id nisi aute aliquip sit mollit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Eu duis veniam ut est incididunt irure est qui proident eiusmod ipsum Lorem. Et qui nisi cillum labore quis tempor. Sint tempor fugiat elit in exercitation commodo aliquip nisi dolore do occaecat veniam. Consequat anim ea quis aliquip velit eiusmod sit cupidatat minim ullamco eiusmod ad laborum commodo. Aliqua deserunt aliquip aute excepteur. Sit sit in amet incididunt non Lorem cillum nostrud esse.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0388e71de50e7680d1",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Isotronic",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rr"
    ],
    "geography": "Spain",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Laboris magna incididunt pariatur aliqua aliqua esse dolore deserunt nisi enim ad sit eiusmod. Consectetur laboris do consequat do sint consequat quis. Deserunt do tempor laboris officia. Labore dolore fugiat laboris cupidatat. Ipsum ea et ullamco voluptate cillum velit exercitation cillum nulla magna pariatur.\r\n",
    "description": "Exercitation non magna Lorem ad labore. In sint tempor id proident qui consequat enim culpa commodo qui. Labore adipisicing pariatur ullamco nostrud fugiat ipsum esse adipisicing dolor occaecat anim. Nisi anim culpa id culpa tempor tempor in quis id elit laboris ex. Exercitation ad esse aute anim sint est pariatur est cillum ex. Id culpa enim ut aliquip proident reprehenderit tempor aliquip Lorem velit. Dolore sit eu duis duis reprehenderit sint ea qui proident.\r\n",
    "strategy": "Incididunt veniam magna aliqua mollit incididunt aliqua Lorem voluptate adipisicing voluptate do cillum aliqua proident. Mollit eiusmod sunt laboris aliqua voluptate non eiusmod aute deserunt. Proident consectetur dolore non pariatur fugiat commodo.\r\n",
    "keyLearnings": "Nisi anim id occaecat sit cupidatat aliqua pariatur id sunt. Pariatur nisi Lorem labore incididunt ex esse fugiat voluptate enim. Ullamco consequat pariatur elit officia mollit nostrud velit veniam ipsum fugiat non id.\r\n",
    "nextSteps": "Culpa ex culpa quis cillum consectetur pariatur qui. Aliqua est nostrud non nulla magna mollit ad consectetur nisi reprehenderit. Culpa mollit qui officia esse sunt adipisicing. Tempor ut commodo laboris eiusmod laboris esse sit exercitation nulla aliquip aliquip consectetur voluptate. Duis occaecat laborum et pariatur.\r\n",
    "investment": "Nulla minim ullamco ad commodo duis nostrud enim in reprehenderit qui quis velit nulla. Consequat duis magna voluptate nulla ipsum eiusmod. Sit ut quis sint officia labore quis in ut in nostrud aute consequat.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Culpa ad amet eiusmod magna esse qui pariatur culpa ex ullamco dolor amet tempor irure. Proident dolore ipsum elit ad sit ea consequat ad occaecat. Qui culpa occaecat voluptate sit. Ullamco enim veniam ex non aute mollit nisi nulla.\r\n",
      "engagementDescr": "Amet sunt ut occaecat et culpa consectetur veniam reprehenderit do cillum duis officia officia. Cupidatat elit aliqua ipsum aliqua. Laborum ullamco in ut est. Incididunt culpa velit velit occaecat eu enim laborum labore sint sunt duis incididunt voluptate.\r\n",
      "dataDescr": "Ut elit quis ipsum laborum velit dolore amet nostrud et voluptate veniam enim sit. Quis aliqua occaecat ex magna ipsum eu ullamco duis ex. Pariatur velit incididunt fugiat quis irure consequat irure tempor. Nostrud do aliquip laborum enim incididunt ex ut anim labore pariatur. Labore eiusmod laborum duis ut est adipisicing velit proident nisi dolore do.\r\n",
      "routeDescr": "Cillum exercitation anim sit cupidatat deserunt anim mollit ipsum consectetur. Voluptate eiusmod ad duis labore exercitation eu id consectetur. Qui aute aute dolor est sunt laborum enim commodo consectetur mollit fugiat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Esmeralda Baker",
        "email": "esmeraldabaker@isotronic.com",
        "fte": 0.297
      },
      {
        "id": 1,
        "name": "Malinda Lee",
        "email": "malindalee@isotronic.com",
        "fte": 0.842
      },
      {
        "id": 2,
        "name": "Graves Bradley",
        "email": "gravesbradley@isotronic.com",
        "fte": 0.891
      },
      {
        "id": 3,
        "name": "Marcie Stein",
        "email": "marciestein@isotronic.com",
        "fte": 0.3071
      },
      {
        "id": 4,
        "name": "Regina Chan",
        "email": "reginachan@isotronic.com",
        "fte": 0.2144
      },
      {
        "id": 5,
        "name": "Margret Kaufman",
        "email": "margretkaufman@isotronic.com",
        "fte": 0.8059
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Weber Rosario",
        "email": "weberrosario@isotronic.com"
      },
      {
        "id": 1,
        "name": "Foster Velasquez",
        "email": "fostervelasquez@isotronic.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 17,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 10,
        "y2": 45,
        "y3": 51
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 10,
        "y3": 63
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12970016,
      "customerVal": 1054332,
      "mvp": 41749176,
      "optimize": 10532960,
      "grow": 858126533,
      "scale": 659694371
    },
    "nextStepsActionPlan": "Voluptate irure quis mollit sunt eiusmod do veniam veniam duis deserunt id ea. Dolore deserunt mollit ad elit mollit. Duis labore duis aliquip irure veniam voluptate id cupidatat quis enim consectetur occaecat ea. Eu est sunt culpa in eiusmod labore qui nostrud amet proident ipsum tempor et. Ullamco Lorem ullamco anim irure nostrud Lorem excepteur exercitation magna mollit labore et. Aliqua dolore Lorem occaecat sint enim officia voluptate voluptate magna officia nulla sint aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Magna duis nulla dolor excepteur elit deserunt laborum laboris. Velit consectetur esse mollit laboris nulla aliquip culpa cillum. Pariatur excepteur velit ex incididunt. Ut pariatur pariatur aliquip commodo pariatur voluptate in eu irure magna ut cupidatat eiusmod. Minim ex irure aliqua proident officia sint. Consectetur velit sunt ad elit magna sit in irure adipisicing dolor reprehenderit fugiat eu cillum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-31",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e030c0e61dad1e80225",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Geekko",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "Italy",
    "channel": "Online: Digital distributor",
    "division": "Division One",
    "painPoint": "Eu anim deserunt nulla cillum adipisicing elit exercitation aute do amet ea. Enim amet veniam eiusmod ex eu dolore nostrud elit aliquip dolore est commodo ex mollit. Commodo elit proident deserunt sunt culpa duis elit culpa ullamco commodo.\r\n",
    "description": "Consectetur aute magna laboris consectetur in ad reprehenderit cillum quis tempor sit deserunt. Eu in eiusmod dolor id laboris aliqua culpa quis reprehenderit ex tempor sunt. Exercitation mollit occaecat ad culpa excepteur consectetur laboris. Est dolore duis enim sunt. Occaecat qui aliquip nostrud Lorem laboris mollit laboris adipisicing velit excepteur magna mollit fugiat.\r\n",
    "strategy": "Tempor in elit in consectetur esse reprehenderit. Nostrud velit minim esse ea aute. Ex consequat quis sit magna ex. Aliquip est voluptate consectetur nostrud voluptate culpa. Consequat tempor amet Lorem voluptate ullamco in qui tempor. Ullamco dolore in nulla duis duis nulla esse sunt tempor aliquip reprehenderit fugiat. Consequat cupidatat proident adipisicing et eu consectetur eiusmod elit ea.\r\n",
    "keyLearnings": "Laborum nisi laborum excepteur cillum ut consectetur est ut ex reprehenderit enim minim dolore. Qui do ullamco minim minim. Eiusmod proident consectetur amet amet culpa qui duis officia elit nulla velit elit non do. Adipisicing ea nulla tempor culpa excepteur do officia nisi ad ipsum occaecat ad. Enim nulla in est culpa qui sit laboris aute in et quis et. Cillum nostrud labore nisi dolor. Nulla excepteur Lorem proident nostrud labore fugiat do occaecat.\r\n",
    "nextSteps": "Anim Lorem est proident nulla dolor laboris occaecat magna id amet minim. Labore ullamco eiusmod ipsum in nulla in. Pariatur ex consequat pariatur id veniam fugiat incididunt exercitation aute mollit elit non. Irure ea id excepteur culpa.\r\n",
    "investment": "Reprehenderit pariatur adipisicing magna magna ex do. Ut do laborum dolor mollit veniam minim. Amet incididunt adipisicing esse ad non nostrud in deserunt consequat ex voluptate nostrud. Occaecat non amet do sint incididunt nulla laborum. Enim nulla enim nulla quis tempor tempor. Consequat aliqua nisi nisi velit ex consectetur fugiat. Do anim sunt tempor adipisicing minim laborum in non est Lorem.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Proident est id veniam aute. Anim nostrud irure esse commodo. Enim anim exercitation aliquip nulla tempor nostrud aliqua adipisicing minim. Nulla commodo mollit ea enim aliqua laborum incididunt commodo enim culpa sit aliquip excepteur. Lorem amet et laboris commodo excepteur eiusmod. Dolore tempor labore culpa occaecat amet adipisicing ad.\r\n",
      "engagementDescr": "Minim adipisicing fugiat Lorem proident consectetur dolor veniam nostrud. Culpa irure irure fugiat voluptate consectetur excepteur incididunt Lorem sint esse est Lorem cillum. Culpa aliquip nisi officia tempor cupidatat non cillum consectetur in officia anim officia. Ut cillum veniam labore velit veniam non magna sit adipisicing consectetur labore est. Sunt eu labore est cillum occaecat. Fugiat non commodo dolore ex ea consequat voluptate voluptate commodo consequat in. Aliqua deserunt nulla amet excepteur.\r\n",
      "dataDescr": "Occaecat laboris nisi ex deserunt duis anim officia adipisicing incididunt cillum. In occaecat nostrud ex ut adipisicing nisi mollit magna labore fugiat ipsum. Proident minim nulla deserunt quis irure. Minim deserunt est magna quis amet proident reprehenderit mollit.\r\n",
      "routeDescr": "Sit non incididunt aliqua ex id amet labore adipisicing. Commodo aliquip pariatur minim ea dolor occaecat ad ex. Lorem veniam ex adipisicing enim magna. Velit cillum mollit cupidatat veniam ea velit sunt ut ad eu sint.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "George Ware",
        "email": "georgeware@geekko.com",
        "fte": 0.3713
      },
      {
        "id": 1,
        "name": "Wiggins Cantrell",
        "email": "wigginscantrell@geekko.com",
        "fte": 0.6602
      },
      {
        "id": 2,
        "name": "Briana Copeland",
        "email": "brianacopeland@geekko.com",
        "fte": 0.4096
      },
      {
        "id": 3,
        "name": "Queen Bartlett",
        "email": "queenbartlett@geekko.com",
        "fte": 0.4979
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Anthony Salas",
        "email": "anthonysalas@geekko.com"
      },
      {
        "id": 1,
        "name": "Dawson Larsen",
        "email": "dawsonlarsen@geekko.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 8,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 7,
        "y2": 31,
        "y3": 50
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 18,
        "y3": 86
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3148126,
      "customerVal": 734807,
      "mvp": 91954079,
      "optimize": 3091222,
      "grow": 981255781,
      "scale": 155673540
    },
    "nextStepsActionPlan": "Consequat duis nostrud officia velit ipsum irure ad proident ipsum veniam excepteur voluptate. Occaecat commodo anim nulla minim est voluptate irure eu adipisicing do. Anim quis ut excepteur non adipisicing cillum aliquip ipsum tempor. Tempor eiusmod Lorem exercitation id voluptate anim nisi. Dolor et consequat officia dolore sunt laborum quis.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Proident nisi cupidatat nulla ullamco duis reprehenderit non dolor sint elit ipsum magna sunt. Deserunt magna exercitation ipsum irure qui ea magna fugiat eiusmod culpa magna ipsum. Mollit non eu eu sint adipisicing pariatur eiusmod officia ut sit et tempor esse irure. Ex in nostrud in ea voluptate Lorem amet proident ut sint Lorem. Nostrud ex veniam ullamco consequat et do. Enim labore nisi culpa ad ut sunt exercitation ipsum exercitation incididunt culpa anim. Consectetur sit velit cillum labore est occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-07",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c345171cc28c6f47",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Zyple",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "China",
    "channel": "Online: Flagship store",
    "division": "Division One",
    "painPoint": "Minim sint ad veniam eu laboris in. Cupidatat labore nisi tempor magna tempor non minim aliqua. Laborum fugiat aliqua Lorem proident tempor eiusmod cupidatat. Qui ut aliquip exercitation enim. In ut magna nisi ad voluptate ad irure cillum do. Nulla id Lorem esse irure excepteur esse eiusmod ea velit velit consectetur laborum nostrud consequat.\r\n",
    "description": "Excepteur dolore dolore aliquip cillum. Dolor elit occaecat proident non nulla. Sint nostrud ex eu labore nisi amet anim enim. Duis et dolore aliqua ut ullamco Lorem.\r\n",
    "strategy": "Duis officia eiusmod excepteur do ipsum nulla cillum laboris id ipsum velit laborum magna. Excepteur irure incididunt pariatur occaecat cupidatat do labore adipisicing duis deserunt. Deserunt esse eu ullamco quis veniam exercitation anim aliqua esse do. Est tempor cillum mollit ullamco incididunt ut qui mollit ex aliqua officia et adipisicing esse. Velit aliqua do occaecat tempor voluptate aute esse officia tempor nulla.\r\n",
    "keyLearnings": "Tempor esse irure aute deserunt et aute veniam pariatur magna tempor. Exercitation tempor deserunt velit est. Laborum est non esse fugiat nostrud proident. Adipisicing cillum non ea magna non eu labore. Mollit esse tempor exercitation Lorem cupidatat irure elit excepteur ad fugiat fugiat ad sint. Et officia ad incididunt enim do magna exercitation minim duis nisi anim sunt.\r\n",
    "nextSteps": "Incididunt proident proident dolor irure officia aliquip magna proident fugiat cillum consectetur veniam ullamco. Sint elit excepteur id consequat esse ex dolore. Enim consequat aute exercitation consectetur ut aliqua pariatur sit. Non enim nisi culpa et. Nostrud ea et quis eu aliqua ad labore. Sint pariatur reprehenderit excepteur ea.\r\n",
    "investment": "Minim ut in ut sunt officia deserunt eiusmod. Ut exercitation reprehenderit fugiat proident ad est cillum. Fugiat magna sint qui exercitation anim sit anim occaecat duis consequat culpa ipsum.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "In ex ex exercitation laboris cupidatat. Quis eu Lorem proident aliqua sunt mollit elit aute nostrud. Do quis non incididunt esse eiusmod qui anim nisi et nostrud ullamco amet. Id laborum sit elit labore aute Lorem ut amet velit consectetur aute enim elit.\r\n",
      "engagementDescr": "Cupidatat est excepteur exercitation cillum irure consequat ullamco magna aliqua voluptate. Sit anim esse commodo sunt est eiusmod qui minim deserunt dolore reprehenderit ipsum ad anim. Ipsum et adipisicing ea enim dolor qui elit laborum minim ullamco ut fugiat in sint. Est elit irure quis ut labore ea ipsum velit laborum et non.\r\n",
      "dataDescr": "Et dolor irure ipsum adipisicing sit esse tempor reprehenderit Lorem deserunt Lorem sit labore. Velit do cupidatat tempor aliqua et tempor. Elit id ex incididunt ut duis ullamco in. Incididunt aliqua nulla ea fugiat enim consequat. Ad labore incididunt sint voluptate amet id in officia. Consectetur do amet enim anim nostrud ut commodo reprehenderit non. Ea id fugiat laboris do non ea minim ad.\r\n",
      "routeDescr": "Et veniam esse mollit irure dolor sit id officia occaecat fugiat. Fugiat esse quis nisi cillum quis tempor ipsum ea et tempor et quis consectetur mollit. Dolor nostrud qui proident adipisicing incididunt. Incididunt nisi nostrud dolor anim nisi adipisicing dolore. Officia cupidatat cupidatat laborum sint sunt enim dolor sit. Reprehenderit consequat qui ullamco excepteur esse dolore ex culpa.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Sims Diaz",
        "email": "simsdiaz@zyple.com",
        "fte": 0.2911
      },
      {
        "id": 1,
        "name": "Pratt Goodman",
        "email": "prattgoodman@zyple.com",
        "fte": 0.8772
      },
      {
        "id": 2,
        "name": "Beasley Wagner",
        "email": "beasleywagner@zyple.com",
        "fte": 0.8658
      },
      {
        "id": 3,
        "name": "Gutierrez Knowles",
        "email": "gutierrezknowles@zyple.com",
        "fte": 0.1678
      },
      {
        "id": 4,
        "name": "Luz Black",
        "email": "luzblack@zyple.com",
        "fte": 0.3726
      },
      {
        "id": 5,
        "name": "Baxter Carney",
        "email": "baxtercarney@zyple.com",
        "fte": 0.1873
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Giles Houston",
        "email": "gileshouston@zyple.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 9,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 23,
        "y3": 57
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 24,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6157928,
      "customerVal": 1043839,
      "mvp": 79083706,
      "optimize": 2264276,
      "grow": 1056341722,
      "scale": 400312156
    },
    "nextStepsActionPlan": "Nulla qui anim id incididunt non. Ipsum amet enim elit tempor ea non ipsum reprehenderit dolore sit esse eu laborum ea. Consectetur excepteur laboris quis dolore deserunt pariatur consectetur ad amet fugiat. Mollit anim irure esse sunt eu qui duis cupidatat incididunt. Et magna ex veniam ipsum aliquip cillum cillum id deserunt qui.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis ut magna aute laborum consequat enim duis. Est est excepteur cupidatat ullamco duis esse ad officia ipsum. Dolore reprehenderit labore et duis enim sunt proident. Aute sit et nulla laborum elit ut ullamco eiusmod velit non consectetur ullamco deserunt qui. Duis incididunt occaecat Lorem mollit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-18",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e034a11a1922ee56e28",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Corecom",
    "highlightKPI": [
      "cac",
      "ltv",
      "rr"
    ],
    "geography": "China",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Reprehenderit enim consequat aute pariatur esse proident quis. Ullamco duis sint magna et proident aliquip laboris officia laborum qui eu proident proident veniam. Commodo incididunt elit labore reprehenderit pariatur sint pariatur deserunt qui velit tempor dolore pariatur proident. Ipsum mollit nostrud enim esse minim laboris Lorem incididunt enim do magna magna nostrud id. In consequat eiusmod duis pariatur reprehenderit sit adipisicing dolore.\r\n",
    "description": "Ut anim exercitation nostrud laboris minim excepteur sit mollit consequat aute cillum in consequat. Officia nisi nisi aute sit deserunt dolore quis reprehenderit quis duis. Cillum ut commodo fugiat laboris. Anim pariatur laboris pariatur elit deserunt adipisicing sint. Est velit deserunt in nulla labore elit velit excepteur. Ipsum ut excepteur esse nulla voluptate velit duis laborum dolore. In veniam amet nisi ut magna amet enim quis officia adipisicing ipsum.\r\n",
    "strategy": "Excepteur non fugiat ullamco non aliqua anim cillum esse dolor enim irure Lorem irure. Incididunt laborum ea pariatur consequat labore sint nostrud excepteur magna elit dolor non adipisicing excepteur. Anim deserunt magna amet pariatur Lorem culpa excepteur Lorem ea. Et ullamco et velit cupidatat deserunt. Sit commodo labore fugiat nulla irure pariatur sit ea Lorem ea nisi. Exercitation Lorem anim labore occaecat ipsum culpa ullamco cillum Lorem ea.\r\n",
    "keyLearnings": "Nostrud ullamco anim enim proident nisi exercitation nostrud nostrud mollit. Duis adipisicing esse et do veniam tempor voluptate laboris labore mollit irure sit. Elit minim in et ullamco id anim. Anim deserunt voluptate et ea minim in sit id. Incididunt eiusmod commodo ea fugiat deserunt cillum est anim elit minim ad eu. Consectetur esse sit consequat qui ipsum officia sint ea.\r\n",
    "nextSteps": "Ullamco ea deserunt voluptate fugiat irure dolor amet dolore cillum in reprehenderit irure cillum dolore. Tempor nisi ea exercitation in incididunt nulla commodo non elit. Dolore tempor adipisicing irure velit dolor deserunt ullamco magna. Ut ea sint irure culpa in sint nisi aliqua reprehenderit. Duis minim sunt ex qui aute aute non eu anim voluptate. Sunt exercitation nostrud consequat nostrud quis sit sunt.\r\n",
    "investment": "Veniam aliquip sint ut duis minim laborum aliquip. Aliquip irure labore nulla aliquip eiusmod velit cupidatat anim ex deserunt incididunt anim id voluptate. Nulla deserunt do pariatur nulla magna cupidatat adipisicing. Qui cillum labore dolore aute Lorem sint reprehenderit do velit fugiat. Quis aute ipsum et ea duis. Aute ea nulla consectetur minim enim aliqua culpa dolor ea. Consectetur Lorem exercitation ipsum cupidatat exercitation ut cupidatat laborum aliquip.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Sit ad culpa duis laboris proident laborum ut. Pariatur velit magna incididunt do magna sunt elit labore pariatur. Velit voluptate dolor eiusmod commodo tempor dolor est ullamco anim officia duis dolore incididunt aute. Commodo in Lorem ipsum consectetur ex nostrud reprehenderit sunt ea aliqua dolore adipisicing ut ipsum. Voluptate est nisi irure sit velit sint consectetur laborum ullamco Lorem mollit ipsum elit.\r\n",
      "engagementDescr": "Consequat voluptate nisi mollit elit aliquip amet in do nulla ut proident. Quis ut dolor duis proident officia elit eiusmod laborum pariatur commodo ea. Eu mollit tempor non irure commodo nulla deserunt officia dolore eiusmod. Incididunt excepteur pariatur pariatur non voluptate occaecat deserunt eiusmod irure incididunt.\r\n",
      "dataDescr": "Id Lorem minim excepteur nisi eiusmod veniam quis pariatur aliqua velit. Qui do dolore laborum minim dolore sit labore occaecat enim duis culpa Lorem adipisicing. Cillum do eiusmod ullamco eu. Do consectetur nisi voluptate laboris. Est irure magna incididunt mollit laborum laboris. Enim sit qui et quis sit nostrud cupidatat esse et consequat ullamco et.\r\n",
      "routeDescr": "In esse ullamco sint cupidatat nisi excepteur labore dolore labore. Deserunt esse amet nostrud aliqua nulla qui ex nulla. Labore sint dolor minim est excepteur consectetur. Aliqua nulla commodo cupidatat quis aliquip et ut aliqua amet sit. Consectetur anim magna sit consequat exercitation consectetur irure esse nostrud ipsum cupidatat eiusmod laborum ullamco. Sunt laboris amet aliquip labore aute ad sint. Velit anim esse et irure proident elit minim sunt.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Berta Raymond",
        "email": "bertaraymond@corecom.com",
        "fte": 0.2125
      },
      {
        "id": 1,
        "name": "Lara Fulton",
        "email": "larafulton@corecom.com",
        "fte": 0.1614
      },
      {
        "id": 2,
        "name": "Kara Myers",
        "email": "karamyers@corecom.com",
        "fte": 0.0683
      },
      {
        "id": 3,
        "name": "Cassandra Brennan",
        "email": "cassandrabrennan@corecom.com",
        "fte": 0.8992
      },
      {
        "id": 4,
        "name": "Sandoval Dickson",
        "email": "sandovaldickson@corecom.com",
        "fte": 0.3201
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Anastasia Walls",
        "email": "anastasiawalls@corecom.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 12,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 20,
        "y3": 65
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 10,
        "y3": 63
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12739775,
      "customerVal": 708948,
      "mvp": 31439237,
      "optimize": 5149305,
      "grow": 1418308419,
      "scale": 600017803
    },
    "nextStepsActionPlan": "Occaecat consequat aliqua ex amet fugiat. Excepteur pariatur eu amet est aliqua ea enim exercitation velit laborum deserunt id velit pariatur. Enim officia ullamco eiusmod nisi voluptate occaecat et deserunt magna officia tempor do laboris ex. Cillum minim sit aliquip id voluptate labore. Veniam cupidatat eu velit et in cupidatat cupidatat laborum magna velit sint exercitation. Dolor nisi commodo elit in enim magna mollit tempor. Laboris ullamco elit amet consectetur proident sunt ullamco excepteur pariatur nisi.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Id esse consectetur duis in aliqua excepteur. Sunt incididunt adipisicing eiusmod laboris irure sit. Do ad veniam ullamco fugiat velit veniam esse quis quis culpa fugiat laborum. Laborum ad occaecat ad excepteur Lorem magna laborum dolor eiusmod voluptate nulla. Anim consequat ea id in.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c564abf5268e57bd",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Collaire",
    "highlightKPI": [
      "cac",
      "ltv",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Minim velit nulla eu laborum sint consectetur adipisicing esse in enim. Eiusmod incididunt est reprehenderit est quis dolore ea quis sunt dolore. Commodo ut in anim nisi Lorem eiusmod mollit reprehenderit tempor. Dolor eiusmod ad labore sint ut labore dolore ullamco fugiat amet. Occaecat dolore mollit voluptate laborum sint est exercitation sint dolor proident. Proident velit exercitation voluptate et fugiat nisi ad aute aliqua laborum proident dolore.\r\n",
    "description": "Consequat dolor veniam eu labore quis cillum est ullamco. Sit ut aliqua Lorem nisi irure esse laborum consectetur duis consequat amet qui laborum non. Velit ex enim aliqua enim laboris ut.\r\n",
    "strategy": "Sint dolor mollit id magna proident enim. Cupidatat officia do aute eu amet ad do tempor enim Lorem laboris nulla ex. Labore eiusmod irure velit reprehenderit sunt culpa qui quis id elit labore fugiat excepteur laboris. Ut minim nulla cillum fugiat officia minim excepteur excepteur consequat nisi ipsum labore qui. Anim esse consequat aliqua deserunt excepteur ad et deserunt. Occaecat tempor do cillum laborum dolore eiusmod sunt exercitation reprehenderit. Dolor esse ullamco commodo fugiat.\r\n",
    "keyLearnings": "Mollit dolore ad cupidatat id. Consectetur cupidatat et nulla sunt minim ipsum sunt ad consectetur fugiat. Elit officia ipsum ex dolor anim esse nisi veniam nulla ad.\r\n",
    "nextSteps": "Mollit officia fugiat dolore proident nisi proident laborum sint reprehenderit eiusmod ad amet laborum voluptate. Laborum sunt adipisicing incididunt in tempor deserunt laborum aute minim nostrud. Cillum commodo veniam ex voluptate magna adipisicing nisi voluptate nostrud cillum.\r\n",
    "investment": "Quis nostrud irure veniam sit nulla officia voluptate laborum exercitation duis. Nostrud voluptate cupidatat ad voluptate est fugiat non consectetur anim sunt veniam aliquip. Dolor sunt commodo mollit elit adipisicing ad mollit amet. Irure eiusmod nisi qui sint proident ea. Duis elit sit consectetur dolor fugiat laborum dolore ad proident. Consectetur ex occaecat aute excepteur exercitation pariatur dolor.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Esse cillum reprehenderit id consequat aute esse. Minim qui cupidatat officia et cupidatat amet occaecat minim do minim. Ea Lorem consectetur nostrud duis fugiat dolor irure enim ut consequat laboris. Cillum incididunt nisi ex ut.\r\n",
      "engagementDescr": "Fugiat nulla incididunt aliquip consequat deserunt commodo quis cupidatat amet. Eiusmod excepteur esse veniam exercitation est pariatur in fugiat excepteur exercitation labore proident officia. Mollit eu aliquip labore duis laboris reprehenderit est esse culpa. Eu minim occaecat laborum sit eu cillum in nulla. Fugiat Lorem pariatur duis dolore reprehenderit aliquip voluptate consequat tempor incididunt nisi culpa id culpa. Duis laboris ex fugiat consectetur ullamco eiusmod do. Excepteur dolor sit minim amet sint.\r\n",
      "dataDescr": "Pariatur aliquip dolor labore reprehenderit in ea sunt aliquip duis amet cillum. Est dolor excepteur labore mollit id sunt est sit consequat anim. Anim amet est do reprehenderit dolor minim reprehenderit sint cillum. Nostrud exercitation et aliqua do est. Laboris ut ad voluptate et elit magna exercitation. Nisi id eu reprehenderit excepteur est excepteur sint duis ad aute eu. Proident occaecat Lorem magna ea excepteur excepteur dolore.\r\n",
      "routeDescr": "Adipisicing consectetur minim consequat qui nulla non laborum in pariatur do. Ea esse eiusmod sint minim labore nostrud minim dolor est incididunt laborum. Occaecat fugiat proident fugiat et elit anim aute pariatur officia laboris laboris deserunt sit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Christian Barton",
        "email": "christianbarton@collaire.com",
        "fte": 0.5787
      },
      {
        "id": 1,
        "name": "Janice Avery",
        "email": "janiceavery@collaire.com",
        "fte": 0.2635
      },
      {
        "id": 2,
        "name": "Antoinette Perry",
        "email": "antoinetteperry@collaire.com",
        "fte": 0.3836
      },
      {
        "id": 3,
        "name": "Bauer Shepherd",
        "email": "bauershepherd@collaire.com",
        "fte": 0.8882
      },
      {
        "id": 4,
        "name": "Lilian Watts",
        "email": "lilianwatts@collaire.com",
        "fte": 0.4533
      },
      {
        "id": 5,
        "name": "Compton Suarez",
        "email": "comptonsuarez@collaire.com",
        "fte": 0.2082
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Mcfadden Romero",
        "email": "mcfaddenromero@collaire.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 12,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 23,
        "y3": 51
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 11,
        "y3": 40
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 786910,
      "customerVal": 738590,
      "mvp": 20359742,
      "optimize": 12677158,
      "grow": 851867155,
      "scale": 1195835624
    },
    "nextStepsActionPlan": "Ad occaecat deserunt adipisicing laboris Lorem. Aute excepteur commodo consequat duis deserunt esse id sint quis pariatur tempor deserunt enim sunt. Nulla ullamco aliqua proident nisi tempor aliquip do adipisicing. Proident non tempor sit enim officia qui laborum est elit occaecat aliqua id. Lorem nulla do eiusmod incididunt Lorem. Aute commodo officia labore veniam enim laboris nulla minim non aute culpa proident. Consectetur sint duis voluptate et aute mollit laborum sit duis ea sint excepteur.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Do consequat est amet ipsum. Excepteur commodo exercitation id est quis cillum. Velit minim aliqua dolore ex quis fugiat consectetur ea aliquip cupidatat amet est. Adipisicing magna ullamco enim sint aute duis fugiat exercitation voluptate officia elit adipisicing sint incididunt. Quis fugiat et ea cillum tempor consequat reprehenderit fugiat irure id incididunt eiusmod exercitation est. Minim ipsum mollit aliqua officia aliqua pariatur nisi velit ad duis cupidatat consequat et. Sint exercitation dolore culpa consectetur do sit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-23",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e033a70b9feb7dece0a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Biohab",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Brazil",
    "channel": "Offline: Shop in shop",
    "division": "Division Two",
    "painPoint": "Sint esse minim nisi laborum reprehenderit sunt et minim ullamco in laboris elit cupidatat. Aute ullamco ipsum veniam consequat pariatur laborum proident nulla sunt incididunt. Eiusmod amet eu excepteur esse laborum non sit in laboris tempor occaecat cupidatat. Ex cupidatat non nisi esse ipsum consectetur deserunt enim irure laborum nostrud ut pariatur aute.\r\n",
    "description": "Consequat nisi qui qui officia in irure magna do mollit. Sunt occaecat in esse labore magna minim mollit eu officia ex aute culpa. Aliqua exercitation minim nulla esse minim laboris voluptate deserunt deserunt do. Non qui dolore qui id sint exercitation amet minim laborum laborum incididunt excepteur amet exercitation. Mollit cillum nisi laboris nulla eu veniam dolore aliquip consectetur consequat irure labore.\r\n",
    "strategy": "Laboris incididunt aliquip laborum fugiat ut ex ullamco occaecat. Exercitation qui laborum mollit id magna anim. Consectetur et pariatur velit nulla tempor et magna ullamco adipisicing laboris ad ipsum nostrud laborum. Occaecat ex tempor dolor eu in consectetur.\r\n",
    "keyLearnings": "Voluptate excepteur voluptate laborum sint dolore non id magna occaecat ex ea pariatur. Magna cupidatat fugiat excepteur duis eiusmod pariatur aliquip aliquip cillum velit magna aliqua do minim. Incididunt magna duis quis ipsum exercitation est occaecat reprehenderit officia excepteur veniam do. Lorem irure excepteur nostrud ullamco irure exercitation ex sint adipisicing consectetur exercitation aliquip. Labore cupidatat laboris consectetur nostrud elit sint. Dolor magna eiusmod reprehenderit excepteur sit deserunt ex proident do non reprehenderit enim. Laboris adipisicing amet consectetur reprehenderit.\r\n",
    "nextSteps": "Ex laboris incididunt Lorem esse voluptate minim excepteur cillum duis aute. Voluptate esse nostrud labore ut nisi sit cupidatat Lorem amet proident. Velit est incididunt nostrud nostrud in tempor ad ex cupidatat cillum sunt aliqua aute.\r\n",
    "investment": "Dolor nisi enim aliquip sit proident dolor. Nisi sunt cillum cupidatat duis nisi tempor ad ex. Consequat sit nisi duis eu quis deserunt cupidatat. Cupidatat incididunt eu adipisicing minim Lorem labore sit.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Laborum nulla dolor dolor proident. Sit aute elit id quis irure amet nulla cillum officia pariatur commodo. Nisi mollit reprehenderit aliqua nisi occaecat deserunt laborum id dolore aute reprehenderit aliqua nisi. Laborum in sint irure ad sint culpa sunt. Eiusmod pariatur cillum aliquip nisi magna ut pariatur Lorem. Est commodo aliqua irure qui voluptate culpa aliquip amet consequat mollit reprehenderit fugiat incididunt. Excepteur et occaecat magna Lorem laborum veniam.\r\n",
      "engagementDescr": "Sunt et proident officia officia et veniam id tempor occaecat aliquip id officia magna. Commodo dolor veniam Lorem non. Ad officia id ipsum consectetur mollit proident. Magna et cupidatat ut proident dolore eu non. Esse amet eu amet pariatur nulla ut minim elit et reprehenderit duis occaecat nostrud. Do tempor magna dolor sit occaecat. Laboris anim amet officia reprehenderit officia magna aliqua nisi eu nulla laboris.\r\n",
      "dataDescr": "Eiusmod exercitation nisi voluptate reprehenderit sit duis pariatur. Et quis reprehenderit laborum duis enim esse. Est consectetur officia in reprehenderit fugiat excepteur exercitation Lorem duis sit qui. Incididunt cupidatat consectetur qui commodo cupidatat culpa pariatur proident sunt aliqua commodo eiusmod qui. Ea aliqua veniam aute cupidatat ex sint deserunt fugiat nulla do do non quis. Dolore exercitation minim dolore id minim sint labore exercitation aliqua adipisicing nisi est aute.\r\n",
      "routeDescr": "Consequat quis ullamco nulla proident mollit excepteur sint qui duis Lorem. Aute Lorem reprehenderit minim minim aliqua. Quis et occaecat mollit quis anim ea ut labore eu fugiat nostrud nisi.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Jolene Barrera",
        "email": "jolenebarrera@biohab.com",
        "fte": 0.3837
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Helene Shields",
        "email": "heleneshields@biohab.com"
      },
      {
        "id": 1,
        "name": "Margery Calderon",
        "email": "margerycalderon@biohab.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 18,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 57,
        "y3": 98
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 17,
        "y3": 37
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2393434,
      "customerVal": 1397614,
      "mvp": 31535884,
      "optimize": 277438,
      "grow": 1105090062,
      "scale": 996452106
    },
    "nextStepsActionPlan": "Aliqua non anim duis sit officia proident id ut consequat ut laboris. Eu nostrud ad commodo eiusmod officia cillum laboris irure ullamco. Ut ad anim duis qui. Eiusmod et culpa ex id mollit magna enim duis eu ea sint. Proident ipsum non deserunt sint ut occaecat exercitation et sunt nostrud pariatur incididunt laboris.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Amet aliquip minim cillum elit ullamco magna sint minim ullamco tempor. Fugiat nisi irure pariatur est cillum dolor aute culpa. Occaecat laborum ex enim exercitation dolor dolore enim incididunt do. Culpa labore excepteur velit in. Minim enim duis incididunt aute nostrud tempor.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e031c96828432333c8c",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Velos",
    "highlightKPI": [
      "ctr",
      "gmac",
      "tc"
    ],
    "geography": "Italy",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Quis dolore exercitation commodo tempor fugiat tempor elit. Cupidatat eiusmod eiusmod pariatur fugiat sit mollit. Dolore elit do est quis. Minim aute esse consequat dolor excepteur labore aliqua voluptate. Est qui laborum incididunt reprehenderit anim deserunt exercitation sint aliqua occaecat adipisicing. Dolore exercitation minim sunt velit sit velit. Sunt laborum reprehenderit eiusmod reprehenderit esse occaecat laborum reprehenderit fugiat Lorem excepteur.\r\n",
    "description": "Irure aliquip qui veniam magna non. Veniam esse magna ad occaecat. Sunt aliqua elit qui elit pariatur quis pariatur esse qui. Ullamco ad culpa ut et consequat ea deserunt duis anim fugiat. Sit ullamco culpa deserunt consectetur Lorem nisi ea deserunt esse.\r\n",
    "strategy": "Velit sunt ad aliquip fugiat mollit deserunt amet velit incididunt enim velit esse sit laborum. Id culpa aute minim est consectetur officia incididunt. Irure nisi magna ea est dolor mollit quis laboris sint consequat esse ipsum. Officia non quis eiusmod laboris sint duis aliqua dolor consectetur eiusmod tempor.\r\n",
    "keyLearnings": "Ea laboris dolor reprehenderit irure esse incididunt velit eu. Aliquip et eu ex velit ipsum non ea laboris veniam aliquip nostrud elit pariatur ullamco. Voluptate ex et qui veniam velit culpa sunt dolor nulla ut sit ipsum duis laboris. Ut qui magna ut sit cupidatat eiusmod non veniam dolore non magna sit non. Labore pariatur commodo incididunt aliqua exercitation. Nostrud minim est est consequat reprehenderit eu incididunt cillum amet reprehenderit deserunt mollit. Veniam dolore anim excepteur dolor elit voluptate.\r\n",
    "nextSteps": "Laboris laborum eu Lorem quis adipisicing. Lorem aliquip aliqua ipsum incididunt anim id. Aliquip tempor in minim eu labore elit elit. Officia incididunt tempor aliqua velit consequat nostrud exercitation consequat irure sit.\r\n",
    "investment": "Laboris esse laboris do culpa exercitation adipisicing. Deserunt ut anim quis eiusmod excepteur aute anim consectetur cupidatat id irure. Non laboris elit id exercitation pariatur ipsum mollit enim in consequat irure. Dolor occaecat dolore exercitation ullamco excepteur irure. Excepteur laboris exercitation nostrud nulla ex.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Sit veniam est nostrud occaecat eu occaecat. Nostrud irure cupidatat consectetur qui sint adipisicing ullamco. Ullamco proident officia proident sunt magna mollit occaecat consequat consequat.\r\n",
      "engagementDescr": "Eiusmod sit excepteur ad ut nulla dolore enim dolore cupidatat duis pariatur amet sit esse. Laboris do anim consectetur culpa consequat sit et cillum irure officia veniam ea quis. Sunt non tempor fugiat commodo mollit proident aliquip tempor adipisicing occaecat reprehenderit. Incididunt nostrud consequat commodo id consectetur nulla quis in.\r\n",
      "dataDescr": "Aliquip deserunt nisi tempor id ipsum sunt elit nostrud deserunt adipisicing non elit velit. Ea id commodo ut magna et deserunt elit nostrud amet. Sit excepteur ipsum anim do ad. Non labore est irure qui aute mollit cillum cillum non.\r\n",
      "routeDescr": "Velit Lorem est cupidatat ex velit minim ut. Sint elit cillum ipsum sunt id est consequat nulla. Duis nisi sint pariatur proident elit ut fugiat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Bowman Hinton",
        "email": "bowmanhinton@velos.com",
        "fte": 0.3421
      },
      {
        "id": 1,
        "name": "Chen Nichols",
        "email": "chennichols@velos.com",
        "fte": 0.158
      },
      {
        "id": 2,
        "name": "Nanette Hendricks",
        "email": "nanettehendricks@velos.com",
        "fte": 0.9508
      },
      {
        "id": 3,
        "name": "Jasmine Key",
        "email": "jasminekey@velos.com",
        "fte": 0.1269
      },
      {
        "id": 4,
        "name": "Mayer Gay",
        "email": "mayergay@velos.com",
        "fte": 0.2287
      },
      {
        "id": 5,
        "name": "Reese Taylor",
        "email": "reesetaylor@velos.com",
        "fte": 0.3507
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Harriet Willis",
        "email": "harrietwillis@velos.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 17,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 36,
        "y3": 73
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 18,
        "y3": 47
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4339705,
      "customerVal": 792235,
      "mvp": 16658267,
      "optimize": 1168369,
      "grow": 511254313,
      "scale": 354700589
    },
    "nextStepsActionPlan": "Veniam excepteur elit ipsum quis elit ut sit pariatur veniam incididunt ut elit amet. Non in ullamco non occaecat qui excepteur pariatur proident culpa nisi. Voluptate quis est eu ea mollit ex proident.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Lorem deserunt ullamco proident tempor. Dolore irure reprehenderit do laborum cillum ipsum mollit enim id esse. Officia pariatur nisi voluptate occaecat in dolor pariatur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0380584a078ee19903",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Otherside",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "Spain",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Qui excepteur mollit elit incididunt culpa excepteur laborum. Commodo ad ut aute ut excepteur ut. Deserunt proident laboris fugiat ut exercitation enim deserunt enim minim eiusmod consequat aliqua. Nulla et aliqua exercitation duis laboris incididunt ad eu aute. Irure commodo ea enim ipsum incididunt anim sit aute pariatur sint et quis cillum occaecat. Eiusmod enim duis id enim elit minim deserunt aute.\r\n",
    "description": "Id dolor ullamco sunt nostrud ex occaecat sunt excepteur incididunt pariatur labore sit est. Officia enim anim incididunt magna amet. Laboris ea magna quis quis dolore ullamco sit consequat veniam consectetur. Minim officia mollit duis minim consectetur ipsum incididunt culpa cillum commodo consequat mollit. Elit non Lorem ex aliqua amet cillum.\r\n",
    "strategy": "Ullamco fugiat ut eiusmod incididunt magna. Occaecat non amet sit velit. Id cupidatat ipsum reprehenderit excepteur quis in esse dolore nisi dolore voluptate ex dolor mollit.\r\n",
    "keyLearnings": "Nisi est ex dolor do commodo aliqua sint quis. Aliqua eiusmod consequat eu et eu aliqua adipisicing commodo dolor pariatur eu. Culpa minim et irure dolor.\r\n",
    "nextSteps": "Ex amet incididunt ipsum ullamco incididunt tempor aute esse magna esse mollit. Laboris deserunt dolor cillum eiusmod culpa. Eiusmod labore qui ullamco consectetur cillum Lorem sunt quis in tempor ad.\r\n",
    "investment": "Cupidatat do culpa proident proident id non eu laborum cupidatat sint fugiat duis fugiat magna. Incididunt aliquip consectetur pariatur aliqua fugiat exercitation. Dolor velit laboris culpa deserunt laboris elit cillum ad.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Nulla occaecat voluptate proident nisi qui sunt irure. Exercitation aliquip aliqua commodo veniam et eiusmod pariatur ea. Magna mollit aute ullamco ullamco sint laborum enim incididunt aute adipisicing occaecat deserunt tempor. Exercitation enim ipsum occaecat pariatur cupidatat incididunt veniam. Do dolore nostrud consectetur aute do eu mollit nulla voluptate. Do quis officia ullamco ut culpa anim esse consequat in deserunt laborum fugiat.\r\n",
      "engagementDescr": "Qui fugiat ea do Lorem. Nostrud nostrud voluptate reprehenderit aliquip excepteur qui ex minim enim. Officia duis ut ullamco dolor velit. Sint et duis nostrud amet ullamco adipisicing.\r\n",
      "dataDescr": "Non excepteur officia minim enim qui deserunt sunt excepteur id. Commodo velit eu officia aliquip esse excepteur id tempor tempor aliqua nulla dolor duis Lorem. Sit exercitation magna dolor adipisicing enim. Amet elit quis qui velit in quis sunt aliquip. Excepteur veniam et duis amet mollit mollit non. Nostrud eu ea ex velit incididunt exercitation sit exercitation nostrud aliqua. Esse velit mollit aute ad ex aute magna.\r\n",
      "routeDescr": "Enim non duis est laborum adipisicing minim exercitation aliquip aliquip commodo laboris. Qui ipsum ea do tempor dolor deserunt esse voluptate et. Esse laborum voluptate id non labore cillum esse laborum ut amet eiusmod in ad.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Maribel Wade",
        "email": "maribelwade@otherside.com",
        "fte": 0.2441
      },
      {
        "id": 1,
        "name": "Ola Merrill",
        "email": "olamerrill@otherside.com",
        "fte": 0.1651
      },
      {
        "id": 2,
        "name": "Bradshaw Knox",
        "email": "bradshawknox@otherside.com",
        "fte": 0.9742
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Silva Barron",
        "email": "silvabarron@otherside.com"
      },
      {
        "id": 1,
        "name": "Harriett Floyd",
        "email": "harriettfloyd@otherside.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 9,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 56,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 26,
        "y3": 65
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6137613,
      "customerVal": 1224398,
      "mvp": 49196272,
      "optimize": 9856434,
      "grow": 1215215780,
      "scale": 390781250
    },
    "nextStepsActionPlan": "Sit adipisicing cillum eu mollit dolor nostrud aliqua exercitation sunt mollit est labore deserunt est. Cillum commodo ea velit aliquip incididunt et pariatur Lorem irure laboris. Ut Lorem ea veniam voluptate. Aliquip esse do consectetur voluptate esse occaecat esse ex aliqua in. Ullamco qui nostrud dolor cillum dolor. Dolore aliqua minim nisi quis enim ut cupidatat excepteur anim. Fugiat amet fugiat mollit anim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis magna cillum adipisicing magna adipisicing magna ea aute. Consectetur id laboris nostrud nostrud laboris incididunt irure elit quis voluptate elit sunt. Magna ad id qui deserunt eiusmod dolore eu dolor reprehenderit veniam laboris. Et id duis consequat consectetur eiusmod reprehenderit laborum eiusmod ea ex nostrud est eiusmod esse. Voluptate aute fugiat eiusmod veniam Lorem. Qui exercitation ex tempor veniam nostrud cupidatat eiusmod.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-10",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0347f8431721ddf485",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Avenetro",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "Sweden",
    "channel": "Offline: Pop up store",
    "division": "Division One",
    "painPoint": "Anim irure irure occaecat consequat tempor enim voluptate qui tempor. Aliqua esse aute id eu qui adipisicing. Et consequat labore veniam et consequat exercitation aute anim mollit. Do dolor ea mollit Lorem aute dolore do qui id minim. Elit fugiat dolore et ex laborum quis velit. Dolore amet qui veniam in. Officia adipisicing ipsum est commodo est quis sint magna nisi.\r\n",
    "description": "In velit esse mollit incididunt Lorem officia est ad. Aute eu id non cillum. Velit duis quis aute sit sit. Voluptate quis sint pariatur nostrud. Officia proident nisi mollit ut quis deserunt eiusmod. Tempor sit aute voluptate elit nisi ea culpa labore aliqua enim anim. Anim in irure aliqua in occaecat deserunt velit nulla eiusmod enim do duis irure.\r\n",
    "strategy": "Aliqua enim cillum anim ipsum voluptate aliquip sunt ut commodo elit aliqua nostrud exercitation culpa. Proident et consectetur et quis quis est deserunt reprehenderit elit veniam labore officia deserunt. Amet enim commodo qui laborum proident tempor dolore esse nostrud cupidatat velit sit exercitation. Elit nulla ut cupidatat non consequat adipisicing esse magna aliquip.\r\n",
    "keyLearnings": "Ipsum ut magna tempor commodo irure. Ad anim amet eu laboris adipisicing adipisicing ex ullamco sit in elit et cupidatat ullamco. Laboris ipsum magna aute qui officia fugiat pariatur sit culpa esse excepteur. Ea mollit ullamco aute in sit voluptate ex commodo. Cillum anim tempor ad proident consequat cillum. Ullamco consectetur consequat id tempor dolor amet nostrud in. Aliqua cupidatat laboris excepteur consequat commodo enim officia dolor officia.\r\n",
    "nextSteps": "Exercitation voluptate in laboris ea fugiat laboris pariatur in anim minim cupidatat deserunt est. Nulla aliqua pariatur magna voluptate ex. Officia id laboris consequat fugiat. Ipsum amet dolor eiusmod consectetur excepteur non mollit nostrud eu. Veniam magna pariatur non ea in ipsum sit. Enim dolore occaecat laborum nisi quis qui sit mollit cupidatat irure minim.\r\n",
    "investment": "Laborum incididunt non laborum ea do dolor enim. Lorem occaecat aute officia nulla dolor qui in est. Lorem laboris dolor occaecat veniam sit aliqua veniam eu esse laboris. Laboris sit sit aliquip in ex qui ad do nulla aliqua voluptate incididunt nulla aute. Magna tempor amet ipsum reprehenderit nisi sit nulla laboris dolore proident officia magna cupidatat. Ad minim non dolore Lorem velit voluptate reprehenderit.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Nisi consequat dolore ea nisi qui dolor ullamco adipisicing enim mollit sit ullamco ullamco. Adipisicing ex magna est dolore commodo fugiat dolor consectetur. Id cillum mollit do id nulla duis officia deserunt ullamco proident. Duis labore aliquip in consectetur occaecat ipsum mollit fugiat irure et cupidatat et elit. Ea esse adipisicing cupidatat duis est. Quis eiusmod ullamco mollit anim ullamco.\r\n",
      "engagementDescr": "Id ipsum nisi aute ipsum ipsum aliquip. In sint occaecat do officia laborum est laboris incididunt id reprehenderit eu. Lorem proident voluptate reprehenderit sunt sunt officia deserunt commodo est sit officia laboris cillum est. Eiusmod ex amet ut officia enim esse deserunt laboris ullamco. Ut pariatur id qui elit est est laborum ad. Laborum do sint est nisi commodo anim cupidatat deserunt.\r\n",
      "dataDescr": "Et non exercitation dolor ea dolor aliqua. Qui nostrud cupidatat minim est magna. Nulla laboris est veniam sint commodo ullamco nostrud quis laboris aliqua commodo amet pariatur. Culpa et nostrud adipisicing sunt minim ut duis dolor reprehenderit. Incididunt esse tempor nisi id dolore nisi.\r\n",
      "routeDescr": "Fugiat ex esse deserunt elit esse Lorem commodo nostrud consectetur sit consectetur qui veniam duis. Quis qui anim irure in ad amet ipsum qui laboris. Veniam eu minim quis officia culpa fugiat laboris enim consectetur ullamco sunt ad esse. Mollit veniam ad et sint magna ipsum amet consequat adipisicing ipsum in nostrud labore. Ullamco aute velit ad do. Officia sit magna ad ea commodo quis velit qui esse.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Madeline Moses",
        "email": "madelinemoses@avenetro.com",
        "fte": 0.7898
      },
      {
        "id": 1,
        "name": "Shepherd Hopper",
        "email": "shepherdhopper@avenetro.com",
        "fte": 0.2531
      },
      {
        "id": 2,
        "name": "Samantha Christian",
        "email": "samanthachristian@avenetro.com",
        "fte": 0.7135
      },
      {
        "id": 3,
        "name": "Murray Tucker",
        "email": "murraytucker@avenetro.com",
        "fte": 0.3577
      },
      {
        "id": 4,
        "name": "Irma Riddle",
        "email": "irmariddle@avenetro.com",
        "fte": 0.7219
      },
      {
        "id": 5,
        "name": "Frye Joseph",
        "email": "fryejoseph@avenetro.com",
        "fte": 0.2623
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Mcmillan Church",
        "email": "mcmillanchurch@avenetro.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 11,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 55,
        "y3": 98
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 19,
        "y3": 55
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11070681,
      "customerVal": 766891,
      "mvp": 52997882,
      "optimize": 11990844,
      "grow": 390836268,
      "scale": 361863202
    },
    "nextStepsActionPlan": "Enim commodo Lorem laboris consectetur occaecat minim enim ea tempor qui dolor eiusmod. Mollit sint non duis labore. Enim sint esse aliquip esse cillum ullamco dolore laborum et dolore incididunt deserunt. Lorem eu aliquip nulla culpa laboris. Amet magna in consequat aliqua nulla reprehenderit minim fugiat ullamco sint. Anim eiusmod enim aliqua sit esse sit deserunt irure id adipisicing proident officia.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Elit tempor laborum sit velit esse exercitation culpa voluptate sunt id occaecat amet laborum. Enim tempor ipsum excepteur do esse. Nisi dolore mollit qui labore quis nisi laborum irure et deserunt. Aliquip anim mollit qui ipsum eiusmod aute ex do amet velit consequat. Eiusmod anim nulla eu nulla cupidatat minim culpa excepteur tempor. Pariatur occaecat minim pariatur tempor. Voluptate non cillum aliqua exercitation amet sint aute irure fugiat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-14",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0328160f70cf0e52b6",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Retrotex",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "India",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Laborum dolor et fugiat amet cupidatat et minim officia consectetur est. Lorem dolor in do aliqua sit voluptate officia proident nostrud. Consectetur incididunt elit laborum laborum mollit ea sunt magna ad mollit labore exercitation in voluptate.\r\n",
    "description": "Commodo elit eiusmod consectetur culpa nulla magna sint commodo officia nisi proident pariatur. Ipsum do aliquip nostrud esse occaecat qui dolore aute Lorem dolor ea. Deserunt incididunt non fugiat officia enim pariatur pariatur deserunt sit Lorem reprehenderit. Nostrud reprehenderit eu proident minim non in aute. Velit anim ut ullamco non duis sit eu exercitation nostrud cupidatat consectetur mollit aute aliquip.\r\n",
    "strategy": "Quis ex aliqua ad officia exercitation consequat ea sunt labore. Labore aute velit cupidatat pariatur id laboris. Velit officia ullamco esse pariatur veniam aliquip nostrud consequat.\r\n",
    "keyLearnings": "Sint cupidatat dolor et in officia. Exercitation cillum irure aliquip ullamco exercitation pariatur dolore consectetur officia sit occaecat. Reprehenderit sunt pariatur proident minim officia exercitation nulla adipisicing irure reprehenderit magna ex et. Minim adipisicing officia duis anim minim sint pariatur laboris incididunt. Ut dolor officia in aute pariatur culpa nulla dolor. Proident aliqua mollit aliquip cillum minim aliquip commodo laborum aute velit aliquip duis enim.\r\n",
    "nextSteps": "Exercitation do labore anim sint enim. Voluptate do laboris est in mollit sunt irure velit anim ea officia. Et occaecat aliqua qui id aute ex officia ut irure elit. Elit dolor officia ex enim dolor cupidatat cupidatat.\r\n",
    "investment": "Qui ea Lorem eiusmod in in aliqua non officia esse ut cupidatat pariatur proident occaecat. Irure aliqua consectetur sit consequat sint qui. Quis laborum do commodo amet dolore enim velit enim fugiat nostrud officia velit. Exercitation commodo ea officia ea incididunt anim quis enim ut. Proident culpa tempor sunt sit reprehenderit sint.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Esse sint laborum sint adipisicing ullamco ipsum laboris Lorem. Adipisicing reprehenderit est dolor duis dolor aliqua mollit sunt cupidatat ad laboris ea. Commodo aliquip quis duis qui commodo do ea ad ex dolor ad anim cillum.\r\n",
      "engagementDescr": "Cillum voluptate proident nostrud culpa pariatur quis minim. Tempor elit nostrud et consectetur ad non proident officia enim. Magna quis irure sit tempor culpa exercitation nulla ex labore. Cillum commodo dolore magna sint enim consectetur minim sunt minim duis consequat. Laboris amet nostrud aute amet cupidatat amet proident enim minim consequat qui exercitation. Ullamco reprehenderit qui qui consequat id incididunt culpa ullamco ad ipsum ea et.\r\n",
      "dataDescr": "Sunt magna adipisicing cillum reprehenderit voluptate commodo sint. Adipisicing aliqua velit quis ex deserunt est occaecat. Deserunt nisi ipsum in officia officia ut nostrud nostrud duis ad commodo labore aute incididunt. Ea tempor amet id sit Lorem deserunt. Ipsum id velit consequat qui qui esse ad occaecat ut cillum enim mollit laboris non. Aliqua excepteur enim ullamco commodo qui do ex. Sint consectetur qui officia consequat commodo cupidatat nisi elit fugiat et amet.\r\n",
      "routeDescr": "Quis ipsum quis officia cillum amet fugiat eiusmod aliqua ipsum tempor est magna. Aliqua cillum pariatur aliquip sit aliqua et cupidatat. Lorem ex irure consectetur mollit Lorem laboris laboris consectetur veniam culpa ex pariatur pariatur.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Short Drake",
        "email": "shortdrake@retrotex.com",
        "fte": 0.334
      },
      {
        "id": 1,
        "name": "Jerry Tran",
        "email": "jerrytran@retrotex.com",
        "fte": 0.417
      },
      {
        "id": 2,
        "name": "Deena Mcdonald",
        "email": "deenamcdonald@retrotex.com",
        "fte": 0.1481
      },
      {
        "id": 3,
        "name": "Justice Mcknight",
        "email": "justicemcknight@retrotex.com",
        "fte": 0.6957
      },
      {
        "id": 4,
        "name": "Gladys Good",
        "email": "gladysgood@retrotex.com",
        "fte": 0.824
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Chris Mays",
        "email": "chrismays@retrotex.com"
      },
      {
        "id": 1,
        "name": "Esther Ramirez",
        "email": "estherramirez@retrotex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 9,
        "y3": 39
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 14,
        "y2": 39,
        "y3": 81
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 14,
        "y3": 66
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2086800,
      "customerVal": 1385534,
      "mvp": 89983845,
      "optimize": 3628083,
      "grow": 738577697,
      "scale": 1402309062
    },
    "nextStepsActionPlan": "In proident excepteur eu minim est duis est ullamco enim enim laboris aliquip. Consequat ullamco nisi nisi Lorem ad aliqua aliqua commodo voluptate incididunt et qui nostrud. Et officia in ex culpa officia minim anim sunt occaecat commodo deserunt. Officia eiusmod sit amet ut magna elit tempor veniam exercitation tempor. Exercitation irure magna aliqua et laboris fugiat do veniam enim adipisicing consectetur irure.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt quis duis labore consectetur. Ipsum eu ad irure culpa duis ipsum velit pariatur eu dolore nulla. Culpa ea Lorem amet pariatur deserunt magna veniam qui. Exercitation Lorem ipsum fugiat id esse laboris ad labore.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039f1c628c70299a72",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Trasola",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Flagship store",
    "division": "Division One",
    "painPoint": "Dolor sint exercitation minim labore laboris eiusmod. Amet ipsum excepteur commodo magna. Aliquip est amet in ea eiusmod dolor enim ut in do. Laborum aliqua eu quis tempor commodo duis veniam fugiat cillum aute non mollit veniam.\r\n",
    "description": "Minim esse in consectetur cupidatat qui reprehenderit officia cupidatat do. Quis elit tempor ad nisi sint deserunt cillum ut aute quis tempor et sint. Incididunt ipsum est sit consequat cillum dolor occaecat non aute magna consequat. Et ipsum ex quis anim pariatur esse veniam cupidatat anim occaecat sunt. Est enim nostrud ut velit duis fugiat sit. Amet exercitation ut culpa eu pariatur veniam sit laborum enim. Et ad dolor aliquip consequat.\r\n",
    "strategy": "Minim sunt quis et adipisicing minim ad id. Non velit aliquip veniam nulla quis enim excepteur. Sunt pariatur ad mollit velit ex ut.\r\n",
    "keyLearnings": "Eiusmod sit eiusmod exercitation aute quis deserunt sint eiusmod proident nostrud enim mollit exercitation fugiat. Eiusmod labore incididunt et anim dolor magna velit ea eiusmod non sint cillum ad. Culpa esse laborum pariatur culpa consequat labore officia culpa laborum adipisicing et excepteur fugiat labore. Duis ipsum consectetur incididunt culpa cillum amet minim. Officia magna dolor Lorem dolore et cupidatat consequat ut. Officia occaecat dolor incididunt reprehenderit proident labore anim cillum.\r\n",
    "nextSteps": "Consectetur laborum id eu non non voluptate in. Labore cupidatat voluptate veniam ea aliqua qui reprehenderit duis sit laboris fugiat consectetur sunt. Lorem officia excepteur voluptate pariatur duis aliquip laborum. Nisi irure occaecat commodo aliqua nulla culpa elit mollit. Voluptate eu reprehenderit laborum voluptate laborum. Enim occaecat occaecat fugiat est laboris qui nulla veniam officia reprehenderit deserunt do ex. Nulla ullamco magna do cupidatat amet irure voluptate aliqua excepteur fugiat amet laborum tempor.\r\n",
    "investment": "Cillum est nostrud ex consectetur ea minim cillum dolore aliquip anim non. Sunt esse cupidatat labore consectetur voluptate aliqua. Culpa Lorem labore ex officia nulla qui reprehenderit officia commodo sint aute mollit quis fugiat.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Occaecat duis adipisicing dolore minim dolore officia cillum ea. Aliqua ullamco reprehenderit incididunt est. Dolore amet labore commodo veniam proident reprehenderit fugiat consequat excepteur.\r\n",
      "engagementDescr": "Nulla nulla veniam ullamco ea ad deserunt ipsum mollit sit Lorem. Reprehenderit cillum do incididunt veniam fugiat enim aliqua nostrud elit esse sit ullamco aute pariatur. Nulla qui veniam deserunt duis sunt et. Adipisicing aliquip est et velit dolore dolor consequat aliquip velit tempor cupidatat et id sunt. Irure minim ex commodo tempor sit et aliquip in. Occaecat ea officia ipsum enim incididunt sit nulla ut eu aliquip exercitation. Laborum irure qui fugiat voluptate aliqua.\r\n",
      "dataDescr": "Est pariatur eiusmod voluptate deserunt. Occaecat ullamco aliqua Lorem velit ea reprehenderit sit ea cupidatat laboris quis eu. Ex cupidatat duis deserunt Lorem mollit cillum proident voluptate ullamco minim in enim aute exercitation. Officia do minim irure veniam eu fugiat mollit adipisicing ea consequat voluptate excepteur. Reprehenderit aute occaecat adipisicing nisi.\r\n",
      "routeDescr": "Enim nostrud laboris excepteur irure elit veniam fugiat esse tempor sunt ut magna voluptate. Consectetur occaecat irure ad officia commodo pariatur ea ad culpa sunt cillum commodo nisi velit. Dolore labore eiusmod cupidatat reprehenderit est officia. Nulla elit duis elit irure incididunt minim consectetur irure id elit. Incididunt amet pariatur duis aliquip amet. Quis irure duis dolore dolor.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Gina Cox",
        "email": "ginacox@trasola.com",
        "fte": 0.9723
      },
      {
        "id": 1,
        "name": "Annabelle Mcguire",
        "email": "annabellemcguire@trasola.com",
        "fte": 0.4859
      },
      {
        "id": 2,
        "name": "Therese Ortega",
        "email": "thereseortega@trasola.com",
        "fte": 0.1629
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Gretchen Waller",
        "email": "gretchenwaller@trasola.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 8,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 46,
        "y3": 53
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 24,
        "y3": 43
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 915510,
      "customerVal": 803480,
      "mvp": 134575946,
      "optimize": 7264908,
      "grow": 493368707,
      "scale": 720274210
    },
    "nextStepsActionPlan": "Laborum commodo cupidatat enim aliquip fugiat. Amet adipisicing ut cupidatat eu laboris velit ea deserunt ad eu elit eiusmod esse. Qui mollit sunt minim ut in cillum aute. Dolore cillum irure eiusmod reprehenderit amet ut nulla eu irure laboris. Commodo sit consequat do aliqua cillum et. Aliqua culpa incididunt reprehenderit eiusmod.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cupidatat enim in sint fugiat commodo nostrud. Ut deserunt laboris deserunt labore cupidatat occaecat irure anim est. Magna commodo sint amet ad in velit nisi elit velit nisi cillum. Ullamco ut incididunt qui excepteur commodo anim enim nostrud officia adipisicing. Est veniam est laboris et labore consequat dolor ullamco reprehenderit enim fugiat adipisicing id. Ipsum quis ullamco amet velit voluptate commodo officia tempor dolor non laborum laboris. Consectetur nisi ipsum magna reprehenderit elit in duis excepteur anim adipisicing.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-09",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d3a0ff4c2433713f",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Prowaste",
    "highlightKPI": [
      "nsv",
      "ltv",
      "rp"
    ],
    "geography": "Spain",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Magna duis esse sint nisi velit anim eu adipisicing quis. Aliquip laboris eu sint voluptate duis voluptate et proident ipsum magna sint Lorem. Id eu commodo adipisicing voluptate. Sit culpa sint do ex aliquip. Voluptate commodo laborum fugiat tempor. In tempor amet aliqua pariatur culpa elit tempor pariatur laboris.\r\n",
    "description": "Duis veniam consequat fugiat amet laboris elit sunt ex irure ullamco cupidatat nisi. Minim ullamco eu duis officia eiusmod labore. Veniam amet adipisicing esse ut ex aliqua do excepteur ut officia. Dolor veniam reprehenderit ut eiusmod amet pariatur ut adipisicing cillum exercitation mollit proident. Elit anim consectetur ipsum id exercitation ex.\r\n",
    "strategy": "Laborum ipsum esse est eu id consectetur quis tempor. Aliqua Lorem esse ipsum nostrud occaecat nostrud fugiat ad laborum dolore id quis. Ad occaecat ipsum sint dolor ex ullamco Lorem occaecat sunt amet. Cupidatat dolore excepteur dolor incididunt est. Cupidatat officia nisi commodo non sunt nulla enim mollit minim adipisicing non qui sint ullamco.\r\n",
    "keyLearnings": "Est aliquip sunt voluptate amet fugiat ad ullamco quis nisi laboris quis consequat ipsum. Adipisicing sunt laborum eiusmod ullamco do anim aliquip quis cupidatat do cillum irure. Eiusmod duis aliqua esse Lorem et sunt commodo et veniam. Irure fugiat pariatur ex aliqua.\r\n",
    "nextSteps": "Exercitation tempor deserunt adipisicing consequat exercitation dolore sint fugiat cupidatat. Excepteur Lorem amet magna est ullamco ea consectetur ut duis ullamco esse. Consectetur sunt consectetur ut esse nisi amet ullamco duis cupidatat quis dolor ad.\r\n",
    "investment": "Id culpa quis commodo exercitation ex in reprehenderit veniam velit nostrud magna elit nisi. Sit consequat tempor amet ullamco excepteur irure Lorem culpa voluptate fugiat. Dolore fugiat mollit in ipsum ea et. Nostrud nisi occaecat cupidatat dolore est proident aliqua cupidatat eu minim elit laborum id ut. Incididunt nulla consectetur est cupidatat occaecat tempor sint.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Ullamco elit qui voluptate eiusmod consectetur Lorem velit dolore voluptate dolor Lorem duis. Aliquip aute ad ut nostrud esse qui excepteur cupidatat magna. Excepteur Lorem laboris velit ullamco. Qui labore voluptate in aute qui aliquip et culpa qui exercitation adipisicing ut. Voluptate cillum velit adipisicing non irure elit ad sunt. Proident mollit anim id proident esse laboris ad mollit voluptate eiusmod eu incididunt.\r\n",
      "engagementDescr": "Exercitation cillum velit non exercitation. Proident enim esse laboris labore quis dolor occaecat laborum velit quis sunt aute reprehenderit exercitation. Eiusmod magna ea eu dolore cupidatat commodo commodo.\r\n",
      "dataDescr": "Non eu fugiat aute adipisicing consequat aliqua ullamco est adipisicing nulla deserunt. Officia qui enim reprehenderit irure proident anim pariatur aute. Minim fugiat commodo laboris duis. Et aute fugiat incididunt qui et nostrud ut laborum dolore do cupidatat incididunt do. Aute qui consectetur nisi aliqua. Mollit do amet ullamco nulla ut id elit laboris ullamco eiusmod. Elit velit culpa officia magna exercitation.\r\n",
      "routeDescr": "Mollit mollit laboris ipsum cillum labore pariatur dolore proident et id. Veniam nisi occaecat culpa sint elit sunt ullamco adipisicing irure qui irure. Ipsum sunt ut adipisicing irure officia proident non ex cillum pariatur pariatur do ipsum cillum.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Castro Spencer",
        "email": "castrospencer@prowaste.com",
        "fte": 0.5069
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Reva Rich",
        "email": "revarich@prowaste.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 19,
        "y3": 15
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 6,
        "y2": 20,
        "y3": 66
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 15,
        "y3": 53
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12584667,
      "customerVal": 1171783,
      "mvp": 106522065,
      "optimize": 10300294,
      "grow": 1047502338,
      "scale": 1498529057
    },
    "nextStepsActionPlan": "Ullamco magna adipisicing esse veniam nulla labore. Qui incididunt in voluptate elit deserunt sit laborum laboris eiusmod. Id aute reprehenderit magna aliquip occaecat ad officia sunt amet sit ut. Pariatur enim voluptate quis Lorem ad mollit deserunt quis nostrud adipisicing tempor ut ex tempor. Qui irure consequat magna eu dolore aliquip in adipisicing.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliquip occaecat adipisicing proident esse aliquip eu dolor veniam eu sunt. Veniam proident culpa quis et sint. Officia amet ut veniam reprehenderit pariatur incididunt aute aliqua officia qui ipsum deserunt veniam. Magna labore officia aute anim exercitation. Elit non nisi quis laboris do aliqua ad occaecat minim dolore proident. Et reprehenderit elit eu anim officia ex pariatur esse ipsum reprehenderit eiusmod do. Et est non voluptate dolor labore voluptate dolore pariatur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03149e527bd6f47208",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Interfind",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "China",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Ex eiusmod laboris eiusmod veniam dolore veniam laborum duis ea velit excepteur. Ipsum et aliquip adipisicing ea nulla deserunt sunt exercitation commodo dolor sint. Non aute reprehenderit reprehenderit et velit aliqua. Nostrud sunt laboris esse elit Lorem proident do laboris proident. Exercitation culpa consectetur et id in tempor quis aliqua aliquip ut sint. Nulla in culpa et dolor velit consequat.\r\n",
    "description": "Cillum tempor ullamco ullamco exercitation. Officia non veniam enim ex commodo est dolor. Labore consectetur laborum tempor est sit voluptate fugiat. Adipisicing veniam exercitation sunt esse cillum anim voluptate esse labore ut ea et.\r\n",
    "strategy": "Amet aliquip ad irure ut tempor ex. Sunt cillum Lorem ea consectetur reprehenderit deserunt. Esse sint dolore adipisicing fugiat reprehenderit dolore non tempor est. Adipisicing dolor incididunt aute qui laboris consectetur amet proident. In do veniam amet dolor cupidatat nulla. Occaecat eiusmod eiusmod ipsum magna in consectetur excepteur consequat sit laboris consectetur est. Quis nostrud excepteur eiusmod occaecat aute fugiat veniam mollit excepteur aliqua.\r\n",
    "keyLearnings": "Reprehenderit ullamco in culpa sint incididunt consequat do est officia ex reprehenderit elit commodo. Aute esse magna cillum fugiat deserunt est. Occaecat laboris esse nulla veniam velit reprehenderit proident sunt minim esse sint anim. Dolor occaecat culpa laboris nostrud qui exercitation consectetur velit nulla proident cupidatat excepteur duis. Ad anim pariatur proident aliqua dolore id aliquip et aliquip incididunt in cillum.\r\n",
    "nextSteps": "Eu commodo dolore veniam sunt qui. Pariatur est incididunt consequat est ex commodo excepteur nostrud magna laboris enim amet. Reprehenderit duis aliquip elit amet ipsum minim aliqua. Aliquip mollit esse aute nostrud aute. Velit irure eiusmod proident quis reprehenderit Lorem elit excepteur ex sit.\r\n",
    "investment": "Nulla in ad minim dolor esse ad ut in sint magna excepteur. Laborum nostrud non officia amet tempor sit reprehenderit. Dolor deserunt anim officia adipisicing laborum culpa sunt dolore. Dolore nisi consectetur sit do et aliquip cupidatat in occaecat. Deserunt magna et labore et adipisicing cillum Lorem elit excepteur dolor magna magna ut ullamco. Ea laborum fugiat voluptate ipsum dolor id ad et ut officia.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Ea exercitation et elit culpa consequat eiusmod tempor eiusmod cillum adipisicing. Ea enim sunt sit incididunt mollit. Excepteur consectetur incididunt non nulla consectetur cupidatat consectetur amet enim proident proident officia velit excepteur. Anim eu dolore dolore velit nisi. Esse veniam officia eiusmod officia laborum dolore.\r\n",
      "engagementDescr": "Dolore id duis sint esse. Nostrud adipisicing dolore reprehenderit voluptate. Ut mollit minim culpa incididunt sit dolor ex. Reprehenderit reprehenderit anim sint est cupidatat occaecat dolor adipisicing consequat aliquip Lorem mollit. Consequat Lorem mollit deserunt ad eu est. Consequat sint velit ut qui exercitation eu reprehenderit laboris exercitation minim aute occaecat reprehenderit.\r\n",
      "dataDescr": "Ex sit fugiat in elit laboris Lorem laboris et anim ea ullamco aute. Non nulla magna voluptate ad enim elit et minim. Ullamco labore minim aliquip eu minim cillum irure esse. Dolore tempor nulla cupidatat consectetur esse incididunt cillum nisi ut. Sunt nostrud cupidatat fugiat incididunt aliquip excepteur laborum dolor non exercitation occaecat minim eu.\r\n",
      "routeDescr": "Qui excepteur dolor incididunt laboris ea aute eiusmod. Incididunt excepteur do labore consectetur anim culpa minim quis exercitation. Fugiat est anim laboris ullamco nostrud adipisicing culpa do cupidatat sunt. Duis magna irure sunt velit quis id sit in irure qui. Eu consectetur enim sunt irure voluptate deserunt consectetur excepteur labore voluptate esse adipisicing. Fugiat enim quis irure exercitation velit officia commodo commodo. Duis cupidatat eiusmod ad nostrud mollit reprehenderit adipisicing aliqua reprehenderit officia culpa amet ut commodo.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Christensen Richard",
        "email": "christensenrichard@interfind.com",
        "fte": 0.5218
      },
      {
        "id": 1,
        "name": "Shannon Horton",
        "email": "shannonhorton@interfind.com",
        "fte": 0.1733
      },
      {
        "id": 2,
        "name": "Toni Lucas",
        "email": "tonilucas@interfind.com",
        "fte": 0.9979
      },
      {
        "id": 3,
        "name": "Leah Vargas",
        "email": "leahvargas@interfind.com",
        "fte": 0.5726
      },
      {
        "id": 4,
        "name": "Cantu Cole",
        "email": "cantucole@interfind.com",
        "fte": 0.1209
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Tillman Bentley",
        "email": "tillmanbentley@interfind.com"
      },
      {
        "id": 1,
        "name": "Cantrell Mclean",
        "email": "cantrellmclean@interfind.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 8,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 56,
        "y3": 77
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 20,
        "y3": 51
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5018685,
      "customerVal": 82805,
      "mvp": 24812176,
      "optimize": 13328020,
      "grow": 361877374,
      "scale": 365881183
    },
    "nextStepsActionPlan": "Tempor nulla ea irure Lorem fugiat ea ipsum proident excepteur. Commodo veniam duis in ullamco anim id proident dolor labore proident anim. Id velit dolor consequat elit quis labore magna aliqua tempor ea.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ipsum Lorem occaecat labore sunt ut. Aute cupidatat cillum veniam excepteur nulla minim. Laboris cillum qui pariatur qui exercitation culpa excepteur voluptate incididunt. Ex nisi incididunt esse duis esse aliqua aliquip consectetur voluptate est anim reprehenderit amet. Est amet voluptate eu nisi aliqua. Enim ullamco dolor non pariatur proident occaecat. Ex aliqua nulla eu minim quis reprehenderit laboris excepteur elit fugiat ipsum nisi consequat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-13",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03a465000d0e7f98b3",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Insurety",
    "highlightKPI": [
      "cac",
      "ltv",
      "tc"
    ],
    "geography": "China",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Nostrud nisi in proident adipisicing ad eiusmod ullamco non esse id ad magna. Sit consectetur qui minim nisi id officia esse veniam non. Eu quis minim irure est.\r\n",
    "description": "Reprehenderit commodo ullamco aliqua nisi incididunt do officia laborum sit in id. Voluptate anim dolore velit dolor ullamco labore sunt. Nostrud laborum adipisicing occaecat enim. In consequat ad magna ex velit reprehenderit proident nulla labore non. Occaecat in amet ut occaecat aliqua culpa sit sint exercitation. Sint deserunt ipsum aliqua ut exercitation. Eu sit cillum fugiat adipisicing exercitation officia reprehenderit duis excepteur.\r\n",
    "strategy": "Amet incididunt esse qui dolor. Est mollit non quis ut et eu laborum nulla ex mollit eu veniam sint. Sit veniam sint deserunt commodo. Esse nulla fugiat sunt nostrud deserunt reprehenderit do sunt. Enim nulla reprehenderit ut enim minim laborum deserunt pariatur officia fugiat minim mollit Lorem. Consequat excepteur elit minim anim minim qui. Excepteur deserunt nulla laborum et exercitation voluptate tempor anim reprehenderit cillum nostrud ut qui.\r\n",
    "keyLearnings": "Et labore do nisi nisi anim ea sit et sit. Dolor fugiat pariatur eu id nostrud consequat id. Exercitation amet eu et aute magna magna officia incididunt officia nisi mollit do do.\r\n",
    "nextSteps": "Laborum voluptate cupidatat quis commodo commodo sint sunt ea laborum veniam. Nisi aliquip est minim non ut do quis aliqua deserunt. Mollit in exercitation tempor enim. Cillum consequat aliquip sit occaecat. Amet pariatur do id ullamco id sit ea quis et labore non irure. Elit fugiat velit in Lorem pariatur deserunt anim ex et exercitation sit qui. Aute deserunt cupidatat commodo tempor mollit aliquip cupidatat excepteur occaecat.\r\n",
    "investment": "Ipsum nisi esse mollit ipsum laborum minim sit magna deserunt adipisicing incididunt. Dolor nisi duis esse aute qui labore laboris ipsum. Cillum duis veniam excepteur minim adipisicing laborum aute aliqua consectetur sunt consectetur. Proident aliqua minim deserunt laborum laborum nisi. Sunt sint minim et officia voluptate. Tempor nisi reprehenderit aliqua duis pariatur mollit nostrud consectetur officia ea mollit duis nisi. Officia voluptate occaecat ullamco exercitation deserunt commodo nulla enim ut excepteur dolore ad quis enim.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Sunt minim enim est mollit cillum sint est et veniam esse ullamco ea excepteur. Magna Lorem nisi deserunt cupidatat irure id. Occaecat proident id cillum minim duis laboris. Magna laborum non cillum deserunt ipsum ea ea dolore sint fugiat. Labore cillum sint non officia nulla ullamco velit aliqua mollit voluptate eiusmod tempor minim Lorem.\r\n",
      "engagementDescr": "Ex aute dolore pariatur ad mollit ad tempor elit. Deserunt pariatur irure ipsum irure mollit adipisicing proident dolor amet nostrud ullamco aliqua sint adipisicing. Ad laboris aute ex elit.\r\n",
      "dataDescr": "Amet proident excepteur tempor dolor ea enim eiusmod aute. Duis in ut excepteur nostrud adipisicing deserunt velit do velit culpa aliquip Lorem. Amet adipisicing tempor irure excepteur culpa. Ipsum nulla nisi deserunt irure anim mollit excepteur quis pariatur cupidatat. Dolor quis excepteur nisi exercitation.\r\n",
      "routeDescr": "Aliqua laboris et non fugiat pariatur excepteur voluptate anim magna commodo velit non. Nisi cupidatat commodo dolore tempor. Pariatur tempor ut nisi laboris eiusmod ut dolor nostrud et ad elit proident. Ipsum minim quis sit enim officia aute fugiat id ex voluptate sunt. Et duis sit ad nulla aute voluptate. Nisi Lorem magna magna consectetur et cillum sit proident fugiat esse. Velit reprehenderit sit exercitation magna ullamco reprehenderit elit elit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Sarah Cooley",
        "email": "sarahcooley@insurety.com",
        "fte": 0.3326
      },
      {
        "id": 1,
        "name": "Patel Fry",
        "email": "patelfry@insurety.com",
        "fte": 0.5942
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Pacheco Massey",
        "email": "pachecomassey@insurety.com"
      },
      {
        "id": 1,
        "name": "Kris Hays",
        "email": "krishays@insurety.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 17,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 47,
        "y3": 90
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 12,
        "y3": 47
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 1848080,
      "customerVal": 557658,
      "mvp": 138583798,
      "optimize": 4817848,
      "grow": 802801648,
      "scale": 1054446532
    },
    "nextStepsActionPlan": "Deserunt consequat minim ea sit incididunt minim deserunt. Cupidatat fugiat nulla ex ullamco incididunt ipsum cillum. Do quis cupidatat minim id consectetur quis exercitation aliquip est minim laborum irure in voluptate. Amet id sint consectetur irure commodo do consequat. Nisi magna mollit id reprehenderit cupidatat commodo reprehenderit tempor deserunt Lorem est cillum nulla. Nisi est officia qui esse aliqua mollit fugiat quis sunt consequat dolor officia fugiat quis.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Do pariatur proident adipisicing sint enim laborum duis amet nulla aute consequat magna aliquip. Nostrud ex elit ullamco ut quis do aliqua aliquip qui ex non irure. Incididunt duis nisi tempor incididunt. Duis commodo quis velit irure enim nisi magna pariatur voluptate ad cupidatat. Voluptate ut duis elit deserunt veniam id. Reprehenderit labore reprehenderit dolor laboris non duis labore occaecat irure laboris. Occaecat aliquip nulla mollit laboris veniam ullamco velit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-21",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03976a4fcd7aef434d",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Conferia",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "United States",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Reprehenderit reprehenderit labore irure est deserunt ea minim adipisicing eu ad velit anim do. Officia consectetur eu adipisicing nulla incididunt aliqua. Nisi velit in non Lorem eu consequat. Enim sit nostrud tempor ad magna nulla sit commodo. Non ea nisi consectetur est proident irure dolore proident minim id irure aliquip exercitation eiusmod. Ex occaecat ex do dolore laboris quis sint minim quis sunt veniam nisi.\r\n",
    "description": "Sint sint dolore sit est amet ipsum cupidatat deserunt Lorem non velit dolore irure quis. Cillum ad enim id fugiat reprehenderit quis amet anim fugiat pariatur. Elit ipsum reprehenderit nulla aliqua aliqua ea aute. Anim qui veniam laboris culpa aliqua adipisicing irure. Dolor deserunt mollit non anim culpa aliqua dolor consectetur nostrud aliquip laboris. Labore qui do sint sit tempor qui consectetur enim amet.\r\n",
    "strategy": "Mollit et sint ipsum ea laboris culpa minim consectetur. Deserunt officia id ea tempor consectetur est. Ipsum pariatur commodo fugiat commodo aliqua excepteur. Quis velit Lorem pariatur do pariatur eiusmod incididunt fugiat ex minim veniam sint commodo pariatur. Consequat in pariatur laboris dolor.\r\n",
    "keyLearnings": "Consectetur dolore cillum ut adipisicing enim eu voluptate laboris nisi nulla esse adipisicing nulla occaecat. Velit occaecat amet nisi magna labore. Exercitation nostrud anim cupidatat cillum in veniam et commodo in voluptate fugiat labore. Ea tempor dolor anim consectetur cillum sunt amet. Sint ex cupidatat laborum in sint ullamco consectetur aute et quis eu mollit duis.\r\n",
    "nextSteps": "Qui qui nostrud magna est culpa et. Est exercitation voluptate dolor deserunt qui elit ipsum occaecat ad mollit laboris veniam enim. Nulla est esse consectetur officia nostrud officia. Esse aliqua ipsum elit magna eu. Anim ullamco magna excepteur eiusmod velit enim Lorem adipisicing est sit adipisicing commodo velit.\r\n",
    "investment": "Ea mollit deserunt occaecat nostrud non aliquip exercitation reprehenderit voluptate duis cupidatat. Esse magna commodo occaecat culpa aliqua tempor. Dolore eu consequat exercitation ex ea commodo non ullamco labore. Veniam id occaecat commodo non consectetur sunt dolor. Ea anim quis nostrud ipsum excepteur culpa anim ipsum qui reprehenderit dolore aliquip. Sit est velit ipsum reprehenderit ut minim non culpa laboris ipsum Lorem.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Ipsum nulla exercitation reprehenderit sint in occaecat excepteur. Voluptate nostrud officia aliquip sint aute amet aliquip irure. Incididunt est sit magna ad occaecat occaecat deserunt consequat.\r\n",
      "engagementDescr": "Ex exercitation cupidatat magna occaecat officia officia. Nostrud culpa dolore ad laboris cillum sit sunt aliqua voluptate aute culpa. Occaecat sint sunt anim cillum nisi aliqua magna labore labore. Nostrud occaecat ipsum tempor nisi ea aliquip aute.\r\n",
      "dataDescr": "In proident excepteur adipisicing elit in mollit fugiat aliqua cillum voluptate cupidatat. Proident consectetur non cillum eiusmod duis ullamco proident do aute Lorem commodo consectetur Lorem. Aliqua veniam nisi et laborum voluptate sunt sit elit. In amet cupidatat laboris sint laboris laborum ut duis. Laboris reprehenderit duis ipsum incididunt voluptate eiusmod aliquip quis pariatur. Enim esse ut voluptate sint.\r\n",
      "routeDescr": "Et amet et occaecat in non aliquip cillum ullamco cupidatat consectetur ex eu nostrud eu. Elit cillum ut exercitation proident. Eiusmod elit irure incididunt deserunt Lorem magna veniam mollit ad. Laboris amet eiusmod in tempor aliqua. Cillum sint tempor fugiat amet.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Josephine Sosa",
        "email": "josephinesosa@conferia.com",
        "fte": 0.9329
      },
      {
        "id": 1,
        "name": "Blake Wolf",
        "email": "blakewolf@conferia.com",
        "fte": 0.6406
      },
      {
        "id": 2,
        "name": "Burt Sloan",
        "email": "burtsloan@conferia.com",
        "fte": 0.6892
      },
      {
        "id": 3,
        "name": "Hughes Kirkland",
        "email": "hugheskirkland@conferia.com",
        "fte": 0.7467
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Clarissa Hill",
        "email": "clarissahill@conferia.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 16,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 14,
        "y2": 32,
        "y3": 90
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 21,
        "y3": 95
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5193206,
      "customerVal": 335756,
      "mvp": 16740873,
      "optimize": 3134402,
      "grow": 973817032,
      "scale": 883316700
    },
    "nextStepsActionPlan": "In commodo sit sit dolore fugiat amet ad. Occaecat reprehenderit sit enim ex id ipsum mollit qui proident sit ea. Aliquip veniam labore incididunt mollit est ut Lorem. Consectetur consequat id in pariatur ut nisi enim ea id pariatur nulla pariatur id ad. Aute ea mollit labore id.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Magna tempor sint dolor pariatur cupidatat reprehenderit non. Laboris cupidatat deserunt fugiat cupidatat. Aliqua veniam velit irure mollit consequat qui laboris laborum. Enim in esse esse incididunt.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03624ed75534c92d67",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Mondicil",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rp"
    ],
    "geography": "India",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Nulla eu eiusmod sint do laborum voluptate. Ullamco irure ullamco in in pariatur mollit laboris. Ipsum laborum nulla officia nostrud. Velit est dolor nostrud tempor anim ipsum sint magna ad Lorem enim voluptate.\r\n",
    "description": "Labore cillum enim culpa in aliqua dolore sunt excepteur tempor ea aliqua eu fugiat eiusmod. Aliquip adipisicing ut enim dolore officia ullamco sunt adipisicing mollit aliquip. Occaecat pariatur irure exercitation dolor aliquip sunt culpa tempor. Cupidatat et do ea dolor esse deserunt anim elit voluptate tempor.\r\n",
    "strategy": "Exercitation sunt Lorem est exercitation velit occaecat deserunt eu sint non quis nostrud ea anim. Consequat est eiusmod cillum ex tempor. Ea cillum adipisicing consectetur veniam quis magna cillum consectetur consequat. Magna consectetur Lorem aliqua nostrud. Eu ad esse consequat ad ipsum quis. Veniam labore nostrud ipsum ea sit qui nulla ex in cupidatat elit.\r\n",
    "keyLearnings": "Aliqua anim cupidatat quis id aliquip esse ad velit. Irure laboris consequat mollit voluptate esse dolor dolor aliqua culpa in minim reprehenderit occaecat. Excepteur culpa aute ex aliqua laborum. Et aute officia proident officia eu. Ad est laborum nostrud ea ex laborum id do anim elit occaecat incididunt cillum fugiat. Elit eu eu excepteur ea laborum anim eu laborum velit veniam. Nulla in occaecat ut nisi duis proident consequat tempor est culpa in.\r\n",
    "nextSteps": "Sint amet ut tempor sunt. Consequat exercitation consequat sint occaecat elit sit ullamco velit. Aliquip excepteur excepteur ex et tempor ea ipsum veniam ut. Laboris nisi labore mollit laborum occaecat in. Labore nulla tempor esse nostrud. Ullamco eiusmod nostrud ex cupidatat non.\r\n",
    "investment": "Et officia exercitation in mollit quis sit magna ut est incididunt cupidatat deserunt. Consectetur Lorem est sit esse nostrud Lorem nostrud culpa enim. Velit anim dolor sint exercitation qui ex velit nulla proident sunt irure. Enim ea Lorem id laborum cupidatat culpa Lorem aute deserunt. Do veniam excepteur excepteur est exercitation minim consectetur esse nostrud enim. Quis esse do ullamco occaecat mollit aliqua exercitation. Proident dolor et minim aliqua sit aliquip reprehenderit ut quis qui veniam in exercitation ex.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Dolor amet esse irure consectetur fugiat aute enim duis Lorem. Pariatur quis in officia pariatur irure voluptate ullamco exercitation do exercitation occaecat. Reprehenderit minim veniam commodo in cupidatat. Sint labore quis cillum irure amet duis ad ut labore enim ex amet exercitation do. Adipisicing ullamco magna cupidatat duis sint enim pariatur ex labore laboris. Culpa consectetur eu reprehenderit deserunt.\r\n",
      "engagementDescr": "Veniam reprehenderit aliquip magna exercitation fugiat proident velit tempor sit fugiat consequat minim fugiat ex. Adipisicing exercitation veniam ut tempor sit amet fugiat aute qui est adipisicing mollit labore aliquip. Reprehenderit in duis occaecat voluptate in ipsum occaecat nostrud consequat. Anim tempor nostrud aliqua ea reprehenderit fugiat mollit aliquip elit exercitation cillum. Est anim dolor officia duis aliquip exercitation exercitation. Nulla et cupidatat magna officia est eu qui labore ut.\r\n",
      "dataDescr": "Labore consequat velit sit laboris nostrud proident aliquip. Do deserunt velit eiusmod commodo cupidatat sit id. Et aliqua tempor labore mollit fugiat ipsum veniam aliquip. Proident adipisicing sunt nostrud est ipsum quis irure Lorem sint. Veniam do veniam consequat dolor officia laborum id laboris Lorem. Et dolor reprehenderit occaecat magna incididunt aliquip adipisicing commodo. Ea dolor ut labore labore reprehenderit mollit officia minim deserunt amet dolore exercitation incididunt anim.\r\n",
      "routeDescr": "Eiusmod incididunt officia nisi esse ullamco exercitation in laborum ea occaecat aliqua culpa mollit. Consectetur cillum duis deserunt nulla ex nisi non sint exercitation. Adipisicing mollit nisi cupidatat dolore deserunt anim. In Lorem aute eiusmod sunt dolor ut adipisicing culpa nulla. Elit adipisicing proident ullamco eu cillum. Aliquip quis adipisicing est ipsum qui commodo proident. Anim deserunt eiusmod eu ut aute exercitation officia cillum amet tempor elit labore occaecat.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Woodard Burt",
        "email": "woodardburt@mondicil.com",
        "fte": 0.9851
      },
      {
        "id": 1,
        "name": "Terry Jimenez",
        "email": "terryjimenez@mondicil.com",
        "fte": 0.2639
      },
      {
        "id": 2,
        "name": "Rosie Bishop",
        "email": "rosiebishop@mondicil.com",
        "fte": 0.6493
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Hess Shepard",
        "email": "hessshepard@mondicil.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 13,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 31,
        "y3": 81
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 14,
        "y3": 72
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5215298,
      "customerVal": 1374177,
      "mvp": 41105764,
      "optimize": 13566042,
      "grow": 806081621,
      "scale": 1390595469
    },
    "nextStepsActionPlan": "Labore ut nulla aute commodo dolor minim id enim pariatur velit Lorem eiusmod ex duis. Quis esse in ea aliqua eiusmod. Voluptate laboris duis non magna minim non amet commodo.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cillum cillum laboris aute sit consectetur id consectetur officia cillum incididunt cillum. Anim elit sunt reprehenderit Lorem sit. Anim elit dolor quis ullamco nisi aliqua. Cillum incididunt deserunt deserunt veniam esse commodo cillum proident elit cillum ullamco est. Aute nulla tempor enim veniam cupidatat laboris nostrud dolor elit aliquip. Fugiat ut exercitation esse fugiat amet eiusmod officia fugiat deserunt aliquip incididunt.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-09-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03f4b98e1e4566f524",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Franscene",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rr"
    ],
    "geography": "India",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Ut enim nisi laboris non. Ea eu excepteur cupidatat aute sint occaecat voluptate. Cupidatat in culpa reprehenderit officia ipsum in id sit commodo minim labore ad exercitation laboris. Incididunt fugiat elit ad tempor mollit exercitation dolore. Voluptate dolor velit ex culpa cupidatat nostrud excepteur deserunt.\r\n",
    "description": "Dolore commodo eu anim ullamco do aliqua non exercitation enim sit nisi et reprehenderit esse. Voluptate do cillum ea minim labore ex proident sint laborum aute laborum quis. Irure in laboris non nisi amet aliqua Lorem do consequat.\r\n",
    "strategy": "Mollit incididunt officia voluptate magna eiusmod. Officia sint reprehenderit cupidatat mollit exercitation duis sunt do. Incididunt mollit laboris excepteur ex sit sunt. Est consectetur pariatur Lorem excepteur excepteur in magna id cupidatat sit fugiat.\r\n",
    "keyLearnings": "Est sint sunt deserunt proident ea ea ipsum adipisicing. Laboris consectetur fugiat mollit minim. Excepteur ad ipsum irure nisi minim dolore do eu Lorem. Magna mollit Lorem do ea. Aliqua et nostrud voluptate qui velit commodo commodo proident ullamco pariatur id dolore. Elit irure ut nostrud consectetur sint mollit nulla aute qui. Adipisicing ea labore velit aliquip ex elit non fugiat labore irure.\r\n",
    "nextSteps": "Dolor dolore sunt qui nulla culpa anim ut eu voluptate sunt reprehenderit. Ipsum elit est culpa voluptate labore nulla. Lorem ad ea esse nulla excepteur enim consequat mollit adipisicing nulla ipsum.\r\n",
    "investment": "Nisi cillum ipsum consectetur cupidatat voluptate duis minim dolore consequat veniam aliqua et magna. Nostrud ipsum irure consectetur culpa occaecat sit. Aliqua aute non qui ullamco qui deserunt dolore exercitation velit labore. Mollit dolore tempor sit amet veniam voluptate laboris labore incididunt non nisi ullamco irure magna. Eiusmod anim et adipisicing esse aliqua fugiat esse. Ut voluptate irure dolor aliquip id dolore est tempor esse. Culpa labore dolore reprehenderit nulla ea enim fugiat voluptate magna et.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Id esse minim dolore esse et sint. Dolore do sit amet eu est deserunt. Irure eu esse veniam non dolore enim ea aute amet magna pariatur magna et voluptate. Dolore officia qui irure ipsum commodo.\r\n",
      "engagementDescr": "Qui voluptate id minim mollit dolore aliqua dolore aute laboris do qui nisi cupidatat. Est eu irure incididunt ea et dolore incididunt mollit irure esse. Velit ut anim minim cillum sunt officia sit officia.\r\n",
      "dataDescr": "Sint sint cillum laborum cillum consequat consequat occaecat pariatur veniam esse laborum. Ad enim dolore elit pariatur ex laborum ex dolore dolore reprehenderit occaecat. Cillum id non culpa enim irure amet. Commodo nulla nisi consectetur do aute proident ipsum minim adipisicing quis exercitation labore aliquip. Nisi minim eu in id in veniam incididunt laboris adipisicing veniam in aliquip. Et consequat magna pariatur anim exercitation duis dolor non. Magna est incididunt occaecat nisi occaecat commodo do aliqua cillum exercitation magna officia.\r\n",
      "routeDescr": "Ea occaecat consequat tempor incididunt voluptate enim dolor labore incididunt. Cillum dolore enim Lorem reprehenderit non dolore. Incididunt ea officia elit elit aute ad anim laborum in cillum dolore nulla Lorem. Eu duis pariatur est esse nostrud fugiat nisi duis aute laborum. Et et nisi minim veniam culpa in deserunt pariatur anim. Minim consectetur sint do exercitation consectetur ea.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Inez Rodriquez",
        "email": "inezrodriquez@franscene.com",
        "fte": 0.2624
      },
      {
        "id": 1,
        "name": "Lester Sheppard",
        "email": "lestersheppard@franscene.com",
        "fte": 0.5018
      },
      {
        "id": 2,
        "name": "Eileen Moody",
        "email": "eileenmoody@franscene.com",
        "fte": 0.0061
      },
      {
        "id": 3,
        "name": "Galloway Lamb",
        "email": "gallowaylamb@franscene.com",
        "fte": 0.0679
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Valencia Osborne",
        "email": "valenciaosborne@franscene.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 10,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 45,
        "y3": 59
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 30,
        "y3": 94
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12292303,
      "customerVal": 1096494,
      "mvp": 103902266,
      "optimize": 2183331,
      "grow": 466554855,
      "scale": 1362998041
    },
    "nextStepsActionPlan": "Ipsum ipsum magna exercitation sint. Esse minim culpa id ipsum et ipsum. Ipsum amet duis sunt elit mollit cupidatat elit id. Ad ea irure sunt cillum elit eu id excepteur. Eiusmod Lorem anim pariatur ex consectetur cillum qui do mollit deserunt culpa. Non deserunt veniam enim qui incididunt exercitation reprehenderit do culpa anim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Culpa officia aute laboris ut cupidatat mollit laboris sunt amet consectetur reprehenderit laboris ullamco. Esse elit aute ipsum ut ut voluptate fugiat commodo aliqua nulla nulla. Culpa voluptate nulla consectetur sunt ut adipisicing consectetur eiusmod eu consequat incididunt adipisicing.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-14",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e037170226ad098803b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Sentia",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "China",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Tempor culpa nisi eu occaecat. Ut pariatur esse Lorem ad. Proident occaecat officia sit ullamco pariatur aute deserunt enim irure cupidatat laborum do nulla. Culpa do exercitation proident ipsum incididunt id in aliquip officia Lorem duis quis consectetur excepteur. Sint nulla irure anim labore culpa sint elit sint. Laborum ad esse nostrud exercitation officia deserunt adipisicing ut commodo aliquip tempor.\r\n",
    "description": "Ea sit Lorem veniam anim et. Ullamco mollit proident aute ipsum qui adipisicing dolore irure dolore. Amet eu dolore dolor deserunt.\r\n",
    "strategy": "Ex culpa irure aliquip proident. Culpa consectetur deserunt enim sint enim proident dolore laborum commodo eu laborum exercitation. Cillum sint sint aute ut ad. Occaecat ea cupidatat eiusmod aliquip labore voluptate magna. Aute Lorem est do labore occaecat exercitation excepteur nostrud exercitation. Consequat ea ullamco sunt laborum.\r\n",
    "keyLearnings": "Quis consequat Lorem amet occaecat. Duis irure enim ipsum sit laboris laboris est commodo non et dolore id. Lorem amet ipsum veniam deserunt exercitation. Excepteur ad ea consequat magna nulla consequat officia voluptate. Aute labore cillum occaecat anim cupidatat magna laborum officia. Sit laboris amet id duis occaecat reprehenderit irure id dolor.\r\n",
    "nextSteps": "Occaecat aliqua elit in Lorem aliqua sunt. Duis labore veniam tempor excepteur adipisicing voluptate reprehenderit culpa sint voluptate culpa et ullamco adipisicing. Ad consequat consequat elit consequat veniam quis. Mollit in ipsum do sit elit magna cupidatat aliquip mollit eiusmod ad. Elit commodo cillum pariatur occaecat est laboris do incididunt ea. Cillum reprehenderit id elit duis. Dolore deserunt anim ullamco sit culpa culpa labore laborum minim quis ad velit sit.\r\n",
    "investment": "Aliqua eu cillum non duis proident aliqua veniam culpa veniam aliquip sint consectetur eu ad. Sint magna ullamco duis adipisicing. Dolore sunt aute aliqua dolore ipsum aliqua excepteur sint dolore cupidatat tempor sunt elit consectetur. Aliqua ex qui eu ex minim magna officia in culpa fugiat aliquip sint deserunt duis. Proident amet ea consequat dolore proident esse tempor reprehenderit eu reprehenderit aliqua irure eu eu. In mollit aute Lorem est eiusmod aute est nulla eiusmod laboris Lorem aliquip amet.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Consectetur esse nostrud fugiat do deserunt sunt cillum excepteur ad deserunt do irure dolor. Veniam voluptate sunt quis tempor nisi fugiat id excepteur sunt irure velit exercitation minim enim. Aliqua cupidatat deserunt voluptate aliqua tempor ex do fugiat quis tempor eu tempor dolor. Et enim amet nostrud ad. Deserunt eu commodo exercitation exercitation mollit dolore sint ad fugiat id labore.\r\n",
      "engagementDescr": "Proident officia cupidatat aliqua deserunt elit sunt voluptate reprehenderit eu aliqua quis ipsum non amet. Duis commodo est laboris adipisicing exercitation non sunt occaecat mollit ipsum pariatur. Excepteur duis proident nisi dolore fugiat nulla ut. Dolor laborum duis officia id voluptate cupidatat amet et culpa elit.\r\n",
      "dataDescr": "Ex elit adipisicing qui labore. Aliquip irure id officia cupidatat ea enim adipisicing excepteur ex. Culpa proident sunt nostrud mollit ad qui sunt ut amet dolor duis. Occaecat minim ut et cupidatat do tempor irure enim nostrud nisi quis fugiat voluptate. Pariatur officia magna amet mollit mollit officia commodo incididunt eu culpa mollit irure.\r\n",
      "routeDescr": "Ullamco deserunt adipisicing mollit eiusmod duis eiusmod dolore incididunt id dolor veniam ut. Irure in minim exercitation ipsum. Commodo excepteur dolore minim laborum qui proident tempor sint consectetur cillum deserunt. Commodo consequat officia consequat incididunt occaecat velit aliqua sit sit qui enim officia et laboris. Aute ipsum deserunt enim eu aliquip quis proident laborum sit aliquip veniam dolore reprehenderit ipsum. Adipisicing ut eiusmod voluptate pariatur.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Bernard Ward",
        "email": "bernardward@sentia.com",
        "fte": 0.029
      },
      {
        "id": 1,
        "name": "Ochoa Spence",
        "email": "ochoaspence@sentia.com",
        "fte": 0.3051
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Trudy Mccall",
        "email": "trudymccall@sentia.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 12,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 34,
        "y3": 90
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 26,
        "y3": 52
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 346714,
      "customerVal": 1439047,
      "mvp": 51833987,
      "optimize": 13438335,
      "grow": 1386708555,
      "scale": 239769303
    },
    "nextStepsActionPlan": "Adipisicing dolor consequat irure ea tempor ea consectetur et. Irure ea consectetur ut consequat proident consectetur voluptate esse eu proident velit voluptate. Est proident ullamco labore velit et est exercitation magna. Id voluptate amet nisi sit magna dolor irure. Consectetur ad nulla laboris exercitation voluptate est ea labore reprehenderit proident sit do mollit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolor nisi qui cillum duis et. Est eiusmod commodo anim elit ipsum ex et fugiat. Nisi reprehenderit elit laborum consequat sunt. Non incididunt dolor nostrud sit excepteur minim culpa. Excepteur do nulla fugiat velit excepteur ipsum labore dolor anim. Excepteur dolor anim tempor laboris aliquip ipsum ex ea consectetur pariatur ullamco laboris. Eiusmod labore excepteur esse do cupidatat enim exercitation excepteur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-14",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0305cfcb36fd4f7736",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Retrack",
    "highlightKPI": [
      "cac",
      "ltv",
      "rr"
    ],
    "geography": "China",
    "channel": "Offline: Shop in shop",
    "division": "Division Two",
    "painPoint": "Dolore irure laborum adipisicing non officia quis est id. Fugiat veniam aliqua non labore minim officia officia. Reprehenderit sit mollit aliqua culpa elit eiusmod deserunt laborum dolore dolor qui consectetur cupidatat do.\r\n",
    "description": "Amet anim officia tempor anim esse consequat nisi elit commodo labore anim. Labore laborum amet ad fugiat sunt exercitation incididunt sunt est tempor deserunt est proident. Enim irure in ex labore dolore elit elit et labore ex sit. Aute culpa laborum culpa duis Lorem tempor enim non. Sit sint sint ipsum nisi commodo cillum incididunt magna proident culpa sunt. Eu est duis exercitation sint.\r\n",
    "strategy": "Sint voluptate est quis consectetur aliquip. Amet aliquip anim ex sint nulla voluptate dolore proident exercitation eu ut pariatur aliqua exercitation. Consequat magna ex laborum nostrud reprehenderit elit cillum mollit ea amet sit do laboris. Aliqua non voluptate occaecat ipsum est pariatur excepteur ut minim consectetur adipisicing.\r\n",
    "keyLearnings": "Laboris nostrud anim tempor reprehenderit ad laborum velit dolor velit. Veniam pariatur amet non commodo. Consequat aute ea dolore nisi et nulla qui id commodo excepteur deserunt deserunt tempor ipsum. Excepteur veniam ut incididunt velit id commodo.\r\n",
    "nextSteps": "Culpa pariatur commodo non nulla sit do non minim mollit amet amet consequat. Duis irure Lorem non minim laborum sint sint ea aliqua cillum culpa veniam. Esse eiusmod laborum quis amet nostrud consequat proident ex ex. Eu enim sint amet cupidatat et laborum dolore. Eu cillum consectetur ex magna sunt. Reprehenderit cupidatat id ullamco dolor esse eu laboris sit. Cillum minim aliquip voluptate nostrud amet exercitation magna.\r\n",
    "investment": "Irure ipsum esse culpa ad amet laborum ea ex exercitation duis excepteur esse laborum culpa. Consequat nostrud irure laboris consectetur nostrud fugiat consectetur voluptate nulla. Laborum laboris consequat amet ea tempor. Enim voluptate magna dolore veniam excepteur quis qui nulla ullamco aliquip irure labore. Ad commodo irure aliqua ea.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Dolore eiusmod dolor labore voluptate Lorem anim ut exercitation. Id dolor anim adipisicing eu et officia. Adipisicing ut in officia ullamco est Lorem aliqua sunt consectetur mollit. Enim commodo enim ad minim labore eiusmod minim consequat id ut reprehenderit reprehenderit minim adipisicing. Aliquip id nisi deserunt laborum est qui laborum irure. Eu proident nostrud eiusmod dolore tempor aliquip culpa sit enim.\r\n",
      "engagementDescr": "Ut excepteur ad tempor excepteur tempor. Cillum amet nostrud quis sint consectetur ad non consectetur laborum est. Officia magna velit pariatur minim officia excepteur velit anim enim commodo consectetur. Enim sit labore adipisicing quis labore aliqua adipisicing laboris. In magna cupidatat id magna deserunt Lorem eiusmod incididunt pariatur qui. Ut non non aute aute fugiat sint sit incididunt ipsum occaecat.\r\n",
      "dataDescr": "Enim commodo aliqua nisi quis adipisicing excepteur. Do sint do ea exercitation laborum sit. Minim Lorem dolore amet dolor nostrud ad veniam cupidatat proident.\r\n",
      "routeDescr": "Id quis reprehenderit culpa dolore culpa cupidatat ea elit esse occaecat occaecat. Mollit adipisicing veniam Lorem eiusmod labore esse ad sit. Et do mollit non deserunt reprehenderit cupidatat. Magna tempor velit irure nostrud consectetur amet pariatur. Aute aliquip magna irure ex reprehenderit Lorem pariatur.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Meghan Short",
        "email": "meghanshort@retrack.com",
        "fte": 0.895
      },
      {
        "id": 1,
        "name": "Barber Tate",
        "email": "barbertate@retrack.com",
        "fte": 0.9575
      },
      {
        "id": 2,
        "name": "Rasmussen Boone",
        "email": "rasmussenboone@retrack.com",
        "fte": 0.1976
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Elsa Mcintyre",
        "email": "elsamcintyre@retrack.com"
      },
      {
        "id": 1,
        "name": "Gay King",
        "email": "gayking@retrack.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 17,
        "y3": 20
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 31,
        "y3": 89
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 26,
        "y3": 68
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 265156,
      "customerVal": 1238640,
      "mvp": 4253372,
      "optimize": 8655155,
      "grow": 1481938972,
      "scale": 304167752
    },
    "nextStepsActionPlan": "Dolore do minim cillum commodo cupidatat nisi ad reprehenderit fugiat culpa. Exercitation culpa ullamco labore fugiat. Eiusmod voluptate mollit cupidatat elit amet magna labore pariatur ut. Ea excepteur qui ex consequat aliqua. Nostrud aliqua officia aute ex ea aute esse aliqua laborum est.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cupidatat deserunt esse exercitation et incididunt elit. Elit aliquip ad officia cupidatat nulla laboris minim nostrud veniam consequat proident. Incididunt ullamco do officia ullamco exercitation ipsum veniam excepteur duis officia pariatur et irure proident. Ipsum laboris sint veniam voluptate nostrud. Cillum non labore laborum aliquip ex. Dolor eiusmod occaecat esse anim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-27",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03dbd9f0b31f884d94",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Ontality",
    "highlightKPI": [
      "cac",
      "bcr",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Online: Flagship store",
    "division": "Division Three",
    "painPoint": "Eu minim magna ea non nostrud consectetur cupidatat. Pariatur et eiusmod elit consequat incididunt qui amet aliquip. Eiusmod consectetur est ullamco ea pariatur adipisicing mollit adipisicing et laborum ea laboris adipisicing incididunt.\r\n",
    "description": "Et ipsum aliqua proident magna laborum dolor ut. Ipsum nisi ipsum mollit velit consectetur enim reprehenderit fugiat cillum. Ipsum aliqua et do nisi dolor officia cupidatat nisi incididunt duis. Adipisicing consectetur deserunt consectetur do aute velit cillum ullamco.\r\n",
    "strategy": "Officia sit consectetur nisi culpa aute. Aliquip ullamco nostrud in ut minim duis voluptate. Nostrud cillum do dolor quis.\r\n",
    "keyLearnings": "Nostrud dolore amet commodo do ut enim nisi sint aute id excepteur ipsum. Mollit aute enim non deserunt ad labore eiusmod proident irure aliquip. Id qui labore eu ad et sint pariatur amet ea. Id nisi exercitation eu irure laborum laboris proident.\r\n",
    "nextSteps": "Ullamco adipisicing mollit sit proident do ea consequat occaecat adipisicing proident cillum pariatur deserunt commodo. Incididunt minim aliquip qui ad aliquip officia ad anim aliqua pariatur veniam commodo enim culpa. Excepteur anim esse aliqua et sit excepteur eiusmod cupidatat consectetur eiusmod quis. Non ex dolor consequat pariatur. Voluptate sit velit cillum tempor tempor non magna minim commodo pariatur nulla. Irure in pariatur aute ex ea nostrud aute occaecat elit ipsum. Sunt in non reprehenderit do do eiusmod eiusmod anim laborum eu.\r\n",
    "investment": "Dolor pariatur irure tempor Lorem consequat dolor quis aliqua aliqua. Cupidatat ex deserunt qui sunt reprehenderit ea. Nulla irure culpa do aliquip ad voluptate velit qui officia velit aute. Ipsum nulla id do reprehenderit consequat aliqua. Adipisicing consectetur laborum veniam eiusmod amet aute tempor magna cillum nostrud ea. Velit est irure ex ut nostrud occaecat. Ea laboris do mollit cupidatat.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Occaecat amet veniam nostrud qui eu aliqua dolor ipsum. Laborum minim aliquip sunt in non deserunt est magna proident ullamco adipisicing. Excepteur ea mollit ex do amet. Dolor sit amet nulla qui laboris eiusmod pariatur qui aute adipisicing mollit ex. Occaecat ullamco commodo proident fugiat laborum exercitation. Anim nostrud mollit cupidatat sit ipsum magna quis consectetur deserunt aliqua ipsum ullamco cillum. Veniam qui elit id irure commodo proident commodo nostrud sit veniam excepteur minim eu tempor.\r\n",
      "engagementDescr": "Occaecat do sint mollit cupidatat laboris amet do occaecat minim consectetur laborum. Proident enim nostrud nisi pariatur eu veniam laborum. Sit non sint consequat quis laborum aliqua cillum nulla mollit. Exercitation culpa et fugiat reprehenderit et. Elit amet duis exercitation dolor proident laboris minim aute nisi consequat nisi. Nulla ea velit exercitation dolore deserunt. Magna incididunt sunt enim cupidatat culpa proident.\r\n",
      "dataDescr": "Cillum tempor amet do ullamco veniam reprehenderit consectetur voluptate. Nulla anim aute mollit sint aliqua. Laboris et consectetur eiusmod officia nisi qui consequat dolor. Dolor qui fugiat veniam magna veniam sint duis mollit non id sit voluptate reprehenderit mollit. Laborum irure cupidatat eu elit fugiat. Irure pariatur cillum eiusmod incididunt aliquip sint eiusmod laboris in reprehenderit aute officia velit enim. Id eu id consectetur amet.\r\n",
      "routeDescr": "Sit irure laborum do amet aliquip ullamco. Sit est incididunt ullamco pariatur sint commodo elit. Amet deserunt dolor laborum ipsum labore laborum laboris. Enim ullamco id mollit nulla sit aliqua aliqua enim cupidatat Lorem sit amet enim. Dolor ea nostrud enim nulla officia laboris ullamco fugiat cillum excepteur amet proident sint. Occaecat laboris et in nulla duis fugiat occaecat cillum. Cupidatat in ex enim eu.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Ryan David",
        "email": "ryandavid@ontality.com",
        "fte": 0.9382
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kelley Salazar",
        "email": "kelleysalazar@ontality.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 12,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 18,
        "y2": 37,
        "y3": 65
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 21,
        "y3": 80
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12752685,
      "customerVal": 1166792,
      "mvp": 148859630,
      "optimize": 7533588,
      "grow": 597464223,
      "scale": 446452557
    },
    "nextStepsActionPlan": "Pariatur ullamco cupidatat irure sit consectetur et. Sit adipisicing dolor voluptate ut laborum tempor ut dolore deserunt culpa proident elit. Minim excepteur adipisicing laborum consectetur minim. Lorem labore elit nulla incididunt exercitation voluptate magna voluptate veniam.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Enim sint tempor aute sint veniam veniam id aute anim non id. Anim id ullamco occaecat elit commodo duis velit velit do aute est. Consectetur velit amet irure non laborum nisi. In nostrud officia mollit esse ad ut voluptate amet occaecat ea nisi. Duis minim mollit esse id dolore sint quis magna.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-06",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e031a2d398775988462",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Pathways",
    "highlightKPI": [
      "cac",
      "bcr",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Ut ullamco consequat cillum duis velit minim duis. Non commodo veniam fugiat ea aliqua dolore ut est dolor officia et nisi velit do. Pariatur nostrud quis quis dolor ipsum enim adipisicing elit duis irure velit aliquip commodo. Aliquip nisi proident esse ea irure do dolor in ullamco duis in. Eiusmod cupidatat officia eu esse velit qui laborum cupidatat reprehenderit tempor est minim deserunt.\r\n",
    "description": "Magna deserunt laboris qui commodo sunt in proident adipisicing irure deserunt et pariatur. Pariatur fugiat ex dolore exercitation. Aliquip laboris minim commodo nisi dolore.\r\n",
    "strategy": "Cupidatat sunt velit culpa do do incididunt. Culpa irure nisi consequat voluptate tempor in cillum deserunt. Nulla dolore adipisicing amet occaecat nostrud officia voluptate. Id consectetur ullamco commodo esse Lorem mollit veniam laborum enim quis ullamco labore mollit. Proident fugiat irure anim veniam in fugiat exercitation incididunt enim duis veniam.\r\n",
    "keyLearnings": "Culpa laborum et aliqua eu irure dolor laborum esse exercitation velit mollit labore. Consequat nulla dolore in sit non enim magna. Eiusmod sunt excepteur cupidatat nisi eu ad fugiat eu eiusmod nostrud ullamco. Ipsum qui minim cillum elit pariatur Lorem.\r\n",
    "nextSteps": "Quis fugiat Lorem fugiat deserunt esse Lorem irure do ex proident. Do aliqua nostrud quis est anim. Nisi tempor nostrud ipsum sint eiusmod eu. Nulla dolore occaecat fugiat aute pariatur magna incididunt cillum consequat laboris nulla tempor qui. Consequat tempor consequat voluptate consequat exercitation labore eu magna laborum do consectetur. Aliquip occaecat consequat cillum nostrud qui irure ipsum eiusmod nulla consectetur nostrud. Ex ea reprehenderit fugiat fugiat sint.\r\n",
    "investment": "In nostrud laboris culpa esse ullamco fugiat ea do. Cillum cillum irure do in qui consequat in sunt occaecat laboris nostrud anim qui. Magna id deserunt ad voluptate ipsum aliqua aute est ex fugiat voluptate consectetur eu qui. Commodo do nostrud qui aliqua cillum. Anim labore reprehenderit ea occaecat.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Reprehenderit cupidatat qui ex amet enim quis exercitation anim incididunt ea occaecat laboris esse deserunt. Nulla qui minim nostrud Lorem deserunt quis labore velit duis. Sit dolore non aute dolore nulla culpa Lorem ut occaecat sit irure sunt in amet. Sit irure consectetur aliqua sint sunt ad laboris.\r\n",
      "engagementDescr": "Consectetur est quis magna culpa laborum officia laboris velit. Ut cillum aliqua officia nulla laborum nostrud non velit aliqua. Deserunt consectetur amet enim ut est esse mollit excepteur laboris. Exercitation ad laborum labore proident consequat ad consequat Lorem duis esse enim. Et est magna eiusmod amet fugiat magna eu fugiat deserunt est.\r\n",
      "dataDescr": "Magna voluptate sint voluptate ullamco sunt eu laboris adipisicing aute eiusmod. Esse non excepteur in sint. Sunt cupidatat est ut minim cupidatat nulla quis commodo nulla enim fugiat sunt reprehenderit. Veniam velit commodo sint nisi.\r\n",
      "routeDescr": "Occaecat aute ipsum dolore nisi labore. Excepteur sunt ea amet in ea. Commodo tempor do eiusmod cillum nisi laboris pariatur ut. Deserunt nostrud aute quis deserunt pariatur laborum mollit ea. In incididunt dolore et nostrud duis commodo tempor Lorem eu laboris veniam consequat cillum consequat. Sunt commodo excepteur dolore sunt labore sit mollit dolor tempor sint proident anim.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Avis Ford",
        "email": "avisford@pathways.com",
        "fte": 0.0045
      },
      {
        "id": 1,
        "name": "Elinor Bolton",
        "email": "elinorbolton@pathways.com",
        "fte": 0.3925
      },
      {
        "id": 2,
        "name": "Aida Williamson",
        "email": "aidawilliamson@pathways.com",
        "fte": 0.1707
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Maryanne Nash",
        "email": "maryannenash@pathways.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 20,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 3,
        "y2": 43,
        "y3": 87
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 18,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14263708,
      "customerVal": 681837,
      "mvp": 34681279,
      "optimize": 9749808,
      "grow": 811282770,
      "scale": 586436441
    },
    "nextStepsActionPlan": "Nostrud enim consequat nostrud dolor id nulla aliquip excepteur ut minim laboris. In cupidatat ipsum enim aute ex est quis anim nisi est. Consectetur nostrud nisi et nostrud anim qui enim. Incididunt quis veniam labore adipisicing deserunt dolor esse ea eu pariatur. Qui ex proident in pariatur sunt incididunt enim irure proident id duis cupidatat. Nostrud magna aliquip quis in voluptate quis id est irure veniam dolore non sunt incididunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Magna amet fugiat ex exercitation consectetur. In sunt commodo excepteur eiusmod. Cillum ad nostrud culpa enim occaecat ex exercitation aliqua minim voluptate eiusmod.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-01",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0314c4ca6bdfad2007",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Gology",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Online: Marketpace",
    "division": "Division Two",
    "painPoint": "Non ut anim sunt nostrud velit ullamco et veniam pariatur fugiat et ad. Aute mollit duis in officia minim laboris ipsum enim laboris do do. Quis magna et sunt labore minim officia laboris ad nisi elit commodo est irure veniam. Nisi incididunt cillum consectetur nulla eiusmod fugiat irure quis. Sit enim est aliqua labore ex ipsum ad exercitation do ut reprehenderit. Nostrud consequat cillum laboris nulla voluptate Lorem et aliqua. Id irure fugiat aliquip sit nisi irure anim aliqua ipsum eu esse aute aliquip.\r\n",
    "description": "Consectetur do consectetur voluptate cupidatat deserunt officia Lorem voluptate Lorem dolore veniam labore. Culpa quis sunt ipsum consectetur cillum sunt qui anim nulla consequat enim excepteur. Elit sint ullamco velit Lorem aute exercitation incididunt deserunt sunt veniam mollit ea. Consectetur officia adipisicing velit irure consequat do in enim pariatur amet. Non dolore reprehenderit reprehenderit sint do sunt proident ex sit sint occaecat voluptate dolore. Sunt commodo sit consequat velit sit. Labore magna sint elit elit labore anim.\r\n",
    "strategy": "Ad eiusmod adipisicing ex mollit do do exercitation. Esse adipisicing anim aliqua voluptate eiusmod deserunt in. Nulla ad reprehenderit eiusmod minim incididunt sit id ea est laboris non do labore eiusmod. Reprehenderit nulla Lorem minim laboris consectetur.\r\n",
    "keyLearnings": "Voluptate id sunt labore veniam nostrud qui deserunt. Qui officia eiusmod dolor in consequat pariatur laboris esse elit. Occaecat dolor officia in velit ipsum tempor reprehenderit.\r\n",
    "nextSteps": "Ex laborum anim cupidatat amet esse. Occaecat voluptate fugiat sit aliquip aliquip qui. Ullamco cillum do veniam dolor cillum deserunt fugiat ex sint nulla. Esse consequat sit velit laborum adipisicing non ex qui Lorem velit enim mollit. Sit ut ea sint pariatur tempor voluptate fugiat cillum officia pariatur. Est magna adipisicing qui do commodo cupidatat id irure ad sunt esse laborum. In proident aliquip laboris incididunt.\r\n",
    "investment": "Enim fugiat excepteur sunt minim consequat officia sit. Amet ipsum cillum ullamco quis fugiat ut velit sunt eu. Laboris enim duis aliquip voluptate quis culpa pariatur consectetur minim aute excepteur enim aute. Nostrud qui veniam officia deserunt adipisicing duis.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Dolor officia laborum ex reprehenderit enim sint Lorem nulla Lorem velit consectetur consectetur Lorem. Cupidatat ullamco nulla pariatur tempor in voluptate est esse aliqua. Cillum nostrud consequat enim ut enim velit ut incididunt sint nulla quis pariatur ipsum qui. Irure et dolore aliqua aliqua do est Lorem reprehenderit est cupidatat qui magna. Nostrud aliqua voluptate commodo ullamco deserunt veniam elit duis ad reprehenderit tempor quis deserunt. Voluptate do fugiat ex aliquip incididunt amet excepteur et.\r\n",
      "engagementDescr": "Ipsum nostrud exercitation pariatur anim tempor excepteur magna laborum enim magna. Ex laborum consectetur non aute nulla est incididunt occaecat ex labore anim veniam. Amet ex ullamco eiusmod magna tempor pariatur magna labore enim excepteur officia occaecat. Commodo duis anim duis proident proident aliqua labore commodo laborum. Dolore ut ipsum ullamco id laborum qui reprehenderit Lorem eiusmod aliqua.\r\n",
      "dataDescr": "Est adipisicing magna laborum ex veniam ex ipsum veniam excepteur aliquip. Ut velit est commodo adipisicing eiusmod exercitation in Lorem eu. Ea cillum sit eiusmod cupidatat laborum eiusmod ad aliquip adipisicing nulla duis qui.\r\n",
      "routeDescr": "In cillum mollit amet in et fugiat consectetur voluptate tempor sint sunt esse. Sint occaecat ea aute pariatur laboris quis. Duis incididunt reprehenderit proident duis occaecat exercitation cupidatat enim laboris. Adipisicing Lorem id veniam mollit voluptate ea sint duis. Ullamco Lorem incididunt id elit ullamco labore culpa incididunt aliquip esse. Anim consequat dolore minim aute.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Oneil Petersen",
        "email": "oneilpetersen@gology.com",
        "fte": 0.7191
      },
      {
        "id": 1,
        "name": "Farley Bernard",
        "email": "farleybernard@gology.com",
        "fte": 0.2832
      },
      {
        "id": 2,
        "name": "Barrett Guzman",
        "email": "barrettguzman@gology.com",
        "fte": 0.7618
      },
      {
        "id": 3,
        "name": "Essie Jenkins",
        "email": "essiejenkins@gology.com",
        "fte": 0.1654
      },
      {
        "id": 4,
        "name": "Kelly Skinner",
        "email": "kellyskinner@gology.com",
        "fte": 0.7978
      },
      {
        "id": 5,
        "name": "Marva Miranda",
        "email": "marvamiranda@gology.com",
        "fte": 0.5773
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Andrews Kennedy",
        "email": "andrewskennedy@gology.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 17,
        "y3": 32
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 26,
        "y3": 65
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 23,
        "y3": 68
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10837190,
      "customerVal": 1284212,
      "mvp": 27866494,
      "optimize": 1539108,
      "grow": 1346800771,
      "scale": 1310087286
    },
    "nextStepsActionPlan": "Sit reprehenderit magna velit adipisicing velit ad aute. Nisi in exercitation minim occaecat dolor do aliqua aliqua eu anim ipsum aute cillum deserunt. Lorem consectetur mollit quis reprehenderit dolore enim velit reprehenderit reprehenderit incididunt. Sunt commodo veniam do aliquip consequat magna deserunt dolore pariatur labore in magna aute sint. Et id ullamco aliqua voluptate dolore non excepteur labore ex voluptate ea fugiat. Excepteur id consectetur aliquip occaecat. Nulla nostrud consectetur aute fugiat sint nostrud mollit et laboris irure.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cillum et cillum est anim Lorem velit deserunt sit aliquip cillum commodo laborum ea consectetur. Officia consectetur est commodo ipsum do commodo pariatur nostrud. Lorem ullamco fugiat eiusmod labore non et pariatur. Labore deserunt duis occaecat nostrud incididunt Lorem nulla adipisicing exercitation exercitation. Deserunt veniam tempor Lorem occaecat incididunt sit exercitation sit cillum minim eiusmod commodo. Veniam aliquip minim cillum aliqua.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039b9f9c9574510956",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Exostream",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rr"
    ],
    "geography": "Norway",
    "channel": "Offline: Pop up store",
    "division": "Division One",
    "painPoint": "Sint qui sit et pariatur est dolor aute commodo. Nulla nostrud pariatur minim consectetur tempor elit ex ullamco laboris excepteur anim. Eu elit minim fugiat commodo eu aliquip quis minim. Exercitation sit aute officia duis aliquip. Aute non ex aliquip commodo eiusmod minim. Dolor ex ullamco culpa reprehenderit velit velit labore officia aliquip elit deserunt velit anim. Esse officia id fugiat laborum voluptate ad voluptate labore ipsum proident.\r\n",
    "description": "Fugiat velit nostrud excepteur elit tempor labore occaecat. Labore commodo laborum minim non culpa veniam Lorem deserunt et culpa ipsum aliquip pariatur. Ut aute id sint cillum elit et dolor reprehenderit dolor. Quis minim proident consequat anim quis sunt tempor consequat elit. Est occaecat officia consequat irure eu et proident qui Lorem sit fugiat esse. Non minim proident proident consequat mollit occaecat nisi qui.\r\n",
    "strategy": "Consequat mollit culpa excepteur commodo commodo mollit nostrud non irure ipsum. Est sunt sunt nulla consequat nisi incididunt cupidatat laboris incididunt. Nostrud dolore labore est aliqua labore aute tempor ex ut do aliqua. Lorem proident laborum nulla deserunt consequat adipisicing ut dolore in. Enim est tempor minim et consectetur dolor. Cillum ipsum in ipsum et occaecat amet occaecat. Elit fugiat irure culpa amet dolor eu veniam eiusmod sint.\r\n",
    "keyLearnings": "Magna esse cillum aliqua nostrud aliqua adipisicing eiusmod laboris. Aute adipisicing anim laborum mollit adipisicing labore eu eiusmod pariatur deserunt adipisicing cupidatat aute. Irure do deserunt aute cillum magna eiusmod id nostrud velit. Deserunt in magna quis non labore irure occaecat ipsum eiusmod amet sint. Ullamco dolor dolor pariatur nulla sint commodo nulla Lorem ad elit. In cupidatat nisi fugiat amet sint consequat exercitation do ex.\r\n",
    "nextSteps": "Laborum cupidatat officia laborum dolor aute deserunt cillum voluptate ut quis consectetur mollit id. Magna nulla amet eu minim ad laborum et ad fugiat ex nulla. Cillum quis dolor id ex minim id aliqua consectetur sit do aliqua nostrud non laborum. Aute laborum et aliqua amet reprehenderit Lorem sunt voluptate qui est. Cillum quis id esse aliquip. Duis nostrud Lorem aliquip sunt labore consectetur ullamco magna nisi ex. Occaecat commodo do nisi elit nostrud sunt reprehenderit excepteur enim esse.\r\n",
    "investment": "Consequat excepteur ex culpa eu aliqua duis mollit pariatur. Occaecat ullamco sit velit sint in occaecat sit. Cupidatat ullamco pariatur reprehenderit ipsum deserunt ad magna et. Ad veniam adipisicing proident sit duis dolore irure ad fugiat est Lorem adipisicing reprehenderit dolore. Reprehenderit eiusmod proident id quis ad consequat excepteur sit laboris nostrud aliqua. Duis labore excepteur deserunt anim eu dolore magna aliqua nisi enim minim ut id.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Nostrud fugiat et cillum esse tempor incididunt enim. Ex culpa ut minim magna ullamco. Ea duis do cupidatat labore id quis labore consequat. Laborum laborum esse do magna.\r\n",
      "engagementDescr": "Incididunt qui cupidatat ex nisi Lorem ut adipisicing Lorem aliqua. Irure est consectetur duis occaecat nulla esse excepteur commodo aute labore incididunt aute incididunt tempor. Aliqua cupidatat in quis dolor et do nisi. Pariatur aliquip excepteur sunt elit amet. Consequat culpa exercitation laborum enim ipsum cillum mollit labore minim consequat.\r\n",
      "dataDescr": "Eu ipsum laborum id proident. Nulla ex dolor nisi do aliquip veniam aute officia anim aliquip tempor aliquip. Amet aute minim tempor officia amet est et laboris laborum laboris culpa. Ea elit voluptate consequat voluptate dolor nostrud do velit mollit in consequat ea consequat.\r\n",
      "routeDescr": "Officia velit sunt ullamco nulla culpa consequat incididunt labore ea eu. Sit Lorem pariatur laboris et non irure. Eiusmod in elit ad anim aliqua sit reprehenderit ut adipisicing. Irure exercitation dolor eu amet commodo fugiat cupidatat excepteur. Sunt exercitation sit tempor eu sit reprehenderit occaecat ea et eiusmod in nostrud do fugiat. Fugiat consequat consectetur cupidatat eiusmod consectetur aliquip Lorem cupidatat consectetur nostrud consectetur ut dolor.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Greene Case",
        "email": "greenecase@exostream.com",
        "fte": 0.5073
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Valentine Sears",
        "email": "valentinesears@exostream.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 13,
        "y3": 21
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 45,
        "y3": 91
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 29,
        "y3": 32
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10056573,
      "customerVal": 835504,
      "mvp": 75417864,
      "optimize": 12948968,
      "grow": 1109238603,
      "scale": 769707957
    },
    "nextStepsActionPlan": "Dolore aliqua proident cillum do. Non laboris enim officia est ad aute. Officia aliqua consectetur quis tempor laboris sunt quis ea duis sunt deserunt consequat irure enim. Exercitation exercitation anim adipisicing qui officia sunt ut ex eu est. Ad pariatur ipsum nulla anim magna excepteur aliqua nulla ea amet qui cupidatat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ea quis non proident ut consectetur ad pariatur cupidatat. Excepteur elit elit nisi nulla nisi elit est consectetur dolor. Et adipisicing tempor id fugiat. Ipsum do nulla aliqua minim duis proident fugiat id consectetur sit. Adipisicing qui fugiat excepteur aliqua pariatur. Tempor cillum ipsum laboris nulla quis adipisicing. Cupidatat do et cillum labore consequat aliqua id sit consequat amet culpa adipisicing magna.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-17",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03322057528f947e03",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Datagen",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rr"
    ],
    "geography": "United States",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Anim sit cupidatat veniam sint elit et adipisicing nostrud. Laboris nulla sit ipsum eiusmod aliqua eu duis voluptate cupidatat. Nulla reprehenderit magna magna ullamco deserunt. Ea sint adipisicing officia excepteur voluptate incididunt. Exercitation non eiusmod aliqua ex veniam deserunt nulla ex officia qui occaecat. Sit culpa voluptate ad sint ex pariatur do.\r\n",
    "description": "Duis dolore magna deserunt ea. Duis consequat culpa tempor Lorem culpa dolor. Lorem cillum consectetur officia ea esse tempor fugiat nulla.\r\n",
    "strategy": "Aliqua dolor et laboris sint dolore labore amet ea quis in nostrud magna. Est esse occaecat nisi nostrud incididunt sint ullamco. Esse ex ullamco ipsum ut consequat proident occaecat sit voluptate proident cillum officia proident ex. Esse cupidatat labore nulla ex laborum in officia exercitation eu culpa sint fugiat adipisicing.\r\n",
    "keyLearnings": "Quis proident cupidatat aute anim ad ea aute ad in commodo nisi id quis in. Exercitation enim cupidatat aute duis ea officia minim aliquip aliquip elit exercitation sint ex. Et ea labore nulla commodo labore ipsum in consectetur eiusmod. Pariatur elit enim consequat amet nostrud exercitation duis adipisicing tempor eiusmod ad incididunt.\r\n",
    "nextSteps": "Consectetur nulla ea consequat ut. Enim anim enim magna occaecat ex aute esse dolore dolore laboris adipisicing. Ipsum commodo deserunt duis excepteur consectetur sunt ex culpa ad. Proident incididunt dolore fugiat minim pariatur nisi commodo. Ex ut sunt dolor minim. Pariatur incididunt amet tempor aliqua ex ad qui cillum ad. Labore esse consectetur qui esse aliquip tempor consequat esse Lorem cillum exercitation adipisicing.\r\n",
    "investment": "Enim minim Lorem aliquip ut tempor amet aliquip dolore reprehenderit aliquip nulla anim. Minim laboris ullamco ad incididunt deserunt. Deserunt incididunt veniam amet cillum eu enim aliquip ea. Esse nisi do aliquip pariatur cillum aliquip magna aliqua enim nulla in dolore do. Voluptate esse aliquip eiusmod reprehenderit esse consectetur excepteur culpa consectetur proident elit dolore commodo proident. Et anim pariatur commodo quis. Ad culpa ut laborum officia non non sint nostrud cillum sint irure nisi do.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Exercitation incididunt irure sit commodo Lorem in duis ad dolor non irure. Cillum non do occaecat exercitation ut anim sunt excepteur quis veniam in laborum commodo. Quis labore labore ad culpa laborum occaecat voluptate velit ad minim. Lorem pariatur nulla reprehenderit voluptate sunt id occaecat.\r\n",
      "engagementDescr": "Eiusmod elit nulla dolore enim sunt sit veniam tempor dolor officia. Labore in commodo cupidatat ut exercitation cupidatat exercitation excepteur eiusmod do. Sint enim veniam ex sit elit Lorem ea. Amet pariatur sit et ullamco qui id enim. Eu labore esse deserunt occaecat. Laboris minim irure occaecat sunt.\r\n",
      "dataDescr": "Magna cillum irure dolore laboris anim cillum ipsum est consectetur culpa elit ullamco ea. Sint commodo ad voluptate cupidatat est fugiat. Ad pariatur pariatur ad do consectetur ullamco nisi et mollit cupidatat. Veniam fugiat dolor esse do ut sint minim culpa. Dolor eu excepteur commodo do reprehenderit cupidatat est irure aliqua esse elit. Mollit aliqua occaecat minim adipisicing aute excepteur est. Dolor aliquip pariatur do id pariatur.\r\n",
      "routeDescr": "Ea amet sunt incididunt quis magna occaecat elit. Dolore sunt eu ea cupidatat ex est. Labore cillum enim duis ex dolore proident fugiat exercitation labore incididunt deserunt. Irure reprehenderit reprehenderit mollit proident laboris eu duis eiusmod sint officia aliqua sunt.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Lucy Weiss",
        "email": "lucyweiss@datagen.com",
        "fte": 0.2255
      },
      {
        "id": 1,
        "name": "Holden Galloway",
        "email": "holdengalloway@datagen.com",
        "fte": 0.0406
      },
      {
        "id": 2,
        "name": "Lindsey Lindsay",
        "email": "lindseylindsay@datagen.com",
        "fte": 0.29
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Annie Gallagher",
        "email": "anniegallagher@datagen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 10,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 3,
        "y2": 38,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 19,
        "y3": 73
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13748548,
      "customerVal": 287830,
      "mvp": 50749121,
      "optimize": 9959459,
      "grow": 1415477168,
      "scale": 1062768804
    },
    "nextStepsActionPlan": "Reprehenderit laborum sunt commodo voluptate. In fugiat do anim sit do labore tempor consequat. Ad adipisicing irure aliquip sunt tempor minim. Consequat nulla cillum incididunt voluptate. Aute elit incididunt sint veniam tempor eu non laborum ut amet.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt duis elit nostrud Lorem occaecat eu aute non eu elit. Sunt sunt et commodo esse. Velit commodo ipsum reprehenderit commodo aliquip sint. Reprehenderit aliqua fugiat tempor nostrud in deserunt consectetur officia velit. Aliquip sit anim ea est amet. Deserunt excepteur duis eiusmod cupidatat ea elit. Ipsum id ipsum pariatur consectetur nostrud velit pariatur minim commodo occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e038227ae842abfd71b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Realysis",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Elit eu eiusmod ex quis labore officia eiusmod ad exercitation cupidatat Lorem est veniam. Qui irure proident aliquip aliqua eu Lorem ut. Aliqua officia consectetur qui voluptate Lorem pariatur Lorem cupidatat exercitation dolore ea magna. Esse id dolore id exercitation ea sunt officia. Enim labore fugiat ea non cillum aliqua ad aliquip duis culpa officia ea incididunt.\r\n",
    "description": "Id aliquip culpa ut quis occaecat esse. Lorem eiusmod nisi est aute ea. Dolore ipsum proident anim magna elit eu magna officia minim est et. Velit exercitation ex est laboris.\r\n",
    "strategy": "Magna consequat dolor cupidatat ipsum magna id eiusmod dolore. Tempor quis proident dolore sunt culpa esse aliqua excepteur velit ipsum id minim. Nostrud excepteur dolore quis dolore ut fugiat qui do deserunt. Sint cillum cillum nulla nostrud. Dolore ullamco do occaecat qui.\r\n",
    "keyLearnings": "Anim labore commodo ullamco commodo Lorem dolore incididunt proident sit veniam cillum est ea. Qui in et sit ipsum elit cillum cillum veniam irure tempor nostrud aliqua qui laboris. Deserunt ad ut sit amet officia ullamco et mollit dolore.\r\n",
    "nextSteps": "Commodo et nostrud anim sunt velit proident cillum est esse sint magna. Duis aliqua esse aliquip aliquip veniam cillum dolore est laborum eu qui. Proident ad in incididunt id. Exercitation quis cupidatat est eiusmod dolor nisi mollit cillum nisi minim Lorem nostrud magna.\r\n",
    "investment": "Ipsum consectetur ex non nulla labore. Laborum non incididunt minim excepteur aute reprehenderit esse eu. Aliquip id sit et adipisicing qui et laborum. Non nisi non eu duis irure ea minim velit incididunt incididunt nulla officia commodo. Mollit sint eu esse cillum consequat exercitation tempor proident laboris sint nostrud. Eiusmod adipisicing amet dolor culpa anim elit non aliqua et ad ex exercitation ea.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Laboris id culpa exercitation consequat do occaecat ipsum adipisicing laboris. Laboris incididunt aliqua elit duis magna cillum. Nulla aliqua ut ut dolore exercitation nisi cillum quis velit veniam nostrud mollit. Dolor esse ullamco do elit. In esse dolor aliquip reprehenderit magna tempor in exercitation amet ut pariatur ea.\r\n",
      "engagementDescr": "Cillum magna sit excepteur Lorem cupidatat ut ad cupidatat laborum incididunt. Consectetur non elit sunt velit. Ullamco in incididunt tempor proident culpa aliquip dolor Lorem amet aliquip cillum.\r\n",
      "dataDescr": "Ipsum consequat velit anim commodo sunt. Occaecat officia mollit do irure officia proident mollit adipisicing laborum enim officia aliquip. Enim do sunt minim irure aliquip eu aliqua. Sit et nulla consequat esse occaecat. Reprehenderit laboris incididunt officia sint commodo eu ullamco fugiat. Amet aliquip ex ullamco pariatur eiusmod adipisicing culpa dolore dolor et.\r\n",
      "routeDescr": "Laborum sint sit labore sunt. Voluptate sint reprehenderit minim et. Sint quis Lorem excepteur sit sunt fugiat sint. Consectetur est magna ea in consequat consequat excepteur labore et reprehenderit pariatur sint mollit occaecat. Reprehenderit amet sint aliquip dolore deserunt est in.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Mcgowan Gardner",
        "email": "mcgowangardner@realysis.com",
        "fte": 0.7542
      },
      {
        "id": 1,
        "name": "Collins Bates",
        "email": "collinsbates@realysis.com",
        "fte": 0.6237
      },
      {
        "id": 2,
        "name": "Clements Mccarthy",
        "email": "clementsmccarthy@realysis.com",
        "fte": 0.3367
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Pauline Reeves",
        "email": "paulinereeves@realysis.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 15,
        "y3": 15
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 4,
        "y2": 32,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 15,
        "y3": 85
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13052894,
      "customerVal": 1271277,
      "mvp": 80670754,
      "optimize": 11299470,
      "grow": 1360153848,
      "scale": 1465550392
    },
    "nextStepsActionPlan": "Id sint elit incididunt esse labore sint enim occaecat Lorem. Eiusmod quis ullamco commodo duis enim sit non cupidatat nisi Lorem occaecat enim dolore. Culpa qui veniam in culpa eiusmod. Laboris minim occaecat elit reprehenderit sit. Aliquip incididunt consequat non do nulla cillum cupidatat incididunt ex Lorem. Commodo culpa commodo adipisicing est est excepteur qui eiusmod magna ullamco.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sit irure sit anim ea consectetur velit ipsum aliqua reprehenderit duis. Consectetur sint mollit esse id velit. Sunt elit id ullamco nisi qui quis qui dolore incididunt. Enim duis consequat in eiusmod excepteur in labore nostrud nulla do proident laborum ex duis. Deserunt labore sit consequat labore cillum eiusmod amet esse excepteur officia nulla labore ipsum. Incididunt aliquip minim proident mollit nisi qui excepteur Lorem in fugiat reprehenderit velit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e034eb0d23c4b044e6e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Duoflex",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Online: Flagship store",
    "division": "Division Two",
    "painPoint": "Esse do ullamco laborum tempor Lorem. Nostrud tempor sint ut deserunt consectetur in velit eiusmod aute pariatur sint elit sunt. Eiusmod anim reprehenderit proident eu enim id exercitation incididunt.\r\n",
    "description": "Quis officia consectetur ex enim laborum ullamco do ut do ipsum non proident eu. Laboris anim ad consectetur dolor veniam minim excepteur nostrud nulla quis. Est sunt veniam duis reprehenderit ipsum excepteur deserunt duis excepteur.\r\n",
    "strategy": "Sit veniam eiusmod cillum adipisicing laboris sunt nisi. Excepteur qui ut commodo aliquip. Occaecat magna reprehenderit excepteur ullamco laboris labore eu minim est est duis. Ea laboris aliquip sit laborum irure aute sunt adipisicing laboris.\r\n",
    "keyLearnings": "Culpa eiusmod reprehenderit nulla irure magna irure incididunt cillum ad pariatur ut ullamco adipisicing eu. Dolore eiusmod est ut eiusmod consequat incididunt sint aliqua labore non amet. Reprehenderit ex adipisicing exercitation laborum ea quis duis nisi.\r\n",
    "nextSteps": "Ipsum pariatur quis ipsum nisi fugiat in eu sint in. Ipsum dolor sunt cupidatat voluptate. Sunt minim amet aute cupidatat sunt anim esse fugiat consectetur.\r\n",
    "investment": "Est id reprehenderit cillum proident id minim nulla exercitation consequat nisi veniam sit deserunt mollit. Qui culpa est cillum incididunt in aliqua. Consectetur cupidatat et occaecat aute consectetur nisi sit. Laboris esse laborum officia sint elit do laborum nisi do amet. Sint id incididunt est laboris nostrud non deserunt qui non deserunt laboris irure aliqua. Dolor nisi eu non laboris dolore mollit do nulla Lorem laborum excepteur.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Ullamco aliqua elit aliqua sit ipsum irure. Consectetur labore incididunt enim dolore in ad exercitation anim. Nulla est adipisicing ea eiusmod mollit esse minim sit veniam. Sunt esse do exercitation aliqua. Ex cillum duis amet Lorem quis mollit.\r\n",
      "engagementDescr": "Est adipisicing ex deserunt labore ipsum elit incididunt nostrud nostrud dolor Lorem in sunt nulla. Aliqua laboris proident labore adipisicing. Sunt labore nostrud nisi amet enim commodo aute duis elit. Qui quis dolor esse nulla magna ea duis minim mollit culpa ullamco anim id. Magna nostrud et anim ea voluptate adipisicing eu labore cillum Lorem eiusmod ullamco sint ea. Eiusmod eu magna proident enim magna sit ullamco voluptate voluptate aliqua reprehenderit incididunt. Dolore occaecat exercitation eu sint qui labore tempor consectetur.\r\n",
      "dataDescr": "Tempor do Lorem elit nulla occaecat aute cupidatat. Ea ex Lorem cillum enim in commodo. Laborum anim quis officia cillum aliquip. Pariatur ullamco ullamco duis nisi nisi esse. Dolor est aliqua in sunt. Lorem esse culpa est dolore elit minim dolore elit anim reprehenderit sit labore ipsum. Cupidatat sunt ea dolore sit occaecat id deserunt.\r\n",
      "routeDescr": "Reprehenderit id aliqua consectetur enim voluptate aliqua eiusmod dolor ad. Do cillum ipsum incididunt fugiat veniam ullamco mollit cupidatat incididunt proident nostrud amet mollit deserunt. Ipsum elit cillum velit cillum sunt velit nulla ad sit. Pariatur sint veniam esse reprehenderit est sit elit ad eu nostrud veniam excepteur aute commodo.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Ferguson Meadows",
        "email": "fergusonmeadows@duoflex.com",
        "fte": 0.1646
      },
      {
        "id": 1,
        "name": "Angelina Yang",
        "email": "angelinayang@duoflex.com",
        "fte": 0.2987
      },
      {
        "id": 2,
        "name": "Myra Merritt",
        "email": "myramerritt@duoflex.com",
        "fte": 0.7123
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Whitfield Gordon",
        "email": "whitfieldgordon@duoflex.com"
      },
      {
        "id": 1,
        "name": "Kirkland Cruz",
        "email": "kirklandcruz@duoflex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 16,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 58,
        "y3": 61
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 28,
        "y3": 38
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8820295,
      "customerVal": 1467462,
      "mvp": 2331151,
      "optimize": 6683331,
      "grow": 325511326,
      "scale": 1126665209
    },
    "nextStepsActionPlan": "Ut est excepteur enim minim mollit id veniam. Ipsum amet elit dolor veniam aliquip exercitation quis nisi sit aute et nisi eu. Sunt deserunt consequat commodo id occaecat sint in amet id. Cillum non enim excepteur laboris esse reprehenderit sint Lorem laborum. Est fugiat labore anim officia fugiat ullamco cillum aute cillum ea Lorem.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nostrud ullamco esse nulla amet ut anim anim nulla incididunt esse sit. Incididunt culpa ea id amet. Velit Lorem sunt ea do magna dolor cillum et proident incididunt consequat. Officia minim eiusmod dolore reprehenderit eiusmod commodo deserunt nisi fugiat incididunt pariatur. Nostrud sint voluptate est elit culpa tempor occaecat pariatur aliqua enim officia elit proident occaecat. Deserunt exercitation sunt ipsum elit minim enim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-09",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e030389770c0e127bb7",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Quilm",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "India",
    "channel": "Offline: Shop in shop",
    "division": "Division One",
    "painPoint": "Voluptate laboris irure ut ad do veniam velit nostrud ea cillum culpa ad aliquip. Fugiat eiusmod deserunt non sint. Nostrud duis sit exercitation in sit ea sunt ullamco amet irure quis enim ut consectetur. Reprehenderit aliquip id culpa aute mollit duis aliquip magna. Eiusmod dolore nulla incididunt veniam. Proident eu et tempor anim minim duis laborum laboris fugiat esse excepteur occaecat quis excepteur.\r\n",
    "description": "Laborum nostrud qui deserunt Lorem duis ex adipisicing ex deserunt ipsum. Commodo sint sunt dolor aliqua nostrud voluptate consequat anim fugiat minim duis ullamco. Laborum fugiat non duis excepteur pariatur. Deserunt ut aliquip aute Lorem fugiat eu quis non nulla officia. Consectetur sit et nisi nostrud commodo anim cillum tempor nisi.\r\n",
    "strategy": "Irure duis consectetur officia aliqua mollit in ea exercitation minim do Lorem nostrud tempor ullamco. Consectetur quis qui exercitation qui enim. Eiusmod nostrud reprehenderit amet incididunt Lorem dolore anim amet id qui veniam exercitation deserunt Lorem.\r\n",
    "keyLearnings": "Veniam eu commodo do ad enim proident ut aute adipisicing. Adipisicing sint tempor ea quis nisi eiusmod pariatur magna sint veniam sunt amet. Cillum veniam sunt mollit in occaecat laborum reprehenderit occaecat sint est consequat cupidatat voluptate. Cillum culpa consequat ea do enim amet et ea eu veniam. Consectetur non nulla qui mollit esse magna nostrud cillum nisi in quis reprehenderit. Duis eiusmod adipisicing enim ea. Dolore dolore esse amet aute eiusmod deserunt sunt ipsum qui.\r\n",
    "nextSteps": "Est irure ea nisi elit consequat cupidatat nostrud ad commodo voluptate occaecat irure duis. Mollit incididunt amet excepteur qui sit occaecat elit anim sit laboris irure minim. Exercitation consequat pariatur officia labore non quis amet.\r\n",
    "investment": "Duis sunt tempor aliqua sit aliqua irure aliqua anim. Voluptate et nisi veniam nisi anim sunt. Qui pariatur consectetur duis officia tempor excepteur anim ex reprehenderit ad sit sint fugiat. Aliqua ex aliqua aute nisi incididunt incididunt aliquip Lorem.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Fugiat laborum pariatur amet deserunt qui id duis duis ipsum reprehenderit nulla. Ad enim mollit esse laboris Lorem nostrud anim sint cupidatat. Tempor aute do tempor voluptate deserunt non non. Deserunt quis ad ullamco nulla Lorem nulla et dolor non ex sit enim. Culpa fugiat quis velit ea officia. Commodo laborum et excepteur enim laboris officia aliquip mollit est. Ullamco cupidatat est voluptate consectetur ullamco.\r\n",
      "engagementDescr": "Cillum occaecat consequat ut eu laborum ex sint Lorem Lorem ipsum voluptate cillum. Aliquip cillum eiusmod excepteur proident proident anim magna culpa commodo. Aliqua exercitation mollit consectetur irure labore nulla aliquip nostrud quis id excepteur. Laboris duis mollit aliquip magna ut aute proident do.\r\n",
      "dataDescr": "Ut est labore ipsum ad nisi ad esse culpa voluptate ad do non incididunt tempor. Laboris incididunt id laborum anim sit laborum sit id et cillum velit voluptate aliqua nostrud. Lorem aliquip occaecat occaecat consequat velit mollit voluptate elit sit. Commodo eiusmod dolore ullamco qui fugiat velit aute eiusmod. Nulla voluptate cillum laborum ut exercitation occaecat velit ipsum excepteur et reprehenderit quis adipisicing. Veniam quis quis amet fugiat excepteur amet.\r\n",
      "routeDescr": "Irure veniam sint dolor laboris excepteur ullamco sit ex ad exercitation reprehenderit Lorem. In id esse officia consequat. Excepteur occaecat magna culpa elit magna in. Deserunt ullamco dolor do culpa. Ipsum pariatur non duis irure aliquip. Amet reprehenderit ex veniam labore do ea.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Townsend Pugh",
        "email": "townsendpugh@quilm.com",
        "fte": 0.2647
      },
      {
        "id": 1,
        "name": "Kelley Cohen",
        "email": "kelleycohen@quilm.com",
        "fte": 0.8084
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Gwendolyn Hayes",
        "email": "gwendolynhayes@quilm.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 10,
        "y3": 32
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 57,
        "y3": 56
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 11,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4597092,
      "customerVal": 335047,
      "mvp": 61473336,
      "optimize": 1121786,
      "grow": 1167583598,
      "scale": 1253948094
    },
    "nextStepsActionPlan": "Minim eu officia laborum et esse cillum. Fugiat quis qui anim cillum mollit culpa velit cillum laborum. Et minim Lorem ex sint laboris duis deserunt esse duis elit culpa ipsum aliqua.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis cupidatat ullamco mollit voluptate nisi eu aliquip officia. Officia cupidatat laborum adipisicing nisi voluptate et enim aliquip consectetur. Nisi ad voluptate nulla commodo ullamco cupidatat. Sint magna quis et minim in officia. Nulla veniam ad aute excepteur irure. Lorem mollit dolor officia nisi nostrud fugiat nulla aliqua aliquip voluptate.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-03",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03317bdc2a55205234",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Pyrami",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Offline: Flagship store",
    "division": "Division Three",
    "painPoint": "Deserunt id sunt aliqua ipsum mollit consectetur tempor enim eiusmod ad. Nostrud reprehenderit nisi fugiat ex nulla reprehenderit est Lorem irure duis magna elit tempor. Nostrud tempor nulla irure aliqua velit velit do et anim sint nisi. Labore aute nulla cillum reprehenderit id consequat ullamco consectetur minim eu quis anim. Irure ipsum et ad commodo voluptate tempor culpa esse aute dolore consequat eiusmod occaecat. Laborum nostrud et sit exercitation fugiat incididunt adipisicing qui ex id dolor mollit adipisicing.\r\n",
    "description": "Eu in fugiat tempor amet anim. Voluptate occaecat eu enim aute. Est ea labore qui deserunt amet elit eu anim et proident. Lorem irure est in mollit enim laborum excepteur voluptate. Proident do qui incididunt deserunt commodo eiusmod aute cupidatat ad elit ut aute. Fugiat veniam in duis cillum excepteur tempor aliqua aliqua.\r\n",
    "strategy": "Ad eiusmod dolor est elit cupidatat nostrud anim. Ad adipisicing do pariatur mollit laborum sit velit veniam laboris esse veniam. Laborum nisi id adipisicing occaecat excepteur deserunt. Qui minim mollit amet pariatur deserunt nisi in officia. Laborum sit elit reprehenderit in dolor elit occaecat dolore quis sint nisi irure velit.\r\n",
    "keyLearnings": "Veniam ad ipsum qui exercitation qui sunt consequat eu laborum fugiat mollit labore aute. Duis aliquip excepteur magna magna incididunt. Et quis exercitation ex amet sint. Culpa sit pariatur ut est cillum do cillum Lorem laborum ex mollit pariatur commodo quis. Duis deserunt laborum minim dolor aliquip amet ipsum officia amet nisi commodo laboris aute ad.\r\n",
    "nextSteps": "Velit officia commodo aliqua mollit sit labore ea aute esse do incididunt velit. Cillum mollit aliquip cillum exercitation reprehenderit sint enim ad duis ut laborum duis mollit reprehenderit. Adipisicing pariatur nulla aliqua reprehenderit quis exercitation adipisicing ut eiusmod et adipisicing mollit ex ex. Irure mollit tempor laborum aliquip amet. Quis ad adipisicing aliqua ut et reprehenderit nulla. Veniam ad sint laborum eiusmod occaecat ad dolor.\r\n",
    "investment": "Minim incididunt reprehenderit do pariatur quis non minim dolor. Duis sint commodo nulla laboris. Dolore ipsum ea cupidatat minim nulla velit dolor amet.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod ipsum occaecat qui laboris. Voluptate fugiat officia duis laboris magna. Consectetur est ut elit pariatur incididunt incididunt. Anim et eiusmod nisi velit ipsum minim sint nulla consequat amet excepteur ipsum ut. Do irure enim quis culpa laboris et laborum occaecat dolore velit. Minim voluptate Lorem proident tempor voluptate anim ex et sunt occaecat.\r\n",
      "engagementDescr": "Cupidatat enim est nisi velit in irure excepteur ea laboris in enim qui est. Mollit laboris excepteur voluptate ipsum ex adipisicing adipisicing consequat adipisicing sint velit anim. Enim duis et veniam est cupidatat. Aliqua in reprehenderit veniam sunt sunt adipisicing. Ad dolor minim elit eu excepteur amet eu incididunt dolor consequat ad proident mollit.\r\n",
      "dataDescr": "Laboris magna aliqua ex aliquip consectetur velit quis ut mollit Lorem amet do laboris duis. Adipisicing proident esse excepteur aliquip amet anim pariatur velit quis. Labore reprehenderit cupidatat quis excepteur ullamco nulla velit aute commodo eiusmod qui mollit. Elit et dolore et minim veniam culpa amet veniam cupidatat tempor. Commodo proident cillum sunt ut officia Lorem enim. Sint consequat Lorem aliqua excepteur velit do tempor eu anim dolore excepteur fugiat. Ut sint nisi fugiat ea proident dolor cillum occaecat in dolore occaecat eiusmod occaecat cupidatat.\r\n",
      "routeDescr": "Mollit labore occaecat nostrud ipsum aliquip pariatur velit sunt. Lorem amet deserunt mollit cupidatat. Sint incididunt culpa deserunt enim eiusmod amet incididunt nulla incididunt elit ea. Pariatur esse anim ipsum nulla cupidatat cupidatat qui laboris aliquip amet voluptate non.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Francis Lara",
        "email": "francislara@pyrami.com",
        "fte": 0.8978
      },
      {
        "id": 1,
        "name": "Jo Stone",
        "email": "jostone@pyrami.com",
        "fte": 0.763
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Levy Joyner",
        "email": "levyjoyner@pyrami.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 11,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 34,
        "y3": 56
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 23,
        "y3": 93
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7516202,
      "customerVal": 859263,
      "mvp": 84481386,
      "optimize": 14950056,
      "grow": 1314197045,
      "scale": 995542237
    },
    "nextStepsActionPlan": "Fugiat aliquip irure culpa enim cillum amet consequat tempor quis ex culpa qui. Qui minim consectetur eiusmod officia esse reprehenderit id est qui id nostrud quis cupidatat in. Consectetur magna ad ullamco consequat sunt proident. Adipisicing velit magna laboris veniam ad tempor enim pariatur labore minim nostrud ipsum. Nulla reprehenderit commodo enim excepteur non duis aliqua ea reprehenderit pariatur. Non labore voluptate ex amet officia velit esse laborum labore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis culpa proident in culpa tempor laboris et sit ea officia officia. Laboris sint quis nisi non aute. Dolor duis fugiat adipisicing nisi duis. Qui sunt veniam fugiat eu aliqua exercitation. Irure est magna labore commodo fugiat non incididunt aliqua.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e031b9da6eb700146c9",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Bitrex",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Consectetur qui et minim fugiat est eu duis aute elit sunt non sunt quis. Ex magna anim consectetur do dolore aliqua sit consequat ex elit occaecat id anim. Do cupidatat minim tempor laboris nisi reprehenderit in incididunt consectetur adipisicing veniam labore. Sunt qui esse in eu minim.\r\n",
    "description": "Culpa incididunt veniam duis dolor ullamco. Aliquip laboris officia sunt aliquip culpa quis duis. Cupidatat in exercitation ipsum Lorem est pariatur aliqua labore. Dolor anim ea nulla sunt Lorem et laboris. Cupidatat tempor quis aliquip occaecat laborum veniam consequat officia proident esse anim consectetur excepteur.\r\n",
    "strategy": "Qui amet ad exercitation in sunt amet occaecat qui consectetur mollit tempor amet. Ipsum et adipisicing ut nisi adipisicing laborum occaecat minim labore tempor culpa exercitation officia proident. Et ex qui duis ad irure. Duis laborum aliqua nulla consectetur amet. Amet amet nostrud excepteur magna dolor eiusmod reprehenderit quis et laborum aliquip ex esse. Minim cupidatat aliqua non non culpa aliqua in do ea officia. Officia eiusmod velit minim dolor cupidatat id quis incididunt minim commodo.\r\n",
    "keyLearnings": "Aliqua occaecat culpa aliquip pariatur reprehenderit aliquip ullamco ex deserunt occaecat nulla eiusmod sint ex. Enim quis aliquip nisi consequat. Do sint fugiat ullamco id proident ex cillum quis aliqua qui officia laborum veniam culpa. Tempor et excepteur elit ea voluptate sint Lorem eiusmod deserunt sunt ea ad est. Incididunt mollit esse duis in est culpa officia ea.\r\n",
    "nextSteps": "Minim officia irure voluptate adipisicing consectetur exercitation aliqua ut enim proident cillum nostrud proident. Commodo aliquip amet nulla eu esse sint anim irure ex velit tempor sunt consectetur. Velit quis Lorem ut duis exercitation anim aliqua labore nulla eu anim velit ut duis. Dolore fugiat enim Lorem non non tempor pariatur eu exercitation labore laborum aliquip reprehenderit. Ea esse exercitation culpa in aliquip et enim Lorem id mollit sit cillum quis. Duis amet in aliqua cupidatat proident laboris ut non reprehenderit ex excepteur aliquip.\r\n",
    "investment": "Quis do do elit sint ea commodo aliqua qui. Velit officia exercitation excepteur culpa reprehenderit sunt irure nulla exercitation adipisicing proident culpa in elit. Irure Lorem consectetur occaecat do laboris Lorem ad dolore ut labore et magna eiusmod. Esse exercitation aliqua nulla laborum minim fugiat incididunt Lorem occaecat sint labore id ex. Id aliqua fugiat laborum aliqua id qui cupidatat sunt laborum pariatur enim nulla aliqua duis.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Occaecat quis proident reprehenderit duis amet consequat Lorem sunt amet dolor ut incididunt. Cillum ex id consectetur duis elit minim voluptate. Mollit mollit dolore est ea deserunt anim incididunt reprehenderit. Cillum non ut duis consectetur veniam amet aliqua magna.\r\n",
      "engagementDescr": "Excepteur amet cupidatat veniam qui eu cupidatat nulla duis quis ea commodo pariatur incididunt. Ex culpa veniam nostrud Lorem amet est voluptate. Magna aliqua veniam consequat duis incididunt est voluptate mollit reprehenderit dolore. Aute occaecat non cupidatat deserunt irure consequat commodo.\r\n",
      "dataDescr": "Aliquip officia mollit ut enim aliqua minim irure. Culpa eu elit excepteur aute est laborum tempor Lorem magna tempor est ad nulla. Elit elit quis veniam dolor laboris deserunt sint excepteur et consectetur exercitation sunt.\r\n",
      "routeDescr": "Dolore consectetur elit ex et excepteur culpa incididunt dolor eu velit. Duis sint mollit quis sunt incididunt aliquip incididunt eiusmod amet cupidatat ad consequat et dolore. Proident culpa enim eu cillum adipisicing amet cillum aliquip amet. Do ut dolore anim qui amet aliqua minim velit Lorem anim.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "May Bush",
        "email": "maybush@bitrex.com",
        "fte": 0.4255
      },
      {
        "id": 1,
        "name": "Cruz Hunt",
        "email": "cruzhunt@bitrex.com",
        "fte": 0.5336
      },
      {
        "id": 2,
        "name": "Raymond Stark",
        "email": "raymondstark@bitrex.com",
        "fte": 0.7815
      },
      {
        "id": 3,
        "name": "Watson Ayala",
        "email": "watsonayala@bitrex.com",
        "fte": 0.4642
      },
      {
        "id": 4,
        "name": "Annmarie Head",
        "email": "annmariehead@bitrex.com",
        "fte": 0.2476
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Spencer Barker",
        "email": "spencerbarker@bitrex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 16,
        "y3": 40
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 21,
        "y3": 53
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 4,
        "y2": 25,
        "y3": 40
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4845961,
      "customerVal": 730918,
      "mvp": 68383944,
      "optimize": 8182040,
      "grow": 198054648,
      "scale": 1348572904
    },
    "nextStepsActionPlan": "Do sunt quis occaecat elit nostrud nulla adipisicing occaecat. Enim in consectetur proident aute. Laboris cupidatat officia non ullamco ullamco dolore ea. Officia consequat dolor minim in deserunt aliquip ipsum adipisicing aliquip esse enim incididunt. Officia culpa eiusmod proident ex officia cillum reprehenderit consequat in fugiat irure culpa. Adipisicing sint qui fugiat commodo. Consectetur aliquip laboris minim occaecat irure dolor amet consequat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sint aliqua laboris sint proident laborum officia commodo quis. Amet pariatur excepteur mollit ipsum tempor nulla amet duis aliqua. Eiusmod veniam ullamco esse voluptate dolor quis eu eiusmod enim exercitation cupidatat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03ea61e42faacf2e0a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Empirica",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rp"
    ],
    "geography": "India",
    "channel": "Online: Shop in shop",
    "division": "Division One",
    "painPoint": "Esse labore excepteur exercitation laboris do in amet. Officia adipisicing aliqua aute in sit fugiat sint commodo nostrud reprehenderit in aute eu eiusmod. Qui mollit minim quis sit culpa elit duis id duis proident irure irure amet occaecat. Nulla laboris officia elit laborum deserunt eu voluptate culpa enim excepteur. Eu ex et mollit do tempor. Voluptate velit non veniam quis. Laborum laborum dolor adipisicing nisi anim nisi nostrud duis in ut voluptate.\r\n",
    "description": "Quis pariatur commodo dolor nisi sit do ullamco et anim proident consequat dolore incididunt. Nulla anim est quis est laboris deserunt laboris. Anim in commodo culpa aute duis velit. Voluptate velit ea culpa Lorem amet ex ipsum exercitation commodo proident eiusmod eu. Laborum dolor cupidatat laborum et officia fugiat fugiat et dolore do id duis eu ipsum. Commodo exercitation minim quis est ullamco esse incididunt elit deserunt eiusmod elit mollit quis esse.\r\n",
    "strategy": "Dolor eu deserunt cupidatat eu sunt. Eu reprehenderit officia laborum amet dolore aliquip consectetur nulla nulla consectetur in. Lorem et ea Lorem consectetur laborum amet. Sint sunt cupidatat velit veniam culpa amet sit ad velit. Et cillum est ut ea ut consectetur et nostrud eiusmod quis pariatur quis Lorem.\r\n",
    "keyLearnings": "Nostrud mollit sint reprehenderit veniam. Aute esse qui mollit minim sint ipsum Lorem qui amet consectetur ad eiusmod. Et dolore eu veniam pariatur minim sunt velit labore nostrud reprehenderit in amet. Sint minim quis non ea elit. Amet ea et nisi quis amet commodo ad magna aliqua sit fugiat pariatur tempor. Dolor magna ullamco ad pariatur nulla veniam dolore.\r\n",
    "nextSteps": "Esse cupidatat excepteur excepteur non excepteur Lorem exercitation. Adipisicing aliquip dolor esse ad. Ut laboris quis consectetur ullamco occaecat veniam deserunt cillum. Voluptate sint aute ad nisi et enim consectetur id commodo esse eu nulla tempor. Officia amet commodo anim cillum nulla occaecat ullamco enim do. Veniam quis proident eiusmod id et adipisicing laborum exercitation magna ad deserunt aliqua fugiat ipsum.\r\n",
    "investment": "Aliqua et sint laboris laborum laborum enim. Quis cupidatat laboris aliquip consequat. Fugiat minim est deserunt pariatur id nisi velit cillum ipsum. Eiusmod commodo commodo officia id culpa non incididunt. Cupidatat aliquip voluptate minim veniam officia aute Lorem sit mollit officia proident sunt ea cillum. Labore commodo nostrud esse proident eu culpa nulla minim in et consectetur Lorem elit amet.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Irure exercitation magna Lorem nulla ex minim proident. Deserunt excepteur nisi minim culpa ex officia occaecat. Labore esse tempor et qui sint amet voluptate incididunt adipisicing qui. Sunt Lorem aliqua deserunt aliquip pariatur est tempor Lorem exercitation eiusmod sunt consectetur commodo do. Eu do dolor irure incididunt irure. Velit anim amet ea anim eiusmod qui eiusmod quis fugiat. Sint eu in amet qui amet nisi est sint.\r\n",
      "engagementDescr": "Commodo incididunt voluptate occaecat quis sunt. Est ad esse fugiat amet id cillum qui do nulla ullamco. Exercitation magna dolor labore cillum sunt laborum commodo est proident ad. Dolore culpa officia nisi fugiat ea fugiat eu. Minim veniam ea adipisicing elit sint culpa.\r\n",
      "dataDescr": "Voluptate occaecat adipisicing quis magna anim tempor eu. Ex ipsum consectetur consequat est officia amet irure exercitation laboris ad esse consequat sunt voluptate. Laboris incididunt in ad commodo.\r\n",
      "routeDescr": "Amet labore deserunt dolore elit velit adipisicing culpa sit ad officia veniam aliquip officia ipsum. Aute elit velit sit incididunt occaecat mollit consectetur exercitation ut consectetur nostrud consequat nisi minim. Magna culpa in ea ea ea duis reprehenderit reprehenderit tempor. Culpa labore officia dolore consectetur qui.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Faith Sweet",
        "email": "faithsweet@empirica.com",
        "fte": 0.0458
      },
      {
        "id": 1,
        "name": "Latoya Armstrong",
        "email": "latoyaarmstrong@empirica.com",
        "fte": 0.4991
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kristin Keith",
        "email": "kristinkeith@empirica.com"
      },
      {
        "id": 1,
        "name": "Chapman Ryan",
        "email": "chapmanryan@empirica.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 10,
        "y3": 34
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 52,
        "y3": 61
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 24,
        "y3": 98
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4528931,
      "customerVal": 1156425,
      "mvp": 63123677,
      "optimize": 6453233,
      "grow": 1233082267,
      "scale": 1144187003
    },
    "nextStepsActionPlan": "Mollit tempor cillum ea eu aute dolor sint anim esse anim anim fugiat. Veniam labore aliquip amet ullamco consequat et id. Elit aliqua commodo aliqua pariatur Lorem. Enim excepteur nulla et reprehenderit labore ad fugiat commodo commodo esse deserunt ut laboris irure. Do dolor non fugiat officia elit. Tempor est occaecat ut aliqua dolore do consequat. Id eiusmod tempor proident duis nostrud culpa excepteur aliqua consectetur tempor do Lorem.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Excepteur sint ex eiusmod mollit tempor Lorem amet aliqua veniam non reprehenderit. Consequat et est anim dolor do tempor sunt exercitation dolor. Deserunt minim cupidatat ullamco adipisicing sit duis est deserunt irure Lorem adipisicing. Culpa culpa minim ex dolore sunt sit. Exercitation occaecat Lorem eu aute aliqua dolore fugiat. Enim et sit reprehenderit tempor enim. Nostrud elit laborum est enim minim excepteur irure nisi.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03a4946646ae7a8609",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Overplex",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Brazil",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Ad minim incididunt dolore adipisicing ex proident dolor ex do minim nisi proident do aliqua. Sunt pariatur ipsum excepteur adipisicing eiusmod sint ex reprehenderit ipsum. Enim mollit exercitation ut labore qui. Non enim ex non nisi ullamco elit non deserunt sunt. Aliquip cillum labore duis do irure officia. Nisi Lorem ex nisi velit veniam id ea aute esse.\r\n",
    "description": "Nostrud adipisicing aliqua veniam commodo sint adipisicing. Aliquip duis labore aliquip commodo ut adipisicing consequat do mollit Lorem anim cupidatat ullamco laboris. Minim nulla consequat labore ut excepteur. Consequat in et minim aliqua minim magna laboris ipsum id ad dolor consequat. Ullamco dolore qui consectetur tempor commodo eu est aliquip do. Adipisicing magna in ipsum ut consectetur aute ea proident.\r\n",
    "strategy": "Deserunt irure aute elit consectetur. Anim reprehenderit ex laboris adipisicing irure eiusmod. In labore ad eiusmod deserunt. Excepteur reprehenderit adipisicing amet anim adipisicing magna velit aliqua labore id duis. Nostrud incididunt et esse aliqua. Et ullamco reprehenderit nostrud laborum officia sit anim qui deserunt.\r\n",
    "keyLearnings": "Pariatur fugiat in ullamco ut nostrud. Velit consequat reprehenderit esse minim. Id in excepteur sunt nisi tempor sint mollit occaecat nulla velit in. Reprehenderit proident et tempor minim mollit reprehenderit fugiat Lorem ut. Ut aute mollit magna irure aute voluptate velit amet magna ullamco ad pariatur velit.\r\n",
    "nextSteps": "Pariatur elit magna voluptate magna nostrud magna enim consequat culpa ea. Eu esse nisi sit irure consectetur voluptate dolor occaecat dolore labore anim pariatur sint. Ipsum ullamco ullamco commodo consequat veniam laboris anim nulla pariatur aliquip ullamco sint ipsum. Officia sit esse aliqua duis deserunt ut est consequat esse sit ullamco do aute aliquip. Velit tempor enim et ipsum officia adipisicing officia cupidatat labore ea et consectetur.\r\n",
    "investment": "Est tempor aute aliquip dolore dolor aute. Eiusmod ut veniam consequat ullamco dolore enim anim aute esse proident in. Quis sint ullamco reprehenderit ex nisi non tempor labore qui veniam dolor nostrud. Incididunt ut aliqua ut elit ex consectetur ullamco dolore irure ad occaecat in. Irure enim aliquip duis anim in duis nulla pariatur aute nostrud exercitation elit. Ea minim deserunt excepteur exercitation minim dolor magna aute laborum labore incididunt ex ex id. Quis voluptate exercitation id aliqua cupidatat.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Sunt adipisicing non dolore excepteur sint exercitation fugiat cillum nulla qui qui dolor. Dolore velit ea minim elit et magna dolor id irure id reprehenderit laboris. Enim cillum incididunt nostrud sunt voluptate mollit sunt do. Voluptate deserunt commodo id nulla culpa id occaecat cillum incididunt. Velit id eu cupidatat enim qui voluptate excepteur anim. Non aute sunt ipsum voluptate. In nulla fugiat eu laborum.\r\n",
      "engagementDescr": "Aliquip mollit aute Lorem dolor laboris reprehenderit pariatur tempor Lorem do occaecat velit. Quis ullamco proident eiusmod ut sunt. Esse aute quis minim nisi esse do consectetur ut qui magna consequat.\r\n",
      "dataDescr": "Sit pariatur exercitation do qui mollit aliqua. Consequat eiusmod consectetur eu ipsum veniam amet. Mollit cillum commodo proident deserunt nostrud labore pariatur quis aliqua aute dolore. Pariatur est pariatur id laboris aliquip minim. Ipsum deserunt Lorem elit labore quis velit incididunt quis voluptate nulla.\r\n",
      "routeDescr": "Deserunt sint do in dolore dolor occaecat nisi. Occaecat irure eu fugiat qui incididunt ut ipsum tempor ipsum pariatur laboris anim dolor. Cupidatat consequat non sunt enim cupidatat esse officia nulla officia mollit minim qui.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Harmon Buck",
        "email": "harmonbuck@overplex.com",
        "fte": 0.1868
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Ruth Whitehead",
        "email": "ruthwhitehead@overplex.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 8,
        "y3": 15
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 41,
        "y3": 73
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 24,
        "y3": 65
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3380776,
      "customerVal": 707551,
      "mvp": 51099659,
      "optimize": 2483858,
      "grow": 652065945,
      "scale": 50839895
    },
    "nextStepsActionPlan": "Duis exercitation nulla excepteur sit tempor consequat eiusmod aute pariatur ipsum cillum et. Velit magna eu ut ex laborum esse labore sit anim. Deserunt amet dolore veniam elit dolore cupidatat veniam. Laboris dolore cupidatat voluptate consectetur cupidatat amet elit ad minim est voluptate sint. Consectetur enim ad incididunt veniam sint dolore voluptate nisi irure do sint. Id deserunt dolore enim officia labore ea Lorem anim elit est aliqua sit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nulla esse aliqua ipsum veniam non excepteur. Ea exercitation sit exercitation adipisicing labore ut. Ad velit ad sint laborum. Ad est est nulla nostrud aliquip. Et velit anim in ullamco culpa deserunt consequat labore reprehenderit laborum minim. Veniam anim culpa ea consequat. Duis Lorem reprehenderit labore non eiusmod dolore est ea excepteur officia.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c93ca27765a5ef28",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Comvey",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "China",
    "channel": "Online: Social media",
    "division": "Division One",
    "painPoint": "Minim cupidatat enim labore labore dolor cupidatat do do minim fugiat in dolore. Exercitation dolor in nulla eu est consequat culpa irure magna in duis. Veniam tempor pariatur excepteur proident aliquip enim cupidatat sint elit laboris aliquip do aute non.\r\n",
    "description": "Enim laborum sunt et officia cillum enim pariatur nostrud ullamco proident excepteur quis et eiusmod. Excepteur culpa non ad adipisicing enim proident cupidatat magna nulla duis officia. Et ex laboris occaecat ipsum reprehenderit labore consectetur in id consectetur. Culpa esse cillum ipsum ea laboris in irure dolore consequat.\r\n",
    "strategy": "Labore amet labore mollit nulla ea dolore est sit aliquip officia nostrud cillum sint Lorem. Lorem ex consectetur cillum fugiat ex fugiat consectetur magna cillum nostrud officia elit. Sint adipisicing aliqua aliqua consequat quis labore tempor amet ipsum proident ad.\r\n",
    "keyLearnings": "Sunt pariatur voluptate cillum sit id mollit dolore qui do quis. Consectetur in quis est cupidatat Lorem dolor duis consectetur. Nostrud nisi nisi et officia laborum cupidatat ad aute eiusmod ad pariatur ex. In excepteur fugiat id in fugiat aliquip duis aute ut consectetur cupidatat irure dolore elit. Ad proident deserunt magna aliqua anim. Amet mollit est fugiat occaecat dolor do.\r\n",
    "nextSteps": "Aliqua adipisicing eiusmod occaecat Lorem proident do cillum reprehenderit incididunt ullamco. Cillum nostrud quis incididunt minim sunt officia amet consequat mollit minim aliquip. Ex ad ad duis eiusmod non deserunt aliqua quis amet cillum ut aliquip. Voluptate aliqua exercitation sit ullamco. Est proident in quis sit dolor non commodo exercitation id occaecat.\r\n",
    "investment": "Fugiat quis sunt nulla duis voluptate et non. Irure velit veniam proident enim ad ut exercitation irure magna velit fugiat consequat commodo. Consequat exercitation enim in pariatur id do proident magna eu aliquip eiusmod anim. Voluptate aute officia reprehenderit incididunt id laboris culpa culpa id enim. Ut ipsum aliquip cupidatat pariatur fugiat labore dolor. Voluptate officia ipsum cillum tempor esse.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Consectetur sunt minim ullamco magna. Qui tempor mollit ex qui reprehenderit voluptate pariatur deserunt dolore elit. Aute incididunt commodo adipisicing aute. Fugiat dolor nulla dolore do ipsum. Irure irure enim incididunt deserunt qui consequat officia est.\r\n",
      "engagementDescr": "Irure esse elit dolore ipsum elit sunt. Qui dolor ut dolore esse. Dolor sint consectetur commodo qui. In consectetur amet ipsum culpa duis minim culpa dolor consectetur non proident occaecat velit. Dolore nisi ad occaecat commodo labore ipsum aliqua est proident ipsum. Nisi ut minim irure sunt voluptate aute reprehenderit fugiat. Magna laboris consectetur excepteur officia sit mollit tempor voluptate voluptate enim velit ex excepteur ipsum.\r\n",
      "dataDescr": "Ipsum excepteur sit aute aliquip magna amet minim sunt dolore sit aliqua. Sint eiusmod ipsum aliquip mollit dolore elit pariatur ut. Proident amet sit proident sint sint sunt irure culpa aute quis reprehenderit ex laborum velit. Tempor quis labore elit velit cillum veniam nisi fugiat. Qui eiusmod ipsum dolore adipisicing cillum. Exercitation incididunt id do nisi quis cillum officia id aliqua laboris nulla dolor incididunt et. Occaecat excepteur cupidatat dolor minim.\r\n",
      "routeDescr": "Culpa officia amet est consequat in voluptate nulla adipisicing amet elit qui Lorem adipisicing mollit. Nulla cillum Lorem aliquip pariatur. Cillum sunt incididunt consequat minim pariatur ipsum dolor Lorem esse ut. Ex dolor velit sit minim quis duis. Anim dolore duis exercitation esse sint. Dolore occaecat anim irure ullamco irure sit reprehenderit sunt et. Voluptate irure enim dolor dolore ut irure irure incididunt eu enim aliqua nisi laborum elit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Underwood Holland",
        "email": "underwoodholland@comvey.com",
        "fte": 0.1695
      },
      {
        "id": 1,
        "name": "Mollie Rojas",
        "email": "mollierojas@comvey.com",
        "fte": 0.9544
      },
      {
        "id": 2,
        "name": "Henry Jacobson",
        "email": "henryjacobson@comvey.com",
        "fte": 0.6622
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Campos Edwards",
        "email": "camposedwards@comvey.com"
      },
      {
        "id": 1,
        "name": "Augusta Greer",
        "email": "augustagreer@comvey.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 18,
        "y3": 22
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 16,
        "y2": 34,
        "y3": 99
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 18,
        "y3": 90
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14220626,
      "customerVal": 1201639,
      "mvp": 137844065,
      "optimize": 8179993,
      "grow": 1372848906,
      "scale": 1485864868
    },
    "nextStepsActionPlan": "Exercitation ut aliquip cupidatat esse deserunt id elit qui dolore magna excepteur officia. Sint nostrud mollit commodo amet consectetur ullamco. Velit non aliquip veniam proident adipisicing irure. Fugiat aute adipisicing cupidatat duis excepteur aliquip labore culpa cillum quis voluptate qui. Cillum esse labore esse proident dolor cillum ut. Proident adipisicing deserunt occaecat esse id.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Do anim proident nostrud nisi ipsum ut cupidatat elit duis. Ad labore minim veniam id proident sint. Excepteur dolore laborum pariatur do eu. Ullamco non qui cupidatat sint. Qui commodo pariatur mollit nulla aliquip veniam nostrud.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03613313105b2c0837",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Anarco",
    "highlightKPI": [
      "ctr",
      "gmac",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Minim laborum Lorem excepteur consequat aliquip. Nostrud veniam sint sint dolor reprehenderit nisi eu dolore officia ullamco proident aute. Culpa aliqua do aliqua nisi minim qui velit ex eu tempor esse culpa.\r\n",
    "description": "Consectetur veniam exercitation eiusmod qui qui mollit minim consectetur ex labore fugiat. Irure eiusmod eiusmod duis anim ullamco magna duis anim. Exercitation proident deserunt sint amet ea. Ea sint aliquip velit ea ex sit dolor amet ex aliquip minim exercitation. Deserunt irure veniam incididunt exercitation adipisicing velit in duis nisi et sit. Laboris tempor veniam velit non nulla.\r\n",
    "strategy": "Qui laborum ex voluptate esse laborum proident voluptate commodo occaecat exercitation amet nisi ad deserunt. Minim id ex sunt eiusmod consectetur sint sit reprehenderit aliqua quis in do sunt cillum. Dolore minim sint tempor elit ex elit sunt minim ea aute. Officia esse tempor cillum qui veniam irure proident irure minim Lorem.\r\n",
    "keyLearnings": "Duis occaecat cupidatat est non est ad nulla deserunt cillum ipsum ea mollit reprehenderit esse. Minim esse magna labore amet exercitation excepteur eiusmod nulla. Sint laborum elit aliqua ut labore incididunt aute quis sit exercitation consequat do. Reprehenderit deserunt nulla eu pariatur proident exercitation voluptate quis eiusmod ullamco velit dolor est. Et minim reprehenderit enim deserunt eu sit veniam.\r\n",
    "nextSteps": "Reprehenderit exercitation reprehenderit occaecat sunt dolor esse non quis enim occaecat minim dolor eiusmod. Duis officia commodo aliquip sunt. Cillum aute officia ex ut est. Do non occaecat sunt proident eu ipsum laborum.\r\n",
    "investment": "Consectetur ut mollit velit cillum nulla tempor labore cupidatat excepteur mollit ex laboris sint incididunt. Eiusmod officia commodo ad nulla incididunt deserunt nulla culpa non nisi deserunt qui. Amet quis anim duis ad cillum ex duis proident velit reprehenderit consequat non Lorem aute. Mollit laboris eiusmod incididunt velit minim id ipsum minim sunt qui mollit.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Laborum nisi anim aute laboris enim qui ullamco est velit aute laborum. Qui dolor cillum nisi magna ex qui pariatur incididunt sunt voluptate pariatur. Officia sit ullamco sunt eu id ea. Ut magna irure ullamco est eiusmod enim elit exercitation laborum tempor fugiat.\r\n",
      "engagementDescr": "Deserunt qui quis reprehenderit consequat commodo do. Sint veniam culpa magna ad excepteur amet incididunt sunt velit culpa ut nisi. Aliqua sint minim do in magna. Eiusmod ad irure Lorem laboris quis elit magna ea.\r\n",
      "dataDescr": "Velit esse ipsum laboris exercitation irure eu nulla. Mollit pariatur aute velit consectetur voluptate consequat Lorem magna laboris pariatur. Laboris elit reprehenderit sint nulla quis labore. Aute cupidatat cupidatat deserunt et nisi nostrud.\r\n",
      "routeDescr": "Officia est laboris consequat occaecat fugiat occaecat magna esse est. Lorem minim velit veniam consequat tempor. Sint eu aliqua aute deserunt cupidatat veniam. Laboris aliqua exercitation laborum dolore dolore pariatur consectetur officia incididunt velit fugiat tempor.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Knowles Lane",
        "email": "knowleslane@anarco.com",
        "fte": 0.6753
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Juana Fitzgerald",
        "email": "juanafitzgerald@anarco.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 13,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 5,
        "y2": 46,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 10,
        "y3": 72
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 5333599,
      "customerVal": 31658,
      "mvp": 118875031,
      "optimize": 12359003,
      "grow": 1464184979,
      "scale": 1096473914
    },
    "nextStepsActionPlan": "Exercitation ea consectetur esse minim nostrud voluptate reprehenderit Lorem qui. Proident eu ad ea qui aute enim occaecat. Laborum nostrud adipisicing exercitation fugiat labore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Incididunt pariatur est deserunt et excepteur ullamco mollit excepteur Lorem fugiat nisi. Reprehenderit labore ullamco incididunt pariatur id eiusmod incididunt reprehenderit excepteur. Nostrud aliqua do sunt ex sit ullamco qui. Fugiat deserunt veniam pariatur aliquip ipsum labore.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-21",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039c215502a0c5af23",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Zaphire",
    "highlightKPI": [
      "cac",
      "bcr",
      "rr"
    ],
    "geography": "Brazil",
    "channel": "Online: Home delivery",
    "division": "Division Two",
    "painPoint": "Reprehenderit proident incididunt elit nisi occaecat consequat occaecat non commodo amet. Mollit irure nulla adipisicing consectetur esse aute aliquip. Consequat do tempor laborum nulla eu anim cillum incididunt nisi elit irure sit.\r\n",
    "description": "Aliquip commodo occaecat eu consequat amet sint consequat aliqua irure id incididunt excepteur. Eiusmod aute ut aliqua eu excepteur dolor mollit veniam laboris eu pariatur. Mollit deserunt labore est excepteur commodo culpa occaecat incididunt consequat ut. Commodo sit et eiusmod adipisicing culpa eu ad ea sunt do duis id ut ipsum. Consequat pariatur cupidatat quis in aliquip ut.\r\n",
    "strategy": "Culpa labore laborum id est id proident in elit. Officia ut est ex ullamco aute ex dolore in aute eu commodo incididunt esse elit. Irure laborum sit laboris aliqua ut Lorem. Consectetur do aute incididunt velit nostrud quis laborum ipsum fugiat consectetur. Sit ex ex veniam adipisicing cillum laborum nulla id. Ut elit sunt nisi aliquip officia commodo nisi do enim velit mollit fugiat deserunt. Cupidatat esse aute quis sunt nisi qui consequat non esse.\r\n",
    "keyLearnings": "Proident enim excepteur ad fugiat sint sint veniam non est cillum eiusmod commodo. Eiusmod proident tempor ad cillum exercitation deserunt eiusmod nostrud ea consectetur dolor do. Officia culpa incididunt commodo labore fugiat nulla non sunt commodo tempor enim elit. Excepteur elit est laboris sunt. Aliqua officia occaecat duis laborum commodo adipisicing nulla ut eu. Incididunt consectetur sunt quis laborum duis in ipsum incididunt. Commodo tempor laboris exercitation dolor.\r\n",
    "nextSteps": "Magna consequat ex elit fugiat laborum incididunt mollit aliqua nisi dolor aliquip. Minim aliquip veniam cupidatat aute exercitation in sit ea eu irure non fugiat. Proident consequat anim commodo nulla. Dolor dolore qui non eiusmod ex magna excepteur et ullamco ullamco deserunt labore. Consequat laboris anim aute eu minim non adipisicing sunt. Sit esse elit incididunt cupidatat aute sunt anim duis consectetur. Mollit do officia deserunt veniam mollit nulla sint culpa do est.\r\n",
    "investment": "Id do sint laborum Lorem mollit esse incididunt duis minim consectetur labore esse fugiat. Tempor adipisicing in proident in aute. Sint qui sunt nisi ex fugiat eiusmod dolor. Minim nisi do irure magna non nisi reprehenderit proident tempor dolor elit id ipsum. Esse aliqua do fugiat adipisicing reprehenderit labore laborum non non.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Laborum Lorem laborum Lorem minim sint. Et eiusmod in veniam ut pariatur labore laborum nisi eiusmod. Id elit cillum duis quis tempor nisi deserunt eu cupidatat reprehenderit ullamco et nisi. Elit proident do do laborum ullamco aliquip sunt cillum enim non. Occaecat in eu eiusmod et irure. Ea nostrud sint ea duis nulla ullamco ex. Ut irure proident anim duis eu laborum id proident aliqua adipisicing qui laborum sit eiusmod.\r\n",
      "engagementDescr": "Excepteur ad proident laborum adipisicing ipsum sunt amet adipisicing dolore laborum. Eu sit proident amet laboris elit proident ullamco fugiat incididunt fugiat. Cupidatat enim anim cupidatat nostrud nostrud culpa deserunt eu et amet. Aliquip commodo tempor non et aute nisi incididunt deserunt do. Dolore est aliqua occaecat esse tempor aliqua tempor nostrud commodo.\r\n",
      "dataDescr": "Cupidatat nostrud ad ea ea deserunt. Laborum pariatur irure est sint ex ad ipsum. Commodo dolore commodo nostrud quis aliquip pariatur deserunt do excepteur est. In irure magna exercitation sunt ullamco laboris consectetur duis velit laborum aute commodo. Deserunt nisi esse est occaecat ipsum eiusmod nostrud Lorem esse ex ex. Officia ex nostrud ad fugiat velit deserunt eu amet.\r\n",
      "routeDescr": "Magna anim officia anim exercitation aute sit dolor aute eu consectetur esse. Duis sunt nostrud officia proident deserunt sunt ex commodo occaecat. Consequat sit exercitation est ipsum incididunt cupidatat cillum id sint cillum consequat cupidatat commodo velit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Mcdaniel Schultz",
        "email": "mcdanielschultz@zaphire.com",
        "fte": 0.0414
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Delgado Parsons",
        "email": "delgadoparsons@zaphire.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 11,
        "y3": 20
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 25,
        "y3": 66
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 24,
        "y3": 99
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8114249,
      "customerVal": 496423,
      "mvp": 125717337,
      "optimize": 5892513,
      "grow": 1305206077,
      "scale": 1060323189
    },
    "nextStepsActionPlan": "Excepteur reprehenderit sit dolor ea consectetur commodo anim consequat ipsum est anim sit. Eiusmod quis cupidatat consequat adipisicing veniam laboris id cillum. Laborum magna consequat sint commodo aliquip reprehenderit. Esse enim tempor in qui mollit sit culpa laboris. Occaecat ut commodo incididunt deserunt nisi non eu aliqua nostrud occaecat exercitation excepteur mollit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sit ex irure commodo tempor proident dolor voluptate in. Deserunt ullamco laboris do ullamco enim excepteur incididunt est in exercitation. Deserunt quis sit commodo voluptate Lorem dolor sint tempor anim exercitation voluptate. Eu amet non veniam labore et Lorem aute ipsum minim ex minim labore. Ipsum veniam amet nulla dolor nulla. Dolor ullamco deserunt mollit duis sit ea cillum excepteur dolore.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03ea845991a7052df4",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Pigzart",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "United States",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Consequat voluptate proident deserunt duis excepteur nostrud sint adipisicing elit Lorem voluptate. Tempor duis veniam magna consequat irure culpa officia et duis ea enim incididunt nisi. Deserunt eu nisi proident non ullamco adipisicing anim ea mollit enim culpa sint. Voluptate consectetur sunt duis Lorem esse proident nisi do.\r\n",
    "description": "Enim id aute velit incididunt ullamco. Voluptate Lorem culpa laboris pariatur adipisicing et officia ex occaecat. Laborum culpa in qui culpa cupidatat. Et in incididunt sit et. Elit eu deserunt consectetur aute laboris tempor. Veniam et nulla eu nisi incididunt do exercitation non ad ipsum. Tempor velit occaecat veniam incididunt magna ut ullamco.\r\n",
    "strategy": "Sit amet dolor fugiat reprehenderit eiusmod sunt sint anim aliquip sit. Nulla excepteur do aute laboris et. Irure et ipsum occaecat enim commodo dolore tempor fugiat tempor irure officia velit. Ipsum quis sit sunt est Lorem incididunt non sit eiusmod ad duis ad occaecat.\r\n",
    "keyLearnings": "Ullamco eiusmod in sunt ad cupidatat magna deserunt nulla sunt eiusmod irure ad ea qui. Proident voluptate duis irure proident sit. Officia do cillum enim sint exercitation eu minim. Aute aute incididunt elit amet dolor sunt quis nisi elit dolor aute mollit fugiat nulla. Et cillum fugiat eiusmod Lorem sit nisi et in et nostrud. Velit duis cupidatat nulla mollit. Non incididunt deserunt velit laboris adipisicing voluptate velit qui adipisicing.\r\n",
    "nextSteps": "Ullamco amet eu occaecat est. Et nisi nulla culpa culpa laboris. Mollit reprehenderit irure ad in officia pariatur irure irure esse esse laborum. Dolore quis laborum minim reprehenderit nostrud. Eiusmod laboris consectetur excepteur nulla voluptate adipisicing enim aute cupidatat reprehenderit. Esse amet laborum amet do quis adipisicing ea pariatur consectetur voluptate quis sit.\r\n",
    "investment": "Consectetur anim elit tempor ex amet eu nulla mollit ullamco cillum veniam laborum consequat. Qui aliqua dolore duis mollit fugiat ex deserunt ut dolore deserunt. Deserunt labore sunt ad in cupidatat labore incididunt quis non sunt laborum. Pariatur ea velit enim cupidatat tempor officia laborum.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Commodo voluptate magna consequat dolor enim. Sit minim aliquip nulla sit quis cillum. Fugiat sunt eu sunt reprehenderit consequat aliquip elit anim quis consequat consectetur Lorem ipsum. Cillum cupidatat consequat cupidatat non tempor veniam duis consectetur. Est aute voluptate sit anim deserunt occaecat consequat fugiat occaecat officia. Magna pariatur et anim consequat dolor labore elit. Irure reprehenderit consequat qui ea aliqua fugiat occaecat exercitation aliquip.\r\n",
      "engagementDescr": "Ipsum et id non dolor. Velit amet reprehenderit ut laborum reprehenderit amet deserunt amet duis ipsum enim ipsum. Consectetur irure id aute voluptate exercitation officia sint dolor ipsum cupidatat quis mollit sint.\r\n",
      "dataDescr": "Excepteur velit aliquip ullamco magna. Esse anim dolore id elit aliqua id aliqua quis nulla commodo tempor sunt qui aliquip. Velit ea labore eu irure reprehenderit qui incididunt dolor eiusmod ea nulla commodo. Velit cillum irure amet minim reprehenderit ipsum ad occaecat incididunt. Sunt excepteur officia laboris occaecat ullamco veniam eu.\r\n",
      "routeDescr": "Duis sit reprehenderit nulla aliquip. Esse fugiat est mollit consectetur fugiat. Velit laborum sint velit ad nulla ea veniam aliqua. Consequat aute incididunt ex aliqua excepteur enim quis dolore quis sit elit veniam commodo laborum. Excepteur exercitation mollit voluptate dolor mollit. Ad nulla sint occaecat excepteur cupidatat ullamco officia ea nostrud reprehenderit sunt.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Wallace Vega",
        "email": "wallacevega@pigzart.com",
        "fte": 0.4212
      },
      {
        "id": 1,
        "name": "Bobbie Vasquez",
        "email": "bobbievasquez@pigzart.com",
        "fte": 0.3325
      },
      {
        "id": 2,
        "name": "Deann Blankenship",
        "email": "deannblankenship@pigzart.com",
        "fte": 0.2672
      },
      {
        "id": 3,
        "name": "Hollie Clemons",
        "email": "hollieclemons@pigzart.com",
        "fte": 0.3027
      },
      {
        "id": 4,
        "name": "Ophelia Singleton",
        "email": "opheliasingleton@pigzart.com",
        "fte": 0.3115
      },
      {
        "id": 5,
        "name": "Stacey Baird",
        "email": "staceybaird@pigzart.com",
        "fte": 0.5369
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Nicholson Dejesus",
        "email": "nicholsondejesus@pigzart.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 11,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 42,
        "y3": 60
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 15,
        "y2": 15,
        "y3": 63
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3316991,
      "customerVal": 498648,
      "mvp": 81136642,
      "optimize": 4147483,
      "grow": 62531728,
      "scale": 108247405
    },
    "nextStepsActionPlan": "Aute adipisicing tempor reprehenderit aute elit. Est officia proident occaecat sunt mollit et enim nostrud do elit labore. Do fugiat et occaecat eiusmod. Nulla reprehenderit enim ex laborum anim quis cupidatat et. Esse ea nulla ex reprehenderit sunt nostrud officia. Et dolor do ad magna tempor non.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Non quis aliqua esse dolore fugiat fugiat duis sunt mollit aute. Sint consectetur consectetur commodo veniam officia mollit sunt. Aliqua dolore labore id Lorem ipsum occaecat pariatur proident enim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-02",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03a45f8be69dca9011",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Comfirm",
    "highlightKPI": [
      "cac",
      "gmac",
      "rp"
    ],
    "geography": "Italy",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Minim irure laborum laborum ex quis eiusmod nulla consectetur elit est cupidatat do cillum nisi. Cillum proident Lorem elit tempor elit velit cillum et enim. Et elit ut elit dolor cillum reprehenderit deserunt aliqua proident. Et esse cupidatat fugiat esse Lorem sunt Lorem. Sunt cupidatat irure aliqua quis sit fugiat quis. Ut ut in mollit laborum incididunt fugiat dolor anim ut fugiat. Ea id amet ut ea amet excepteur occaecat.\r\n",
    "description": "Culpa pariatur elit reprehenderit eiusmod laboris eu reprehenderit incididunt id est ullamco id excepteur. Do pariatur deserunt fugiat commodo ea consequat fugiat aute ex. Nulla ex amet laborum ullamco deserunt occaecat ad. Incididunt amet culpa elit occaecat ut ullamco ea laborum dolor aliqua labore cupidatat. Officia cupidatat aute est cupidatat excepteur ad. Esse ullamco occaecat sint cillum ex officia eiusmod.\r\n",
    "strategy": "Ipsum excepteur minim Lorem sunt ipsum minim eu anim velit reprehenderit nisi voluptate mollit. Laborum est officia pariatur ipsum eu est excepteur do ullamco proident anim enim sint proident. Eu irure ea dolore exercitation non. Consectetur dolor aliquip consequat dolor Lorem quis consequat Lorem irure amet cupidatat. Dolore culpa esse excepteur commodo qui laboris ea exercitation eu. Id voluptate sit non velit quis est voluptate Lorem sunt officia adipisicing eiusmod. Nisi velit sit deserunt sunt consectetur id deserunt velit.\r\n",
    "keyLearnings": "Commodo ea exercitation non nostrud consectetur commodo sunt tempor ad do esse exercitation. Ipsum ex dolor nostrud aliqua voluptate non duis commodo. Aliqua velit in enim incididunt reprehenderit eu minim. Elit voluptate ullamco non in tempor voluptate cupidatat est voluptate consectetur. Veniam nulla eiusmod dolore sunt irure pariatur dolore.\r\n",
    "nextSteps": "Ad excepteur anim quis voluptate do occaecat. Aute id ea commodo incididunt minim exercitation sint. Do ea elit cupidatat enim eiusmod eiusmod velit officia consequat officia. Qui ullamco eiusmod sit proident commodo tempor qui et consectetur pariatur in est. Amet nostrud exercitation enim elit aliquip ullamco laborum et amet in nulla cupidatat laboris. Ex veniam excepteur voluptate adipisicing magna velit veniam pariatur laboris consectetur magna dolor. Consequat ea incididunt id aliqua.\r\n",
    "investment": "Enim pariatur occaecat labore Lorem est. Tempor Lorem et sit Lorem pariatur ipsum laboris magna amet non sunt reprehenderit voluptate sunt. Qui quis labore incididunt labore nisi incididunt eiusmod ipsum culpa non cillum exercitation anim ipsum. Officia exercitation consectetur laboris ea aliquip aliquip. Reprehenderit officia excepteur ipsum laborum nulla aute in ad commodo nisi sunt.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Consequat enim voluptate nulla dolor minim duis ipsum nulla proident nulla excepteur excepteur. Dolor tempor excepteur ut enim est minim ad amet. Qui do Lorem id dolor consectetur consectetur. Esse eiusmod deserunt quis exercitation laborum consectetur. Voluptate labore minim commodo Lorem veniam est et labore ullamco elit sint et Lorem.\r\n",
      "engagementDescr": "Exercitation in Lorem enim et in tempor reprehenderit occaecat. Occaecat esse adipisicing commodo fugiat ut labore pariatur proident incididunt nisi. Eu enim dolore voluptate irure irure aliquip sunt deserunt sit commodo esse eiusmod laborum ex. Sint id culpa culpa aute excepteur nulla culpa elit aute quis. Amet velit esse et in enim cupidatat anim aliquip qui. Nisi aliquip occaecat ea aute non nisi non adipisicing laborum ea aliquip. Eu laborum qui id tempor proident ipsum aliqua.\r\n",
      "dataDescr": "Laboris proident mollit aute quis. Id fugiat esse adipisicing sint elit sit enim adipisicing in tempor elit. Non quis anim eu ut dolor aliqua occaecat ut officia tempor.\r\n",
      "routeDescr": "Enim veniam quis nulla mollit aliquip. Irure incididunt aute elit aliqua ipsum elit ad consectetur dolor sunt nisi. Laborum fugiat anim aute quis aute voluptate Lorem nulla sint officia ea fugiat cupidatat aute. Enim nisi eiusmod Lorem amet commodo nisi ullamco ea excepteur. Voluptate irure elit voluptate enim tempor duis veniam mollit. Minim elit eiusmod elit culpa et cillum occaecat ut.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Steele Howard",
        "email": "steelehoward@comfirm.com",
        "fte": 0.6549
      },
      {
        "id": 1,
        "name": "Rivas Gill",
        "email": "rivasgill@comfirm.com",
        "fte": 0.5117
      },
      {
        "id": 2,
        "name": "Marlene Hoffman",
        "email": "marlenehoffman@comfirm.com",
        "fte": 0.7522
      },
      {
        "id": 3,
        "name": "Grant Stafford",
        "email": "grantstafford@comfirm.com",
        "fte": 0.035
      },
      {
        "id": 4,
        "name": "Graham Reese",
        "email": "grahamreese@comfirm.com",
        "fte": 0.8694
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Concetta Workman",
        "email": "concettaworkman@comfirm.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 18,
        "y3": 35
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 43,
        "y3": 85
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 12,
        "y3": 58
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3789883,
      "customerVal": 318565,
      "mvp": 47264853,
      "optimize": 10189729,
      "grow": 1160417001,
      "scale": 164588654
    },
    "nextStepsActionPlan": "Ad duis qui sunt duis excepteur laborum occaecat proident exercitation duis cillum minim fugiat cupidatat. Irure laboris ex proident occaecat deserunt sit qui consectetur in esse fugiat sunt. Mollit nostrud nulla consequat laborum nulla et. Nulla laborum ad aliquip irure est labore officia do incididunt incididunt. Non labore qui reprehenderit reprehenderit ex culpa. Reprehenderit labore do ad id anim adipisicing est in id occaecat Lorem consequat dolor deserunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Adipisicing proident esse et duis. Pariatur cillum laborum ad aliqua ullamco aliqua excepteur sit velit aliqua. Non ut aliqua commodo incididunt aute quis Lorem. Exercitation tempor non duis occaecat cillum nulla magna ullamco et. Amet culpa in cupidatat eu commodo eiusmod et sit elit proident ad magna fugiat. Tempor sint ipsum ea eu eiusmod officia magna laboris pariatur adipisicing deserunt nostrud ipsum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-05",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e037698349afbb370f4",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Bluplanet",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rp"
    ],
    "geography": "United States",
    "channel": "Online: Flagship store",
    "division": "Division Two",
    "painPoint": "Est sunt ad eiusmod non cupidatat sint minim. Pariatur nisi deserunt eiusmod laborum duis laboris eiusmod aute incididunt voluptate sunt. Do et sunt eu ex ipsum. Enim officia anim enim duis excepteur id esse nisi mollit. Labore anim ipsum est consectetur. Laboris non esse magna enim ex qui nisi elit mollit eu incididunt excepteur pariatur. Quis elit enim dolore reprehenderit amet elit excepteur Lorem velit eiusmod eu.\r\n",
    "description": "Ea quis fugiat proident deserunt officia irure commodo. Elit Lorem labore sint cupidatat pariatur amet mollit qui exercitation tempor. Est exercitation id exercitation eu pariatur cupidatat enim nulla ad aliqua commodo deserunt eu. Ex id cupidatat adipisicing cillum esse consectetur proident nulla est proident. Mollit nisi sint aliquip exercitation non aliqua elit ex ea et qui ullamco. Ea commodo occaecat pariatur culpa cupidatat laborum. Laborum consequat ea eu officia excepteur enim est amet exercitation excepteur cupidatat irure occaecat.\r\n",
    "strategy": "Excepteur ea reprehenderit qui fugiat duis ex magna amet. Tempor consequat veniam consectetur labore qui sunt. Sit ad deserunt consectetur labore ut. Eu culpa culpa eiusmod ad. Minim amet dolore officia aliqua.\r\n",
    "keyLearnings": "Anim esse sunt aute labore Lorem eu non adipisicing. Est tempor incididunt voluptate consequat excepteur sit et in. Culpa officia duis ex aute.\r\n",
    "nextSteps": "Aute ullamco deserunt amet occaecat enim ut elit ex. Tempor aliqua tempor eu ut. Sunt aliqua cillum anim esse in minim dolore anim.\r\n",
    "investment": "Amet velit dolore cupidatat aliquip ut dolor aute. Qui elit esse et laboris. Velit deserunt nostrud consectetur excepteur eu ullamco adipisicing duis.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Voluptate laborum commodo officia qui dolore et excepteur adipisicing. Lorem cupidatat adipisicing adipisicing cillum incididunt eu ad fugiat officia occaecat ea mollit tempor irure. Non adipisicing laborum eiusmod tempor tempor id anim excepteur laborum voluptate labore. Reprehenderit commodo sunt esse cupidatat consequat minim duis id esse duis nostrud irure. Laborum occaecat laborum pariatur dolore consequat amet elit non culpa ea ex fugiat sunt ad. Anim adipisicing exercitation nostrud sint ut aliquip eu cupidatat est.\r\n",
      "engagementDescr": "Aute nisi nulla sit duis amet est cillum dolor occaecat labore incididunt. Voluptate eiusmod proident cillum aliquip in nulla sunt irure occaecat velit Lorem eiusmod laboris. Nulla ullamco proident Lorem voluptate nostrud reprehenderit laboris eu dolore dolore excepteur reprehenderit irure. Aliqua cillum eiusmod est nostrud amet commodo sunt. Sint aute officia laboris consectetur veniam veniam. Exercitation fugiat irure mollit tempor ullamco labore deserunt labore exercitation. Laboris exercitation est eiusmod deserunt nostrud anim et.\r\n",
      "dataDescr": "Esse sit commodo deserunt dolor nulla adipisicing ut incididunt Lorem id ad velit. Nostrud commodo laborum deserunt dolore aute sit sint ad nostrud est incididunt consequat nulla dolor. Ad enim anim deserunt consectetur in laboris consequat excepteur nisi aute dolor.\r\n",
      "routeDescr": "Sunt consectetur ex ex eu irure. Non nulla adipisicing laboris amet cupidatat anim non aliquip enim. Eiusmod in adipisicing laborum nulla. Minim non voluptate ex laborum consequat officia ex et reprehenderit irure amet ad sunt.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Key Humphrey",
        "email": "keyhumphrey@bluplanet.com",
        "fte": 0.7652
      },
      {
        "id": 1,
        "name": "Ora Clayton",
        "email": "oraclayton@bluplanet.com",
        "fte": 0.2252
      },
      {
        "id": 2,
        "name": "Emma Rowe",
        "email": "emmarowe@bluplanet.com",
        "fte": 0.5974
      },
      {
        "id": 3,
        "name": "Catherine Bass",
        "email": "catherinebass@bluplanet.com",
        "fte": 0.5782
      },
      {
        "id": 4,
        "name": "Weeks Stevens",
        "email": "weeksstevens@bluplanet.com",
        "fte": 0.6537
      },
      {
        "id": 5,
        "name": "Stafford Matthews",
        "email": "staffordmatthews@bluplanet.com",
        "fte": 0.1664
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Dianne Mccormick",
        "email": "diannemccormick@bluplanet.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 12,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 52,
        "y3": 93
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 14,
        "y3": 83
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7778094,
      "customerVal": 1201021,
      "mvp": 42588025,
      "optimize": 8658557,
      "grow": 805552719,
      "scale": 10409135
    },
    "nextStepsActionPlan": "Do cillum veniam labore non voluptate voluptate ex. Amet sunt proident nulla velit nostrud aliquip non veniam exercitation. Nisi eiusmod cupidatat mollit Lorem voluptate id esse cupidatat nisi enim cillum. Voluptate sunt magna eiusmod dolore. Cupidatat ex voluptate ad dolore irure elit magna.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Nostrud occaecat cupidatat aliquip veniam laboris ut do commodo sint. Ut aliquip eiusmod in excepteur ea voluptate do ad non officia elit officia velit ipsum. Irure ex quis sunt eu dolor duis. Excepteur enim magna sint dolore ea cupidatat mollit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0312d01088bb5c8304",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Olucore",
    "highlightKPI": [
      "cac",
      "ltv",
      "rr"
    ],
    "geography": "Brazil",
    "channel": "Online: Social media",
    "division": "Division One",
    "painPoint": "Mollit ex esse tempor pariatur ad commodo aute consectetur ex exercitation anim. Cupidatat anim tempor proident mollit laborum tempor tempor dolore sunt laboris occaecat ipsum. Nulla pariatur aliqua proident occaecat id esse magna consectetur pariatur. Enim incididunt sit quis anim in et.\r\n",
    "description": "Mollit deserunt ullamco est esse veniam pariatur ad. Sunt reprehenderit eu ullamco amet eu commodo consectetur deserunt sit adipisicing. Amet ut mollit reprehenderit minim id nostrud in. Consectetur elit deserunt sit deserunt adipisicing aliqua aliquip ad duis aute reprehenderit minim. Culpa esse voluptate consectetur quis tempor sit excepteur ullamco consectetur eu cupidatat. Labore nostrud nulla eiusmod laborum enim culpa quis occaecat sit quis amet esse aliquip cillum. Sit reprehenderit commodo deserunt excepteur.\r\n",
    "strategy": "Proident id ad ut nulla eu. Exercitation officia adipisicing amet adipisicing aute in veniam occaecat sint adipisicing minim do pariatur. Veniam aliqua dolor excepteur reprehenderit in adipisicing cillum excepteur cupidatat cupidatat minim nulla.\r\n",
    "keyLearnings": "Id pariatur deserunt sint culpa proident elit sit sit. Id ea ut dolor minim veniam mollit et sit aute qui id esse enim. Qui labore do laborum laborum magna qui culpa amet sit ullamco eu.\r\n",
    "nextSteps": "Anim laborum veniam ut sunt excepteur velit velit reprehenderit non ullamco. Officia consectetur ad excepteur sunt consectetur consequat do cillum. Dolor eu culpa tempor est dolor eiusmod. In deserunt eu commodo ipsum fugiat nulla. Nostrud ut exercitation minim enim velit anim sunt nulla aute fugiat sit tempor exercitation duis. Cillum sint esse enim cillum dolor non voluptate et pariatur minim occaecat esse. Nisi nostrud proident dolore sit nisi incididunt est eu in pariatur est.\r\n",
    "investment": "Esse culpa do ad id veniam reprehenderit ipsum sit est ullamco tempor quis sunt. Sit adipisicing adipisicing reprehenderit eiusmod voluptate nostrud exercitation irure ex quis incididunt cillum aliquip proident. Quis in sunt aute ullamco sit laboris ex do ullamco. Cupidatat pariatur sunt mollit ut ut incididunt mollit mollit Lorem esse. Deserunt do labore aliquip veniam. Deserunt elit elit qui non deserunt cillum pariatur ullamco Lorem occaecat excepteur dolore.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Enim fugiat esse excepteur veniam. Do sit eiusmod minim ullamco aute est sit. Eiusmod laborum cillum veniam nulla qui consequat culpa. Amet nulla ipsum duis do velit qui laborum irure deserunt anim officia commodo. Voluptate veniam sint mollit qui dolore Lorem deserunt fugiat minim nulla ea officia minim. Aliqua non irure amet magna velit laborum Lorem labore minim ad ut. Dolore laboris velit dolore sunt ullamco dolor reprehenderit sint anim.\r\n",
      "engagementDescr": "Anim reprehenderit dolore ex fugiat eu dolor ut nulla cupidatat quis velit culpa anim non. Occaecat et exercitation ut mollit sunt dolor incididunt. Officia quis est tempor nostrud ad duis est exercitation commodo ipsum enim anim. Mollit aute minim quis commodo eiusmod anim amet. Deserunt consequat eu deserunt officia commodo nulla.\r\n",
      "dataDescr": "Qui esse et aliqua eiusmod. Elit et labore reprehenderit aute qui anim. Duis commodo id eiusmod quis sint esse. Eu veniam do deserunt laboris. Magna incididunt culpa esse eu minim sunt anim do. Amet anim reprehenderit fugiat laborum aute velit ex velit ut sit ea laborum. Voluptate et enim ipsum et sunt minim.\r\n",
      "routeDescr": "Veniam aliquip eu esse ut incididunt enim. In ullamco amet esse excepteur dolor id labore id aliqua consequat. Est culpa adipisicing nisi proident quis id aute esse commodo voluptate.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Melba Smith",
        "email": "melbasmith@olucore.com",
        "fte": 0.5488
      },
      {
        "id": 1,
        "name": "Manning Butler",
        "email": "manningbutler@olucore.com",
        "fte": 0.4727
      },
      {
        "id": 2,
        "name": "Duke Hampton",
        "email": "dukehampton@olucore.com",
        "fte": 0.5981
      },
      {
        "id": 3,
        "name": "Penny Fields",
        "email": "pennyfields@olucore.com",
        "fte": 0.3541
      },
      {
        "id": 4,
        "name": "Hansen Robinson",
        "email": "hansenrobinson@olucore.com",
        "fte": 0.3833
      },
      {
        "id": 5,
        "name": "Kayla Andrews",
        "email": "kaylaandrews@olucore.com",
        "fte": 0.9957
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Roberta Hernandez",
        "email": "robertahernandez@olucore.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 12,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 0,
        "y2": 38,
        "y3": 74
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 28,
        "y3": 47
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11820950,
      "customerVal": 1284090,
      "mvp": 45200146,
      "optimize": 4248637,
      "grow": 389719297,
      "scale": 679772346
    },
    "nextStepsActionPlan": "Anim aute cillum in nulla dolor labore Lorem eiusmod. Est ipsum pariatur nostrud incididunt ex. Sunt commodo commodo id et dolore ad exercitation reprehenderit amet irure non dolor aute. Consequat cillum ex eiusmod ullamco. Velit amet veniam cupidatat veniam sint sint veniam mollit. Dolore tempor velit sint tempor excepteur veniam.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Labore minim dolore qui adipisicing in deserunt do deserunt in ipsum veniam laboris laboris. Occaecat laboris nisi eu ea sit id proident. Officia consequat consectetur aute dolore laboris magna occaecat id irure. Fugiat incididunt et anim non cillum cillum ea irure exercitation proident occaecat irure dolor officia. Esse commodo magna fugiat elit deserunt anim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c31ccc9ae8516749",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Biospan",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Brazil",
    "channel": "Online: Home delivery",
    "division": "Division One",
    "painPoint": "Et consectetur Lorem ut mollit velit dolore velit eiusmod. Veniam ullamco consectetur consequat occaecat do nostrud excepteur cillum culpa occaecat fugiat Lorem. Excepteur labore cupidatat cupidatat irure duis exercitation culpa sunt. Id voluptate exercitation ut deserunt officia mollit amet.\r\n",
    "description": "Magna ullamco do est deserunt sint aliqua incididunt. Fugiat elit velit id ea anim velit incididunt duis irure commodo esse. Incididunt minim adipisicing et ullamco non laboris culpa duis ut exercitation. Sit culpa reprehenderit cillum consequat pariatur sint. Dolore sunt elit ad aute aliqua magna amet ea occaecat nostrud. Reprehenderit et minim eiusmod veniam.\r\n",
    "strategy": "Qui tempor proident laborum amet non ipsum consectetur voluptate do labore. Laboris in nulla laboris anim irure cupidatat veniam voluptate. Ullamco minim ipsum laborum velit. Magna officia ullamco esse voluptate mollit sint. Esse sit elit id ipsum eu occaecat laborum amet cupidatat.\r\n",
    "keyLearnings": "Exercitation ex nulla Lorem nisi voluptate tempor occaecat pariatur et veniam officia ut do. Consequat ipsum aliqua veniam nostrud. Qui eiusmod magna elit ea. Aute minim labore id occaecat.\r\n",
    "nextSteps": "Enim dolor eu labore amet reprehenderit minim. Voluptate mollit exercitation irure cupidatat. Et nulla reprehenderit quis est ipsum proident adipisicing.\r\n",
    "investment": "Pariatur voluptate nulla incididunt irure duis velit irure cillum labore. Non commodo velit culpa voluptate minim. Velit sunt duis in et quis exercitation eu nulla ad sint enim adipisicing deserunt laborum. Id commodo eiusmod et esse ex id magna duis occaecat pariatur occaecat deserunt sit excepteur.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Amet fugiat pariatur magna dolor consequat in velit. Aute pariatur laborum cupidatat voluptate non tempor excepteur. Quis exercitation do ea occaecat excepteur non id sit adipisicing mollit. Dolor laboris incididunt laboris ex esse pariatur velit. Deserunt veniam dolore pariatur mollit ullamco tempor sunt mollit labore cillum aute mollit fugiat commodo.\r\n",
      "engagementDescr": "Magna non elit ea ipsum veniam veniam irure elit ea. Fugiat nulla tempor commodo magna. Et commodo pariatur nulla aliquip nulla excepteur et dolore id officia.\r\n",
      "dataDescr": "Eu sunt eiusmod velit ullamco irure. Exercitation ullamco amet voluptate dolore cupidatat sit consequat do culpa in. Do elit commodo qui aliquip tempor quis veniam dolore. Aute minim velit dolore officia fugiat mollit est consectetur voluptate et duis est minim. Ipsum minim do voluptate nulla esse non voluptate. Pariatur ullamco quis pariatur deserunt Lorem dolor sit ipsum adipisicing laboris. Fugiat fugiat consectetur ad et exercitation ad cupidatat quis.\r\n",
      "routeDescr": "Cupidatat deserunt deserunt nulla irure nulla sint in fugiat velit eiusmod esse ex. Duis quis et nisi duis excepteur sit pariatur. Irure qui nulla amet proident sunt mollit. Occaecat esse officia deserunt deserunt anim pariatur non adipisicing.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Wood Cochran",
        "email": "woodcochran@biospan.com",
        "fte": 0.712
      },
      {
        "id": 1,
        "name": "Alexis Greene",
        "email": "alexisgreene@biospan.com",
        "fte": 0.0374
      },
      {
        "id": 2,
        "name": "Hester Gillespie",
        "email": "hestergillespie@biospan.com",
        "fte": 0.2376
      },
      {
        "id": 3,
        "name": "Ursula Pate",
        "email": "ursulapate@biospan.com",
        "fte": 0.5545
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Booth Holloway",
        "email": "boothholloway@biospan.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 8,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 4,
        "y2": 51,
        "y3": 77
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 16,
        "y3": 90
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4202928,
      "customerVal": 1410584,
      "mvp": 93689367,
      "optimize": 7462939,
      "grow": 6879308,
      "scale": 1145699276
    },
    "nextStepsActionPlan": "Fugiat nisi ex do ullamco quis. Mollit Lorem minim consequat excepteur occaecat. Voluptate qui eu cillum anim amet velit consequat ex. Deserunt cupidatat do cillum laboris. Nisi duis aute irure ullamco non. Ea labore tempor non reprehenderit esse in esse mollit nostrud.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Tempor nostrud id tempor velit. Proident ad do do commodo. Elit esse ad aute velit eiusmod consectetur voluptate ullamco elit sunt. Labore laborum dolore eu non ad nostrud. Cupidatat velit aute laborum Lorem dolor. Commodo proident sunt anim fugiat sunt exercitation elit cupidatat elit excepteur.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-03",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03e87b3cff1d6727c7",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Opticall",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rp"
    ],
    "geography": "Sweden",
    "channel": "Online: Marketpace",
    "division": "Division One",
    "painPoint": "Sint non id id in. Sunt aute proident duis cupidatat irure amet in velit adipisicing sunt ullamco. Do proident eiusmod fugiat laboris.\r\n",
    "description": "Fugiat sint et nisi cillum anim laborum culpa nisi nostrud nostrud duis non. Labore tempor officia cillum sit est. Nulla deserunt sunt et reprehenderit anim. Adipisicing officia voluptate dolor ad anim.\r\n",
    "strategy": "Proident magna ipsum quis non incididunt laborum labore reprehenderit irure quis. Commodo excepteur laborum eu occaecat dolor officia nostrud deserunt. Duis eiusmod nostrud nostrud eu consequat non commodo.\r\n",
    "keyLearnings": "Labore est proident commodo consequat. Consectetur aliqua fugiat labore dolore exercitation consectetur culpa ullamco ipsum velit laboris reprehenderit proident. Ullamco excepteur sint nisi sit laborum et exercitation qui aliquip adipisicing do.\r\n",
    "nextSteps": "Tempor ut irure et officia occaecat in fugiat ea excepteur ut. Adipisicing ut id ea et proident fugiat pariatur labore eiusmod pariatur eu. Tempor eu culpa quis laboris irure excepteur excepteur est do tempor exercitation. Magna fugiat reprehenderit officia et commodo nulla nisi nisi labore.\r\n",
    "investment": "In ipsum aute culpa mollit dolore enim incididunt. Esse aliqua in aliqua eiusmod aute. Id cupidatat non eu fugiat magna commodo culpa eu id irure adipisicing. Veniam sunt do incididunt sunt cupidatat incididunt laborum velit magna dolor est sunt aliquip aliqua. Ea reprehenderit nulla amet elit.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Amet anim irure laborum mollit sint irure cillum incididunt. Non magna mollit cupidatat magna ea adipisicing. Ullamco enim in ad tempor magna voluptate. Non tempor qui aute proident. Qui nulla ea ad culpa qui exercitation sunt ea deserunt adipisicing elit incididunt dolor. Mollit esse nostrud consequat amet duis amet mollit aute velit sit voluptate.\r\n",
      "engagementDescr": "Incididunt excepteur mollit ut Lorem quis ut reprehenderit magna qui ipsum labore minim mollit est. Sunt laboris nostrud tempor labore elit commodo do ullamco Lorem enim cillum. Proident reprehenderit amet proident sunt incididunt excepteur ex. Deserunt in enim incididunt excepteur minim. Amet nisi cillum sunt consectetur excepteur dolore fugiat.\r\n",
      "dataDescr": "Laboris officia non qui dolor eiusmod occaecat Lorem deserunt est ipsum in voluptate. Nisi non dolore magna excepteur qui. Consectetur occaecat veniam cupidatat id dolore ut deserunt non anim eu deserunt ad. Exercitation amet amet commodo mollit sit voluptate.\r\n",
      "routeDescr": "Id labore dolor laboris adipisicing excepteur ex adipisicing exercitation eu est elit amet. Do esse labore proident irure mollit duis enim do commodo eu Lorem nisi tempor laboris. Minim dolore reprehenderit veniam commodo eiusmod velit ad eu irure amet sint ex ea. Mollit laboris irure id amet deserunt laboris Lorem proident pariatur. In tempor aliquip non exercitation anim commodo nostrud reprehenderit incididunt. Culpa enim Lorem reprehenderit sunt anim laboris ex duis Lorem dolor amet ipsum.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Mari Gamble",
        "email": "marigamble@opticall.com",
        "fte": 0.4691
      },
      {
        "id": 1,
        "name": "Landry Frye",
        "email": "landryfrye@opticall.com",
        "fte": 0.3054
      },
      {
        "id": 2,
        "name": "Lynch Davis",
        "email": "lynchdavis@opticall.com",
        "fte": 0.5449
      },
      {
        "id": 3,
        "name": "Tammie Benson",
        "email": "tammiebenson@opticall.com",
        "fte": 0.1357
      },
      {
        "id": 4,
        "name": "Ellison Lindsey",
        "email": "ellisonlindsey@opticall.com",
        "fte": 0.8553
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Dollie Wilcox",
        "email": "dolliewilcox@opticall.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 13,
        "y3": 22
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 45,
        "y3": 50
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 26,
        "y3": 54
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6296013,
      "customerVal": 425516,
      "mvp": 4323723,
      "optimize": 4053960,
      "grow": 1152887558,
      "scale": 344544720
    },
    "nextStepsActionPlan": "Sunt eu proident ipsum Lorem fugiat do consequat exercitation sint amet exercitation non ipsum occaecat. Nisi irure ipsum aliqua anim minim ad nulla aute commodo nulla pariatur sint in irure. Eu sunt deserunt enim sint amet. Ullamco irure est ea commodo officia aute pariatur quis labore cupidatat non culpa.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Esse incididunt est amet consectetur. Dolore cillum pariatur commodo sit quis occaecat minim sunt sint ea. Ipsum quis enim ex dolor adipisicing magna enim et. Voluptate enim ea excepteur enim occaecat occaecat veniam.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0324ce86e6dad881af",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Capscreen",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Sweden",
    "channel": "Offline: Pop up store",
    "division": "Division Three",
    "painPoint": "Ea duis tempor velit velit eu excepteur fugiat officia ullamco culpa nostrud sint veniam officia. Nisi reprehenderit et do nisi anim adipisicing amet nisi dolore. Esse do voluptate enim enim officia esse aliqua reprehenderit. Eiusmod ea esse anim enim deserunt amet laborum enim quis nisi qui duis nostrud. Proident mollit excepteur nostrud do sit velit esse occaecat sunt. Amet magna mollit sunt proident.\r\n",
    "description": "Et non qui deserunt eu magna occaecat pariatur qui quis Lorem. Velit proident nulla cupidatat qui non dolor proident veniam anim cillum deserunt non. Voluptate cupidatat labore anim laboris ut id aliqua id id consequat laborum tempor ut occaecat. Culpa nulla non magna incididunt anim pariatur. Officia ipsum labore ex id ex.\r\n",
    "strategy": "Nostrud laboris tempor velit culpa magna elit ex laboris veniam qui deserunt occaecat ipsum. Esse aliquip commodo consequat sit ad irure velit consectetur voluptate cupidatat deserunt adipisicing duis. Incididunt irure dolor ut labore nisi id ex non ex voluptate quis fugiat ea.\r\n",
    "keyLearnings": "Dolore nisi consectetur nulla duis aliqua. Sit dolor sit culpa veniam excepteur ex Lorem dolor anim duis in. Enim ex dolore id enim laboris excepteur non consequat irure consectetur culpa.\r\n",
    "nextSteps": "Laboris cillum voluptate elit quis elit reprehenderit est culpa. Labore id magna eiusmod sint dolore duis irure sunt consectetur et sint. Reprehenderit reprehenderit cillum reprehenderit aute amet amet sit consectetur veniam. Ipsum culpa non eu do occaecat occaecat veniam eiusmod consequat aliqua anim anim aliquip non.\r\n",
    "investment": "Consectetur tempor anim et eu proident do tempor in mollit voluptate tempor. Ullamco amet nulla duis ad reprehenderit veniam cillum laboris non ad. Duis deserunt non consequat duis aute amet nulla sit labore ullamco adipisicing.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Sit laboris nulla duis adipisicing enim ea ipsum exercitation dolor commodo dolore proident. Irure cillum cillum excepteur amet fugiat consequat aliqua non exercitation irure laboris irure commodo. Exercitation sint adipisicing eu magna. Aliqua occaecat aute amet proident.\r\n",
      "engagementDescr": "Ullamco voluptate sint veniam ea. Ullamco proident minim adipisicing culpa irure occaecat tempor velit anim ad enim tempor ad. Proident pariatur magna laboris exercitation exercitation aliquip reprehenderit proident aliqua adipisicing sint. Do reprehenderit dolore exercitation ea dolore ullamco. Lorem dolore duis exercitation excepteur occaecat elit quis sit est veniam incididunt eu est.\r\n",
      "dataDescr": "Eu in excepteur occaecat in aute sunt non adipisicing. Aliquip consectetur eu qui irure dolor. Cupidatat voluptate labore mollit ea enim aliqua eiusmod ullamco Lorem officia incididunt. Ut dolore proident et reprehenderit ea fugiat. Id quis quis deserunt ullamco est magna consequat ea excepteur veniam aute minim magna mollit.\r\n",
      "routeDescr": "Anim sunt eiusmod et minim elit pariatur. Sit duis duis nisi fugiat in quis excepteur esse. Minim aliquip commodo fugiat quis nostrud et deserunt qui. Quis id enim fugiat pariatur duis Lorem elit in cupidatat laboris. Sunt sunt nulla aute irure. Qui sit reprehenderit Lorem velit dolor velit Lorem proident excepteur dolor enim in. Pariatur anim eiusmod ea cupidatat eiusmod aliqua pariatur.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Evangelina Zimmerman",
        "email": "evangelinazimmerman@capscreen.com",
        "fte": 0.7011
      },
      {
        "id": 1,
        "name": "Grace Richards",
        "email": "gracerichards@capscreen.com",
        "fte": 0.5157
      },
      {
        "id": 2,
        "name": "Curtis Hayden",
        "email": "curtishayden@capscreen.com",
        "fte": 0.2581
      },
      {
        "id": 3,
        "name": "Brandi Mack",
        "email": "brandimack@capscreen.com",
        "fte": 0.4969
      },
      {
        "id": 4,
        "name": "Valenzuela Finley",
        "email": "valenzuelafinley@capscreen.com",
        "fte": 0.3524
      },
      {
        "id": 5,
        "name": "Agnes Sanchez",
        "email": "agnessanchez@capscreen.com",
        "fte": 0.5843
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Jeanette Mejia",
        "email": "jeanettemejia@capscreen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 20,
        "y3": 15
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 25,
        "y3": 93
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 6,
        "y2": 19,
        "y3": 95
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 953971,
      "customerVal": 615858,
      "mvp": 103876319,
      "optimize": 2635849,
      "grow": 1270763804,
      "scale": 1302928656
    },
    "nextStepsActionPlan": "Nisi aliquip sunt culpa aliqua nostrud cupidatat nisi aliqua labore cupidatat eiusmod aute labore non. Velit cupidatat adipisicing magna pariatur consectetur. Officia commodo ullamco eiusmod qui. Cupidatat excepteur duis velit nostrud fugiat esse id ipsum ea sit. Proident Lorem aute officia dolore quis id ipsum ullamco commodo amet et.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ad qui nostrud occaecat ullamco sit consectetur sint ullamco. Duis pariatur pariatur officia do dolore minim ea quis adipisicing ad. Nulla do culpa ut sit. Enim laboris fugiat sint nulla amet eu elit nostrud nostrud esse ullamco tempor.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-25",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03fea4a2f0eb82c209",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Illumity",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rp"
    ],
    "geography": "Spain",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Dolor ex occaecat et enim incididunt ut officia anim anim. Consequat deserunt nostrud in incididunt elit ea dolor est non ex. Id eu officia incididunt amet quis aliquip in velit fugiat pariatur elit dolor.\r\n",
    "description": "Deserunt commodo elit nisi in sit non. Ea culpa irure consequat magna commodo cupidatat excepteur sunt nisi labore est voluptate. Deserunt sit pariatur ad reprehenderit officia excepteur ex.\r\n",
    "strategy": "Veniam voluptate Lorem pariatur est voluptate amet do labore aute in aliqua. Eu ea fugiat excepteur ex fugiat in. Exercitation Lorem officia laboris est ea nisi occaecat dolore mollit eiusmod sint deserunt consequat. Labore nisi exercitation magna duis esse anim consequat consectetur Lorem aliqua eiusmod sint cupidatat officia. Deserunt commodo voluptate excepteur irure ad. Do occaecat officia ad nisi magna esse sit magna minim consequat. Irure ad enim ut do est sint mollit culpa qui aute irure sit.\r\n",
    "keyLearnings": "Quis laboris aliquip irure aliquip consequat sit voluptate elit veniam cillum. Dolore culpa laborum cupidatat dolore aute elit minim dolore officia et duis est. Ea laboris exercitation magna ipsum nostrud fugiat.\r\n",
    "nextSteps": "Quis id cupidatat consequat enim sit aliquip amet fugiat officia. Magna nisi adipisicing adipisicing aliqua irure esse sint nostrud culpa. Et do non ut aute veniam magna fugiat fugiat minim est ex. Tempor consequat ad veniam aliqua exercitation ea deserunt aliqua eu veniam non id minim exercitation.\r\n",
    "investment": "Do aute sit aute ad nostrud ea consectetur amet in elit laboris. Quis laborum pariatur ea est adipisicing ad commodo proident ad. Elit aliquip occaecat minim in adipisicing magna magna aliquip enim.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Eiusmod ut non labore est magna tempor dolor. Ea do pariatur do dolore laborum elit quis aute. Nisi do amet commodo adipisicing dolore commodo reprehenderit aute in officia. Non sunt minim cupidatat velit nulla excepteur veniam cillum dolor exercitation reprehenderit eu deserunt qui. Aliqua excepteur voluptate id quis eiusmod culpa excepteur non incididunt ea aute.\r\n",
      "engagementDescr": "Sit commodo commodo dolor proident do eu esse laboris nostrud veniam exercitation id do. Nostrud enim veniam dolore cupidatat duis. Enim officia ut nisi excepteur laboris ea Lorem magna ex anim ad est occaecat. Ullamco commodo amet excepteur anim sunt dolore dolore ex qui minim labore proident dolore. Aliqua nisi adipisicing ex consectetur tempor non.\r\n",
      "dataDescr": "Est veniam sint ut duis ex velit adipisicing magna. Veniam cillum ea velit laborum dolore laboris velit reprehenderit. In eiusmod et fugiat laborum elit commodo excepteur sit officia pariatur. Aliqua laboris deserunt do proident duis fugiat sint id excepteur aliquip culpa ad ea et. Ipsum et incididunt mollit qui elit id sint consectetur et nostrud nulla non cillum reprehenderit. Dolor ipsum tempor consequat aliqua est ad velit cillum deserunt aliquip anim cupidatat.\r\n",
      "routeDescr": "Officia excepteur ex incididunt proident non anim. Sit est elit occaecat dolor velit ipsum tempor excepteur occaecat laborum et. Exercitation in adipisicing incididunt culpa adipisicing cupidatat sunt do enim. Est velit reprehenderit nostrud nulla duis do dolore. Elit velit elit enim eu velit excepteur consectetur quis proident nisi anim deserunt consectetur. Ut minim anim excepteur eiusmod amet sint amet enim. Culpa sint enim irure aliqua amet incididunt non incididunt ullamco in ex.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Karla Conrad",
        "email": "karlaconrad@illumity.com",
        "fte": 0.0824
      },
      {
        "id": 1,
        "name": "Rochelle Blevins",
        "email": "rochelleblevins@illumity.com",
        "fte": 0.3991
      },
      {
        "id": 2,
        "name": "Millicent Hurst",
        "email": "millicenthurst@illumity.com",
        "fte": 0.0016
      },
      {
        "id": 3,
        "name": "Rena Durham",
        "email": "renadurham@illumity.com",
        "fte": 0.9174
      },
      {
        "id": 4,
        "name": "Guerrero Spears",
        "email": "guerrerospears@illumity.com",
        "fte": 0.4178
      },
      {
        "id": 5,
        "name": "Dorothea Mclaughlin",
        "email": "dorotheamclaughlin@illumity.com",
        "fte": 0.6274
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Blankenship Burgess",
        "email": "blankenshipburgess@illumity.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 19,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 51,
        "y3": 98
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 27,
        "y3": 65
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 594618,
      "customerVal": 849936,
      "mvp": 118838316,
      "optimize": 10654287,
      "grow": 1347980905,
      "scale": 353239674
    },
    "nextStepsActionPlan": "Do exercitation dolore nostrud aliqua sint elit ad eu cillum ad. Exercitation esse ut id est occaecat minim. Et exercitation pariatur duis ipsum ut enim ad mollit. Nostrud elit pariatur eu minim voluptate pariatur. Aliquip nostrud id minim labore do et non. Sint aliqua id esse commodo Lorem. Esse aliqua non officia eu id voluptate deserunt quis officia exercitation mollit.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sit est commodo proident ea aute tempor dolor labore proident. Reprehenderit qui laborum tempor excepteur commodo ullamco magna aliquip. Cupidatat aute ex reprehenderit do proident proident veniam laborum aliqua duis nostrud ea dolor anim. Nisi do veniam est ea id fugiat non eiusmod cillum consequat. Exercitation veniam sint do enim eiusmod anim culpa Lorem aliquip ipsum eu non elit. Excepteur dolore ad laborum proident qui cillum adipisicing occaecat excepteur aute.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-18",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03dc93154af08e9d05",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Enquility",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "Sweden",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Id incididunt mollit ad irure esse proident mollit dolore. Irure mollit deserunt consectetur officia ex nostrud cupidatat. Laborum quis adipisicing deserunt amet tempor magna eu qui minim enim nulla Lorem sit sit. Dolore voluptate velit dolor excepteur adipisicing sint dolore ullamco. Aute non mollit eiusmod voluptate aute anim nulla pariatur duis voluptate nostrud ipsum. Deserunt commodo do Lorem laboris. Labore ipsum mollit enim Lorem cupidatat ea exercitation eiusmod.\r\n",
    "description": "Irure qui laborum ut excepteur adipisicing velit ipsum sint nisi. Laboris qui aliquip magna dolor aliquip mollit proident deserunt veniam culpa laboris. Reprehenderit enim aliqua excepteur et dolore magna ullamco dolore.\r\n",
    "strategy": "Aliqua non nisi enim dolore duis est nisi enim ad adipisicing sint eu laborum. Incididunt adipisicing anim consectetur quis voluptate mollit ut labore esse minim consequat occaecat velit. Ullamco id veniam amet magna anim duis dolore commodo quis quis pariatur id.\r\n",
    "keyLearnings": "Irure quis fugiat commodo ea cupidatat dolore ullamco dolore Lorem magna officia reprehenderit. Deserunt irure minim qui Lorem irure commodo veniam sunt. Cillum ipsum id dolore ex id eu proident sit aliqua et aute duis culpa exercitation.\r\n",
    "nextSteps": "Ex in nisi sint commodo culpa nulla ullamco exercitation mollit non elit. Sunt elit in occaecat fugiat eiusmod elit anim labore cillum tempor nostrud. Proident proident irure nisi et incididunt consectetur pariatur Lorem voluptate exercitation do excepteur. Consequat aliquip sit sint ut aliquip laborum fugiat ut pariatur do proident. Ad sunt irure aliqua consequat.\r\n",
    "investment": "Adipisicing nulla sint nulla qui. Veniam consequat velit labore voluptate sint aliquip reprehenderit qui aliquip occaecat Lorem cillum. Ea duis quis qui cillum magna enim exercitation sunt qui adipisicing. Id irure nisi culpa ut irure do sit Lorem ea laboris exercitation magna cillum fugiat. Aliqua commodo labore veniam nisi ipsum enim ut do elit aliqua. Quis cupidatat anim est amet deserunt excepteur duis.\r\n",
    "currentStage": "customerVal",
    "sovDescription": {
      "intrinsicDescr": "Sunt magna consectetur aliqua cillum non duis eu amet adipisicing tempor veniam. Qui aute incididunt aute qui quis tempor labore adipisicing non dolor magna cupidatat et. Laboris non dolor amet voluptate excepteur duis aliquip. Aliquip nulla proident minim incididunt adipisicing cillum ullamco ut labore exercitation eiusmod dolore nisi. Mollit aute duis commodo in. Sit excepteur proident nostrud adipisicing mollit veniam esse adipisicing magna Lorem anim nisi. Cillum reprehenderit aliquip qui duis sint est qui minim aliquip deserunt nulla id pariatur elit.\r\n",
      "engagementDescr": "Aliqua ullamco elit nulla ullamco consequat irure do reprehenderit ullamco adipisicing anim cillum ea anim. Fugiat incididunt nostrud et sint exercitation laboris dolor commodo nostrud qui Lorem. Deserunt officia labore magna ullamco elit mollit velit tempor dolor ad sunt elit. Tempor nostrud consequat tempor non cupidatat dolor deserunt consectetur culpa. Proident nostrud quis sit aliquip exercitation. Aute ipsum nulla nostrud aliquip ut amet sit enim occaecat dolor nulla nostrud. Sit consequat exercitation nisi nulla et aute dolor nisi.\r\n",
      "dataDescr": "Commodo minim esse sunt sint aliquip deserunt irure incididunt ex eu irure occaecat ullamco. Voluptate dolore dolor aute velit consectetur. Nisi ea veniam proident ipsum Lorem exercitation.\r\n",
      "routeDescr": "Nostrud qui elit elit incididunt. Nulla incididunt ut fugiat anim in anim non excepteur exercitation eu id irure. Officia adipisicing velit et velit laboris excepteur quis commodo labore proident elit exercitation. Incididunt culpa excepteur laboris proident ipsum magna pariatur eiusmod incididunt adipisicing voluptate sint sunt. Proident nostrud nisi reprehenderit non mollit aute. Duis nisi voluptate veniam sit exercitation elit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Milagros Navarro",
        "email": "milagrosnavarro@enquility.com",
        "fte": 0.8812
      },
      {
        "id": 1,
        "name": "Guy Cain",
        "email": "guycain@enquility.com",
        "fte": 0.9663
      },
      {
        "id": 2,
        "name": "Calhoun Marsh",
        "email": "calhounmarsh@enquility.com",
        "fte": 0.6894
      },
      {
        "id": 3,
        "name": "Norma Cobb",
        "email": "normacobb@enquility.com",
        "fte": 0.3323
      },
      {
        "id": 4,
        "name": "Alfreda Austin",
        "email": "alfredaaustin@enquility.com",
        "fte": 0.1227
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Snyder Summers",
        "email": "snydersummers@enquility.com"
      },
      {
        "id": 1,
        "name": "Candace Ferguson",
        "email": "candaceferguson@enquility.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 11,
        "y3": 20
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 54,
        "y3": 61
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 28,
        "y3": 75
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2263940,
      "customerVal": 979795,
      "mvp": 67794405,
      "optimize": 1162799,
      "grow": 511473158,
      "scale": 857940288
    },
    "nextStepsActionPlan": "Aute elit culpa ullamco culpa deserunt elit mollit non minim et labore officia exercitation. Consectetur anim mollit est veniam minim nisi officia sint magna et. Nostrud quis aliqua eu aliquip laborum id enim deserunt ut quis.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Eu velit cillum ut enim Lorem excepteur incididunt nulla. Ex ut ut occaecat quis. Tempor non minim esse ea anim. Occaecat excepteur deserunt ullamco elit amet ipsum est. Quis pariatur cupidatat nostrud eiusmod ipsum ex Lorem fugiat qui eu exercitation. Labore cupidatat fugiat adipisicing minim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-17",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b05a2e6e817b0f87",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Waterbaby",
    "highlightKPI": [
      "nsv",
      "ltv",
      "tc"
    ],
    "geography": "Germany",
    "channel": "Online: Flagship store",
    "division": "Division Two",
    "painPoint": "Culpa ex ad dolore exercitation deserunt in aliqua ad. Ullamco deserunt nisi sint veniam dolor est veniam dolore ea tempor pariatur ex occaecat aliqua. Magna fugiat tempor eiusmod deserunt cillum adipisicing proident. Do enim et non velit duis culpa est ullamco id officia. Velit eu non non aliqua. Laboris eiusmod ad id laboris ad commodo consectetur magna velit sit mollit.\r\n",
    "description": "Ex nostrud non est amet amet sint mollit aute commodo sit in aliquip. Adipisicing culpa deserunt aliqua consequat ipsum ut enim proident. Voluptate nisi voluptate minim cupidatat veniam consequat. Eu magna nulla eiusmod minim elit culpa dolore consectetur nulla. Nulla veniam exercitation consectetur sunt dolor irure voluptate id ipsum voluptate. Id incididunt tempor ipsum eiusmod magna aute culpa pariatur nostrud.\r\n",
    "strategy": "Adipisicing nulla ut laboris exercitation cupidatat fugiat in laboris quis ad adipisicing cillum magna. Et laboris Lorem laboris tempor. Voluptate officia ullamco pariatur qui. Consectetur aute sit aute ex eiusmod magna do ullamco cillum aliquip.\r\n",
    "keyLearnings": "Nulla incididunt sunt amet labore aute esse ipsum amet quis. Sunt consectetur elit voluptate eu ea nulla duis. Eu nostrud duis culpa dolore laboris nulla exercitation sint voluptate dolor labore labore. Dolor ea incididunt fugiat sit anim occaecat voluptate reprehenderit commodo dolore. Exercitation tempor ullamco sunt excepteur elit ad deserunt do laboris minim aute. Culpa veniam dolor ipsum ullamco ipsum ex veniam. Consectetur sint do in magna sit eiusmod.\r\n",
    "nextSteps": "Nisi laboris reprehenderit quis et cupidatat voluptate incididunt culpa eiusmod in irure. Ex cillum commodo ipsum duis consectetur commodo. Aute reprehenderit nostrud cillum ut fugiat deserunt dolore consequat irure irure non Lorem mollit nostrud. Aute Lorem sint do aliqua anim pariatur incididunt minim consectetur magna elit irure. Sint dolore pariatur laboris nostrud excepteur nisi magna et laborum voluptate veniam do aliquip. Dolor mollit magna culpa eiusmod incididunt magna ea.\r\n",
    "investment": "Proident minim laborum exercitation labore esse mollit. Lorem incididunt anim qui fugiat. Ut tempor dolore aliqua qui ullamco laborum sunt commodo laborum.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Amet minim elit sunt enim nisi laboris mollit ut commodo reprehenderit. Enim labore minim voluptate ad ullamco ipsum. Sit non duis cillum sit ipsum magna.\r\n",
      "engagementDescr": "Eiusmod nulla est do sit fugiat tempor cillum culpa Lorem. Consectetur est anim et commodo id elit. Occaecat eu deserunt ipsum adipisicing consectetur sit.\r\n",
      "dataDescr": "Culpa ipsum ad dolore ex anim officia aliqua laborum. Elit consequat ex ullamco pariatur ullamco nisi laboris quis anim nostrud anim nisi. In irure laborum incididunt irure ex aliqua ipsum ut minim duis non exercitation ipsum eiusmod. Enim non velit ipsum deserunt sint id deserunt ad quis. Ut qui ipsum eu elit excepteur ea et culpa et esse aliquip. Nulla amet mollit deserunt minim. Irure tempor sunt est nostrud dolor eiusmod eiusmod sint do cillum pariatur commodo commodo.\r\n",
      "routeDescr": "In anim enim consectetur adipisicing amet laboris excepteur tempor nulla nisi. Fugiat in veniam occaecat occaecat deserunt adipisicing eu velit eu est proident dolor dolore. Ea ex commodo ullamco elit sit ex duis est eiusmod minim non dolor laboris non. Esse sunt proident reprehenderit elit do esse. Nisi quis aliqua nostrud ut fugiat est ut ea pariatur ullamco consequat velit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Tania Perez",
        "email": "taniaperez@waterbaby.com",
        "fte": 0.7293
      },
      {
        "id": 1,
        "name": "Bonner Byers",
        "email": "bonnerbyers@waterbaby.com",
        "fte": 0.3981
      },
      {
        "id": 2,
        "name": "Jennifer Townsend",
        "email": "jennifertownsend@waterbaby.com",
        "fte": 0.113
      },
      {
        "id": 3,
        "name": "Clarke Logan",
        "email": "clarkelogan@waterbaby.com",
        "fte": 0.3605
      },
      {
        "id": 4,
        "name": "Stacy Russell",
        "email": "stacyrussell@waterbaby.com",
        "fte": 0.5713
      },
      {
        "id": 5,
        "name": "Beatriz Vinson",
        "email": "beatrizvinson@waterbaby.com",
        "fte": 0.8423
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Debra Foley",
        "email": "debrafoley@waterbaby.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 13,
        "y3": 32
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 5,
        "y2": 35,
        "y3": 84
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 27,
        "y3": 73
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8259388,
      "customerVal": 254649,
      "mvp": 6831777,
      "optimize": 8741498,
      "grow": 283952894,
      "scale": 1023858377
    },
    "nextStepsActionPlan": "Nulla nostrud ad mollit sint tempor nostrud. Cupidatat qui voluptate aliqua officia labore enim officia consequat veniam. Consectetur esse irure veniam consequat occaecat consectetur ad eiusmod. Exercitation sint non id eu dolor ullamco tempor irure. Sit aliquip magna non dolor et duis ad esse non consectetur culpa veniam. Sint ut irure amet magna.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Dolor commodo reprehenderit laboris consequat dolore magna est laboris laborum aliquip. Esse laboris in labore fugiat sit commodo exercitation voluptate enim veniam. Aliqua ipsum anim labore minim aliquip culpa anim veniam fugiat. Aliqua eu sint et nisi anim sint. Dolore sunt aliquip minim aliquip. Laboris est excepteur est aliqua.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-08",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0311389e231b51403f",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Quarmony",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Amet labore occaecat nostrud eu ad enim aliquip do non do occaecat elit nostrud aliqua. Irure laborum dolor commodo aliquip nostrud exercitation minim nisi ea. Esse adipisicing laboris nisi id esse dolore do esse et dolor culpa veniam nostrud. Laborum eiusmod ut ea culpa culpa excepteur velit nostrud nulla Lorem adipisicing laboris proident. Cillum veniam duis adipisicing ex laboris nisi non ipsum amet exercitation velit minim.\r\n",
    "description": "Consectetur reprehenderit eiusmod qui mollit occaecat laboris culpa qui dolor aliqua reprehenderit. Velit consequat deserunt labore sint dolore eu consectetur mollit. Anim duis sit cupidatat non fugiat reprehenderit laborum commodo nulla ea deserunt tempor. Culpa exercitation cupidatat eu voluptate ex eu nulla consequat laborum irure. Eiusmod nostrud ut dolore excepteur esse nostrud tempor id enim voluptate pariatur id do.\r\n",
    "strategy": "Nulla nulla labore elit laborum sint cupidatat excepteur. Ut esse laboris tempor labore voluptate. Ullamco deserunt aute id reprehenderit qui magna commodo nostrud commodo exercitation fugiat. Incididunt eiusmod id dolor culpa laboris tempor dolor commodo. Velit proident id esse Lorem deserunt magna eu dolor tempor deserunt eiusmod. Adipisicing exercitation esse labore elit ipsum est amet fugiat. Esse cillum magna cupidatat sunt Lorem qui aliquip.\r\n",
    "keyLearnings": "Non dolor pariatur nisi veniam anim nisi est id cupidatat ex excepteur minim minim voluptate. Voluptate aliquip non incididunt excepteur sit non et sunt voluptate quis consequat fugiat. Sunt ad ad ea culpa duis qui officia dolor aute culpa officia aute do. Lorem non aute nostrud deserunt id consequat quis et exercitation ex magna amet. Adipisicing sint qui id proident aliqua commodo. Quis amet nostrud voluptate Lorem consectetur. Sint magna voluptate fugiat ad elit proident do.\r\n",
    "nextSteps": "Nisi reprehenderit consequat est magna Lorem culpa voluptate deserunt excepteur enim adipisicing. Incididunt ad minim mollit sunt. Culpa laboris dolore eiusmod deserunt. Non id quis occaecat sunt sunt quis incididunt. Duis elit ullamco magna do non non dolor.\r\n",
    "investment": "Magna qui incididunt tempor ipsum esse ad culpa veniam cupidatat in ad qui. Ex consequat laboris ex Lorem. Laboris commodo sunt ipsum officia ut ullamco exercitation eu velit do voluptate dolor do pariatur. Esse labore sunt laboris excepteur dolore irure.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Veniam quis occaecat ullamco excepteur eiusmod et irure commodo. Veniam ea adipisicing proident magna officia minim ut commodo ut ut sunt. Do est eiusmod ex nostrud id. Excepteur est aute fugiat laboris id veniam dolore. Aliqua amet qui reprehenderit pariatur cupidatat reprehenderit nostrud occaecat ut adipisicing. Sint ex ex aute consequat duis quis magna incididunt amet quis laboris eu adipisicing elit. Do nisi exercitation eiusmod nisi do duis ad ut officia.\r\n",
      "engagementDescr": "Aliqua cupidatat est sunt culpa cupidatat mollit do Lorem in excepteur dolore tempor. Elit sunt id irure in incididunt id commodo ipsum amet consectetur deserunt anim. Anim sint occaecat quis fugiat nostrud. Aliquip deserunt elit ad quis magna incididunt proident sit non eiusmod esse qui proident.\r\n",
      "dataDescr": "Aliqua ea ea esse esse irure ea. Pariatur ad ea dolore dolor. Proident anim tempor cupidatat dolore. Elit qui mollit qui nisi non duis magna enim.\r\n",
      "routeDescr": "Occaecat aliqua ad irure ex anim non excepteur nostrud tempor occaecat. Fugiat non cillum laboris adipisicing sit laborum eu sunt enim enim. Nisi incididunt amet ea sint velit. Esse nulla sit excepteur proident ad aute mollit nulla ipsum cupidatat est. Veniam laboris sit velit dolore sint laborum. Qui veniam velit consequat exercitation eiusmod.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Effie Sanders",
        "email": "effiesanders@quarmony.com",
        "fte": 0.4274
      },
      {
        "id": 1,
        "name": "Abigail Jefferson",
        "email": "abigailjefferson@quarmony.com",
        "fte": 0.0855
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Letitia Lawrence",
        "email": "letitialawrence@quarmony.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 11,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 2,
        "y2": 22,
        "y3": 67
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 20,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8745430,
      "customerVal": 953184,
      "mvp": 104217974,
      "optimize": 7918481,
      "grow": 284031455,
      "scale": 1480945462
    },
    "nextStepsActionPlan": "Ex eu excepteur irure elit proident occaecat voluptate ea pariatur aliquip ipsum ipsum dolore. Esse excepteur nisi pariatur tempor fugiat cillum dolor. Amet cillum sit amet exercitation commodo et aliquip fugiat aliqua reprehenderit non est sunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Elit dolore laborum minim non fugiat eiusmod laborum ad reprehenderit minim voluptate tempor irure laborum. Proident laborum ad id nulla. Aliqua ut minim aliqua veniam aute enim consectetur aliqua culpa sint fugiat labore ex. Occaecat ad aliqua et enim sint consectetur cupidatat laborum elit consectetur pariatur culpa laboris quis. Fugiat occaecat incididunt nulla Lorem tempor incididunt consequat minim. Do aliquip mollit laboris mollit anim adipisicing dolore Lorem commodo sint officia velit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-25",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e039eb7c67dcd761310",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Digigene",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "United States",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Excepteur ipsum excepteur aute ex officia veniam minim. Labore magna aliquip dolor amet labore deserunt nulla ullamco laboris do sint labore laboris enim. Excepteur esse exercitation amet esse sit non culpa pariatur aliquip eiusmod.\r\n",
    "description": "Dolore sint ipsum Lorem ut non tempor excepteur non. Culpa aliquip ipsum in laboris reprehenderit sunt velit eiusmod. Nisi aliquip commodo irure nostrud consequat occaecat in amet laboris adipisicing. Irure id eu pariatur ullamco velit id consectetur Lorem ipsum nostrud cillum ex. Lorem adipisicing est laborum sint ad consequat sint cupidatat. Et Lorem veniam id officia minim velit pariatur pariatur nostrud voluptate ipsum labore enim. Incididunt consequat eiusmod dolore amet ipsum duis sit exercitation id cupidatat.\r\n",
    "strategy": "Eiusmod nulla duis culpa est dolore eiusmod ipsum dolor occaecat mollit ex in eu labore. Mollit sit velit qui do ad non proident deserunt. Est nostrud adipisicing sit eu exercitation aliquip veniam. Sint labore qui ullamco deserunt enim quis enim nostrud adipisicing.\r\n",
    "keyLearnings": "Cupidatat occaecat velit ipsum esse ex ipsum quis eiusmod voluptate laboris tempor non. Pariatur sunt anim in enim ex ut dolore deserunt deserunt cillum enim ea adipisicing nisi. Proident cupidatat non deserunt ipsum sit exercitation ex cillum cupidatat culpa. Aute elit sunt anim exercitation eu nulla est non exercitation ipsum.\r\n",
    "nextSteps": "Fugiat aliqua veniam reprehenderit laboris ut cupidatat id esse cupidatat proident. Magna commodo consequat irure aute aliqua. Enim pariatur aute veniam id commodo deserunt non aliquip minim. Est consectetur voluptate do esse commodo sunt fugiat. Nulla duis ad velit officia. Commodo enim ea culpa ea adipisicing. Id qui mollit proident ullamco anim qui.\r\n",
    "investment": "Consectetur sint dolore anim Lorem proident anim commodo nisi reprehenderit nisi aliquip et ad duis. Ea dolore Lorem ipsum eiusmod. Consectetur duis irure non deserunt aliquip occaecat dolor duis non sint. Esse incididunt eu enim in. Elit nostrud officia laboris enim veniam. Magna officia exercitation Lorem minim qui ea incididunt qui voluptate Lorem cillum mollit culpa.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Irure exercitation esse et voluptate occaecat pariatur velit ea aute proident enim velit consectetur. Cillum proident dolore in sunt nisi consequat proident consectetur do ad magna ex pariatur ipsum. Irure aute veniam ullamco excepteur enim laborum proident Lorem. Consectetur et officia laborum id aliqua quis et Lorem dolore reprehenderit consequat ut esse. Enim dolor sit ad consectetur mollit qui eu ipsum ipsum.\r\n",
      "engagementDescr": "Eu id consectetur Lorem enim aliquip ut laborum eu dolore aliqua. Quis eu nostrud ad ex commodo tempor. Non mollit aute magna dolore tempor anim elit ut ad et duis. In ullamco incididunt ut ea qui ex anim cupidatat aute consequat dolore. Est laboris ad veniam ad occaecat mollit exercitation id aute ex labore cillum esse Lorem. Velit proident cillum nostrud duis.\r\n",
      "dataDescr": "Excepteur est nostrud reprehenderit non amet dolor culpa elit ex magna. Laborum occaecat proident ullamco ex culpa amet occaecat nulla aliqua aute do adipisicing consectetur. Eiusmod non do consectetur nulla magna cillum officia culpa officia irure. Lorem est esse quis excepteur incididunt ipsum sunt sunt eiusmod aute adipisicing magna amet. Nostrud adipisicing voluptate duis Lorem exercitation ad culpa sit.\r\n",
      "routeDescr": "Minim veniam labore nisi sint qui eu sit ea exercitation est est laboris minim. Est dolore nostrud non nostrud sunt laboris esse qui nulla et fugiat Lorem. Enim ex magna deserunt exercitation exercitation deserunt aliquip.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Bessie Ewing",
        "email": "bessieewing@digigene.com",
        "fte": 0.9572
      },
      {
        "id": 1,
        "name": "Joanne Booker",
        "email": "joannebooker@digigene.com",
        "fte": 0.9011
      },
      {
        "id": 2,
        "name": "Riggs Bray",
        "email": "riggsbray@digigene.com",
        "fte": 0.5216
      },
      {
        "id": 3,
        "name": "Vega Mullins",
        "email": "vegamullins@digigene.com",
        "fte": 0.1362
      },
      {
        "id": 4,
        "name": "Terrell Haney",
        "email": "terrellhaney@digigene.com",
        "fte": 0.5612
      },
      {
        "id": 5,
        "name": "Mcclure Harmon",
        "email": "mcclureharmon@digigene.com",
        "fte": 0.4031
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Mathews Ball",
        "email": "mathewsball@digigene.com"
      },
      {
        "id": 1,
        "name": "Owen Murray",
        "email": "owenmurray@digigene.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 16,
        "y3": 30
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 48,
        "y3": 57
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 13,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12063472,
      "customerVal": 743779,
      "mvp": 141784542,
      "optimize": 7217097,
      "grow": 1039693099,
      "scale": 390926516
    },
    "nextStepsActionPlan": "Elit veniam laborum est veniam commodo irure voluptate duis. Est dolor consequat aliqua laboris voluptate sint irure. Nostrud occaecat deserunt sit ea aliqua qui proident occaecat aliqua aliquip. Cillum tempor deserunt ea cillum sit pariatur cillum reprehenderit exercitation. Fugiat laboris duis amet deserunt duis nulla consectetur in nulla proident et.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Proident ut ipsum proident do do laboris esse sint incididunt mollit. Adipisicing culpa aliqua dolore cupidatat amet commodo culpa occaecat pariatur deserunt do. Nostrud qui elit est amet do eu non magna reprehenderit nisi ea aliqua fugiat. Tempor quis est nostrud do labore excepteur laboris nulla proident eu eu.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-30",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03ccab4d8a7bfabc71",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Enthaze",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "Norway",
    "channel": "Offline: Flagship store",
    "division": "Division Two",
    "painPoint": "Exercitation ipsum in quis nisi consectetur pariatur minim sint mollit irure. Occaecat veniam et pariatur dolore. Et velit ipsum ea amet. Magna aliqua commodo irure incididunt. Enim Lorem voluptate dolor minim nisi amet esse aute magna exercitation excepteur quis. Sint et Lorem velit sunt labore elit incididunt est veniam exercitation mollit velit nulla veniam. Quis magna tempor et ut mollit laboris minim culpa.\r\n",
    "description": "Quis laborum elit reprehenderit dolor pariatur eiusmod officia est deserunt. Occaecat non duis tempor laborum fugiat voluptate id velit irure reprehenderit laborum. Eu id nulla fugiat ex incididunt eu cupidatat irure deserunt. Consequat qui labore sint culpa ad id laboris duis minim laboris aliquip.\r\n",
    "strategy": "Do labore voluptate et magna officia excepteur nisi do. Lorem eu ipsum aute eiusmod cupidatat non occaecat. Qui fugiat culpa proident nisi esse. Esse sunt adipisicing mollit duis exercitation reprehenderit. Laboris sint ipsum eu mollit duis elit do sit deserunt duis ipsum veniam. Est et culpa eiusmod sunt minim proident aliquip magna cillum.\r\n",
    "keyLearnings": "Ex fugiat eiusmod id sit sit ex non aliquip voluptate exercitation velit reprehenderit voluptate. Est voluptate sint nostrud esse duis cupidatat id reprehenderit. Ullamco dolore est nisi nulla cupidatat duis aliquip aute sint ullamco ut. Tempor aute consequat ullamco ex officia quis occaecat minim.\r\n",
    "nextSteps": "Irure et proident enim ullamco non eu ea cillum aliquip dolor cupidatat. Consequat culpa eiusmod nisi eu nisi velit incididunt laborum tempor est id minim. Pariatur adipisicing ullamco cupidatat consequat irure elit est voluptate aute elit.\r\n",
    "investment": "Cupidatat in dolore irure ad culpa nisi eu adipisicing eu proident irure consequat. Fugiat et elit ad laboris nisi irure voluptate. Voluptate sunt anim sint enim consectetur excepteur deserunt laboris magna eiusmod deserunt.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Dolore reprehenderit culpa duis dolore sint incididunt dolore. Consequat quis sint do tempor est sit culpa. Eu fugiat occaecat ut voluptate occaecat. Incididunt eu eu tempor id excepteur fugiat laboris exercitation. Nostrud cupidatat ipsum dolor occaecat pariatur amet elit nulla labore nulla. Nisi ullamco nostrud consequat aute ea elit amet. Adipisicing sit exercitation nostrud qui duis aliquip nostrud pariatur Lorem veniam magna consequat.\r\n",
      "engagementDescr": "Consectetur est excepteur tempor id laboris non laboris cupidatat sit. Aliqua sunt aute aliqua adipisicing enim est dolor. Dolore pariatur pariatur irure voluptate magna pariatur officia ea dolor consectetur veniam aliquip culpa. Minim officia dolore occaecat fugiat tempor officia ullamco Lorem nostrud laborum nostrud commodo elit do. Non nisi sit sunt ipsum sit cillum sunt eiusmod velit minim ullamco consectetur laboris. In minim laboris tempor cillum pariatur excepteur ullamco esse consectetur in.\r\n",
      "dataDescr": "Do magna laboris ad nulla dolor non quis proident sunt. Ut duis amet sit ea sit est qui. Labore duis est nostrud irure officia nostrud excepteur ullamco eiusmod incididunt nostrud do. Duis cupidatat quis nulla eiusmod ut tempor cillum proident enim sint elit irure exercitation.\r\n",
      "routeDescr": "Eu mollit ex fugiat dolore in minim deserunt. Duis proident elit ad duis veniam commodo Lorem esse enim. Dolor amet veniam cillum ut irure fugiat laboris nulla dolor. Laborum labore occaecat velit dolore ut est et. Et esse reprehenderit consectetur incididunt quis irure do. Sit consectetur incididunt tempor occaecat est nisi do esse veniam dolor id exercitation cupidatat. Consequat esse quis sunt amet adipisicing nostrud sunt do nisi amet commodo sunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Nikki Becker",
        "email": "nikkibecker@enthaze.com",
        "fte": 0.1711
      },
      {
        "id": 1,
        "name": "Blackwell Cook",
        "email": "blackwellcook@enthaze.com",
        "fte": 0.4953
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Gentry Patterson",
        "email": "gentrypatterson@enthaze.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 13,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 53,
        "y3": 75
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 3,
        "y2": 27,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11380457,
      "customerVal": 1276540,
      "mvp": 100305743,
      "optimize": 12492821,
      "grow": 1492165537,
      "scale": 531632502
    },
    "nextStepsActionPlan": "Ad magna in qui cupidatat velit in quis qui exercitation deserunt velit. Ullamco nostrud aliqua officia quis. Proident nulla laboris id ipsum proident. Et adipisicing enim et sint labore nostrud occaecat irure ad. Magna dolor mollit mollit incididunt minim.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ea consequat commodo commodo esse consequat officia cupidatat nostrud velit anim eu quis ut enim. Labore irure exercitation laboris dolor. Irure eu amet labore velit labore velit do aliqua commodo non enim tempor cillum minim. Culpa esse excepteur duis consequat reprehenderit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03a40f839c6a6b96b7",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Permadyne",
    "highlightKPI": [
      "cac",
      "ltv",
      "rr"
    ],
    "geography": "Spain",
    "channel": "Online: Social media",
    "division": "Division Two",
    "painPoint": "Sint nulla adipisicing culpa sint. Aute excepteur consequat anim consequat culpa voluptate duis irure duis et qui do dolor. Voluptate sit ex laboris laboris nisi.\r\n",
    "description": "Amet adipisicing dolore officia pariatur tempor officia. Est do reprehenderit est in eu duis. Aliquip ea incididunt eiusmod cupidatat voluptate velit in officia nisi qui laborum.\r\n",
    "strategy": "Deserunt laboris aliqua consectetur sint duis non reprehenderit. Exercitation reprehenderit esse est ex officia do nulla et. Reprehenderit magna eu enim nostrud. Occaecat mollit incididunt in consequat officia id ex. Esse ullamco aute culpa sunt minim qui. In sunt dolor cupidatat eiusmod occaecat laborum excepteur est elit voluptate in adipisicing ut. Minim veniam occaecat nostrud ea.\r\n",
    "keyLearnings": "Aute consectetur eiusmod nisi labore nisi sint dolore esse nulla velit culpa occaecat non. Excepteur duis deserunt id minim esse ut velit excepteur cupidatat Lorem dolore nisi et culpa. Elit in labore magna est amet culpa qui consectetur consectetur consectetur. Id commodo duis labore culpa ipsum ullamco Lorem. Dolore fugiat elit voluptate ex occaecat eiusmod est anim incididunt.\r\n",
    "nextSteps": "Ex qui exercitation incididunt anim consectetur reprehenderit ipsum quis occaecat nostrud. Veniam ex magna nisi nostrud laborum id elit dolor qui. Ex proident quis ea irure exercitation excepteur exercitation.\r\n",
    "investment": "Veniam nisi deserunt do ut nisi aliqua aute. Voluptate est officia qui commodo labore proident magna voluptate veniam do Lorem in pariatur. Occaecat aliquip ut est amet cillum aute incididunt. Magna voluptate non ipsum pariatur commodo. Anim ea irure id pariatur esse est pariatur esse ut id elit adipisicing.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Duis enim sit irure amet incididunt commodo fugiat qui nostrud dolore excepteur. Laborum amet esse nostrud incididunt anim. Id laboris id laborum consectetur exercitation. Reprehenderit anim anim irure cillum. Cupidatat mollit dolor excepteur tempor.\r\n",
      "engagementDescr": "Proident veniam magna aute sunt do non ad consectetur nulla mollit quis amet irure sint. Eiusmod ex non incididunt quis adipisicing sit esse laborum ullamco sit incididunt. Dolor sunt aliquip pariatur Lorem esse pariatur magna quis sit dolor mollit. Ea esse cillum pariatur in sit aliquip sit et. Laborum dolore non nostrud incididunt velit ullamco ut laborum voluptate incididunt. Reprehenderit culpa ea ullamco irure amet laboris occaecat quis voluptate dolore. Minim consequat nisi enim qui deserunt non enim sint adipisicing in.\r\n",
      "dataDescr": "Sint occaecat aliqua ad voluptate amet laboris consequat deserunt voluptate tempor fugiat tempor consectetur. Ut mollit ex ipsum nostrud dolore. Est cillum cillum proident laborum ullamco dolor labore. Esse esse ea non qui esse anim velit commodo.\r\n",
      "routeDescr": "Qui mollit mollit id reprehenderit fugiat elit dolor ad ex. Tempor duis cupidatat reprehenderit Lorem ullamco. Laboris veniam exercitation deserunt excepteur do non esse ipsum. Aute eu anim sit est ea culpa fugiat cupidatat aute labore consequat aute. Aliqua elit cupidatat nulla officia. Commodo in consequat tempor aliqua dolor qui aute minim non.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Carroll Coleman",
        "email": "carrollcoleman@permadyne.com",
        "fte": 0.6767
      },
      {
        "id": 1,
        "name": "Kristi Gallegos",
        "email": "kristigallegos@permadyne.com",
        "fte": 0.176
      },
      {
        "id": 2,
        "name": "Mercedes Ballard",
        "email": "mercedesballard@permadyne.com",
        "fte": 0.3406
      },
      {
        "id": 3,
        "name": "Jefferson Pitts",
        "email": "jeffersonpitts@permadyne.com",
        "fte": 0.0262
      },
      {
        "id": 4,
        "name": "Pearson Quinn",
        "email": "pearsonquinn@permadyne.com",
        "fte": 0.8939
      },
      {
        "id": 5,
        "name": "Amber Farrell",
        "email": "amberfarrell@permadyne.com",
        "fte": 0.8087
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Price Moran",
        "email": "pricemoran@permadyne.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 15,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 43,
        "y3": 73
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 18,
        "y3": 31
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 9716558,
      "customerVal": 725436,
      "mvp": 57533736,
      "optimize": 8388681,
      "grow": 64371211,
      "scale": 513073114
    },
    "nextStepsActionPlan": "Qui tempor nulla irure veniam ea. Ea laborum laboris ipsum quis amet sit aliquip ut nulla minim cillum. Cupidatat nisi do est tempor laborum non aliqua. Cupidatat in mollit deserunt laborum irure veniam commodo amet eiusmod ullamco et aliqua. Est amet eiusmod nostrud officia fugiat ea voluptate ipsum laborum nulla ea eiusmod culpa.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliqua ea occaecat aliquip qui dolore. Deserunt amet velit qui voluptate consectetur eu tempor laboris velit do. Commodo consectetur dolor elit ipsum occaecat aute aliquip tempor deserunt ut nisi ea labore. Amet sunt occaecat dolor ut sint est id ex cillum est pariatur duis minim aliquip.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-28",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e036482a74603941434",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Dadabase",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Online: Social media",
    "division": "Division Three",
    "painPoint": "Do nostrud incididunt anim esse tempor duis pariatur culpa ullamco veniam veniam consectetur. Est consectetur dolore minim aliqua aliqua consectetur. Duis in dolore exercitation ex.\r\n",
    "description": "Nulla reprehenderit cupidatat fugiat fugiat incididunt est sit nostrud occaecat laboris officia. Voluptate amet cillum sunt cupidatat. Deserunt eiusmod pariatur pariatur consequat dolore ad dolor ullamco voluptate magna est. In consectetur esse minim sit veniam do esse minim do sint amet. Laborum consectetur laborum fugiat non incididunt aute sunt elit sunt laborum aliqua dolor aute. Dolore nulla fugiat minim non. Exercitation irure esse adipisicing sunt aute culpa proident tempor ex.\r\n",
    "strategy": "Non minim velit anim voluptate excepteur ea laboris veniam cillum eiusmod irure. Labore elit nostrud magna laboris proident ad ut cupidatat veniam occaecat cupidatat in proident. Fugiat voluptate minim ea veniam eu eu excepteur aliquip Lorem tempor. Anim esse consequat reprehenderit nulla.\r\n",
    "keyLearnings": "Lorem adipisicing exercitation est ipsum et ex dolore cupidatat eu enim. Reprehenderit incididunt cupidatat culpa consectetur. Sit exercitation cupidatat eu eu dolore commodo duis commodo. Fugiat ut tempor ut occaecat sit velit cillum. Enim et aute ea minim id.\r\n",
    "nextSteps": "Voluptate reprehenderit consectetur ex do esse tempor magna dolor excepteur cupidatat sunt. Aute anim excepteur nisi deserunt consectetur aliquip in ullamco. Non ex pariatur laborum culpa id nisi ipsum excepteur.\r\n",
    "investment": "Cillum adipisicing cillum Lorem excepteur velit nulla voluptate sit qui. Ex qui labore culpa ullamco ullamco excepteur enim mollit. Magna labore dolor sunt aliquip esse eu non. Esse velit mollit aliqua Lorem magna irure eu ullamco. Id mollit irure velit ad enim sit ut tempor.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Irure quis fugiat fugiat quis adipisicing Lorem ex aute aute. Ut cupidatat ullamco ea officia ut cillum cillum nulla deserunt voluptate. Sunt consequat consectetur et laborum dolor consectetur aliquip aute excepteur. Dolore amet ut labore laborum excepteur tempor sint do.\r\n",
      "engagementDescr": "Deserunt dolore aute proident excepteur non in nulla sint exercitation officia culpa laboris sit. Aliqua sit nulla aliquip ad incididunt tempor irure aute. Ex fugiat Lorem irure aliquip reprehenderit magna cillum do.\r\n",
      "dataDescr": "Irure do et culpa excepteur laborum duis ullamco incididunt laboris nisi non nulla consectetur consectetur. Anim eu ea nulla sint. Aute enim fugiat aliquip non ullamco sit qui ipsum esse. Lorem labore culpa fugiat ut excepteur eu officia mollit sunt cillum est laborum sit.\r\n",
      "routeDescr": "Consectetur officia ipsum in incididunt sunt irure qui veniam cupidatat mollit. Tempor nulla Lorem anim deserunt cillum pariatur non consectetur pariatur ex. Pariatur do consectetur eu nostrud. Quis tempor nulla ut magna nulla sunt amet.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Durham Hull",
        "email": "durhamhull@dadabase.com",
        "fte": 0.6527
      },
      {
        "id": 1,
        "name": "Porter Hess",
        "email": "porterhess@dadabase.com",
        "fte": 0.8423
      },
      {
        "id": 2,
        "name": "Mays Joyce",
        "email": "maysjoyce@dadabase.com",
        "fte": 0.9069
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Cara Mcfadden",
        "email": "caramcfadden@dadabase.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 12,
        "y3": 33
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 9,
        "y2": 31,
        "y3": 98
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 20,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13261950,
      "customerVal": 1313218,
      "mvp": 88645999,
      "optimize": 12870753,
      "grow": 413792975,
      "scale": 1391790827
    },
    "nextStepsActionPlan": "Fugiat mollit do sunt velit Lorem aliqua ut aliqua nostrud amet minim. Tempor ipsum duis minim cillum esse. Cillum proident cillum tempor dolore dolor nulla exercitation exercitation. Eiusmod nulla duis nulla irure velit. Elit est officia id sunt esse nostrud excepteur ex minim amet.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Irure consectetur officia fugiat occaecat nulla esse veniam nostrud dolor. Adipisicing incididunt anim nisi deserunt ut cillum laboris dolore elit adipisicing eu reprehenderit deserunt cupidatat. Aute exercitation exercitation nulla sit irure pariatur. Laborum dolor anim pariatur ea proident nisi culpa cillum. Incididunt esse ex incididunt ut reprehenderit tempor pariatur deserunt proident duis.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-19",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03c5cf6ffaa279d80a",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Geoforma",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "Norway",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Consequat exercitation proident esse cillum anim adipisicing nisi ea reprehenderit excepteur id ut laboris. Velit quis velit tempor elit. Exercitation amet ad quis dolore. Velit tempor cupidatat amet consectetur sit ea nostrud quis aliquip ad dolor irure ipsum deserunt.\r\n",
    "description": "Labore aliqua aliquip excepteur cillum nisi duis labore ex voluptate dolor anim ullamco. Deserunt cupidatat veniam aute duis ea magna. Officia est laborum nulla laboris esse.\r\n",
    "strategy": "Dolore voluptate fugiat non aliqua laboris nostrud consequat amet voluptate commodo. Ullamco cillum id fugiat et mollit ad cupidatat. Occaecat mollit incididunt nulla magna aliquip voluptate ex elit.\r\n",
    "keyLearnings": "Qui laboris fugiat proident laborum nulla ad magna sunt sint. Nostrud exercitation et est Lorem commodo cillum velit minim deserunt ullamco. Eu cillum non occaecat do aliquip nisi aliquip proident ad minim fugiat.\r\n",
    "nextSteps": "Laboris ipsum Lorem laboris commodo aute non tempor pariatur tempor cupidatat. Dolor enim sit esse irure labore proident nulla occaecat quis quis pariatur. Ea labore deserunt labore qui laboris pariatur consequat consectetur aliquip ipsum ea fugiat incididunt sit.\r\n",
    "investment": "Est velit elit consectetur amet quis ullamco reprehenderit magna. Duis enim sit excepteur fugiat dolore nisi magna ullamco minim tempor quis. Ea ea culpa labore fugiat commodo. Dolore id tempor duis id. Aliquip enim culpa laboris ea esse nulla enim mollit officia laboris sunt aute.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Dolor ea enim consectetur veniam esse do cillum sit et ipsum. Eu amet amet sint veniam enim ut est nulla voluptate exercitation fugiat magna. Sunt aliqua esse tempor ea in reprehenderit. Ipsum id ullamco esse Lorem est occaecat est eiusmod culpa elit. Aliqua ad id tempor consequat. In sit culpa laborum ipsum cupidatat.\r\n",
      "engagementDescr": "Id laboris amet Lorem do aute veniam sit mollit officia laboris. Sit magna consectetur tempor ipsum nostrud elit in ut ipsum ad sunt aliqua. Aliqua nulla deserunt proident in occaecat ullamco dolore sit proident occaecat Lorem fugiat. Mollit duis sunt minim adipisicing duis ex consectetur. Cupidatat enim deserunt est magna velit culpa et do exercitation officia cupidatat duis. Pariatur sint mollit anim culpa deserunt incididunt. Minim minim do aliquip id culpa sunt minim deserunt.\r\n",
      "dataDescr": "Tempor sit adipisicing mollit ex laborum elit. Ea quis consequat dolor irure excepteur dolor exercitation nulla sunt aute sit amet mollit. Ipsum excepteur velit commodo pariatur velit ipsum do proident labore nulla eiusmod aliquip aliqua. Nostrud aute esse minim elit qui enim. Nostrud velit ad tempor nisi.\r\n",
      "routeDescr": "Pariatur aute tempor tempor consectetur et sint dolor incididunt ad minim nisi. Ad labore eu nostrud nulla Lorem non mollit exercitation ipsum mollit ea esse anim deserunt. Nulla ipsum qui nostrud enim cillum fugiat aute non do incididunt irure ullamco adipisicing occaecat. Officia ex qui qui culpa in nulla aliquip fugiat nisi nostrud magna ullamco.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Richard Lewis",
        "email": "richardlewis@geoforma.com",
        "fte": 0.9292
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Sherri Kerr",
        "email": "sherrikerr@geoforma.com"
      },
      {
        "id": 1,
        "name": "Hutchinson Potter",
        "email": "hutchinsonpotter@geoforma.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 1,
        "y2": 13,
        "y3": 32
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 33,
        "y3": 86
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 8,
        "y2": 30,
        "y3": 61
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 14757261,
      "customerVal": 924379,
      "mvp": 120662457,
      "optimize": 9925831,
      "grow": 1085816121,
      "scale": 353894980
    },
    "nextStepsActionPlan": "In pariatur duis eu quis velit laboris ad. Non eiusmod dolor exercitation exercitation cupidatat ut nostrud dolore culpa reprehenderit. Exercitation amet in nulla cupidatat in dolore officia incididunt. Ullamco sit do eu eu aute nisi incididunt amet. Commodo veniam commodo sint ea deserunt. Id cupidatat quis eiusmod pariatur nisi.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Duis occaecat nulla eu irure duis aliqua. Consequat exercitation ipsum voluptate nisi ea laborum sunt sunt ea qui mollit occaecat incididunt. Esse irure pariatur fugiat dolor minim duis sunt. Aute commodo pariatur sunt laborum ullamco anim qui et quis veniam. Culpa anim irure dolor laborum anim sunt enim. Magna commodo Lorem commodo labore. Minim elit aliquip nisi proident laboris proident sint.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-29",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0368fb2a294a975a75",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Zappix",
    "highlightKPI": [
      "nsv",
      "gmac",
      "rr"
    ],
    "geography": "United States",
    "channel": "Online: Home delivery",
    "division": "Division Three",
    "painPoint": "Excepteur sunt minim sit sit elit officia. Veniam elit id irure cillum ipsum ipsum laboris cupidatat laboris laborum. Consequat sunt sunt exercitation ea proident sunt commodo adipisicing nulla est ullamco non voluptate culpa. Voluptate nostrud proident ipsum in. Aliquip et esse reprehenderit mollit eiusmod minim dolore. In velit anim culpa tempor magna laborum aute deserunt et minim eu voluptate commodo deserunt. Officia excepteur sit sit dolore magna nulla elit officia duis duis magna ex.\r\n",
    "description": "Occaecat officia amet cillum sint exercitation aliquip amet Lorem. Dolor sunt Lorem aliquip nostrud eiusmod enim esse adipisicing. Dolore magna eiusmod aliquip minim irure minim irure in ad. Lorem sit eiusmod magna amet ea labore. Sint minim laborum ipsum id aliqua anim magna consequat. Deserunt nulla commodo qui elit labore et aliquip esse. Laborum eu quis eu quis exercitation consequat.\r\n",
    "strategy": "Ad dolor eiusmod duis labore consequat laboris officia. Exercitation aliquip minim cillum eiusmod mollit nulla cupidatat. Tempor nisi ea excepteur laboris proident laboris laborum ad. Ex commodo do laborum nostrud sunt qui adipisicing. Esse commodo est est qui id ullamco culpa non labore laborum enim.\r\n",
    "keyLearnings": "Voluptate esse sunt aute nulla qui ad ex eiusmod Lorem dolore commodo. Nulla commodo commodo cupidatat aute aliquip dolore reprehenderit Lorem adipisicing ullamco in qui Lorem. Laboris incididunt dolore nisi exercitation aliqua. Sit aliqua occaecat est nisi Lorem reprehenderit aliqua excepteur est aute non. Cupidatat aliquip culpa magna qui eiusmod. Eu elit aliqua cupidatat aliqua nisi ullamco culpa cupidatat nostrud excepteur sunt excepteur.\r\n",
    "nextSteps": "Dolore aliquip veniam aliquip ex magna exercitation. Ea Lorem consectetur ad amet Lorem tempor eiusmod sunt proident commodo culpa. In pariatur sit esse ad minim excepteur non sunt eu laborum dolor sit eu irure.\r\n",
    "investment": "Aute velit elit officia exercitation magna nostrud nulla occaecat exercitation eiusmod est aliqua. Sint eiusmod id do labore tempor. Tempor excepteur cupidatat ex laborum laborum minim minim. Consectetur ea anim veniam aliquip duis cillum.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Reprehenderit amet aute officia velit cupidatat deserunt esse Lorem ad dolor. Consequat labore exercitation laboris eu elit. Pariatur pariatur voluptate ad nulla. Nostrud ad aute et laborum minim non tempor. Esse dolore mollit nulla veniam officia magna ut ea do sit non consequat occaecat. Lorem laborum magna adipisicing exercitation adipisicing anim nostrud quis incididunt aute eu anim laborum. Elit ullamco nulla ex adipisicing qui officia ad tempor et.\r\n",
      "engagementDescr": "Voluptate sit dolore id sint eu eu ut incididunt ullamco commodo ad sint elit. Consectetur ipsum cillum culpa anim exercitation. Sit dolor reprehenderit duis Lorem officia cillum esse in.\r\n",
      "dataDescr": "Consectetur anim cupidatat officia eu aute sit nisi consectetur magna fugiat. Officia ullamco cupidatat duis Lorem ipsum ea nostrud elit ut officia incididunt. Consequat sit aliquip qui eiusmod consequat incididunt voluptate ut dolore irure ipsum est exercitation enim.\r\n",
      "routeDescr": "Non laboris quis culpa labore ex culpa ea incididunt. Cillum id eiusmod do sint. Fugiat amet culpa ea occaecat commodo consequat excepteur adipisicing. Cupidatat sint est dolore sit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Carolina Gaines",
        "email": "carolinagaines@zappix.com",
        "fte": 0.196
      },
      {
        "id": 1,
        "name": "Rebecca Wyatt",
        "email": "rebeccawyatt@zappix.com",
        "fte": 0.0457
      },
      {
        "id": 2,
        "name": "Angie Crawford",
        "email": "angiecrawford@zappix.com",
        "fte": 0.1596
      },
      {
        "id": 3,
        "name": "Myrtle Bridges",
        "email": "myrtlebridges@zappix.com",
        "fte": 0.7294
      },
      {
        "id": 4,
        "name": "Pickett Whitley",
        "email": "pickettwhitley@zappix.com",
        "fte": 0.673
      },
      {
        "id": 5,
        "name": "Shirley Puckett",
        "email": "shirleypuckett@zappix.com",
        "fte": 0.0357
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Conrad Simpson",
        "email": "conradsimpson@zappix.com"
      },
      {
        "id": 1,
        "name": "Lessie Barry",
        "email": "lessiebarry@zappix.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 5,
        "y2": 11,
        "y3": 17
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 39,
        "y3": 91
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 27,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13302501,
      "customerVal": 468066,
      "mvp": 118657394,
      "optimize": 480643,
      "grow": 151699994,
      "scale": 939859395
    },
    "nextStepsActionPlan": "Duis sint sint Lorem id ad consequat velit anim. Fugiat sint tempor est reprehenderit quis mollit dolor incididunt ea minim dolor mollit. Sunt dolor eu ullamco pariatur quis exercitation irure ad commodo veniam veniam dolore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Pariatur dolor deserunt reprehenderit aute anim mollit consequat aliquip enim voluptate do occaecat. Nulla ea in ea deserunt. Fugiat do aute ipsum tempor officia commodo magna aliquip magna ut voluptate nulla aliquip. Cillum pariatur duis amet pariatur. Id do dolore commodo fugiat sunt eiusmod voluptate cupidatat enim. Dolore incididunt excepteur ad commodo voluptate duis ullamco duis reprehenderit nisi. Non veniam ipsum elit dolor ex sunt duis aute voluptate ad anim.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-03",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0342344f8938781d3c",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Ezent",
    "highlightKPI": [
      "ctr",
      "ltv",
      "tc"
    ],
    "geography": "China",
    "channel": "Offline: Shop in shop",
    "division": "Division Three",
    "painPoint": "Duis veniam minim laborum duis proident ex exercitation cupidatat. Dolore eu excepteur dolore voluptate dolore elit consectetur ad ut. Ex deserunt cupidatat mollit aliquip commodo sit magna aliqua sint aute. Ex sint laborum nulla aliquip. Reprehenderit enim ut adipisicing tempor aliqua laborum.\r\n",
    "description": "Excepteur anim eu anim labore dolor non sint minim voluptate Lorem cupidatat ipsum aliquip aliquip. Excepteur ea elit elit id elit do sunt esse. Nulla anim eiusmod est laboris quis eiusmod quis tempor qui. Culpa et ex nisi commodo adipisicing sint nulla quis incididunt veniam.\r\n",
    "strategy": "Excepteur ullamco sit dolore do sint deserunt esse et commodo ea. Sunt nisi qui sit laboris occaecat irure sunt laborum reprehenderit do minim ad exercitation mollit. Labore veniam dolore voluptate non dolore laboris. Id exercitation ut aliqua quis culpa qui elit sunt. Proident dolor voluptate ex mollit voluptate deserunt pariatur deserunt aliquip sunt duis ullamco dolore.\r\n",
    "keyLearnings": "Exercitation ex sit officia ex non ea cillum aliqua sint quis incididunt elit. Ipsum velit eiusmod reprehenderit ipsum nostrud consequat pariatur. Nostrud sint nulla ut ipsum reprehenderit mollit laboris laboris sunt. Sit laboris do nulla et commodo nisi minim incididunt sint et aliquip.\r\n",
    "nextSteps": "Aute magna sunt ex laboris dolor. Fugiat Lorem ea duis ad aute eiusmod esse ut aliqua fugiat dolor reprehenderit eu aliqua. Lorem ut reprehenderit duis eu occaecat nulla nostrud dolor enim. Tempor exercitation culpa tempor aliquip eu Lorem veniam nisi laborum ipsum sunt amet aliqua. Commodo voluptate commodo consectetur dolore tempor. Sunt exercitation adipisicing incididunt aute.\r\n",
    "investment": "Id laboris veniam minim exercitation incididunt incididunt culpa velit irure. Occaecat minim eu ipsum pariatur culpa eiusmod ad officia elit est. In duis velit aliqua sint fugiat proident cupidatat nisi. Minim fugiat quis occaecat excepteur labore esse sint. Fugiat ipsum sunt velit officia ipsum.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Ex excepteur est dolore sit sit. Commodo nostrud aliqua dolor id adipisicing. Dolore aute et dolore excepteur in occaecat nostrud sit aute non. Sunt reprehenderit minim in commodo ut amet est proident dolor voluptate excepteur qui. Consequat elit culpa nostrud nostrud eu mollit. Cillum non officia velit non pariatur. Dolor irure occaecat excepteur proident nulla laborum dolore laboris excepteur magna et consectetur Lorem.\r\n",
      "engagementDescr": "Id ipsum ipsum tempor fugiat sunt pariatur pariatur magna nisi consequat occaecat ea. Aute Lorem culpa do cupidatat Lorem. Aliqua sint nulla non occaecat ut laboris elit ullamco ipsum pariatur minim. Lorem excepteur proident in cupidatat ex ut deserunt non non sit magna tempor eu amet.\r\n",
      "dataDescr": "Aliquip ad velit fugiat consequat est ad do elit ea exercitation commodo adipisicing consectetur. Quis cupidatat id ipsum aute nulla nisi. Nulla aute in do aute excepteur nostrud quis aliqua elit culpa.\r\n",
      "routeDescr": "Fugiat sunt pariatur deserunt ad incididunt. Ipsum deserunt enim dolore ex exercitation consequat. Fugiat laborum ut officia sit id laborum incididunt duis culpa excepteur excepteur elit. Lorem non dolore eiusmod amet mollit qui irure ullamco esse officia ut sint adipisicing. Quis sit excepteur aliqua ullamco id et commodo cillum occaecat eiusmod minim. Quis ea laborum id elit duis adipisicing pariatur excepteur dolor. Pariatur labore consequat laboris minim laboris fugiat est ullamco occaecat aute tempor duis.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Joseph Soto",
        "email": "josephsoto@ezent.com",
        "fte": 0.5572
      },
      {
        "id": 1,
        "name": "Noble Higgins",
        "email": "noblehiggins@ezent.com",
        "fte": 0.4358
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kitty Fleming",
        "email": "kittyfleming@ezent.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 14,
        "y3": 36
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 8,
        "y2": 20,
        "y3": 86
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 11,
        "y3": 54
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 7256649,
      "customerVal": 1068324,
      "mvp": 128333940,
      "optimize": 8252106,
      "grow": 1436077709,
      "scale": 813781849
    },
    "nextStepsActionPlan": "Commodo deserunt officia veniam consectetur officia incididunt amet quis sit ex. Culpa aliqua aliqua occaecat aute excepteur eiusmod occaecat ipsum. Veniam fugiat incididunt cupidatat velit enim magna. Ut nostrud ex nostrud sint ea dolor magna dolor amet ea nostrud nisi adipisicing sint. Et anim magna ipsum adipisicing dolore adipisicing ea.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Cupidatat sint culpa id quis ex in exercitation exercitation in ea. Eiusmod elit magna ullamco culpa eu ullamco. Nostrud occaecat irure tempor mollit exercitation quis officia. Quis sunt ut duis cupidatat culpa deserunt et aliqua tempor velit nisi consectetur. Deserunt enim incididunt commodo incididunt aliqua ex sit. Ipsum excepteur eu ea fugiat exercitation veniam reprehenderit. Magna nisi occaecat excepteur Lorem.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-22",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e033ed6439aaf9fa8ff",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Arctiq",
    "highlightKPI": [
      "nsv",
      "bcr",
      "tc"
    ],
    "geography": "Brazil",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Veniam tempor nulla pariatur voluptate cillum dolor aliquip aliquip enim aute veniam ad magna. Dolor excepteur ex dolore id ea commodo incididunt ad proident eiusmod ex mollit. Non reprehenderit sunt ut reprehenderit duis nulla. Excepteur ad occaecat commodo esse reprehenderit irure sit. Minim ullamco et labore pariatur officia consequat irure dolore ad officia sint duis id consequat.\r\n",
    "description": "Eu mollit est ut incididunt veniam qui nulla cupidatat aliquip mollit esse sunt sint. Commodo in occaecat excepteur nostrud. Ut ipsum consectetur aute commodo aute deserunt culpa laborum minim culpa velit id cupidatat commodo. Mollit aute non aliquip officia.\r\n",
    "strategy": "Esse magna nostrud pariatur dolore ut ad id occaecat id tempor. Pariatur quis anim reprehenderit reprehenderit enim commodo id et ullamco. Sint voluptate sint id dolore pariatur nisi exercitation amet aliquip velit non eiusmod. Dolor consequat dolor occaecat tempor ut ea sit sint ex nisi ad. Do sint tempor sunt consectetur sint anim elit pariatur incididunt eu laboris proident incididunt. Nisi ad ad veniam eiusmod consequat minim mollit.\r\n",
    "keyLearnings": "Id occaecat velit nulla qui adipisicing reprehenderit ipsum. Aliqua aliquip quis cillum culpa id cupidatat nisi velit fugiat duis qui et adipisicing proident. Labore quis et adipisicing elit cillum sint eu est nostrud laborum ullamco eiusmod. Eiusmod adipisicing do pariatur dolor in consequat laboris exercitation aliquip culpa ut exercitation. Mollit cupidatat dolore tempor voluptate aliquip. Eiusmod incididunt nisi ullamco non velit ullamco nulla incididunt occaecat eiusmod dolor occaecat occaecat exercitation. Laboris et aliquip amet commodo eu velit qui.\r\n",
    "nextSteps": "Dolore nulla cillum dolor occaecat aliquip sit eiusmod quis dolore qui velit. Ex esse occaecat irure veniam mollit anim dolor culpa sunt. Veniam laborum ipsum nisi laborum fugiat aute consequat aliqua incididunt nostrud. Dolore velit laborum tempor consequat. Velit mollit culpa adipisicing consectetur laborum voluptate.\r\n",
    "investment": "Aliquip nostrud dolor aliquip quis ut duis quis adipisicing ad irure minim nisi adipisicing commodo. Do sint aliqua occaecat consectetur sit dolor elit nostrud dolor. Aliquip do in consectetur voluptate nostrud labore. Minim nostrud amet nostrud aliquip ullamco excepteur tempor ut enim minim.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Id do nulla sint commodo occaecat laborum irure proident irure et consequat. Ea quis eu in aliqua tempor irure et nulla. Laboris tempor aliqua nostrud ipsum sunt fugiat qui minim ex culpa. Do sit Lorem esse adipisicing aute. Non adipisicing eiusmod eiusmod veniam nostrud ea nostrud proident fugiat consequat anim reprehenderit.\r\n",
      "engagementDescr": "Ipsum aliquip cillum consequat cupidatat elit ipsum adipisicing laboris culpa est. Sit cupidatat nisi excepteur eu cillum quis. Labore occaecat aliqua est culpa qui dolor non consectetur qui in. Dolore qui sit sit adipisicing do enim. Pariatur labore eiusmod amet aliquip in esse minim do non culpa ipsum Lorem.\r\n",
      "dataDescr": "Nulla incididunt adipisicing dolore commodo minim. Eiusmod amet dolore ex nisi commodo proident aliquip mollit duis. Consectetur id incididunt commodo ea aliquip tempor nisi mollit nostrud dolor. Reprehenderit adipisicing cupidatat cupidatat veniam nulla officia deserunt consequat. Lorem commodo mollit qui ullamco do cupidatat exercitation. Proident nulla eu aute occaecat in est in qui aliqua labore ex aliquip reprehenderit. Dolor deserunt minim irure proident duis deserunt minim et sit voluptate dolor amet deserunt ut.\r\n",
      "routeDescr": "Velit ex nisi voluptate culpa in anim enim occaecat qui pariatur excepteur id. Labore anim magna sit in enim irure exercitation ut fugiat elit. Id commodo tempor voluptate et sit do ex aliquip dolor. Officia veniam anim irure ex. Deserunt excepteur laborum sunt laboris amet. Duis duis in Lorem non cupidatat magna reprehenderit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Cunningham Washington",
        "email": "cunninghamwashington@arctiq.com",
        "fte": 0.3129
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "David Patel",
        "email": "davidpatel@arctiq.com"
      },
      {
        "id": 1,
        "name": "Mercado Acevedo",
        "email": "mercadoacevedo@arctiq.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 16,
        "y3": 23
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 15,
        "y2": 29,
        "y3": 85
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 18,
        "y3": 34
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 13807057,
      "customerVal": 1317146,
      "mvp": 47408676,
      "optimize": 2526888,
      "grow": 342685328,
      "scale": 1327840797
    },
    "nextStepsActionPlan": "Proident ex deserunt aliqua eiusmod cillum magna veniam non occaecat. Sint dolore excepteur officia duis tempor aliqua ad cupidatat ex aliqua ex. Dolore deserunt irure exercitation officia qui voluptate aute non eu laboris esse.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Sunt tempor irure est fugiat sunt enim anim pariatur ex. Eu esse fugiat ad id et anim ea duis non voluptate sint proident. Excepteur irure ad esse nisi laborum et anim exercitation deserunt ut ipsum ipsum.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-01-11",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0348a6e4724d8911e8",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Freakin",
    "highlightKPI": [
      "cac",
      "ltv",
      "rp"
    ],
    "geography": "Brazil",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Laboris sit dolor excepteur consequat. Eu culpa elit minim id culpa commodo. Esse do exercitation culpa cupidatat. Nulla proident dolore irure et nostrud culpa magna qui sunt cupidatat in ut est ut.\r\n",
    "description": "Aute non magna aliquip consectetur. Cillum labore aliqua aliqua laborum exercitation qui duis nostrud consequat et officia. Ea non tempor culpa velit aliqua dolor in voluptate nostrud. Consectetur elit dolore incididunt nostrud voluptate dolore ullamco duis ad.\r\n",
    "strategy": "Sint est in excepteur nisi adipisicing anim fugiat. Non laboris et ea commodo voluptate voluptate. Non ullamco nisi deserunt commodo pariatur enim. Aute cillum duis labore nulla officia id anim quis culpa.\r\n",
    "keyLearnings": "Et aute velit irure incididunt. Sint qui anim ipsum elit duis do. Incididunt dolor amet excepteur enim. Adipisicing sit commodo nostrud velit in enim est qui mollit velit cillum et adipisicing irure. Exercitation voluptate aliquip commodo enim laborum elit fugiat dolor tempor quis esse sunt quis. Veniam deserunt quis mollit veniam incididunt Lorem laboris non consectetur excepteur culpa.\r\n",
    "nextSteps": "Pariatur reprehenderit sunt consequat officia. Ullamco et proident irure pariatur tempor ea labore sunt incididunt officia culpa exercitation aliqua. Enim velit sunt aliquip reprehenderit Lorem ad. Ad culpa aliquip ad do commodo. Labore enim tempor elit aliqua duis nulla proident est excepteur. Mollit irure esse nulla nulla tempor duis adipisicing adipisicing ut excepteur veniam ad nulla. Non irure id eiusmod consectetur nostrud officia excepteur reprehenderit elit magna dolore.\r\n",
    "investment": "Cupidatat officia est amet commodo. Amet ex elit nisi exercitation aliqua. Eu dolore cillum voluptate et cupidatat nostrud ea et enim cillum dolore. Magna est Lorem non occaecat ex. Laboris sunt dolor proident proident mollit adipisicing nostrud.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Excepteur labore adipisicing non nostrud anim mollit. Exercitation non duis proident aliqua ut aliqua tempor incididunt esse dolor. Reprehenderit voluptate culpa quis reprehenderit ad quis commodo tempor ipsum. Do esse sunt ad deserunt excepteur fugiat amet ex exercitation est ut consectetur velit. Ea elit non sit mollit eu eu veniam quis. Id consectetur sunt commodo cupidatat sit irure ipsum in id in consequat dolore enim.\r\n",
      "engagementDescr": "Anim sunt cillum dolore magna laboris nulla Lorem do ullamco id eiusmod voluptate deserunt. Consequat sit velit deserunt pariatur proident sit cupidatat consectetur cillum. Ad eiusmod ullamco nostrud veniam esse laborum enim. Exercitation minim ex qui non laborum sunt cupidatat nulla excepteur laboris ad fugiat sit amet.\r\n",
      "dataDescr": "Incididunt veniam occaecat laborum proident irure id non sunt dolore deserunt occaecat aliqua. Est voluptate deserunt fugiat excepteur sunt eu. Mollit dolor exercitation laborum duis. Cillum exercitation labore ea ad sunt ad. Proident proident eu consectetur ut dolor culpa. Do ut voluptate eu incididunt sunt velit incididunt nostrud.\r\n",
      "routeDescr": "Nostrud ex mollit eiusmod esse magna esse nisi occaecat tempor sit. Do nisi nostrud voluptate id laboris voluptate. Fugiat eiusmod cillum minim amet duis. Tempor ipsum ut nulla amet Lorem nisi qui. Deserunt proident culpa duis cupidatat irure nulla cupidatat tempor. Sunt nulla deserunt occaecat ex dolore exercitation Lorem aute reprehenderit fugiat amet ad amet sit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Concepcion Garcia",
        "email": "concepciongarcia@freakin.com",
        "fte": 0.7616
      },
      {
        "id": 1,
        "name": "Angel Olson",
        "email": "angelolson@freakin.com",
        "fte": 0.3416
      },
      {
        "id": 2,
        "name": "Keith Burton",
        "email": "keithburton@freakin.com",
        "fte": 0.4867
      },
      {
        "id": 3,
        "name": "Johanna Colon",
        "email": "johannacolon@freakin.com",
        "fte": 0.9321
      },
      {
        "id": 4,
        "name": "Josie Silva",
        "email": "josiesilva@freakin.com",
        "fte": 0.1067
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Allie Riley",
        "email": "allieriley@freakin.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 18,
        "y3": 31
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 3,
        "y2": 29,
        "y3": 88
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 25,
        "y3": 91
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 12184801,
      "customerVal": 394701,
      "mvp": 24044370,
      "optimize": 8170268,
      "grow": 566496270,
      "scale": 308220304
    },
    "nextStepsActionPlan": "Incididunt velit exercitation velit nostrud irure do ea adipisicing quis esse. Labore est cillum do aliqua veniam duis culpa qui anim amet est minim quis nulla. Labore in proident ut laborum eu velit anim est. Incididunt magna proident anim deserunt pariatur dolore.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Officia consequat eu sint irure labore. Consequat excepteur voluptate cillum quis laborum nulla tempor deserunt dolor esse ut consectetur. Esse proident aliqua adipisicing ut exercitation proident excepteur sint minim culpa. Veniam tempor laborum qui eiusmod excepteur eu in occaecat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-11",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03867939cbc2390ede",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Entogrok",
    "highlightKPI": [
      "ctr",
      "bcr",
      "rr"
    ],
    "geography": "Italy",
    "channel": "Offline: Flagship store",
    "division": "Division Two",
    "painPoint": "Occaecat est do incididunt quis incididunt ut irure. Ad laborum nostrud est mollit mollit quis eu. Elit irure tempor aliqua anim irure consequat commodo consectetur consectetur id nisi. Laborum adipisicing velit mollit sit aliquip amet sunt incididunt.\r\n",
    "description": "Ea ipsum amet quis laborum sint qui do. Adipisicing do et nostrud Lorem proident nulla aliquip culpa irure nisi culpa. Lorem Lorem fugiat laboris amet sunt sunt proident veniam aliqua. Velit tempor cillum consectetur dolor aute labore Lorem pariatur anim. Exercitation eiusmod consectetur ipsum excepteur. Sit elit non veniam tempor enim deserunt dolore occaecat elit voluptate elit esse.\r\n",
    "strategy": "Quis labore cillum non qui eiusmod pariatur amet. Amet magna elit veniam aliquip. Ut do elit qui exercitation esse labore aliqua tempor qui sit commodo ex.\r\n",
    "keyLearnings": "Commodo velit incididunt dolor esse consectetur cillum elit reprehenderit sunt ad eiusmod tempor esse. Aliqua adipisicing sint et in veniam nulla tempor culpa veniam ullamco. Ut dolor ipsum reprehenderit ad laboris consectetur dolor est esse id. Ex laborum eu incididunt exercitation in cupidatat anim aliqua irure cillum. Deserunt voluptate anim mollit mollit voluptate in occaecat consectetur. Adipisicing aliqua qui adipisicing laborum id ex.\r\n",
    "nextSteps": "Tempor quis laborum minim commodo id labore voluptate sint eu do consectetur esse adipisicing. Dolore amet nostrud non amet. Pariatur do eu occaecat fugiat exercitation anim minim anim proident elit eu eiusmod. Sunt elit officia laborum occaecat occaecat. Mollit enim incididunt dolore pariatur voluptate fugiat ut id aliquip id deserunt.\r\n",
    "investment": "Culpa minim voluptate exercitation commodo reprehenderit duis sit cupidatat duis. Id nisi mollit esse fugiat adipisicing laboris incididunt mollit. Exercitation ea mollit in minim nulla fugiat elit enim. Consequat duis non non deserunt. Consectetur adipisicing in sunt fugiat nostrud nostrud occaecat et do amet ad.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Incididunt cupidatat nostrud officia enim consequat labore ad labore do quis. Cillum laboris ad tempor est cillum irure aute irure excepteur occaecat ea occaecat. Proident reprehenderit amet exercitation ea excepteur enim enim duis anim enim incididunt. Occaecat nostrud sint excepteur est veniam elit. Occaecat minim amet anim irure quis amet aliquip voluptate. Qui reprehenderit nisi aliquip tempor non nulla ipsum laborum id ullamco do. Magna ut consequat nulla enim ex ea aliquip cupidatat irure id.\r\n",
      "engagementDescr": "Ullamco ullamco ullamco reprehenderit culpa id proident commodo velit elit aliquip ex adipisicing dolore. Eu sit ea ea dolor commodo veniam culpa nostrud dolor consequat. Sunt do reprehenderit aliquip exercitation consectetur ipsum. Reprehenderit cillum ex minim veniam. Ipsum sint magna occaecat reprehenderit duis consectetur veniam sint. Veniam Lorem proident dolore elit duis consectetur id duis duis eu est Lorem.\r\n",
      "dataDescr": "Reprehenderit mollit non nostrud aliquip. Consectetur est dolore quis sunt nostrud mollit exercitation. Fugiat id voluptate sunt incididunt aliquip et laborum eiusmod ullamco laborum exercitation. Adipisicing quis incididunt sunt magna tempor velit id.\r\n",
      "routeDescr": "Adipisicing ea ullamco Lorem ipsum ea. Nulla dolore sit cupidatat dolore labore occaecat consectetur pariatur irure aliqua. Pariatur dolor amet nulla cillum ipsum eiusmod aliquip. Velit voluptate exercitation velit eu. Consectetur incididunt nostrud occaecat enim ut proident consequat incididunt ea adipisicing minim Lorem labore esse.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Janis Rios",
        "email": "janisrios@entogrok.com",
        "fte": 0.1991
      },
      {
        "id": 1,
        "name": "Flossie Johnston",
        "email": "flossiejohnston@entogrok.com",
        "fte": 0.0159
      },
      {
        "id": 2,
        "name": "Isabel Booth",
        "email": "isabelbooth@entogrok.com",
        "fte": 0.7835
      },
      {
        "id": 3,
        "name": "Klein Guthrie",
        "email": "kleinguthrie@entogrok.com",
        "fte": 0.849
      },
      {
        "id": 4,
        "name": "Dolores Moreno",
        "email": "doloresmoreno@entogrok.com",
        "fte": 0.4501
      },
      {
        "id": 5,
        "name": "Harrison Castaneda",
        "email": "harrisoncastaneda@entogrok.com",
        "fte": 0.375
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Jamie Peters",
        "email": "jamiepeters@entogrok.com"
      },
      {
        "id": 1,
        "name": "Dana Cummings",
        "email": "danacummings@entogrok.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 6,
        "y2": 18,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 18,
        "y2": 33,
        "y3": 82
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 13,
        "y3": 69
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 918679,
      "customerVal": 401588,
      "mvp": 80110087,
      "optimize": 1568494,
      "grow": 445860541,
      "scale": 119896577
    },
    "nextStepsActionPlan": "Quis ex nostrud nulla irure elit magna minim sit tempor dolor deserunt. Anim fugiat dolor veniam aliquip amet consequat non enim quis dolor velit excepteur duis nulla. Ut exercitation aute officia in do ipsum ut. Officia velit commodo reprehenderit ullamco qui do.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Pariatur voluptate anim fugiat non elit dolore cupidatat quis sunt culpa laboris nostrud. Do ipsum nisi proident mollit. Nostrud cupidatat reprehenderit eu voluptate dolor. Do minim officia Lorem esse irure anim magna ex tempor occaecat magna. Est aute commodo dolor qui. Labore ipsum et nostrud sint laboris reprehenderit duis deserunt aliquip. Amet anim id ullamco ad est sint esse ea ipsum non aliqua.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-07",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03cdbf80a63e800451",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Valpreal",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "United States",
    "channel": "Online: Marketpace",
    "division": "Division Three",
    "painPoint": "Anim veniam mollit consectetur fugiat quis est deserunt excepteur aute qui. Non sint amet deserunt non culpa magna. Nulla anim sit sit elit eiusmod labore laboris nisi duis. Incididunt commodo mollit nulla laborum qui culpa ullamco sit tempor et sit cillum.\r\n",
    "description": "Ipsum laboris sint duis exercitation consequat consectetur voluptate amet anim aliquip enim amet aliqua. Fugiat velit irure do consequat ullamco aute voluptate sit dolore ea in incididunt. Aute aliquip cillum ad sit ullamco occaecat duis cupidatat qui eu labore ut exercitation non. Labore culpa minim id dolore sint aliqua.\r\n",
    "strategy": "Non velit aliqua ex minim occaecat tempor sit do cillum deserunt. Occaecat aliqua dolor nisi sint eiusmod nostrud cillum anim. Dolor tempor incididunt voluptate aute sunt nisi irure sint incididunt dolore in enim ad. Cillum proident tempor minim qui laborum ut irure ipsum magna veniam.\r\n",
    "keyLearnings": "Excepteur enim Lorem velit consectetur quis ullamco et laboris sit cupidatat proident occaecat commodo. Laborum consectetur deserunt cupidatat ea adipisicing commodo proident. Occaecat nulla laboris commodo commodo amet commodo nulla in est dolor nulla commodo minim.\r\n",
    "nextSteps": "Minim eu magna minim ex reprehenderit sit cillum tempor ad officia. Reprehenderit aliquip cillum laborum non. Ea consectetur et voluptate occaecat et et est eu nostrud veniam incididunt duis deserunt.\r\n",
    "investment": "Occaecat enim consectetur anim Lorem occaecat adipisicing cillum sit. Est dolore ex nulla eu consectetur enim irure enim adipisicing id do minim. Est consequat ipsum aliquip pariatur dolor est nulla minim et culpa Lorem. Deserunt consequat enim nostrud officia incididunt proident veniam veniam commodo ut. Nostrud exercitation ut fugiat eiusmod ea consequat incididunt do.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Enim ea quis irure minim adipisicing ad ea. Voluptate exercitation sunt sunt ad ullamco veniam tempor ullamco cillum eiusmod esse non adipisicing. Excepteur voluptate quis occaecat mollit cillum enim consectetur aliquip Lorem incididunt irure aute tempor. Eiusmod velit nulla velit officia non amet aute amet culpa voluptate officia officia ut.\r\n",
      "engagementDescr": "Nostrud veniam fugiat ea enim laboris nulla sunt dolor dolore eiusmod sunt enim. Occaecat nulla est do cupidatat adipisicing ad et duis enim excepteur. Aliquip est in elit Lorem enim consequat exercitation velit consequat magna ad. Lorem irure adipisicing eu proident anim. Sunt aliquip enim eu duis fugiat ullamco. Velit cupidatat elit Lorem non ad eu culpa Lorem consectetur reprehenderit. Voluptate consectetur veniam eu deserunt.\r\n",
      "dataDescr": "Ea ad veniam incididunt occaecat ex id est. Est aliquip dolore ad non excepteur deserunt anim pariatur excepteur cupidatat ad eiusmod Lorem laboris. Dolor adipisicing officia pariatur est aliqua Lorem. Ipsum dolor non eiusmod tempor ipsum sint proident et ipsum ea do nulla. Anim occaecat irure pariatur aliqua qui dolor amet reprehenderit ipsum excepteur aliqua minim velit excepteur. Laboris veniam do velit qui ut elit duis voluptate. Ex laboris et eu ad nulla minim.\r\n",
      "routeDescr": "Eiusmod eu laborum non nisi incididunt. Irure Lorem velit ullamco ut veniam. Nisi laborum velit pariatur laboris consequat amet. Velit elit mollit dolore Lorem dolor est nulla do deserunt ipsum irure irure dolor sint. Id sit ea elit irure non aute dolore mollit cillum qui ex. Voluptate eiusmod nisi enim magna nisi mollit consectetur ut eu esse est.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Lila Herman",
        "email": "lilaherman@valpreal.com",
        "fte": 0.6257
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Craig Bowman",
        "email": "craigbowman@valpreal.com"
      },
      {
        "id": 1,
        "name": "Janine Roman",
        "email": "janineroman@valpreal.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 10,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 21,
        "y3": 54
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 4,
        "y2": 12,
        "y3": 35
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8376465,
      "customerVal": 248648,
      "mvp": 24690888,
      "optimize": 11737195,
      "grow": 1358942275,
      "scale": 326993482
    },
    "nextStepsActionPlan": "Veniam laborum ipsum eiusmod excepteur quis id consectetur nulla consequat dolor. Adipisicing ea deserunt consectetur veniam dolor ex. Cupidatat amet nisi laborum ex. Duis consectetur fugiat dolore labore amet nostrud exercitation sit qui incididunt et. Minim ex ullamco proident ipsum laboris quis proident. Consequat nostrud reprehenderit esse nulla nostrud ad sit ad ad enim consectetur. Sint officia non exercitation exercitation eu aliquip in nisi nostrud ipsum officia exercitation anim tempor.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Ex enim commodo pariatur culpa non duis aliquip aute eu duis duis laboris esse et. Ullamco amet id nostrud ipsum non commodo. Id ullamco consectetur aute occaecat. Sint ea pariatur reprehenderit labore minim consequat veniam mollit. Quis Lorem aute cillum fugiat tempor et elit.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03d0d38d793f33b4b2",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Poochies",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "Norway",
    "channel": "Online: Digital distributor",
    "division": "Division Two",
    "painPoint": "Magna mollit est amet minim sunt voluptate sint aliqua quis irure. Ipsum elit esse laborum ea nostrud ad magna laborum amet Lorem. Do cupidatat elit eu qui. Et fugiat sit consequat in sit dolore ad. Aute consequat qui velit proident voluptate ullamco aliqua do laborum laboris proident ea reprehenderit elit.\r\n",
    "description": "Velit pariatur eu dolore magna consequat minim nostrud. Dolore pariatur enim sunt laboris deserunt reprehenderit quis. Eiusmod dolore duis labore laboris laboris duis. Ex est incididunt est tempor ex tempor ut id qui incididunt eu dolor quis. Nulla ea adipisicing consectetur cillum esse.\r\n",
    "strategy": "Pariatur laboris voluptate labore dolore aliqua. Tempor est dolor veniam et cillum nostrud nulla nostrud voluptate reprehenderit. Et commodo consequat irure aliqua ad minim adipisicing consequat minim nulla voluptate excepteur fugiat laboris. Proident aliqua deserunt et sint laboris laboris Lorem eiusmod. Proident adipisicing irure aute Lorem eu eu.\r\n",
    "keyLearnings": "Esse fugiat excepteur amet anim ipsum voluptate. Ea exercitation et elit eu et est adipisicing. Ex tempor veniam eiusmod irure mollit commodo laborum officia excepteur in velit. Qui pariatur voluptate id ullamco consectetur occaecat amet fugiat irure nulla laborum qui.\r\n",
    "nextSteps": "Nisi adipisicing fugiat enim ullamco magna consectetur exercitation. Dolor ullamco in consequat esse duis cupidatat. Duis deserunt exercitation tempor id fugiat sunt labore ullamco ullamco nisi officia reprehenderit excepteur. Quis ea Lorem dolore pariatur enim. Est ea labore eiusmod aliqua ea dolore duis quis aliquip. Esse ullamco culpa sunt occaecat voluptate aliqua duis aute.\r\n",
    "investment": "Voluptate duis aute ad cupidatat ex voluptate id enim ullamco commodo ad reprehenderit. Nostrud Lorem aliqua minim elit duis. Irure mollit cupidatat nostrud consectetur Lorem Lorem et duis dolor tempor consequat.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Lorem culpa aliquip fugiat ipsum qui dolor. Excepteur ipsum laborum laboris veniam nisi dolor aliquip in aute Lorem adipisicing. Excepteur consequat mollit anim eu amet cupidatat velit non adipisicing.\r\n",
      "engagementDescr": "Eiusmod do reprehenderit minim duis dolore anim aliquip cupidatat. Nulla labore minim sint quis. Tempor id sunt sunt exercitation elit.\r\n",
      "dataDescr": "Qui consequat enim nisi adipisicing cillum qui quis aute commodo esse nulla elit incididunt Lorem. Duis ullamco laborum Lorem elit eiusmod quis duis in. Ea exercitation ad voluptate culpa ex.\r\n",
      "routeDescr": "Proident tempor commodo nostrud consectetur occaecat nostrud veniam minim voluptate eu. Consectetur dolore non pariatur ullamco officia velit. Aliqua dolor occaecat culpa reprehenderit. Aliquip qui velit aliquip cillum velit cupidatat exercitation id. Eiusmod Lorem aliquip dolore excepteur nostrud deserunt mollit velit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Rosella Watkins",
        "email": "rosellawatkins@poochies.com",
        "fte": 0.6124
      },
      {
        "id": 1,
        "name": "Nadine Morrow",
        "email": "nadinemorrow@poochies.com",
        "fte": 0.1967
      },
      {
        "id": 2,
        "name": "Whitaker Cline",
        "email": "whitakercline@poochies.com",
        "fte": 0.0484
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Katrina Ross",
        "email": "katrinaross@poochies.com"
      },
      {
        "id": 1,
        "name": "Rae Kline",
        "email": "raekline@poochies.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 7,
        "y2": 13,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 17,
        "y2": 50,
        "y3": 97
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 12,
        "y2": 23,
        "y3": 86
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4575591,
      "customerVal": 1097621,
      "mvp": 125819674,
      "optimize": 14736982,
      "grow": 1180196981,
      "scale": 217552023
    },
    "nextStepsActionPlan": "Occaecat proident excepteur proident occaecat. Nisi fugiat ullamco aliqua laborum quis ipsum deserunt anim magna aliqua. Eiusmod amet consequat magna in amet. Eu nostrud commodo reprehenderit nostrud ut aliquip aute qui non aliqua aliqua aute ullamco.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Culpa ipsum enim id mollit aute irure veniam consectetur dolor aliqua sunt aliqua adipisicing. Occaecat commodo eu ipsum anim consequat nulla culpa cillum aliquip sint. Aliqua consequat quis minim eiusmod et nostrud. Eu nisi eu ex laboris Lorem eu commodo fugiat eu dolor.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-25",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03332d3183699d2655",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Assistix",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Online: Home delivery",
    "division": "Division One",
    "painPoint": "Mollit pariatur enim fugiat officia ut exercitation exercitation dolore occaecat minim. Culpa adipisicing eiusmod laboris eu fugiat duis non eu voluptate irure fugiat. Ad dolore sunt amet esse consequat laboris aliquip commodo cupidatat officia elit.\r\n",
    "description": "Exercitation fugiat pariatur dolor do amet. Magna aliqua occaecat commodo qui laborum labore. Cupidatat et exercitation Lorem veniam. Eiusmod labore velit incididunt eu veniam aute magna exercitation fugiat anim. Do cillum incididunt do minim reprehenderit duis occaecat aliquip sit duis aliquip ea minim. Aute eu adipisicing ea culpa commodo minim eiusmod proident occaecat proident cupidatat sunt amet. Irure amet mollit deserunt consectetur mollit cillum mollit ad irure ullamco enim cillum ut.\r\n",
    "strategy": "Non amet eiusmod culpa sint. Anim laborum est ullamco fugiat pariatur ipsum ipsum proident aute quis mollit. Anim id Lorem ex mollit aute elit sit est eiusmod exercitation velit ut laboris. Duis laboris laborum nisi qui officia excepteur irure nulla.\r\n",
    "keyLearnings": "Cupidatat consequat esse do reprehenderit irure duis. Mollit amet tempor anim in consectetur nisi veniam do. Enim nulla cupidatat nulla ipsum fugiat. Excepteur et proident occaecat elit velit Lorem.\r\n",
    "nextSteps": "Sunt veniam tempor elit cillum ea aute amet adipisicing. Sunt et occaecat non consectetur laboris et pariatur nisi anim. Cupidatat magna adipisicing proident laborum amet fugiat elit ea aliquip. Amet exercitation irure duis ut voluptate. Nisi ea dolore velit est.\r\n",
    "investment": "Occaecat aliqua cillum nostrud magna aliqua anim ex. Aliqua voluptate adipisicing occaecat ea aute ea. Sit fugiat ut amet cupidatat est excepteur laboris esse est. Fugiat qui aliqua anim aute ipsum nisi veniam qui duis aliquip laborum amet cupidatat elit.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Fugiat amet deserunt esse do amet culpa aliquip eiusmod incididunt. Occaecat nostrud velit minim ipsum esse aute. Voluptate reprehenderit ullamco quis esse non velit consectetur velit laborum mollit. Aliqua quis voluptate sit culpa magna. Exercitation aute ad nulla pariatur est aute magna. Veniam ipsum deserunt aute amet sint cillum voluptate nostrud. Eu incididunt nostrud minim laborum proident.\r\n",
      "engagementDescr": "Anim adipisicing sit Lorem Lorem dolore dolore aliqua do pariatur. Aliquip nostrud exercitation pariatur sint qui anim non. Nulla cillum veniam ex dolore minim anim occaecat. Velit cupidatat amet laboris officia Lorem aliqua non aliquip ut aliquip voluptate proident. Id aliquip labore dolor ipsum cillum mollit.\r\n",
      "dataDescr": "Eu occaecat tempor cillum nulla quis ea adipisicing. Reprehenderit id quis ipsum minim tempor Lorem mollit. Tempor in ipsum ea adipisicing eu minim enim laborum aliqua reprehenderit officia. Incididunt dolore eu ea nisi consectetur sunt ea exercitation esse in. Commodo ipsum magna laboris sint incididunt dolore ex tempor anim aliquip elit. Sunt sunt Lorem laboris culpa voluptate.\r\n",
      "routeDescr": "Pariatur ea sit exercitation aliquip non sit reprehenderit tempor consequat ut ullamco anim tempor. Ad deserunt ad ullamco id culpa nisi aliqua nisi dolore labore. Labore laborum deserunt irure eu magna Lorem cupidatat nulla dolor sunt aliqua non occaecat do. Incididunt reprehenderit aliqua incididunt ullamco tempor voluptate dolore anim. Ea nulla velit commodo incididunt deserunt culpa culpa minim esse. Est do excepteur ad nulla pariatur tempor tempor aute ullamco quis voluptate magna sunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Tonya Huffman",
        "email": "tonyahuffman@assistix.com",
        "fte": 0.4261
      },
      {
        "id": 1,
        "name": "Rosemarie Rivers",
        "email": "rosemarierivers@assistix.com",
        "fte": 0.6108
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Gallegos Cannon",
        "email": "gallegoscannon@assistix.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 0,
        "y2": 20,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 42,
        "y3": 85
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 26,
        "y3": 70
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 2569378,
      "customerVal": 1253328,
      "mvp": 88834216,
      "optimize": 12975309,
      "grow": 975926922,
      "scale": 83609007
    },
    "nextStepsActionPlan": "Minim commodo eu et reprehenderit ex pariatur in qui id adipisicing. Id ea excepteur irure nulla ipsum cillum enim sunt. Sint duis eiusmod reprehenderit ipsum enim consequat consequat mollit voluptate nulla cillum sunt est.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "In ipsum ea aliquip anim occaecat ipsum et id exercitation aliquip occaecat nostrud. Et incididunt consequat veniam nisi amet mollit eu irure eu nostrud. Sint nulla veniam occaecat dolor sit proident esse ea magna dolor. Quis in commodo esse tempor commodo magna incididunt minim. Anim labore reprehenderit sint magna amet enim laboris.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03b0e6e995a9b71500",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Dragbot",
    "highlightKPI": [
      "cac",
      "ltv",
      "rp"
    ],
    "geography": "Germany",
    "channel": "Online: Shop in shop",
    "division": "Division One",
    "painPoint": "Sint Lorem pariatur voluptate est et ut veniam fugiat enim ut ex voluptate. Aliqua qui excepteur mollit et reprehenderit adipisicing reprehenderit cillum amet nisi occaecat adipisicing eu ad. Voluptate ipsum eu ullamco quis culpa est cupidatat velit sunt in.\r\n",
    "description": "Minim Lorem minim culpa in adipisicing tempor excepteur sunt ullamco aliqua excepteur aute. Consequat veniam ut sint ad laborum exercitation proident incididunt nisi eiusmod. Commodo non aute reprehenderit laboris id nostrud ipsum duis consequat laboris nisi exercitation velit. Aliqua minim incididunt proident minim sunt consequat. Amet ex ut do voluptate cupidatat occaecat. Deserunt velit id aliqua aliquip cillum aute. Minim et cillum dolore consequat cupidatat sit nostrud veniam ex.\r\n",
    "strategy": "Amet deserunt labore laborum dolor cillum non proident cillum. Aliquip nisi mollit enim duis sunt do ea. Proident proident reprehenderit aliqua nulla ipsum et cupidatat.\r\n",
    "keyLearnings": "Exercitation id dolor ipsum adipisicing magna deserunt irure dolor culpa excepteur officia reprehenderit. Consequat mollit quis dolor culpa aute ut reprehenderit fugiat do. Incididunt velit do do et sunt dolor ex. Consectetur mollit in nostrud do proident. Aliqua laboris sunt nisi elit dolore in sunt ea sunt tempor excepteur. Minim Lorem anim qui cillum fugiat aliqua exercitation do officia culpa Lorem veniam.\r\n",
    "nextSteps": "Eu duis non qui exercitation veniam consequat minim duis eiusmod labore Lorem. Minim magna duis nostrud qui magna. Amet consectetur enim amet do eu eiusmod et duis sit mollit. Cupidatat incididunt Lorem veniam laboris in elit aliquip ad eiusmod laboris anim do veniam anim. Eiusmod fugiat ullamco consectetur dolore veniam magna enim exercitation velit aliquip pariatur laboris aliqua ullamco. Cupidatat excepteur magna deserunt magna pariatur incididunt consectetur. Aliquip anim ea fugiat ipsum.\r\n",
    "investment": "Dolore qui tempor dolor fugiat incididunt ex sunt deserunt nulla irure. Consectetur sit nostrud aliqua nisi. Sit nostrud sunt ea adipisicing adipisicing nostrud aliqua.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Ad irure incididunt nulla labore ea aute pariatur. Eiusmod commodo in exercitation do sint culpa ipsum non est qui. Aliquip non fugiat cupidatat velit labore cupidatat. Tempor laborum veniam id consequat ea est cupidatat quis Lorem enim aute.\r\n",
      "engagementDescr": "Velit Lorem elit incididunt esse eu in labore magna incididunt. Aliquip laboris veniam dolor occaecat proident deserunt laborum. Culpa nisi aliqua culpa culpa culpa elit non. Elit nisi laboris consectetur excepteur. Cupidatat consectetur veniam sit nisi esse mollit fugiat duis culpa. Enim sunt anim laborum occaecat voluptate pariatur elit reprehenderit anim.\r\n",
      "dataDescr": "Elit nulla consectetur proident amet Lorem ea et anim excepteur excepteur occaecat exercitation. Eu et ad nostrud occaecat cupidatat reprehenderit proident ullamco cupidatat. Pariatur aliquip occaecat ut deserunt consectetur cillum id nisi est elit exercitation incididunt enim irure.\r\n",
      "routeDescr": "Aliqua duis est qui consectetur proident adipisicing amet irure reprehenderit. Ea adipisicing qui pariatur est ut adipisicing aute ad. Ipsum in incididunt ipsum in. Do sint et officia voluptate pariatur nulla ea amet voluptate proident velit aliquip laborum. Minim aute exercitation est adipisicing dolor voluptate mollit sunt officia incididunt nulla magna anim esse. Cillum laboris proident incididunt et non aliquip est ipsum qui.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Conley Guerra",
        "email": "conleyguerra@dragbot.com",
        "fte": 0.6788
      },
      {
        "id": 1,
        "name": "Guthrie Robbins",
        "email": "guthrierobbins@dragbot.com",
        "fte": 0.9963
      },
      {
        "id": 2,
        "name": "Imelda Clements",
        "email": "imeldaclements@dragbot.com",
        "fte": 0.7004
      },
      {
        "id": 3,
        "name": "Beverley Daugherty",
        "email": "beverleydaugherty@dragbot.com",
        "fte": 0.7703
      },
      {
        "id": 4,
        "name": "Hopkins Maldonado",
        "email": "hopkinsmaldonado@dragbot.com",
        "fte": 0.1951
      },
      {
        "id": 5,
        "name": "Christi Beasley",
        "email": "christibeasley@dragbot.com",
        "fte": 0.8711
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Drake Mcneil",
        "email": "drakemcneil@dragbot.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 8,
        "y3": 39
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 1,
        "y2": 41,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 15,
        "y3": 57
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11614179,
      "customerVal": 93241,
      "mvp": 91938692,
      "optimize": 10984583,
      "grow": 1440577595,
      "scale": 1469410606
    },
    "nextStepsActionPlan": "Incididunt eu nisi sunt ipsum ea non adipisicing aliquip ipsum ullamco amet id ex. Minim est magna eiusmod excepteur. Cillum esse sint cillum reprehenderit. Aute qui minim est cupidatat ex amet ullamco ea nulla. Quis pariatur nostrud commodo fugiat cillum ex et et cupidatat enim consequat eu duis laborum. Incididunt Lorem nostrud esse laborum velit. Aute duis elit Lorem cupidatat commodo esse ullamco nisi anim labore esse nisi.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aute culpa incididunt deserunt exercitation irure do eiusmod culpa veniam in sunt. Tempor amet et reprehenderit nulla voluptate duis nisi consectetur et mollit. Non sunt elit veniam ullamco proident commodo deserunt non amet irure culpa veniam. In officia sit velit ipsum ullamco sit labore deserunt est consectetur. Excepteur aliquip culpa ex fugiat Lorem deserunt laborum ullamco nostrud. Irure nostrud consequat occaecat excepteur sit aliqua.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-07-16",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03713b4c5facbaa95b",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Amril",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rp"
    ],
    "geography": "Brazil",
    "channel": "Online: Shop in shop",
    "division": "Division Three",
    "painPoint": "Anim pariatur excepteur culpa enim enim amet fugiat dolore voluptate dolore cillum anim. Ullamco ad incididunt laboris excepteur ad elit culpa Lorem commodo excepteur duis deserunt aute. Velit veniam esse culpa cupidatat. Quis aute consectetur veniam elit. Qui ex et pariatur ex mollit fugiat aliquip est voluptate ea laboris. Aliqua aliquip dolor Lorem minim proident enim minim nisi pariatur nisi cillum nostrud. Id cillum adipisicing veniam pariatur ipsum in ullamco excepteur sunt.\r\n",
    "description": "Velit nisi aliqua eu occaecat consectetur quis excepteur. Esse aliqua mollit officia dolor commodo nulla incididunt incididunt excepteur eiusmod aliqua exercitation dolor. Sint aliquip aliquip in occaecat nostrud sint aute ea est est eu. Aliquip aliqua enim non in cillum commodo consequat. Nulla culpa labore occaecat excepteur ex aliquip occaecat elit.\r\n",
    "strategy": "Proident nulla consequat duis adipisicing deserunt anim tempor dolore elit aliqua nulla aliqua in non. Do non ullamco sint mollit occaecat labore sint ex nulla do. Cupidatat nulla minim velit nulla duis laborum occaecat culpa. Labore enim id ad occaecat eiusmod minim dolor duis laborum. Ut tempor qui sint culpa proident aute anim tempor id duis reprehenderit incididunt.\r\n",
    "keyLearnings": "Cillum irure et Lorem do culpa aliqua occaecat incididunt esse. Quis quis sunt occaecat quis dolore pariatur aute sit eu. Magna reprehenderit minim dolore amet Lorem pariatur cillum reprehenderit officia mollit. Lorem ipsum exercitation minim adipisicing consequat et Lorem. Nostrud sunt et est sint ut tempor enim duis occaecat. Esse duis excepteur labore deserunt commodo nisi mollit consectetur pariatur est non esse. Laboris enim veniam in enim mollit minim enim cupidatat occaecat commodo laboris fugiat cupidatat commodo.\r\n",
    "nextSteps": "Mollit duis nostrud commodo proident dolore cillum aliqua. Labore eiusmod aliqua fugiat amet eu amet ipsum eiusmod non non. Exercitation amet sit nulla ullamco laborum commodo sit ut in ipsum excepteur sit. Excepteur magna ipsum fugiat consectetur. Aute consequat dolor nostrud sit magna enim est id adipisicing aliqua officia adipisicing. Esse nisi non laborum exercitation dolor magna non cillum ut pariatur dolore.\r\n",
    "investment": "Nostrud quis enim ut culpa exercitation enim laborum enim commodo pariatur reprehenderit. Magna dolore proident anim non anim. Sunt ea Lorem irure sint nulla ut exercitation mollit qui eu deserunt nisi. Minim irure est minim et. Tempor ullamco esse reprehenderit deserunt amet aute laborum. Quis est officia ipsum dolor reprehenderit. Commodo aliquip nisi esse nulla in.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Sint consequat nisi anim velit. Veniam cupidatat cupidatat aute duis labore adipisicing est Lorem ea eu ipsum Lorem Lorem. Amet aliquip consectetur qui fugiat mollit fugiat cupidatat eiusmod Lorem cillum aliqua. Dolor minim culpa sint mollit laboris consequat dolor cupidatat dolore. Amet dolor duis elit Lorem velit nisi. Esse non occaecat nulla consequat ad amet dolor. Ea elit id aliqua veniam laborum quis elit id et amet.\r\n",
      "engagementDescr": "Voluptate sit et proident occaecat est sint incididunt minim commodo ad incididunt Lorem ad. Consequat proident eu incididunt fugiat adipisicing aute aute consectetur esse esse anim aute magna officia. Occaecat dolore in et reprehenderit nulla ipsum. In do nisi laboris duis excepteur laboris in ea ipsum proident et sunt ipsum adipisicing.\r\n",
      "dataDescr": "Ea culpa irure minim veniam minim fugiat veniam esse. Laborum Lorem eiusmod elit fugiat tempor reprehenderit do. Cillum voluptate magna commodo fugiat esse irure aute enim consectetur non Lorem sit occaecat adipisicing. Ea consectetur ullamco do commodo ipsum cupidatat.\r\n",
      "routeDescr": "Nisi culpa cupidatat consequat voluptate elit commodo. Lorem ea sit cupidatat deserunt tempor. Cupidatat aute eu ad duis enim velit nulla pariatur adipisicing minim id deserunt in. Ullamco quis ea irure nisi culpa proident nulla nostrud sint mollit culpa irure laboris.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Chan Dixon",
        "email": "chandixon@amril.com",
        "fte": 0.4763
      },
      {
        "id": 1,
        "name": "Summer Prince",
        "email": "summerprince@amril.com",
        "fte": 0.0777
      },
      {
        "id": 2,
        "name": "Robles Francis",
        "email": "roblesfrancis@amril.com",
        "fte": 0.5071
      },
      {
        "id": 3,
        "name": "Hyde Bryan",
        "email": "hydebryan@amril.com",
        "fte": 0.6496
      },
      {
        "id": 4,
        "name": "Wolf Mendez",
        "email": "wolfmendez@amril.com",
        "fte": 0.4186
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Patti Oneal",
        "email": "pattioneal@amril.com"
      },
      {
        "id": 1,
        "name": "Bullock Poole",
        "email": "bullockpoole@amril.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 10,
        "y3": 26
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 36,
        "y3": 55
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 10,
        "y2": 23,
        "y3": 60
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8643527,
      "customerVal": 239147,
      "mvp": 127274133,
      "optimize": 4876527,
      "grow": 43995479,
      "scale": 938027851
    },
    "nextStepsActionPlan": "Veniam veniam tempor qui non amet exercitation id quis eiusmod ea commodo magna proident. Ut sit id anim laborum. Aliquip et adipisicing commodo anim veniam sunt excepteur consectetur deserunt. Labore sit magna veniam irure enim sunt ad exercitation dolor et anim eiusmod nisi. Deserunt laborum aliqua nisi quis pariatur cillum velit velit. Ad veniam velit do adipisicing. Consequat sit aute voluptate elit deserunt.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Quis veniam eiusmod labore voluptate sit sint aliquip reprehenderit aliqua id exercitation dolore tempor exercitation. Esse ad officia in nulla commodo qui laboris Lorem veniam aliquip nostrud fugiat dolore. Laborum qui excepteur officia Lorem. Ex eu elit ea Lorem labore nisi tempor in.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-13",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e030cb06b402d391079",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Kongene",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rr"
    ],
    "geography": "Germany",
    "channel": "Online: Shop in shop",
    "division": "Division Two",
    "painPoint": "Sint enim occaecat est Lorem exercitation enim minim anim duis eiusmod Lorem deserunt pariatur. Qui sint cupidatat minim laboris. Ipsum quis aute irure nulla elit irure irure excepteur commodo ea laboris. Est consectetur sit deserunt labore amet excepteur cillum minim anim id. Ex mollit occaecat in anim enim laborum officia et eu. Nulla enim velit exercitation tempor do incididunt pariatur non culpa et consectetur fugiat duis excepteur.\r\n",
    "description": "Consectetur fugiat labore consequat ea non officia Lorem ea ullamco non est id. Velit sint ex consequat proident id sit et ipsum consequat incididunt dolore. Esse ut amet dolore eiusmod aliqua consectetur laborum Lorem occaecat culpa cupidatat.\r\n",
    "strategy": "Occaecat fugiat qui incididunt labore officia ad eu et laboris aliqua sint. Nostrud excepteur Lorem excepteur fugiat. Qui nostrud id occaecat anim consequat anim.\r\n",
    "keyLearnings": "Dolor dolore dolore minim veniam tempor ex esse voluptate irure fugiat adipisicing enim. Elit aliqua labore non nisi. Tempor eiusmod mollit est sit incididunt ad aliquip amet commodo proident tempor. Nostrud adipisicing culpa esse eiusmod anim minim sunt occaecat ipsum esse.\r\n",
    "nextSteps": "Incididunt nulla elit in dolore ea aliqua ipsum. Est Lorem duis reprehenderit in do elit non irure ullamco et in. Et voluptate do Lorem in commodo quis. Do voluptate nulla dolore ea aliquip officia cupidatat aliquip pariatur id ea eu et. Ut veniam excepteur sit elit laborum est proident esse esse occaecat et ad enim anim.\r\n",
    "investment": "Minim ipsum consectetur mollit sint ea consectetur pariatur consectetur nostrud. Dolore exercitation cillum et qui non duis. Consectetur excepteur Lorem eiusmod reprehenderit anim sint esse ut culpa aliquip. Dolore commodo cillum sint nisi velit do. Et nulla voluptate pariatur mollit sint mollit sunt exercitation amet amet ea incididunt velit.\r\n",
    "currentStage": "ideation",
    "sovDescription": {
      "intrinsicDescr": "Enim deserunt nisi minim non consectetur incididunt incididunt in nisi elit sint. Id duis dolore occaecat veniam adipisicing amet laboris excepteur commodo. Fugiat mollit mollit aliquip qui velit sunt aliqua aute officia velit occaecat. Enim magna esse excepteur laborum incididunt occaecat magna elit id esse ullamco. Ad anim esse veniam excepteur. Reprehenderit nulla fugiat consectetur deserunt do ipsum.\r\n",
      "engagementDescr": "Ad consectetur eu adipisicing reprehenderit. Eiusmod nulla consectetur aliqua adipisicing anim ipsum esse culpa non ut ut eu. Commodo commodo aute deserunt et aliquip culpa duis non.\r\n",
      "dataDescr": "Sunt eiusmod enim veniam eu consectetur velit sunt ullamco. Anim nulla ea occaecat nostrud. Voluptate laboris magna culpa eu et irure veniam minim. Ad deserunt ea et sunt irure id pariatur. Nisi ad nisi non consequat officia voluptate ipsum et amet aliqua sunt ea.\r\n",
      "routeDescr": "Labore veniam dolor in pariatur nulla aute Lorem et adipisicing aliqua elit id. Qui elit pariatur in proident. Consequat id ut velit nulla consequat proident aute et laboris id.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Keri Garza",
        "email": "kerigarza@kongene.com",
        "fte": 0.3439
      },
      {
        "id": 1,
        "name": "Branch Mccoy",
        "email": "branchmccoy@kongene.com",
        "fte": 0.1927
      },
      {
        "id": 2,
        "name": "Danielle Cameron",
        "email": "daniellecameron@kongene.com",
        "fte": 0.0902
      },
      {
        "id": 3,
        "name": "Gates Carlson",
        "email": "gatescarlson@kongene.com",
        "fte": 0.2716
      },
      {
        "id": 4,
        "name": "Nelda Riggs",
        "email": "neldariggs@kongene.com",
        "fte": 0.482
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Hayden Burns",
        "email": "haydenburns@kongene.com"
      },
      {
        "id": 1,
        "name": "Lorna Wright",
        "email": "lornawright@kongene.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 16,
        "y3": 18
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 12,
        "y2": 48,
        "y3": 69
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 11,
        "y2": 15,
        "y3": 82
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 8165704,
      "customerVal": 443144,
      "mvp": 114470745,
      "optimize": 13533829,
      "grow": 299525157,
      "scale": 552114942
    },
    "nextStepsActionPlan": "Nulla nostrud occaecat excepteur in. Sint eiusmod exercitation anim irure est Lorem ut aliqua velit tempor ea. Duis culpa aliquip est laborum labore cupidatat incididunt dolore occaecat do dolor. Sunt incididunt occaecat mollit dolor anim ad. Culpa aute aute nostrud duis exercitation laborum sunt ut id pariatur fugiat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Mollit sint sit pariatur deserunt aliqua nulla proident aliqua nisi. Dolor ut labore magna laboris deserunt voluptate amet esse ut ut. Anim mollit nostrud ad adipisicing fugiat fugiat est anim adipisicing mollit dolor exercitation duis amet. Cillum culpa laborum aliqua enim exercitation.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-06-30",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e037ca84f86517fdf72",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Three",
    "name": "Vendblend",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "China",
    "channel": "Online: Subscription",
    "division": "Division Two",
    "painPoint": "Dolore reprehenderit mollit qui elit nisi nostrud consequat enim ex irure consectetur occaecat. Nostrud laborum sit quis Lorem reprehenderit. Labore magna sit exercitation deserunt elit pariatur veniam ipsum enim. Exercitation commodo amet voluptate ut duis ad duis velit incididunt quis qui do aliquip pariatur. Consequat sit officia ad nostrud exercitation labore incididunt irure. Minim ipsum qui labore deserunt elit velit minim sint nostrud et commodo. Nostrud sint reprehenderit reprehenderit culpa cupidatat quis ullamco cillum amet irure exercitation adipisicing incididunt in.\r\n",
    "description": "Ut sunt sint ipsum proident eiusmod et aliqua do nulla laborum commodo tempor. Proident laborum quis sint exercitation anim culpa. Ut excepteur ex ut magna in veniam occaecat culpa laborum est dolor ea. Sint labore exercitation consequat occaecat reprehenderit sit. Ipsum aute fugiat et in Lorem incididunt quis ex est veniam minim aliquip sint laborum. Consequat aliqua cupidatat pariatur eu nostrud duis quis dolore laboris ea quis veniam nisi.\r\n",
    "strategy": "Id dolor cupidatat cupidatat ipsum culpa occaecat. Lorem ex eu exercitation occaecat esse sint irure ex. Do nostrud esse irure elit amet ad dolore sit velit. Eu minim reprehenderit sit non id voluptate laborum mollit ullamco occaecat esse id pariatur. Ullamco Lorem fugiat deserunt sit anim proident dolor incididunt incididunt occaecat voluptate.\r\n",
    "keyLearnings": "Mollit pariatur nostrud consectetur cillum qui mollit in anim aliquip sint commodo. Id nostrud magna minim et nulla consequat voluptate officia culpa pariatur. Enim eiusmod nisi minim quis irure enim laboris. Quis ullamco non amet eu tempor eiusmod culpa laboris magna. Cillum ipsum laboris aliqua nulla eiusmod sunt dolore nulla ea ad sit elit. Reprehenderit mollit ut aliquip occaecat incididunt irure ut occaecat velit in veniam aliquip Lorem. Amet ad dolore laborum duis quis qui ea.\r\n",
    "nextSteps": "Ex sunt aute consequat in dolor proident in qui commodo duis voluptate veniam non. In dolor in irure ut eu. Quis sint amet ex sint sint laboris dolore velit excepteur do qui. Adipisicing eu esse cillum incididunt laborum excepteur irure non est ipsum ipsum ad. Aute consequat enim cillum sit qui nisi amet velit. Minim ad mollit aute cillum laborum sint minim magna do minim velit dolore ea.\r\n",
    "investment": "Laboris proident anim ea ullamco veniam Lorem nisi quis labore in magna amet. Aute esse eiusmod aliquip veniam excepteur ut non minim id consectetur. Minim reprehenderit magna deserunt amet ea in. Proident nisi voluptate nulla fugiat commodo in incididunt cillum ex voluptate ad in. Mollit velit amet ex anim id enim adipisicing Lorem dolor ut. Aliquip sint reprehenderit fugiat duis aute culpa do et.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Quis magna ad id anim tempor consequat nostrud occaecat eiusmod deserunt. Excepteur nostrud velit reprehenderit est duis. Ut duis quis aute et ex. Consequat adipisicing laboris incididunt eiusmod irure incididunt nisi.\r\n",
      "engagementDescr": "Pariatur consectetur est elit deserunt non reprehenderit culpa cillum velit quis in ut commodo. Reprehenderit et nulla eiusmod proident consequat velit. Et laborum ut qui laboris sunt laboris mollit ea cillum ad.\r\n",
      "dataDescr": "Excepteur do ea quis eiusmod eu culpa. Sint enim tempor velit cupidatat id laborum sint cupidatat nisi deserunt excepteur culpa dolore. Commodo enim Lorem nisi qui. Fugiat aliqua fugiat aute laboris laboris ipsum aliquip aliqua sit quis deserunt reprehenderit. Reprehenderit eiusmod dolor excepteur ut aute. Sit ullamco deserunt magna veniam.\r\n",
      "routeDescr": "Consectetur incididunt duis eu eiusmod proident irure ullamco qui ad aliqua. Deserunt cupidatat adipisicing reprehenderit non eiusmod ipsum sunt cillum nostrud. Ea aliqua labore eiusmod sit irure aliquip minim incididunt voluptate minim consequat. Cillum labore cillum id cupidatat adipisicing eiusmod elit et amet mollit. Occaecat dolor veniam occaecat enim id incididunt pariatur.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Naomi Bailey",
        "email": "naomibailey@vendblend.com",
        "fte": 0.6073
      },
      {
        "id": 1,
        "name": "Gertrude Rhodes",
        "email": "gertruderhodes@vendblend.com",
        "fte": 0.6998
      },
      {
        "id": 2,
        "name": "Petra Hensley",
        "email": "petrahensley@vendblend.com",
        "fte": 0.1968
      },
      {
        "id": 3,
        "name": "Dalton Wilkerson",
        "email": "daltonwilkerson@vendblend.com",
        "fte": 0.7841
      },
      {
        "id": 4,
        "name": "Vazquez Mcdaniel",
        "email": "vazquezmcdaniel@vendblend.com",
        "fte": 0.4949
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Velazquez Newton",
        "email": "velazqueznewton@vendblend.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 3,
        "y2": 13,
        "y3": 25
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 50,
        "y3": 67
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 10,
        "y3": 88
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4943242,
      "customerVal": 219773,
      "mvp": 99940697,
      "optimize": 12868500,
      "grow": 393868124,
      "scale": 1144238119
    },
    "nextStepsActionPlan": "Labore cillum ullamco minim ea excepteur sint consequat fugiat fugiat. Velit excepteur esse nulla est id eiusmod culpa consectetur magna elit laboris pariatur. Ad ullamco esse elit consequat adipisicing pariatur do adipisicing.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Occaecat excepteur est fugiat non ad aliquip voluptate ut pariatur sint ad laboris ea. Laborum ut commodo ipsum esse ea consectetur non laboris. Excepteur adipisicing quis reprehenderit commodo anim ex. Do deserunt exercitation dolor sunt duis quis consectetur magna mollit sunt esse.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-03-27",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0389373c768da3788e",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Accusage",
    "highlightKPI": [
      "cac",
      "bcr",
      "rp"
    ],
    "geography": "India",
    "channel": "Online: Subscription",
    "division": "Division One",
    "painPoint": "Occaecat nisi duis esse mollit et est laboris. Veniam ex cillum incididunt deserunt ut veniam laboris ipsum magna qui irure eu id. Adipisicing proident velit ea amet ex deserunt. Sint cillum consequat culpa tempor excepteur culpa. Veniam proident dolor deserunt proident eiusmod nostrud ut reprehenderit proident mollit culpa proident ea. Ullamco dolore Lorem quis aute magna aute dolore proident nisi labore non voluptate pariatur magna.\r\n",
    "description": "Proident consequat voluptate commodo minim dolore tempor in. Nostrud culpa mollit labore fugiat tempor. In esse adipisicing laboris sit do dolor ipsum sint consequat eiusmod minim voluptate.\r\n",
    "strategy": "Qui nostrud mollit labore aliqua sint ex. Non proident eiusmod velit duis aliquip cillum ex duis adipisicing Lorem nisi dolore. Veniam aliqua consequat labore amet id mollit ipsum consequat laborum irure reprehenderit amet. Consequat amet velit et commodo irure anim non. Qui voluptate dolore ullamco culpa. Sint exercitation est deserunt officia consectetur eu cupidatat non irure aliquip exercitation tempor occaecat pariatur.\r\n",
    "keyLearnings": "Aliqua irure velit cupidatat nostrud tempor fugiat labore commodo minim. Quis labore anim mollit irure consectetur do. Adipisicing mollit adipisicing dolore duis ad ut. Quis ad in excepteur ex cupidatat fugiat nostrud amet. Aliqua ut velit culpa dolore id sint consectetur cillum laborum. Reprehenderit labore mollit mollit sunt proident quis ea proident veniam aliqua labore nisi. Aliqua nisi nisi aute irure sunt nulla ut fugiat ex cillum dolor proident.\r\n",
    "nextSteps": "Minim labore aliqua do enim amet aliquip. Veniam laboris dolor excepteur non magna consectetur id amet ullamco. Nostrud culpa laboris elit incididunt anim est sit incididunt adipisicing cillum qui deserunt tempor. Fugiat magna commodo laboris labore laborum voluptate irure laboris voluptate voluptate excepteur.\r\n",
    "investment": "Dolore anim ea laboris fugiat eu. Est ea nostrud non fugiat ex sint dolore ut in nulla. Magna laborum labore enim dolor ipsum est esse.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Voluptate minim cillum dolor laboris irure Lorem sit veniam id aute. Exercitation irure ipsum cillum culpa ex nisi. Cupidatat consequat consectetur consequat ullamco do minim fugiat occaecat dolor do eu reprehenderit cillum. Anim dolor laboris laboris consequat sit incididunt ullamco velit proident exercitation. In voluptate ipsum consequat laborum veniam duis et.\r\n",
      "engagementDescr": "Pariatur reprehenderit aliqua Lorem duis do mollit. Exercitation consectetur sint officia culpa esse laborum nisi veniam laborum. Excepteur duis esse ut est excepteur id esse consectetur incididunt cillum. Proident dolor magna elit commodo enim fugiat officia ut. Ut occaecat reprehenderit reprehenderit incididunt laborum.\r\n",
      "dataDescr": "Esse laboris eiusmod sunt proident. Quis elit aliquip aliqua minim ex excepteur nulla ipsum commodo proident consectetur qui enim consequat. Et in Lorem enim consequat cupidatat qui irure elit culpa veniam aliquip officia. Id minim qui labore est cillum eiusmod eiusmod aliqua eu adipisicing anim quis cillum qui.\r\n",
      "routeDescr": "Nostrud proident do minim esse adipisicing commodo laboris ut enim veniam est exercitation velit commodo. Lorem amet sint irure tempor id voluptate esse id labore adipisicing do duis. Dolor qui ad amet aliqua laborum esse sint nisi aliquip culpa. Anim non ut pariatur incididunt. Veniam voluptate elit culpa ut excepteur eiusmod proident. Exercitation laboris cupidatat nulla mollit elit.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Christy House",
        "email": "christyhouse@accusage.com",
        "fte": 0.2865
      },
      {
        "id": 1,
        "name": "Alexandra Dale",
        "email": "alexandradale@accusage.com",
        "fte": 0.8697
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Herminia Walters",
        "email": "herminiawalters@accusage.com"
      },
      {
        "id": 1,
        "name": "Gayle Atkins",
        "email": "gayleatkins@accusage.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 18,
        "y3": 38
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 13,
        "y2": 51,
        "y3": 67
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 7,
        "y2": 20,
        "y3": 92
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 11987730,
      "customerVal": 281466,
      "mvp": 33931120,
      "optimize": 9769706,
      "grow": 871568092,
      "scale": 496470702
    },
    "nextStepsActionPlan": "Aliqua aute dolor adipisicing ea. Sint dolor ad cillum labore ad sint sint fugiat do. Officia qui pariatur exercitation commodo deserunt fugiat dolore mollit do do adipisicing. Magna labore eu magna sit reprehenderit dolore esse duis minim est. Veniam incididunt veniam ad id. Magna do consectetur laborum non aliquip in est ipsum irure dolor aute esse.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Laboris laboris reprehenderit ullamco non amet consectetur ipsum duis. Mollit occaecat eu est non duis proident velit velit excepteur deserunt consectetur ipsum nulla pariatur. Exercitation velit nostrud Lorem sint.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-20",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e035a0c76ffe5c142fa",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Lunchpad",
    "highlightKPI": [
      "cac",
      "gmac",
      "rp"
    ],
    "geography": "Netherlands",
    "channel": "Offline: Pop up store",
    "division": "Division One",
    "painPoint": "Ullamco excepteur quis nisi culpa eu sint anim do. Ipsum nulla culpa culpa deserunt ex minim veniam officia nisi cupidatat tempor ex. Commodo ea mollit nostrud pariatur irure. Ex nostrud non nisi ad duis dolore deserunt laborum. Non reprehenderit nulla commodo amet voluptate commodo tempor ipsum dolore do pariatur nisi.\r\n",
    "description": "Occaecat aliqua eiusmod cupidatat ad proident. Ea ea culpa irure non dolore dolore dolore culpa aute dolore anim enim minim cupidatat. Voluptate commodo commodo fugiat occaecat sunt aute aliqua adipisicing minim dolor pariatur. Sit non elit nostrud culpa ullamco commodo aliqua mollit tempor consectetur minim mollit est. Ex nisi nulla quis proident nisi eu esse tempor. Veniam cupidatat consequat labore tempor commodo commodo.\r\n",
    "strategy": "In voluptate veniam ea consequat esse consectetur esse Lorem tempor aliqua. Laborum nisi et minim reprehenderit laborum commodo laborum adipisicing culpa laborum quis magna nulla. Ullamco dolor irure exercitation deserunt nostrud aute proident dolor. Aute ea proident ad id esse do aliqua.\r\n",
    "keyLearnings": "Reprehenderit proident quis culpa et enim est laboris consectetur excepteur ipsum laboris quis dolore. Veniam consequat excepteur nisi sit. Ad id cillum commodo nulla nostrud adipisicing non enim dolor eiusmod qui esse adipisicing sint. Mollit cillum aliqua adipisicing quis consectetur sit consequat non qui duis consequat quis non culpa. Aliqua consectetur minim enim exercitation nulla commodo. Pariatur ad do fugiat in.\r\n",
    "nextSteps": "Id dolore in in ea anim velit minim anim minim consectetur eiusmod exercitation. Non ipsum in cillum velit sit id irure. Dolore reprehenderit anim sunt incididunt enim cillum aliqua aute ex eiusmod non nisi esse.\r\n",
    "investment": "Qui irure duis quis eiusmod commodo ipsum incididunt consequat nulla id in. Consectetur reprehenderit ad nisi cillum eiusmod duis non aliqua et. Eu elit cupidatat dolore velit ea magna labore aute id aliqua ipsum. Proident occaecat officia quis et qui aliqua ad velit anim eu sint minim.\r\n",
    "currentStage": "grow",
    "sovDescription": {
      "intrinsicDescr": "Incididunt labore occaecat laborum cillum aliqua do aute duis pariatur enim anim nostrud cillum ad. Esse Lorem eiusmod mollit non. Ad veniam magna occaecat consequat qui. Nostrud Lorem ad proident aute adipisicing enim aliquip. Irure mollit aute sint deserunt deserunt cupidatat anim id exercitation laboris adipisicing sit. Laboris dolor Lorem qui consectetur ipsum aliquip commodo aliqua sunt anim eiusmod tempor irure tempor. Occaecat aliquip incididunt quis quis et mollit non fugiat aliqua elit commodo ullamco.\r\n",
      "engagementDescr": "Commodo nisi dolor commodo eu. Pariatur cillum deserunt et dolor veniam sunt ex qui dolor. Deserunt sit esse consectetur ut labore. Sit eu officia sint incididunt dolor eu ea.\r\n",
      "dataDescr": "Ex consequat deserunt ullamco ex Lorem. Esse qui tempor adipisicing reprehenderit non sint officia. Amet cillum consectetur labore incididunt qui ullamco. Ea sint incididunt ipsum laboris do enim qui minim sit tempor pariatur dolore.\r\n",
      "routeDescr": "Ad velit velit exercitation fugiat ea id. Ad duis pariatur laborum nulla pariatur dolore irure. Pariatur dolore do in cillum in mollit. In voluptate nisi in consectetur exercitation consectetur tempor anim sunt minim anim occaecat. Amet veniam tempor laborum qui fugiat amet labore nisi ullamco magna non amet dolor. Enim labore eu quis non ullamco deserunt.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Maryellen Bauer",
        "email": "maryellenbauer@lunchpad.com",
        "fte": 0.4955
      },
      {
        "id": 1,
        "name": "Haley West",
        "email": "haleywest@lunchpad.com",
        "fte": 0.1417
      },
      {
        "id": 2,
        "name": "Marisol Mcconnell",
        "email": "marisolmcconnell@lunchpad.com",
        "fte": 0.3863
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kathie Elliott",
        "email": "kathieelliott@lunchpad.com"
      },
      {
        "id": 1,
        "name": "Griffin Baxter",
        "email": "griffinbaxter@lunchpad.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 9,
        "y2": 16,
        "y3": 35
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 19,
        "y2": 43,
        "y3": 76
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 14,
        "y2": 13,
        "y3": 98
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 10133490,
      "customerVal": 177148,
      "mvp": 40693008,
      "optimize": 1596338,
      "grow": 1465541061,
      "scale": 549278987
    },
    "nextStepsActionPlan": "Voluptate ullamco aliqua eu excepteur culpa aliqua. Sint quis eu consectetur velit veniam aliqua velit. Deserunt proident qui aliqua consectetur esse in laborum pariatur. Minim et anim laboris anim. Cillum aute mollit eiusmod ipsum labore cupidatat minim proident adipisicing Lorem aliquip cillum cillum. Exercitation laboris tempor ipsum laborum labore amet ullamco nulla ipsum est ex. Enim do exercitation velit qui officia anim reprehenderit do.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Deserunt sint nulla sunt aliquip reprehenderit excepteur consequat in laborum ut. In minim ea aliquip sunt esse aute enim ut eiusmod pariatur amet et dolor eiusmod. Ad sunt aute ipsum ipsum elit eiusmod aute ullamco quis minim in Lorem cupidatat.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-05-24",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0349f692eee59e07da",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Shadease",
    "highlightKPI": [
      "ctr",
      "bcr",
      "tc"
    ],
    "geography": "Netherlands",
    "channel": "Online: Digital distributor",
    "division": "Division One",
    "painPoint": "Mollit fugiat proident officia incididunt enim. Tempor id anim nisi eiusmod proident eu aliqua. Exercitation ullamco pariatur dolor sint est do excepteur commodo esse ipsum anim proident eu tempor. Anim nisi magna labore aute irure deserunt laboris do officia ipsum sunt nisi veniam. Lorem sint elit officia nisi Lorem aute. Eu proident Lorem anim velit proident non ex fugiat fugiat eiusmod qui minim. Adipisicing sit eiusmod non incididunt veniam esse sit magna id velit Lorem.\r\n",
    "description": "Lorem do aliqua exercitation incididunt occaecat. Tempor elit adipisicing nulla eiusmod magna commodo consequat. Est eu do est anim. Nostrud esse eu ut tempor labore.\r\n",
    "strategy": "Aliqua nulla cillum incididunt qui exercitation do voluptate non amet officia ea nostrud minim dolor. Exercitation pariatur tempor culpa consequat minim anim sit deserunt commodo nisi ea. Adipisicing veniam commodo ut adipisicing aliquip id veniam.\r\n",
    "keyLearnings": "Consectetur incididunt anim aliquip irure sunt cillum. Sint consequat duis veniam labore Lorem incididunt velit non dolore voluptate cillum eu fugiat cillum. Veniam ipsum duis aliquip consectetur ex pariatur irure eiusmod ipsum. Esse aliqua nulla anim velit. Elit pariatur nulla occaecat exercitation deserunt eu aliqua. Aliquip deserunt dolor incididunt exercitation proident ipsum ea laborum.\r\n",
    "nextSteps": "Commodo ipsum id culpa non dolore esse irure ad anim amet. Do esse magna dolore ad reprehenderit dolore incididunt dolore exercitation. Nostrud nulla pariatur culpa commodo enim reprehenderit incididunt eu. Occaecat officia in commodo deserunt officia velit labore ipsum exercitation anim commodo commodo qui esse. Lorem magna adipisicing et officia. Mollit magna ea incididunt commodo velit nulla.\r\n",
    "investment": "Cillum amet commodo non officia officia Lorem nulla irure qui non. Ut do est nisi non minim laborum ipsum sunt velit excepteur occaecat occaecat voluptate cillum. Ut nulla culpa aliqua esse. Elit proident minim ullamco excepteur nisi tempor adipisicing ad. Exercitation ea fugiat reprehenderit adipisicing duis cupidatat ipsum cillum ullamco. Sunt ad dolor cillum nisi dolor laborum ipsum.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Nulla deserunt id voluptate fugiat id. Sunt aliqua dolore consectetur nisi voluptate officia voluptate veniam nulla. Tempor in veniam occaecat aute esse nulla nisi cillum minim. Ea officia ut incididunt ipsum veniam incididunt laborum. Veniam aliqua id cillum exercitation sit eiusmod enim aliqua ullamco. Proident reprehenderit consequat officia reprehenderit pariatur proident amet quis non irure ea tempor cupidatat sit.\r\n",
      "engagementDescr": "Ullamco esse eiusmod ad irure culpa irure officia sunt nisi deserunt enim incididunt. Exercitation irure eiusmod sit incididunt cupidatat anim id sint ut. Commodo aute exercitation incididunt est magna cupidatat qui commodo dolore magna anim ex voluptate. Sunt dolore ullamco anim amet cupidatat cillum exercitation. Commodo anim culpa culpa adipisicing velit deserunt do velit elit. Aute amet fugiat qui aliquip do eiusmod magna sunt tempor incididunt ea aliquip excepteur.\r\n",
      "dataDescr": "Pariatur ad labore et aliquip consectetur aliqua aute quis aliquip laborum aute fugiat esse. Amet consectetur laboris in enim. Nulla irure ullamco ipsum et eiusmod consequat reprehenderit non. Cupidatat aliquip aliqua cupidatat mollit sint pariatur nulla ad. In aliquip elit incididunt amet sunt irure.\r\n",
      "routeDescr": "Pariatur sit mollit dolore labore ad magna laborum magna. Minim labore enim cupidatat velit. Ut consectetur excepteur consectetur elit. Magna excepteur laboris pariatur consectetur tempor esse commodo eu non. Adipisicing pariatur ullamco id esse tempor exercitation velit elit mollit laborum tempor incididunt. Nostrud qui laborum aliquip reprehenderit sint.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Caldwell Foreman",
        "email": "caldwellforeman@shadease.com",
        "fte": 0.6303
      },
      {
        "id": 1,
        "name": "Opal Delaney",
        "email": "opaldelaney@shadease.com",
        "fte": 0.5347
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Odonnell Meyers",
        "email": "odonnellmeyers@shadease.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 2,
        "y2": 17,
        "y3": 16
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 11,
        "y2": 31,
        "y3": 72
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 14,
        "y3": 64
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6125168,
      "customerVal": 38823,
      "mvp": 23892532,
      "optimize": 7327488,
      "grow": 1226354334,
      "scale": 764367911
    },
    "nextStepsActionPlan": "Deserunt proident deserunt irure cillum aliquip dolore excepteur aliqua sint commodo ut excepteur fugiat. Ut veniam consectetur sit aute amet irure. Deserunt est est sunt adipisicing veniam reprehenderit exercitation elit cillum. Laborum veniam incididunt aliquip do exercitation sit incididunt non laborum. Sint nulla consectetur dolor ex dolor dolore voluptate laborum.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Aliquip ipsum laboris consectetur ipsum non deserunt. Ex fugiat minim et dolore minim consectetur aute eu officia labore. Ut esse ex cupidatat labore reprehenderit. Ullamco ipsum et enim et quis cupidatat aliqua aliquip. Sunt ea ut velit est ullamco consectetur ea consectetur ut culpa amet velit ex proident. Reprehenderit cupidatat voluptate sit elit minim non aliquip sunt dolore ex ut. Sunt eu culpa eu anim deserunt labore minim nisi Lorem.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-12",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e0348e6bb7aed6fd467",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition One",
    "name": "Namegen",
    "highlightKPI": [
      "nsv",
      "bcr",
      "rr"
    ],
    "geography": "China",
    "channel": "Online: Digital distributor",
    "division": "Division Three",
    "painPoint": "Eu culpa non nulla adipisicing laboris. Ullamco qui incididunt officia enim id. Aliquip eu consectetur eu deserunt mollit esse incididunt elit nisi quis ad. Eu et quis magna deserunt officia enim nostrud et duis in nisi exercitation officia. Nisi sunt deserunt consequat labore mollit dolor duis sunt in fugiat cupidatat.\r\n",
    "description": "Sunt in consectetur reprehenderit ex ullamco consequat eiusmod. Magna consectetur do commodo consectetur. Ex do occaecat aliquip reprehenderit sunt amet pariatur ad commodo qui enim occaecat. Cupidatat pariatur consectetur non labore cillum consectetur eu. Minim irure consectetur pariatur aute veniam eiusmod veniam et dolor.\r\n",
    "strategy": "Culpa magna tempor sit nisi Lorem consectetur irure labore reprehenderit cillum. Laborum fugiat fugiat cillum et elit ad. Ullamco ut laborum deserunt commodo deserunt. Culpa labore veniam laborum occaecat veniam ex cillum et adipisicing reprehenderit do fugiat deserunt. Eu cillum laborum labore ea irure qui.\r\n",
    "keyLearnings": "Officia reprehenderit consectetur cupidatat cillum. Deserunt aliqua ea ut sunt. Enim labore sit non ex non consectetur magna tempor irure ipsum. Id commodo nulla consequat consequat sint labore eiusmod esse. Consectetur magna non aute cillum est exercitation cillum cupidatat aute tempor irure elit. Esse quis qui culpa magna irure amet amet nostrud mollit. Esse eiusmod fugiat non occaecat dolore est.\r\n",
    "nextSteps": "Minim ut ex fugiat ea ex occaecat. Deserunt mollit duis officia amet. Dolor nulla do mollit cillum et ex.\r\n",
    "investment": "Ex eiusmod esse excepteur nostrud aute culpa do. Exercitation pariatur officia non nostrud ullamco incididunt ullamco cillum anim qui. Cupidatat enim culpa elit ex magna irure cillum non tempor sit veniam veniam nulla.\r\n",
    "currentStage": "scale",
    "sovDescription": {
      "intrinsicDescr": "Amet quis enim proident quis ad. Consequat officia id commodo id labore consectetur dolor laboris enim consequat consequat. Enim dolor commodo occaecat minim et cillum irure sint eu tempor deserunt pariatur id. Ea ullamco labore tempor dolore ad voluptate officia consectetur elit ea officia dolor.\r\n",
      "engagementDescr": "Laboris officia irure cupidatat deserunt ullamco. Proident amet excepteur nostrud incididunt irure amet id aliqua cillum aliquip magna veniam. Amet ad voluptate in adipisicing veniam. Lorem Lorem aliqua nisi in nisi aute commodo veniam est in aute.\r\n",
      "dataDescr": "Sint veniam aliquip non velit est et. Consequat in magna voluptate ex sunt magna. Elit fugiat eu proident aliquip culpa excepteur sit velit voluptate aliquip commodo. Eiusmod elit ullamco magna non ex culpa occaecat id dolor consequat sit velit ad velit. Aliquip excepteur dolore sit dolore cupidatat magna qui voluptate excepteur adipisicing sit aute fugiat. Et consequat minim fugiat velit cillum officia tempor et tempor nisi ipsum elit voluptate irure.\r\n",
      "routeDescr": "In cillum cupidatat velit aute ad ea ex incididunt exercitation anim nostrud anim anim. Aute cillum eu tempor ea aliquip veniam tempor esse ipsum do fugiat incididunt commodo laborum. Adipisicing labore ut do tempor ullamco. Consectetur reprehenderit ea qui ex nisi mollit.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Marcella Guy",
        "email": "marcellaguy@namegen.com",
        "fte": 0.3906
      },
      {
        "id": 1,
        "name": "Cecelia Glover",
        "email": "ceceliaglover@namegen.com",
        "fte": 0.8902
      },
      {
        "id": 2,
        "name": "Harrington Doyle",
        "email": "harringtondoyle@namegen.com",
        "fte": 0.8044
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Dudley Mcgee",
        "email": "dudleymcgee@namegen.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 8,
        "y2": 17,
        "y3": 24
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 8,
        "y2": 55,
        "y3": 57
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 13,
        "y2": 30,
        "y3": 98
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 6648194,
      "customerVal": 1131846,
      "mvp": 37859504,
      "optimize": 6453030,
      "grow": 687745635,
      "scale": 17333346
    },
    "nextStepsActionPlan": "Nisi Lorem Lorem qui in tempor sint ad culpa. Cupidatat eiusmod ullamco in excepteur. Proident Lorem quis incididunt ipsum sit pariatur proident voluptate labore ut minim non ullamco. Veniam ad pariatur in Lorem esse non velit Lorem do. Pariatur eiusmod mollit do veniam.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Laboris aliqua cupidatat ad non. Nisi fugiat laboris adipisicing velit exercitation magna do nisi ullamco dolor mollit. Est cillum elit commodo commodo voluptate deserunt mollit labore incididunt. Aliquip nostrud magna cupidatat eu in labore eu officia officia laboris duis ex labore. Commodo reprehenderit elit excepteur ipsum. Deserunt cillum tempor ut dolore do id Lorem eu do eiusmod. Exercitation aliquip consectetur proident non dolore dolore commodo in occaecat duis ipsum exercitation sint.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-04-29",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e03a38469b49b8d3688",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Gynk",
    "highlightKPI": [
      "ctr",
      "ltv",
      "rr"
    ],
    "geography": "Germany",
    "channel": "Online: Home delivery",
    "division": "Division One",
    "painPoint": "Ut laboris nisi consectetur proident reprehenderit commodo aliquip. Incididunt ad dolor aliqua reprehenderit id minim fugiat culpa do consectetur minim reprehenderit qui deserunt. Ipsum in labore deserunt amet sint minim irure duis aliquip mollit sunt. Pariatur eiusmod officia sit et qui eu ullamco officia occaecat reprehenderit dolore proident. Est fugiat laborum ipsum anim voluptate et incididunt. Magna esse voluptate laboris culpa velit sint anim elit laborum sint enim.\r\n",
    "description": "Nostrud enim adipisicing tempor aute ut et ea irure ipsum ex sint cillum cupidatat id. Commodo fugiat ex veniam do dolor minim qui ex elit pariatur eiusmod dolor enim sunt. Fugiat nulla eiusmod dolor eu Lorem proident. Occaecat dolor voluptate aliquip dolore ullamco. Eu sit labore duis commodo sint et minim nostrud. Fugiat excepteur anim aliquip mollit Lorem dolor aliquip consequat.\r\n",
    "strategy": "Excepteur et reprehenderit exercitation proident aliqua consequat magna reprehenderit consequat aute aute. Sit et occaecat eu sint. Irure duis laboris adipisicing dolor labore mollit amet. Consectetur et ad mollit ipsum veniam elit magna. In mollit reprehenderit ad nostrud ipsum consectetur laborum irure proident consequat consequat mollit. Veniam incididunt reprehenderit et et pariatur ex sunt velit consectetur do anim quis ea aliqua.\r\n",
    "keyLearnings": "Nostrud amet consectetur exercitation ut qui quis laborum tempor. Dolor eiusmod nisi reprehenderit et aliquip cillum anim enim commodo aliquip minim ut deserunt irure. Nisi eiusmod Lorem eu aliqua exercitation. Pariatur dolor consectetur excepteur cillum culpa eu consectetur non nulla dolore ipsum.\r\n",
    "nextSteps": "Et consequat Lorem fugiat consequat enim. Cillum deserunt mollit deserunt nisi enim in nulla sunt duis ut consequat nulla. Ex incididunt adipisicing sunt qui ullamco non nostrud. Ea fugiat minim id esse amet qui adipisicing ea nisi proident non labore. Cillum tempor nisi voluptate eu nostrud ex aliqua laboris dolor ipsum adipisicing proident in.\r\n",
    "investment": "Mollit occaecat laboris minim esse veniam et officia eiusmod pariatur tempor ad reprehenderit dolor amet. Sunt sint aliquip pariatur cupidatat ex laboris enim amet laboris ullamco in. Anim id eu sit culpa cillum minim voluptate esse ullamco eiusmod.\r\n",
    "currentStage": "optimize",
    "sovDescription": {
      "intrinsicDescr": "Proident est incididunt mollit nostrud ullamco. Ea occaecat enim et quis labore proident proident cillum nisi consectetur sunt magna non. Nostrud id elit officia reprehenderit veniam ea laboris reprehenderit.\r\n",
      "engagementDescr": "Eu consequat amet sunt reprehenderit sunt. Commodo nostrud proident occaecat magna anim qui ea. Laborum nisi dolore deserunt labore. Proident qui aliquip laboris eiusmod consectetur non et et culpa in. Reprehenderit dolore reprehenderit incididunt enim sint dolor. Fugiat cupidatat est excepteur dolore sit qui fugiat qui nostrud aliquip.\r\n",
      "dataDescr": "Exercitation aliqua in dolor ex ipsum elit in in laboris non laboris excepteur. Deserunt consectetur amet veniam commodo. Ipsum ullamco dolor voluptate quis id eu. Eiusmod et in nostrud dolor aute exercitation aliqua culpa laboris. Sit anim voluptate duis aute id minim aliqua non ad minim eu consequat duis commodo. In aute laborum veniam esse enim labore ex.\r\n",
      "routeDescr": "Ad excepteur Lorem cupidatat nostrud veniam aliqua et mollit. Aliquip tempor quis veniam dolore magna. Laboris officia id et in est irure minim duis deserunt id do sint anim.\r\n"
    },
    "businessType": "Beyond the Core",
    "team": [
      {
        "id": 0,
        "name": "Terra Ramsey",
        "email": "terraramsey@gynk.com",
        "fte": 0.7017
      },
      {
        "id": 1,
        "name": "Becky Lester",
        "email": "beckylester@gynk.com",
        "fte": 0.8802
      },
      {
        "id": 2,
        "name": "Marguerite Wood",
        "email": "margueritewood@gynk.com",
        "fte": 0.4757
      },
      {
        "id": 3,
        "name": "Garrett Bradford",
        "email": "garrettbradford@gynk.com",
        "fte": 0.3932
      },
      {
        "id": 4,
        "name": "Hilda Dawson",
        "email": "hildadawson@gynk.com",
        "fte": 0.2433
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Sybil Peck",
        "email": "sybilpeck@gynk.com"
      },
      {
        "id": 1,
        "name": "Benton Phillips",
        "email": "bentonphillips@gynk.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 10,
        "y2": 15,
        "y3": 29
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 20,
        "y2": 39,
        "y3": 98
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 5,
        "y2": 19,
        "y3": 51
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 4800776,
      "customerVal": 5792,
      "mvp": 121922163,
      "optimize": 7467273,
      "grow": 1130629856,
      "scale": 1141578822
    },
    "nextStepsActionPlan": "Laborum velit aute sunt elit nisi aute quis dolor minim eu. Ad reprehenderit ex ad laboris. Nostrud laborum in laboris ex. Nostrud cupidatat aute nulla ad eiusmod magna id nostrud velit. Sunt excepteur aliquip Lorem eu ad ipsum aliquip ullamco id ut laboris.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Magna ex duis aliqua proident sint veniam anim do ex duis. Duis dolor commodo duis enim. Irure dolore sint reprehenderit aliqua aliquip id proident. Mollit in eiusmod ut ex labore deserunt ullamco id nulla eiusmod.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-08-29",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  },
  {
    "uuid": "631a1e030c12534ccee6c639",
    "active": true,
    "pivot": false,
    "perish": false,
    "species": [],
    "valuePropositionName": "Value Proposition Two",
    "name": "Manglo",
    "highlightKPI": [
      "ctr",
      "gmac",
      "rr"
    ],
    "geography": "Spain",
    "channel": "Offline: Flagship store",
    "division": "Division One",
    "painPoint": "Dolore amet eu eu deserunt deserunt veniam in sit voluptate. Eu nulla labore aliquip proident officia magna ipsum occaecat velit laboris eiusmod id. Aliqua voluptate aute ullamco magna Lorem voluptate fugiat excepteur fugiat velit. Exercitation id esse veniam cillum exercitation sunt consequat excepteur. Aliqua cupidatat laboris eu sunt enim reprehenderit irure.\r\n",
    "description": "Commodo anim nisi ea nulla aute occaecat duis aute sunt eu amet. Laborum dolore non est sint dolor consequat consectetur quis ullamco eiusmod. Lorem est ex laborum velit commodo sit ullamco ea aliqua occaecat officia Lorem. Velit nostrud Lorem ex in ullamco tempor quis ad commodo veniam excepteur labore.\r\n",
    "strategy": "Ut consequat labore in aute. Dolore cillum elit dolor amet occaecat. Labore eiusmod ex nulla ea fugiat dolor quis dolor reprehenderit mollit quis exercitation laborum. Anim esse dolor voluptate non. Anim consequat laboris laboris laboris ea excepteur anim laborum esse pariatur aute. Velit in ex pariatur in proident sit sint est.\r\n",
    "keyLearnings": "Laboris consequat consequat non enim est non officia reprehenderit labore est voluptate incididunt. Id anim dolor aute laborum exercitation magna labore esse tempor sint. Ipsum esse consectetur deserunt aliqua nisi dolore officia ut dolor laborum dolore ut consectetur. Aute elit nulla ipsum in enim consectetur sunt culpa. Sit fugiat laborum ex et voluptate laboris reprehenderit elit ipsum deserunt consectetur. Amet reprehenderit nisi velit sit et duis cillum nisi et ad minim mollit aliquip ullamco. Duis duis labore duis reprehenderit ipsum tempor dolore ut ea labore.\r\n",
    "nextSteps": "Deserunt in veniam ea cupidatat nisi mollit est. Sint excepteur voluptate duis ut deserunt sunt proident nisi aliquip minim Lorem incididunt. Culpa aliqua duis ad officia velit Lorem laboris. Excepteur commodo proident excepteur qui elit ut officia sint. Aliqua velit fugiat fugiat adipisicing cillum ullamco aliqua dolor dolor occaecat cillum. Excepteur consequat exercitation aliqua quis commodo aute id. Quis nulla deserunt exercitation mollit excepteur.\r\n",
    "investment": "Ullamco eu velit nulla quis consectetur est voluptate cupidatat ut. Est est consectetur elit eiusmod id velit. Pariatur enim est proident enim qui exercitation dolor sunt velit velit cupidatat eiusmod veniam. Et est laborum est do Lorem aute est quis et ad ipsum pariatur. Ea aliqua culpa dolor in aliqua nulla voluptate elit aliquip et nostrud amet incididunt. Nulla cupidatat amet cillum tempor ea nostrud non elit commodo nisi aute. Cillum anim ullamco sint exercitation anim Lorem aliquip culpa id do dolor commodo culpa.\r\n",
    "currentStage": "mvp",
    "sovDescription": {
      "intrinsicDescr": "Excepteur quis culpa et mollit dolor excepteur ea dolor. Incididunt duis id amet amet excepteur aliqua fugiat aute officia pariatur minim sit qui. Sint consectetur occaecat nulla ut nisi reprehenderit proident nisi elit elit. Adipisicing incididunt occaecat ut sit occaecat ex aute aliquip ut duis voluptate eiusmod. Ullamco proident aliqua aute sint enim do elit deserunt.\r\n",
      "engagementDescr": "Labore sunt sint veniam aliqua cillum deserunt. Officia eiusmod ad tempor qui id tempor officia. Officia in reprehenderit Lorem in qui quis sint cupidatat labore cupidatat nisi enim nulla. Veniam Lorem ad irure deserunt id adipisicing.\r\n",
      "dataDescr": "Laborum fugiat excepteur sit dolor aliqua. Esse ex ut adipisicing mollit veniam ea qui officia non consequat dolor Lorem. Aliquip aliqua voluptate commodo commodo. Aute irure cupidatat ipsum ut aliqua consequat tempor enim esse do pariatur incididunt do. Officia nostrud esse velit deserunt voluptate aliqua aliquip. Amet et quis ex nisi cupidatat aliquip culpa sint ullamco sint. Deserunt sit consectetur officia amet occaecat officia.\r\n",
      "routeDescr": "Exercitation esse adipisicing ut et sint aliqua do aliqua. Ex qui cillum nulla do aute sunt consectetur aliqua ut nostrud. Fugiat non duis in laboris. Velit ad excepteur aute Lorem amet ullamco in ea magna enim. Reprehenderit et deserunt irure ea duis exercitation occaecat aliquip proident.\r\n"
    },
    "businessType": "Core",
    "team": [
      {
        "id": 0,
        "name": "Adriana Fletcher",
        "email": "adrianafletcher@manglo.com",
        "fte": 0.1695
      }
    ],
    "sponsors": [
      {
        "id": 0,
        "name": "Kelsey Cortez",
        "email": "kelseycortez@manglo.com"
      },
      {
        "id": 1,
        "name": "Gill Camacho",
        "email": "gillcamacho@manglo.com"
      }
    ],
    "metrics": [
      {
        "sov": "Intrinsic",
        "kpi": "nsv",
        "threshold": 8,
        "unit": "-",
        "y1": 4,
        "y2": 9,
        "y3": 27
      },
      {
        "sov": "Data",
        "kpi": "ppp",
        "threshold": "800K",
        "unit": "-",
        "y1": 8,
        "y2": 34,
        "y3": 64
      },
      {
        "sov": "Route To Scale",
        "kpi": "tam",
        "threshold": "3.4B",
        "unit": "-",
        "y1": 9,
        "y2": 29,
        "y3": 80
      }
    ],
    "files": [],
    "countries": [
      "United States"
    ],
    "valueModel": {
      "intrinsic": "secondary",
      "engagement": "secondary",
      "data": "primary",
      "routetoscale": "routetoscale"
    },
    "funding": {
      "ideation": 3781636,
      "customerVal": 739297,
      "mvp": 82931698,
      "optimize": 13865095,
      "grow": 378931963,
      "scale": 361711008
    },
    "nextStepsActionPlan": "Est officia laborum cillum enim incididunt voluptate occaecat reprehenderit elit commodo. Qui consectetur id aute nostrud. Dolore elit officia qui elit velit qui irure. Commodo in nulla sit sint pariatur occaecat.\r\n",
    "nextStepsFunding": 1000,
    "nextStepsOutcomes": "Voluptate qui mollit sunt fugiat ad ad est nostrud ex laboris. Ut esse fugiat irure ullamco. Eu fugiat incididunt laborum consequat cupidatat qui proident id quis. Adipisicing deserunt velit labore culpa sit nisi.\r\n",
    "pluggedIntoBox": false,
    "eventHistory": [
      {
        "date": "2022-02-06",
        "event": "02. Customer Validation => 03. MVP",
        "requester": null,
        "decision": "Pivot",
        "fileName": "02. Customer Validation stage review",
        "fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
      }
    ]
  }
]