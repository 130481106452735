import React from 'react';

const PipelineShapeFundingCard = ({data, valueProps}) => {

  let ideationFun = 0
  let customerValFun = 0
  let mvpFun = 0
  let optimizeFun = 0
  let growFun = 0 
  let scaleFun = 0
  let fundingAggregate = 0

  valueProps.forEach(vp => {
    ideationFun = ideationFun + vp.funding.ideation
    customerValFun = customerValFun + vp.funding.customerVal
    mvpFun = mvpFun + vp.funding.mvp
    optimizeFun = optimizeFun + vp.funding.optimize
    growFun = growFun + vp.funding.grow
    scaleFun = scaleFun + vp.funding.scale
    fundingAggregate = ideationFun + customerValFun + mvpFun + optimizeFun + growFun + scaleFun 
  })

  const getWidth = (input) => {
    //console.log(`input: ${input.toFixed(1)}, total: ${fundingAggregate.toFixed(1)}, %: ${input * 100 / fundingAggregate}`)
    return (input * 100 / fundingAggregate)/2
  }

  return (
    <tr class="btn-reveal-trigger">
      <td class="align-top">
        Funding per VP
        <br />
        (avg k$)
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage1"
            style={{ width: getWidth(ideationFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(ideationFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage2"
            style={{ width: getWidth(customerValFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(customerValFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage3"
            style={{ width: getWidth(mvpFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(mvpFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage4"
            style={{ width: getWidth(optimizeFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(optimizeFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage5"
            style={{ width: getWidth(growFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(growFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div
            class="progress-bar mars-bg-stage6"
            style={{ width: getWidth(scaleFun) }}
            role="progressbar"
          />
        </div>
        <span>{`${(scaleFun/1000000).toFixed(1)} k$`}</span>
      </td>
      <td align={"center"} class="align-top bg-200 px-2 border-start">
        <div class="d-none progress progress-white mars-pb-table">
          <div
            class="progress-bar mars-bg-blue"
            style={{ width: '25%' }}
            role="progressbar"
          />
        </div>
        <span>{`${(fundingAggregate/1000000).toFixed(1)} k$`}</span>
      </td>
    </tr>
  );
};

export default PipelineShapeFundingCard;
