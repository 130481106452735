const starter = {
  name: 'Overview',
  to: '/monitor/starter',
  icon: 'flag', //icon: ["fas", "flag"],//'star',
  exact: true
};

const add = {
  name: 'Add',
  to: '/monitor/individual/add/1',
  icon: 'plus-square',
  exact: true
};

const search = {
  name: 'Search',
  to: '/monitor/searchvp',
  icon: 'search',
  exact: true
};

const review = {
  name: 'Review',
  to: '/monitor/searchreviewvp',
  icon: 'check-double',
  exact: true
};

const mvpBench = {
  name: 'Benchmark',
  to: '/monitor/benchmark',
  icon: 'compass',
  exact: true
};
 
const swimmingLanes = {
  name: 'Stage Gate',
  to: '/monitor/stageoverview',
  icon: 'route',
  exact: true
};

export const mvpRoutes = {
  sectionTitle: 'Manage value propositions',
  routes: [
    starter, 
    add, 
    search, 
    review,
    //mvpBench,
    swimmingLanes
  ] 
};

const overview = {
  name: 'Overview',
  to: '/insight/dashboard',
  icon: 'table',
  exact: true
};

const insights = {
  name: 'Business Insights',
  to: '/insight/brandoverview/all',
  icon: 'shapes',
  exact: true
};

const benchmark = {
  name: 'Benchmark',
  to: '/insight/socialanalysis',
  icon: 'compass',
  exact: true
};


export const dciRoutes = {
  sectionTitle: 'Discover competition insights',
  routes: [overview, insights, benchmark]
};

const guides = {
  name: 'Playbooks & Guides',
  to: '/home/learn#playbooks',
  icon: 'book-open',
  exact: true
};

const tools = {
  name: 'Tools & Templates',
  to: '/home/learn#tools',
  icon: 'tools',
  exact: true
};

const communications = {
  name: 'Other Resources',
  to: '/home/learn#other',
  icon: 'comments',
  exact: true
};

export const ratRoutes = {
  sectionTitle: 'Resources and tools',
  routes: [guides, tools, communications]
}

const feedback = {
  name: 'Share your feedback',
  to: '/home/feedback',
  icon: 'comments',
  exact: true
};

export const feedbackRoutes = {
  sectionTitle: 'Feedback',
  routes: [feedback]
}

// const widgets = {
//   name: 'Widgets',
//   to: '/',
//   icon: 'magic', //'magic'
//   exact: true
// };

// const echarts = {
//   name: 'eCharts',
//   to: '/',
//   icon: 'chart-line',
//   exact: true
// };

// export const wrepRoutes = {
//   sectionTitle: 'Widget repository',
//   routes: [widgets, echarts]
// };