export const demoVPOpbject = { 
	"active": true,
	"pivot": false,
	"perish": false,
	"species": ["Cat", "Dog"],
	"name": "Default Value Proposition",
	"geography": "United States",
	"channel": "Online: Flagship store",
	"division": "No Division",
	"painPoint": "Pain Point Text",
	"description": "Description Text",
	"strategy": "Strategy Text",
	"keyLearnings": "Key Learnings Text",
	"nextSteps": "Next Steps Text",
	"investment": "This is the text field where we explain how the current investment has been used.",
	"currentStage": "customerVal",
	"sovDescription": { 
		"intrinsicDescr": "Intrinsic Description Text",
		"engagementDescr": "Engagement Description Text",
		"dataDescr": "Data Description Text",
		"routeDescr": "Route to Scale Description Text"
	},
	"stageGate": { 
		"ideation": { 
			"intrinsic": { 
				"nsv": null,
				"gmac": null,
				"cac": null,
				"ltv": null,
				"ltvcac": null,
				"ctr": null,
				"session": null,
				"bcr": null,
				"aov": null
			},
			"engagement": { 
				"rp": null,
				"tc": null,
				"rr": null,
				"nps": null
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		},
		"customerVal": { 
			"intrinsic": { 
				"nsv": null,
				"gmac": null,
				"cac": { 
					"benchmark": "",
					"target": "",
					"actual": "90",
					"comment": "3x Cost / Intent click from testing phase",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ltv": null,
				"ltvcac": null,
				"ctr": { 
					"benchmark": "",
					"target": "",
					"actual": "0.8%",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"session": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": false,
					"trend": 2
				},
				"bcr": { 
					"benchmark": "",
					"target": "",
					"actual": "4.7%",
					"comment": "Cost / Intent click from testing phase",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"aov": null
			},
			"engagement": { 
				"rp": null,
				"tc": null,
				"rr": null,
				"nps": null
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		},
		"mvp": { 
			"intrinsic": { 
				"nsv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"gmac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"cac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ltv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ltvcac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ctr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"session": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"bcr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"aov": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"engagement": { 
				"rp": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"tc": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"rr": null,
				"nps": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		},
		"optimize": { 
			"intrinsic": { 
				"nsv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"gmac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"cac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ltv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ltvcac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ctr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"session": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"bcr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"aov": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"engagement": { 
				"rp": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"tc": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"rr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"nps": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		},
		"grow": { 
			"intrinsic": { 
				"nsv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"gmac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"cac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ltv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ltvcac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": true,
					"active": true,
					"trend": 2
				},
				"ctr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"session": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"bcr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"aov": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"engagement": { 
				"rp": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"tc": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"rr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"nps": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		},
		"scale": { 
			"intrinsic": { 
				"nsv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"gmac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"cac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ltv": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ltvcac": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"ctr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"session": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"bcr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"aov": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"engagement": { 
				"rp": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"tc": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"rr": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				},
				"nps": { 
					"benchmark": "",
					"target": "",
					"actual": "",
					"comment": "",
					"modelled": false,
					"active": true,
					"trend": 2
				}
			},
			"data": { 
				"dvam": null,
				"ppp": null,
				"cda": null
			}
		}
	},
  
	"businessType": "Core (blue)",
	"team": [{ 
		"name": "John Doe",
		"email": "john@doe.com",
		"fte": "0.1"
	}],
	"sponsors": [{ 
		"name": "Jane Doe",
		"email": "jane@doe.com"
	}],
	"metrics": [{ 
		"sov": "Intrinsic",
		"kpi": "nsv",
		"threshold": "8",
		"unit": "-",
		"y1": null,
		"y2": null,
		"y3": "9"
	}, { 
		"sov": "Data",
		"kpi": "ppp",
		"threshold": "800K",
		"unit": "-",
		"y1": null,
		"y2": null,
		"y3": "800000"
	}, { 
		"sov": "Route To Scale",
		"kpi": "tam",
		"threshold": "3.4B",
		"unit": "-",
		"y1": "3.4",
		"y2": null,
		"y3": null
	}],
	"uuid": "a69dd52f-741c-4a56-a468-ad420f6c75e7",
	"files": [ ], 
	"countries": ["United States"],
	"valueModel": { 
		"intrinsic": "secondary",
		"engagement": "secondary",
		"data": "primary",
		"routetoscale": "routetoscale"
	},
	"funding": { 
		"ideation": 0,
		"customerVal": 25,
		"mvp": null,
		"optimize": null,
		"grow": null,
		"scale": null
	},
	"nextStepsActionPlan": "Next Steps Action Plan Text",
	"nextStepsFunding": 100,
	"nextStepsOutcomes": "Next Steps Outcomes Text",
	"pluggedIntoBox": false,
  "eventHistory": [{ 
		"date": "Mar 28th 2022",
		"event": "02. Customer Validation => 03. MVP",
		"requester": null,
		"decision": "Pivot",
		"fileName": "02. Customer Validation stage review",
		"fileUuid": "1c9fc080-c890-4610-a079-67651a80d1c9"
	},
  ],
}