//import VPMonitor from "../pages/monitor/VPMonitor";
//import AddVP from "../pages/monitor/AddVP";
//import SearchEditVP from "../pages/monitor/SearchEditVP";
//import ViewVP from "../pages/monitor/ViewVP";
//import ReviewVP from "../pages/monitor/ReviewVP";
import SearchReviewVP from "../pages/monitor/SearchReviewVP";
import BenchmarkVP from "../pages/monitor/BenchmarkVP";
import PdfKitPage from "../pages/monitor/PdfKitPage";

//import VPQuickGuide from "../pages/monitor/VPQuickGuide";
//import Landing from "../pages/dashboards/Landing";
import VPControlTower from "../pages/monitor/Starter/VPControlTower";
import ViewEditVP from "./../pages/monitor/ViewEditVP";

import SearchVP from "./../pages/monitor/SearchVP";
import SwimmingLanes from "../pages/monitor/SwimmingLanes";

const VPRoutes = [
  {
    collapse: true,
    path: "/vpmonitor",
    layout: "/monitor",
    name: "Control Tower",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: [      
      {
        path: "/starter",
        name: "Landing Page",
        miniName: "LP",
        component: VPControlTower,
        layout: "/monitor",
        display: false,
      },
      {
        path: "/individual/:action/:id",
        name: "Individual VP",
        miniName: "IV",
        component: ViewEditVP,
        layout: "/monitor",
      },
      // {
      //   path: "/addvp",
      //   name: "Competitive Analysis",
      //   miniName: "SA",
      //   component: AddVP,
      //   layout: "/monitor",
      // },
      {
        path: "/searchvp",
        name: "Competitive Analysis",
        miniName: "SE",
        component: SearchVP,
        layout: "/monitor",
      },
      // {
      //   path: "/:id/viewvp/:mode",
      //   name: "",
      //   miniName: "SE",
      //   component: ViewVP,
      //   layout: "/monitor",
      // },
      // {
      //   path: "/:id/reviewvp/",
      //   name: "",
      //   miniName: "SE",
      //   component: ReviewVP,
      //   layout: "/monitor",
      // },
      {
        path: "/searchreviewvp",
        name: "Competitive Analysis",
        miniName: "SA",
        component: SearchReviewVP,
        layout: "/monitor",
      },
      {
        path: "/benchmark",
        name: "Benchmark",
        miniName: "SA",
        component: BenchmarkVP,
        layout: "/monitor",
      },
      {
        path: "/stageoverview",
        name: "Stage Overview",
        miniName: "SA",
        component: SwimmingLanes,
        layout: "/monitor",
      },
      // {
      //   path: "/quickguide",
      //   name: "Competitive Analysis",
      //   miniName: "SA",
      //   component: VPQuickGuide,
      //   layout: "/monitor",
      // },
    ],
  },
  // {
  //   collapse: true,
  //   path: "/addvp",
  //   layout: "/monitor",
  //   component: AddVP,
  //   name: "Add value prop",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   logo: "whiteBox",
  //   views: []
  // },
  // {
  //   collapse: true,
  //   path: "/searchvp",
  //   layout: "/monitor",
  //   component: VPMonitor,
  //   name: "Search / Edit value prop",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   logo: "whiteBox",
  //   views: []
  // },
  {
    collapse: true,
    path: "/searchreviewvp",
    layout: "/monitor",
    component: BenchmarkVP,
    name: "Review value prop",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: []
  },
  // {
  //   collapse: true,
  //   path: "/quickguide",
  //   layout: "/monitor",
  //   component: VPQuickGuide,
  //   name: "Quick Guide",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   logo: "whiteBox",
  //   views: []
  // }
];

export default VPRoutes;
