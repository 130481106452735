export const kpiDictionary = [
  
  {
    "helper": "Custom KPI",
    "kpi": "Custom KPI",
    "description": "",
    "category": "--",
    "unit": "--",
    "min": "--",
    "max": "--"
  },
  {
    "helper": "Conversion Rate (%)",
    "kpi": "Conversion Rate (%)",
    "description": "The % of total sessions that make a transaction (single purchase VPs) or subscription (subscription VPs) to your proposition (Active Consumers).",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "CAC ($)",
    "kpi": "CAC ($)",
    "description": "Consumer Acquisition Cost. A&P Spend to acquire one consumer (e.g. single purchase or subscription). Should include all directly attributable marketing and sales costs; exlcude shared resources.",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "LTV ($)",
    "kpi": "LTV ($)",
    "description": "Lifetime Value. Average revenue a single consumer generates over the duration of their relationship with us",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "LTV:CAC (x:x)",
    "kpi": "LTV:CAC (x:x)",
    "description": "Lifetime Value:Consumer Acquisition Cost. The cost to acquire a new consumer as a ratio of average lifetime value",
    "category": "Intrinsic",
    "unit": "x:x",
    "min": "",
    "max": ""
  },
  {
    "helper": "Payback period (months)",
    "kpi": "Payback period (months)",
    "description": "The number of months it takes to earn back customer acquisition cost (i.e. until MAC per customer = CAC). This is a strong unit economics indicator in early stages when LTV calculations are still heavily assumption based.",
    "category": "Intrinsic",
    "unit": "s",
    "min": 0,
    "max": ""
  },
  {
    "helper": "AOV ($)",
    "kpi": "AOV ($)",
    "description": "Average Order Value. Average amount spent by consumers in a single order, excluding VAT",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "NSV ($)",
    "kpi": "NSV ($)",
    "description": "Net Sales Value. The revenue Mars receives from consumers after any discounts and promotions",
    "category": "Intrinsic",
    "unit": "$m",
    "min": 0,
    "max": ""
  },
  {
    "helper": "GMAC (%)",
    "kpi": "GMAC (%)",
    "description": "Profit after Discounting, COGS and Fulfilment costs, as a % of NSV",
    "category": "Intrinsic",
    "unit": "%",
    "min": "",
    "max": 1
  },
  {
    "helper": "NPS (%)",
    "kpi": "NPS (%)",
    "description": "Net Promotor Score. Measures the willingness of consumers to recommend a product or service to others. Score can vary between -100 and +100",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "Repeat Purchases (%)",
    "kpi": "Repeat Purchases (%)",
    "description": "The proportion of purchases that are made by consumers that have bought from us before [Period cut off between transactions/orders tbc based on nature of propositon]",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "Churn rate (%)",
    "kpi": "Churn rate (%)",
    "description": "Churn rate in subscription models: # subscribers that have cancelled their subscription as a % of total subscribers\n Churn rate in transactional models: # lapsed transactional consumers who have purchased within last 12 months, but have not done so in over the previous 3 months, as a % of active consumers over a certain time period.",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "Referral rate (%)",
    "kpi": "Referral rate (%)",
    "description": "The # of transactional purchases or subscriptions from referral as a % of total transactional purchases or subscriptions",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "# NEW PET PARENT REGISTRATIONS",
    "kpi": "New pet parent registrations (k)",
    "description": "The number of new individual registrants who create a Mars profile and submit their contact details to us",
    "category": "Data",
    "unit": "k",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# NEW PET PROFILES CREATED",
    "kpi": "New pet profiles created (k)",
    "description": "The number of pets that are registered by users who have created a Mars profile for them",
    "category": "Data",
    "unit": "k",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# PET PARENT PROFILES",
    "kpi": "Pet parent profiles (k)",
    "description": "The total number of individual registrants who have created a Mars profile and submitted their contact details to us, as of the end of the period",
    "category": "Data",
    "unit": "k",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PROFILE REGISTRATION RATE (%)",
    "kpi": "Profile registration rate (%)",
    "description": "The percentage of unique visitors to channel that register their details / create a profile",
    "category": "Data",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "# INTIMATE PET PARENT RELATIONSHIPS",
    "kpi": "Intimate pet parent relationships (#)",
    "description": "TBD - work on single definition ongoing",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# NEW CONSUMERS",
    "kpi": "New consumers (#)",
    "description": "The number of new consumers who start a subscription OR make a transactional purchase in the given period",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF SUBSCRIBERS",
    "kpi": "Subscribers (#)",
    "description": "The number of consumers with a subscription that pay periodically (e.g., every week/month)",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PLATFORM REACH (%)",
    "kpi": "Platform reach (%)",
    "description": "The proportion of our target market that visit our platforms on a monthly basis [Target market defined as number of individuals in each market that we could target]",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "TOTAL # OF SESSIONS",
    "kpi": "Number of sessions (#)",
    "description": "Total number of visits to the site — including both new and repeat visits.",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# UNIQUE VISITORS",
    "kpi": "Unique visitors (#)",
    "description": "The number of unique users of the digital channel on a monthly basis",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# UNIQUE VISITORS WITH A MARS ID",
    "kpi": "Unique visitors with a Mars ID (#)",
    "description": "The # of unique visitors to a channel that we can match to a Mars ID",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "% ORGANIC TRAFFIC",
    "kpi": "Organic traffic (%)",
    "description": "% of unique users of the digital channel on a monthly basis that arrive through organic search",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "% OTHER TRAFFIC (PAID/UNPAID)",
    "kpi": "Other traffic (Paid/Unpaid) (%)",
    "description": "% of unique users of the digital channel on a monthly basis that arrive through other means",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "BOUNCE RATE (%)",
    "kpi": "Bounce rate (%)",
    "description": "The percentage of single-page sessions in which there was no interaction with the page. A bounced session has a duration of 0 seconds.",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "STORE FOOTFALL",
    "kpi": "Store footfall (#)",
    "description": "The number of visitors that enter your physical stores every month (non-unique - e.g. an individual visiting a store 3 times counts as 3 visits)",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "QUALIFIED TRAFFIC VET/PRO/SPT RATE (%)",
    "kpi": "Qualified traffic vet/pro/spt rate (%)",
    "description": "The percentage of site visitors referred from Professional/Speciality channel partner sites",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "AVERAGE SESSION DURATION",
    "kpi": "Average session duration (minutes)",
    "description": "The length of time consumers spend on your online store, expressed as an average",
    "category": "Engagement",
    "unit": "minutes",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF RECOMMENDATIONS",
    "kpi": "Recommendations (#)",
    "description": "The total number of recommendation sent",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "TRANSACTION CONVERSION RATE (%)",
    "kpi": "Transaction conversion rate (%)",
    "description": "The percentage of number of sessions to channel that make a transaction (single purchase) to your proposition (Active Consumers).\n \n\n This KPI captures transacting consumers (single purchase), not subscribers - they fall under the Subscription Conversion Rate",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "SUBSCRIPTION CONVERSION RATE (%)",
    "kpi": "Subscription conversion rate (%)",
    "description": "The percentage of number of sessions to channel that make a subscription to your proposition (Active consumers)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "eCOMMERCE CONVERSION RATE (%)",
    "kpi": "Ecommerce conversion rate (%)",
    "description": "The percentage of number of sessions to a channel that results in a purchase to your propositions (Active consumers). All single purchase + 1st subscription purchase (excluding re-current orders).",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "# OF TRIALISTS",
    "kpi": "Trialists (#)",
    "description": "Number of consumers who have signed up/have completed the final stage of the sign up process (entered CC details) AND have had no orders dispatched OR have only had discounted orders",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF TRANSACTING CONSUMERS",
    "kpi": "Transacting consumers (#)",
    "description": "Number of consumers who have made a transaction. This excludes purchases of subscriptions",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF ORDERS",
    "kpi": "Orders (#)",
    "description": "Number of orders: The number of orders placed in a given period. These include subscriptions and transactions.",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "ADD-TO-CART RATE (%)",
    "kpi": "Add-to-cart rate (%)",
    "description": "The number of visitors to your site that place products into their shopping cart (or basket)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "CART ABANDONMENT RATE (%)",
    "kpi": "Cart abandonment rate (%)",
    "description": "The number of visitors to your site that have placed one or more products into their shopping cart but left the site before completing the purchase and checking out",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "UNITS SOLD",
    "kpi": "Units sold (#)",
    "description": "Total number of units from all validated orders",
    "category": "Intrinsic",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PRESCRIBER CONVERSION RATE (%)",
    "kpi": "Prescriber conversion rate (%)",
    "description": "The number of transactions / recommendations sent from prescriber",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "# ACTIVE CONSUMERS",
    "kpi": "Active consumers (#)",
    "description": "The number of consumers who have made a purchase within the last 3 months (including any month with a live subscription)",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# CHURNED SUBSCRIBERS",
    "kpi": "Churned subscribers (#)",
    "description": "The number of susbcribers who have cancelled their subscription in a given period",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# LAPSED CONSUMERS",
    "kpi": "Lapsed consumers (#)",
    "description": "Rolling annual figure - number of transactional consumers who have purchased within last 12 months, but have not done so in over the previous 3 months. After 12 months of no purchases, the user is no longer considered to be lapsed.",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "CONSUMER LIFETIME",
    "kpi": "Consumer lifetime (months)",
    "description": "The number of months where you are continuously categorised as an Active Consumer. This measures the tenure of a consumer.",
    "category": "Engagement",
    "unit": "months",
    "min": 0,
    "max": ""
  },
  {
    "helper": "AVG CONSUMER LIFETIME (TENURE)",
    "kpi": "Average consumer lifetime (months)",
    "description": "The average number of months that consumer are continuously categorised as an Active Consumer",
    "category": "Engagement",
    "unit": "months",
    "min": 0,
    "max": ""
  },
  {
    "helper": "ACTIVE CUSTOMER LIFETIME FOR LTV",
    "kpi": "Active customer lifetime for ltv",
    "description": "The average number of months that consumer is expected to be considered active based on churn rate performance. ",
    "category": "Engagement",
    "unit": "months",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# TOTAL LOST CONSUMERS",
    "kpi": "Total lost consumers (#)",
    "description": "Total number of churned subscribers and lapsed transactional consumers in a given period [sum of # CHURNED SUBSCRIBERS and # LAPSED CONSUMERS]",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "SUBSCRIPTION CHURN RATE",
    "kpi": "Subscription churn rate (%)",
    "description": "The number of subscribers that have cancelled their subscription as a % of total subscribers",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "LAPSING CONSUMER RATE",
    "kpi": "Lapsing consumer rate (%)",
    "description": "The number of lapsed transactional consumers as a proportion of active consumers over a certain time period. Lapsing transactional consumers are defined as those that have not made a purchase in 3 months (see # Lapsed Consumers in KPI Dictionary)",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "NET NEW CONSUMERS",
    "kpi": "Net new consumers (#)",
    "description": "The number of new consumers acquired over a month less the number of lapsed consumers over the same period",
    "category": "Engagement",
    "unit": "#",
    "min": "",
    "max": ""
  },
  {
    "helper": "NET NEW SUBSCRIBERS",
    "kpi": "Net new subscribers (#)",
    "description": "Share of new subscription as % of new consumers recruited",
    "category": "Engagement",
    "unit": "#",
    "min": "",
    "max": ""
  },
  {
    "helper": "ACTIVE PRESCRIBER RATE (%)",
    "kpi": "Active prescriber rate (%)",
    "description": "Currently in RC FGS, the active prescriber (split by VET/PRO/SPT) means who ever sent out at least 2 recommendations to pet owners in a period",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "ACTIVE VET/PRO/SPT",
    "kpi": "Active vet/pro/spt (#)",
    "description": "Number of active VET prescribers within your D2C project during this period",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "AVERAGE DURATION OF SUBSCRIPTION CLUB",
    "kpi": "Average duration of subscription club (# of refills)",
    "description": "The average duration ( mesaured by number of refills ) of custmer staying in club subscription",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "AVERAGE DURATION OF SUBSCRIPTION AUTOSHIP",
    "kpi": "Average duration of subscription autoship (# of refills)",
    "description": "The average duration ( mesaured by number of refills ) of custmer staying in club autoship",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "CLTV AUTOSHIP",
    "kpi": "Consumer LTV autoship ($)",
    "description": "MVP definition : avg. revenue from each subscriber to autoship",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "CLTV CLUB",
    "kpi": "Consumer LTV club ($)",
    "description": "MVP definition : avg. revenue from each subscriber to club",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "% PRESCRIBER REWARD LEVEL",
    "kpi": "Prescriber reward level (%)",
    "description": "Average % money (or equivalent) back we give to our prescribers for each Petowner transaction (single purchases AND subscriptions)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "REGISTERED-TO-PURCHASE CONVERSION RATE (%)",
    "kpi": "Registered-to-purchase conversion rate (%)",
    "description": "The percentage of registered consumers that make a transaction/subscriber to your proposition (Active consumers)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "CPA",
    "kpi": "Cost per acquisition (campaign) ($)",
    "description": "Cost Per Acquisition: This metric is a campaign metric and will be used to measure the campaign cost for acquisition as it's defined for a campaign",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "HUB CONVERSION RATE",
    "kpi": "Hub conversion rate (%)",
    "description": "The percentage of sessions from the hub that make a purchase (transaction or subscription)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "PAID MEDIA CONVERSION RATE",
    "kpi": "Paid media conversion rate (%)",
    "description": "The percentage of sessions from paid media that make a purchase (transaction or subscription)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "SEO CONVERSION RATE",
    "kpi": "SEO conversion rate (%)",
    "description": "The percentage of sessions from seo / organic that make a purcahse (transaction or subscription)",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "ORDER SPLIT",
    "kpi": "Order split (%)",
    "description": "The split of orders in different value proposition types : share in single purchase, in 1st autoship, in recurring autoship, in 1st club and in recurring club.",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "OFFER LEVEL CONVERSION RATE SINGLE PURCHASE",
    "kpi": "Offer level conversion rate - Single purchase (%)",
    "description": "Attractiveness of single purchase proposal on 1st conversion",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "OFFER LEVEL CONVERSION RATE CLUB",
    "kpi": "Offer level conversion rate - Club (%)",
    "description": "Attractiveness of club proposal on 1st conversion",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "OFFER LEVEL CONVERSION RATE AUTOSHIP",
    "kpi": "Offer level conversion rate - Autoship (%)",
    "description": "Attractiveness of autoship proposal on 1st conversion",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "ARPC",
    "kpi": "Average revenue per customer ($)",
    "description": "Average Revenue per Consumer: Average amount spent by consumers over the course of a year",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "REPEAT PUCHASE FREQUENCY",
    "kpi": "Repeat puchase frequency (#)",
    "description": "Number of times that a consumer makes repeat purchases over the course of a single year",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# REPEAT PURCHASERS",
    "kpi": "Repeat purchasers (#)",
    "description": "The number of consumers buying more than once in a year",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "GMAC PER TRIALIST",
    "kpi": "GMAC per trialist ($)",
    "description": "Profit per Subscriber accounting for Total Revenue, Discounting, COGS and Fulfilment",
    "category": "Intrinsic",
    "unit": "$",
    "min": "",
    "max": ""
  },
  {
    "helper": "GMAC PER SUBSCRIBER",
    "kpi": "GMAC per subscriber ($)",
    "description": "Profit per Subscriber accounting for Total Revenue, Discounting, COGS and Fulfilment",
    "category": "Intrinsic",
    "unit": "$",
    "min": "",
    "max": ""
  },
  {
    "helper": "GMAC PER ORDER",
    "kpi": "GMAC per order ($)",
    "description": "The unit economics of each individual order we fulfill - identifying the profitability of an order relative to all the associated variable costs of the order",
    "category": "Intrinsic",
    "unit": "$",
    "min": "",
    "max": ""
  },
  {
    "helper": "COGS (EX FULFILMENT)",
    "kpi": "COGS (excl. fulfilment) ($)",
    "description": "All of the costs and expenses directly related to the production of goods, and costs incurred after the factory delivers to the market warehouse - this includes logistics to customers, copacking, warehousing, etc [Could be zero for D2C proposition if delivery from factory is directly to D2C fulfilment centre] Excludes indirect costs such as overhead and sales & marketing. Per ton.",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "FULFILMENT COSTS",
    "kpi": "Fulfilment costs ($)",
    "description": "All D2C costs incurred to deliver an order to an individual customer - this includes VAT, fulfilment centre storage, picking and packing, inventory management, order processing and delivery costs",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "SUBSCRIPTION REVENUE ($)",
    "kpi": "Subscription revenue ($)",
    "description": "The amount of revenue that is generated from subscriptions",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "SUBSCRIPTION REVENUE (%)",
    "kpi": "Subscription revenue (%)",
    "description": "Percentage of NSV generated through D2C subscription",
    "category": "Intrinsic",
    "unit": "%",
    "min": 0,
    "max": 1
  },
  {
    "helper": "AVERAGE ITEM VALUE",
    "kpi": "Average item ($)",
    "description": "The average revenue that is earned per item",
    "category": "Intrinsic",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  // KPI DICTIONARY 2.0 STARTS HERE
  {
    "helper": "PPR",
    "kpi": "Pet Parent Registrations (#)",
    "description": "The number of new individual registrants who create a Mars profile and submit their contact details to us",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PPC",
    "kpi": "Pet Parent Profile Created (#)",
    "description": "The number of pets that are registered by users who have created a Mars profile for them",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PRR",
    "kpi": "Profile Registration Rate (#)",
    "description": "The percentage of unique visitors to channel that register their details / create a profile",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "NC",
    "kpi": "New Consumers (#)",
    "description": "The number of new consumers who start a subscription OR make a transactional purchase in the given period",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "Subs",
    "kpi": "Subscribers (#)",
    "description": "The number of consumers with an active subscription that pay periodically (e.g., every week/month, quarters, etc)",
    "category": "Data",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PLATFORM REACH",
    "kpi": "Platform Reach (%)",
    "description": "The proportion of our target market that visit our platforms on a monthly basis [Target market defined as number of individuals in each market that we could target]",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": ""
  },
  {
    "helper": "Sessions",
    "kpi": "Total Sessions (#)",
    "description": "Total number of visits to the site — including both new and repeat visits.",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "UNV",
    "kpi": "Unique Visitors (#)",
    "description": "The number of unique users of the digital channel on a monthly basis.",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "UNVM",
    "kpi": "Unique Visitors w/ MARS ID (#)",
    "description": "The # of unique visitors to a channel that we can match to a Mars ID",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "OT",
    "kpi": "Organic Traffic (%)",
    "description": "% of unique users of the digital channel on a monthly basis that arrive through organic search",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": ""
  },
  {
    "helper": "OTT",
    "kpi": "OTher Traffic (PAID/UNPAID) (%)",
    "description": "% of unique users of the digital channel on a monthly basis that arrive through other means",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": ""
  },
  {
    "helper": "OTT",
    "kpi": "OTher Traffic (PAID/UNPAID) (%)",
    "description": "% of unique users of the digital channel on a monthly basis that arrive through other means",
    "category": "Engagement",
    "unit": "%",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF TRIALISTS",
    "kpi": "Number of Trialists (#)",
    "description": "Number of consumers who have signed up/have completed the final stage of the sign up process (entered CC details) AND have had no orders dispatched OR have only had discounted orders.",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# OF TRANSACTING CONSUMERS",
    "kpi": "Number of transaction consumers (#)",
    "description": "Number of consumers who have made a transaction within the last 3 months. This excludes purchases of subscriptions",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  },
  {
    "helper": "# LAPSED TRANSACTIONAL CONSUMERS",
    "kpi": "Number of lapsed transaction consumers (#)",
    "description": "Number of transacting consumers, in given period, who have not purchased over the last 3 months.",
    "category": "Engagement",
    "unit": "#",
    "min": 0,
    "max": ""
  }, 
  {
    "helper": "AVERAGE BASKET VALUE",
    "kpi": "Average Basket Value ($)",
    "description": "Average Order : Average amount spent by consumers in a single order, including VAT.",
    "category": "Engagement",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "AVERAGE REVENUE PER CUSTOMER (ARPC) (LTV)",
    "kpi": "Average Revenue per customer",
    "description": "Average Revenue per Consumer: Average amount spent by consumers over the course of expected lifetime",
    "category": "Value Generation",
    "unit": "$",
    "min": 0,
    "max": ""
  },
  {
    "helper": "PERIODIC PURCHASE FREQUENCY",
    "kpi": "Periodic Purchase Frequency",
    "description": "Average # orders per active consumers on a given period/month",
    "category": "Value Generation",
    "unit": "#",
    "min": 0,
    "max": ""
  }




]