import React from "react";
import * as api from "../../API/IndividualVpAPI";
import moment from "moment"
import axios from 'axios';
import { countryList } from "../../Lists/countryList"; 
import {
  Button,
  Modal,
  Spinner,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import { useOktaAuth } from "@okta/okta-react";

export const SaveEditBar = ({vpHandler}) => {
  var md5 = require('md5'); 
  const { authState, oktaAuth } = useOktaAuth();
  const [fileObj, setFileObj] = React.useState({});
  const [checked, setChecked] = React.useState(false);
  const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(!modal);
  const [modal2, setModal2] = React.useState(false);
    const toggleModal2 = () => setModal2(!modal2);

  //console.log(`vphandler: ${JSON.stringify(vpHandler.get)}`)

  const onClickRequestPPP = () => {
    let vpObject = vpHandler.get
    if(vpObject.name === "" || vpObject.division === "" || vpObject.geography === ""){
      let string = "Both Country and Division are required for a PPP review."
      api.notify(string, vpHandler.ref, "danger")
    }
    else toggleModal2()
  }

  let list = {
    "ideation": " 01. Ideation",
    "customerVal": "02. Customer Validation",
    "mvp": "03. MVP",
    "optimize": "04. Optimize",
    "grow": "05. Grow",
    "scale": "06. Scale",
    "persevere": "Progress",
    "perish": "Perish",
    "pivot": "Pivot"
  }

  const nextStage = () => {
    let current = vpHandler.get.currentStage
    if(current === "ideation") return "customerVal"
    if(current === "customerVal") return "mvp"
    if(current === "mvp") return "optimize"
    if(current === "optimize") return "grow"
    if(current === "grow") return "scale"
    else return "scale"
  }
  
  const resourceForm = [
    {
      title:'Name',
      name:'name',
      placeholder:'Building the Box',
      type:'text'
    },
    {
      title:'Version Number',
      name:'version',
      placeholder:'Version 1.0',
      type:'text'
    },
    {
      title:'Description',
      name:'description',
      placeholder:'To be used by the Mars Team.',
      type:'text'
    }
  ]
  
  const buttonText = () => {
    let action = vpHandler.action
    if(action === "add") return "Add"
    if(action === "view") return "Edit"
    if(action === "edit"){ 
      if(md5(JSON.stringify(vpHandler.get)) === vpHandler.hash) return "Cancel"
      else return "Save"
    }
  }
  
  const lookUpEmail = {
    "ideation": [{name: "Anna Dibner", email: "anna.dibner@royalcanin.com"},{name: "Danson Huang", email: "danson.huang@royalcanin.com"}],
    "customerVal": [{name: "Anna Dibner", email: "anna.dibner@royalcanin.com"},{name: "Elisa Brami", email: "elisa.brami@royalcanin.com"}],
    "mvp": [{name: "Elisa Brami", email: "elisa.brami@royalcanin.com"}],
    "optimize": [{name: "Elisa Brami", email: "elisa.brami@royalcanin.com"}],
    "grow": [{name: "Danson Huang", email: "danson.huang@royalcanin.com"}],
    "scale": ["wessels.rene@gmail.com"] ,
  }
  
  const europeEmail = [{name: "Deirdre McSharry", email: "deirdre.mcsharry@effem.com"}]

  const GetEmail = (index) => {
    if(!vpHandler.get.sponsors > 0) return lookUpEmail[vpHandler.currentStage];
    else return vpHandler.get.sponsors[index]
  }


  const SubmitFeedback = (emailObj) => {
    let testEmailObj = {"vpName":"Test VP Name","recipients":[{"name":"Douwe","email":"dafmulder@gmail.com"}, {"name":"Rene","email":"wessels.rene@gmail.com"}]}

    axios({
      url: process.env.REACT_APP_API,
      //url: "https://d2cx.dev/graphql",
      method: "post",
      data: {
        query: `query SendEmailSponsor($vpApproval: VPApprovalEmailInputType) {
          SendEmailSponsor(vpApproval: $vpApproval)
        }`,
        //variables: { email: _email, inviteName: vpHandler.get.name },
        variables: {vpApproval: { vpName: emailObj.vpName, recipients: emailObj.recipients }},
      },
    })
      .then((result) => {
        //api.notify("Your feedback has been submitted.", inputEl, "success" )
        //openInNewTab(result.data.data.GetDownload);
        //setLearnFiles( [...result.data.data.GetLearnFile] );
      })
      .catch((err) => console.log(err));
  };



  const getAllSponsorEmail = () => {
    let arr = []
    let obj = {}
    vpHandler.get.sponsors.forEach(sponsor => {
      obj.name = sponsor.name
      obj.email = sponsor.email
      arr.push({...obj})
    })
    return arr
  }

  const submitForReview = () => {
    let readyCheck = false;
    let feedbackObj = {"vpName": vpHandler.get.name, recipients: []}
    if(vpHandler.get.geography!=="TestCountry"){
      if(vpHandler.get.division === "Royal Canin"){
        readyCheck = true
        feedbackObj.recipients = lookUpEmail[vpHandler.get.currentStage]     
        //SubmitFeedback(obj);
      }
      if(vpHandler.get.division === "Pet Nutrition"){
        let countryObj = countryList.find((country) => country.Name === vpHandler.get.geography)
        //console.log(countryObj)
        if(countryObj !== undefined && countryObj.Region !== "Europe") {
          readyCheck = true;
          feedbackObj.recipients = europeEmail       
          //SubmitFeedback(obj);
        }
        else {
          //console.log(vpHandler.get.sponsors)
          if(vpHandler.get.sponsors.length > 0){
            readyCheck = true;
            feedbackObj.recipients = getAllSponsorEmail()          
            //SubmitFeedback(obj);
          }
          else api.notify("A sponsor is required before requesting review", vpHandler.ref, "danger");
        }
      }
    }
    else if(vpHandler.get.geography==="TestCountry"){
      feedbackObj.recipients = [{name: "D2CX Help", email: "d2cxhelp@effem.com"}]   
      readyCheck = true;
      //SubmitFeedback(obj);
    }

    if(readyCheck){
      let vpObject = vpHandler.get;
      api.createPDF(vpHandler.get.uuid, false, vpHandler, (out) => { 
        let obj = {
          date: moment().format("MMM Do YYYY"), 
          event: `${list[vpHandler.get.currentStage]} => ${list[nextStage()]}`, 
          decision: "Pending", 
          requester: authState.idToken.claims.name,
          fileName: `${list[vpHandler.get.currentStage]} stage review`, 
          fileUuid: out.uuid,
          fileType: out.mime
        }
        if(vpObject.active){
          vpObject.active = false
          vpObject.eventHistory.push(obj)
          vpHandler.set({...vpObject})
          api.notify("VP submitted for review", vpHandler.ref, "success")
          api.updateVP(vpHandler)
          SubmitFeedback(feedbackObj);
        }
      })
      toggleModal2();
    }
    else api.notify("Both Country and Channel are required for a PPP review.", vpHandler.ref, "danger")
  }
  
  const submitButton = () => {
    if(vpHandler.get.active) return "Submit for approval"
    else return "VP approval pending"
  }

  const isTeamMember = () => {
    let vpObject = vpHandler.get
    let emailArray = []
    vpObject.team.forEach(member => emailArray.push(member.email))
    let teamMemberFound = emailArray.find(item => item === authState.accessToken.claims.sub)
    if(!!teamMemberFound) return true
    else return false
  }

  const archiveVP = () => {
    let vpObject = vpHandler.get
    vpObject.archived = true
    vpHandler.set({...vpObject})
    api.notify("VP has been archived", vpHandler.ref, "success")
    api.updateVP(vpHandler)
    toggleModal()
  }
  
  const icon = () => {
    let action = vpHandler.action
    if(minWidth()){
      if(action === "add") return "far fa-check-square mr-2"
      if(action === "view") return "far fa-edit mr-2"
      if(action === "edit"){ 
        if(md5(JSON.stringify(vpHandler.get)) === vpHandler.hash) return "far fa-window-close mr-2"
        else return "far fa-check-square mr-2"
      }
    }
    else return ""
  }


  const saveVp = () => {
    let action = vpHandler.action
    if(action === "add") {
      let vpObject = vpHandler.get
      vpObject.createdBy = authState.idToken.claims.name
      vpHandler.set({...vpObject})
      if(vpHandler.get.name !== "") api.addVP(vpHandler)
      else api.notify("Insert name before saving.", vpHandler.ref, "danger")}
    if(action === "view") {
      setChecked(true)
      vpHandler.history.push(`/monitor/individual/edit/${vpHandler.get.uuid}`)

    }
    if(action === "edit") {
      if(md5(JSON.stringify(vpHandler.get)) === vpHandler.hash) {
        api.notify("No changes were made.", vpHandler.ref, "success")
        setChecked(false)

        vpHandler.history.push(`/monitor/individual/view/${vpHandler.get.uuid}`)
      }
      else api.updateVP(vpHandler)
    }

  }  

  const switchAction = () => {
    let action = vpHandler.action
    // if(action === "add") {
    //   if(vpHandler.get.name !== "") api.addVP(vpHandler)
    //   else api.notify("Insert name before saving.", vpHandler.ref, "danger")}
    if(action === "view") {
      setChecked(true)
      vpHandler.history.push(`/monitor/individual/edit/${vpHandler.get.uuid}`)
    }

    if(action === "edit") {
        setChecked(false)
        vpHandler.history.push(`/monitor/individual/view/${vpHandler.get.uuid}`)
    }
  }  

  // function sleep(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  // const openInNewTab = (url) => {
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  //   if (newWindow) newWindow.opener = null
  // }

  const submitColor = () => {
    if(vpHandler.get.active) return "mars-button"
    else return "mars-button-inactive"
  }

  const minWidth = () => {
    //const { innerWidth: width, innerHeight: height } = window;
    if(window.innerWidth < 400){
      return false}
    else return true
  }

  const getSaveChangesButton = () => {
    if(vpHandler.get.action === "edit"){
      if(md5(JSON.stringify(vpHandler.get)) === vpHandler.hash) return true
      else return false
    }
    else return true
  }

  return(
    //localStorage.getItem("userGroup").includes("admin") &&
      <div 
        class="row mb-2 pb-1 pt-2 col p-0 m-0 bg-white mt--1"
        style={{ position: "sticky", top: "62px", zIndex: "100" }} //old 109px
      >
        <Modal
          className="modal-dialog-centered"
          isOpen={modal}
          toggle={toggleModal}
        >
          <div class="modal-header fs--3 fw-900">
            <div className="modal-title mars-blue">
              Remove Value Proposition
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <FormGroup>
                <label
                  className="fs--2"
                >
                  Are you sure you want to remove {`${vpHandler.get.name}`}? <br/> This action can not be undone.
                </label>
              </FormGroup>     
            </Form>
          </div>
          <div className="modal-footer">
            <div class="pr-1">
              <Button onClick={e=>archiveVP()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: 'red', fontSize: '11px' }}>
                Remove
              </Button>
            </div>
            <div class="pr-1">
              <Button onClick={e=>toggleModal()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={modal2}
          toggle={toggleModal2}
        >
          <div class="modal-header fs--3 fw-900">
            <div className="modal-title mars-blue">
              Request PPP Review
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal2}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <FormGroup>
                <label
                  className="fs--2"
                >
                  Are you sure you want to request a PPP review for {`${vpHandler.get.name}`}?
                </label>
              </FormGroup>     
            </Form>
          </div>
          <div className="modal-footer">
            <div class="pr-1">
              <Button onClick={e=>submitForReview()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: 'red', fontSize: '11px' }}>
                Request
              </Button>
            </div>
            <div class="pr-1">
              <Button onClick={e=>toggleModal2()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        <div class="">          
          {vpHandler.action !== "add" && <>
          <div class="btn mars-button-add btn-sm mb-1 px-0 fs--2"   > 

            <div class="toggle-switch">
              <input 
                checked={checked}
                type="checkbox" 
                onChange={e=>switchAction()} 
                class="toggle-switch-checkbox" 
                name="toggleSwitch" 
                id="toggleSwitch" 
              />
              <label class="toggle-switch-label" for="toggleSwitch">
                <span class="toggle-switch-inner"></span>
                <span class="toggle-switch-switch"></span>
              </label>
            </div>

            </div>
            {md5(JSON.stringify(vpHandler.get)) !== vpHandler.hash &&
            <button class={`btn mars-button btn-sm ml-2 mb-1 fs--2`} type="button" onClick={e=>saveVp()}> 
              <span class={`${minWidth() ? "far fa-check-square mr-2" : ""}`}/>
              Save Changes
            </button>}
              <button class="btn mars-button btn-sm ml-2 mb-1 fs--2" type="button" onClick={console.log('save to pdf')}> 
                <span class={`${minWidth() ? "far fa-file-pdf mr-2" : ""}`}/>
                Export to PDF
              </button>
            </>
          }
        </div>
        {vpHandler.action === "add" &&
          <button class={`btn mars-button btn-sm ml-2 mb-1 fs--2`} type="button" onClick={e=>saveVp()}> 
            <span class={`${minWidth() ? "far fa-check-square mr-2" : ""}`}/>
            Add VP
          </button>}
        {(vpHandler.action !== "add" && localStorage.getItem("customUserGroup").includes("admin")) &&
          <div class="ml-auto mt-1">
            <button class="btn mars-button-remove btn-sm ml-1 mb-1 fs--2" type="button" onClick={e=>toggleModal()}>
              <span class={`${minWidth() ? "far fa-trash-alt mr-2" : ""}`}/>
              Remove Value Proposition
            </button>
          </div>
        }
        {(!localStorage.getItem("customUserGroup").includes("admin")) &&
          <div class="ml-auto">
          </div>
        }
        {(vpHandler.action !== "add" && vpHandler.get.currentStage !== "scale" && isTeamMember()) &&
          <div class="ml-1 mt-1">
            <button class={`btn btn-sm ml-1 mb-1 fs--2 ${submitColor()}`} type="button" onClick={e=>onClickRequestPPP()}>
              <span class={`${minWidth() ? "far fa-arrow-alt-circle-right mr-2" : ""}`}/>
              {submitButton()}
            </button>
          </div>
        }
        
      </div>
        
  )}

  
  // const submitForReview = () => {
  //   console.log(`div; ${vpHandler.get.division}`)
  //   if(vpHandler.get.sponsors.length > 0 || vpHandler.get.division === "Royal Canin")
  //   {
  //     toggleModal2()
  //     let vpObject = vpHandler.get
  //     if(vpHandler.get.sponsors > 0){
  //       vpHandler.get.sponsors.foreach((sponsor) => {
  //         console.log(`submit PN: ${sponsor.email}`)
  //         SubmitFeedback(sponsor.email); //name //email
  //       })
  //     } 
  //     console.log(`submit RC: ${lookUpEmail[vpHandler.currentStage]}`)
  //     SubmitFeedback(lookUpEmail[vpHandler.currentStage]);
      

  //     api.createPDF(vpHandler.get.uuid, false, vpHandler, (out) => { 
  //       let obj = {
  //         date: moment().format("MMM Do YYYY"), 
  //         event: `${list[vpHandler.get.currentStage]} => ${list[nextStage()]}`, 
  //         decision: "Pending", 
  //         fileName: `${list[vpHandler.get.currentStage]} stage review`, 
  //         fileUuid: out.uuid,
  //         fileType: out.mime
  //       }
  //       if(vpObject.active){
  //         vpObject.active = false
  //         console.log(`obj: ${JSON.stringify(obj)}`)
  //         vpObject.eventHistory.push(obj)
  //         vpHandler.set({...vpObject})
  //         api.notify("VP submitted for review", vpHandler.ref, "success")
  //         api.updateVP(vpHandler)
  //       }
  //     })
  //   }
  //   else api.notify("A sponsor is required before requesting review", vpHandler.ref, "danger");
  //     //if(vpObject.active){
  //     //vpObject.active = false
      
  //   //}
  // }
  