import React from "react";
// import { DivOverlay } from "react-leaflet";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';

import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { CharacterLimitObject } from "./CharacterLimitObject";
// import * as api from "./../../API/IndividualVpAPI";


export const Proposition = ({vpHandler}) => {

  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  // const [value, setValue] = React.useState("**Hello world!!!**");
  const [selectedTab, setSelectedTab] = React.useState("preview");

  const handleChange = (e, handle) => {
    let vpObject = vpHandler.get
    if(vpObject[handle].length <= vpHandler.characterLimitObject[handle]) vpObject[handle] = e 
    else if(e.length <= vpHandler.characterLimitObject[handle]) vpObject[handle] = e
    vpHandler.set({...vpObject})
  }
  
  React.useEffect(() => {
    if(vpHandler.action === "view" || vpHandler.action === "review") setSelectedTab("preview")
    else setSelectedTab("write")
  }, [vpHandler.action]);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });
  

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Proposition</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
        <div class="p-card rounded fs--2">
          <p>The fields and their explanation:
          </p>
          <ul>
            <li>Addressed Pain Point → Describe the target audience and the pain point / unmet need that this proposition addresses.</li>
            <li>Value proposition summary → Provide a description of the value proposition, including solution summary, products & services offered, customer journey, and (if relevant) content and community strategy.</li>
            <li>USP and Differentiation → Describe the unique selling point(s) of this proposition and its competitive advantage(s) against other players in this space.</li>
          </ul>
        </div>
      </div>
      <div class={`${hide ? "collapse" : ""} card-body`}>
        <div class="min-vh-30  fs--2">
          <p>Select the tab for input on each respective proposition part</p>
          <div class="tab">
            <button 
              style={{ backgroundColor: `${selected === "pain" ? "#dddddd" : ""}` }} 
              onClick={e => setSelected("pain")}
            >
              Addressed pain point
            </button>
            <button 
              style={{ backgroundColor: `${selected === "summary" ? "#dddddd" : ""}` }} 
              onClick={e => setSelected("summary")}
            >
              Value proposition summary
            </button>
            <button 
              style={{ backgroundColor: `${selected === "usp" ? "#dddddd" : ""}` }} 
              onClick={e => setSelected("usp")}
            >
              USP and differentiation
            </button>
          </div>
          <div id="pain" class={`pt-2 ${selected === "pain" ? "" : "collapse"}`}>
            <div className="">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.painPoint}
                onChange={e => handleChange(e, "painPoint")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              /> 
              <CharacterLimitObject vpHandler={vpHandler} handle="painPoint"/> 

              </div>
            {// <div id="textPain">Engaged segment of pet parents who want to be proactive about giving their pet (dog) the best care possible across all health needs, using trusted natural products that “taste good and do good” for their pet.
              
            //   <ul>
            //     <li>Overwhelmed – There’s so much to learn so I can stay on top of keeping my pet healthy.  I want to take a proactive role in my pet’s health & wellbeing and not suddenly worry when it’s too late. But I simply don’t know where to start or how to add it to my routine.</li>
            //     <li>Curious but uninformed – I take vitamins & supplements as part of my own health & wellness routine but don’t know where to start with my pet.  I’m also hesitant to buy supplements when I don’t know the brand.</li>
            //     <li>Life stage change – My pet is aging and/or has a health issue.  How can I improve and/or extend their quality of life?</li>
            //     <li>Convenience seekers: I already feed GREENIES or something similar, and am looking for a convenient and trusted way to purchase treats I know and love.</li>
            //   </ul>
            // </div>
            }
          </div>
          <div class={`pt-2 ${selected === "usp" ? "" : "collapse"}`}>
            <div id="textUSP">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.strategy}
                onChange={e => handleChange(e, "strategy")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
              <CharacterLimitObject vpHandler={vpHandler} handle="strategy"/> 

            </div>
          </div>
          <div class={`pt-2 ${selected === "summary" ? "" : "collapse"}`}>
            <div id="textSummary">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.description}
                onChange={e => handleChange(e, "description")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
              <CharacterLimitObject vpHandler={vpHandler} handle="description"/> 

              {// A digital experience that creates proactive wellness routines across supplements, oral health, and pill pockets that are delicious for pets and easy for pet parents.<br/><br/>
              // Physical Product:
              // <ul>
              //   <li>~30 SKUs from the existing range of dental treats, PILL POCKETS™ and new Supplements line</li>
              //   <li>Note:  limited on the dental side due to supply constraints.  Expected volumes &lt;100 cases/period in MVP</li>
              //   <li>Option to subscribe for regular deliveries</li>
              // </ul>
              // Digital Product & Services:
              // <ul>
              //   <li>Optional quiz to recommend products based on age, activity level, health issues, etc.   Quiz serves as a product recommender, gathers 1P data, and provides info on how to feed</li>
              //   <li>Option to speak with a vet/vet tech on-demand about selection or usage by chat, Facebook Messenger, or phone</li>
              //   <li>Cross-promotion with Whistle for activity tracking (MVP2)</li>
              // </ul>
              // Content & Community:
              // <ul>
              //   <li>Partnership with Wildest/Kinship to develop blog content to support SEO and non-branded traffic</li>
              //   <li>Give Back Value: Charitable “buy one, give one” option for each purchase as Q4 campaign</li>
              //   <li>Insiders Group:  May also test a private Facebook group for Greenies fans/insiders (MVP2)</li>
              // </ul>
              // Pet parent experience journey:
              // Primary channel will be an online storefront (shop.greenies.com) that will be discoverable through the brand.com site and through a selected set of paid performance marketing tactics.  The first 4-6 months will be a “test & learn” phase to optimize the consumer proposition based on in-market consumer data and feedback.  Example digital touchpoints include:
              // <ul>
              //   <li>Click advertisement, search link, enter site url, and/or other digital options to arrive on site</li>
              //   <li>Option to take quiz or quick buy (build own assortment)</li>
              //   <li>If take quiz: final screen provides product recommendation, feeding detail, and shipping schedule</li>
              //   <li>Check out as one-time or subscription, with option for charitable giving</li>
              //   <li>Follow up CRM communications including standard flows (transactional emails + welcome series, abandoned cart, ratings & review, as well as engagement or special offer email flows)</li>
              //   <li>Return traffic options to chat with vet/vet tech, update account area/manage subscription, etc.</li>
              // </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </div>  
  )}