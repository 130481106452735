import React from "react";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';

// import * as api from './../../API/IndividualVpAPI';3
import * as api from "../../API/IndividualVpAPI";

import { DropdownField } from "../../Input/DropdownField";
import { DropdownFieldBox } from "../../Input/DropdownFieldBox";
import { InputField } from "../../Input/InputField";
import { countryList } from "../../Lists/countryList";
import { valuePropositionList } from "../../Lists/valuePropositionList";

import { speciesList } from "../../Lists/speciesList";
import { channelList } from "../../Lists/channelList";
import { divisionList } from "../../Lists/divisionList";
import { coreList } from "../../Lists/coreList";
import { stageList } from "../../Lists/stageList";
import { boxList } from "../../Lists/boxList";
import { DropdownFieldSpecies } from "../../Input/DropdownFieldSpecies";
import { DropdownFieldStage } from "../../Input/DropdownFieldStage";
import { DropdownFieldShowDiscover } from "../../Input/DropdownFieldShowDiscover";
import { DropdownFieldSpecialIcon } from "../../Input/DropdownFieldSpecialIcon";
import { DropdownFieldVPName } from "../../Input/DropdownFieldVPName";



//import { countryList } from "../../Lists/countryList_backup";

export const ValuePropositionDetails = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [countryListFixed, setCountryListFixed] = React.useState([]);
  const [init, setInit] = React.useState(false);
  const [specialIconString, setSpecialIconString] = React.useState("unicorn-icon");

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      CreateCountryList();
    }
  }, [init]);

  const CreateCountryList = () => {
    let list = []
    let object = {label: '', value: ''}
    countryList.flat().forEach(country => {
        //console.log(country)
        object.label = country.Name
        object.value = country.Name
        list.push({...object})
    })
    setCountryListFixed([...list])
  }

  const checkIfAdd = () => {
    if(vpHandler.action === "add") return true
    else return false
  }

  // const handleChange = () => {
    
  // }

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Value proposition details</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <ul>
              <li>Value Proposition → Select the Value Proposition your project falls under. If your Value Proposition is not yet in the list, select "To be defined".</li>              
              <li>Name → Enter the name of the value proposition, ensuring it is consistent with other documentation</li>
              <li>Country* → Please enter the country or countries this VP is active in</li>
              <li>Stage → Select the stage your value porposition is currently in<br/>
                <span class="mars-c1 fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2 me-1"></span>| Ideation<br/>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-c2 fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2 me-1"></span>| Customer validation<br/>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-c3 fas fa-circle fs--2="></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2 me-1"></span>| MVP<br/>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-c4 fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2 me-1"></span>| Optimize<br/>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-c5 fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2 me-1"></span>| Grow<br/>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-cd fas fa-circle fs--2"></span>
                <span class="mars-c6 fas fa-circle fs--2 me-1"></span>| Scale<br/>
                <span class="text-danger fas fa-times-circle fs--2 me-1"></span>| Perished<br/></li>
              {/* <li>Species → Select the species your value porposition is for</li> */}
              <li>Channel → Select the primary sales channel the proposition will be delivered through</li>
              {/* <li>Plugged into Box → Select 'yes' if your value porposition is plugged into the BOX shared service centre, select 'no' otherwise</li> */}
              {/* <li>Division* → Select RC for Royal Canin or PN for Pet Nutrition</li> */}
              {/* <li>
                  Business → filter by "<span style={{ color: 'red' }} class="fas fa-circle fs--2 mr-1" /> (Red
                  = Core)" or "<span style={{ color: '#000096' }} class="fas fa-circle fs--2 mr-1" /> (Blue =
                  Beyond the core)"
              </li>  */} 
              <br/>
              <div className="ml-0">* required for progress, pivot, perish review. </div>  
            </ul>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
            
          <div class="row" >
            {/* {vpHandler.get.specialIcon&&
              <div style={{width: '10%'}}>
              <div class="text-center">
                <img className="" src={require(`./../../../assets/img/mars/${vpHandler.get.specialIcon}.png`)} alt="..." width="70%" />
              </div>
              
              {(localStorage.getItem("customUserGroup").includes("admin") && !api.checkDisabled(vpHandler)) &&  
              <div class="text-center p-3">
                <DropdownFieldSpecialIcon 
                  inputString={specialIconString}
                  setInputString={setSpecialIconString}
                  title="SpecialIcon" 
                  handle="specialIcon" 
                  isMulti={false} 
                  vpHandler={vpHandler} 
                  list={speciesList}
                />
              </div>
            }
            </div>} */}
            <div className="row px-2">
            < div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text">
                {`Value Proposition${checkIfAdd() ? "*" : ""}`} 
                </div>
                <div class="w-60">
                  <DropdownFieldVPName title="Value Proposition" handle="valuePropositionName" isMulti={false} vpHandler={vpHandler} list={valuePropositionList}/>
                </div>
              </div>
              <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <span class="input-group-text w-50">
                  {`Project Name${checkIfAdd() ? "*" : ""}`} 
                </span>
                <InputField title="Name" handle="name" vpHandler={vpHandler}/>
              </div>

              <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text">
                {`Country${checkIfAdd() ? "*" : ""}`} 
                </div>
                <div class="w-60">
                  <DropdownField title="Country" handle="geography" isMulti={false} vpHandler={vpHandler} list={countryListFixed}/>
                </div>
              </div>
            
              {/* <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text">
                  Species
                </div>
                <div class="w-60">
                  <DropdownFieldSpecies
                    title="Species" 
                    handle="species" 
                    isMulti={false} 
                    vpHandler={vpHandler} 
                    list={speciesList}
                  />
                </div>
              </div> */}

              <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text">
                {`Channel${checkIfAdd() ? "*" : ""}`} 
                </div>
                <div class="w-60">
                  <DropdownField title="Channel" handle="channel" isMulti={false} vpHandler={vpHandler} list={channelList}/>
                </div>
              </div>

              {/* <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text">
                {`Division${checkIfAdd() ? "*" : ""}`} 
                </div>
                <div class="w-60">
                  <DropdownField title="Division" handle="division" isMulti={false} vpHandler={vpHandler} list={divisionList}/>
                </div>
              </div> */}

              {/* <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text" id="inputGroup-sizing-sm">
                  Business
                </div>
                <div class="w-60">
                  <DropdownField title="Type" handle="businessType" isMulti={false} vpHandler={vpHandler} list={coreList}/>
                </div>
              </div> */}
            
              <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text" id="inputGroup-sizing-sm">
                {`Stage${checkIfAdd() ? "*" : ""}`} 

                </div>
                <div class="w-60">
                  <DropdownFieldStage title="Stage" handle="currentStage" isMulti={false} vpHandler={vpHandler} list={stageList}/>
                </div>
              </div>
           
              {/* <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text" id="inputGroup-sizing-sm">
                  Plugged into Box  
                </div>
                <div class="w-60">
                  <DropdownFieldBox title="Box" handle="pluggedIntoBox" isMulti={false} vpHandler={vpHandler} list={boxList}/>
                </div>
              </div> */}

              {/* <div class="input-group col-xl-4 col-md-6 col-xs-12">
                <div class="input-group-text" id="inputGroup-sizing-sm">
                  Show in Discover  
                </div>
                <div class="w-60">
                  <DropdownFieldShowDiscover title="Box" handle="showDiscover" isMulti={false} vpHandler={vpHandler} list={boxList}/>
                </div>
              </div> */}
              {checkIfAdd() &&  
                <div className="ml-3 mt-2" style={{fontSize: '11px'}}>* required fields.</div>
              }
            </div>
          </div>
        </div>

      </div>
    </div>
          
  )}