import React, { useState } from "react";
// import { DivOverlay } from "react-leaflet";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';
import { stageGateKpiList } from "../../Lists/StageGateKpiList";
import { stageGateCopyList } from "../../Lists/stageGateCopyList";
import { lookUpList } from "../../Lists/lookUpList";
import * as api from "./../../API/IndividualVpAPI";

// import { DropdownFieldValueModel } from "../../Input/DropdownFieldValueModel";
// import { allKPIList } from "../../Lists/allKPIList";
// import { DropdownFieldStageGate } from "../../Input/DropdownFieldStageGate";
import KpiSearchBar from './../../SearchBars/KpiSearchBar';
// import { kpiDictionary } from "../../Lists/kpiDictionary";

import { demoStagegateObject } from "../../Lists/demoStagegateObject";

export const StageGate = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selected, setSelected] = React.useState("a");
  // const [fixedSov, setFixedSov] = React.useState("");
  const [dictionaryList, setDictionaryList] = React.useState([]);

  const [customKpiList, setCustomKpiList] = useState([])

  // React.useEffect(() => {
  //   //console.log(`selected: ${selected}`)
  // }, [selected]);


  const getValue = (stage, sov, kpi, field) => {
    //return vpHandler.get.stageGate[stage][sov][kpi][field]
    //return demoStagegateObject[stage][sov][kpi] !== null && vpHandler.get.stageGate[stage][sov][kpi][field]
  }

  const getCValue = (kpi, field) => {
    // let _dictionaryList = dictionaryList
    return kpi[field]

    // let item = vpHandler.get.dictMetrics.find(item => item.kpi === kpi)
    // console.log(`item: ${item[field]}`)
    // return item[field]
  } 


  const handleCchange = (e, kpi, field) => {
    // let vpObject = vpHandler.get
    // vpObject.dictMetrics.find(item=>item.kpi === kpi.kpi)[field] = e.target.value
    // vpHandler.set({...vpObject})
  }

  // React.useEffect(() => {
  //   console.log(`dictionaryList ${JSON.stringify(dictionaryList)}`)

  // }, [dictionaryList]);


  // const checkKpi = (stage, sov, kpi, field) => {

  //   if(findKpi(vpHandler.get.currentStage, sov, kpi) === false && vpHandler.get.stageGate[stage][sov][kpi] !== null) return true
  //   else return false
  // }

  // const findKpi = (stage, sov, kpi) => { //returns true if a KPI already exists in A or B list
  //   if(stageGateKpiList[stage][sov]['a'].find(item => item.name === kpi) !== undefined ) return true
  //   else if(stageGateKpiList[stage][sov]['b'].find(item => item.name === kpi) !== undefined ) return true
  //   else return false
  // }

  // const removeKpi = (stage, sov, kpi) => {
  //   let vpObject = vpHandler.get
  //   demoStagegateObject[stage][sov][kpi] = null
  //   vpHandler.set({...vpObject})
  // }
  
  const removeCKpi = (e) => {
    // let vpObject = vpHandler.get
    // vpObject.dictMetrics = vpObject.dictMetrics.filter(item => item.kpi !== e)
    // vpHandler.set({...vpObject})
  }

  const handleChange = (e, stage, sov, kpi, field) => {
    // let vpObject = vpHandler.get
    // demoStagegateObject[stage][sov][kpi][field] = e.target.value
    // vpHandler.set({...vpObject})
  }

  const handleNumberChange = (e, stage, sov, kpi, field) => {
    // let vpObject = vpHandler.get
    // demoStagegateObject[stage][sov][kpi][field] = Number(e.target.value)
    // vpHandler.set({...vpObject})
  }

  const handleBoolChange = (e, stage, sov, kpi, field) => {
    // let vpObject = vpHandler.get
    // if(e.target.value === "true") demoStagegateObject[stage][sov][kpi][field] = true
    // else demoStagegateObject[stage][sov][kpi][field] = false
    // vpHandler.set({...vpObject})
  }

  const handleHighlightChange = (e, stage, sov, kpi, field) => {
    // let vpObject = vpHandler.get 
    // if(e.target.checked === true){
    //   if(vpObject.highlightKPI.length <= 3) vpObject.highlightKPI.push(kpi)
    //   else api.notify("Only 4 KPI's can be selected.", vpHandler.ref, "danger")
    // }
    // else{
    //   let index = vpObject.highlightKPI.indexOf(kpi)
    //   if (index !== -1) {
    //     vpObject.highlightKPI.splice(index, 1);
    //   }
    // } 

    // console.log(vpObject.highlightKPI)
    // vpHandler.set({...vpObject})
  }

  const checkHighlight = (stage, sov, kpi, field) => {
    let vpObject = vpHandler.get 
    if(vpObject.highlightKPI?.includes(kpi)) return true 
    else return false
  }

  const checkStage = () => {
    if(vpHandler.get.currentStage === "optimize" || vpHandler.get.currentStage === "grow" || vpHandler.get.currentStage === "scale") return false
    else return true
  }


  const inputAffix = (kpi) => {
    if( !!kpi ){
      if(kpi.includes("%")) return "stage-procent"
      if(kpi.includes("$")) return "stage-dollar"
      if(kpi.includes(":")) return "stage-compare"
      if(kpi.includes("#")) return "stage-number"
      else return ""
    }
    else return ""
  }


  return(
    
    <div class={`card mb-3 `}>
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Stage Gate KPI's</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
        <div class="p-card rounded fs--2">
          <p>
            Please use the table below to set a Target for relevant KPIs at the beginning of the stage, and report on the Actual (or Estimated) value for each KPI at the end of the stage. Please provide any related commentary to the Comments column.
            <br/>
            Use the Highlight column to select up to 4 KPI's that are displayed in the Stage Gating Overview.
          </p>
        </div>
      </div>
      <div class={`${hide ? "collapse" : ""}  ${vpHandler.get.currentStage === "ideation" ? "d-none" : ""} card-body`}>
        <div class="min-vh-30  fs--2">
          <h6 class="fs--2">{lookUpList[vpHandler.get.currentStage]}</h6>
          <p>{stageGateCopyList[vpHandler.get.currentStage].header}</p>
          <p>Please fill the respective KPI sections in the tabs below:</p>          
          <div class="tab d-none">
            <button 
              onClick={e => setSelected("a")}
              style={{ backgroundColor: `${selected === "a" ? "#dddddd" : ""}` }} 
            >
              {`${checkStage() ? "A." : ""} Stage gate targets(*)`}
            </button>
            {checkStage() &&
              <button 
                onClick={e => setSelected("b")}
                style={{ backgroundColor: `${selected === "b" ? "#dddddd" : ""}` }} 
              >
                B. Tracked/modelled(*)
              </button>
            }
            <button 
              onClick={e => setSelected("c")}
              style={{ backgroundColor: `${selected === "c" ? "#dddddd" : ""}` }} 
            >
              {`${checkStage() ? "C." : ""} Additional (optional)`}
            </button>
          </div>

          <div id="A" class={`pt-2`}>
          
            <div 
              style={{backgroundColor: '#dddddd'}}
              class="pl-1 pt-1 pb-1 font-weight-bold">{`${checkStage() ? "A. " : "" }`}Stage gate targets (*)
            </div>

            <p>
                {stageGateCopyList[vpHandler.get.currentStage]["aSub"]}           
            </p>
            <div class="table-responsive scrollbar">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"12%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"20%"}}/>
                  <col style={{width:"8%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="source">Source</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="kpi">KPI</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="bench">Benchmark</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="target">Target</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="value">Value</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="act-mod">Actual/model</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="trend">Trend</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="appl">Applicable</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="comment">Comment</th>
                    <th class="sort pe-1 align-middle white-space-nowrap mr-2" data-sort="comment">Highlight</th>

                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {Object.keys(stageGateKpiList).map((stage) => (<>
                    {stage === vpHandler.get.currentStage &&
                      Object.keys(stageGateKpiList[stage]).map((sov) => (<> 
                        {stageGateKpiList[stage][sov]["a"].map((kpi, key) => (
                          <tr key={key} class="btn-reveal-trigger">
                            <td class="align-top source">{lookUpList[sov]}</td>
                            <td class="align-top kpi">{lookUpList[kpi.name]}</td>
                            <td class="align-top bench">{kpi.bench}</td>
                            <td class={`align-top target ${inputAffix(lookUpList[kpi.name])}`}>
                              <input 
                                disabled={api.checkDisabled(vpHandler)} 
                                class="form-control form-height font-base" 
                                type="text"
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'target')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'target')}
                              />
                            </td>
                            <td class={`align-top value ${inputAffix(lookUpList[kpi.name])}`}>                              
                              <input
                                disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height font-base" 
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'actual')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'actual')} 
                                type="text" 
                              />
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)} 
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleBoolChange(e,vpHandler.get.currentStage, sov, kpi.name, 'modelled')}
                                >
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'modelled') === false} value={false}>Actual</option>
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'modelled') === true} value={true}>Modelled</option>
                              </select>
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)} 
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleNumberChange(e,vpHandler.get.currentStage, sov, kpi.name, 'trend')}
                                >
                                <option 
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 1} 
                                  value={1}>
                                    Up
                                </option>
                                <option 
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 0} 
                                  value={0}>
                                    Flat
                                </option>
                                <option                                   
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === -1} 
                                  value={-1}>
                                    Down
                                </option>
                                <option                                   
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 2} 
                                  value={2}>
                                    Select
                                </option>
                              </select>
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)} 
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleBoolChange(e,vpHandler.get.currentStage, sov, kpi.name, 'active')}
                                >
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'active') === true} value={true}>Yes</option>
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'active') === false} value={false}>No</option>
                              </select>
                            </td>
                            <td class="align-top value">
                              <input 
                                disabled={api.checkDisabled(vpHandler)} 
                                class="form-control form-height font-base" 
                                type="text"
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'comment')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'comment')} 
                              />
                            </td>
                            <td>
                              <input
                                className="ml-3"
                                checked={checkHighlight(vpHandler.get.currentStage, sov, kpi.name, 'active') === true}
                                disabled={api.checkDisabled(vpHandler)} 
                                type="checkbox"  
                                onChange={e=> handleHighlightChange(e,vpHandler.get.currentStage, sov, kpi.name, 'active')}
                              />
                            </td> 
                          </tr>
                        ))}
                      </>))}
                    </>))
                  }    
                </tbody>
              </table>
            </div>
          </div>
          
          <div id="B" class={`pt-2 ${checkStage() ? "" : "d-none"}`}>
            <div 
              style={{backgroundColor: '#dddddd'}}
              class="pl-1 pt-1 pb-1 font-weight-bold">B. Tracked/Modelled (*)
            </div>
            <p>
              {stageGateCopyList[vpHandler.get.currentStage]["bSub"]}           
            </p>
            <div class="table-responsive scrollbar">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"12%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"20%"}}/>
                  <col style={{width:"8%"}}/> 
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="source">Source</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="kpi">KPI</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="bench">Benchmark</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="target">Target</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="value">Value</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="act-mod">Actual/model</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="trend">Trend</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="appl">Applicable</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="comment">Comment</th>
                    <th class="sort pe-1 align-middle white-space-nowrap mr-2" data-sort="comment">Highlight</th>

                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {Object.keys(stageGateKpiList).map((stage) => (<>
                    {stage === vpHandler.get.currentStage &&
                      Object.keys(stageGateKpiList[stage]).map((sov) => (<> 
                        {stageGateKpiList[stage][sov]["b"].map((kpi, key) => (<>
                          <tr key={key} class="btn-reveal-trigger">
                            <td class="align-top source">{lookUpList[sov]}</td>
                            <td class="align-top kpi">{lookUpList[kpi.name]}</td>
                            <td class="align-top bench">{kpi.bench}</td>
                            <td class={`align-top target ${inputAffix(lookUpList[kpi.name])}`}>                              
                              <input 
                                disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height font-base" 
                                type="text"
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'target')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'target')}
                              />
                            </td>
                            <td class={`align-top value ${inputAffix(lookUpList[kpi.name])}`}>                              
                              <input 
                                disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height font-base" 
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'actual')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'actual')} 
                                type="text" 
                              />
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleBoolChange(e,vpHandler.get.currentStage, sov, kpi.name, 'modelled')}
                                >
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'modelled') === false} value={false}>Actual</option>
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'modelled') === true} value={true}>Modelled</option>
                              </select>
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleNumberChange(e,vpHandler.get.currentStage, sov, kpi.name, 'trend')}
                                >
                                <option 
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 1} 
                                  value={1}>
                                    Up
                                </option>
                                <option 
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 0} 
                                  value={0}>
                                    Flat
                                </option>
                                <option                                   
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === -1} 
                                  value={-1}>
                                    Down
                                </option>
                                <option                                   
                                  selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'trend') === 2} 
                                  value={2}>
                                    Select
                                </option>
                              </select>
                            </td>
                            <td>
                              <select 
                              disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height form-select font-base"
                                onChange={e=>handleBoolChange(e,vpHandler.get.currentStage, sov, kpi.name, 'active')}
                                >
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'active') === true} value={true}>Yes</option>
                                <option selected={getValue(vpHandler.get.currentStage, sov, kpi.name, 'active') === false} value={false}>No</option>
                              </select>
                            </td>
                            <td class="align-top value">
                              <input 
                              disabled={api.checkDisabled(vpHandler)}  
                                class="form-control form-height font-base" 
                                type="text"
                                onChange={e=>handleChange(e,vpHandler.get.currentStage, sov, kpi.name, 'comment')}
                                value={getValue(vpHandler.get.currentStage, sov, kpi.name, 'comment')} 
                              />
                            </td>
                            <td>
                              <input
                                className="ml-3"
                                checked={checkHighlight(vpHandler.get.currentStage, sov, kpi.name, 'active') === true}
                                disabled={api.checkDisabled(vpHandler)} 
                                type="checkbox"  
                                onChange={e=> handleHighlightChange(e,vpHandler.get.currentStage, sov, kpi.name, 'active')}
                              />
                            </td> 
                          </tr>
                        </>))}
                      </>))}
                    </>))
                  }    
                </tbody>
              </table>
            </div>
          </div>
          
          <div id="C" class={`pt-2`}>
            <div 
              style={{backgroundColor: '#dddddd'}}
              class="pl-1 pt-1 pb-1 font-weight-bold">
                {`${checkStage() ? "C. " : "" }`}Additional (optional)
            </div>

            <p>
              {stageGateCopyList[vpHandler.get.currentStage]["cSub"]}           
            </p>
            <div class="table-responsive scrollbar">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"10%"}}/>
                  <col style={{width:"7%"}}/>
                  <col style={{width:"7%"}}/>

                  <col style={{width:"100%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="source">Source</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="kpi">KPI</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="bench">Benchmark</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="target">Target</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="value">Value</th>

                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="comment">Description</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="action"> </th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {customKpiList.map((item, key) => (
                    item.helper !== "Custom KPI" ? 
                      <tr key={key} class="btn-reveal-trigger">
                        <td class="align-top source">{item.category}</td>
                        <td class="align-top kpi">{item.kpi}</td>
                        <td class="align-top bench">{`${item.min} - ${item.max}${item.unit}`}</td>
                        <td class={`align-top target ${inputAffix(item.kpi)}`}>                              
                          <input 
                            disabled={api.checkDisabled(vpHandler)}  
                            class="form-control form-height font-base" 
                            type="text"
                            onChange={e=>handleCchange(e, item, 'target')}
                            value={getCValue(item, "target")}
                          />
                        </td>
                        <td class={`align-top value ${inputAffix(item.kpi)}`}>      
                          <input 
                            disabled={api.checkDisabled(vpHandler)}  
                            class=" form-control form-height font-base " 
                            onChange={e=>handleCchange(e, item, 'actual')}
                            value={getCValue(item, "actual")}
                            type="text" 
                          />
                        </td>

                        <td class="align-top value">
                          <div class="row pl-3">
                            {item.description} 

                          </div>
                        </td>
                        <td>
                          <span style={{cursor: 'pointer'}} class="mars-blue fas far fa-trash-alt fs--2 pl-1 pt-1 pr-2" onClick={e => removeCKpi(item.kpi)}/>
                        </td>
                      </tr>
                    :
                      <tr key={key} class="btn-reveal-trigger">
                        <td class="align-top source">{"Custom KPI"}</td>
                        <td class="align-top target">
                          <input 
                            disabled={api.checkDisabled(vpHandler)}  
                            class="form-control form-height font-base" 
                            type="text"
                            onChange={e=>handleCchange(e, item, 'kpi')}
                            value={getCValue(item, "kpi")}
                          />
                        </td>
                        <td class="align-top bench">{"Not applicable"}</td>
                        <td class={`align-top target ${inputAffix(item.kpi)}`}>                              
                          <input 
                            disabled={api.checkDisabled(vpHandler)}  
                            class="form-control form-height font-base" 
                            type="text"
                            onChange={e=>handleCchange(e, item, 'target')}
                            value={getCValue(item, "target")}
                          />
                        </td>
                        <td class={`align-top value ${inputAffix(item.kpi)}`}>                              
                          <input 
                            disabled={api.checkDisabled(vpHandler)}  
                            class=" form-control form-height font-base " 
                            onChange={e=>handleCchange(e, item, 'actual')}
                            value={getCValue(item, "actual")}
                            type="text" 
                          />
                        </td>

                        <td class="align-top value">
                          <div class="row pl-1">
                            <input 
                              disabled={api.checkDisabled(vpHandler)}  
                              style={{width: "90%"}}
                              class="ml-2 form-control form-height font-base" 
                              type="textarea"
                              onChange={e=>handleCchange(e, item, 'description')}
                              value={item.description} 
                            />
                          </div>
                        </td>
                        <td>
                          <span style={{cursor: 'pointer'}} class="mars-blue fas far fa-trash-alt fs--2 pl-1 pt-1 pr-2" onClick={e => removeCKpi(item.kpi)}/>
                        </td>
                      </tr>
                  ))}
                </tbody>

              </table>
            </div>  
            {!api.checkDisabled(vpHandler) && <KpiSearchBar vpHandler={vpHandler} setDictionaryList={setDictionaryList} dictionaryList={dictionaryList} customKpiList={customKpiList} setCustomKpiList={setCustomKpiList}/>}

          </div>
        </div>
      </div>
    </div>  
  )}