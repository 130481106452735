import React, {useState, useEffect} from 'react';
import { lookUpList } from '../../components/Lists/lookUpList';
import VpPaginationTable from '../../components/table/VpPaginationTable';
//import { FilterDeckDiscover }  from '../../components/Cards/FilterDeckDiscover';
//import { GetValueProps } from '../../components/API/ControlTowerAPI';  
//import * as brandAPI from '../../components/API/BrandsTableAPI';
import { useOktaAuth } from "@okta/okta-react";
import { countryList } from '../../components/Lists/countryList';

const ValuePropositionTable = ({setSelectedBrand, valueProps, itemsPerPage, origin, string, setString }) => {
   
  const [init, setInit] = useState(false);
  //const [backup, setBackup] = useState([]);
  const [page, setPage] = useState(0);
  //const [includePerished, setIncludePerished] = React.useState(false);

  const [tableItems, setTableItems] = useState([]);
  // const [tableItemsBackup, setTableItemsBackup] = useState([]);

  // const [brandTableBrands, setBrandTableBrands] = useState([]);
  // const [brandTableSocials, setBrandTableSocials] = useState([]);
  // const [brandTableTraffic, setBrandTableTraffic] = useState([]);
  const [number, setNumber] = useState(4);
  //const [valueProps, setValueProps] = useState([]);

  const [autofillList, setAutofillList] = useState([]);

  const tableColumns = [
    { columnName: '', id: 'image', isSortable: false, defaultSort: true, isNumeric: false },
    // { columnName: 'Icon', id: 'icon', isSortable: false, defaultSort: true, isNumeric: false },
    { columnName: 'Project Name', id: 'name', isSortable: true, isNumeric: false },
    { columnName: 'Value Proposition', id: 'vpName', isSortable: true, isNumeric: false },
    { columnName: 'Stage', id: 'stage', isSortable: true, isNumeric: false },
    { columnName: 'Country', id: 'country', isSortable: true, isNumeric: false },
    // { columnName: 'Division', id: 'division', isSortable: true, isNumeric: false },
    // { columnName: 'Species', id: 'species', isSortable: true, isNumeric: false },
    { columnName: 'Channel', id: 'channel', isSortable: true, isNumeric: false },
    // { columnName: 'Business', id: 'business', isSortable: true, isNumeric: false },
    // { columnName: 'Box', id: 'box', isSortable: false, isNumeric: false },
    { columnName: '', id: 'image', isSortable: false, defaultSort: true, isNumeric: false },

  ];

  useEffect(() => {
    if (!init) {
      setInit(true);
      setNumber(itemsPerPage)
      //GetValueProps(setValueProps);
      
      //brandAPI.GetBrands(setBrandTableBrands);
      //brandAPI.GetSocialAnalysisData(setBrandTableSocials);
      //brandAPI.GetSocialAnalysisWebTraffic(setBrandTableTraffic);

    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  useEffect(() => {
    //console.log(`vps: ${JSON.stringify(valueProps)}`) 
    setPage(0)
    fillTable() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProps]);

  // useEffect(() => {
  //   if (brandTableBrands.length > 0 && brandTableSocials.length > 0 && brandTableTraffic.length > 0) fillTable();
  // }, [brandTableBrands, brandTableSocials, brandTableTraffic]);

  useEffect(() => {
    if (tableItems.length > 0 ) {
      createSearchBarList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]);

  let rcEmailArray = [ "anna.dibner@royalcanin.com", "danson.huang@royalcanin.com", "elisa.brami@royalcanin.com", "wessels.rene@gmail.com"]
  let pnEuropeEmailArray = [ "wessels.rene@gmail.com", "deirdre.mcsharry@effem.com", "etc"]

  let emailList = {
    ideation: "emailOne", //elisabeth.franzky@royalcanin.com
    customerVal : "emailTwo",
    mvp: "emailThree",
    optimize: "emailFour",
    grow: "emailFive",
    scale: "emailSix"
  }

  const checkIfSponsorOrRoyalCanin = (_isSponsor, item, _email) => {
    if(_isSponsor) return true
    else if(item.division === "Royal Canin"){
      if(rcEmailArray.includes(_email)) return true
    }
    else return false
  }

  const checkDisplay = (item, userEmail) => {
    // console.log(`item: ${JSON.stringify(item.name)}`)
    if(item.division === "Royal Canin"){
      //console.log(`${item.name} RC`)
      if(rcEmailArray.includes(userEmail)) return true
    }
    else {

      let country = countryList.flat().find((item) => item.Name === item.geography)
      //if(countryList.find((country) => country.Name === item.geography).Region === "Europe") {
      if(country !== undefined && country.Region === "Europe") {
        //console.log(`${item.name} in europe`)
        if(pnEuropeEmailArray.includes(userEmail)) {
          //console.log(`${item.name} is europe email`)
          return true
        }
      }
      else { 
        //console.log(`${item.name} else, not EU`)
        if(item.sponsors.find(sponsor => sponsor.email === userEmail)){
          //console.log(`${item.name} not eu, email found in sponsor`)
          return true
        }

      }
    }
  }

  const fillTable = () => {
    let table = [];
    let data = {
      icon: "-",
      name: "-",
      stage: "-",
      country: "-",
      division: [],
      species: "-",
      channel: "0",
      business: "0",
      box: false,
      uuid: "",
    }
    valueProps.forEach((item) => {
      // item.sponsors.forEach(sponsor => {
      //   //console.log(`${sponsor.email} ${sponsor.email === authState.accessToken.claims.sub}`)
      //   if(sponsor.email === authState.accessToken.claims.sub) isSponsor = true;
      //   //console.log(`hasSponsor: ${sponsor}`)
      // })
      //getEmailDestination(item, authState.accessToken.claims.sub)
      //checkIfSponsorOrRoyalCanin(isSponsor, item, authState.accessToken.claims.sub)
      if(origin === "review"){
        data = {
          icon:      item.specialIcon,
          name:      item.name,
          vpName:    item.valuePropositionName,
          stage:     lookUpList[item.currentStage],
          country:   item.geography,
          division:  item.division,
          //species:   item.species,
          channel:   item.channel,
          business:  item.businessType,
          //box:       item.pluggedIntoBox,
          uuid:      item.uuid,
        }
        table.push(data)
      }
      else {
        data = {
          icon:      item.specialIcon,
          name:      item.name,
          vpName:    item.valuePropositionName,
          stage:     lookUpList[item.currentStage],
          country:   item.geography,
          division:  item.division,
          species:   item.species,
          channel:   item.channel,
          business:  item.businessType,
          box:       item.pluggedIntoBox,
          uuid:      item.uuid,
        }
        table.push(data)
      }
      // isSponsor = false;
    })

    //-------------------------------------------------------

    setTableItems([...table]);
  }

  const createSearchBarList = () => {
    let _data = tableItems;
    let _list = [];

    _data.forEach((item) => {
      let object = {
        name: item.name,
        uuid: item.uuid,
      };
      _list.push({ ...object });
    });
    setAutofillList([..._list]);
  };

  return ( 
    <>
      <VpPaginationTable 
        page={page}
        setPage={setPage}
        string={string}
        setString={setString}
        setSelectedBrand={setSelectedBrand} 
        columns={tableColumns} 
        tableItems={tableItems} 
        itemsPerPage={itemsPerPage} 
        setTableItems={setTableItems} 
        autofillList={autofillList}
        origin={origin}
      />
    </> 
  )
};

export default ValuePropositionTable;