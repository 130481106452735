export const getManyVpQuery = `
query GetManyVP($vp: ValuePropInputType) {
  GetManyVP(vp: $vp) {
    name
    valuePropositionName
    uuid
    currentStage
    createdBy
    geography
    division
    species
    channel
    businessType
    pluggedIntoBox
    specialIcon
    active
    perish
    pivot
    highlightKPI
    eventHistory{
      date
      event
      decision
      requester
      fileName
      fileUuid
      fileType
    }
    funding{
      customerVal
      grow
      ideation
      mvp
      optimize
      scale
    }
    
    team{
      name
      fte
      email
    }

    sponsors{
      name
      email
    }

    metrics{
      sov
      kpi
      unit
      threshold
      y1
      y2
      y3
    }
    stageGate {
      ideation {
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      customerVal{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      mvp {
          intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      optimize{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }
      grow{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }
      scale{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }
      }
    }   
  }
}
`
