export const getOneVpQuery = `
query GetVP($vp: ValuePropInputType) {
  GetVP(vp: $vp) {
    name
    valuePropositionName
    uuid
    archived
    active
    specialIcon
    pluggedIntoBox
    pivot
    perish
    geography
    countries
    channel
    highlightKPI
    division
    painPoint
    description
    strategy
    createdBy
    businessType
    investment
    currentStage
    nextStepsActionPlan
    nextStepsOutcomes
    nextStepsFunding
    nextStepsFundingAllocation
    species
    showDiscover
    eventHistory{
      date
      event
      decision
      requester
      fileName
      fileUuid
      fileType
    }
    
    dictMetrics {
      helper
      kpi
      description
      category
      unit
      min
      max
      target
      actual
      modelled
      trend
      active
    }
    valueModel{
      intrinsic
      engagement
      data
      routetoscale
    }
    funding{
      customerVal
      grow
      ideation
      mvp
      optimize
      scale
    }
    files{
      uuid
      fileName
      mime
      date
      fileType
    }
    team{
      name
      fte
      email
    }
    sponsors{
      name
      email
    }
    sovDescription{
      engagementDescr
      intrinsicDescr
      dataDescr
      routeDescr
    }
    metrics{
      sov
      kpi
      unit
      threshold
      y1
      y2
      y3
    }
    keyLearnings
    nextSteps
    nextStepsActionPlan
    nextStepsOutcomes
    nextStepsFunding
    nextStepsFundingAllocation
    name
    stageGate {
      ideation {
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      customerVal{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      mvp {
          intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }

      optimize{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }
      grow{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }


      }
      scale{
        intrinsic {
          nsv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          gmac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltv {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ltvcac {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ctr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          session {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          bcr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          aov {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        engagement {
          rp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          tc {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          rr {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          nps {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }

        data {
          dvam {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          ppp {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
          cda {
            target
            benchmark
            actual
            comment
            modelled
            active
            trend
          }
        }
      }
    }
  }
}
`
