import React from 'react'
import moment from 'moment';
import { lookUpList } from '../Lists/lookUpList';

import { demoStagegateObject } from '../Lists/demoStagegateObject';

export const SwimmingLaneObject = ({input, api, vpHandler}) => {
  const [open, setOpen] = React.useState(true)
  const [init, setInit] = React.useState(false)
  const [openKPI, setOpenKPI] = React.useState(false)

  let percent = Math.floor(Math.random() * 100)

  React.useEffect(() => {
    if (!init) {
      setInit(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]); 

  const getColor = (input) => { 
    if(input === 'scale') return '#fff'
    if(getPercent() === 0 ) return '#fff'
    else if(getPercent() < 80) return 'green'
    else if (getPercent() < 99) return '#FD7F20'
    else return '#FC2E20'
  }

  // const calculateTime = () => {
  //   if(input.eventHistory.length > 0){
  //     console.log(input.eventHistory[0].date )
  //     let a = moment() //now
  //     let b = moment(input.eventHistory[0].date, "MMM Do YYYY") //submit
  //     let duration = moment.duration(b.diff(a));
  //     console.log(duration)
  //   }
  // }
 
  const getWeeks = () => {
    if(getSubmittedDate()){
      let a = moment() //now
      let b = moment(getSubmittedDate(), "YYYY-MM-dd") //submit
      let duration = moment.duration(b.diff(a));
      //console.log(duration.asWeeks())
      let weeks = ((Math.round(duration.asWeeks())) * -1) + 1
      if(weeks) return weeks
    } 
  }

  const getSubmittedDate = () => {
    let obj = input.eventHistory[0]
    if(!!obj) return obj.date
  }


  let durationTable = {
    ideation: 34,
    customerVal: 28,
    mvp: 32,
    optimize: 46,
    grow: 42,
    scale: 60
  }

  const getPercent = () => {
    let max = durationTable[input.currentStage]
    let current = getWeeks() || 0 
    return current/max*100
  }
 
  const getKPI = () => {
    if(input.highlightKPI.length > 0){
      let _arr = [
        {
          kpi: input.highlightKPI[0],
          target: 15,
          actual: 20,
        },
        {
          kpi: input.highlightKPI[1],
          target: 18,
          actual: 17,
        },
        {
          kpi: input.highlightKPI[2],
          target: 21,
          actual: 17,
        },
      ]
      // let obj = {...demoStagegateObject[input.currentStage].intrinsic, ...demoStagegateObject[input.currentStage].engagement, ...demoStagegateObject[input.currentStage].data}
      // let arr = [] 
      // Object.keys(obj).map(item => {
      //   if(input.highlightKPI.includes(item)){ 
      //     let kpiObj = {"kpi": item, ...obj[item]}
      //     arr.push(kpiObj) 
      //   }
      // }) 
      return _arr
    }
    else return []
  }

  return (
    <div className='rounded' style={{
      border: '1px solid #e2e3e5'
    }}>
      <div className='fs--1 cursor-pointer' style={{userSelect: 'none'}} onClick={()=>setOpen(!open)}> 
        <div className='d-flex'>
          <div className='mt-2 mx-1' style={{
            width: '7px',
            height: '7px',
            backgroundColor: `${input.businessType === "Core (red)" ? 'red' : 'blue'}`,
            borderRadius: '50%', 
          }}/>
          <div className='ml-2 '>
            {open?
              input.name.length > 19 ? input.name.substring(0, 19) + ".." : input.name : input.name
            }
          </div>
          {open? 
            <i class="ml-auto fas fa-chevron-right mr-2 mt-1" style={{color: '#e2e3e5'}}/>:
            <i class="ml-auto fas fa-chevron-down mr-2 mt-1"  style={{color: '#e2e3e5'}}/>
          }
        </div> 
        {input.currentStage !== "test"&&<>
        <div className='text-center fs--2'style={{
          position: 'relative',
          marginTop: '', 
          zIndex:'5' 
        }}>  
          <span style={{visibility: `${open?'hidden':""}`}} className="text-white">{`${getWeeks()}/${durationTable[input.currentStage]} weeks`}</span>
        </div>
        <div className='text-center' style={{
          position: 'relative',
          display: 'flex',
          marginTop: '-16px',
          zIndex: '1'
        }}>           
          <div className='' style={{
            width: `${getPercent()}%`,
            height: `${!open ? '14px' : '8px'}`,
            backgroundColor: getColor(input.currentStage)
          }}/> 
          <div className='' style={{
            width: `${100-getPercent()}%`,
            backgroundColor: getColor(input.currentStage),
            opacity:'30%'
          }}/>  
        </div></>}
      </div>
      <div class={`${!open ? '' : 'collapse'}`} id="">
        <div className='d-flex flex-1 px-2 rounded fs--2'>
          <div className='' style={{width:'45%'}}>
            Channel:
          </div>
          <div className='' style={{width:'55%'}}>
            {input.channel}
          </div>
        </div>
        <div className='d-flex flex-1 px-2 rounded fs--2'>
          <div className='' style={{width:'45%'}}>
            Business:
          </div>
          <div className='' style={{width:'55%'}}>
            {input.businessType}
          </div>
        </div>
        {input.currentStage !== "ideation"&&
        <div className='d-flex flex-1 px-2 rounded fs--2'>
          <div className='' style={{width:'45%'}}>
            KPIs
          </div>
          <div className='' style={{width:'55%'}}>
            <div className='cursor-pointer' onClick={()=>{
              setOpenKPI(!openKPI) 
            }}>
              {!openKPI? 
                <i class="ml-auto fas fa-chevron-right mr-2 mt-1" />:
                <i class="ml-auto fas fa-chevron-down mr-2 mt-1" />
              }
            </div>
          </div>
        </div>}  
        {input.currentStage !== "ideation"&& <>
          <div class={`${openKPI ? '' : 'collapse'}`} id="">
          {input.highlightKPI?.length > 0 ? <>
          <div class="px-2 rounded fs--2" style={{display: 'flex'}}> 
            <div className='font-weight-bold' style={{width: '50%'}}>
              <div className=''>
                KPI
              </div> 
            </div>
            <div className='font-weight-bold' style={{width: '25%'}}>
              <div className=' '>
                Target
              </div> 
            </div>
            <div className='font-weight-bold' style={{width: '25%'}}>
              <div className=' '>
                Value
              </div> 
            </div>   
          </div>
          {getKPI().map((item) => (
            <div class="px-2 rounded fs--2" style={{display: 'flex'}}> 
                <div className='' style={{width: '50%'}}>
                  <div className=' '>
                    {lookUpList[item.kpi]}
                  </div> 
                </div>
                <div className='' style={{width: '25%'}}>
                  <div className=' '>
                    {item.target}
                  </div> 
                </div>
                <div className='' style={{width: '25%'}}>
                  <div className=' '>
                    {item.actual}
                  </div> 
                </div>   
              </div>
          ))}
          </>: 
          <div className='p-2 fs--2'>
            Select a KPI to be displayed using the "Highlight" checkbox in the <a href={`/monitor/individual/view/${input.uuid}`}>VP overview page.</a>
          </div>
          }
        </div>
      </>}
        <div className='fs--2 d-flex px-2 py-1 ' style={{color: '#2c7be8'}}>
          <div className='cursor-pointer' onClick={e => console.log('ddf')}>
            <i class="ml-auto fas fa-download mr-2 mt-1"  style={{color: '#2c7be8'}}/>
            <a>Report</a>
          </div>
          <div className='ml-auto cursor-pointer mars-blue ' style={{textDecoration: 'none', color: '#000096'}} >
            <a href={`/monitor/individual/view/${input.uuid}`}>Visit VP</a>
          </div>
        </div>  
      </div> 
    </div>
  )


}