import React from 'react'


export const CharacterLimitObject = ( {vpHandler, handle}) => {

  return(
    <>
      {typeof vpHandler.get[handle] === 'string' ?
        <div className="w-full row"> 
          <div class="ml-auto">
              <div class="fs--2 row mr-3 pt-1 font-red " style={{color: `${vpHandler.get[handle].length >= vpHandler.characterLimitObject[handle]? "red": ""}`}} >
                <div>
                  {vpHandler.get[handle].length}
                </div>
                {" / "}
                <div>
                  {vpHandler.characterLimitObject[handle]}
                </div>
              </div> 
          </div>
        </div>
        : 
        <div className="w-full row"> 
          <div class="ml-auto">
              <div class="fs--2 row mr-3 pt-1 font-red ">
                <div>
                  {0}
                </div>
                {" / "}
                <div>
                  {vpHandler.characterLimitObject[handle]}
                </div>
              </div> 
          </div>
        </div>
      } 
    </>
  )
}