import React from 'react';
// import { useHistory } from 'react-router-dom';
// import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { HomepageHeader } from '../../components/Cards/HomepageCards/HomepageHeader';
import { PortfolioCard } from '../../components/Cards/HomepageCards/PortfolioCard';
// import { LearnHeader } from '../../components/Cards/LearnCards/LearnHeader';
// import { Other } from '../../components/Cards/LearnCards/Other';
// import { Playbooks } from '../../components/Cards/LearnCards/Playbooks';
// import { Tools } from '../../components/Cards/LearnCards/Tools';

import PageTitle from "../../components/header/PageTitle";


const Homepage = () => {
  // const [init, setInit] = React.useState(false)

  return (
    <div class="pt-3">
      <PageTitle title="D2C Accelerator" subtitle="Your world of direct-to-consumer innovations" />
      <HomepageHeader />
      <PortfolioCard />
      {/* <div class="text-center">
        <img className="" src={require('./../../assets/img/AdobeStock_313078297.jpeg')} alt="..." width="75%" />
      </div> */}
    </div>
  );
};

export default Homepage;
