import React, { useEffect, useState } from "react";
import { DropdownFieldFilterDeck } from "../Input/DropdownFieldFilterDeck";
import { countryList } from "../Lists/countryList";
import CsvDownload from 'react-json-to-csv'
import { useOktaAuth } from "@okta/okta-react";

export const FilterDeck = ({ valueProps, valuePropsBackup, setValueProps, string, setString }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [init, setInit] = useState(false);
  const [dataInit, setDataInit] = useState(false);
  const [includePerished, setIncludePerished] = useState(true);
  const [myVPs, setMyVPs] = useState(false);
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [boxOptions, setBoxOptions] = useState([]);
  const [vpNameOptions, setVpNameOptions] = useState([]);

  const [filterObject, setFilterObject] = useState({ 
    Name: "",
    ValuePropositionName: "",
    Country: "",
    CountryArr: [],
    Region: "",
    RegionArr: [],
    Division: "",
    Channel:"",
    Species: "",
    Box: ""
  })

  useEffect(() => {
    Filter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject, includePerished, myVPs, string]);

  useEffect(() => {
    if (valueProps !== undefined && valueProps.length > 0 && !init) {
      setInit(true); 
      setData(valueProps);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProps]);

  useEffect(() => {
    if (data.length > 0) {
      if (!dataInit) {
        CreateCountryOptions();
        CreateVPOptions();
        CreateRegionOptions();
        CreateDivisionOptions();
        CreateChannelOptions();
        CreateSpeciesOptions();
        CreateBoxOptions();
        setDataInit(!dataInit);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   if (
  //     countryOptions.length > 0 &&
  //     regionOptions.length > 0 &&
  //     divisionOptions.length > 0 &&
  //     channelOptions.length > 0 &&
  //     speciesOptions.length > 0 &&
  //     boxOptions.length > 0
  //   )
  //     Filter();
  // }, [countryOptions, regionOptions, divisionOptions, channelOptions, speciesOptions, boxOptions]);

  const sortArray = (array) => {
    array.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    })
    return array
  }

  const CreateCountryOptions = () => {
    let array = [];
    data.forEach(item => (item.geography !== '' && array.push(item.geography)));
    array = Array.from(new Set(array));

    setCountryOptions(sortArray(array));
  };

  const CreateVPOptions = () => {
    let array = [];
    data.forEach(item => (!!item.valuePropositionName && array.push(item.valuePropositionName)));
    array = Array.from(new Set(array));

    setVpNameOptions(sortArray(array));
  };

  const CreateRegionOptions = () => {
    let array = [];

    data.forEach(item =>{
      let obj = countryList.flat().find(arrayItem => arrayItem.Name === item.geography)
      if(obj !== undefined) array.push(obj.Region)
      //if(item.geography !== '' && item.geography !== undefined) array.push(countryList.find(arrayItem => arrayItem.Name === item.geography).Region)
    });
    array = Array.from(new Set(array));

    setRegionOptions(sortArray(array));
  };

  const CreateDivisionOptions = () => {
    let array = [];

    data.forEach(item => (item.division !== '' ? array.push(item.division) : ''));
    array = Array.from(new Set(array));
    setDivisionOptions(sortArray(array));
  };

  const CreateChannelOptions = () => {
    let array = [];

    data.forEach(item => (item.channel !== '' ? array.push(item.channel) : ''));
    array = Array.from(new Set(array));

    setChannelOptions(sortArray(array));
  };

  const CreateSpeciesOptions = () => {
    let array = ["Cat", "Dog", "Both"];

    // data.forEach(item =>
    //   item.both ? array.push('Both') : item.cat ? array.push('Cat') : item.dog ? array.push('Dog') : ''
    // );
    // array = Array.from(new Set(array));
    
    setSpeciesOptions(array);
  };

  const CreateBoxOptions = () => {
    let array = [];

    data.forEach(item => (item.pluggedIntoBox ? array.push('Plugged In') : array.push('Not Plugged In')));
    array = Array.from(new Set(array));

    setBoxOptions(array);
  };

  const ResetFilter = () =>{
    setString("")
    setFilterObject({ 
      Name: "",
      ValuePropositionName: "",
      Country: "",
      CountryArr: [],
      Region: "",
      RegionArr: [],
      Division: "",
      Channel:"",
      Species: "",
      Box: ""
    })

  }

  const Filter = () => {

    const CheckSpecies = (item) => {

      if(item.species.length === 0) return false
      else {
        if(filterObject.Species === "Both") if(item.species.length === 2) return true; else return false
        if(filterObject.Species === "Cat") if(item.species.length === 1 && item.species.includes("Cat")) return true; else return false
        if(filterObject.Species === "Dog") if(item.species.length === 1 && item.species.includes("Dog")) return true; else return false
      }

      
      
      //if(item.species.length === 1) 
      // if (filterObject.Species === "Both") return item.both
      // if (filterObject.Species === "Cat")  return item.cat
      // if (filterObject.Species === "Dog")  return item.dog
      // return false;
    }

    const CheckCountryArray = (item, field) => {

      if(filterObject.CountryArr.includes(item[field])) return true
      else return false
    }

    const CheckMyVPs = (item, field) => {
      let user = authState.accessToken.claims.sub
      //console.log(item.team)
      let check = false
      item.team.forEach(item => {
        if(item.email === user) check = true
      })
      return check
    }

    const CheckRegionArray = (item, field) => {
      let code = countryList.flat().find(country => country.Name === item.geography)
      
      if(code !== undefined){
        if(filterObject.RegionArr.includes(code.Region)) return true
        else return false
      }
      else return false

    }

    const CheckRegion = (item) => {
  
      let country = item.geography;
      let countries = []; 
      let countriesS = []; 

      if (country === "") return false;
      
      countries = countryList.filter(arrayItem => arrayItem.Region === filterObject.Region);
      countries.forEach(arrayItem => countriesS.push(arrayItem.Name))

      return countriesS.includes(country)
    }

    const CheckFilter = (item) => {
      if (filterObject.Box === "Plugged In") return (item.pluggedIntoBox === true)
      if (filterObject.Box === "Not Plugged In")  return (item.pluggedIntoBox === false)
    }

    const CheckString = (item) => {

      if(item.name.toLowerCase().includes(string.toLowerCase())) return true
      else return false
    }

    let _data = valuePropsBackup;

    if (filterObject.ValuePropositionName !== "") _data = _data.filter(item => item.valuePropositionName         === filterObject.ValuePropositionName);
    if (filterObject.Country !== "")              _data = _data.filter(item => item.geography         === filterObject.Country);
    if (filterObject.Region !== "")               _data = _data.filter(item => CheckRegion(item));
    if (filterObject.Division !== "")             _data = _data.filter(item => item.division        === filterObject.Division);
    if (filterObject.Channel !== "")              _data = _data.filter(item => item.channel         === filterObject.Channel);
    if (filterObject.Species !== "")              _data = _data.filter(item => CheckSpecies(item));
    if (filterObject.Box !== "")                  _data = _data.filter(item => CheckFilter(item));
    if (includePerished !== true)                 _data = _data.filter(item => item.perish          !== true);
    if (myVPs === true)                           _data = _data.filter(item => CheckMyVPs(item, "myVPs"));
    if (filterObject.CountryArr.length>0)         _data = _data.filter(item => CheckCountryArray(item, "geography"));
    if (filterObject.RegionArr.length>0)          _data = _data.filter(item => CheckRegionArray(item, "region"));
    
    _data = _data.filter(item => CheckString(item));

    setValueProps(_data);
  };

// region
  return (
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <div class="row flex-between-center">
          <div class="col-auto col-sm-6 col-lg-7">
            <h6 class="mb-0 text-nowrap py-2 py-xl-0">Filter deck</h6>
            <div class="me-2 fs--2 mars-blue" onClick={() => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1" />
              Quick guide
            </div>
          </div>
          <div class="ml-auto">
            <input class="form-check-input mt-2" type="checkbox" checked={includePerished} onChange={e=>setIncludePerished(!includePerished)}/>
            <label class="form-check-label fs--2">Include Perished VP's</label>
          </div>
          <div class="ml-5 mr-3">
            <input class="form-check-input mt-2" type="checkbox" checked={myVPs} onChange={e=>setMyVPs(!myVPs)}/>
            <label class="form-check-label fs--2">My VP's</label>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
          <div class="p-card rounded fs--2 pb-0">
            <p>The filters you set here are applicable to all the elements on the rest of this page. All filters have an "All" optional choice to resets this particular filter. The "reset all" link on the right will reset all filters.<br/><br/>
              Select one or more of the following optional filters:
            </p>
              <ul>
                <li>Country → Select a country you like to use a filter in this overview</li>
                <li>Region → Select a region you like to see in this overview</li>
                <li>Division → Select Royal Canin (RC) or Pet Nutrition (PN)</li>
                <li>Channel → Select one of the following channels<br/>
                <div class="row">
                  <div class="col-lg-2">
                    <span class="text-decoration-underline">Online owned</span><br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>flagship store<br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>subscription<br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>marketplace<br/><br/>
                    <span class="text-decoration-underline">Online platforms</span><br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>shop in shop<br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>social media<br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>home delivery<br/><br/>
                  </div>
                  <div class="col-lg-3">
                    <span class="text-decoration-underline">Offline</span><br/>
                    <span class="fas fa-store fs--1 me-1"></span>flagship store<br/>
                    <span class="fas fa-store fs--2 me-1"></span>shop in shop<br/>
                    <span class="fas fa-store fs--2 me-1"></span>pop-up store<br/><br/>
                    <span class="text-decoration-underline">Online owned (B2B)</span><br/>
                    <span class="fas fa-wifi fs--2 me-1"></span>digital distributor<br/><br/>
                  </div>
                </div>
                </li>
                {/* <li>Species → Select one<br/>
                  <div class="row">
                    <div class="col-lg-1">
                      <span class="fas fas fa-cat fs--1 text-600 me-1"></span>Cat<br/>
                    </div>
                    <div class="col-lg-1">
                      <span class="fas fas fa-dog fs--1 text-600 me-1"></span>Dog
                    </div>
                  </div> 
                </li>
                <li>Box → Select (not) plugged into the box to see the respective value propositions</li> */}
              </ul>
          </div>
          
        </div>
        <div class="btn-group" />
        <div class="row">
          <DropdownFieldFilterDeck title="ValuePropositionName"  label="VP Name" width={"25"} minWidth={"15"} isMulti={false} list={vpNameOptions}   setState={setVpNameOptions}  filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Country"  width={"25"} minWidth={"15"} isMulti={true} list={countryOptions}   setState={setCountryOptions}  filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Region"   width={"25"} minWidth={"15"} isMulti={true} list={regionOptions}    setState={setRegionOptions}   filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Division" width={"9"}  minWidth={"9"} list={divisionOptions}  setState={setDivisionOptions} filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Channel"  width={"10"} minWidth={"10"} list={channelOptions}   setState={setChannelOptions}  filterObject={filterObject} setFilterObject={setFilterObject}/>
          {/* <DropdownFieldFilterDeck title="Species"  width={"9"}  minWidth={"9"} list={speciesOptions}   setState={setSpeciesOptions}  filterObject={filterObject} setFilterObject={setFilterObject}/> */}
          {/* <DropdownFieldFilterDeck title="Box"      width={"8"}  minWidth={"8"} list={boxOptions}       setState={setBoxOptions}      filterObject={filterObject} setFilterObject={setFilterObject}/> */}
          <div onClick={() => ResetFilter() } class="hoverhand mars-blue fs--2 col-xl-1 col-md-2 col-sm-4 pt-2" style={{fontWeight: '500'}}>reset all</div>
          
          {/* <div class="ml-auto col-xl-2 col-md-3 col-sm-5">
            <CsvDownload  
              data={valueProps}
              filename="VP_Data.csv"
              style={{ //pass other props, like styles
                //background:"linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)",
                backgroundColor:"#fff",
                border:"0px solid #a511c0",
                }} 
                >
              <div class="hoverhand mars-blue fs--2 pt-2" style={{fontWeight: '500'}}>download data</div>
            </CsvDownload>
          </div> */}
          
        </div>
      </div>
    </div>
  );
};
