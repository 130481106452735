import React from 'react';
import PageTitle from "../../components/header/PageTitle";
import { GetValueProps } from '../../components/API/ControlTowerAPI';  
import { FilterDeck } from '../../components/Cards/FilterDeck';
import ValuePropositionTable from '../dashboards/ValuePropositionTable';
import * as vpApi from "./../../components/API/IndividualVpAPI";

import { demoVPArray } from '../../components/Lists/demoVPArray';

const SearchVP = () => {
  const [init, setInit] = React.useState(false)
  const [init2, setInit2] = React.useState(false)
  const [valueProps, setValueProps] = React.useState([]);
  const [valuePropsBackup, setValuePropsBackup] = React.useState([]);
  const [string, setString] = React.useState("");

  const [allVp, setAllVp] = React.useState(demoVPArray);
  const [allVpBackup, setAllVpBackup] = React.useState(demoVPArray);

  // React.useEffect(() => {
  //   if (!init) {
  //     setInit(true);
  //     GetValueProps(setValueProps, setValuePropsBackup);
  //     vpApi.getManyVp(setAllVp, setAllVpBackup)
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [init]);

  // React.useEffect(() => {
  //   if (!init2 && allVp.length > 0 ) {
  //     setInit2(true);
  //     let _data = allVp.filter(item => item.active === true);
  //     setAllVp([..._data])
  //     setValuePropsBackup([..._data])
  //    // console.log(`_data; ${JSON.stringify(_data)}`)
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allVp]);

  return (
    <>
      <div class="px-3 pt-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
        <FilterDeck string={string} setString={setString} valueProps={allVp} setValueProps={setAllVp} valuePropsBackup={allVpBackup}/>
        <ValuePropositionTable string={string} setString={setString} valueProps={allVp} itemsPerPage={100} />
        <div style={{height: '1000px'}}></div>

      </div>
    </>
  );
};

export default SearchVP;
