import React from "react";
import * as api from "./../../API/IndividualVpAPI";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { CharacterLimitObject } from "./CharacterLimitObject";

export const Funding = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("preview");

  React.useEffect(() => {
    if(vpHandler.action === "view" || vpHandler.action === "review") setSelectedTab("preview")
    else setSelectedTab("write")
  }, [vpHandler.action]);
  
  const handleChange = (e, handle) => {
    let vpObject = vpHandler.get
    if(!!vpObject[handle]) {
      if(vpObject[handle].length <= vpHandler.characterLimitObject[handle]) vpObject[handle] = e 
      else if(e.length <= vpHandler.characterLimitObject[handle]) vpObject[handle] = e
    }
    else vpObject[handle] = e
    vpHandler.set({...vpObject})
  }

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  })

  const handleFunding = (e, sov) => {
    let vpObject = vpHandler.get
    vpObject.funding[sov] = Number(e.target.value)
    if(vpObject.funding[sov] === 0) vpObject.funding[sov] = null
    else vpObject.funding[sov] = Number(e.target.value)
    vpHandler.set({...vpObject})
  }

  const getFunding = (sov) => {
    let vpObject = vpHandler.get

    if(vpObject.funding[sov] !== null && vpObject.funding[sov] !== undefined) return vpObject.funding[sov]
    else return null

  }

  // const getValue = (sov) => {
  //   let vpObject = vpHandler.get
  //   //console.log(`funding ${JSON.stringify(vpObject.funding)}`)

  //   if(vpObject.funding[sov] !== null || vpObject.funding[sov] !== undefined) return Number(vpObject.funding[sov])

  //   else return null
  // }

  // const handleChange = (e, sov) => {
  //   let vpObject = vpHandler.get
  //   if(e.target.value === undefined) vpObject.funding[sov] = null
  //   else vpObject.funding[sov] = Number(e.target.value)
  //   vpHandler.set({...vpObject})
  // }

  const totalFunding = () => {
    let total = 0
    Object.keys(vpHandler.get.funding).forEach((item) => total = Number(total) + Number(vpHandler.get.funding[item]))
    return numberWithCommas(total)
  }

  const numberWithCommas = (x) => {
    if (x !== null && x !== undefined && x !== "") return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else return 'N/A'
  }
  

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="">Funding</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button" >
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p class="fs--2">
              Enter below the amount of funding ($) that the value propsoition has received by stage. Include both one-off and incremental running costs for technical development, sales & marketing and overheads. 
            </p>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
          <div class="row fs--2 pl-2">
            <div class="col col-6 col-lg-2">
              <span class="mars-c1 fas fa-circle fs--2 mr-1"></span>Ideation
              <div>
                <span class="input-dollar">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base" 
                    placeholder="Ideation"
                    onChange={e => handleFunding(e, "ideation")} 
                    value={getFunding("ideation")} 
                    type="number"
                  />           
                </span>
              </div>             
            </div>
            <div class="col col-6 col-lg-2">
              <span class="mars-c2 fas fa-circle fs--2 mr-1"></span>Cust Val
              <div>
                <span class="input-dollar">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base" 
                    placeholder="Cust. Val."

                    onChange={e => handleFunding(e, "customerVal")} 
                    value={getFunding("customerVal")} 
                    type="number"
                  />           
                </span>
              </div>             
            </div>
            <div class="col col-6 col-lg-2">
              <span class="mars-c3 fas fa-circle fs--2 mr-1"></span>MVP
              <div>
                <span class="input-dollar">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base" 
                    placeholder="MVP"
                    onChange={e => handleFunding(e, "mvp")} 
                    value={getFunding("mvp")} 
                    type="number"
                  />           
                </span>
              </div>             
            </div>
            <div class="col col-6 col-lg-2">
              <span class="mars-c4 fas fa-circle fs--2 mr-1"></span>Optimize
              <div>
                <span class="input-dollar">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base" 
                    placeholder="Optimize"
                    onChange={e => handleFunding(e, "optimize")} 
                    value={getFunding("optimize")} 
                    type="number"
                  />           
                </span>
              </div> 
            </div>
            <div class="col col-6 col-lg-2">
              <span class="mars-c5 fas fa-circle fs--2 mr-1"></span>Grow
              <div>
                <span class="input-dollar">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base" 
                    onChange={e => handleFunding(e, "grow")} 
                    placeholder="Grow"
                    value={getFunding("grow")} 
                    type="number"
                  />           
                </span>
              </div>            
            </div>
            <div class="col col-6 col-lg-2">
              <span class="mars-c6 fas fa-circle fs--2 mr-1"></span>Scale
              <div>
                <span class="input-dollar input-test">
                  <input 
                    disabled={api.checkDisabled(vpHandler)} 
                    //class="form-control form-height font-base"
                    placeholder="Scale" 
                    onChange={e => handleFunding(e, "scale")} 
                    value={getFunding("scale")} 
                    type="number"
                  />           
                </span>
              </div>
            </div>
            <hr/>
            <div class="col pl-1 pt-1 text-center">
              {`Total: ${totalFunding()}$`}
            </div>
          </div>
          <hr/>
            <div class="p-card rounded fs--2">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.investment}
                onChange={e => handleChange(e, "investment")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
              <CharacterLimitObject vpHandler={vpHandler} handle="investment"/> 

            </div>
        </div>
      </div>
    </div>      
  )}