import React from 'react';
import PageTitle from "../../components/header/PageTitle";
import { GetValueProps } from '../../components/API/ControlTowerAPI';  
import { FilterDeck } from '../../components/Cards/FilterDeck';
import ValuePropositionTable from '../dashboards/ValuePropositionTable';
import * as vpApi from "./../../components/API/IndividualVpAPI";
import Select from 'react-select'
import { lookUpList } from '../../components/Lists/lookUpList';
import { lookUpKPI } from '../../components/Lists/lookUpKPI';
import BenchmarkScatter from '../../components/ECharts/BenchmarkScatter';
import { Input, Col, Card, CardHeader, CardBody, Row, Table, UncontrolledTooltip } from "reactstrap"
import CsvDownload from 'react-json-to-csv'

const BenchmarkVP = () => {

  const [yAxisName, setYAxisName] = React.useState("");
  const [init, setInit] = React.useState(false)
  const [init2, setInit2] = React.useState(false)
  const [valueProps, setValueProps] = React.useState([]);
  const [valuePropsBackup, setValuePropsBackup] = React.useState([]);
  const [string, setString] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [allVp, setAllVp] = React.useState([]);
  const [allVpBackup, setAllVpBackup] = React.useState([]);
  const [selectedKPI, setSelectedKPI] = React.useState("");
  const [selectedVP, setSelectedVP] = React.useState("");
  const [selectedVPArr, setSelectedVPArr] = React.useState([]);
  const [kpiData, setKpiData] = React.useState([]);
  const [scatterData, setScatterData] = React.useState({ideation: [], customerVal: [], mvp: [], optimize: [], grow: [], scale: []});

  let list = {
    "ideation": " 1",
    "customerVal": "2",
    "mvp": "3",
    "optimize": "4",
    "grow": "5",
    "scale": "6",
    "persevere": "Persevere",
    "perish": "Perish",
    "pivot": "Pivot"
  }


  React.useEffect(() => {
    if (!init) {
      setInit(true);
      GetValueProps(setValueProps, setValuePropsBackup);
      vpApi.getManyVp(setAllVp, setAllVpBackup)

    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);
  
  React.useEffect(() => {
    getVPKpi()
    getSplitValues()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVP]);

  React.useEffect(() => {
    getVPKpi()
    getSplitValues()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVPArr]);


  React.useEffect(() => {
    if (!init2 && allVp.length > 0 ) {
      setInit2(true);
      let _data = allVp.filter(item => item.active === true);
      setAllVp([..._data])
      setValuePropsBackup([..._data])
      getVPList()
      getVPKpi()

     // console.log(`_data; ${JSON.stringify(_data)}`)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVp]);

  React.useEffect(() => {

      getSplitValues()
      
     // console.log(`_data; ${JSON.stringify(_data)}`)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKPI, allVp]);
  
  const getList = () => {
    let array = [];
    let list = []
    let resetObj = {value: "", label: "Reset"}
    allVp.forEach((vp) => {
      Object.keys(vp.stageGate.customerVal).forEach((item) => {
        Object.keys(vp.stageGate.customerVal[item]).forEach((item2) => {
          if(vp.stageGate.customerVal[item][item2] !== null){
            array.push(item2);}
        })
      })
    })
    let uniq = new Set(array);
    const uniqueData = [...uniq];
    // console.log(`data: ${JSON.stringify(uniqueData)}`)
    list.push(resetObj)
    uniqueData.forEach(item => {
      let object = {
        label: lookUpList[item],
        value: item
      }
      list.push(object)
    })
    return list;
  };

  const getVPKpi = () => {
    let array = [];
    let list = []
    let resetObj = {value: "", label: "Reset"}

    let VP = allVp.find((item) => item.name === selectedVP)

    if(VP !== undefined){
      Object.keys(VP.stageGate[VP.currentStage]).forEach((item) => {
        Object.keys(VP.stageGate[VP.currentStage][item]).forEach((item2) => {
          if(VP.stageGate[VP.currentStage][item][item2] !== null){
            if(item2 !== "session") array.push(item2);}
            //console.log(`item: ${item} item2: ${item2}, ${VP.name}`)
        })
      })
    }
    else if(VP === undefined){
      allVp.forEach((VP) => {
        Object.keys(VP.stageGate[VP.currentStage]).forEach((item) => {
          Object.keys(VP.stageGate[VP.currentStage][item]).forEach((item2) => {
            if(VP.stageGate[VP.currentStage][item][item2] !== null){
              if(item2 !== "session") array.push(item2);}
              //console.log(`item: ${item} item2: ${item2}, ${VP.name}`)

          })
        })
      })
    }

    
    let uniq = new Set(array);
    const uniqueData = [...uniq];
    // console.log(`data: ${JSON.stringify(uniqueData)}`)
    //list.push(resetObj)
    uniqueData.forEach(item => {
      let object = {
        label: lookUpList[item],
        value: item
      }
      list.push(object)
    })
    getTableValues()
    list.sort((a,b) => a.label.localeCompare(b.label))
    list.unshift(resetObj)
    return list;
  };

  const getVPList = () => {
    let list = []
    let resetObj = {value: "", label: "Reset"}
    //list.push(resetObj)
    allVp.forEach((vp) => {
      let object = {
        label: vp.name,
        value: vp.name
      }
      list.push(object)
    })
    list.sort((a,b) => a.label.localeCompare(b.label))
    list.unshift(resetObj)
    return list;
  };

  const getVPValue = () => {
    if(selectedVP !== "") return {label: selectedVP, value: selectedVP}
    else return null
  }

  const getValue = () => {
    if(selectedKPI !== "") return {label: lookUpList[selectedKPI], value: selectedKPI}
    else return null
  }

  const handleVPChange = (e) => {  
    if(e.value === ""){
      setSelectedVP("") 
    }
    else setSelectedVP(e.value)
  }

  const handleChange = (e) => {  
      if(e.value === "")setSelectedKPI("")
      else setSelectedKPI(e.value)
  }

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
      height: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const getTableValues = () => {
    let obj = {}
    let arr = []
    if(selectedKPI !== "") {
      allVp.forEach((vp) => {
        obj = {
          "vp": vp.name,
          "stage": vp.currentStage,
          "kpi": selectedKPI,
          "value": getKpiValue(vp.stageGate[vp.currentStage][lookUpKPI[selectedKPI]][selectedKPI])
        }
        arr.push(obj)
      })
    }
    //setKpiData(arr)
    arr.sort((a, b) => a.value > b.value ? 1 : -1)
    return arr
  }

  const getAvg = (stage, splitValues) => {
    let data = splitValues[stage]
    let total = 0;
    let avg = 0;

    data.forEach(item => {
      total = Number(total) + Number(item.value[1])
    })
    avg = total / data.length
    //console.log(`avg; ${stage} ${avg}`)
    if(!isNaN(+avg)) return avg
    else return 0
  }

  const getSplitValues = () => {
    let obj = {}
    let splitValues= {
        'ideation': [], 'ideationAvg': 0, 
        'customerVal': [], 'customerValAvg': 0, 
        'mvp': [], 'mvpAvg': 0, 
        'optimize': [], 'optimizeAvg': 0, 
        'grow': [], 'growAvg': 0,
        'scale': [], 'scaleAvg': 0, 
        'highlight': [] 
      }
    let arr = []
    let listArr = []

    if(selectedKPI !== "") {
      allVp.forEach((vp) => {
        obj = {
          "vp": vp.name,
          "stage": vp.currentStage,
          "kpi": selectedKPI,
          "value": getKpiValue(vp.stageGate[vp.currentStage][lookUpKPI[selectedKPI]][selectedKPI])
        }
        arr.push({...obj})

      })
    }

    arr.sort((a, b) => a.value > b.value ? 1 : -1)

    arr.forEach((item) => {
      !isNaN(+item.value) && splitValues[item.stage].push({name: item.vp, value:[list[item.stage], item.value]}) 
      if(item.vp === selectedVP)
        !isNaN(+item.value) && splitValues['highlight'].push({name: item.vp, value: [list[item.stage], item.value]}) 
    })

    splitValues.ideationAvg = getAvg('ideation', splitValues)
    splitValues.customerValAvg = getAvg('customerVal', splitValues)
    splitValues.mvpAvg = getAvg('mvp', splitValues)
    splitValues.optimizeAvg = getAvg('optimize', splitValues)
    splitValues.growAvg = getAvg('grow', splitValues)
    splitValues.scaleAvg = getAvg('scale', splitValues)
    splitValues.kpi = lookUpList[selectedKPI]
   
    setScatterData({...splitValues})
    
    setYAxisName(lookUpList[selectedKPI])

  }

  const getKpiValue = (input) => {
    if(input === null) return `VP does not track ${lookUpList[selectedKPI]}`
    else{
      if(input.actual === "") return `${lookUpList[selectedKPI]} has no data`
      else return input.actual
    }
    //vp.stageGate[vp.currentStage][lookUpKPI[selectedKPI]][selectedKPI] !== null? vp.stageGate[vp.currentStage][lookUpKPI[selectedKPI]][selectedKPI].actual : 'N/A'
  }

  return (
    <>
      <div class="px-3 pt-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
        <FilterDeck string={string} setString={setString} valueProps={allVp} setValueProps={setAllVp} valuePropsBackup={allVpBackup}/>
        
        <div class="card mb-3">
          <div class="card-header pb-1 bg-light">
            <div class="row flex-between-center">
              <div class="col-auto col-sm-6 col-lg-7">
                <h6 class="mb-0 text-nowrap py-2 py-xl-0">Value proposition internal benchmark</h6>
                  <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
                    <i class="fas fa-info-circle mr-1" />
                    Quick guide & legend
                  </div>
                </div>
                
              </div>
            </div>
            <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
                <div class="p-card rounded fs--2">
                  <ul>
                    <li>
                      Phase → Phase is indicated by color.
                      <br />
                      <span style={{ color: '#add136' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Ideation
                      <br />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#50C1EF' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Customer validation
                      <br />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#FFC32D' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| MVP
                      <br />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#00B050' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Optimize
                      <br />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#5F7D95' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Grow
                      <br />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                      <span style={{ color: '#6F3996' }} class="fas fa-circle fs--2 mr-1" />| Scale
                    </li>  
                  </ul>
                </div>
              </div>
              <div class="card-footer p-0">
                <div class="row ml-2">
                  <div className="fs--2 ml-2 mt-1 w-25">
                    <Select 
                      isMulti={false}
                      isSearchable={false}
                      isClearable={false}
                      className="" 
                      options={getVPKpi()}
                      placeholder={`Select Stage Gate KPI`}
                      autosize={true}
                      onChange={(e) => handleChange(e)}
                      value={getValue()}
                      styles={styles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 3,
                        colors: {
                          ...theme.colors,
                          primary: '#cccccc',
                        },
                      })}
                    />
                  </div>
                  <div className="fs--2 ml-2 mt-1 w-25">
                    <Select 
                      isMulti={false}
                      isSearchable={false}
                      isClearable={false}
                      className="" 
                      options={getVPList()}
                      placeholder={`Select Value Proposition`}
                      autosize={true}
                      onChange={(e) => handleVPChange(e)}
                      value={getVPValue()}
                      styles={styles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 3,
                        colors: {
                          ...theme.colors,
                          primary: '#cccccc',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className={`ml-auto mr-auto pt-3 ${selectedKPI === "" ? "":"d-none"}`}>Select a Stage Gate KPI to display graph data.</div>
                </div>
                <BenchmarkScatter dataObject={scatterData} yAxisName={yAxisName}/>            
              </div>
            </div>
          </div>

        <div style={{height: '1000px'}}></div>

      </div>
    </>
  );
};

export default BenchmarkVP;
